import {gql} from 'apollo-angular';
import {Injectable} from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /** A date string, such as 2007-12-03 (YYYY-MM-DD), compliant with ISO 8601 standard for representation of dates using the Gregorian calendar. */
  Date: any;
  /** A date-time string at UTC, such as 2007-12-03T10:15:30Z, compliant with the date-timeformat outlined in section 5.6 of the RFC 3339 profile of the ISO 8601 standard for representationof dates and times using the Gregorian calendar. */
  DateTime: any;
  Hex: any;
  /** Raw JSON value */
  Json: any;
  /** The Long scalar type represents non-fractional signed whole numeric values. Long can represent values between -(2^63) and 2^63 - 1. */
  Long: any;
  RGBAHue: any;
  RGBATransparency: any;
  /** Slate-compatible RichText AST */
  RichTextAST: any;
};

export type GraphCmsAggregate = {
  __typename?: 'Aggregate';
  count: Scalars['Int'];
};

/** Asset system model */
export type GraphCmsAsset = GraphCmsNode & {
  __typename?: 'Asset';
  /** System stage field */
  stage: GraphCmsStage;
  /** System Locale field */
  locale: GraphCmsLocale;
  /** Get the other localizations for this document */
  localizations: Array<GraphCmsAsset>;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsAsset>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** The file handle */
  handle: Scalars['String'];
  /** The file name */
  fileName: Scalars['String'];
  /** The height of the file */
  height?: Maybe<Scalars['Float']>;
  /** The file width */
  width?: Maybe<Scalars['Float']>;
  /** The file size */
  size?: Maybe<Scalars['Float']>;
  /** The mime type of the file */
  mimeType?: Maybe<Scalars['String']>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of Asset versions */
  history: Array<GraphCmsVersion>;
  /** Get the url for the asset with provided transformations applied. */
  url: Scalars['String'];
};

/** Asset system model */
export type GraphCmsAssetLocalizationsArgs = {
  locales?: Array<GraphCmsLocale>;
  includeCurrent?: Scalars['Boolean'];
};

/** Asset system model */
export type GraphCmsAssetDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

/** Asset system model */
export type GraphCmsAssetCreatedAtArgs = {
  variation?: GraphCmsSystemDateTimeFieldVariation;
};

/** Asset system model */
export type GraphCmsAssetUpdatedAtArgs = {
  variation?: GraphCmsSystemDateTimeFieldVariation;
};

/** Asset system model */
export type GraphCmsAssetPublishedAtArgs = {
  variation?: GraphCmsSystemDateTimeFieldVariation;
};

/** Asset system model */
export type GraphCmsAssetCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Asset system model */
export type GraphCmsAssetUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Asset system model */
export type GraphCmsAssetPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Asset system model */
export type GraphCmsAssetScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Asset system model */
export type GraphCmsAssetHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

/** Asset system model */
export type GraphCmsAssetUrlArgs = {
  transformation?: Maybe<GraphCmsAssetTransformationInput>;
};

export type GraphCmsAssetConnectInput = {
  /** Document to connect */
  where: GraphCmsAssetWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsAssetConnection = {
  __typename?: 'AssetConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsAssetEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsAssetCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  handle: Scalars['String'];
  fileName: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: Maybe<GraphCmsAssetCreateLocalizationsInput>;
};

export type GraphCmsAssetCreateLocalizationDataInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  handle: Scalars['String'];
  fileName: Scalars['String'];
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type GraphCmsAssetCreateLocalizationInput = {
  /** Localization input */
  data: GraphCmsAssetCreateLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsAssetCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: Maybe<Array<GraphCmsAssetCreateLocalizationInput>>;
};

export type GraphCmsAssetCreateManyInlineInput = {
  /** Create and connect multiple existing Asset documents */
  create?: Maybe<Array<GraphCmsAssetCreateInput>>;
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<GraphCmsAssetWhereUniqueInput>>;
};

export type GraphCmsAssetCreateOneInlineInput = {
  /** Create and connect one Asset document */
  create?: Maybe<GraphCmsAssetCreateInput>;
  /** Connect one existing Asset document */
  connect?: Maybe<GraphCmsAssetWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsAssetEdge = {
  __typename?: 'AssetEdge';
  /** The item at the end of the edge. */
  node: GraphCmsAsset;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsAssetManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsAssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsAssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsAssetWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsAssetOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  HandleAsc = 'handle_ASC',
  HandleDesc = 'handle_DESC',
  FileNameAsc = 'fileName_ASC',
  FileNameDesc = 'fileName_DESC',
  HeightAsc = 'height_ASC',
  HeightDesc = 'height_DESC',
  WidthAsc = 'width_ASC',
  WidthDesc = 'width_DESC',
  SizeAsc = 'size_ASC',
  SizeDesc = 'size_DESC',
  MimeTypeAsc = 'mimeType_ASC',
  MimeTypeDesc = 'mimeType_DESC',
}

/** Transformations for Assets */
export type GraphCmsAssetTransformationInput = {
  image?: Maybe<GraphCmsImageTransformationInput>;
  document?: Maybe<GraphCmsDocumentTransformationInput>;
  /** Pass true if you want to validate the passed transformation parameters */
  validateOptions?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsAssetUpdateInput = {
  handle?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  /** Manage document localizations */
  localizations?: Maybe<GraphCmsAssetUpdateLocalizationsInput>;
};

export type GraphCmsAssetUpdateLocalizationDataInput = {
  handle?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type GraphCmsAssetUpdateLocalizationInput = {
  data: GraphCmsAssetUpdateLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsAssetUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: Maybe<Array<GraphCmsAssetCreateLocalizationInput>>;
  /** Localizations to update */
  update?: Maybe<Array<GraphCmsAssetUpdateLocalizationInput>>;
  upsert?: Maybe<Array<GraphCmsAssetUpsertLocalizationInput>>;
  /** Localizations to delete */
  delete?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsAssetUpdateManyInlineInput = {
  /** Create and connect multiple Asset documents */
  create?: Maybe<Array<GraphCmsAssetCreateInput>>;
  /** Connect multiple existing Asset documents */
  connect?: Maybe<Array<GraphCmsAssetConnectInput>>;
  /** Override currently-connected documents with multiple existing Asset documents */
  set?: Maybe<Array<GraphCmsAssetWhereUniqueInput>>;
  /** Update multiple Asset documents */
  update?: Maybe<Array<GraphCmsAssetUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Asset documents */
  upsert?: Maybe<Array<GraphCmsAssetUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Asset documents */
  disconnect?: Maybe<Array<GraphCmsAssetWhereUniqueInput>>;
  /** Delete multiple Asset documents */
  delete?: Maybe<Array<GraphCmsAssetWhereUniqueInput>>;
};

export type GraphCmsAssetUpdateManyInput = {
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  /** Optional updates to localizations */
  localizations?: Maybe<GraphCmsAssetUpdateManyLocalizationsInput>;
};

export type GraphCmsAssetUpdateManyLocalizationDataInput = {
  fileName?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
};

export type GraphCmsAssetUpdateManyLocalizationInput = {
  data: GraphCmsAssetUpdateManyLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsAssetUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: Maybe<Array<GraphCmsAssetUpdateManyLocalizationInput>>;
};

export type GraphCmsAssetUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsAssetWhereInput;
  /** Update many input */
  data: GraphCmsAssetUpdateManyInput;
};

export type GraphCmsAssetUpdateOneInlineInput = {
  /** Create and connect one Asset document */
  create?: Maybe<GraphCmsAssetCreateInput>;
  /** Update single Asset document */
  update?: Maybe<GraphCmsAssetUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Asset document */
  upsert?: Maybe<GraphCmsAssetUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Asset document */
  connect?: Maybe<GraphCmsAssetWhereUniqueInput>;
  /** Disconnect currently connected Asset document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Asset document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsAssetUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsAssetWhereUniqueInput;
  /** Document to update */
  data: GraphCmsAssetUpdateInput;
};

export type GraphCmsAssetUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsAssetCreateInput;
  /** Update document if it exists */
  update: GraphCmsAssetUpdateInput;
};

export type GraphCmsAssetUpsertLocalizationInput = {
  update: GraphCmsAssetUpdateLocalizationDataInput;
  create: GraphCmsAssetCreateLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsAssetUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsAssetWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsAssetUpsertInput;
};

/** Identifies documents */
export type GraphCmsAssetWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsAssetWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsAssetWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsAssetWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  handle?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  handle_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  handle_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  handle_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  handle_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  handle_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  handle_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  handle_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  handle_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  handle_not_ends_with?: Maybe<Scalars['String']>;
  fileName?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  fileName_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  fileName_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  fileName_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  fileName_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  fileName_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  fileName_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  fileName_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  fileName_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  fileName_not_ends_with?: Maybe<Scalars['String']>;
  height?: Maybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  height_not?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  height_in?: Maybe<Array<Scalars['Float']>>;
  /** All values that are not contained in given list. */
  height_not_in?: Maybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  height_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  height_lte?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  height_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  height_gte?: Maybe<Scalars['Float']>;
  width?: Maybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  width_not?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  width_in?: Maybe<Array<Scalars['Float']>>;
  /** All values that are not contained in given list. */
  width_not_in?: Maybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  width_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  width_lte?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  width_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  width_gte?: Maybe<Scalars['Float']>;
  size?: Maybe<Scalars['Float']>;
  /** All values that are not equal to given value. */
  size_not?: Maybe<Scalars['Float']>;
  /** All values that are contained in given list. */
  size_in?: Maybe<Array<Scalars['Float']>>;
  /** All values that are not contained in given list. */
  size_not_in?: Maybe<Array<Scalars['Float']>>;
  /** All values less than the given value. */
  size_lt?: Maybe<Scalars['Float']>;
  /** All values less than or equal the given value. */
  size_lte?: Maybe<Scalars['Float']>;
  /** All values greater than the given value. */
  size_gt?: Maybe<Scalars['Float']>;
  /** All values greater than or equal the given value. */
  size_gte?: Maybe<Scalars['Float']>;
  mimeType?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  mimeType_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  mimeType_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  mimeType_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  mimeType_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  mimeType_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  mimeType_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  mimeType_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  mimeType_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  mimeType_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References Asset record uniquely */
export type GraphCmsAssetWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GraphCmsAuctionCuration = GraphCmsNode & {
  __typename?: 'AuctionCuration';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsAuctionCuration>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Used in GraphCMS UI for identification */
  title: Scalars['String'];
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  curatedAuctions: Array<GraphCmsCuratedAuction>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of AuctionCuration versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsAuctionCurationDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsAuctionCurationCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsAuctionCurationUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsAuctionCurationPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsAuctionCurationCuratedAuctionsArgs = {
  where?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedAuctionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsAuctionCurationScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsAuctionCurationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsAuctionCurationConnectInput = {
  /** Document to connect */
  where: GraphCmsAuctionCurationWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsAuctionCurationConnection = {
  __typename?: 'AuctionCurationConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsAuctionCurationEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsAuctionCurationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  curatedAuctions?: Maybe<GraphCmsCuratedAuctionCreateManyInlineInput>;
};

export type GraphCmsAuctionCurationCreateManyInlineInput = {
  /** Create and connect multiple existing AuctionCuration documents */
  create?: Maybe<Array<GraphCmsAuctionCurationCreateInput>>;
  /** Connect multiple existing AuctionCuration documents */
  connect?: Maybe<Array<GraphCmsAuctionCurationWhereUniqueInput>>;
};

export type GraphCmsAuctionCurationCreateOneInlineInput = {
  /** Create and connect one AuctionCuration document */
  create?: Maybe<GraphCmsAuctionCurationCreateInput>;
  /** Connect one existing AuctionCuration document */
  connect?: Maybe<GraphCmsAuctionCurationWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsAuctionCurationEdge = {
  __typename?: 'AuctionCurationEdge';
  /** The item at the end of the edge. */
  node: GraphCmsAuctionCuration;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsAuctionCurationManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsAuctionCurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsAuctionCurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsAuctionCurationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  curatedAuctions_every?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  curatedAuctions_some?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  curatedAuctions_none?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsAuctionCurationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type GraphCmsAuctionCurationUpdateInput = {
  title?: Maybe<Scalars['String']>;
  curatedAuctions?: Maybe<GraphCmsCuratedAuctionUpdateManyInlineInput>;
};

export type GraphCmsAuctionCurationUpdateManyInlineInput = {
  /** Create and connect multiple AuctionCuration documents */
  create?: Maybe<Array<GraphCmsAuctionCurationCreateInput>>;
  /** Connect multiple existing AuctionCuration documents */
  connect?: Maybe<Array<GraphCmsAuctionCurationConnectInput>>;
  /** Override currently-connected documents with multiple existing AuctionCuration documents */
  set?: Maybe<Array<GraphCmsAuctionCurationWhereUniqueInput>>;
  /** Update multiple AuctionCuration documents */
  update?: Maybe<Array<GraphCmsAuctionCurationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple AuctionCuration documents */
  upsert?: Maybe<Array<GraphCmsAuctionCurationUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple AuctionCuration documents */
  disconnect?: Maybe<Array<GraphCmsAuctionCurationWhereUniqueInput>>;
  /** Delete multiple AuctionCuration documents */
  delete?: Maybe<Array<GraphCmsAuctionCurationWhereUniqueInput>>;
};

export type GraphCmsAuctionCurationUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
};

export type GraphCmsAuctionCurationUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsAuctionCurationWhereInput;
  /** Update many input */
  data: GraphCmsAuctionCurationUpdateManyInput;
};

export type GraphCmsAuctionCurationUpdateOneInlineInput = {
  /** Create and connect one AuctionCuration document */
  create?: Maybe<GraphCmsAuctionCurationCreateInput>;
  /** Update single AuctionCuration document */
  update?: Maybe<GraphCmsAuctionCurationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single AuctionCuration document */
  upsert?: Maybe<GraphCmsAuctionCurationUpsertWithNestedWhereUniqueInput>;
  /** Connect existing AuctionCuration document */
  connect?: Maybe<GraphCmsAuctionCurationWhereUniqueInput>;
  /** Disconnect currently connected AuctionCuration document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected AuctionCuration document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsAuctionCurationUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsAuctionCurationWhereUniqueInput;
  /** Document to update */
  data: GraphCmsAuctionCurationUpdateInput;
};

export type GraphCmsAuctionCurationUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsAuctionCurationCreateInput;
  /** Update document if it exists */
  update: GraphCmsAuctionCurationUpdateInput;
};

export type GraphCmsAuctionCurationUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsAuctionCurationWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsAuctionCurationUpsertInput;
};

/** Identifies documents */
export type GraphCmsAuctionCurationWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsAuctionCurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsAuctionCurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsAuctionCurationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  curatedAuctions_every?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  curatedAuctions_some?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  curatedAuctions_none?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References AuctionCuration record uniquely */
export type GraphCmsAuctionCurationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum GraphCmsAuctionType {
  English = 'ENGLISH',
  Dutch = 'DUTCH',
}

export type GraphCmsBatchPayload = {
  __typename?: 'BatchPayload';
  /** The number of nodes that have been affected by the Batch operation. */
  count: Scalars['Long'];
};

/** Representing a color value comprising of HEX, RGBA and css color values */
export type GraphCmsColor = {
  __typename?: 'Color';
  hex: Scalars['Hex'];
  rgba: GraphCmsRgba;
  css: Scalars['String'];
};

/** Accepts either HEX or RGBA color value. At least one of hex or rgba value should be passed. If both are passed RGBA is used. */
export type GraphCmsColorInput = {
  hex?: Maybe<Scalars['Hex']>;
  rgba?: Maybe<GraphCmsRgbaInput>;
};

export type GraphCmsConnectPositionInput = {
  /** Connect document after specified document */
  after?: Maybe<Scalars['ID']>;
  /** Connect document before specified document */
  before?: Maybe<Scalars['ID']>;
  /** Connect document at first position */
  start?: Maybe<Scalars['Boolean']>;
  /** Connect document at last position */
  end?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsCuratedAuction = GraphCmsNode & {
  __typename?: 'CuratedAuction';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsCuratedAuction>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Name displayed for curated token in the GraphCMS UI. */
  title: Scalars['String'];
  auctionId: Scalars['String'];
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  auctionType: GraphCmsAuctionType;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of CuratedAuction versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsCuratedAuctionDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsCuratedAuctionCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedAuctionUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedAuctionPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedAuctionScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedAuctionHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsCuratedAuctionConnectInput = {
  /** Document to connect */
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsCuratedAuctionConnection = {
  __typename?: 'CuratedAuctionConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsCuratedAuctionEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsCuratedAuctionCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  auctionId: Scalars['String'];
  cl126zjpb6ghr01ywhdf37brn?: Maybe<GraphCmsAuctionCurationCreateManyInlineInput>;
  auctionType: GraphCmsAuctionType;
};

export type GraphCmsCuratedAuctionCreateManyInlineInput = {
  /** Create and connect multiple existing CuratedAuction documents */
  create?: Maybe<Array<GraphCmsCuratedAuctionCreateInput>>;
  /** Connect multiple existing CuratedAuction documents */
  connect?: Maybe<Array<GraphCmsCuratedAuctionWhereUniqueInput>>;
};

export type GraphCmsCuratedAuctionCreateOneInlineInput = {
  /** Create and connect one CuratedAuction document */
  create?: Maybe<GraphCmsCuratedAuctionCreateInput>;
  /** Connect one existing CuratedAuction document */
  connect?: Maybe<GraphCmsCuratedAuctionWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsCuratedAuctionEdge = {
  __typename?: 'CuratedAuctionEdge';
  /** The item at the end of the edge. */
  node: GraphCmsCuratedAuction;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsCuratedAuctionManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsCuratedAuctionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsCuratedAuctionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsCuratedAuctionWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  auctionId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  auctionId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  auctionId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  auctionId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  auctionId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  auctionId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  auctionId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  auctionId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  auctionId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  auctionId_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  auctionType?: Maybe<GraphCmsAuctionType>;
  /** All values that are not equal to given value. */
  auctionType_not?: Maybe<GraphCmsAuctionType>;
  /** All values that are contained in given list. */
  auctionType_in?: Maybe<Array<GraphCmsAuctionType>>;
  /** All values that are not contained in given list. */
  auctionType_not_in?: Maybe<Array<GraphCmsAuctionType>>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsCuratedAuctionOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  AuctionIdAsc = 'auctionId_ASC',
  AuctionIdDesc = 'auctionId_DESC',
  AuctionTypeAsc = 'auctionType_ASC',
  AuctionTypeDesc = 'auctionType_DESC',
}

export type GraphCmsCuratedAuctionUpdateInput = {
  title?: Maybe<Scalars['String']>;
  auctionId?: Maybe<Scalars['String']>;
  cl126zjpb6ghr01ywhdf37brn?: Maybe<GraphCmsAuctionCurationUpdateManyInlineInput>;
  auctionType?: Maybe<GraphCmsAuctionType>;
};

export type GraphCmsCuratedAuctionUpdateManyInlineInput = {
  /** Create and connect multiple CuratedAuction documents */
  create?: Maybe<Array<GraphCmsCuratedAuctionCreateInput>>;
  /** Connect multiple existing CuratedAuction documents */
  connect?: Maybe<Array<GraphCmsCuratedAuctionConnectInput>>;
  /** Override currently-connected documents with multiple existing CuratedAuction documents */
  set?: Maybe<Array<GraphCmsCuratedAuctionWhereUniqueInput>>;
  /** Update multiple CuratedAuction documents */
  update?: Maybe<Array<GraphCmsCuratedAuctionUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CuratedAuction documents */
  upsert?: Maybe<Array<GraphCmsCuratedAuctionUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple CuratedAuction documents */
  disconnect?: Maybe<Array<GraphCmsCuratedAuctionWhereUniqueInput>>;
  /** Delete multiple CuratedAuction documents */
  delete?: Maybe<Array<GraphCmsCuratedAuctionWhereUniqueInput>>;
};

export type GraphCmsCuratedAuctionUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  auctionId?: Maybe<Scalars['String']>;
  auctionType?: Maybe<GraphCmsAuctionType>;
};

export type GraphCmsCuratedAuctionUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsCuratedAuctionWhereInput;
  /** Update many input */
  data: GraphCmsCuratedAuctionUpdateManyInput;
};

export type GraphCmsCuratedAuctionUpdateOneInlineInput = {
  /** Create and connect one CuratedAuction document */
  create?: Maybe<GraphCmsCuratedAuctionCreateInput>;
  /** Update single CuratedAuction document */
  update?: Maybe<GraphCmsCuratedAuctionUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CuratedAuction document */
  upsert?: Maybe<GraphCmsCuratedAuctionUpsertWithNestedWhereUniqueInput>;
  /** Connect existing CuratedAuction document */
  connect?: Maybe<GraphCmsCuratedAuctionWhereUniqueInput>;
  /** Disconnect currently connected CuratedAuction document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected CuratedAuction document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsCuratedAuctionUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  /** Document to update */
  data: GraphCmsCuratedAuctionUpdateInput;
};

export type GraphCmsCuratedAuctionUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsCuratedAuctionCreateInput;
  /** Update document if it exists */
  update: GraphCmsCuratedAuctionUpdateInput;
};

export type GraphCmsCuratedAuctionUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsCuratedAuctionUpsertInput;
};

/** Identifies documents */
export type GraphCmsCuratedAuctionWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsCuratedAuctionWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsCuratedAuctionWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsCuratedAuctionWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  auctionId?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  auctionId_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  auctionId_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  auctionId_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  auctionId_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  auctionId_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  auctionId_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  auctionId_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  auctionId_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  auctionId_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  auctionType?: Maybe<GraphCmsAuctionType>;
  /** All values that are not equal to given value. */
  auctionType_not?: Maybe<GraphCmsAuctionType>;
  /** All values that are contained in given list. */
  auctionType_in?: Maybe<Array<GraphCmsAuctionType>>;
  /** All values that are not contained in given list. */
  auctionType_not_in?: Maybe<Array<GraphCmsAuctionType>>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References CuratedAuction record uniquely */
export type GraphCmsCuratedAuctionWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GraphCmsCuratedToken = GraphCmsNode & {
  __typename?: 'CuratedToken';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsCuratedToken>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Name displayed for curated token in the GraphCMS UI. */
  title: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  /** Leave empty if linking directly to token. */
  link?: Maybe<Scalars['String']>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  /** deprecated */
  tokenCategories: Array<GraphCmsTokenCategory>;
  curationType: GraphCmsCurationType;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  categories: Array<GraphCmsCuratedTokenCategories>;
  /** List of CuratedToken versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsCuratedTokenDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsCuratedTokenCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedTokenUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedTokenPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedTokenTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryWhereInput>;
  orderBy?: Maybe<GraphCmsTokenCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedTokenScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedTokenCategoriesArgs = {
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCuratedTokenHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsCuratedTokenCategories = GraphCmsTokenCategory;

export type GraphCmsCuratedTokenCategoriesConnectInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryConnectInput>;
};

export type GraphCmsCuratedTokenCategoriesCreateInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryCreateInput>;
};

export type GraphCmsCuratedTokenCategoriesCreateManyInlineInput = {
  /** Create and connect multiple existing CuratedTokenCategories documents */
  create?: Maybe<Array<GraphCmsCuratedTokenCategoriesCreateInput>>;
  /** Connect multiple existing CuratedTokenCategories documents */
  connect?: Maybe<Array<GraphCmsCuratedTokenCategoriesWhereUniqueInput>>;
};

export type GraphCmsCuratedTokenCategoriesCreateOneInlineInput = {
  /** Create and connect one CuratedTokenCategories document */
  create?: Maybe<GraphCmsCuratedTokenCategoriesCreateInput>;
  /** Connect one existing CuratedTokenCategories document */
  connect?: Maybe<GraphCmsCuratedTokenCategoriesWhereUniqueInput>;
};

export type GraphCmsCuratedTokenCategoriesUpdateInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryUpdateInput>;
};

export type GraphCmsCuratedTokenCategoriesUpdateManyInlineInput = {
  /** Create and connect multiple CuratedTokenCategories documents */
  create?: Maybe<Array<GraphCmsCuratedTokenCategoriesCreateInput>>;
  /** Connect multiple existing CuratedTokenCategories documents */
  connect?: Maybe<Array<GraphCmsCuratedTokenCategoriesConnectInput>>;
  /** Override currently-connected documents with multiple existing CuratedTokenCategories documents */
  set?: Maybe<Array<GraphCmsCuratedTokenCategoriesWhereUniqueInput>>;
  /** Update multiple CuratedTokenCategories documents */
  update?: Maybe<Array<GraphCmsCuratedTokenCategoriesUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CuratedTokenCategories documents */
  upsert?: Maybe<Array<GraphCmsCuratedTokenCategoriesUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple CuratedTokenCategories documents */
  disconnect?: Maybe<Array<GraphCmsCuratedTokenCategoriesWhereUniqueInput>>;
  /** Delete multiple CuratedTokenCategories documents */
  delete?: Maybe<Array<GraphCmsCuratedTokenCategoriesWhereUniqueInput>>;
};

export type GraphCmsCuratedTokenCategoriesUpdateManyWithNestedWhereInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryUpdateManyWithNestedWhereInput>;
};

export type GraphCmsCuratedTokenCategoriesUpdateOneInlineInput = {
  /** Create and connect one CuratedTokenCategories document */
  create?: Maybe<GraphCmsCuratedTokenCategoriesCreateInput>;
  /** Update single CuratedTokenCategories document */
  update?: Maybe<GraphCmsCuratedTokenCategoriesUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CuratedTokenCategories document */
  upsert?: Maybe<GraphCmsCuratedTokenCategoriesUpsertWithNestedWhereUniqueInput>;
  /** Connect existing CuratedTokenCategories document */
  connect?: Maybe<GraphCmsCuratedTokenCategoriesWhereUniqueInput>;
  /** Disconnect currently connected CuratedTokenCategories document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected CuratedTokenCategories document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsCuratedTokenCategoriesUpdateWithNestedWhereUniqueInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryUpdateWithNestedWhereUniqueInput>;
};

export type GraphCmsCuratedTokenCategoriesUpsertWithNestedWhereUniqueInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryUpsertWithNestedWhereUniqueInput>;
};

export type GraphCmsCuratedTokenCategoriesWhereInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryWhereInput>;
};

export type GraphCmsCuratedTokenCategoriesWhereUniqueInput = {
  TokenCategory?: Maybe<GraphCmsTokenCategoryWhereUniqueInput>;
};

export type GraphCmsCuratedTokenConnectInput = {
  /** Document to connect */
  where: GraphCmsCuratedTokenWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsCuratedTokenConnection = {
  __typename?: 'CuratedTokenConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsCuratedTokenEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsCuratedTokenCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title: Scalars['String'];
  tokenId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  ckzwt85ay413r01z8es1igvwr?: Maybe<GraphCmsCurationCreateManyInlineInput>;
  cl0qk03vca8g601w7dbr725lb?: Maybe<GraphCmsTokenFeedCreateManyInlineInput>;
  tokenCategories?: Maybe<GraphCmsTokenCategoryCreateManyInlineInput>;
  curationType: GraphCmsCurationType;
  categories?: Maybe<GraphCmsCuratedTokenCategoriesCreateManyInlineInput>;
};

export type GraphCmsCuratedTokenCreateManyInlineInput = {
  /** Create and connect multiple existing CuratedToken documents */
  create?: Maybe<Array<GraphCmsCuratedTokenCreateInput>>;
  /** Connect multiple existing CuratedToken documents */
  connect?: Maybe<Array<GraphCmsCuratedTokenWhereUniqueInput>>;
};

export type GraphCmsCuratedTokenCreateOneInlineInput = {
  /** Create and connect one CuratedToken document */
  create?: Maybe<GraphCmsCuratedTokenCreateInput>;
  /** Connect one existing CuratedToken document */
  connect?: Maybe<GraphCmsCuratedTokenWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsCuratedTokenEdge = {
  __typename?: 'CuratedTokenEdge';
  /** The item at the end of the edge. */
  node: GraphCmsCuratedToken;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsCuratedTokenManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsCuratedTokenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsCuratedTokenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsCuratedTokenWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  tokenId_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  tokenId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  tokenId_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  tokenId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  tokenId_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  tokenId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  tokenId_gte?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  contract_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  contract_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  contract_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  contract_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  contract_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  contract_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  contract_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  contract_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  contract_not_ends_with?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  link_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  link_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  link_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  link_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  link_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  link_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  link_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  link_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  link_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  tokenCategories_every?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_some?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_none?: Maybe<GraphCmsTokenCategoryWhereInput>;
  curationType?: Maybe<GraphCmsCurationType>;
  /** All values that are not equal to given value. */
  curationType_not?: Maybe<GraphCmsCurationType>;
  /** All values that are contained in given list. */
  curationType_in?: Maybe<Array<GraphCmsCurationType>>;
  /** All values that are not contained in given list. */
  curationType_not_in?: Maybe<Array<GraphCmsCurationType>>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsCuratedTokenOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  TokenIdAsc = 'tokenId_ASC',
  TokenIdDesc = 'tokenId_DESC',
  ContractAsc = 'contract_ASC',
  ContractDesc = 'contract_DESC',
  LinkAsc = 'link_ASC',
  LinkDesc = 'link_DESC',
  CurationTypeAsc = 'curationType_ASC',
  CurationTypeDesc = 'curationType_DESC',
}

export type GraphCmsCuratedTokenUpdateInput = {
  title?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  ckzwt85ay413r01z8es1igvwr?: Maybe<GraphCmsCurationUpdateManyInlineInput>;
  cl0qk03vca8g601w7dbr725lb?: Maybe<GraphCmsTokenFeedUpdateManyInlineInput>;
  tokenCategories?: Maybe<GraphCmsTokenCategoryUpdateManyInlineInput>;
  curationType?: Maybe<GraphCmsCurationType>;
  categories?: Maybe<GraphCmsCuratedTokenCategoriesUpdateManyInlineInput>;
};

export type GraphCmsCuratedTokenUpdateManyInlineInput = {
  /** Create and connect multiple CuratedToken documents */
  create?: Maybe<Array<GraphCmsCuratedTokenCreateInput>>;
  /** Connect multiple existing CuratedToken documents */
  connect?: Maybe<Array<GraphCmsCuratedTokenConnectInput>>;
  /** Override currently-connected documents with multiple existing CuratedToken documents */
  set?: Maybe<Array<GraphCmsCuratedTokenWhereUniqueInput>>;
  /** Update multiple CuratedToken documents */
  update?: Maybe<Array<GraphCmsCuratedTokenUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple CuratedToken documents */
  upsert?: Maybe<Array<GraphCmsCuratedTokenUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple CuratedToken documents */
  disconnect?: Maybe<Array<GraphCmsCuratedTokenWhereUniqueInput>>;
  /** Delete multiple CuratedToken documents */
  delete?: Maybe<Array<GraphCmsCuratedTokenWhereUniqueInput>>;
};

export type GraphCmsCuratedTokenUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  curationType?: Maybe<GraphCmsCurationType>;
};

export type GraphCmsCuratedTokenUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsCuratedTokenWhereInput;
  /** Update many input */
  data: GraphCmsCuratedTokenUpdateManyInput;
};

export type GraphCmsCuratedTokenUpdateOneInlineInput = {
  /** Create and connect one CuratedToken document */
  create?: Maybe<GraphCmsCuratedTokenCreateInput>;
  /** Update single CuratedToken document */
  update?: Maybe<GraphCmsCuratedTokenUpdateWithNestedWhereUniqueInput>;
  /** Upsert single CuratedToken document */
  upsert?: Maybe<GraphCmsCuratedTokenUpsertWithNestedWhereUniqueInput>;
  /** Connect existing CuratedToken document */
  connect?: Maybe<GraphCmsCuratedTokenWhereUniqueInput>;
  /** Disconnect currently connected CuratedToken document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected CuratedToken document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsCuratedTokenUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsCuratedTokenWhereUniqueInput;
  /** Document to update */
  data: GraphCmsCuratedTokenUpdateInput;
};

export type GraphCmsCuratedTokenUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsCuratedTokenCreateInput;
  /** Update document if it exists */
  update: GraphCmsCuratedTokenUpdateInput;
};

export type GraphCmsCuratedTokenUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsCuratedTokenWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsCuratedTokenUpsertInput;
};

/** Identifies documents */
export type GraphCmsCuratedTokenWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsCuratedTokenWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsCuratedTokenWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsCuratedTokenWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['Int']>;
  /** All values that are not equal to given value. */
  tokenId_not?: Maybe<Scalars['Int']>;
  /** All values that are contained in given list. */
  tokenId_in?: Maybe<Array<Scalars['Int']>>;
  /** All values that are not contained in given list. */
  tokenId_not_in?: Maybe<Array<Scalars['Int']>>;
  /** All values less than the given value. */
  tokenId_lt?: Maybe<Scalars['Int']>;
  /** All values less than or equal the given value. */
  tokenId_lte?: Maybe<Scalars['Int']>;
  /** All values greater than the given value. */
  tokenId_gt?: Maybe<Scalars['Int']>;
  /** All values greater than or equal the given value. */
  tokenId_gte?: Maybe<Scalars['Int']>;
  contract?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  contract_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  contract_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  contract_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  contract_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  contract_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  contract_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  contract_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  contract_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  contract_not_ends_with?: Maybe<Scalars['String']>;
  link?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  link_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  link_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  link_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  link_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  link_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  link_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  link_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  link_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  link_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  tokenCategories_every?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_some?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_none?: Maybe<GraphCmsTokenCategoryWhereInput>;
  curationType?: Maybe<GraphCmsCurationType>;
  /** All values that are not equal to given value. */
  curationType_not?: Maybe<GraphCmsCurationType>;
  /** All values that are contained in given list. */
  curationType_in?: Maybe<Array<GraphCmsCurationType>>;
  /** All values that are not contained in given list. */
  curationType_not_in?: Maybe<Array<GraphCmsCurationType>>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References CuratedToken record uniquely */
export type GraphCmsCuratedTokenWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GraphCmsCuration = GraphCmsNode & {
  __typename?: 'Curation';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsCuration>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  randomise: Scalars['Boolean'];
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  curatedTokens: Array<GraphCmsCuratedToken>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of Curation versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsCurationDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsCurationCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCurationUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCurationPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCurationCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedTokenOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCurationScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsCurationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsCurationConnectInput = {
  /** Document to connect */
  where: GraphCmsCurationWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsCurationConnection = {
  __typename?: 'CurationConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsCurationEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsCurationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  randomise: Scalars['Boolean'];
  curatedTokens?: Maybe<GraphCmsCuratedTokenCreateManyInlineInput>;
};

export type GraphCmsCurationCreateManyInlineInput = {
  /** Create and connect multiple existing Curation documents */
  create?: Maybe<Array<GraphCmsCurationCreateInput>>;
  /** Connect multiple existing Curation documents */
  connect?: Maybe<Array<GraphCmsCurationWhereUniqueInput>>;
};

export type GraphCmsCurationCreateOneInlineInput = {
  /** Create and connect one Curation document */
  create?: Maybe<GraphCmsCurationCreateInput>;
  /** Connect one existing Curation document */
  connect?: Maybe<GraphCmsCurationWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsCurationEdge = {
  __typename?: 'CurationEdge';
  /** The item at the end of the edge. */
  node: GraphCmsCuration;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsCurationManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsCurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsCurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsCurationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>;
  randomise?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  randomise_not?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  curatedTokens_every?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_some?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_none?: Maybe<GraphCmsCuratedTokenWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsCurationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
  RandomiseAsc = 'randomise_ASC',
  RandomiseDesc = 'randomise_DESC',
}

export enum GraphCmsCurationType {
  ObjktCurated = 'OBJKT_CURATED',
  PartnerCurated = 'PARTNER_CURATED',
}

export type GraphCmsCurationUpdateInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  randomise?: Maybe<Scalars['Boolean']>;
  curatedTokens?: Maybe<GraphCmsCuratedTokenUpdateManyInlineInput>;
};

export type GraphCmsCurationUpdateManyInlineInput = {
  /** Create and connect multiple Curation documents */
  create?: Maybe<Array<GraphCmsCurationCreateInput>>;
  /** Connect multiple existing Curation documents */
  connect?: Maybe<Array<GraphCmsCurationConnectInput>>;
  /** Override currently-connected documents with multiple existing Curation documents */
  set?: Maybe<Array<GraphCmsCurationWhereUniqueInput>>;
  /** Update multiple Curation documents */
  update?: Maybe<Array<GraphCmsCurationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Curation documents */
  upsert?: Maybe<Array<GraphCmsCurationUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Curation documents */
  disconnect?: Maybe<Array<GraphCmsCurationWhereUniqueInput>>;
  /** Delete multiple Curation documents */
  delete?: Maybe<Array<GraphCmsCurationWhereUniqueInput>>;
};

export type GraphCmsCurationUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  randomise?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsCurationUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsCurationWhereInput;
  /** Update many input */
  data: GraphCmsCurationUpdateManyInput;
};

export type GraphCmsCurationUpdateOneInlineInput = {
  /** Create and connect one Curation document */
  create?: Maybe<GraphCmsCurationCreateInput>;
  /** Update single Curation document */
  update?: Maybe<GraphCmsCurationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Curation document */
  upsert?: Maybe<GraphCmsCurationUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Curation document */
  connect?: Maybe<GraphCmsCurationWhereUniqueInput>;
  /** Disconnect currently connected Curation document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Curation document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsCurationUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsCurationWhereUniqueInput;
  /** Document to update */
  data: GraphCmsCurationUpdateInput;
};

export type GraphCmsCurationUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsCurationCreateInput;
  /** Update document if it exists */
  update: GraphCmsCurationUpdateInput;
};

export type GraphCmsCurationUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsCurationWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsCurationUpsertInput;
};

/** Identifies documents */
export type GraphCmsCurationWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsCurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsCurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsCurationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>;
  randomise?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  randomise_not?: Maybe<Scalars['Boolean']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  curatedTokens_every?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_some?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_none?: Maybe<GraphCmsCuratedTokenWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References Curation record uniquely */
export type GraphCmsCurationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export enum GraphCmsDocumentFileTypes {
  Jpg = 'jpg',
  Odp = 'odp',
  Ods = 'ods',
  Odt = 'odt',
  Png = 'png',
  Svg = 'svg',
  Txt = 'txt',
  Webp = 'webp',
  Docx = 'docx',
  Pdf = 'pdf',
  Html = 'html',
  Doc = 'doc',
  Xlsx = 'xlsx',
  Xls = 'xls',
  Pptx = 'pptx',
  Ppt = 'ppt',
}

export type GraphCmsDocumentOutputInput = {
  /**
   * Transforms a document into a desired file type.
   * See this matrix for format support:
   *
   * PDF:	jpg, odp, ods, odt, png, svg, txt, and webp
   * DOC:	docx, html, jpg, odt, pdf, png, svg, txt, and webp
   * DOCX:	doc, html, jpg, odt, pdf, png, svg, txt, and webp
   * ODT:	doc, docx, html, jpg, pdf, png, svg, txt, and webp
   * XLS:	jpg, pdf, ods, png, svg, xlsx, and webp
   * XLSX:	jpg, pdf, ods, png, svg, xls, and webp
   * ODS:	jpg, pdf, png, xls, svg, xlsx, and webp
   * PPT:	jpg, odp, pdf, png, svg, pptx, and webp
   * PPTX:	jpg, odp, pdf, png, svg, ppt, and webp
   * ODP:	jpg, pdf, png, ppt, svg, pptx, and webp
   * BMP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * GIF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * JPG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * PNG:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * WEBP:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * TIFF:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * AI:	    jpg, odp, ods, odt, pdf, png, svg, and webp
   * PSD:	jpg, odp, ods, odt, pdf, png, svg, and webp
   * SVG:	jpg, odp, ods, odt, pdf, png, and webp
   * HTML:	jpg, odt, pdf, svg, txt, and webp
   * TXT:	jpg, html, odt, pdf, svg, and webp
   */
  format?: Maybe<GraphCmsDocumentFileTypes>;
};

/** Transformations for Documents */
export type GraphCmsDocumentTransformationInput = {
  /** Changes the output for the file. */
  output?: Maybe<GraphCmsDocumentOutputInput>;
};

export type GraphCmsDocumentVersion = {
  __typename?: 'DocumentVersion';
  id: Scalars['ID'];
  stage: GraphCmsStage;
  revision: Scalars['Int'];
  createdAt: Scalars['DateTime'];
  data?: Maybe<Scalars['Json']>;
};

export type GraphCmsFeaturedCategoryCuration = GraphCmsNode & {
  __typename?: 'FeaturedCategoryCuration';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsFeaturedCategoryCuration>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Used in GraphCMS UI for identification */
  title?: Maybe<Scalars['String']>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  tokenCategories: Array<GraphCmsTokenCategory>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of FeaturedCategoryCuration versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsFeaturedCategoryCurationDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsFeaturedCategoryCurationCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsFeaturedCategoryCurationUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsFeaturedCategoryCurationPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsFeaturedCategoryCurationTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryWhereInput>;
  orderBy?: Maybe<GraphCmsTokenCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsFeaturedCategoryCurationScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsFeaturedCategoryCurationHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsFeaturedCategoryCurationConnectInput = {
  /** Document to connect */
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsFeaturedCategoryCurationConnection = {
  __typename?: 'FeaturedCategoryCurationConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsFeaturedCategoryCurationEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsFeaturedCategoryCurationCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  tokenCategories?: Maybe<GraphCmsTokenCategoryCreateManyInlineInput>;
};

export type GraphCmsFeaturedCategoryCurationCreateManyInlineInput = {
  /** Create and connect multiple existing FeaturedCategoryCuration documents */
  create?: Maybe<Array<GraphCmsFeaturedCategoryCurationCreateInput>>;
  /** Connect multiple existing FeaturedCategoryCuration documents */
  connect?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereUniqueInput>>;
};

export type GraphCmsFeaturedCategoryCurationCreateOneInlineInput = {
  /** Create and connect one FeaturedCategoryCuration document */
  create?: Maybe<GraphCmsFeaturedCategoryCurationCreateInput>;
  /** Connect one existing FeaturedCategoryCuration document */
  connect?: Maybe<GraphCmsFeaturedCategoryCurationWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsFeaturedCategoryCurationEdge = {
  __typename?: 'FeaturedCategoryCurationEdge';
  /** The item at the end of the edge. */
  node: GraphCmsFeaturedCategoryCuration;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsFeaturedCategoryCurationManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  tokenCategories_every?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_some?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_none?: Maybe<GraphCmsTokenCategoryWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsFeaturedCategoryCurationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type GraphCmsFeaturedCategoryCurationUpdateInput = {
  title?: Maybe<Scalars['String']>;
  tokenCategories?: Maybe<GraphCmsTokenCategoryUpdateManyInlineInput>;
};

export type GraphCmsFeaturedCategoryCurationUpdateManyInlineInput = {
  /** Create and connect multiple FeaturedCategoryCuration documents */
  create?: Maybe<Array<GraphCmsFeaturedCategoryCurationCreateInput>>;
  /** Connect multiple existing FeaturedCategoryCuration documents */
  connect?: Maybe<Array<GraphCmsFeaturedCategoryCurationConnectInput>>;
  /** Override currently-connected documents with multiple existing FeaturedCategoryCuration documents */
  set?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereUniqueInput>>;
  /** Update multiple FeaturedCategoryCuration documents */
  update?: Maybe<Array<GraphCmsFeaturedCategoryCurationUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple FeaturedCategoryCuration documents */
  upsert?: Maybe<Array<GraphCmsFeaturedCategoryCurationUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple FeaturedCategoryCuration documents */
  disconnect?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereUniqueInput>>;
  /** Delete multiple FeaturedCategoryCuration documents */
  delete?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereUniqueInput>>;
};

export type GraphCmsFeaturedCategoryCurationUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
};

export type GraphCmsFeaturedCategoryCurationUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsFeaturedCategoryCurationWhereInput;
  /** Update many input */
  data: GraphCmsFeaturedCategoryCurationUpdateManyInput;
};

export type GraphCmsFeaturedCategoryCurationUpdateOneInlineInput = {
  /** Create and connect one FeaturedCategoryCuration document */
  create?: Maybe<GraphCmsFeaturedCategoryCurationCreateInput>;
  /** Update single FeaturedCategoryCuration document */
  update?: Maybe<GraphCmsFeaturedCategoryCurationUpdateWithNestedWhereUniqueInput>;
  /** Upsert single FeaturedCategoryCuration document */
  upsert?: Maybe<GraphCmsFeaturedCategoryCurationUpsertWithNestedWhereUniqueInput>;
  /** Connect existing FeaturedCategoryCuration document */
  connect?: Maybe<GraphCmsFeaturedCategoryCurationWhereUniqueInput>;
  /** Disconnect currently connected FeaturedCategoryCuration document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected FeaturedCategoryCuration document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsFeaturedCategoryCurationUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  /** Document to update */
  data: GraphCmsFeaturedCategoryCurationUpdateInput;
};

export type GraphCmsFeaturedCategoryCurationUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsFeaturedCategoryCurationCreateInput;
  /** Update document if it exists */
  update: GraphCmsFeaturedCategoryCurationUpdateInput;
};

export type GraphCmsFeaturedCategoryCurationUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsFeaturedCategoryCurationUpsertInput;
};

/** Identifies documents */
export type GraphCmsFeaturedCategoryCurationWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsFeaturedCategoryCurationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  tokenCategories_every?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_some?: Maybe<GraphCmsTokenCategoryWhereInput>;
  tokenCategories_none?: Maybe<GraphCmsTokenCategoryWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References FeaturedCategoryCuration record uniquely */
export type GraphCmsFeaturedCategoryCurationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export enum GraphCmsImageFit {
  /** Resizes the image to fit within the specified parameters without distorting, cropping, or changing the aspect ratio. */
  Clip = 'clip',
  /** Resizes the image to fit the specified parameters exactly by removing any parts of the image that don't fit within the boundaries. */
  Crop = 'crop',
  /** Resizes the image to fit the specified parameters exactly by scaling the image to the desired size. The aspect ratio of the image is not respected and the image can be distorted using this method. */
  Scale = 'scale',
  /** Resizes the image to fit within the parameters, but as opposed to 'fit:clip' will not scale the image if the image is smaller than the output size. */
  Max = 'max',
}

export type GraphCmsImageResizeInput = {
  /** The width in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  width?: Maybe<Scalars['Int']>;
  /** The height in pixels to resize the image to. The value must be an integer from 1 to 10000. */
  height?: Maybe<Scalars['Int']>;
  /** The default value for the fit parameter is fit:clip. */
  fit?: Maybe<GraphCmsImageFit>;
};

/** Transformations for Images */
export type GraphCmsImageTransformationInput = {
  /** Resizes the image */
  resize?: Maybe<GraphCmsImageResizeInput>;
};

/** Locale system enumeration */
export enum GraphCmsLocale {
  /** System locale */
  En = 'en',
}

/** Representing a geolocation point with latitude and longitude */
export type GraphCmsLocation = {
  __typename?: 'Location';
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
  distance: Scalars['Float'];
};

/** Representing a geolocation point with latitude and longitude */
export type GraphCmsLocationDistanceArgs = {
  from: GraphCmsLocationInput;
};

/** Input for a geolocation point with latitude and longitude */
export type GraphCmsLocationInput = {
  latitude: Scalars['Float'];
  longitude: Scalars['Float'];
};

export type GraphCmsMutation = {
  __typename?: 'Mutation';
  /** Create one featuredCategoryCuration */
  createFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Update one featuredCategoryCuration */
  updateFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Delete one featuredCategoryCuration from _all_ existing stages. Returns deleted document. */
  deleteFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Upsert one featuredCategoryCuration */
  upsertFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Publish one featuredCategoryCuration */
  publishFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Unpublish one featuredCategoryCuration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Update many FeaturedCategoryCuration documents */
  updateManyFeaturedCategoryCurationsConnection: GraphCmsFeaturedCategoryCurationConnection;
  /** Delete many FeaturedCategoryCuration documents, return deleted documents */
  deleteManyFeaturedCategoryCurationsConnection: GraphCmsFeaturedCategoryCurationConnection;
  /** Publish many FeaturedCategoryCuration documents */
  publishManyFeaturedCategoryCurationsConnection: GraphCmsFeaturedCategoryCurationConnection;
  /** Find many FeaturedCategoryCuration documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyFeaturedCategoryCurationsConnection: GraphCmsFeaturedCategoryCurationConnection;
  /**
   * Update many featuredCategoryCurations
   * @deprecated Please use the new paginated many mutation (updateManyFeaturedCategoryCurationsConnection)
   */
  updateManyFeaturedCategoryCurations: GraphCmsBatchPayload;
  /**
   * Delete many FeaturedCategoryCuration documents
   * @deprecated Please use the new paginated many mutation (deleteManyFeaturedCategoryCurationsConnection)
   */
  deleteManyFeaturedCategoryCurations: GraphCmsBatchPayload;
  /**
   * Publish many FeaturedCategoryCuration documents
   * @deprecated Please use the new paginated many mutation (publishManyFeaturedCategoryCurationsConnection)
   */
  publishManyFeaturedCategoryCurations: GraphCmsBatchPayload;
  /**
   * Unpublish many FeaturedCategoryCuration documents
   * @deprecated Please use the new paginated many mutation (unpublishManyFeaturedCategoryCurationsConnection)
   */
  unpublishManyFeaturedCategoryCurations: GraphCmsBatchPayload;
  /** Schedule to publish one featuredCategoryCuration */
  schedulePublishFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Unpublish one featuredCategoryCuration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishFeaturedCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Create one tokenFeed */
  createTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Update one tokenFeed */
  updateTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Delete one tokenFeed from _all_ existing stages. Returns deleted document. */
  deleteTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Upsert one tokenFeed */
  upsertTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Publish one tokenFeed */
  publishTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Unpublish one tokenFeed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Update many TokenFeed documents */
  updateManyTokenFeedsConnection: GraphCmsTokenFeedConnection;
  /** Delete many TokenFeed documents, return deleted documents */
  deleteManyTokenFeedsConnection: GraphCmsTokenFeedConnection;
  /** Publish many TokenFeed documents */
  publishManyTokenFeedsConnection: GraphCmsTokenFeedConnection;
  /** Find many TokenFeed documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTokenFeedsConnection: GraphCmsTokenFeedConnection;
  /**
   * Update many tokenFeeds
   * @deprecated Please use the new paginated many mutation (updateManyTokenFeedsConnection)
   */
  updateManyTokenFeeds: GraphCmsBatchPayload;
  /**
   * Delete many TokenFeed documents
   * @deprecated Please use the new paginated many mutation (deleteManyTokenFeedsConnection)
   */
  deleteManyTokenFeeds: GraphCmsBatchPayload;
  /**
   * Publish many TokenFeed documents
   * @deprecated Please use the new paginated many mutation (publishManyTokenFeedsConnection)
   */
  publishManyTokenFeeds: GraphCmsBatchPayload;
  /**
   * Unpublish many TokenFeed documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTokenFeedsConnection)
   */
  unpublishManyTokenFeeds: GraphCmsBatchPayload;
  /** Schedule to publish one tokenFeed */
  schedulePublishTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Unpublish one tokenFeed from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Create one curatedAuction */
  createCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Update one curatedAuction */
  updateCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Delete one curatedAuction from _all_ existing stages. Returns deleted document. */
  deleteCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Upsert one curatedAuction */
  upsertCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Publish one curatedAuction */
  publishCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Unpublish one curatedAuction from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Update many CuratedAuction documents */
  updateManyCuratedAuctionsConnection: GraphCmsCuratedAuctionConnection;
  /** Delete many CuratedAuction documents, return deleted documents */
  deleteManyCuratedAuctionsConnection: GraphCmsCuratedAuctionConnection;
  /** Publish many CuratedAuction documents */
  publishManyCuratedAuctionsConnection: GraphCmsCuratedAuctionConnection;
  /** Find many CuratedAuction documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCuratedAuctionsConnection: GraphCmsCuratedAuctionConnection;
  /**
   * Update many curatedAuctions
   * @deprecated Please use the new paginated many mutation (updateManyCuratedAuctionsConnection)
   */
  updateManyCuratedAuctions: GraphCmsBatchPayload;
  /**
   * Delete many CuratedAuction documents
   * @deprecated Please use the new paginated many mutation (deleteManyCuratedAuctionsConnection)
   */
  deleteManyCuratedAuctions: GraphCmsBatchPayload;
  /**
   * Publish many CuratedAuction documents
   * @deprecated Please use the new paginated many mutation (publishManyCuratedAuctionsConnection)
   */
  publishManyCuratedAuctions: GraphCmsBatchPayload;
  /**
   * Unpublish many CuratedAuction documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCuratedAuctionsConnection)
   */
  unpublishManyCuratedAuctions: GraphCmsBatchPayload;
  /** Schedule to publish one curatedAuction */
  schedulePublishCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Unpublish one curatedAuction from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCuratedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Create one auctionCuration */
  createAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Update one auctionCuration */
  updateAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Delete one auctionCuration from _all_ existing stages. Returns deleted document. */
  deleteAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Upsert one auctionCuration */
  upsertAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Publish one auctionCuration */
  publishAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Unpublish one auctionCuration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Update many AuctionCuration documents */
  updateManyAuctionCurationsConnection: GraphCmsAuctionCurationConnection;
  /** Delete many AuctionCuration documents, return deleted documents */
  deleteManyAuctionCurationsConnection: GraphCmsAuctionCurationConnection;
  /** Publish many AuctionCuration documents */
  publishManyAuctionCurationsConnection: GraphCmsAuctionCurationConnection;
  /** Find many AuctionCuration documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAuctionCurationsConnection: GraphCmsAuctionCurationConnection;
  /**
   * Update many auctionCurations
   * @deprecated Please use the new paginated many mutation (updateManyAuctionCurationsConnection)
   */
  updateManyAuctionCurations: GraphCmsBatchPayload;
  /**
   * Delete many AuctionCuration documents
   * @deprecated Please use the new paginated many mutation (deleteManyAuctionCurationsConnection)
   */
  deleteManyAuctionCurations: GraphCmsBatchPayload;
  /**
   * Publish many AuctionCuration documents
   * @deprecated Please use the new paginated many mutation (publishManyAuctionCurationsConnection)
   */
  publishManyAuctionCurations: GraphCmsBatchPayload;
  /**
   * Unpublish many AuctionCuration documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAuctionCurationsConnection)
   */
  unpublishManyAuctionCurations: GraphCmsBatchPayload;
  /** Schedule to publish one auctionCuration */
  schedulePublishAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Unpublish one auctionCuration from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAuctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Create one tokenCategory */
  createTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Update one tokenCategory */
  updateTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Delete one tokenCategory from _all_ existing stages. Returns deleted document. */
  deleteTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Upsert one tokenCategory */
  upsertTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Publish one tokenCategory */
  publishTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Unpublish one tokenCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Update many TokenCategory documents */
  updateManyTokenCategoriesConnection: GraphCmsTokenCategoryConnection;
  /** Delete many TokenCategory documents, return deleted documents */
  deleteManyTokenCategoriesConnection: GraphCmsTokenCategoryConnection;
  /** Publish many TokenCategory documents */
  publishManyTokenCategoriesConnection: GraphCmsTokenCategoryConnection;
  /** Find many TokenCategory documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyTokenCategoriesConnection: GraphCmsTokenCategoryConnection;
  /**
   * Update many tokenCategories
   * @deprecated Please use the new paginated many mutation (updateManyTokenCategoriesConnection)
   */
  updateManyTokenCategories: GraphCmsBatchPayload;
  /**
   * Delete many TokenCategory documents
   * @deprecated Please use the new paginated many mutation (deleteManyTokenCategoriesConnection)
   */
  deleteManyTokenCategories: GraphCmsBatchPayload;
  /**
   * Publish many TokenCategory documents
   * @deprecated Please use the new paginated many mutation (publishManyTokenCategoriesConnection)
   */
  publishManyTokenCategories: GraphCmsBatchPayload;
  /**
   * Unpublish many TokenCategory documents
   * @deprecated Please use the new paginated many mutation (unpublishManyTokenCategoriesConnection)
   */
  unpublishManyTokenCategories: GraphCmsBatchPayload;
  /** Schedule to publish one tokenCategory */
  schedulePublishTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Unpublish one tokenCategory from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishTokenCategory?: Maybe<GraphCmsTokenCategory>;
  /**
   * Create one asset
   * @deprecated Asset mutations will be overhauled soon
   */
  createAsset?: Maybe<GraphCmsAsset>;
  /** Update one asset */
  updateAsset?: Maybe<GraphCmsAsset>;
  /** Delete one asset from _all_ existing stages. Returns deleted document. */
  deleteAsset?: Maybe<GraphCmsAsset>;
  /** Upsert one asset */
  upsertAsset?: Maybe<GraphCmsAsset>;
  /** Publish one asset */
  publishAsset?: Maybe<GraphCmsAsset>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishAsset?: Maybe<GraphCmsAsset>;
  /** Update many Asset documents */
  updateManyAssetsConnection: GraphCmsAssetConnection;
  /** Delete many Asset documents, return deleted documents */
  deleteManyAssetsConnection: GraphCmsAssetConnection;
  /** Publish many Asset documents */
  publishManyAssetsConnection: GraphCmsAssetConnection;
  /** Find many Asset documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyAssetsConnection: GraphCmsAssetConnection;
  /**
   * Update many assets
   * @deprecated Please use the new paginated many mutation (updateManyAssetsConnection)
   */
  updateManyAssets: GraphCmsBatchPayload;
  /**
   * Delete many Asset documents
   * @deprecated Please use the new paginated many mutation (deleteManyAssetsConnection)
   */
  deleteManyAssets: GraphCmsBatchPayload;
  /**
   * Publish many Asset documents
   * @deprecated Please use the new paginated many mutation (publishManyAssetsConnection)
   */
  publishManyAssets: GraphCmsBatchPayload;
  /**
   * Unpublish many Asset documents
   * @deprecated Please use the new paginated many mutation (unpublishManyAssetsConnection)
   */
  unpublishManyAssets: GraphCmsBatchPayload;
  /** Schedule to publish one asset */
  schedulePublishAsset?: Maybe<GraphCmsAsset>;
  /** Unpublish one asset from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishAsset?: Maybe<GraphCmsAsset>;
  /** Delete and return scheduled operation */
  deleteScheduledOperation?: Maybe<GraphCmsScheduledOperation>;
  /** Create one scheduledRelease */
  createScheduledRelease?: Maybe<GraphCmsScheduledRelease>;
  /** Update one scheduledRelease */
  updateScheduledRelease?: Maybe<GraphCmsScheduledRelease>;
  /** Delete one scheduledRelease from _all_ existing stages. Returns deleted document. */
  deleteScheduledRelease?: Maybe<GraphCmsScheduledRelease>;
  /** Create one page */
  createPage?: Maybe<GraphCmsPage>;
  /** Update one page */
  updatePage?: Maybe<GraphCmsPage>;
  /** Delete one page from _all_ existing stages. Returns deleted document. */
  deletePage?: Maybe<GraphCmsPage>;
  /** Upsert one page */
  upsertPage?: Maybe<GraphCmsPage>;
  /** Publish one page */
  publishPage?: Maybe<GraphCmsPage>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishPage?: Maybe<GraphCmsPage>;
  /** Update many Page documents */
  updateManyPagesConnection: GraphCmsPageConnection;
  /** Delete many Page documents, return deleted documents */
  deleteManyPagesConnection: GraphCmsPageConnection;
  /** Publish many Page documents */
  publishManyPagesConnection: GraphCmsPageConnection;
  /** Find many Page documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyPagesConnection: GraphCmsPageConnection;
  /**
   * Update many pages
   * @deprecated Please use the new paginated many mutation (updateManyPagesConnection)
   */
  updateManyPages: GraphCmsBatchPayload;
  /**
   * Delete many Page documents
   * @deprecated Please use the new paginated many mutation (deleteManyPagesConnection)
   */
  deleteManyPages: GraphCmsBatchPayload;
  /**
   * Publish many Page documents
   * @deprecated Please use the new paginated many mutation (publishManyPagesConnection)
   */
  publishManyPages: GraphCmsBatchPayload;
  /**
   * Unpublish many Page documents
   * @deprecated Please use the new paginated many mutation (unpublishManyPagesConnection)
   */
  unpublishManyPages: GraphCmsBatchPayload;
  /** Schedule to publish one page */
  schedulePublishPage?: Maybe<GraphCmsPage>;
  /** Unpublish one page from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishPage?: Maybe<GraphCmsPage>;
  /** Create one curation */
  createCuration?: Maybe<GraphCmsCuration>;
  /** Update one curation */
  updateCuration?: Maybe<GraphCmsCuration>;
  /** Delete one curation from _all_ existing stages. Returns deleted document. */
  deleteCuration?: Maybe<GraphCmsCuration>;
  /** Upsert one curation */
  upsertCuration?: Maybe<GraphCmsCuration>;
  /** Publish one curation */
  publishCuration?: Maybe<GraphCmsCuration>;
  /** Unpublish one curation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCuration?: Maybe<GraphCmsCuration>;
  /** Update many Curation documents */
  updateManyCurationsConnection: GraphCmsCurationConnection;
  /** Delete many Curation documents, return deleted documents */
  deleteManyCurationsConnection: GraphCmsCurationConnection;
  /** Publish many Curation documents */
  publishManyCurationsConnection: GraphCmsCurationConnection;
  /** Find many Curation documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCurationsConnection: GraphCmsCurationConnection;
  /**
   * Update many curations
   * @deprecated Please use the new paginated many mutation (updateManyCurationsConnection)
   */
  updateManyCurations: GraphCmsBatchPayload;
  /**
   * Delete many Curation documents
   * @deprecated Please use the new paginated many mutation (deleteManyCurationsConnection)
   */
  deleteManyCurations: GraphCmsBatchPayload;
  /**
   * Publish many Curation documents
   * @deprecated Please use the new paginated many mutation (publishManyCurationsConnection)
   */
  publishManyCurations: GraphCmsBatchPayload;
  /**
   * Unpublish many Curation documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCurationsConnection)
   */
  unpublishManyCurations: GraphCmsBatchPayload;
  /** Schedule to publish one curation */
  schedulePublishCuration?: Maybe<GraphCmsCuration>;
  /** Unpublish one curation from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCuration?: Maybe<GraphCmsCuration>;
  /** Create one curatedToken */
  createCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Update one curatedToken */
  updateCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Delete one curatedToken from _all_ existing stages. Returns deleted document. */
  deleteCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Upsert one curatedToken */
  upsertCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Publish one curatedToken */
  publishCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Unpublish one curatedToken from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  unpublishCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Update many CuratedToken documents */
  updateManyCuratedTokensConnection: GraphCmsCuratedTokenConnection;
  /** Delete many CuratedToken documents, return deleted documents */
  deleteManyCuratedTokensConnection: GraphCmsCuratedTokenConnection;
  /** Publish many CuratedToken documents */
  publishManyCuratedTokensConnection: GraphCmsCuratedTokenConnection;
  /** Find many CuratedToken documents that match criteria in specified stage and unpublish from target stages */
  unpublishManyCuratedTokensConnection: GraphCmsCuratedTokenConnection;
  /**
   * Update many curatedTokens
   * @deprecated Please use the new paginated many mutation (updateManyCuratedTokensConnection)
   */
  updateManyCuratedTokens: GraphCmsBatchPayload;
  /**
   * Delete many CuratedToken documents
   * @deprecated Please use the new paginated many mutation (deleteManyCuratedTokensConnection)
   */
  deleteManyCuratedTokens: GraphCmsBatchPayload;
  /**
   * Publish many CuratedToken documents
   * @deprecated Please use the new paginated many mutation (publishManyCuratedTokensConnection)
   */
  publishManyCuratedTokens: GraphCmsBatchPayload;
  /**
   * Unpublish many CuratedToken documents
   * @deprecated Please use the new paginated many mutation (unpublishManyCuratedTokensConnection)
   */
  unpublishManyCuratedTokens: GraphCmsBatchPayload;
  /** Schedule to publish one curatedToken */
  schedulePublishCuratedToken?: Maybe<GraphCmsCuratedToken>;
  /** Unpublish one curatedToken from selected stages. Unpublish either the complete document with its relations, localizations and base data or specific localizations only. */
  scheduleUnpublishCuratedToken?: Maybe<GraphCmsCuratedToken>;
};

export type GraphCmsMutationCreateFeaturedCategoryCurationArgs = {
  data: GraphCmsFeaturedCategoryCurationCreateInput;
};

export type GraphCmsMutationUpdateFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  data: GraphCmsFeaturedCategoryCurationUpdateInput;
};

export type GraphCmsMutationDeleteFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
};

export type GraphCmsMutationUpsertFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  upsert: GraphCmsFeaturedCategoryCurationUpsertInput;
};

export type GraphCmsMutationPublishFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyFeaturedCategoryCurationsConnectionArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  data: GraphCmsFeaturedCategoryCurationUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyFeaturedCategoryCurationsConnectionArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyFeaturedCategoryCurationsConnectionArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyFeaturedCategoryCurationsConnectionArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyFeaturedCategoryCurationsArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  data: GraphCmsFeaturedCategoryCurationUpdateManyInput;
};

export type GraphCmsMutationDeleteManyFeaturedCategoryCurationsArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
};

export type GraphCmsMutationPublishManyFeaturedCategoryCurationsArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyFeaturedCategoryCurationsArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationCreateTokenFeedArgs = {
  data: GraphCmsTokenFeedCreateInput;
};

export type GraphCmsMutationUpdateTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  data: GraphCmsTokenFeedUpdateInput;
};

export type GraphCmsMutationDeleteTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
};

export type GraphCmsMutationUpsertTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  upsert: GraphCmsTokenFeedUpsertInput;
};

export type GraphCmsMutationPublishTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyTokenFeedsConnectionArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  data: GraphCmsTokenFeedUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyTokenFeedsConnectionArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyTokenFeedsConnectionArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyTokenFeedsConnectionArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyTokenFeedsArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  data: GraphCmsTokenFeedUpdateManyInput;
};

export type GraphCmsMutationDeleteManyTokenFeedsArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
};

export type GraphCmsMutationPublishManyTokenFeedsArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyTokenFeedsArgs = {
  where?: Maybe<GraphCmsTokenFeedManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationCreateCuratedAuctionArgs = {
  data: GraphCmsCuratedAuctionCreateInput;
};

export type GraphCmsMutationUpdateCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  data: GraphCmsCuratedAuctionUpdateInput;
};

export type GraphCmsMutationDeleteCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
};

export type GraphCmsMutationUpsertCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  upsert: GraphCmsCuratedAuctionUpsertInput;
};

export type GraphCmsMutationPublishCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyCuratedAuctionsConnectionArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  data: GraphCmsCuratedAuctionUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyCuratedAuctionsConnectionArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyCuratedAuctionsConnectionArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyCuratedAuctionsConnectionArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyCuratedAuctionsArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  data: GraphCmsCuratedAuctionUpdateManyInput;
};

export type GraphCmsMutationDeleteManyCuratedAuctionsArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
};

export type GraphCmsMutationPublishManyCuratedAuctionsArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyCuratedAuctionsArgs = {
  where?: Maybe<GraphCmsCuratedAuctionManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationCreateAuctionCurationArgs = {
  data: GraphCmsAuctionCurationCreateInput;
};

export type GraphCmsMutationUpdateAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  data: GraphCmsAuctionCurationUpdateInput;
};

export type GraphCmsMutationDeleteAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
};

export type GraphCmsMutationUpsertAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  upsert: GraphCmsAuctionCurationUpsertInput;
};

export type GraphCmsMutationPublishAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyAuctionCurationsConnectionArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  data: GraphCmsAuctionCurationUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyAuctionCurationsConnectionArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyAuctionCurationsConnectionArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyAuctionCurationsConnectionArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyAuctionCurationsArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  data: GraphCmsAuctionCurationUpdateManyInput;
};

export type GraphCmsMutationDeleteManyAuctionCurationsArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
};

export type GraphCmsMutationPublishManyAuctionCurationsArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyAuctionCurationsArgs = {
  where?: Maybe<GraphCmsAuctionCurationManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationCreateTokenCategoryArgs = {
  data: GraphCmsTokenCategoryCreateInput;
};

export type GraphCmsMutationUpdateTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  data: GraphCmsTokenCategoryUpdateInput;
};

export type GraphCmsMutationDeleteTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
};

export type GraphCmsMutationUpsertTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  upsert: GraphCmsTokenCategoryUpsertInput;
};

export type GraphCmsMutationPublishTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyTokenCategoriesConnectionArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  data: GraphCmsTokenCategoryUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyTokenCategoriesConnectionArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyTokenCategoriesConnectionArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyTokenCategoriesConnectionArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  data: GraphCmsTokenCategoryUpdateManyInput;
};

export type GraphCmsMutationDeleteManyTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
};

export type GraphCmsMutationPublishManyTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationCreateAssetArgs = {
  data: GraphCmsAssetCreateInput;
};

export type GraphCmsMutationUpdateAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  data: GraphCmsAssetUpdateInput;
};

export type GraphCmsMutationDeleteAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
};

export type GraphCmsMutationUpsertAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  upsert: GraphCmsAssetUpsertInput;
};

export type GraphCmsMutationPublishAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUpdateManyAssetsConnectionArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  data: GraphCmsAssetUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyAssetsConnectionArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyAssetsConnectionArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUnpublishManyAssetsConnectionArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUpdateManyAssetsArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  data: GraphCmsAssetUpdateManyInput;
};

export type GraphCmsMutationDeleteManyAssetsArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
};

export type GraphCmsMutationPublishManyAssetsArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  to?: Array<GraphCmsStage>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUnpublishManyAssetsArgs = {
  where?: Maybe<GraphCmsAssetManyWhereInput>;
  from?: Array<GraphCmsStage>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationSchedulePublishAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationDeleteScheduledOperationArgs = {
  where: GraphCmsScheduledOperationWhereUniqueInput;
};

export type GraphCmsMutationCreateScheduledReleaseArgs = {
  data: GraphCmsScheduledReleaseCreateInput;
};

export type GraphCmsMutationUpdateScheduledReleaseArgs = {
  where: GraphCmsScheduledReleaseWhereUniqueInput;
  data: GraphCmsScheduledReleaseUpdateInput;
};

export type GraphCmsMutationDeleteScheduledReleaseArgs = {
  where: GraphCmsScheduledReleaseWhereUniqueInput;
};

export type GraphCmsMutationCreatePageArgs = {
  data: GraphCmsPageCreateInput;
};

export type GraphCmsMutationUpdatePageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  data: GraphCmsPageUpdateInput;
};

export type GraphCmsMutationDeletePageArgs = {
  where: GraphCmsPageWhereUniqueInput;
};

export type GraphCmsMutationUpsertPageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  upsert: GraphCmsPageUpsertInput;
};

export type GraphCmsMutationPublishPageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishPageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUpdateManyPagesConnectionArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  data: GraphCmsPageUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyPagesConnectionArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyPagesConnectionArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUnpublishManyPagesConnectionArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUpdateManyPagesArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  data: GraphCmsPageUpdateManyInput;
};

export type GraphCmsMutationDeleteManyPagesArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
};

export type GraphCmsMutationPublishManyPagesArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  to?: Array<GraphCmsStage>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationUnpublishManyPagesArgs = {
  where?: Maybe<GraphCmsPageManyWhereInput>;
  from?: Array<GraphCmsStage>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationSchedulePublishPageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  locales?: Maybe<Array<GraphCmsLocale>>;
  publishBase?: Maybe<Scalars['Boolean']>;
  withDefaultLocale?: Maybe<Scalars['Boolean']>;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishPageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
  unpublishBase?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsMutationCreateCurationArgs = {
  data: GraphCmsCurationCreateInput;
};

export type GraphCmsMutationUpdateCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  data: GraphCmsCurationUpdateInput;
};

export type GraphCmsMutationDeleteCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
};

export type GraphCmsMutationUpsertCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  upsert: GraphCmsCurationUpsertInput;
};

export type GraphCmsMutationPublishCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyCurationsConnectionArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  data: GraphCmsCurationUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyCurationsConnectionArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyCurationsConnectionArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyCurationsConnectionArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyCurationsArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  data: GraphCmsCurationUpdateManyInput;
};

export type GraphCmsMutationDeleteManyCurationsArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
};

export type GraphCmsMutationPublishManyCurationsArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyCurationsArgs = {
  where?: Maybe<GraphCmsCurationManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationCreateCuratedTokenArgs = {
  data: GraphCmsCuratedTokenCreateInput;
};

export type GraphCmsMutationUpdateCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  data: GraphCmsCuratedTokenUpdateInput;
};

export type GraphCmsMutationDeleteCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
};

export type GraphCmsMutationUpsertCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  upsert: GraphCmsCuratedTokenUpsertInput;
};

export type GraphCmsMutationPublishCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUpdateManyCuratedTokensConnectionArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  data: GraphCmsCuratedTokenUpdateManyInput;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationDeleteManyCuratedTokensConnectionArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationPublishManyCuratedTokensConnectionArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  from?: Maybe<GraphCmsStage>;
  to?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUnpublishManyCuratedTokensConnectionArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  stage?: Maybe<GraphCmsStage>;
  from?: Array<GraphCmsStage>;
  skip?: Maybe<Scalars['Int']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  before?: Maybe<Scalars['ID']>;
  after?: Maybe<Scalars['ID']>;
};

export type GraphCmsMutationUpdateManyCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  data: GraphCmsCuratedTokenUpdateManyInput;
};

export type GraphCmsMutationDeleteManyCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
};

export type GraphCmsMutationPublishManyCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  to?: Array<GraphCmsStage>;
};

export type GraphCmsMutationUnpublishManyCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenManyWhereInput>;
  from?: Array<GraphCmsStage>;
};

export type GraphCmsMutationSchedulePublishCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  to?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

export type GraphCmsMutationScheduleUnpublishCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  from?: Array<GraphCmsStage>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  releaseId?: Maybe<Scalars['String']>;
};

/** An object with an ID */
export type GraphCmsNode = {
  /** The id of the object. */
  id: Scalars['ID'];
  /** The Stage of an object */
  stage: GraphCmsStage;
};

export type GraphCmsPage = GraphCmsNode & {
  __typename?: 'Page';
  /** System stage field */
  stage: GraphCmsStage;
  /** System Locale field */
  locale: GraphCmsLocale;
  /** Get the other localizations for this document */
  localizations: Array<GraphCmsPage>;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsPage>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  content?: Maybe<GraphCmsRichText>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of Page versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsPageLocalizationsArgs = {
  locales?: Array<GraphCmsLocale>;
  includeCurrent?: Scalars['Boolean'];
};

export type GraphCmsPageDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsPageCreatedAtArgs = {
  variation?: GraphCmsSystemDateTimeFieldVariation;
};

export type GraphCmsPageUpdatedAtArgs = {
  variation?: GraphCmsSystemDateTimeFieldVariation;
};

export type GraphCmsPagePublishedAtArgs = {
  variation?: GraphCmsSystemDateTimeFieldVariation;
};

export type GraphCmsPageCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsPageUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsPagePublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsPageScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsPageHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsPageConnectInput = {
  /** Document to connect */
  where: GraphCmsPageWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsPageConnection = {
  __typename?: 'PageConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsPageEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsPageCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** content input for default locale (en) */
  content?: Maybe<Scalars['RichTextAST']>;
  /** Inline mutations for managing document localizations excluding the default locale */
  localizations?: Maybe<GraphCmsPageCreateLocalizationsInput>;
};

export type GraphCmsPageCreateLocalizationDataInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  content?: Maybe<Scalars['RichTextAST']>;
};

export type GraphCmsPageCreateLocalizationInput = {
  /** Localization input */
  data: GraphCmsPageCreateLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsPageCreateLocalizationsInput = {
  /** Create localizations for the newly-created document */
  create?: Maybe<Array<GraphCmsPageCreateLocalizationInput>>;
};

export type GraphCmsPageCreateManyInlineInput = {
  /** Create and connect multiple existing Page documents */
  create?: Maybe<Array<GraphCmsPageCreateInput>>;
  /** Connect multiple existing Page documents */
  connect?: Maybe<Array<GraphCmsPageWhereUniqueInput>>;
};

export type GraphCmsPageCreateOneInlineInput = {
  /** Create and connect one Page document */
  create?: Maybe<GraphCmsPageCreateInput>;
  /** Connect one existing Page document */
  connect?: Maybe<GraphCmsPageWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsPageEdge = {
  __typename?: 'PageEdge';
  /** The item at the end of the edge. */
  node: GraphCmsPage;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Information about pagination in a connection. */
export type GraphCmsPageInfo = {
  __typename?: 'PageInfo';
  /** When paginating forwards, are there more items? */
  hasNextPage: Scalars['Boolean'];
  /** When paginating backwards, are there more items? */
  hasPreviousPage: Scalars['Boolean'];
  /** When paginating backwards, the cursor to continue. */
  startCursor?: Maybe<Scalars['String']>;
  /** When paginating forwards, the cursor to continue. */
  endCursor?: Maybe<Scalars['String']>;
  /** Number of items in the current page. */
  pageSize?: Maybe<Scalars['Int']>;
};

/** Identifies documents */
export type GraphCmsPageManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsPageWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsPageOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  SlugAsc = 'slug_ASC',
  SlugDesc = 'slug_DESC',
}

export type GraphCmsPageUpdateInput = {
  title?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** content input for default locale (en) */
  content?: Maybe<Scalars['RichTextAST']>;
  /** Manage document localizations */
  localizations?: Maybe<GraphCmsPageUpdateLocalizationsInput>;
};

export type GraphCmsPageUpdateLocalizationDataInput = {
  content?: Maybe<Scalars['RichTextAST']>;
};

export type GraphCmsPageUpdateLocalizationInput = {
  data: GraphCmsPageUpdateLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsPageUpdateLocalizationsInput = {
  /** Localizations to create */
  create?: Maybe<Array<GraphCmsPageCreateLocalizationInput>>;
  /** Localizations to update */
  update?: Maybe<Array<GraphCmsPageUpdateLocalizationInput>>;
  upsert?: Maybe<Array<GraphCmsPageUpsertLocalizationInput>>;
  /** Localizations to delete */
  delete?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsPageUpdateManyInlineInput = {
  /** Create and connect multiple Page documents */
  create?: Maybe<Array<GraphCmsPageCreateInput>>;
  /** Connect multiple existing Page documents */
  connect?: Maybe<Array<GraphCmsPageConnectInput>>;
  /** Override currently-connected documents with multiple existing Page documents */
  set?: Maybe<Array<GraphCmsPageWhereUniqueInput>>;
  /** Update multiple Page documents */
  update?: Maybe<Array<GraphCmsPageUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple Page documents */
  upsert?: Maybe<Array<GraphCmsPageUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple Page documents */
  disconnect?: Maybe<Array<GraphCmsPageWhereUniqueInput>>;
  /** Delete multiple Page documents */
  delete?: Maybe<Array<GraphCmsPageWhereUniqueInput>>;
};

export type GraphCmsPageUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  /** content input for default locale (en) */
  content?: Maybe<Scalars['RichTextAST']>;
  /** Optional updates to localizations */
  localizations?: Maybe<GraphCmsPageUpdateManyLocalizationsInput>;
};

export type GraphCmsPageUpdateManyLocalizationDataInput = {
  content?: Maybe<Scalars['RichTextAST']>;
};

export type GraphCmsPageUpdateManyLocalizationInput = {
  data: GraphCmsPageUpdateManyLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsPageUpdateManyLocalizationsInput = {
  /** Localizations to update */
  update?: Maybe<Array<GraphCmsPageUpdateManyLocalizationInput>>;
};

export type GraphCmsPageUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsPageWhereInput;
  /** Update many input */
  data: GraphCmsPageUpdateManyInput;
};

export type GraphCmsPageUpdateOneInlineInput = {
  /** Create and connect one Page document */
  create?: Maybe<GraphCmsPageCreateInput>;
  /** Update single Page document */
  update?: Maybe<GraphCmsPageUpdateWithNestedWhereUniqueInput>;
  /** Upsert single Page document */
  upsert?: Maybe<GraphCmsPageUpsertWithNestedWhereUniqueInput>;
  /** Connect existing Page document */
  connect?: Maybe<GraphCmsPageWhereUniqueInput>;
  /** Disconnect currently connected Page document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected Page document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsPageUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsPageWhereUniqueInput;
  /** Document to update */
  data: GraphCmsPageUpdateInput;
};

export type GraphCmsPageUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsPageCreateInput;
  /** Update document if it exists */
  update: GraphCmsPageUpdateInput;
};

export type GraphCmsPageUpsertLocalizationInput = {
  update: GraphCmsPageUpdateLocalizationDataInput;
  create: GraphCmsPageCreateLocalizationDataInput;
  locale: GraphCmsLocale;
};

export type GraphCmsPageUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsPageWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsPageUpsertInput;
};

/** Identifies documents */
export type GraphCmsPageWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsPageWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsPageWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsPageWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  slug_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  slug_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  slug_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  slug_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  slug_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  slug_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  slug_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  slug_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  slug_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References Page record uniquely */
export type GraphCmsPageWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  slug?: Maybe<Scalars['String']>;
};

export type GraphCmsPublishLocaleInput = {
  /** Locales to publish */
  locale: GraphCmsLocale;
  /** Stages to publish selected locales to */
  stages: Array<GraphCmsStage>;
};

export type GraphCmsQuery = {
  __typename?: 'Query';
  /** Fetches an object given its ID */
  node?: Maybe<GraphCmsNode>;
  /** Retrieve multiple featuredCategoryCurations */
  featuredCategoryCurations: Array<GraphCmsFeaturedCategoryCuration>;
  /** Retrieve a single featuredCategoryCuration */
  featuredCategoryCuration?: Maybe<GraphCmsFeaturedCategoryCuration>;
  /** Retrieve multiple featuredCategoryCurations using the Relay connection interface */
  featuredCategoryCurationsConnection: GraphCmsFeaturedCategoryCurationConnection;
  /** Retrieve document version */
  featuredCategoryCurationVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple tokenFeeds */
  tokenFeeds: Array<GraphCmsTokenFeed>;
  /** Retrieve a single tokenFeed */
  tokenFeed?: Maybe<GraphCmsTokenFeed>;
  /** Retrieve multiple tokenFeeds using the Relay connection interface */
  tokenFeedsConnection: GraphCmsTokenFeedConnection;
  /** Retrieve document version */
  tokenFeedVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple curatedAuctions */
  curatedAuctions: Array<GraphCmsCuratedAuction>;
  /** Retrieve a single curatedAuction */
  curatedAuction?: Maybe<GraphCmsCuratedAuction>;
  /** Retrieve multiple curatedAuctions using the Relay connection interface */
  curatedAuctionsConnection: GraphCmsCuratedAuctionConnection;
  /** Retrieve document version */
  curatedAuctionVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple auctionCurations */
  auctionCurations: Array<GraphCmsAuctionCuration>;
  /** Retrieve a single auctionCuration */
  auctionCuration?: Maybe<GraphCmsAuctionCuration>;
  /** Retrieve multiple auctionCurations using the Relay connection interface */
  auctionCurationsConnection: GraphCmsAuctionCurationConnection;
  /** Retrieve document version */
  auctionCurationVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple tokenCategories */
  tokenCategories: Array<GraphCmsTokenCategory>;
  /** Retrieve a single tokenCategory */
  tokenCategory?: Maybe<GraphCmsTokenCategory>;
  /** Retrieve multiple tokenCategories using the Relay connection interface */
  tokenCategoriesConnection: GraphCmsTokenCategoryConnection;
  /** Retrieve document version */
  tokenCategoryVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple users */
  users: Array<GraphCmsUser>;
  /** Retrieve a single user */
  user?: Maybe<GraphCmsUser>;
  /** Retrieve multiple users using the Relay connection interface */
  usersConnection: GraphCmsUserConnection;
  /** Retrieve multiple assets */
  assets: Array<GraphCmsAsset>;
  /** Retrieve a single asset */
  asset?: Maybe<GraphCmsAsset>;
  /** Retrieve multiple assets using the Relay connection interface */
  assetsConnection: GraphCmsAssetConnection;
  /** Retrieve document version */
  assetVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple scheduledOperations */
  scheduledOperations: Array<GraphCmsScheduledOperation>;
  /** Retrieve a single scheduledOperation */
  scheduledOperation?: Maybe<GraphCmsScheduledOperation>;
  /** Retrieve multiple scheduledOperations using the Relay connection interface */
  scheduledOperationsConnection: GraphCmsScheduledOperationConnection;
  /** Retrieve multiple scheduledReleases */
  scheduledReleases: Array<GraphCmsScheduledRelease>;
  /** Retrieve a single scheduledRelease */
  scheduledRelease?: Maybe<GraphCmsScheduledRelease>;
  /** Retrieve multiple scheduledReleases using the Relay connection interface */
  scheduledReleasesConnection: GraphCmsScheduledReleaseConnection;
  /** Retrieve multiple pages */
  pages: Array<GraphCmsPage>;
  /** Retrieve a single page */
  page?: Maybe<GraphCmsPage>;
  /** Retrieve multiple pages using the Relay connection interface */
  pagesConnection: GraphCmsPageConnection;
  /** Retrieve document version */
  pageVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple curations */
  curations: Array<GraphCmsCuration>;
  /** Retrieve a single curation */
  curation?: Maybe<GraphCmsCuration>;
  /** Retrieve multiple curations using the Relay connection interface */
  curationsConnection: GraphCmsCurationConnection;
  /** Retrieve document version */
  curationVersion?: Maybe<GraphCmsDocumentVersion>;
  /** Retrieve multiple curatedTokens */
  curatedTokens: Array<GraphCmsCuratedToken>;
  /** Retrieve a single curatedToken */
  curatedToken?: Maybe<GraphCmsCuratedToken>;
  /** Retrieve multiple curatedTokens using the Relay connection interface */
  curatedTokensConnection: GraphCmsCuratedTokenConnection;
  /** Retrieve document version */
  curatedTokenVersion?: Maybe<GraphCmsDocumentVersion>;
};

export type GraphCmsQueryNodeArgs = {
  id: Scalars['ID'];
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryFeaturedCategoryCurationsArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationWhereInput>;
  orderBy?: Maybe<GraphCmsFeaturedCategoryCurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryFeaturedCategoryCurationArgs = {
  where: GraphCmsFeaturedCategoryCurationWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryFeaturedCategoryCurationsConnectionArgs = {
  where?: Maybe<GraphCmsFeaturedCategoryCurationWhereInput>;
  orderBy?: Maybe<GraphCmsFeaturedCategoryCurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryFeaturedCategoryCurationVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryTokenFeedsArgs = {
  where?: Maybe<GraphCmsTokenFeedWhereInput>;
  orderBy?: Maybe<GraphCmsTokenFeedOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryTokenFeedArgs = {
  where: GraphCmsTokenFeedWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryTokenFeedsConnectionArgs = {
  where?: Maybe<GraphCmsTokenFeedWhereInput>;
  orderBy?: Maybe<GraphCmsTokenFeedOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryTokenFeedVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryCuratedAuctionsArgs = {
  where?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedAuctionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCuratedAuctionArgs = {
  where: GraphCmsCuratedAuctionWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCuratedAuctionsConnectionArgs = {
  where?: Maybe<GraphCmsCuratedAuctionWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedAuctionOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCuratedAuctionVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryAuctionCurationsArgs = {
  where?: Maybe<GraphCmsAuctionCurationWhereInput>;
  orderBy?: Maybe<GraphCmsAuctionCurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAuctionCurationArgs = {
  where: GraphCmsAuctionCurationWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAuctionCurationsConnectionArgs = {
  where?: Maybe<GraphCmsAuctionCurationWhereInput>;
  orderBy?: Maybe<GraphCmsAuctionCurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAuctionCurationVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryTokenCategoriesArgs = {
  where?: Maybe<GraphCmsTokenCategoryWhereInput>;
  orderBy?: Maybe<GraphCmsTokenCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryTokenCategoryArgs = {
  where: GraphCmsTokenCategoryWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryTokenCategoriesConnectionArgs = {
  where?: Maybe<GraphCmsTokenCategoryWhereInput>;
  orderBy?: Maybe<GraphCmsTokenCategoryOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryTokenCategoryVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryUsersArgs = {
  where?: Maybe<GraphCmsUserWhereInput>;
  orderBy?: Maybe<GraphCmsUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryUserArgs = {
  where: GraphCmsUserWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryUsersConnectionArgs = {
  where?: Maybe<GraphCmsUserWhereInput>;
  orderBy?: Maybe<GraphCmsUserOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAssetsArgs = {
  where?: Maybe<GraphCmsAssetWhereInput>;
  orderBy?: Maybe<GraphCmsAssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAssetArgs = {
  where: GraphCmsAssetWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAssetsConnectionArgs = {
  where?: Maybe<GraphCmsAssetWhereInput>;
  orderBy?: Maybe<GraphCmsAssetOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryAssetVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryScheduledOperationsArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  orderBy?: Maybe<GraphCmsScheduledOperationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryScheduledOperationArgs = {
  where: GraphCmsScheduledOperationWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryScheduledOperationsConnectionArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  orderBy?: Maybe<GraphCmsScheduledOperationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryScheduledReleasesArgs = {
  where?: Maybe<GraphCmsScheduledReleaseWhereInput>;
  orderBy?: Maybe<GraphCmsScheduledReleaseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryScheduledReleaseArgs = {
  where: GraphCmsScheduledReleaseWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryScheduledReleasesConnectionArgs = {
  where?: Maybe<GraphCmsScheduledReleaseWhereInput>;
  orderBy?: Maybe<GraphCmsScheduledReleaseOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryPagesArgs = {
  where?: Maybe<GraphCmsPageWhereInput>;
  orderBy?: Maybe<GraphCmsPageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryPageArgs = {
  where: GraphCmsPageWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryPagesConnectionArgs = {
  where?: Maybe<GraphCmsPageWhereInput>;
  orderBy?: Maybe<GraphCmsPageOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryPageVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryCurationsArgs = {
  where?: Maybe<GraphCmsCurationWhereInput>;
  orderBy?: Maybe<GraphCmsCurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCurationArgs = {
  where: GraphCmsCurationWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCurationsConnectionArgs = {
  where?: Maybe<GraphCmsCurationWhereInput>;
  orderBy?: Maybe<GraphCmsCurationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCurationVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

export type GraphCmsQueryCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedTokenOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCuratedTokenArgs = {
  where: GraphCmsCuratedTokenWhereUniqueInput;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCuratedTokensConnectionArgs = {
  where?: Maybe<GraphCmsCuratedTokenWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedTokenOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  stage?: GraphCmsStage;
  locales?: Array<GraphCmsLocale>;
};

export type GraphCmsQueryCuratedTokenVersionArgs = {
  where: GraphCmsVersionWhereInput;
};

/** Representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type GraphCmsRgba = {
  __typename?: 'RGBA';
  r: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  b: Scalars['RGBAHue'];
  a: Scalars['RGBATransparency'];
};

/** Input type representing a RGBA color value: https://developer.mozilla.org/en-US/docs/Web/CSS/color_value#rgb()_and_rgba() */
export type GraphCmsRgbaInput = {
  r: Scalars['RGBAHue'];
  g: Scalars['RGBAHue'];
  b: Scalars['RGBAHue'];
  a: Scalars['RGBATransparency'];
};

/** Custom type representing a rich text value comprising of raw rich text ast, html, markdown and text values */
export type GraphCmsRichText = {
  __typename?: 'RichText';
  /** Returns AST representation */
  raw: Scalars['RichTextAST'];
  /** Returns HTMl representation */
  html: Scalars['String'];
  /** Returns Markdown representation */
  markdown: Scalars['String'];
  /** Returns plain-text contents of RichText */
  text: Scalars['String'];
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperation = GraphCmsNode & {
  __typename?: 'ScheduledOperation';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsScheduledOperation>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Operation description */
  description?: Maybe<Scalars['String']>;
  /** Operation error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** Raw operation payload including all details, this field is subject to change */
  rawPayload: Scalars['Json'];
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  /** The release this operation is scheduled for */
  release?: Maybe<GraphCmsScheduledRelease>;
  /** operation Status */
  status: GraphCmsScheduledOperationStatus;
  affectedDocuments: Array<GraphCmsScheduledOperationAffectedDocument>;
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperationDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperationCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperationUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperationPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperationReleaseArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Operation system model */
export type GraphCmsScheduledOperationAffectedDocumentsArgs = {
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsScheduledOperationAffectedDocument =
  | GraphCmsAsset
  | GraphCmsAuctionCuration
  | GraphCmsCuratedAuction
  | GraphCmsCuratedToken
  | GraphCmsCuration
  | GraphCmsFeaturedCategoryCuration
  | GraphCmsPage
  | GraphCmsTokenCategory
  | GraphCmsTokenFeed;

export type GraphCmsScheduledOperationConnectInput = {
  /** Document to connect */
  where: GraphCmsScheduledOperationWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsScheduledOperationConnection = {
  __typename?: 'ScheduledOperationConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsScheduledOperationEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsScheduledOperationCreateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: Maybe<Array<GraphCmsScheduledOperationWhereUniqueInput>>;
};

export type GraphCmsScheduledOperationCreateOneInlineInput = {
  /** Connect one existing ScheduledOperation document */
  connect?: Maybe<GraphCmsScheduledOperationWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsScheduledOperationEdge = {
  __typename?: 'ScheduledOperationEdge';
  /** The item at the end of the edge. */
  node: GraphCmsScheduledOperation;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsScheduledOperationManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsScheduledOperationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  release?: Maybe<GraphCmsScheduledReleaseWhereInput>;
  status?: Maybe<GraphCmsScheduledOperationStatus>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<GraphCmsScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<GraphCmsScheduledOperationStatus>>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<GraphCmsScheduledOperationStatus>>;
};

export enum GraphCmsScheduledOperationOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
}

/** System Scheduled Operation Status */
export enum GraphCmsScheduledOperationStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
  Canceled = 'CANCELED',
}

export type GraphCmsScheduledOperationUpdateManyInlineInput = {
  /** Connect multiple existing ScheduledOperation documents */
  connect?: Maybe<Array<GraphCmsScheduledOperationConnectInput>>;
  /** Override currently-connected documents with multiple existing ScheduledOperation documents */
  set?: Maybe<Array<GraphCmsScheduledOperationWhereUniqueInput>>;
  /** Disconnect multiple ScheduledOperation documents */
  disconnect?: Maybe<Array<GraphCmsScheduledOperationWhereUniqueInput>>;
};

export type GraphCmsScheduledOperationUpdateOneInlineInput = {
  /** Connect existing ScheduledOperation document */
  connect?: Maybe<GraphCmsScheduledOperationWhereUniqueInput>;
  /** Disconnect currently connected ScheduledOperation document */
  disconnect?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type GraphCmsScheduledOperationWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsScheduledOperationWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsScheduledOperationWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsScheduledOperationWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  release?: Maybe<GraphCmsScheduledReleaseWhereInput>;
  status?: Maybe<GraphCmsScheduledOperationStatus>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<GraphCmsScheduledOperationStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<GraphCmsScheduledOperationStatus>>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<GraphCmsScheduledOperationStatus>>;
};

/** References ScheduledOperation record uniquely */
export type GraphCmsScheduledOperationWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Scheduled Release system model */
export type GraphCmsScheduledRelease = GraphCmsNode & {
  __typename?: 'ScheduledRelease';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsScheduledRelease>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** Release Title */
  title?: Maybe<Scalars['String']>;
  /** Release description */
  description?: Maybe<Scalars['String']>;
  /** Release error message */
  errorMessage?: Maybe<Scalars['String']>;
  /** Whether scheduled release should be run */
  isActive: Scalars['Boolean'];
  /** Whether scheduled release is implicit */
  isImplicit: Scalars['Boolean'];
  /** Release date and time */
  releaseAt?: Maybe<Scalars['DateTime']>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  /** Operations to run with this release */
  operations: Array<GraphCmsScheduledOperation>;
  /** Release Status */
  status: GraphCmsScheduledReleaseStatus;
};

/** Scheduled Release system model */
export type GraphCmsScheduledReleaseDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

/** Scheduled Release system model */
export type GraphCmsScheduledReleaseCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Release system model */
export type GraphCmsScheduledReleaseUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Release system model */
export type GraphCmsScheduledReleasePublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

/** Scheduled Release system model */
export type GraphCmsScheduledReleaseOperationsArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  orderBy?: Maybe<GraphCmsScheduledOperationOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsScheduledReleaseConnectInput = {
  /** Document to connect */
  where: GraphCmsScheduledReleaseWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsScheduledReleaseConnection = {
  __typename?: 'ScheduledReleaseConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsScheduledReleaseEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsScheduledReleaseCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['DateTime']>;
};

export type GraphCmsScheduledReleaseCreateManyInlineInput = {
  /** Create and connect multiple existing ScheduledRelease documents */
  create?: Maybe<Array<GraphCmsScheduledReleaseCreateInput>>;
  /** Connect multiple existing ScheduledRelease documents */
  connect?: Maybe<Array<GraphCmsScheduledReleaseWhereUniqueInput>>;
};

export type GraphCmsScheduledReleaseCreateOneInlineInput = {
  /** Create and connect one ScheduledRelease document */
  create?: Maybe<GraphCmsScheduledReleaseCreateInput>;
  /** Connect one existing ScheduledRelease document */
  connect?: Maybe<GraphCmsScheduledReleaseWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsScheduledReleaseEdge = {
  __typename?: 'ScheduledReleaseEdge';
  /** The item at the end of the edge. */
  node: GraphCmsScheduledRelease;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsScheduledReleaseManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsScheduledReleaseWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  isImplicit?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isImplicit_not?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  releaseAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  releaseAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  operations_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  operations_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  operations_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
  status?: Maybe<GraphCmsScheduledReleaseStatus>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<GraphCmsScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<GraphCmsScheduledReleaseStatus>>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<GraphCmsScheduledReleaseStatus>>;
};

export enum GraphCmsScheduledReleaseOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
  DescriptionAsc = 'description_ASC',
  DescriptionDesc = 'description_DESC',
  ErrorMessageAsc = 'errorMessage_ASC',
  ErrorMessageDesc = 'errorMessage_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  IsImplicitAsc = 'isImplicit_ASC',
  IsImplicitDesc = 'isImplicit_DESC',
  ReleaseAtAsc = 'releaseAt_ASC',
  ReleaseAtDesc = 'releaseAt_DESC',
  StatusAsc = 'status_ASC',
  StatusDesc = 'status_DESC',
}

/** System Scheduled Release Status */
export enum GraphCmsScheduledReleaseStatus {
  Pending = 'PENDING',
  InProgress = 'IN_PROGRESS',
  Completed = 'COMPLETED',
  Failed = 'FAILED',
}

export type GraphCmsScheduledReleaseUpdateInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['DateTime']>;
};

export type GraphCmsScheduledReleaseUpdateManyInlineInput = {
  /** Create and connect multiple ScheduledRelease documents */
  create?: Maybe<Array<GraphCmsScheduledReleaseCreateInput>>;
  /** Connect multiple existing ScheduledRelease documents */
  connect?: Maybe<Array<GraphCmsScheduledReleaseConnectInput>>;
  /** Override currently-connected documents with multiple existing ScheduledRelease documents */
  set?: Maybe<Array<GraphCmsScheduledReleaseWhereUniqueInput>>;
  /** Update multiple ScheduledRelease documents */
  update?: Maybe<Array<GraphCmsScheduledReleaseUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple ScheduledRelease documents */
  upsert?: Maybe<Array<GraphCmsScheduledReleaseUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple ScheduledRelease documents */
  disconnect?: Maybe<Array<GraphCmsScheduledReleaseWhereUniqueInput>>;
  /** Delete multiple ScheduledRelease documents */
  delete?: Maybe<Array<GraphCmsScheduledReleaseWhereUniqueInput>>;
};

export type GraphCmsScheduledReleaseUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['DateTime']>;
};

export type GraphCmsScheduledReleaseUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsScheduledReleaseWhereInput;
  /** Update many input */
  data: GraphCmsScheduledReleaseUpdateManyInput;
};

export type GraphCmsScheduledReleaseUpdateOneInlineInput = {
  /** Create and connect one ScheduledRelease document */
  create?: Maybe<GraphCmsScheduledReleaseCreateInput>;
  /** Update single ScheduledRelease document */
  update?: Maybe<GraphCmsScheduledReleaseUpdateWithNestedWhereUniqueInput>;
  /** Upsert single ScheduledRelease document */
  upsert?: Maybe<GraphCmsScheduledReleaseUpsertWithNestedWhereUniqueInput>;
  /** Connect existing ScheduledRelease document */
  connect?: Maybe<GraphCmsScheduledReleaseWhereUniqueInput>;
  /** Disconnect currently connected ScheduledRelease document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected ScheduledRelease document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsScheduledReleaseUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsScheduledReleaseWhereUniqueInput;
  /** Document to update */
  data: GraphCmsScheduledReleaseUpdateInput;
};

export type GraphCmsScheduledReleaseUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsScheduledReleaseCreateInput;
  /** Update document if it exists */
  update: GraphCmsScheduledReleaseUpdateInput;
};

export type GraphCmsScheduledReleaseUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsScheduledReleaseWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsScheduledReleaseUpsertInput;
};

/** Identifies documents */
export type GraphCmsScheduledReleaseWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsScheduledReleaseWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsScheduledReleaseWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsScheduledReleaseWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  description_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  description_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  description_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  description_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  description_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  description_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  description_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  description_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  description_not_ends_with?: Maybe<Scalars['String']>;
  errorMessage?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  errorMessage_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  errorMessage_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  errorMessage_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  errorMessage_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  errorMessage_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  errorMessage_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  errorMessage_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  errorMessage_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  errorMessage_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  isImplicit?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isImplicit_not?: Maybe<Scalars['Boolean']>;
  releaseAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  releaseAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  releaseAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  releaseAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  releaseAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  releaseAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  releaseAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  releaseAt_gte?: Maybe<Scalars['DateTime']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  operations_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  operations_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  operations_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
  status?: Maybe<GraphCmsScheduledReleaseStatus>;
  /** All values that are not equal to given value. */
  status_not?: Maybe<GraphCmsScheduledReleaseStatus>;
  /** All values that are contained in given list. */
  status_in?: Maybe<Array<GraphCmsScheduledReleaseStatus>>;
  /** All values that are not contained in given list. */
  status_not_in?: Maybe<Array<GraphCmsScheduledReleaseStatus>>;
};

/** References ScheduledRelease record uniquely */
export type GraphCmsScheduledReleaseWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

/** Stage system enumeration */
export enum GraphCmsStage {
  /** The Draft is the default stage for all your content. */
  Draft = 'DRAFT',
  /** The Published stage is where you can publish your content to. */
  Published = 'PUBLISHED',
}

export enum GraphCmsSystemDateTimeFieldVariation {
  Base = 'BASE',
  Localization = 'LOCALIZATION',
  Combined = 'COMBINED',
}

export type GraphCmsTokenCategory = GraphCmsNode & {
  __typename?: 'TokenCategory';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsTokenCategory>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  coverColour?: Maybe<GraphCmsColor>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  tokens: Array<GraphCmsCuratedToken>;
  /** List of TokenCategory versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsTokenCategoryDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsTokenCategoryCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenCategoryUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenCategoryPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenCategoryScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenCategoryTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenCategoryHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsTokenCategoryConnectInput = {
  /** Document to connect */
  where: GraphCmsTokenCategoryWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsTokenCategoryConnection = {
  __typename?: 'TokenCategoryConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsTokenCategoryEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsTokenCategoryCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  coverColour?: Maybe<GraphCmsColorInput>;
  cl13fmv4bc61s01ywfoab8bzb?: Maybe<GraphCmsCuratedTokenCreateManyInlineInput>;
  cl156io1agl8501z0hmb62hep?: Maybe<GraphCmsFeaturedCategoryCurationCreateManyInlineInput>;
  tokens?: Maybe<GraphCmsCuratedTokenCreateManyInlineInput>;
};

export type GraphCmsTokenCategoryCreateManyInlineInput = {
  /** Create and connect multiple existing TokenCategory documents */
  create?: Maybe<Array<GraphCmsTokenCategoryCreateInput>>;
  /** Connect multiple existing TokenCategory documents */
  connect?: Maybe<Array<GraphCmsTokenCategoryWhereUniqueInput>>;
};

export type GraphCmsTokenCategoryCreateOneInlineInput = {
  /** Create and connect one TokenCategory document */
  create?: Maybe<GraphCmsTokenCategoryCreateInput>;
  /** Connect one existing TokenCategory document */
  connect?: Maybe<GraphCmsTokenCategoryWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsTokenCategoryEdge = {
  __typename?: 'TokenCategoryEdge';
  /** The item at the end of the edge. */
  node: GraphCmsTokenCategory;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsTokenCategoryManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsTokenCategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsTokenCategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsTokenCategoryWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  category_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  category_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  category_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  category_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  category_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  category_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  category_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  category_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  category_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
  tokens_every?: Maybe<GraphCmsCuratedTokenWhereInput>;
  tokens_some?: Maybe<GraphCmsCuratedTokenWhereInput>;
  tokens_none?: Maybe<GraphCmsCuratedTokenWhereInput>;
};

export enum GraphCmsTokenCategoryOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  CategoryAsc = 'category_ASC',
  CategoryDesc = 'category_DESC',
}

export type GraphCmsTokenCategoryUpdateInput = {
  name?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  coverColour?: Maybe<GraphCmsColorInput>;
  cl13fmv4bc61s01ywfoab8bzb?: Maybe<GraphCmsCuratedTokenUpdateManyInlineInput>;
  cl156io1agl8501z0hmb62hep?: Maybe<GraphCmsFeaturedCategoryCurationUpdateManyInlineInput>;
  tokens?: Maybe<GraphCmsCuratedTokenUpdateManyInlineInput>;
};

export type GraphCmsTokenCategoryUpdateManyInlineInput = {
  /** Create and connect multiple TokenCategory documents */
  create?: Maybe<Array<GraphCmsTokenCategoryCreateInput>>;
  /** Connect multiple existing TokenCategory documents */
  connect?: Maybe<Array<GraphCmsTokenCategoryConnectInput>>;
  /** Override currently-connected documents with multiple existing TokenCategory documents */
  set?: Maybe<Array<GraphCmsTokenCategoryWhereUniqueInput>>;
  /** Update multiple TokenCategory documents */
  update?: Maybe<Array<GraphCmsTokenCategoryUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TokenCategory documents */
  upsert?: Maybe<Array<GraphCmsTokenCategoryUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple TokenCategory documents */
  disconnect?: Maybe<Array<GraphCmsTokenCategoryWhereUniqueInput>>;
  /** Delete multiple TokenCategory documents */
  delete?: Maybe<Array<GraphCmsTokenCategoryWhereUniqueInput>>;
};

export type GraphCmsTokenCategoryUpdateManyInput = {
  name?: Maybe<Scalars['String']>;
  coverColour?: Maybe<GraphCmsColorInput>;
};

export type GraphCmsTokenCategoryUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsTokenCategoryWhereInput;
  /** Update many input */
  data: GraphCmsTokenCategoryUpdateManyInput;
};

export type GraphCmsTokenCategoryUpdateOneInlineInput = {
  /** Create and connect one TokenCategory document */
  create?: Maybe<GraphCmsTokenCategoryCreateInput>;
  /** Update single TokenCategory document */
  update?: Maybe<GraphCmsTokenCategoryUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TokenCategory document */
  upsert?: Maybe<GraphCmsTokenCategoryUpsertWithNestedWhereUniqueInput>;
  /** Connect existing TokenCategory document */
  connect?: Maybe<GraphCmsTokenCategoryWhereUniqueInput>;
  /** Disconnect currently connected TokenCategory document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected TokenCategory document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsTokenCategoryUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsTokenCategoryWhereUniqueInput;
  /** Document to update */
  data: GraphCmsTokenCategoryUpdateInput;
};

export type GraphCmsTokenCategoryUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsTokenCategoryCreateInput;
  /** Update document if it exists */
  update: GraphCmsTokenCategoryUpdateInput;
};

export type GraphCmsTokenCategoryUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsTokenCategoryWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsTokenCategoryUpsertInput;
};

/** Identifies documents */
export type GraphCmsTokenCategoryWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsTokenCategoryWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsTokenCategoryWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsTokenCategoryWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  category?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  category_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  category_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  category_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  category_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  category_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  category_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  category_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  category_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  category_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
  tokens_every?: Maybe<GraphCmsCuratedTokenWhereInput>;
  tokens_some?: Maybe<GraphCmsCuratedTokenWhereInput>;
  tokens_none?: Maybe<GraphCmsCuratedTokenWhereInput>;
};

/** References TokenCategory record uniquely */
export type GraphCmsTokenCategoryWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
  category?: Maybe<Scalars['String']>;
};

export type GraphCmsTokenFeed = GraphCmsNode & {
  __typename?: 'TokenFeed';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsTokenFeed>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** User that created this document */
  createdBy?: Maybe<GraphCmsUser>;
  /** User that last updated this document */
  updatedBy?: Maybe<GraphCmsUser>;
  /** User that last published this document */
  publishedBy?: Maybe<GraphCmsUser>;
  curatedTokens: Array<GraphCmsCuratedToken>;
  scheduledIn: Array<GraphCmsScheduledOperation>;
  /** List of TokenFeed versions */
  history: Array<GraphCmsVersion>;
};

export type GraphCmsTokenFeedDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsTokenFeedCreatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenFeedUpdatedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenFeedPublishedByArgs = {
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenFeedCuratedTokensArgs = {
  where?: Maybe<GraphCmsCuratedTokenWhereInput>;
  orderBy?: Maybe<GraphCmsCuratedTokenOrderByInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenFeedScheduledInArgs = {
  where?: Maybe<GraphCmsScheduledOperationWhereInput>;
  skip?: Maybe<Scalars['Int']>;
  after?: Maybe<Scalars['String']>;
  before?: Maybe<Scalars['String']>;
  first?: Maybe<Scalars['Int']>;
  last?: Maybe<Scalars['Int']>;
  locales?: Maybe<Array<GraphCmsLocale>>;
};

export type GraphCmsTokenFeedHistoryArgs = {
  limit?: Scalars['Int'];
  skip?: Scalars['Int'];
  stageOverride?: Maybe<GraphCmsStage>;
};

export type GraphCmsTokenFeedConnectInput = {
  /** Document to connect */
  where: GraphCmsTokenFeedWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsTokenFeedConnection = {
  __typename?: 'TokenFeedConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsTokenFeedEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsTokenFeedCreateInput = {
  createdAt?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  curatedTokens?: Maybe<GraphCmsCuratedTokenCreateManyInlineInput>;
};

export type GraphCmsTokenFeedCreateManyInlineInput = {
  /** Create and connect multiple existing TokenFeed documents */
  create?: Maybe<Array<GraphCmsTokenFeedCreateInput>>;
  /** Connect multiple existing TokenFeed documents */
  connect?: Maybe<Array<GraphCmsTokenFeedWhereUniqueInput>>;
};

export type GraphCmsTokenFeedCreateOneInlineInput = {
  /** Create and connect one TokenFeed document */
  create?: Maybe<GraphCmsTokenFeedCreateInput>;
  /** Connect one existing TokenFeed document */
  connect?: Maybe<GraphCmsTokenFeedWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsTokenFeedEdge = {
  __typename?: 'TokenFeedEdge';
  /** The item at the end of the edge. */
  node: GraphCmsTokenFeed;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** Identifies documents */
export type GraphCmsTokenFeedManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsTokenFeedWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsTokenFeedWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsTokenFeedWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  curatedTokens_every?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_some?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_none?: Maybe<GraphCmsCuratedTokenWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

export enum GraphCmsTokenFeedOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  TitleAsc = 'title_ASC',
  TitleDesc = 'title_DESC',
}

export type GraphCmsTokenFeedUpdateInput = {
  title?: Maybe<Scalars['String']>;
  curatedTokens?: Maybe<GraphCmsCuratedTokenUpdateManyInlineInput>;
};

export type GraphCmsTokenFeedUpdateManyInlineInput = {
  /** Create and connect multiple TokenFeed documents */
  create?: Maybe<Array<GraphCmsTokenFeedCreateInput>>;
  /** Connect multiple existing TokenFeed documents */
  connect?: Maybe<Array<GraphCmsTokenFeedConnectInput>>;
  /** Override currently-connected documents with multiple existing TokenFeed documents */
  set?: Maybe<Array<GraphCmsTokenFeedWhereUniqueInput>>;
  /** Update multiple TokenFeed documents */
  update?: Maybe<Array<GraphCmsTokenFeedUpdateWithNestedWhereUniqueInput>>;
  /** Upsert multiple TokenFeed documents */
  upsert?: Maybe<Array<GraphCmsTokenFeedUpsertWithNestedWhereUniqueInput>>;
  /** Disconnect multiple TokenFeed documents */
  disconnect?: Maybe<Array<GraphCmsTokenFeedWhereUniqueInput>>;
  /** Delete multiple TokenFeed documents */
  delete?: Maybe<Array<GraphCmsTokenFeedWhereUniqueInput>>;
};

export type GraphCmsTokenFeedUpdateManyInput = {
  title?: Maybe<Scalars['String']>;
};

export type GraphCmsTokenFeedUpdateManyWithNestedWhereInput = {
  /** Document search */
  where: GraphCmsTokenFeedWhereInput;
  /** Update many input */
  data: GraphCmsTokenFeedUpdateManyInput;
};

export type GraphCmsTokenFeedUpdateOneInlineInput = {
  /** Create and connect one TokenFeed document */
  create?: Maybe<GraphCmsTokenFeedCreateInput>;
  /** Update single TokenFeed document */
  update?: Maybe<GraphCmsTokenFeedUpdateWithNestedWhereUniqueInput>;
  /** Upsert single TokenFeed document */
  upsert?: Maybe<GraphCmsTokenFeedUpsertWithNestedWhereUniqueInput>;
  /** Connect existing TokenFeed document */
  connect?: Maybe<GraphCmsTokenFeedWhereUniqueInput>;
  /** Disconnect currently connected TokenFeed document */
  disconnect?: Maybe<Scalars['Boolean']>;
  /** Delete currently connected TokenFeed document */
  delete?: Maybe<Scalars['Boolean']>;
};

export type GraphCmsTokenFeedUpdateWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsTokenFeedWhereUniqueInput;
  /** Document to update */
  data: GraphCmsTokenFeedUpdateInput;
};

export type GraphCmsTokenFeedUpsertInput = {
  /** Create document if it didn't exist */
  create: GraphCmsTokenFeedCreateInput;
  /** Update document if it exists */
  update: GraphCmsTokenFeedUpdateInput;
};

export type GraphCmsTokenFeedUpsertWithNestedWhereUniqueInput = {
  /** Unique document search */
  where: GraphCmsTokenFeedWhereUniqueInput;
  /** Upsert data */
  data: GraphCmsTokenFeedUpsertInput;
};

/** Identifies documents */
export type GraphCmsTokenFeedWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsTokenFeedWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsTokenFeedWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsTokenFeedWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  title?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  title_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  title_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  title_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  title_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  title_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  title_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  title_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  title_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  title_not_ends_with?: Maybe<Scalars['String']>;
  createdBy?: Maybe<GraphCmsUserWhereInput>;
  updatedBy?: Maybe<GraphCmsUserWhereInput>;
  publishedBy?: Maybe<GraphCmsUserWhereInput>;
  curatedTokens_every?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_some?: Maybe<GraphCmsCuratedTokenWhereInput>;
  curatedTokens_none?: Maybe<GraphCmsCuratedTokenWhereInput>;
  scheduledIn_every?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_some?: Maybe<GraphCmsScheduledOperationWhereInput>;
  scheduledIn_none?: Maybe<GraphCmsScheduledOperationWhereInput>;
};

/** References TokenFeed record uniquely */
export type GraphCmsTokenFeedWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GraphCmsUnpublishLocaleInput = {
  /** Locales to unpublish */
  locale: GraphCmsLocale;
  /** Stages to unpublish selected locales from */
  stages: Array<GraphCmsStage>;
};

/** User system model */
export type GraphCmsUser = GraphCmsNode & {
  __typename?: 'User';
  /** System stage field */
  stage: GraphCmsStage;
  /** Get the document in other stages */
  documentInStages: Array<GraphCmsUser>;
  /** The unique identifier */
  id: Scalars['ID'];
  /** The time the document was created */
  createdAt: Scalars['DateTime'];
  /** The time the document was updated */
  updatedAt: Scalars['DateTime'];
  /** The time the document was published. Null on documents in draft stage. */
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** The username */
  name: Scalars['String'];
  /** Profile Picture url */
  picture?: Maybe<Scalars['String']>;
  /** Flag to determine if user is active or not */
  isActive: Scalars['Boolean'];
  /** User Kind. Can be either MEMBER, PAT or PUBLIC */
  kind: GraphCmsUserKind;
};

/** User system model */
export type GraphCmsUserDocumentInStagesArgs = {
  stages?: Array<GraphCmsStage>;
  includeCurrent?: Scalars['Boolean'];
  inheritLocale?: Scalars['Boolean'];
};

export type GraphCmsUserConnectInput = {
  /** Document to connect */
  where: GraphCmsUserWhereUniqueInput;
  /** Allow to specify document position in list of connected documents, will default to appending at end of list */
  position?: Maybe<GraphCmsConnectPositionInput>;
};

/** A connection to a list of items. */
export type GraphCmsUserConnection = {
  __typename?: 'UserConnection';
  /** Information to aid in pagination. */
  pageInfo: GraphCmsPageInfo;
  /** A list of edges. */
  edges: Array<GraphCmsUserEdge>;
  aggregate: GraphCmsAggregate;
};

export type GraphCmsUserCreateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: Maybe<Array<GraphCmsUserWhereUniqueInput>>;
};

export type GraphCmsUserCreateOneInlineInput = {
  /** Connect one existing User document */
  connect?: Maybe<GraphCmsUserWhereUniqueInput>;
};

/** An edge in a connection. */
export type GraphCmsUserEdge = {
  __typename?: 'UserEdge';
  /** The item at the end of the edge. */
  node: GraphCmsUser;
  /** A cursor for use in pagination. */
  cursor: Scalars['String'];
};

/** System User Kind */
export enum GraphCmsUserKind {
  Member = 'MEMBER',
  Pat = 'PAT',
  Public = 'PUBLIC',
  Webhook = 'WEBHOOK',
}

/** Identifies documents */
export type GraphCmsUserManyWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsUserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsUserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsUserWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<GraphCmsUserKind>;
  /** All values that are not equal to given value. */
  kind_not?: Maybe<GraphCmsUserKind>;
  /** All values that are contained in given list. */
  kind_in?: Maybe<Array<GraphCmsUserKind>>;
  /** All values that are not contained in given list. */
  kind_not_in?: Maybe<Array<GraphCmsUserKind>>;
};

export enum GraphCmsUserOrderByInput {
  IdAsc = 'id_ASC',
  IdDesc = 'id_DESC',
  CreatedAtAsc = 'createdAt_ASC',
  CreatedAtDesc = 'createdAt_DESC',
  UpdatedAtAsc = 'updatedAt_ASC',
  UpdatedAtDesc = 'updatedAt_DESC',
  PublishedAtAsc = 'publishedAt_ASC',
  PublishedAtDesc = 'publishedAt_DESC',
  NameAsc = 'name_ASC',
  NameDesc = 'name_DESC',
  PictureAsc = 'picture_ASC',
  PictureDesc = 'picture_DESC',
  IsActiveAsc = 'isActive_ASC',
  IsActiveDesc = 'isActive_DESC',
  KindAsc = 'kind_ASC',
  KindDesc = 'kind_DESC',
}

export type GraphCmsUserUpdateManyInlineInput = {
  /** Connect multiple existing User documents */
  connect?: Maybe<Array<GraphCmsUserConnectInput>>;
  /** Override currently-connected documents with multiple existing User documents */
  set?: Maybe<Array<GraphCmsUserWhereUniqueInput>>;
  /** Disconnect multiple User documents */
  disconnect?: Maybe<Array<GraphCmsUserWhereUniqueInput>>;
};

export type GraphCmsUserUpdateOneInlineInput = {
  /** Connect existing User document */
  connect?: Maybe<GraphCmsUserWhereUniqueInput>;
  /** Disconnect currently connected User document */
  disconnect?: Maybe<Scalars['Boolean']>;
};

/** Identifies documents */
export type GraphCmsUserWhereInput = {
  /** Contains search across all appropriate fields. */
  _search?: Maybe<Scalars['String']>;
  /** Logical AND on all given filters. */
  AND?: Maybe<Array<GraphCmsUserWhereInput>>;
  /** Logical OR on all given filters. */
  OR?: Maybe<Array<GraphCmsUserWhereInput>>;
  /** Logical NOT on all given filters combined by AND. */
  NOT?: Maybe<Array<GraphCmsUserWhereInput>>;
  id?: Maybe<Scalars['ID']>;
  /** All values that are not equal to given value. */
  id_not?: Maybe<Scalars['ID']>;
  /** All values that are contained in given list. */
  id_in?: Maybe<Array<Scalars['ID']>>;
  /** All values that are not contained in given list. */
  id_not_in?: Maybe<Array<Scalars['ID']>>;
  /** All values containing the given string. */
  id_contains?: Maybe<Scalars['ID']>;
  /** All values not containing the given string. */
  id_not_contains?: Maybe<Scalars['ID']>;
  /** All values starting with the given string. */
  id_starts_with?: Maybe<Scalars['ID']>;
  /** All values not starting with the given string. */
  id_not_starts_with?: Maybe<Scalars['ID']>;
  /** All values ending with the given string. */
  id_ends_with?: Maybe<Scalars['ID']>;
  /** All values not ending with the given string */
  id_not_ends_with?: Maybe<Scalars['ID']>;
  createdAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  createdAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  createdAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  createdAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  createdAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  createdAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  createdAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  createdAt_gte?: Maybe<Scalars['DateTime']>;
  updatedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  updatedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  updatedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  updatedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  updatedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  updatedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  updatedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  updatedAt_gte?: Maybe<Scalars['DateTime']>;
  publishedAt?: Maybe<Scalars['DateTime']>;
  /** All values that are not equal to given value. */
  publishedAt_not?: Maybe<Scalars['DateTime']>;
  /** All values that are contained in given list. */
  publishedAt_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values that are not contained in given list. */
  publishedAt_not_in?: Maybe<Array<Scalars['DateTime']>>;
  /** All values less than the given value. */
  publishedAt_lt?: Maybe<Scalars['DateTime']>;
  /** All values less than or equal the given value. */
  publishedAt_lte?: Maybe<Scalars['DateTime']>;
  /** All values greater than the given value. */
  publishedAt_gt?: Maybe<Scalars['DateTime']>;
  /** All values greater than or equal the given value. */
  publishedAt_gte?: Maybe<Scalars['DateTime']>;
  name?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  name_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  name_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  name_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  name_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  name_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  name_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  name_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  name_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  name_not_ends_with?: Maybe<Scalars['String']>;
  picture?: Maybe<Scalars['String']>;
  /** All values that are not equal to given value. */
  picture_not?: Maybe<Scalars['String']>;
  /** All values that are contained in given list. */
  picture_in?: Maybe<Array<Scalars['String']>>;
  /** All values that are not contained in given list. */
  picture_not_in?: Maybe<Array<Scalars['String']>>;
  /** All values containing the given string. */
  picture_contains?: Maybe<Scalars['String']>;
  /** All values not containing the given string. */
  picture_not_contains?: Maybe<Scalars['String']>;
  /** All values starting with the given string. */
  picture_starts_with?: Maybe<Scalars['String']>;
  /** All values not starting with the given string. */
  picture_not_starts_with?: Maybe<Scalars['String']>;
  /** All values ending with the given string. */
  picture_ends_with?: Maybe<Scalars['String']>;
  /** All values not ending with the given string */
  picture_not_ends_with?: Maybe<Scalars['String']>;
  isActive?: Maybe<Scalars['Boolean']>;
  /** All values that are not equal to given value. */
  isActive_not?: Maybe<Scalars['Boolean']>;
  kind?: Maybe<GraphCmsUserKind>;
  /** All values that are not equal to given value. */
  kind_not?: Maybe<GraphCmsUserKind>;
  /** All values that are contained in given list. */
  kind_in?: Maybe<Array<GraphCmsUserKind>>;
  /** All values that are not contained in given list. */
  kind_not_in?: Maybe<Array<GraphCmsUserKind>>;
};

/** References User record uniquely */
export type GraphCmsUserWhereUniqueInput = {
  id?: Maybe<Scalars['ID']>;
};

export type GraphCmsVersion = {
  __typename?: 'Version';
  id: Scalars['ID'];
  stage: GraphCmsStage;
  revision: Scalars['Int'];
  createdAt: Scalars['DateTime'];
};

export type GraphCmsVersionWhereInput = {
  id: Scalars['ID'];
  stage: GraphCmsStage;
  revision: Scalars['Int'];
};

export enum GraphCms_FilterKind {
  Search = 'search',
  And = 'AND',
  Or = 'OR',
  Not = 'NOT',
  Eq = 'eq',
  EqNot = 'eq_not',
  In = 'in',
  NotIn = 'not_in',
  Lt = 'lt',
  Lte = 'lte',
  Gt = 'gt',
  Gte = 'gte',
  Contains = 'contains',
  NotContains = 'not_contains',
  StartsWith = 'starts_with',
  NotStartsWith = 'not_starts_with',
  EndsWith = 'ends_with',
  NotEndsWith = 'not_ends_with',
  ContainsAll = 'contains_all',
  ContainsSome = 'contains_some',
  ContainsNone = 'contains_none',
  RelationalSingle = 'relational_single',
  RelationalEvery = 'relational_every',
  RelationalSome = 'relational_some',
  RelationalNone = 'relational_none',
}

export enum GraphCms_MutationInputFieldKind {
  Scalar = 'scalar',
  RichText = 'richText',
  RichTextWithEmbeds = 'richTextWithEmbeds',
  Enum = 'enum',
  Relation = 'relation',
  Union = 'union',
  Virtual = 'virtual',
}

export enum GraphCms_MutationKind {
  Create = 'create',
  Publish = 'publish',
  Unpublish = 'unpublish',
  Update = 'update',
  Upsert = 'upsert',
  Delete = 'delete',
  UpdateMany = 'updateMany',
  PublishMany = 'publishMany',
  UnpublishMany = 'unpublishMany',
  DeleteMany = 'deleteMany',
  SchedulePublish = 'schedulePublish',
  ScheduleUnpublish = 'scheduleUnpublish',
}

export enum GraphCms_OrderDirection {
  Asc = 'asc',
  Desc = 'desc',
}

export enum GraphCms_RelationInputCardinality {
  One = 'one',
  Many = 'many',
}

export enum GraphCms_RelationInputKind {
  Create = 'create',
  Update = 'update',
}

export enum GraphCms_RelationKind {
  Regular = 'regular',
  Union = 'union',
}

export enum GraphCms_SystemDateTimeFieldVariation {
  Base = 'base',
  Localization = 'localization',
  Combined = 'combined',
}

export type GraphCmsGetCurationsQueryVariables = Exact<{[key: string]: never}>;

export type GraphCmsGetCurationsQuery = {__typename?: 'Query'} & {
  curations: Array<
    {__typename?: 'Curation'} & Pick<GraphCmsCuration, 'title' | 'slug' | 'randomise'> & {
        curatedTokens: Array<{__typename?: 'CuratedToken'} & Pick<GraphCmsCuratedToken, 'tokenId' | 'contract' | 'link'>>;
      }
  >;
  tokenFeed?: Maybe<
    {__typename?: 'TokenFeed'} & Pick<GraphCmsTokenFeed, 'title'> & {
        curatedTokens: Array<{__typename?: 'CuratedToken'} & Pick<GraphCmsCuratedToken, 'tokenId' | 'contract' | 'link'>>;
      }
  >;
  auctionCurations: Array<
    {__typename?: 'AuctionCuration'} & Pick<GraphCmsAuctionCuration, 'title'> & {
        curatedAuctions: Array<{__typename?: 'CuratedAuction'} & Pick<GraphCmsCuratedAuction, 'auctionId' | 'auctionType'>>;
      }
  >;
};

export type GraphCmsGetCuratedTokensByCategoryQueryVariables = Exact<{
  category: Scalars['String'];
  skip: Scalars['Int'];
  limit?: Maybe<Scalars['Int']>;
}>;

export type GraphCmsGetCuratedTokensByCategoryQuery = {__typename?: 'Query'} & {
  curatedTokens: Array<{__typename?: 'CuratedToken'} & Pick<GraphCmsCuratedToken, 'id' | 'tokenId' | 'contract' | 'link'>>;
  tokenCategories: Array<{__typename?: 'TokenCategory'} & Pick<GraphCmsTokenCategory, 'name' | 'category'>>;
  curatedTokensConnection: {__typename?: 'CuratedTokenConnection'} & {aggregate: {__typename?: 'Aggregate'} & Pick<GraphCmsAggregate, 'count'>};
};

export type GraphCmsGetCuratedTokenCategoriesQueryVariables = Exact<{[key: string]: never}>;

export type GraphCmsGetCuratedTokenCategoriesQuery = {__typename?: 'Query'} & {
  tokenCategories: Array<
    {__typename?: 'TokenCategory'} & Pick<GraphCmsTokenCategory, 'name' | 'category'> & {
        firstTokens: Array<{__typename?: 'CuratedToken'} & Pick<GraphCmsCuratedToken, 'id' | 'tokenId' | 'contract' | 'link'>>;
      }
  >;
};

export type GraphCmsGetFeaturedCategoryCurationQueryVariables = Exact<{[key: string]: never}>;

export type GraphCmsGetFeaturedCategoryCurationQuery = {__typename?: 'Query'} & {
  featuredCategoryCuration?: Maybe<
    {__typename?: 'FeaturedCategoryCuration'} & {
      tokenCategories: Array<
        {__typename?: 'TokenCategory'} & Pick<GraphCmsTokenCategory, 'name' | 'category'> & {
            coverColour?: Maybe<{__typename?: 'Color'} & {rgba: {__typename?: 'RGBA'} & Pick<GraphCmsRgba, 'r' | 'g' | 'b' | 'a'>}>;
          }
      >;
    }
  >;
};

export type GraphCmsGetPageQueryVariables = Exact<{
  id: Scalars['ID'];
  locales: Array<GraphCmsLocale> | GraphCmsLocale;
}>;

export type GraphCmsGetPageQuery = {__typename?: 'Query'} & {
  page?: Maybe<
    {__typename?: 'Page'} & Pick<GraphCmsPage, 'publishedAt' | 'slug' | 'title'> & {content?: Maybe<{__typename?: 'RichText'} & Pick<GraphCmsRichText, 'html'>>}
  >;
};

export const GraphCmsGetCurationsDocument = gql`
  query GetCurations {
    curations(last: 1) {
      title
      slug
      randomise
      curatedTokens {
        tokenId
        contract
        link
      }
    }
    tokenFeed(where: {id: "cl0qll5yj4oys0bz7x68df6f3"}) {
      title
      curatedTokens(first: 25) {
        tokenId
        contract
        link
      }
    }
    auctionCurations(last: 1) {
      title
      curatedAuctions {
        auctionId
        auctionType
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GraphCmsGetCurationsGQL extends Apollo.Query<GraphCmsGetCurationsQuery, GraphCmsGetCurationsQueryVariables> {
  document = GraphCmsGetCurationsDocument;
  client = 'graphCms';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GraphCmsGetCuratedTokensByCategoryDocument = gql`
  query GetCuratedTokensByCategory($category: String!, $skip: Int!, $limit: Int = 36) {
    curatedTokens(where: {tokenCategories_some: {category: $category}, contract_not: null, tokenId_not: null}, first: $limit, skip: $skip) {
      id
      tokenId
      contract
      link
    }
    tokenCategories {
      name
      category
    }
    curatedTokensConnection(where: {tokenCategories_some: {category: $category}}) {
      aggregate {
        count
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GraphCmsGetCuratedTokensByCategoryGQL extends Apollo.Query<
  GraphCmsGetCuratedTokensByCategoryQuery,
  GraphCmsGetCuratedTokensByCategoryQueryVariables
> {
  document = GraphCmsGetCuratedTokensByCategoryDocument;
  client = 'graphCms';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GraphCmsGetCuratedTokenCategoriesDocument = gql`
  query GetCuratedTokenCategories {
    tokenCategories {
      name
      category
      firstTokens: tokens(first: 4, where: {tokenId_not: null}) {
        id
        tokenId
        contract
        link
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GraphCmsGetCuratedTokenCategoriesGQL extends Apollo.Query<
  GraphCmsGetCuratedTokenCategoriesQuery,
  GraphCmsGetCuratedTokenCategoriesQueryVariables
> {
  document = GraphCmsGetCuratedTokenCategoriesDocument;
  client = 'graphCms';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GraphCmsGetFeaturedCategoryCurationDocument = gql`
  query GetFeaturedCategoryCuration {
    featuredCategoryCuration(where: {id: "cl156lu3t0zsr0duol320vz2k"}) {
      tokenCategories {
        name
        category
        coverColour {
          rgba {
            r
            g
            b
            a
          }
        }
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GraphCmsGetFeaturedCategoryCurationGQL extends Apollo.Query<
  GraphCmsGetFeaturedCategoryCurationQuery,
  GraphCmsGetFeaturedCategoryCurationQueryVariables
> {
  document = GraphCmsGetFeaturedCategoryCurationDocument;
  client = 'graphCms';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const GraphCmsGetPageDocument = gql`
  query GetPage($id: ID!, $locales: [Locale!]!) {
    page(where: {id: $id}, locales: $locales) {
      publishedAt
      slug
      title
      content {
        html
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class GraphCmsGetPageGQL extends Apollo.Query<GraphCmsGetPageQuery, GraphCmsGetPageQueryVariables> {
  document = GraphCmsGetPageDocument;
  client = 'graphCms';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
