import {
  ChangeDetectorRef,
  Component,
  ElementRef,
  Input,
  OnChanges,
  SimpleChanges,
} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {
  DutchAuctionModel,
  ObjktDetailsModel,
  ObjktPreviewSizes,
  OpenEditionModel,
  TypesenseAuctionType,
} from 'src/app/types/types';
import {PreviewService} from 'src/app/services/preview.service';
import {ObjktSelectService} from '../../services/select.service';
import {UsernamePipe} from 'src/app/pipes/username/username.pipe';
import {combineLatest, Observable, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {DateTime, Duration} from 'luxon';
import {WalletService} from '../../services/tezos/wallet.service';
import {AuctionApiService} from '../../services/api/auction-api.service';

@Component({
  selector: 'app-objkt-gallery-element',
  templateUrl: './objkt-gallery-element.component.html',
  styleUrls: ['./objkt-gallery-element.component.scss'],
})
export class ObjktGalleryElementComponent implements OnChanges {
  @Input() objkt: ObjktDetailsModel;
  @Input() showPricing = true;
  @Input() showSelect = false;
  @Input() imgSize: ObjktPreviewSizes;
  @Input() isAuction = false;

  isGalleryOverride = false;
  galleryOverrideCollectionName: string;
  imgUrl: string | SafeUrl = '';
  faCollectionType: string;
  creators: string;
  mime: string;

  auctionModel$: Observable<{
    inFuture: boolean;
    countdown: {
      days: number;
      hours: number;
      minutes: number;
      seconds: number;
    };
    highestBid: number;
  }>;

  constructor(
    readonly elementRef: ElementRef,
    readonly previewService: PreviewService,
    readonly cdr: ChangeDetectorRef,
    readonly objktSelect: ObjktSelectService,
    readonly usernamePipe: UsernamePipe,
    private readonly auctionApiService: AuctionApiService,
    private readonly walletService: WalletService,
  ) {}

  get imgPreloadUrl() {
    const url = this.previewService.getObjktPreview(this.objkt);
    if (typeof url !== 'string') {
      return (url as any).changingThisBreaksApplicationSecurity;
    }
    return url;
  }

  get listedPrice() {
    return this.objkt?.lowest_ask;
  }

  get highestOffer() {
    return this.objkt?.highest_offer || 0;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.objkt && this.objkt) {
      this.mime = this.objkt.mime;
      this.faCollectionType =
        this.objkt.fa.collection_type || this.objkt.fa_collection_type;
      this.creators = this.objkt.creators
        ?.map(creator => this.usernamePipe.transform(creator.holder))
        .join(', ');
      const isFxHash = this.isFxhashContract(this.objkt.fa_contract);
      const isEmergentProperties = this.isEmergentPropertiesContract(
        this.objkt.fa_contract,
      );
      this.isGalleryOverride = isFxHash || isEmergentProperties;

      if (this.isGalleryOverride) {
        const lastIndex = this.objkt.name?.lastIndexOf('#');
        if (lastIndex === -1) {
          this.galleryOverrideCollectionName = this.objkt.name;
        } else {
          this.galleryOverrideCollectionName = this.objkt.name?.slice(
            0,
            lastIndex - 1,
          );
        }
        this.objkt.galleries_pk =
          this.objkt.galleries_pk ||
          this.objkt.galleries?.map(gallery => gallery.gallery.pk);
      }

      if (this.objkt.auction_type) {
        this.auctionModel$ = combineLatest([
          timer(0, 1000),
          this.walletService.getHead(),
        ]).pipe(
          map(([countdown, head]) => {
            if (
              !this.objkt.auction_start_time ||
              !this.objkt.auction_end_time
            ) {
              return {
                inFuture: false,
                countdown: undefined,
                highestBid: 0,
              };
            }
            const blockTime = 30;
            const timestamp = DateTime.fromISO(head.timestamp as string);
            const offset = Duration.fromObject({
              seconds: ~~(countdown / blockTime) * blockTime,
            });
            const approxBlockTimestamp = timestamp.plus(offset).toISO();
            const inFuture =
              DateTime.fromISO(this.objkt.auction_start_time?.toString()) >
              DateTime.now();

            return {
              inFuture,
              countdown: this.getCountdown(
                inFuture,
                new Date(this.objkt.auction_start_time * 1000),
                new Date(this.objkt.auction_end_time * 1000),
              ),
              highestBid: this.highestBid(approxBlockTimestamp),
            };
          }),
        );
      }
      if (this.objkt.open_edition?.end_time) {
        this.objkt.auction_type = TypesenseAuctionType.OpenEdition;
        this.objkt.auction_status = this.getOpenEditionStatus(
          this.objkt.open_edition,
        );
        this.auctionModel$ = combineLatest([
          timer(0, 1000),
          this.walletService.getHead(),
        ]).pipe(
          map(([countdown, head]) => {
            const blockTime = 30;
            const timestamp = DateTime.fromISO(head.timestamp as string);
            const offset = Duration.fromObject({
              seconds: ~~(countdown / blockTime) * blockTime,
            });
            const approxBlockTimestamp = timestamp.plus(offset).toISO();
            const inFuture =
              DateTime.fromISO(this.objkt.open_edition.start_time?.toString()) >
              DateTime.now();
            return {
              inFuture,
              countdown: this.getCountdown(
                inFuture,
                DateTime.fromISO(this.objkt.open_edition.start_time).toJSDate(),
                DateTime.fromISO(this.objkt.open_edition.end_time).toJSDate(),
              ),
              highestBid: this.objkt.open_edition.price,
            };
          }),
        );
      }
    }
  }

  handleSelect(objkt: ObjktDetailsModel) {
    this.objktSelect.add(objkt);
  }

  handleDeselect(objkt: ObjktDetailsModel) {
    this.objktSelect.remove(objkt);
  }

  select($event) {
    if (this.showSelect) {
      $event.stopPropagation();
      $event.preventDefault();
      if (!this.objktSelect.items.hasOwnProperty(this.objkt.pk)) {
        this.handleSelect(this.objkt);
      } else {
        this.handleDeselect(this.objkt);
      }
    }
  }

  private highestBid(timestamp: string) {
    switch (this.objkt.auction_type) {
      case 'dutch':
        const price = Number(
          this.auctionApiService.getCurrentPriceFromModel(
            {
              start_time: new Date(
                this.objkt.auction_start_time * 1000,
              ).toISOString(),
              start_price: this.objkt.auction_start_price,
              end_time: new Date(
                this.objkt.auction_end_time * 1000,
              ).toISOString(),
              end_price: this.objkt.auction_end_price,
            } as DutchAuctionModel,
            timestamp,
          ),
        );
        return Math.max(price, this.objkt.auction_end_price);
      case 'english':
        return this.objkt.auction_highest_bid;
    }
  }

  private getCountdown(inFuture: boolean, sTime: Date, eTime: Date) {
    const startTime = DateTime.fromISO(sTime.toISOString());
    const endTime = DateTime.fromISO(eTime.toISOString());
    const now = DateTime.now();
    const diff = (inFuture ? startTime.diff(now) : endTime.diff(now)).shiftTo(
      'days',
      'hours',
      'minutes',
      'seconds',
      'milliseconds',
    );
    return {
      days: Math.max(0, diff.days),
      hours: Math.max(0, diff.hours),
      minutes: Math.max(0, diff.minutes),
      seconds: Math.max(0, diff.seconds),
    };
  }

  private isFxhashContract(contract: string) {
    return [
      'KT1KEa8z6vWXDJrVqtMrAeDVzsvxat3kHaCE',
      'KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi',
    ].includes(contract);
  }

  private isEmergentPropertiesContract(contract: string) {
    return ['KT1MhSRKsujc4q5b5KsXvmsvkFyht9h4meZs'].includes(contract);
  }

  private getOpenEditionStatus(openEdition: OpenEditionModel) {
    const startTime = DateTime.fromISO(openEdition.start_time);
    const endTime = DateTime.fromISO(openEdition.end_time);

    if (startTime > DateTime.now()) {
      return 'scheduled';
    }

    if (endTime > DateTime.now()) {
      return 'active';
    }

    return 'finished';
  }
}
