import {Component, Input, OnInit} from '@angular/core';

const mimesWithIcon = {
  video: 'video',
  model: 'model',
  audio: 'audio',
  application: 'interactive',
  text: 'text',
};

@Component({
  selector: 'app-gallery-mime',
  templateUrl: './gallery-mime.component.html',
  styleUrls: ['./gallery-mime.component.scss'],
})
export class GalleryMimeComponent implements OnInit {
  @Input() mime: string;
  parsedMime: string;

  constructor() {}

  ngOnInit(): void {
    this.parsedMime = this.parseMime(this.mime);
  }

  private parseMime(mime: string) {
    try {
      const firstPart = mime.split('/')[0];

      // exception for PDF
      if (firstPart === 'application' && mime === 'application/pdf') {
        return 'pdf';
      }

      return mimesWithIcon[firstPart];
    } catch (e) {
      return null;
    }
  }
}
