import {Component, Input, OnInit} from '@angular/core';
import {merge, Observable, of} from 'rxjs';
import {GenericAuctionModel} from 'src/app/types/types';

@Component({
  selector: 'app-auction-carousel',
  templateUrl: './auction-carousel.component.html',
  styleUrls: ['./auction-carousel.component.scss'],
})
export class AuctionCarouselComponent implements OnInit {
  @Input() auctions$: Observable<GenericAuctionModel[]>;
  @Input() divide;

  auctionsMerged$: Observable<GenericAuctionModel[]>;

  emptyList: any[];

  constructor() {}

  ngOnInit(): void {
    this.auctionsMerged$ = merge(of(Array(12)), this.auctions$);
  }
}
