import {Component, EventEmitter, Input, OnInit, Output, ViewEncapsulation} from '@angular/core';

@Component({
  selector: 'app-button',
  templateUrl: './button.component.html',
  styleUrls: ['../buttons.scss', './button.component.scss'],
  encapsulation: ViewEncapsulation.None,
})
export class ButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() buttonStyle: string;
  @Input() type: string;
  @Output() onClick = new EventEmitter();
  constructor() {}

  ngOnInit(): void {}
}
