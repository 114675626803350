import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {FeaturedObjktComponent} from './featured-objkt.component';
import {RouterModule} from '@angular/router';
import {ObjktPreviewModule} from '../objkt-preview/objkt-preview.module';
import {ObjktLinkPipeModule} from '../../pipes/objkt-links/objkt-link-pipe.module';
import {UsernamePipeModule} from '../../pipes/username/username-pipe.module';
import {PrerenderStopModule} from 'src/app/directives/prerender-stop/prerender-stop.module';
import {SrcFallbackModule} from 'src/app/directives/src-fallback/src-fallback.module';
import {AvatarImageModule} from '../avatar-image/avatar-image.module';
import {InfoPopoverModule} from '../info-popover/info-popover.module';

@NgModule({
  declarations: [FeaturedObjktComponent],
  imports: [
    CommonModule,
    RouterModule,
    ObjktPreviewModule,
    ObjktLinkPipeModule,
    UsernamePipeModule,
    PrerenderStopModule,
    SrcFallbackModule,
    AvatarImageModule,
    InfoPopoverModule,
  ],
  exports: [FeaturedObjktComponent],
})
export class FeaturedObjktModule {}
