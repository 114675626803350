import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NotificationsPanelComponent} from './notifications-panel.component';
import {ObjktPreviewModule} from '../objkt-preview/objkt-preview.module';
import {ObjktLinkPipeModule} from '../../pipes/objkt-links/objkt-link-pipe.module';
import {RouterModule} from '@angular/router';
import {LoginMessageModule} from '../login-message/login-message.module';

@NgModule({
  declarations: [NotificationsPanelComponent],
  exports: [NotificationsPanelComponent],
  imports: [CommonModule, RouterModule, ObjktPreviewModule, ObjktLinkPipeModule, LoginMessageModule],
})
export class NotificationsPanelModule {}
