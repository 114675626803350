import {Component, OnInit} from '@angular/core';
import {NotificationService} from '../../services/notification.service';
import {ObjktModel} from '../../types/types';
import {SigningService} from '../../services/tezos/signing.service';
import {WalletService} from '../../services/tezos/wallet.service';

@Component({
  selector: 'app-notifications-panel',
  templateUrl: './notifications-panel.component.html',
  styleUrls: ['./notifications-panel.component.scss'],
})
export class NotificationsPanelComponent implements OnInit {
  userAddress: string;

  constructor(readonly notificationService: NotificationService, readonly walletService: WalletService, readonly signingService: SigningService) {}

  ngOnInit(): void {
    this.walletService.userAddress().subscribe(v => (this.userAddress = v));
  }

  getObjktModel(token: any) {
    return token as ObjktModel;
  }
}
