import {NgModule} from '@angular/core';
import {CdnArtPipe} from './cdn-art.pipe';
import {TokenService} from '../../services/token.service';

@NgModule({
  providers: [TokenService],
  declarations: [CdnArtPipe],
  exports: [CdnArtPipe],
})
export class CdnArtPipeModule {}
