import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BatchTransferModalComponent} from './batch-transfer-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from '../modal.module';
import {ButtonsModule} from '../../buttons/buttons.module';
import {InfoPopoverModule} from '../../info-popover/info-popover.module';

@NgModule({
  declarations: [BatchTransferModalComponent],
  exports: [BatchTransferModalComponent],
  imports: [CommonModule, ReactiveFormsModule, ModalModule, ButtonsModule, InfoPopoverModule],
})
export class BatchTransferModalModule {}
