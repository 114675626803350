import {NgModule} from '@angular/core';
import {RouterModule, Routes, UrlSegment} from '@angular/router';
import {SiteLayoutComponent} from './site-layout.component';
import {HomeComponent} from './views/home/home.component';

const routes: Routes = [
  {
    path: '',
    component: SiteLayoutComponent,
    children: [
      {path: '', component: HomeComponent, pathMatch: 'full'},
      {
        path: 'profile/:address',
        redirectTo: 'profile/:address/created',
        pathMatch: 'full',
      },
      {
        path: 'o/:id',
        redirectTo: 'asset/hicetnunc/:id',
        pathMatch: 'full',
      },
      {
        path: 'objkt/:id',
        redirectTo: 'asset/hicetnunc/:id',
        pathMatch: 'full',
      },
      {
        path: 'page/:page',
        loadChildren: () => import('./views/static/static.module').then(m => m.StaticModule),
      },
      {
        path: 'profile/:address',
        loadChildren: () => import('./views/profile/profile.module').then(m => m.ProfileModule),
      },
      {
        path: 'feed',
        loadChildren: () => import('./views/feed/feed.module').then(m => m.FeedModule),
      },
      {
        path: 'mint/:tab/:contract',
        loadChildren: () => import('./views/mint/mint.module').then(m => m.MintModule),
      },
      {
        path: 'mint/:tab',
        loadChildren: () => import('./views/mint/mint.module').then(m => m.MintModule),
      },
      {
        path: 'mint',
        loadChildren: () => import('./views/mint/mint.module').then(m => m.MintModule),
      },
      {
        path: 'auction/:type/:id',
        loadChildren: () => import('./views/auction/auction.module').then(m => m.AuctionModule),
      },
      {
        path: 'collections',
        loadChildren: () => import('./views/collections/collections.module').then(m => m.CollectionsModule),
      },
      {
        path: 'search',
        redirectTo: 'explore/tokens',
        pathMatch: 'full',
      },
      {
        path: 'explore',
        loadChildren: () => import('./views/explore/explore.module').then(m => m.ExploreModule),
      },
      {
        path: 'collection/:contract',
        loadChildren: () => import('./views/collection/collection.module').then(m => m.CollectionModule),
      },
      {
        path: 'gallery/:registry/:slug',
        loadChildren: () => import('./views/gallery/gallery.module').then(m => m.GalleryModule),
      },
      {
        path: 'asset/:contract/:id',
        loadChildren: () => import('./views/asset/asset.module').then(m => m.AssetModule),
      },
      {
        path: 'faq',
        loadChildren: () => import('./views/faq/faq.module').then(m => m.FaqModule),
      },
      {
        path: 'trust-score',
        loadChildren: () => import('./views/trust-score/trust-score.module').then(m => m.TrustScoreModule),
      },
      {
        path: 'release',
        loadChildren: () => import('./views/release/release.module').then(m => m.ReleaseModule),
      },
      {
        path: 'live',
        loadChildren: () => import('./views/live/live.module').then(m => m.LiveModule),
      },
      {
        path: 'settings',
        loadChildren: () => import('./views/settings/settings.module').then(m => m.SettingsModule),
      },
      {
        path: 'new/:contract/:id',
        loadChildren: () => import('./views/new/new.module').then(m => m.NewModule),
      },
      {
        path: 'token-migration',
        loadChildren: () => import('./views/token-migration/token-migration.module').then(m => m.TokenMigrationModule),
      },
    ],
  },
  {
    path: '',
    children: [
      {
        path: 'review/2022/:address',
        loadChildren: () => import('./views/review/review.module').then(m => m.ReviewModule),
      },
      {
        path: 'review/2022',
        loadChildren: () => import('./views/review/review.module').then(m => m.ReviewModule),
      },
    ],
  },
  {
    matcher: url => {
      if (url.length === 1 && url[0].path.startsWith('@')) {
        return {
          consumed: url,
          posParams: {
            username: new UrlSegment(url[0].path.substr(1), {}),
          },
        };
      }

      return null;
    },
    component: SiteLayoutComponent,
  },
  {path: '404', component: SiteLayoutComponent, children: [{path: '**', loadChildren: () => import('./views/error/error.module').then(m => m.ErrorModule)}]},
  {
    path: '**',
    component: SiteLayoutComponent,
    children: [{path: '**', loadChildren: () => import('./views/error/error.module').then(m => m.ErrorModule)}],
  },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      // scrollPositionRestoration: 'enabled'
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
