import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TezComponent} from './tez.component';

@NgModule({
  declarations: [TezComponent],
  imports: [CommonModule],
  exports: [TezComponent],
})
export class TezModule {}
