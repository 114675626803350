import {NgModule} from '@angular/core';
import {APOLLO_NAMED_OPTIONS} from 'apollo-angular';
import {ApolloClientOptions, ApolloLink, InMemoryCache} from '@apollo/client/core';
import {HttpLink} from 'apollo-angular/http';
import {environment} from 'src/environments/environment';
import {setContext} from '@apollo/client/link/context';

export function createNamedApollo(httpLink: HttpLink): Record<string, ApolloClientOptions<any>> {
  const graphCmsAuth = setContext((operation, context) => ({
    headers: {
      'gcms-stage': environment.graphCmsStage,
      ...(environment.graphCmsAuth && {authorization: `Bearer ${environment.graphCmsAuth}`}),
    },
  }));

  return {
    indexer: {
      name: 'indexer',
      link: httpLink.create({
        uri: environment.indexerGraphql,
        withCredentials: true,
      }),
      cache: new InMemoryCache(),
    },
    backend: {
      name: 'backend',
      link: httpLink.create({
        uri: environment.backendGraphql,
        withCredentials: true,
      }),
      cache: new InMemoryCache(),
    },
    graphCms: {
      name: 'graphCms',
      link: ApolloLink.from([
        graphCmsAuth,
        httpLink.create({
          uri: environment.graphCmsGraphql,
          withCredentials: false,
        }),
      ]),
      cache: new InMemoryCache(),
    },
  };
}

@NgModule({
  providers: [
    {
      provide: APOLLO_NAMED_OPTIONS,
      useFactory: createNamedApollo,
      deps: [HttpLink],
    },
  ],
})
export class GraphQLModule {}
