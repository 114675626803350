import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LoginMessageComponent} from './login-message.component';

@NgModule({
  declarations: [LoginMessageComponent],
  exports: [LoginMessageComponent],
  imports: [CommonModule],
})
export class LoginMessageModule {}
