<div
  class="component-objkt-gallery-element gradient-loading"
  [class.done]="objkt"
  [class.select]="showSelect"
  [class.selected]="showSelect && objktSelect.items.hasOwnProperty(objkt.pk)"
  (click)="select($event)"
>
  <a
    class="token-preview"
    [routerLink]="objkt ? (objkt | objktLink) : undefined"
    [appDisableLink]="showSelect"
  >
    <app-objkt-preview [objkt]="objkt" [size]="imgSize"></app-objkt-preview>
    <app-gallery-mime [mime]="mime"></app-gallery-mime>
  </a>

  <div class="component-objkt-gallery-element-content frow column-start">
    <div class="collection frow justify-between width-100">
      <div class="col-xs-3-4">
        <div class="collection-link-wrapper frow row-start">
          <ng-container *ngIf="!isGalleryOverride; else galleryOverride">
            <app-info-popover
              *ngIf="objkt?.fa.contract"
              [collectionContract]="objkt?.fa.contract"
            >
              <a
                [class.open]="
                  faCollectionType === 'open' || faCollectionType === 'artist'
                "
                class="text-ellipsis"
                routerLink="{{
                  objkt ? (objkt?.fa | collectionLink) : undefined
                }}"
              >
                {{
                  objkt?.fa?.short_name ||
                    objkt?.fa?.name ||
                    (objkt?.fa?.contract | shortAddress) ||
                    '-'
                }}
              </a>
            </app-info-popover>
          </ng-container>
          <ng-template #galleryOverride>
            <app-info-popover
              *ngIf="objkt?.galleries_pk"
              [galleryPk]="objkt?.galleries_pk[0]"
            >
              <a
                class="text-ellipsis open text-ellipsis"
                routerLink="/explore/tokens"
                [queryParams]="{galleryPk: objkt?.galleries_pk[0]}"
              >
                {{ galleryOverrideCollectionName }}
              </a>
            </app-info-popover>
          </ng-template>
        </div>
      </div>
      <div class="col-xs-1-4 editions text-right">
        <app-info-popover
          *ngIf="objkt?.supply"
          [text]="
            objkt?.supply + (objkt?.supply > 1 ? ' editions' : ' edition')
          "
        >
          {{ objkt?.supply | number : '1.0-0' }}
          <small>x</small>
        </app-info-popover>
      </div>
    </div>

    <div class="title text-ellipsis">{{ objkt?.name || '-' }}</div>
    <div
      *ngIf="objkt?.creators?.length <= 1; else multipleCreators"
      class="person"
    >
      <app-info-popover
        *ngIf="objkt?.creators?.length === 1; else noCreator"
        [userAddress]="objkt?.creators[0].holder.address"
      >
        <a routerLink="/profile/{{ objkt?.creators[0].creator_address }}">
          {{ objkt?.creators[0].holder | username }}
        </a>
      </app-info-popover>
      <ng-template #noCreator>n/a</ng-template>
    </div>
    <ng-template #multipleCreators>
      <div class="person">
        <app-info-popover [text]="creators">
          <div class="text-ellipsis">
            <a [routerLink]="objkt | objktLink">{{ creators }}</a>
          </div>
        </app-info-popover>
      </div>
    </ng-template>
  </div>
  <div *ngIf="showPricing" class="pricing">
    <ng-container *ngIf="objkt?.auction_type; else defaultPricing">
      <div
        class="component-objkt-gallery-element-content frow column-start"
        *ngIf="auctionModel$ | async as auctionModel; else loadingAuction"
      >
        <div class="auction-status">
          <span
            class="{{
              auctionModel.inFuture ? 'scheduled' : objkt.auction_status
            }}"
          >
            <small>{{
              auctionModel.inFuture ? 'scheduled' : objkt.auction_status
            }}</small>
          </span>
        </div>
        <div *ngIf="auctionModel.highestBid; else noBid" class="title">
          {{ auctionModel.highestBid | tez | localisedNumber : '1.2-2' }}
          <app-tez></app-tez>
        </div>
        <ng-template #noBid>
          <div
            *ngIf="objkt.auction_reserve; else noPrice"
            class="title reserve"
          >
            {{ objkt.auction_reserve | tez | localisedNumber : '1.2-2' }}
            <app-tez></app-tez>
            <span style="font-size: 80%"> reserve</span>
          </div>
          <ng-template #noPrice>
            <div class="title">-</div>
          </ng-template>
        </ng-template>
        <div class="auction-type" *ngIf="objkt.auction_type === 'english'">
          Highest Bid Auction
        </div>
        <div class="auction-type" *ngIf="objkt.auction_type === 'dutch'">
          Price Drop Auction
        </div>
        <div class="auction-type" *ngIf="objkt.auction_type === 'open_edition'">
          Open Edition
        </div>
        <div
          *ngIf="
            objkt.auction_status !== 'finished' &&
              objkt.auction_status !== 'cancelled';
            else ended
          "
          class="time-remaining mt-5"
        >
          <ng-container *ngIf="auctionModel.countdown; else notStarted">
            <span class="amount">{{ auctionModel.countdown.days }}</span>
            <span class="time">d</span>
            <span class="amount">{{ auctionModel.countdown.hours }}</span>
            <span class="time">h</span>
            <span class="amount">{{ auctionModel.countdown.minutes }}</span>
            <span class="time">m</span>
            <span class="amount">{{ auctionModel.countdown.seconds }}</span>
            <span class="time">s</span>
          </ng-container>
          <ng-template #notStarted>
            <span class="time">Place bid to start</span>
          </ng-template>
        </div>
        <ng-template #ended>
          <div class="time-remaining mt-5">ended</div>
        </ng-template>
      </div>
      <ng-template #loadingAuction>
        <div
          class="component-objkt-gallery-element-content frow column-start gradient-loading"
        >
          <div class="title">
            ?
            <app-tez></app-tez>
          </div>
          <div class="auction-type">?</div>
          <div class="time-remaining mt-5">
            <span class="amount">?</span>
          </div>
        </div>
      </ng-template>
    </ng-container>
    <ng-template #defaultPricing>
      <div
        class="component-objkt-gallery-element-content width-100 frow justify-between"
      >
        <div class="text-ellipsis">
          <label class="mb-5">Price</label>
          <p
            *ngIf="listedPrice || listedPrice === 0"
            class="highest-offer mt-0 mb-0 mr-5 text-ellipsis"
          >
            {{ listedPrice | tez | localisedNumber : '1.2-2' }}
            <app-tez></app-tez>
          </p>
          <p
            *ngIf="!listedPrice && listedPrice !== 0"
            class="highest-offer mt-0 mb-0"
          >
            -
          </p>
        </div>
        <div class="text-ellipsis">
          <label class="mb-5">Offer</label>
          <p
            *ngIf="highestOffer"
            class="highest-offer mt-0 mb-0 text-right text-ellipsis"
          >
            {{ highestOffer | tez | localisedNumber : '1.2-2' }}
            <app-tez></app-tez>
          </p>
          <p *ngIf="!highestOffer" class="highest-offer mt-0 mb-0 text-right">
            -
          </p>
        </div>
      </div>
    </ng-template>
  </div>
  <ng-content></ng-content>
</div>
