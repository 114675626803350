import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjktPreviewComponent} from './objkt-preview.component';
import {PrerenderStopModule} from 'src/app/directives/prerender-stop/prerender-stop.module';

@NgModule({
  declarations: [ObjktPreviewComponent],
  imports: [CommonModule, PrerenderStopModule],
  exports: [ObjktPreviewComponent],
})
export class ObjktPreviewModule {}
