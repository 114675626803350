import Appsignal from '@appsignal/javascript';
import {environment} from '../environments/environment';
import {versions} from '../environments/versions';
import {plugin as pathDecorator} from '@appsignal/plugin-path-decorator';
import {plugin as windowEvents} from '@appsignal/plugin-window-events';
import {plugin as networkBreadcrumbs} from "@appsignal/plugin-breadcrumbs-network";

const ignoreErrors = [
  // Random plugins/extensions
  'top.GLOBALS',
  // See: http://blog.errorception.com/2012/03/tale-of-unfindable-js-error.html
  'originalCreateNotification',
  'canvas.contentDocument',
  'MyApp_RemoveAllHighlights',
  'http://tt.epicplay.com',
  "Can't find variable: ZiteReader",
  'jigsaw is not defined',
  'ComboSearch is not defined',
  'http://loading.retry.widdit.com/',
  'atomicFindClose',
  // Facebook borked
  'fb_xd_fragment',
  // ISP "optimizing" proxy - `Cache-Control: no-transform` seems to reduce this. (thanks @acdha)
  // See http://stackoverflow.com/questions/4113268/how-to-stop-javascript-injection-from-vodafone-proxy
  'bmi_SafeAddOnload',
  'EBCallBackMessageReceived',
  // See http://toolbar.conduit.com/Developer/HtmlAndGadget/Methods/JSInjection.aspx
  'conduitPage',
  // Generic error code from errors outside the security sandbox
  // You can delete this if using raven.js > 1.0, which ignores these automatically.
  'Script error.',
  // Avast extension error
  '_avast_submit',
  // most thrown error on objkt, seems to be a browser issue we can't solve
  'ResizeObserver loop limit exceeded',
  // these 3 are thrown by "@walletconnect/utils" and expected
  'Proposal expired',
  'connection failed for URL: wss://relay.walletconnect.com',
  'Syncing stopped manually'
].map(err => new RegExp(err));

const appsignal = new Appsignal({
  key: environment.appsignalKey,
  revision: versions.revision,
  ignoreErrors: ignoreErrors,
});

appsignal.use(pathDecorator());
appsignal.use(windowEvents());
appsignal.use(networkBreadcrumbs())

export { appsignal }
