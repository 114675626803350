import {Injectable} from '@angular/core';
import {Observable, Subject} from 'rxjs';
import {map, scan, startWith} from 'rxjs/operators';
import {TransactionModel, TransactionType} from 'src/app/types/types';

@Injectable({
  providedIn: 'root',
})
export class TransactionsService {
  transactions$: Observable<TransactionModel[]>;
  pendingTransactions$: Observable<TransactionModel[]>;
  pendingTransactionsCount$: Observable<number>;

  private addTransaction$ = new Subject<TransactionModel>();

  constructor() {
    this.transactions$ = this.addTransaction$.pipe(
      scan((acc, value) => {
        const hasTransaction = acc.find(transaction => transaction.hash === value.hash);
        if (hasTransaction) {
          return acc.map(transaction => (transaction.hash === value.hash ? value : transaction));
        }
        return [...acc, value];
      }, []),
      startWith([]),
    );

    this.pendingTransactions$ = this.transactions$.pipe(map(transactions => transactions.filter(trx => trx.pending)));
    this.pendingTransactionsCount$ = this.pendingTransactions$.pipe(map(transactions => transactions?.length || 0));
  }

  addTransaction(transaction: TransactionModel) {
    this.addTransaction$.next(transaction);
  }
}
