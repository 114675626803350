<div class="component-carousel" #itemsContainer>
  <app-virtual-scroller #scroll [items]="items" horizontal="true" class="items-container" (vsChange)="vsChange($event)">
    <div class="items-element" [ngStyle]="{width: itemsWidth}" *ngFor="let item of scroll.viewPortItems">
      <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
    </div>
  </app-virtual-scroller>

  <div class="drag-scroll-navigation frow justify-between centered" *ngIf="!loading">
    <div (click)="previous()" [class.hidden]="!page || page?.startIndex === 0" class="arrow previous frow center items-center">
      <span class="material-icons-outlined">arrow_back_ios</span>
    </div>
    <div (click)="next()" [class.hidden]="hideNext" class="arrow next frow center items-center">
      <span class="material-icons-outlined">arrow_forward_ios</span>
    </div>
  </div>
</div>
