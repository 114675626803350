import {Pipe, PipeTransform} from '@angular/core';
import {DateTime} from 'luxon';

@Pipe({
  name: 'toRelativeTime',
})
export class ToRelativeTimePipe implements PipeTransform {
  transform(value: any): string {
    return DateTime.fromISO(value).toRelative();
  }
}
