import {Component} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {EMPTY, Observable} from 'rxjs';
import {distinctUntilChanged, map, mergeMap, tap} from 'rxjs/operators';
import {WarningModalService} from './components/modal/warning-modal/warning-modal.service';
import {LoaderService} from './loader.service';
import {ObjktApiService} from './services/api/objkt-api.service';
import {AssetService} from './services/asset.service';
import {NotificationService} from './services/notification.service';
import {SigningService} from './services/tezos/signing.service';
import {WalletService} from './services/tezos/wallet.service';

@Component({
  selector: 'app-layout',
  templateUrl: './site-layout.component.html',
  styleUrls: ['./site-layout.component.scss'],
})
export class SiteLayoutComponent {
  title = 'objkt-bid-web';

  isLoading$: Observable<boolean>;
  transactionLoading$: Observable<boolean>;
  loadingText$: Observable<string>;

  constructor(
    readonly loaderService: LoaderService,
    readonly signingService: SigningService,
    readonly notificationService: NotificationService,
    readonly route: ActivatedRoute,
    readonly router: Router,
    readonly warningModalService: WarningModalService,
    readonly objktApiService: ObjktApiService,
    readonly walletService: WalletService,
    readonly assetService: AssetService,
  ) {
    this.isLoading$ = loaderService.isLoading$;
    this.transactionLoading$ = signingService.loading$;
    this.loadingText$ = signingService.loadingText$;

    const userNameListener = this.route.params
      .pipe(
        map(params => params.username),
        distinctUntilChanged(),
      )
      .subscribe(name => {
        if (name) {
          this.router.navigate([`/profile/${name}`]);
        } else if (name) {
          this.router.navigate(['/404'], {skipLocationChange: true});
        }
      });

    this.signingService
      .hasPermission()
      .pipe(
        mergeMap(hasPermission => {
          if (hasPermission) {
            return this.signingService.login(true);
          } else {
            this.signingService.isSigned$.next(false);
            return EMPTY;
          }
        }),
        tap(session => {
          if (session) {
            this.notificationService.refresh();
          }
        }),
      )
      .subscribe();

    this.assetService.cleanNotFound();
  }

  handleModalClose(success, modal: any) {
    modal.onClose(success);
    setTimeout(() => {
      this.warningModalService.remove(modal.id);
    });
  }
}
