<ng-container *ngIf="model$ | async as model">
  <div class="header-wrapper">
    <div class="frow-container full-width height-100">
      <div class="frow row-between height-100 nowrap">
        <div class="logo">
          <a routerLink="/">objkt<small>.com</small></a>
        </div>
        <div class="content-wrapper">
          <div class="hidden-xs">
            <div class="header-desktop">
              <ng-container *ngTemplateOutlet="searchBar; context: {isDesktop: true}"></ng-container>
              <ng-container *ngTemplateOutlet="pendingTransactionsButton"></ng-container>
              <ng-container *ngTemplateOutlet="liveButton"></ng-container>
              <ng-container *ngTemplateOutlet="exploreButton"></ng-container>
              <ng-container *ngTemplateOutlet="modeToggleButton"></ng-container>
              <ng-container *ngTemplateOutlet="notificationsButton; context: {withPopover: true}"></ng-container>
              <ng-container *ngTemplateOutlet="walletButton"></ng-container>
              <ng-container *ngTemplateOutlet="syncButton"></ng-container>
              <ng-container *ngTemplateOutlet="userIcon"></ng-container>
            </div>
          </div>
          <div class="visible-xs">
            <div class="header-mobile">
              <ng-container *ngTemplateOutlet="searchButton"></ng-container>
              <ng-container *ngTemplateOutlet="modeToggleButton"></ng-container>
              <ng-container *ngTemplateOutlet="walletButton"></ng-container>
              <ng-container *ngTemplateOutlet="mobileMenu"></ng-container>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>

  <ng-template #searchButton>
    <app-info-popover [custom]="searchBar" [placement]="'bottom'" [interactOnClick]="true" [hideOnClick]="true" [appendToParent]="true" [ignoreResize]="true">
      <a accessibleLink>
        <span class="material-icons">search</span>
      </a>
    </app-info-popover>
  </ng-template>

  <ng-template #searchBar let-isDesktop="isDesktop">
    <ng-container *ngIf="isDesktop; else plain">
      <div class="header-search">
        <app-info-popover
          [custom]="preview"
          [placement]="'bottom-start'"
          [interactOnClick]="true"
          [hideOnClick]="true"
          [appendToParent]="true"
          [ignoreResize]="true"
          [offset]="[0, 5]"
        >
          <input (keyup.enter)="search($event.target.value)" [formControl]="searchTerm" placeholder="Title, Artist, ID" type="text" />
        </app-info-popover>
        <ng-template #preview>
          <ng-container *ngIf="searchTerm$ | async as query">
            <app-search-preview [term]="query"></app-search-preview>
          </ng-container>
        </ng-template>
      </div>
    </ng-container>
    <ng-template #plain>
      <div class="header-search">
        <div class="header-search-input-wrapper">
          <input
            id="header-search-input-plain"
            (keyup.enter)="search($event.target.value)"
            [formControl]="searchTerm"
            placeholder="Title, Artist, ID"
            type="text"
          />
        </div>
        <ng-container *ngIf="searchTerm$ | async as query">
          <app-search-preview [term]="query"></app-search-preview>
        </ng-container>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #userIcon>
    <app-info-popover [custom]="userPopover" [interactOnClick]="true" [hideOnClick]="true" [appendToParent]="true" [offset]="[0, 6]" *ngIf="model.isSynced">
      <a accessibleLink>
        <app-avatar-image [user]="model.user"></app-avatar-image>
      </a>
    </app-info-popover>

    <ng-template #userPopover let-hide="hide">
      <div class="header-user-popover">
        <ng-container *ngTemplateOutlet="profileButton; context: {withIcon: true}"></ng-container>
        <ng-container *ngTemplateOutlet="createButton; context: {withIcon: true}"></ng-container>
        <ng-container *ngTemplateOutlet="settingsButton; context: {withIcon: true}"></ng-container>
        <ng-container *ngTemplateOutlet="logoutButton; context: {withIcon: true, hide: hide}"></ng-container>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #mobileMenu>
    <app-info-popover
      [custom]="mobilePopover"
      [interactOnClick]="true"
      [hideOnClick]="true"
      [appendToParent]="true"
      [offset]="[0, 14]"
      (opened)="mobileMenuOpened = true"
      (closed)="mobileMenuOpened = false"
    >
      <a accessibleLink class="burger-menu" [class.open]="mobileMenuOpened"><span></span><span></span><span></span></a>
    </app-info-popover>

    <ng-template #mobilePopover let-hide="hide">
      <div class="header-mobile-menu-popover">
        <div class="header-mobile-menu-group">
          <ng-container *ngTemplateOutlet="liveButton; context: {withIcon: true}"></ng-container>
          <ng-container *ngTemplateOutlet="exploreButton; context: {withIcon: true}"></ng-container>
          <ng-container *ngTemplateOutlet="profileButton; context: {withIcon: true}"></ng-container>
          <ng-container *ngTemplateOutlet="createButton; context: {withIcon: true}"></ng-container>
          <ng-container *ngTemplateOutlet="notificationsButton; context: {withIcon: true}"></ng-container>
          <ng-container *ngTemplateOutlet="settingsButton; context: {withIcon: true}"></ng-container>
        </div>
        <ng-container *ngTemplateOutlet="syncButton; context: {withIcon: true}"></ng-container>
        <ng-container *ngTemplateOutlet="logoutButton; context: {withIcon: true, hide: hide}"></ng-container>
      </div>
    </ng-template>
  </ng-template>

  <ng-template #walletButton>
    <app-info-popover [custom]="walletPopover" [interactOnClick]="true" [hideOnClick]="true" [appendToParent]="true" *ngIf="model.isSynced">
      <a accessibleLink>
        <span class="material-icons">account_balance_wallet</span>
      </a>
    </app-info-popover>

    <ng-template #walletPopover let-hide="hide">
      <app-wallet-panel (action)="showWrapModal = true; hide()" class="header-wallet-panel"></app-wallet-panel>
    </ng-template>
  </ng-template>

  <ng-template #notificationsButton let-withPopover="withPopover" let-withIcon="withIcon">
    <ng-container *ngIf="withPopover; else plain">
      <app-info-popover
        [custom]="notificationsPopover"
        [interactOnClick]="true"
        [hideOnClick]="true"
        [appendToParent]="true"
        (closed)="markAllAsRead()"
        *ngIf="model.isSynced"
      >
        <a accessibleLink>
          <span class="header-notification-count" *ngIf="model.unreadNotifications">{{ model.unreadNotifications }}</span>
          <span class="material-icons">notifications</span>
        </a>
      </app-info-popover>

      <ng-template #notificationsPopover>
        <app-notifications-panel></app-notifications-panel>
      </ng-template>
    </ng-container>

    <ng-template #plain>
      <a *ngIf="model.isSynced" [routerLink]="'profile/' + model.address + '/notifications'">
        <span class="header-notification-count" *ngIf="model.unreadNotifications">{{ model.unreadNotifications }}</span>
        <span class="material-icons" *ngIf="withIcon">notifications</span>
        Notifications
      </a>
    </ng-template>
  </ng-template>

  <ng-template #modeToggleButton>
    <a accessibleLink (click)="switchMode(!model.isDarkMode)">
      <span class="material-icons">{{ model.isDarkMode ? 'light_mode' : 'dark_mode' }}</span>
    </a>
  </ng-template>

  <ng-template #pendingTransactionsButton>
    <app-info-popover [custom]="pendingTransactionsPopover" [interactOnClick]="true" [hideOnClick]="true" [appendToParent]="true" [offset]="[0, 13]">
      <a accessibleLink class="pending-transactions">
        <span [attr.aria-busy]="model.pendingTransactions > 0"></span>
      </a>
    </app-info-popover>

    <ng-template #pendingTransactionsPopover>
      <app-transactions-panel></app-transactions-panel>
    </ng-template>
  </ng-template>

  <ng-template #syncButton let-withIcon="withIcon">
    <a accessibleLink *ngIf="!model.isSynced" (click)="syncWallet()" class="sync" [class.is-loading]="model.isSyncing">
      <ng-container *ngIf="model.isSyncing; else sync">
        <span class="material-icons syncing-icon">sync</span>
        Loading...
      </ng-container>
      <ng-template #sync>
        <span class="material-icons" *ngIf="withIcon">sync</span>
        Sync
      </ng-template>
    </a>
  </ng-template>

  <ng-template #liveButton let-withIcon="withIcon">
    <a routerLink="/live">
      <span class="material-icons" *ngIf="withIcon">radio_button_checked</span>
      Live
    </a>
  </ng-template>

  <ng-template #exploreButton let-withIcon="withIcon">
    <a routerLink="/explore/tokens">
      <span class="material-icons" *ngIf="withIcon">explore</span>
      Explore
    </a>
  </ng-template>

  <ng-template #createButton let-withIcon="withIcon">
    <a *ngIf="model.isSynced" routerLink="/mint">
      <span class="material-icons" *ngIf="withIcon">add_circle</span>
      Create
    </a>
  </ng-template>

  <ng-template #profileButton let-withIcon="withIcon">
    <a *ngIf="model.isSynced" [routerLink]="'profile/' + model.address">
      <span class="material-icons" *ngIf="withIcon">account_circle</span>
      Profile
    </a>
  </ng-template>

  <ng-template #settingsButton let-withIcon="withIcon">
    <a *ngIf="model.isSynced" routerLink="/settings">
      <span class="material-icons" *ngIf="withIcon">settings</span>
      Settings
    </a>
  </ng-template>

  <ng-template #logoutButton let-withIcon="withIcon" let-hide="hide">
    <a accessibleLink *ngIf="model.isSynced" (click)="logout(); hide()" class="logout">
      <span class="material-icons" *ngIf="withIcon">logout</span>
      Logout
    </a>
  </ng-template>

  <app-wrap-modal *ngIf="showWrapModal" [(show)]="showWrapModal"></app-wrap-modal>
</ng-container>
