import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {map, switchMap, defaultIfEmpty} from 'rxjs/operators';
import {
  IndexerFa_Bool_Exp,
  IndexerFa_Order_By,
  IndexerGetAllFa2GQL,
  IndexerGetAllFa2WithAggregateGQL,
  IndexerGetFa2AttributesGQL,
  IndexerGetFa2ByCollectionIdGQL,
  IndexerGetFa2GQL,
  IndexerGetFa2ManagersGQL,
  IndexerGetFa2PathGQL,
  IndexerGetFa2UnverifiedCreatorGQL,
  IndexerGetFaCollectorsGQL,
  IndexerGetFaFloorPriceGQL,
  IndexerGetGalleryAttributesGQL,
  IndexerGetUserActionableCollectionsGQL,
  IndexerGetUserCollectionsGQL,
  IndexerGetUserOwnedCollectionsGQL,
} from 'src/graphql/gen/indexer';
import {FaLightModel, FaModel, UserCollectionModel} from 'src/app/types/types';
import {UserApiService} from './user-api.service';
import {BackendToggleIsHiddenFa2GQL, BackendUpdateContractFa2GQL, BackendUpdateManagersFa2GQL} from '../../../graphql/gen/backend';
import {HttpClient} from '@angular/common/http';
import {ObjktApiService} from './objkt-api.service';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class Fa2ApiService {
  constructor(
    readonly fa2: IndexerGetFa2GQL,
    readonly fa2Path: IndexerGetFa2PathGQL,
    readonly fa2BycollectionId: IndexerGetFa2ByCollectionIdGQL,
    readonly allFa2: IndexerGetAllFa2GQL,
    readonly allFa2WithAggregate: IndexerGetAllFa2WithAggregateGQL,
    readonly userCollections: IndexerGetUserCollectionsGQL,
    readonly userOwnedCollections: IndexerGetUserOwnedCollectionsGQL,
    readonly userActionableCollections: IndexerGetUserActionableCollectionsGQL,
    readonly userApiService: UserApiService,
    readonly fa2Attributes: IndexerGetFa2AttributesGQL,
    readonly galleryAttributes: IndexerGetGalleryAttributesGQL,
    readonly toggleIsHiddenFa2: BackendToggleIsHiddenFa2GQL,
    readonly faGetCollectors: IndexerGetFaCollectorsGQL,
    readonly getFaFloorPrice: IndexerGetFaFloorPriceGQL,
    readonly objktApiService: ObjktApiService,
    readonly httpClient: HttpClient,
    readonly updateManagersFa2: BackendUpdateManagersFa2GQL,
    readonly fa2Managers: IndexerGetFa2ManagersGQL,
    readonly updateContractFa2: BackendUpdateContractFa2GQL,
    readonly fa2UnverifiedCreator: IndexerGetFa2UnverifiedCreatorGQL,
  ) {}

  getFa2PathOrContract(pathOrContract: string) {
    if (this.isContract(pathOrContract)) {
      return this.getFa2(pathOrContract);
    } else {
      return this.getFa2Path(pathOrContract);
    }
  }

  getFa2Path(path: string) {
    return this.fa2Path
      .fetch(
        {path},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(
          res =>
            res.data.fa &&
            res.data.fa.length && {
              ...res.data.fa[0],
              creator: this.userApiService.extendUser(res.data.fa[0] && res.data.fa[0].creator),
            },
        ),
        switchMap(fa2 => this.addAsyncCollaboratorData(fa2)),
        switchMap(fa2 => this.addAsyncCreatorData(fa2)),
      );
  }

  getFa2(fa2: string): Observable<FaModel> {
    return this.fa2
      .fetch(
        {contract: fa2},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(
          res =>
            res.data.fa_by_pk && {
              ...res.data.fa_by_pk,
              creator: this.userApiService.extendUser(res.data.fa_by_pk && res.data.fa_by_pk.creator),
              collaborators: res.data.fa_by_pk.collaborators
                ?.map(invitation => ({
                  ...invitation,
                  holder: this.userApiService.extendUser(invitation.holder),
                }))
                .filter(invitation => invitation.status === 'accepted'),
            },
        ),
        switchMap(fa2 => this.addAsyncCollaboratorData(fa2)),
        switchMap(fa2 => this.addAsyncCreatorData(fa2)),
      );
  }

  getFa2ByCollectionId(collectionId: string): Observable<FaModel> {
    return this.fa2BycollectionId
      .fetch(
        {collectionId},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(
          res =>
            res.data.fa &&
            res.data.fa.length && {
              ...res.data.fa[0],
              creator: this.userApiService.extendUser(res.data.fa[0] && res.data.fa[0].creator),
            },
        ),
        switchMap(fa2 => this.addAsyncCreatorData(fa2)),
      );
  }

  getUserActionableCollections(address: string): Observable<FaLightModel[]> {
    const hiddenContracts = ['KT1TF4jpdZfmzWjt3GtcdtY7CtgxMhdpF93u', 'KT1JoCZiDbi5VtBEUh8maaxAopBkYLkcJUkH'];
    return this.userActionableCollections
      .fetch(
        {address},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(res =>
          res.data.fa.map(fa => ({
            ...fa,
            creator: this.userApiService.extendUser(fa.creator),
          })),
        ),
        map(res => {
          return res.filter(fa2 => !hiddenContracts.includes(fa2.contract));
        }),
      );
  }

  getUserCollections(address: string): Observable<UserCollectionModel[]> {
    // hidden DOGAMI & fxhash contracts & emprops
    const hiddenContracts = [
      'KT1TF4jpdZfmzWjt3GtcdtY7CtgxMhdpF93u',
      'KT1JoCZiDbi5VtBEUh8maaxAopBkYLkcJUkH',
      'KT1KEa8z6vWXDJrVqtMrAeDVzsvxat3kHaCE',
      'KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi',
      'KT1MhSRKsujc4q5b5KsXvmsvkFyht9h4meZs',
    ];
    return this.userCollections
      .fetch(
        {address},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(res => {
          return res.data.fa
            .filter(fa => !hiddenContracts.includes(fa.contract))
            .map(fa => ({
              name: fa.name,
              contract: fa.contract,
              collection_id: fa.collection_id,
              collection_type: fa.collection_type,
              path: fa.path,
              logo: fa.logo,
              live: fa.live,
              count: fa.tokens_aggregate?.aggregate?.count || 0,
            }));
        }),
      );
  }

  getUserOwnedCollections(address: string) {
    return this.userOwnedCollections.fetch({address}, {fetchPolicy: 'no-cache'}).pipe(map(res => res.data.fa));
  }

  getFa2UnverifiedCreator(currentUser: string) {
    return this.fa2UnverifiedCreator.fetch({currentUser}, {fetchPolicy: 'no-cache'}).pipe(map(res => res.data.fa));
  }

  getAllFa2(orderBy: IndexerFa_Order_By, where: IndexerFa_Bool_Exp = {live: {_eq: true}}, limit: number = null, offset: number = null) {
    return this.allFa2
      .fetch(
        {where, order_by: orderBy, limit, offset},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data.fa));
  }

  getAllFa2WithAggregate(orderBy: IndexerFa_Order_By, where: IndexerFa_Bool_Exp = {live: {_eq: true}}, limit: number = null, offset: number = null) {
    return this.allFa2WithAggregate
      .fetch(
        {where, order_by: orderBy, limit, offset},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data));
  }

  getFeaturedFa2(orderBy: IndexerFa_Order_By) {
    return this.allFa2
      .fetch(
        {
          where: {live: {_eq: true}, collection_type: {_neq: 'artist'}},
          order_by: orderBy,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data.fa));
  }

  getFaCollectors(contract: string) {
    return this.faGetCollectors
      .fetch(
        {contract},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(res => {
          const result: any = {};
          const addressesToRemove = [...environment.burnWallets, ...environment.legacyWallets];
          for (const token of res.data.fa?.[0].tokens || []) {
            for (const holder of token.holders) {
              if (addressesToRemove.includes(holder.holder_address)) {
                continue;
              }
              if (holder.holder_address in result) {
                result[holder.holder_address].quantity += holder.quantity || 0;
                continue;
              }
              result[holder.holder_address] = holder;
            }
          }
          return Object.values<any>(result);
        }),
      );
  }

  getAttributeCount(pathOrContract: string) {
    if (this.isContract(pathOrContract)) {
      return this.getAttributeCountContract(pathOrContract);
    } else {
      return this.getAttributeCountPath(pathOrContract);
    }
  }

  getAttributeCountByIds(contract: string, attributeIds: number[]) {
    return this.fa2Attributes
      .fetch({
        where: {
          fa_contract: {_eq: contract},
          attribute_id: {_in: attributeIds},
        },
      })
      .pipe(map(res => res.data.fa2_attribute_count));
  }

  getGalleryAttributeCountByIds(galleryPk: number, attributeIds: number[]) {
    return this.galleryAttributes
      .fetch({
        where: {
          gallery_pk: {_eq: galleryPk},
          attribute_id: {_in: attributeIds},
        },
      })
      .pipe(map(res => res.data.gallery_attribute_count));
  }

  getManagers(contract: string) {
    return this.fa2Managers
      .fetch(
        {
          contract,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data.fa_by_pk.managers));
  }

  updateManagers(contract: string, addWallets: string[] = [], removeWallets: string[] = []) {
    return this.updateManagersFa2.mutate({
      contract,
      add: addWallets,
      remove: removeWallets,
    });
  }

  updateContract(contract: string, metadata) {
    return this.updateContractFa2.mutate({
      contract,
      metadata,
    });
  }

  toggleIsHidden(contract, hidden) {
    return this.toggleIsHiddenFa2.mutate({
      contract,
      hidden,
    });
  }

  recentlyHiddenToggeled(collectionId: string) {
    return this.httpClient.get(`https://back.objkt.com/api/v1/collections/hidden_updates`).pipe(
      map((res: any) => {
        const collection = res.collections.find(col => col.id + '' === collectionId);
        return collection ? collection.hidden : null;
      }),
    );
  }

  getFloorPrice(contract: string) {
    return this.getFaFloorPrice.fetch({contract}, {fetchPolicy: 'no-cache'}).pipe(map(res => res.data.fa_by_pk.floor_price || 0));
  }

  private addAsyncCreatorData(fa2) {
    return fa2.creator
      ? this.userApiService.addAvatarToUser(fa2.creator).pipe(
          map(creator => ({
            ...fa2,
            creator,
          })),
        )
      : of(fa2);
  }

  private addAsyncCollaboratorData(fa2): Observable<any> {
    return forkJoin(
      fa2.collaborators?.map(collaborator =>
        this.userApiService.addAvatarToUser(collaborator.holder).pipe(
          map(user => ({
            ...collaborator,
            holder: user,
          })),
        ),
      ),
    ).pipe(
      defaultIfEmpty([]),
      map(collaborators => ({
        ...fa2,
        collaborators,
      })),
    );
  }

  private getAttributeCountPath(path: string) {
    return this.fa2Attributes
      .fetch({
        where: {fa: {path: {_eq: path}}},
      })
      .pipe(map(res => res.data.fa2_attribute_count));
  }

  private getAttributeCountContract(contract: string) {
    return this.fa2Attributes
      .fetch({
        where: {fa_contract: {_eq: contract}},
      })
      .pipe(map(res => res.data.fa2_attribute_count));
  }

  private isContract(pathOrContract: string) {
    return pathOrContract.toLowerCase().startsWith('kt') && pathOrContract.length === 36;
  }

  private getQueryForExploreSearch(search: string) {
    if (!search) {
      return null;
    }
    return {
      _or: [
        {
          name: {
            _ilike: `%${search}%`,
          },
        },
        {
          creator_address: {
            _eq: search,
          },
        },
        {
          creator: {
            alias: {
              _ilike: `%${search}%`,
            },
          },
        },
        {
          creator: {
            tzdomain: {
              _ilike: `%${search}%`,
            },
          },
        },
      ],
    };
  }
}
