import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {defaultIfEmpty, map, switchMap, tap} from 'rxjs/operators';
import {IndexerGetSalesStatsGQL} from 'src/graphql/gen/indexer';
import {SalesStatsModel} from 'src/app/types/types';
import {UserApiService} from './user-api.service';
import {stat} from 'fs';

@Injectable({
  providedIn: 'root',
})
export class SalesStatsApiService {
  constructor(readonly salesStats: IndexerGetSalesStatsGQL, readonly userApiService: UserApiService) {}

  getTopBuyers(days: number): Observable<SalesStatsModel[]> {
    return this.salesStats.fetch({days, type: 'buyer', limit: 10}).pipe(
      map(res => res.data.sales_stat),
      map(stats =>
        stats.map(stat => ({
          ...stat,
          subject: this.userApiService.extendUser(stat.subject),
        })),
      ),
      switchMap(stats => this.addAsyncUserData(stats)),
    );
  }

  getTopSellers(days: number): Observable<SalesStatsModel[]> {
    return this.salesStats.fetch({days, type: 'artist', limit: 10}).pipe(
      map(res => res.data.sales_stat),
      map(stats =>
        stats.map(stat => ({
          ...stat,
          subject: this.userApiService.extendUser(stat.subject),
        })),
      ),
      switchMap(stats => this.addAsyncUserData(stats)),
    );
  }

  private addAsyncUserData(stats: SalesStatsModel[]) {
    return forkJoin(
      stats.map(stat =>
        this.userApiService.addAvatarToUser(stat.subject).pipe(
          map(user => ({
            ...stat,
            subject: user,
          })),
        ),
      ),
    ).pipe(defaultIfEmpty([]));
  }
}
