<app-modal [(show)]="show" (showChange)="showChange.next($event)" [title]="title">
  <ng-container body>
    <p *ngIf="tooManyObjktsError">{{ tooManyObjktsError }}</p>
    <form id="objktForm" [formGroup]="objktsForm" (ngSubmit)="handleBatchTransfer()">
      <table class="batch-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Owned</th>
            <th>
              <div class="frow row-between">
                <span>Amount</span>
                <a class="max-amount-trigger" (click)="setMaxOwned()">(Set Max)</a>
              </div>
            </th>
            <th>Address</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let objktFormGroup of objktsFormArray.controls; let i = index">
            <tr [formGroup]="getObjktFormGroupByIndex(i)">
              <td>{{ objkts[i].token_id }}</td>
              <td>{{ objkts[i].name }}</td>
              <td>
                <a (click)="getFormControl(i, 'amount').setValue(objkts[i].owned_quantity)">{{ objkts[i].owned_quantity }}</a>
              </td>
              <td>
                <input type="number" formControlName="amount" placeholder="1" style="width: 140px" />
                <div class="error-text-container">
                  <ng-container *ngIf="getFormControl(i, 'amount').invalid && (!getFormControl(i, 'amount').pristine || getFormControl(i, 'amount').touched)">
                    <div *ngIf="getFormControl(i, 'amount').errors.pattern" class="error-text">Must be an integer</div>
                    <div *ngIf="getFormControl(i, 'amount').errors.number" class="error-text">{{ showNumberError(i, 'amount') }}</div>
                  </ng-container>
                </div>
              </td>
              <td>
                <div class="address-input-wrapper">
                  <input formControlName="address" placeholder="tz..." style="width: 230px" />
                  <app-info-popover [text]="'Copy to all address fields'">
                    <a (click)="copyAddressToAll(getFormControl(i, 'address').value)"><span class="material-icons">copy_all</span></a>
                  </app-info-popover>
                </div>
                <div class="error-text-container">
                  <ng-container
                    *ngIf="getFormControl(i, 'address').invalid && (!getFormControl(i, 'address').pristine || getFormControl(i, 'address').touched)"
                  >
                    <div *ngIf="getFormControl(i, 'address').errors.address" class="error-text">Invalid address</div>
                  </ng-container>
                </div>
              </td>
              <td>
                <app-icon-button (click)="handleRemoveObjkt(i)">
                  <span class="material-icons-sharp remove"> remove_circle </span>
                </app-icon-button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </form>
  </ng-container>
  <ng-container footer>
    <div class="frow row-end">
      <button [attr.aria-busy]="loading" form="objktForm" type="submit" [disabled]="!!tooManyObjktsError">Send tokens</button>
    </div>
  </ng-container>
</app-modal>
