import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RoundedButtonComponent} from './rounded-button/rounded-button.component';
import {TextButtonComponent} from './text-button/text-button.component';
import {ButtonComponent} from './button/button.component';
import {IconButtonComponent} from './icon-button/icon-button.component';
import {ContextActionButtonComponent} from './context-action-button/context-action-button.component';

@NgModule({
  declarations: [ButtonComponent, RoundedButtonComponent, TextButtonComponent, IconButtonComponent, ContextActionButtonComponent],
  exports: [ButtonComponent, RoundedButtonComponent, TextButtonComponent, IconButtonComponent, ContextActionButtonComponent],
  imports: [CommonModule],
})
export class ButtonsModule {}
