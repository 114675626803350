import {ChangeDetectionStrategy, ChangeDetectorRef, Component, ElementRef, Input, OnChanges, OnInit, SimpleChanges, ViewChild} from '@angular/core';
import {ObjktModel, ObjktPreviewSizes} from 'src/app/types/types';
import {PreviewService} from 'src/app/services/preview.service';
import {HttpClient} from '@angular/common/http';
import {DomSanitizer, SafeUrl} from '@angular/platform-browser';
import {ContentFilteringService} from 'src/app/services/content-filtering.service';
import {Observable} from 'rxjs';

@Component({
  selector: 'app-objkt-preview',
  templateUrl: './objkt-preview.component.html',
  styleUrls: ['./objkt-preview.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class ObjktPreviewComponent implements OnChanges, OnInit {
  @Input() objkt: ObjktModel;
  @Input() size = ObjktPreviewSizes.Thumb288;
  @ViewChild('img', {static: false}) img: ElementRef<HTMLImageElement>;

  loading = true;
  imgUrl: string;
  dataImgUrl: SafeUrl;
  imgIpfsUrl: string;
  hasTriedIpfs = false;
  isSmol: boolean;
  isSmall = false;
  isRestricted: boolean;
  isDomain: boolean;
  isEndlessWays: boolean;
  is8scribo: boolean;
  isPlenty: boolean;
  hideMature$: Observable<boolean>;
  isMature: boolean;
  hideFlashing$: Observable<boolean>;
  isFlashing: boolean;

  constructor(
    readonly previewService: PreviewService,
    readonly httpClient: HttpClient,
    readonly cdr: ChangeDetectorRef,
    readonly domSanitizer: DomSanitizer,
    readonly contentFiltering: ContentFilteringService,
  ) {}

  ngOnInit(): void {
    this.hideMature$ = this.contentFiltering.hideMature$;
    this.hideFlashing$ = this.contentFiltering.hideFlashing$;
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (this.objkt) {
      this.isDomain = this.objkt?.fa_contract === 'KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS';
      this.isEndlessWays = this.objkt.fa_contract === 'KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB';
      this.is8scribo = this.objkt.fa_contract === 'KT1Aq1umaV8gcDQmi4CLDk7KeKpoUjFQeg1B';
      this.isPlenty = this.objkt.fa_contract === 'KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw';

      this.updateImageSrc();

      this.isRestricted = this.objkt.flag === 'banned' || this.objkt.flag === 'flagged';
      if (this.objkt.attributes !== undefined) {
        this.isMature = this.contentFiltering.isMature(this.objkt.attributes);
        this.isFlashing = this.contentFiltering.isFlashing(this.objkt.attributes);
      }

      if (this.isEndlessWays) {
        const obsv$ = this.previewService.getEndlessWaysArtifact(this.objkt).subscribe(res => {
          obsv$.unsubscribe();
          this.imgUrl = res;
          this.cdr.detectChanges();
        });
      }
    }
  }

  handleImageLoad() {
    this.loading = false;
    if (this.img.nativeElement.naturalWidth <= 128 && this.img.nativeElement.naturalHeight <= 128) {
      this.isSmol = true;
    }
    this.isSmall = this.img.nativeElement.width <= 128;
  }

  handleImageError() {
    if (!this.hasTriedIpfs) {
      this.imgUrl = this.imgIpfsUrl;
      this.hasTriedIpfs = true;
    } else {
      this.imgUrl = 'assets/blank.jpeg';
    }
  }

  private updateImageSrc() {
    this.hasTriedIpfs = false;
    this.loading = true;

    // special case for plenty. Use the thumbnail_uri in preview
    if (this.isPlenty && this.objkt.thumbnail_uri?.startsWith('data:image/')) {
      this.dataImgUrl = this.domSanitizer.bypassSecurityTrustUrl(this.objkt.thumbnail_uri);
    }

    this.imgUrl = this.previewService.getObjktPreview(this.objkt, this.size);
    this.imgIpfsUrl = this.previewService.getObjktIpfsPreview(this.objkt);
  }
}
