import {ToastrModule} from 'ngx-toastr';

import {CommonModule} from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, ErrorHandler, NgModule} from '@angular/core';
import {BrowserModule, Meta, Title} from '@angular/platform-browser';
import {HttpClientModule, HTTP_INTERCEPTORS} from '@angular/common/http';
import {BrowserAnimationsModule} from '@angular/platform-browser/animations';
import {createErrorHandlerFactory} from '@appsignal/angular';

import {AppInterceptor} from './app.interceptor';
import {appsignal} from './appsignal';

import {IpfsPipe} from './pipes/ipfs/ipfs.pipe';
import {CdnArtPipe} from './pipes/cdn-art/cdn-art.pipe';
import {UsernamePipe} from './pipes/username/username.pipe';
import {ObjktLinkPipe} from './pipes/objkt-links/objkt-link.pipe';
import {CollectionLinkPipe} from './pipes/collection-link/collection-link.pipe';
import {CollectionImagePipe} from './pipes/collection-image/collection-image.pipe';

import {ImageLoadingDirective} from './directives/image-loading.directive';

import {GraphQLModule} from './graphql.module';
import {TezModule} from './components/tez/tez.module';
import {AppRoutingModule} from './app-routing.module';
import {TezPipeModule} from './pipes/tez/tez-pipe.module';
import {ModalModule} from './components/modal/modal.module';
import {IpfsPipeModule} from './pipes/ipfs/ipfs-pipe.module';
import {HeaderModule} from './components/header/header.module';
import {FooterModule} from './components/footer/footer.module';
import {ButtonsModule} from './components/buttons/buttons.module';
import {CarouselModule} from './components/carousel/carousel.module';
import {CdnArtPipeModule} from './pipes/cdn-art/cdn-art-pipe.module';
import {ShufflePipeModule} from './pipes/shuffle/shuffle-pipe.module';
import {SafePipeModule} from './pipes/safe/safe-pipe.module';
import {UsernamePipeModule} from './pipes/username/username-pipe.module';
import {TokenListModule} from './components/token-list/token-list.module';
import {TopElementModule} from './components/top-element/top-element.module';
import {ObjktLinkPipeModule} from './pipes/objkt-links/objkt-link-pipe.module';
import {StatusBannerModule} from './components/status-banner/status-banner.module';
import {CategoryLinkModule} from './components/category-link/category-link.module';
import {TokenCarouselModule} from './components/token-carousel/token-carousel.module';
import {TopCollectionModule} from './components/top-collection/top-collection.module';
import {FeaturedObjktModule} from './components/featured-objkt/featured-objkt.module';
import {PrerenderStopModule} from './directives/prerender-stop/prerender-stop.module';
import {ToRelativeTimeModule} from './pipes/to-relative-time/to-relative-time.module';
import {LocalisedNumberModule} from './pipes/localised-number/localised-number.module';
import {WarningModalModule} from './components/modal/warning-modal/warning-modal.module';
import {ColorPickerRgbaModule} from './pipes/color-picker-rgba/color-picker-rgba.module';
import {AuctionCarouselModule} from './components/auction-carousel/auction-carousel.module';
import {CollectionLinkPipeModule} from './pipes/collection-link/collection-link-pipe.module';
import {CollectionImagePipeModule} from './pipes/collection-image/collection-image-pipe.module';
import {HangingListingsModule} from './components/modal/hanging-listings/hanging-listings.module';
import {ObjktGalleryElementModule} from './components/objkt-gallery-element/objkt-gallery-element.module';
import {ObjktAuctionElementModule} from './components/objkt-auction-element/objkt-auction-element.module';

import {AppComponent} from './app.component';
import {HomeComponent} from './views/home/home.component';
import {SiteLayoutComponent} from './site-layout.component';

@NgModule({
  declarations: [AppComponent, HomeComponent, ImageLoadingDirective, SiteLayoutComponent],
  imports: [
    // Angular
    CommonModule,
    BrowserModule,
    AppRoutingModule,
    HttpClientModule,
    BrowserAnimationsModule,

    // 3rd party
    ToastrModule.forRoot({
      positionClass: 'toast-bottom-right',
      maxOpened: 5,
      closeButton: true,
      newestOnTop: false,
      tapToDismiss: true,
      timeOut: 15000,
      extendedTimeOut: 15000,
      progressBar: true,
      progressAnimation: 'decreasing',
      autoDismiss: true,
    }),

    // Objkt
    TezModule,
    ModalModule,
    HeaderModule,
    FooterModule,
    GraphQLModule,
    GraphQLModule,
    ButtonsModule,
    TezPipeModule,
    CarouselModule,
    CarouselModule,
    CarouselModule,
    IpfsPipeModule,
    TokenListModule,
    TopElementModule,
    TopElementModule,
    CdnArtPipeModule,
    ShufflePipeModule,
    SafePipeModule,
    UsernamePipeModule,
    WarningModalModule,
    CategoryLinkModule,
    StatusBannerModule,
    TopCollectionModule,
    FeaturedObjktModule,
    TokenCarouselModule,
    ObjktLinkPipeModule,
    ObjktLinkPipeModule,
    PrerenderStopModule,
    ToRelativeTimeModule,
    LocalisedNumberModule,
    ColorPickerRgbaModule,
    HangingListingsModule,
    AuctionCarouselModule,
    CollectionLinkPipeModule,
    ObjktGalleryElementModule,
    ObjktAuctionElementModule,
    CollectionImagePipeModule,
  ],
  exports: [],
  providers: [
    {
      provide: ErrorHandler,
      useFactory: createErrorHandlerFactory(appsignal),
    },
    {
      provide: HTTP_INTERCEPTORS,
      useClass: AppInterceptor,
      multi: true,
    },
    Meta,
    Title,
    CdnArtPipe,
    CollectionImagePipe,
    CollectionLinkPipe,
    UsernamePipe,
    ObjktLinkPipe,
    IpfsPipe,
  ],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA],
})
export class AppModule {}
