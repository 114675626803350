import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {LocalisedNumberPipe} from './localised-number.pipe';

@NgModule({
  declarations: [LocalisedNumberPipe],
  exports: [LocalisedNumberPipe],
  imports: [CommonModule],
})
export class LocalisedNumberModule {}
