import {Component, Input, OnInit} from '@angular/core';
import {CuratedTokenCategory} from 'src/app/views/home/home.component';

@Component({
  selector: 'app-category-link',
  templateUrl: './category-link.component.html',
  styleUrls: ['./category-link.component.scss'],
})
export class CategoryLinkComponent implements OnInit {
  @Input() category: CuratedTokenCategory;

  constructor() {}

  ngOnInit(): void {}
}
