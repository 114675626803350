<a [class.disabled]="!person" class="component-top-element frow row-start items-stretch" routerLink="/profile/{{ person?.address }}">
  <div class="avatar frow column-start justify-between">
    <app-avatar-image [user]="person" [size]="avatarSize"></app-avatar-image>
    <ng-template #loadingImg>
      <div class="loading-image gradient-loading-bg"></div>
    </ng-template>
  </div>
  <div class="ml-15 component-top-element-content frow column-start justify-evenly text-ellipsis">
    <a [class.gradient-loading-bg]="!person" class="person text-ellipsis" href="" routerLink="/profile/{{ person?.address }}">
      <span *ngIf="person">{{ person | username }}</span>
    </a>
    <span [class.gradient-loading-bg]="!person" class="value"
      >{{ value ? (value | tez | localisedNumber: '1.2-2') : '' }}
      <app-tez *ngIf="value"></app-tez>
    </span>
  </div>
</a>
