import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

export enum ViewMode {
  Default,
  Medium,
  Large,
  Masonry,
}

@Injectable({
  providedIn: 'root',
})
export class ViewModeService {
  private _viewMode: ViewMode = ViewMode.Default;
  private _detailMode: boolean = true;
  viewMode$ = new BehaviorSubject<ViewMode>(ViewMode.Default);
  detailMode$ = new BehaviorSubject<boolean>(true);

  constructor() {}

  get viewMode() {
    return this._viewMode;
  }

  get detailMode() {
    return this._detailMode;
  }

  updateViewMode(viewMode: ViewMode) {
    this._viewMode = viewMode;
    this.viewMode$.next(viewMode);
  }

  updateDetailMode(mode: boolean) {
    this._detailMode = mode;
    this.detailMode$.next(mode);
  }
}
