import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {NgxPaginationModule} from 'ngx-pagination';
import {BatchAskModalModule} from '../modal/batch-ask-modal/batch-ask-modal.module';
import {BatchTransferModalModule} from '../modal/batch-transfer-modal/batch-transfer-modal.module';
import {ButtonsModule} from '../buttons/buttons.module';
import {TokenListComponent} from '../token-list/token-list.component';
import {ObjktGalleryElementModule} from '../objkt-gallery-element/objkt-gallery-element.module';
import {ToolBarModule} from '../tool-bar/tool-bar.module';

@NgModule({
  declarations: [TokenListComponent],
  imports: [CommonModule, NgxPaginationModule, BatchAskModalModule, BatchTransferModalModule, ButtonsModule, ObjktGalleryElementModule],
  exports: [TokenListComponent],
})
export class TokenListModule {}
