import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CollectionImageComponent} from './collection-image.component';
import {PrerenderStopModule} from 'src/app/directives/prerender-stop/prerender-stop.module';

@NgModule({
  declarations: [CollectionImageComponent],
  imports: [CommonModule, PrerenderStopModule],
  exports: [CollectionImageComponent],
})
export class CollectionImageModule {}
