import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AvatarImageComponent} from './avatar-image.component';
import {UsernamePipeModule} from '../../pipes/username/username-pipe.module';
import {SrcFallbackModule} from '../../directives/src-fallback/src-fallback.module';

@NgModule({
  declarations: [AvatarImageComponent],
  exports: [AvatarImageComponent],
  imports: [CommonModule, SrcFallbackModule, UsernamePipeModule],
})
export class AvatarImageModule {}
