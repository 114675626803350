import {IUserNotification, IUserNotificationActivity} from './notifications.interface';

export default class UserNotification implements IUserNotification {
  private readonly _activity: IUserNotificationActivity;
  private readonly _actor: string;
  private readonly _seller: string;
  private readonly _buyer: string;
  private readonly _price: number;
  private readonly _prettyVerb: string;
  private readonly _description: string;
  private readonly _id: number;
  private readonly _unread: boolean;
  private readonly _userAddress: string;
  private readonly _actorAddress: string;
  private readonly _sellerAddress?: string;

  constructor(rawNotification: any, userAddress: string) {
    this._userAddress = userAddress;
    this._id = rawNotification.id;
    this._unread = rawNotification.unread;
    const actorUserData = this.getUserData(rawNotification.activity.actor);
    this._actorAddress = actorUserData.address;
    this._sellerAddress = rawNotification.activity.object.seller?.address;
    this._actor = this.createPrettyName(actorUserData);
    this._seller = this.createPrettyName(rawNotification.activity.object.seller);
    this._buyer = this.createPrettyName(rawNotification.activity.object.buyer);
    this._price = this.createXtzPrice(rawNotification.activity.object.price);
    this._prettyVerb = this.createPrettyVerb(rawNotification.activity.verb);
    this._description = this.createDescription(rawNotification);

    // workaround for broken notifications
    if (rawNotification?.activity?.object?.token?.token) {
      rawNotification.activity.object.token = rawNotification?.activity?.object?.token?.token;
    }

    this._activity = {
      ...rawNotification.activity,
      object: {
        ...rawNotification.activity.object,
        token: {
          ...rawNotification.activity.object.token,
          token_id: rawNotification.activity.object.token.id,
          fa_contract: rawNotification.activity.object.fa2.contract,
          fa: {
            contract: rawNotification.activity.object.fa2.contract,
          },
        },
      },
    };
  }

  get activity(): IUserNotificationActivity {
    return this._activity;
  }

  get actor(): string {
    return this._actor;
  }

  get seller(): string {
    return this._seller;
  }

  get buyer(): string {
    return this._buyer;
  }

  get price(): number {
    return this._price;
  }

  get prettyVerb(): string {
    return this._prettyVerb;
  }

  get description(): string {
    return this._description;
  }

  get id(): number {
    return this._id;
  }

  get unread(): boolean {
    return this._unread;
  }

  get user() {
    switch (this.activity.verb) {
      case 'accept_offer':
        return this.activity.object.seller;
      case 'conclude_auction':
        return this.activity.object.buyer;
      default:
        return this.getUserData(this.activity.actor);
    }
  }

  private createPrettyVerb(verb: string) {
    switch (verb) {
      case 'list':
      case 'open_edition_create':
      case 'list_create':
        return 'List';
      case 'purchase':
      case 'dutch_purchase':
      case 'ask_purchase':
      case 'open_edition_buy':
        if (this._actorAddress === this._userAddress) {
          return 'Purchase';
        } else {
          return 'Sale';
        }
      case 'accept_offer':
        return 'Accept Offer';
      case 'offer':
      case 'offer_create':
        return 'Offer';
      case 'conclude_auction':
        return 'Conclude';
      case 'english_bid':
        return 'Bid';
      case 'create_dutch_auction':
      case 'create_english_auction':
        return 'Created Auction';
      case 'followee_mint':
        return 'New NFT';
      default:
        return verb
          .split('_')
          .map(w => w[0].toUpperCase() + w.slice(1))
          .join(' ');
    }
  }

  private createDescription(rawNotification: any) {
    switch (rawNotification.activity.verb) {
      case 'list':
      case 'open_edition_create':
        return `Was listed by ${this._actor} for ${this._price}ꜩ`;
      case 'purchase':
      case 'dutch_purchase':
      case 'ask_purchase':
        return this.getPurchaseText();
      case 'accept_offer':
        return this.getAcceptOfferText();
      case 'offer':
        return `You received an offer of ${this._price}ꜩ from ${this._actor}`;
      case 'conclude_auction':
        return this.getConcludeAuctionText();
      case 'english_bid':
        return this.getBidText();
      case 'english_auction_outbid':
        return `You were outbid by ${this._actor} for ${this._price}ꜩ`;
      case 'create_dutch_auction':
      case 'create_english_auction':
        return this.getCreateAuctionText();
      case 'followee_mint':
        return `${this._actor} minted an NFT`;
      case 'bookmark_token_buyable':
        return `Now available for ${this._price}ꜩ`;
      default:
        return this.createPrettyVerb(rawNotification.activity.verb);
    }
  }

  private getUserData({name, address}: {name: string; address: string}) {
    return {alias: name, address};
  }

  private createXtzPrice(price: number) {
    return price / 1e6;
  }

  private createPrettyName(user?: {alias?: string; address: string}) {
    if (!user) {
      return null;
    }
    return user.alias || `${user.address.slice(0, 5)}...${user.address.slice(-5)}`;
  }

  private getAcceptOfferText() {
    if (this._actorAddress === this._userAddress) {
      return `Your offer of ${this._price}ꜩ was accepted by ${this._seller}`;
    }
    return `An offer of ${this._price}ꜩ was accepted by ${this._seller}`;
  }

  private getPurchaseText() {
    if (this._actorAddress === this._userAddress) {
      return `Bought for ${this._price}ꜩ`;
    }
    return `Sold to ${this._actor} for ${this._price}ꜩ`;
  }

  private getConcludeAuctionText() {
    if (this._sellerAddress === this._userAddress) {
      return `Your auction sold for ${this._price}ꜩ to ${this._buyer}`;
    }
    return `Sold at auction for ${this._price}ꜩ to ${this._buyer}`;
  }

  private getCreateAuctionText() {
    if (this._actor === this._userAddress) {
      return `Your auction has started at ${this._price}ꜩ`;
    }
    return `Is being auctioned by ${this._actor} for ${this._price}ꜩ`;
  }

  private getBidText() {
    if (this._sellerAddress === this._userAddress) {
      return `You received bid of ${this._price}ꜩ from ${this._actor}`;
    }
    return `An auction received a ${this._price}ꜩ bid from ${this._actor}`;
  }
}
