import {Component, ElementRef, EventEmitter, HostListener, Input, OnChanges, OnInit, Output, Renderer2} from '@angular/core';

@Component({
  selector: 'app-modal',
  templateUrl: './modal.component.html',
  styleUrls: ['./modal.component.scss'],
})
export class ModalComponent implements OnInit, OnChanges {
  @Input() preventClose = false;
  @Input() show = false;
  @Output() showChange = new EventEmitter<boolean>();
  @Input() title;

  constructor(private el: ElementRef, private renderer: Renderer2) {}

  ngOnInit(): void {}

  ngOnChanges(change) {
    if (change.show) {
      if (change.show.currentValue) {
        this.renderer.addClass(document.body, 'modal-open');
        this.showChange.emit(true);
      } else {
        this.renderer.removeClass(document.body, 'modal-open');
      }
    }
  }

  close() {
    this.show = false;
    this.renderer.removeClass(document.body, 'modal-open');
    this.showChange.emit(this.show);
  }

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape' && !this.preventClose) {
      this.close();
    }
  }
}
