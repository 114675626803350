import {Router} from '@angular/router';
import {FormControl} from '@angular/forms';
import {Component} from '@angular/core';
import {debounce, map, mergeMap, shareReplay, startWith} from 'rxjs/operators';
import {BehaviorSubject, combineLatest, EMPTY, merge, Observable, of, timer} from 'rxjs';

import {UserModel} from '../../types/types';
import {DarkModeService} from '../../services/dark-mode.service';
import {WalletService} from '../../services/tezos/wallet.service';
import {SigningService} from '../../services/tezos/signing.service';
import {NotificationService} from '../../services/notification.service';
import {TransactionsService} from '../../services/tezos/transactions.service';
import {NotificationApiService} from '../../services/api/notification-api.service';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss'],
})
export class HeaderComponent {
  model$: Observable<{
    isSynced?: boolean;
    isSyncing?: boolean;
    isDarkMode?: boolean;
    address?: string;
    user?: UserModel;
    unreadNotifications?: number | string;
    pendingTransactions?: number;
  }>;

  searchTerm = new FormControl();
  searchTerm$: Observable<string>;
  userChanged$ = new BehaviorSubject<boolean>(true);

  mobileMenuOpened = false;
  showWrapModal = false;

  constructor(
    private readonly router: Router,
    private readonly walletService: WalletService,
    private readonly signingService: SigningService,
    private readonly darkModeService: DarkModeService,
    private readonly transactionService: TransactionsService,
    private readonly notificationService: NotificationService,
    private readonly notificationApiService: NotificationApiService,
  ) {
    const signing$: Observable<[boolean, boolean]> = combineLatest([
      this.signingService.hasPermission().pipe(shareReplay(1)),
      this.signingService.synching$.pipe(shareReplay(1), startWith(false)),
    ]);

    const wallet$: Observable<[string, UserModel]> = combineLatest([
      merge(of(null), this.userChanged$.pipe(mergeMap(() => this.walletService.userAddress()))),
      merge(of(null), this.userChanged$.pipe(mergeMap(() => this.walletService.getUser()))),
    ]);

    const actions$: Observable<[number | string, number]> = combineLatest([
      this.notificationService.unreadNotifications$.pipe(map(ln => (ln.total >= 50 ? '50+' : ln.total))),
      this.transactionService.pendingTransactionsCount$,
    ]);

    this.model$ = combineLatest([signing$, wallet$, this.darkModeService.darkMode$, actions$]).pipe(
      map(([[isSynced, isSyncing], [address, user], isDarkMode, [unreadNotifications, pendingTransactions]]) => ({
        isSynced,
        isSyncing,
        isDarkMode,
        address,
        user,
        unreadNotifications,
        pendingTransactions,
      })),
    );

    this.searchTerm$ = this.searchTerm.valueChanges.pipe(debounce(term => (term ? timer(500) : EMPTY)));
  }

  syncWallet() {
    this.signingService.requestPermission().subscribe((wallet: any) => {
      if (wallet && wallet.address) this.userChanged$.next(true);
    });
  }

  logout() {
    this.signingService.desync().subscribe();
  }

  search(term: string) {
    this.searchTerm.setValue(null);
    this.router.navigate(['search'], {
      queryParams: {
        search: term,
      },
    });
  }

  switchMode(mode) {
    this.darkModeService.updatePreference(mode);
  }

  markAllAsRead() {
    this.signingService.isSigned$.subscribe(isSigned => {
      if (isSigned) {
        this.notificationApiService.markAllAsRead().subscribe(() => {
          this.notificationService.refresh();
        });
      }
    });
  }
}
