import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjktAuctionElementComponent} from './objkt-auction-element.component';
import {RouterModule} from '@angular/router';
import {ObjktPreviewModule} from '../objkt-preview/objkt-preview.module';
import {TezModule} from '../tez/tez.module';
import {AuctionIdHashPipeModule} from '../../pipes/auction-id-hash/auction-id-hash-pipe.module';
import {TezPipeModule} from '../../pipes/tez/tez-pipe.module';
import {LocalisedNumberModule} from '../../pipes/localised-number/localised-number.module';

@NgModule({
  declarations: [ObjktAuctionElementComponent],
  imports: [CommonModule, RouterModule, ObjktPreviewModule, TezModule, AuctionIdHashPipeModule, TezPipeModule, LocalisedNumberModule],
  exports: [ObjktAuctionElementComponent],
})
export class ObjktAuctionElementModule {}
