import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'localisedNumber',
})
export class LocalisedNumberPipe implements PipeTransform {
  transform(value: number, format: string): string {
    if (!/^\d+\.\d+-\d+$/.test(format)) {
      throw new Error('Invalid LocalisedNumberPipe format');
    }
    const [minimumIntegerDigits, fractionDigits] = format.split('.');
    const [minimumFractionDigits, maximumFractionDigits] = fractionDigits.split('-');
    return new Intl.NumberFormat(undefined, {
      minimumIntegerDigits: Number(minimumIntegerDigits),
      minimumFractionDigits: Number(minimumFractionDigits),
      maximumFractionDigits: Number(maximumFractionDigits),
    }).format(value);
  }
}
