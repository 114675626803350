import {Directive, ElementRef} from '@angular/core';

@Directive({
  selector: '[appPrerenderStop]',
})
export class PrerenderStopDirective {
  isPrerender = /HeadlessChrome/.test(window.navigator.userAgent);

  constructor(private el: ElementRef) {
    if (this.isPrerender) {
      this.el.nativeElement.remove();
    }
  }
}
