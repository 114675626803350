import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[accessibleLink]',
})
export class AccessibleLinkDirective {
  constructor(private readonly element: ElementRef, private readonly renderer: Renderer2) {}

  ngOnInit() {
    this.renderer.setAttribute(this.element.nativeElement, 'href', '#');
  }

  @HostListener('click', ['$event'])
  onClick(event) {
    event.preventDefault();
  }
}
