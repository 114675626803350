import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-status-banner',
  templateUrl: './status-banner.component.html',
  styleUrls: ['./status-banner.component.scss'],
})
export class StatusBannerComponent {
  @Input() status: string;
  @Input() type: string;
}
