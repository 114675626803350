import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {AuctionCarouselComponent} from './auction-carousel.component';
import {CarouselModule} from '../carousel/carousel.module';
import {ObjktAuctionElementModule} from '../objkt-auction-element/objkt-auction-element.module';

@NgModule({
  declarations: [AuctionCarouselComponent],
  imports: [CommonModule, CarouselModule, ObjktAuctionElementModule],
  exports: [AuctionCarouselComponent],
})
export class AuctionCarouselModule {}
