import {NgModule} from '@angular/core';
import {IpfsPipe} from './ipfs.pipe';
import {TokenService} from '../../services/token.service';

@NgModule({
  providers: [TokenService],
  declarations: [IpfsPipe],
  exports: [IpfsPipe],
})
export class IpfsPipeModule {}
