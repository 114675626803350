import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {merge, Observable, of} from 'rxjs';
import {ViewMode, ViewModeService} from 'src/app/services/view-mode.service';
import {ObjktPreviewSizes} from 'src/app/types/types';
import {IPaginatedToken} from '../../services/api/typesense-api.service';

@Component({
  selector: 'app-token-list',
  templateUrl: './token-list.component.html',
  styleUrls: ['./token-list.component.scss'],
})
export class TokenListComponent implements OnInit {
  @Input() tokenPage$: Observable<IPaginatedToken>;
  @Input() batchMode = false;
  @Input() viewMode?: ViewMode;

  @Output() pageChange = new EventEmitter<number>();

  tokenPageDefault$: Observable<IPaginatedToken>;

  private get currentViewMode() {
    return this.viewMode || this.viewModeService.viewMode;
  }

  get isLargeMode() {
    return this.currentViewMode === ViewMode.Large;
  }

  get isMasonryMode() {
    return this.currentViewMode === ViewMode.Masonry;
  }

  get isDetailMode() {
    return this.viewModeService.detailMode;
  }

  get isMediumMode() {
    return this.currentViewMode === ViewMode.Medium;
  }

  get imageSize() {
    return this.isMasonryMode || this.isLargeMode ? ObjktPreviewSizes.Thumb400 : ObjktPreviewSizes.Thumb288;
  }

  constructor(private readonly viewModeService: ViewModeService) {}

  ngOnInit(): void {
    this.tokenPageDefault$ = merge(
      of({
        total: 1,
        limit: 1,
        page: 1,
        tokens: Array(24),
      }),
      this.tokenPage$,
    );
  }
}
