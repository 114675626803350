import {Injectable} from '@angular/core';
import {Client} from 'typesense';
import {environment} from '../../../environments/environment';
import {
  SearchParams,
  SearchResponseFacetCountSchema,
} from 'typesense/lib/Typesense/Documents';
import {forkJoin, from, Observable, of} from 'rxjs';
import {catchError, defaultIfEmpty, map, switchMap} from 'rxjs/operators';
import {ObjktApiService} from './objkt-api.service';
import {UserApiService} from './user-api.service';
import {IndexerGetExploreFaTokensGQL} from 'src/graphql/gen/indexer';
import {FaModel, ObjktModel, UserModel} from '../../types/types';

export interface ITokenQuery {
  search?: string;
  priceRange?: string;
  editionsRange?: string;
  bidRange?: string;
  faContracts?: string[];
  hideFaContract?: string;
  mimetypes?: string[];
  tags?: string[];
  sort?: string;
  attr?: string[];
  auction_type?: string;
  auction_status?: string;
  holders_addresses?: string[];
  creators_addresses?: string[];
  name?: string[];
  flag?: string;
  fa_live?: boolean;
  gallery?: string;
  galleryPk?: number;
  auto_gallery_pk?: number;
  type?: string;
}

export interface ICollectionQuery {
  search?: string;
  sort?: string;
  collection_type?: string;
  live?: boolean;
  flag?: string;
}

export interface IUserQuery {
  search?: string;
  sort?: string;
  flag?: string;
}

export interface IPaginatedToken {
  page?: number;
  limit?: number;
  total?: number;
  query?: ITokenQuery;
  error?: boolean;
  loading?: boolean;
  tokens?: any[];
  facets?: {
    collections?: SearchResponseFacetCountSchema<any>;
    tags?: SearchResponseFacetCountSchema<any>;
    mime?: SearchResponseFacetCountSchema<any>;
    attributes?: SearchResponseFacetCountSchema<any>;
    attribute_names?: SearchResponseFacetCountSchema<any>;
  };
}

export interface IPaginatedCollection {
  page?: number;
  limit?: number;
  total: number;
  query?: string;
  error?: boolean;
  collections?: FaModel[] &
    {
      address?: string;
      creator?: UserModel;
      tokens?: ObjktModel[];
    }[];
}

export interface IPaginatedUser {
  page?: number;
  limit?: number;
  total?: number;
  query?: IUserQuery;
  error?: boolean;
  users?: UserModel[];
}

export interface IFacetCount {
  count: number;
  highlighted: string;
  value: string;
}

@Injectable({
  providedIn: 'root',
})
export class TypesenseApiService {
  private readonly _client: Client;

  private _mimeTypeOptions = [
    {value: '', label: 'All Types'},
    {value: 'image', label: 'Image'},
    {value: 'gif', label: 'GIF'},
    {value: 'svg', label: 'SVG'},
    {value: 'video', label: 'Video'},
    {value: 'audio', label: 'Audio'},
    {value: 'model', label: 'Model'},
    {value: 'interactive', label: 'Interactive'},
    {value: 'pdf', label: 'PDF'},
    {value: 'text', label: 'Text'},
  ];

  private _mimetypeGroups = {
    all: [],
    audio: [
      'audio/wav',
      'audio/ogg',
      'audio/mpeg',
      'audio/flac',
      'audio/x-wav',
    ],
    image: ['image/png', 'image/jpeg', 'image/bmp', 'image/tiff', 'image/webp'],
    gif: ['image/gif'],
    svg: ['image/svg+xml'],
    model: ['model/gltf-binary', 'model/gltf+json'],
    video: [
      'video/mp4',
      'video/webm',
      'video/quicktime',
      'video/ogg',
      'video/webm',
      'video/3gpp',
      'video/x-ms-wmv',
    ],
    interactive: ['application/x-directory'],
    pdf: ['application/pdf'],
    text: ['text/markdown'],
  };

  private _cardFields =
    'artifact_uri,flag,collection_name,creators_addresses,creators_aliases,creators,display_uri,fa,fa_collection_type,fa_contract,id,lowest_ask,highest_offer,name,supply,thumbnail_uri,token_id,mime,galleries_pk,attributes';
  private _auctionCardFields =
    'artifact_uri,collection_name,creators_addresses,creators_aliases,creators,display_uri,fa,fa_collection_type,fa_contract,id,auction_current_price,auction_highest_bid,auction_bids,auction_price_increment,auction_reserve,auction_start_price,auction_end_price,auction_expiry,auction_amount,auction_amount_left,auction_status,auction_start_time,auction_end_time,auction_type,name,supply,thumbnail_uri,token_id,mime,attributes';

  constructor(
    private objktApiService: ObjktApiService,
    private readonly userApiService: UserApiService,
    private readonly getExploreTokens: IndexerGetExploreFaTokensGQL,
  ) {
    this._client = new Client({
      nodes: [environment.searchConfig],
      apiKey: environment.typesenseApiKey,
      connectionTimeoutSeconds: 15,
    });
  }

  get mimeTypeGroups() {
    return this._mimetypeGroups;
  }

  get mimeTypeOptions() {
    return this._mimeTypeOptions;
  }

  queryAll(search: string): Observable<any> {
    return from(
      this._client.multiSearch.perform({
        searches: [
          {
            collection: 'tokens',
            q: decodeURI(search) || '',
            query_by:
              'name,creators_aliases,creators_tzdomains,mime,tags,attribute_names,creators_addresses,fa_contract,description',
            query_by_weights: '10,8,8,6,4,4,3,2,1',
            include_fields: this._cardFields,
            num_typos: 2,
            per_page: 3,
            filter_by: 'flag:=none&&fa_live:=true&&type:=token',
          },
          {
            collection: 'fas',
            q: decodeURI(search) || '',
            query_by: 'name,creator_alias,description,address,creator_address',
            query_by_weights: '10,8,3,3,1',
            num_typos: 2,
            per_page: 3,
            sort_by: 'volume_total:desc',
            filter_by: 'live:=true&&flag:=none',
          },
          {
            collection: 'holders',
            q: decodeURI(search) || '',
            query_by: 'alias,twitter,tzdomain,address,description',
            query_by_weights: '10,10,8,3,1',
            num_typos: 2,
            per_page: 3,
            filter_by: 'flag:=none',
          },
        ],
      }),
    );
  }

  queryTokens(
    query: ITokenQuery,
    page = 1,
    limit = 50,
  ): Observable<IPaginatedToken> {
    const searchParams: SearchParams = {
      q: decodeURI(query.search) || '',
      query_by:
        'name,creators_aliases,creators_tzdomains,mime,tags,attribute_names,creators_addresses,fa_contract,description',
      query_by_weights: '10,8,8,6,4,4,3,2,1',
      facet_by: 'mime,fa_contract',
      max_facet_values: 1000,
      num_typos: 2,
      per_page: limit,
      page,
      include_fields: this._cardFields,
    };
    searchParams.filter_by = this.insertFilters(query);

    // only facet by attributes if a collection is selected
    if ((query.faContracts && query.faContracts.length) || query.galleryPk) {
      searchParams.facet_by += ',attributes';
    }

    if (query.sort && !query.sort.includes('received')) {
      searchParams.sort_by = query.sort;

      if (searchParams.sort_by === 'timestamp:desc') {
        searchParams.sort_by += ',token_id:desc';
      } else if (searchParams.sort_by === 'timestamp:asc') {
        searchParams.sort_by += ',token_id:asc';
      }
    }

    // sort by relevance if user only applies search string
    if (
      !searchParams.filter_by &&
      query.search &&
      query.sort === 'timestamp:desc'
    ) {
      searchParams.sort_by = undefined;
    }

    // TODO: this._client.collections<any> shouldn't be 'any'.
    return from(
      this._client.collections<any>('tokens').documents().search(searchParams),
    ).pipe(
      map(data => ({
        page,
        query,
        total: data.found || 0,
        limit,
        loading: false,
        facets: {
          collections: data.facet_counts.find(
            count => count.field_name === 'fa_contract',
          ),
          attributes: data.facet_counts.find(
            count => count.field_name === 'attributes',
          ),
          attribute_names: data.facet_counts.find(
            count => count.field_name === 'attribute_names',
          ),
          mime: data.facet_counts.find(count => count.field_name === 'mime'),
        },
        tokens:
          data.hits.map(h => ({
            ...h.document,
            creators: this.makeCreators(h.document),
            preview: this.objktApiService.getObjktPreview(h.document),
            lowest_ask:
              h.document.lowest_ask >= 0 ? h.document.lowest_ask : null,
            pk: h.document.id,
            attributes: this.parseAttributes(h.document.attributes),
          })) || [],
      })),
      // add digest hash for tokens that have https:// in artifact_uri
      switchMap(paginatedToken => {
        return this.objktApiService
          .addDigestHashToList(paginatedToken.tokens)
          .pipe(
            map(tokens => ({
              ...paginatedToken,
              tokens,
            })),
          );
      }),
      // Reproducible with timeout errors, e.g. overflow max priceRange value (e.g. max=9223372036855)
      catchError(() => of({error: true})),
    );
  }

  queryAuctions(
    query: ITokenQuery,
    page = 1,
    limit = 50,
  ): Observable<IPaginatedToken> {
    const searchParams: SearchParams = {
      q: decodeURI(query.search) || '',
      query_by:
        'name,creators_aliases,creators_tzdomains,mime,creators_addresses,fa_contract,description',
      query_by_weights: '10,8,8,4,3,2,1',
      facet_by: 'mime,fa_contract',
      max_facet_values: 1000,
      num_typos: 2,
      per_page: limit,
      page,
      include_fields: this._auctionCardFields,
    };
    searchParams.filter_by = this.insertFilters(query);

    // only facet by attributes if a collection is selected
    if (query.faContracts && query.faContracts.length) {
      searchParams.facet_by += ',attributes';
    }

    if (query.sort && !query.sort.includes('received')) {
      searchParams.sort_by = query.sort;
    }

    // sort by relevance if user only applies search string
    if (
      !searchParams.filter_by &&
      query.search &&
      query.sort === 'auction_start_time:desc'
    ) {
      searchParams.sort_by = undefined;
    }

    // TODO: this._client.collections<any> shouldn't be 'any'.
    return from(
      this._client
        .collections<any>('auctions')
        .documents()
        .search(searchParams),
    ).pipe(
      map(data => ({
        page,
        query,
        total: data.found || 0,
        limit,
        loading: false,
        facets: {
          collections: data.facet_counts.find(
            count => count.field_name === 'fa_contract',
          ),
          attributes: data.facet_counts.find(
            count => count.field_name === 'attributes',
          ),
          attribute_names: data.facet_counts.find(
            count => count.field_name === 'attribute_names',
          ),
          mime: data.facet_counts.find(count => count.field_name === 'mime'),
        },
        tokens:
          data.hits.map(h => ({
            ...h.document,
            creators: this.makeCreators(h.document),
            preview: this.objktApiService.getObjktPreview(h.document),
            pk: h.document.id,
            attributes: this.parseAttributes(h.document.attributes),
            hash: h.document.id.split('-')[2],
          })) || [],
      })),
      // Reproducible with timeout errors, e.g. overflow max priceRange value (e.g. max=9223372036855)
      catchError(() => of({error: true})),
    );
  }

  queryCollections(
    query: ICollectionQuery,
    page = 1,
    limit: 18,
  ): Observable<IPaginatedCollection> {
    const searchParams: SearchParams = {
      q: decodeURI(query.search) || '',
      query_by: 'name,creator_alias,description,address,creator_address',
      query_by_weights: '10,8,3,3,1',
      num_typos: 2,
      per_page: limit,
      page,
      include_fields:
        'name,collection_type,address,creator,logo,path,floor_price,volume_24h,volume_total',
    };

    searchParams.filter_by = this.insertCollectionFilters(query);

    if (query.sort) {
      searchParams.sort_by = query.sort;
    }

    return from(
      this._client.collections<any>('fas').documents().search(searchParams),
    ).pipe(
      map(data => ({
        page,
        query,
        total: data.found || 0,
        limit,
        collections: data.hits.map(c => ({
          ...c.document,
        })),
      })),
      switchMap(data => this.addCreatorToCollections(data)),
      switchMap(data => this.addTokensToCollection(data)),
      catchError(() => of({error: true})),
    );
  }

  queryCollectionsDropdown(search: string) {
    const searchParams = {
      collection: 'fas',
      q: decodeURI(search) || '',
      query_by: 'name,creator_alias,description',
      query_by_weights: '10,8,3',
      num_typos: 2,
      per_page: 20,
      sort_by: 'volume_total:desc',
    };

    return from(
      this._client.collections<any>('fas').documents().search(searchParams),
    ).pipe(map(data => data.hits.map(c => c.document)));
  }

  queryGalleriesDropdown(search: string) {
    const searchParams = {
      collection: 'galleries',
      q: decodeURI(search) || '',
      query_by: 'name,curators_aliases,description',
      query_by_weights: '10,8,3',
      num_typos: 2,
      per_page: 20,
      sort_by: 'volume_total:desc',
    };

    return from(
      this._client
        .collections<any>('galleries')
        .documents()
        .search(searchParams),
    ).pipe(map(data => data.hits.map(c => c.document)));
  }

  queryUsers(
    query: IUserQuery,
    page = 1,
    limit = 18,
  ): Observable<IPaginatedUser> {
    const searchParams: SearchParams = {
      q: decodeURI(query.search) || '',
      query_by: 'alias,twitter,tzdomain,address,description',
      query_by_weights: '10,10,8,3,1',
      num_typos: 2,
      per_page: limit,
      page,
      include_fields: 'address,alias,description,logo,tzdomain',
    };

    searchParams.filter_by = this.insertUserFilters(query);

    if (query.sort) {
      searchParams.sort_by = query.sort;
    }

    return from(
      this._client.collections<any>('holders').documents().search(searchParams),
    ).pipe(
      map(data => ({
        page,
        query,
        total: data.found || 0,
        limit,
        users: data.hits.map(u => ({
          ...u.document,
        })),
      })),
      switchMap(data =>
        forkJoin(
          data.users.map(user =>
            user.logo ? this.userApiService.addAvatarToUser(user) : of(user),
          ),
        ).pipe(
          defaultIfEmpty([]),
          map(users => ({
            ...data,
            users,
          })),
        ),
      ),
      catchError(() => of({error: true})),
    );
  }

  private insertFilters(query: ITokenQuery) {
    const filterBy = [];
    if (query.tags?.length) {
      filterBy.push(...query.tags.map(tag => `tags:= ${tag}`));
    }
    if (query.mimetypes?.length) {
      filterBy.push(this._getMimetypeFilters(query.mimetypes));
    }
    if (query.faContracts?.length) {
      filterBy.push(
        ...query.faContracts.reduce(
          (arr, faContract) =>
            faContract ? [...arr, `fa_contract:= ${faContract}`] : arr,
          [],
        ),
      );
    }
    if (query.hideFaContract) {
      filterBy.push(`fa_contract:!=${query.hideFaContract}`);
    }
    if (query.priceRange) {
      filterBy.push(this._getPriceRange(query.priceRange));
    }
    if (query.bidRange) {
      filterBy.push(this._getBidRange(query.bidRange));
    }
    if (query.editionsRange) {
      filterBy.push(this._getEditionsRange(query.editionsRange));
    }
    if (query.auction_type) {
      filterBy.push(`auction_type:=${query.auction_type}`);
    }
    if (query.auction_status) {
      if (query.auction_status === 'scheduled') {
        filterBy.push(`auction_start_time:>${Date.now()}`);
      } else {
        filterBy.push(`auction_status:=${query.auction_status}`);
      }
    }
    if (query.holders_addresses) {
      filterBy.push(
        ...query.holders_addresses.map(
          address => `holders_addresses:= ${address}`,
        ),
      );
    }
    if (query.creators_addresses) {
      filterBy.push(
        ...query.creators_addresses.map(
          address => `creators_addresses:= ${address}`,
        ),
      );
    }
    if (query.attr) {
      filterBy.push(...query.attr.map(tag => `attributes:= ${tag}`));
    }
    if (query.name) {
      filterBy.push(`name:${query.name}`);
    }
    if (query.fa_live) {
      filterBy.push('fa_live:=true');
    }
    if (query.flag) {
      filterBy.push(`flag:=${query.flag}`);
    }
    if (query.galleryPk) {
      filterBy.push(`galleries_pk:=${query.galleryPk}`);
    }
    if (query.auto_gallery_pk) {
      filterBy.push(`auto_gallery_pk:=${query.auto_gallery_pk}`);
    }
    if (query.type) {
      filterBy.push(`type:=${query.type}`);
    }

    return filterBy.join(' && ');
  }

  private insertCollectionFilters(query: ICollectionQuery) {
    const filterBy = [];
    if (query.collection_type) {
      filterBy.push(`collection_type:=${query.collection_type}`);
    }
    if (query.live) {
      filterBy.push('live:=true');
    }
    if (query.flag) {
      filterBy.push(`flag:=${query.flag}`);
    }

    return filterBy.join(' && ');
  }

  private insertUserFilters(query: IUserQuery) {
    const filterBy = [];

    if (query.flag) {
      filterBy.push(`flag:=${query.flag}`);
    }

    return filterBy.join(' && ');
  }

  private _getMimetypeFilters(mimetypes: string[]) {
    return `mime:= [${mimetypes
      .reduce(
        (arr, mimetype) => [
          ...arr,
          ...(this._mimetypeGroups?.[mimetype] || []),
        ],
        [],
      )
      .join(',')}]`;
  }

  private _getPriceRange(range: string) {
    const [from, to] = range
      ?.split(',')
      .map(v => (v ? parseFloat(v) : undefined));
    if (from && to) {
      return `lowest_ask:${from * 1e6}..${to * 1e6}`;
    } else if (from) {
      return `lowest_ask:>=${from * 1e6}`;
    } else if (to) {
      return `lowest_ask:<=${to * 1e6}`;
    }
  }

  private _getBidRange(range: string) {
    const [from, to] = range
      ?.split(',')
      .map(v => (v ? parseFloat(v) : undefined));
    if (from && to) {
      return `auction_highest_bid:${from * 1e6}..${to * 1e6}`;
    } else if (from) {
      return `auction_highest_bid:>=${from * 1e6}`;
    } else if (to) {
      return `auction_highest_bid:<=${to * 1e6}`;
    }
  }

  private _getEditionsRange(range: string) {
    const [from, to] = range
      ?.split(',')
      .map(v => (v ? parseFloat(v) : undefined));
    if (from && to) {
      return `supply:${from}..${to}`;
    } else if (from) {
      return `supply:>=${from}`;
    } else if (to) {
      return `supply:<=${to}`;
    }
  }

  private makeCreators(document) {
    const creators = [];
    for (
      let i = 0;
      i <
      Math.max(
        document.creators_addresses.length,
        document.creators_aliases.length,
      );
      i++
    ) {
      creators.push({
        creator_address: document.creators_addresses?.[i] || '',
        holder: {
          address: document.creators_addresses?.[i] || '',
          alias: document.creators_aliases?.[i] || '',
        },
      });
    }

    return (
      document.creators?.map(c => ({
        creator_address: c.address || '',
        holder: this.userApiService.extendUser({
          address: c.address || '',
          alias: c.alias || '',
          tzdomain: c.tzdomain,
        }),
      })) || []
    );
  }

  private addCreatorToCollections(data) {
    return forkJoin(
      data.collections.map(collection =>
        collection.creator
          ? this.userApiService.addAvatarToUser(collection.creator).pipe(
              map(creator => ({
                ...collection,
                creator,
              })),
            )
          : of(collection),
      ),
    ).pipe(
      defaultIfEmpty([]),
      map(collections => ({
        ...data,
        collections,
      })),
    );
  }

  private addTokensToCollection(data) {
    return this.getExploreTokens
      .fetch({
        where: {
          contract: {
            _in: data.collections?.map(collection => collection.address),
          },
        },
      })
      .pipe(
        map(res => res.data.fa),
        map(collections => ({
          tokens: collections
            .map(collection =>
              collection.tokens.map(token => ({
                ...token,
                fa_contract: collection.contract,
              })),
            )
            .flat(),
          collections,
        })),
        switchMap(({collections, tokens}) =>
          this.objktApiService
            .addDigestHashToList(tokens)
            .pipe(map(tokens => ({collections, tokens}))),
        ),
        map(({collections, tokens}) => ({
          ...data,
          collections: data.collections.map(collection => ({
            ...collection,
            tokens: tokens.filter(
              token => token.fa_contract === collection.address,
            ),
            items: collections.find(c => c.contract === collection.address)
              .items,
            owners: collections.find(c => c.contract === collection.address)
              .owners,
          })),
        })),
      );
  }

  private parseAttributes(attributes) {
    if (!attributes) return;
    return attributes.map(a => {
      const splitted = a.split(': ');
      return {attribute: {name: splitted[0], value: splitted[1]}};
    });
  }
}
