import {Injectable} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {switchMap} from 'rxjs/operators';
import {SettingsApiService} from './api/settings-api.service';
import {LocalStorageService} from './local-storage.service';
import {SigningService} from './tezos/signing.service';

@Injectable({
  providedIn: 'root',
})
export class ContentFilteringService {
  hideMature$: Observable<boolean>;
  hideFlashing$: Observable<boolean>;

  constructor(
    private readonly settingsApi: SettingsApiService,
    private readonly localStorage: LocalStorageService,
    private readonly signingService: SigningService,
  ) {
    this.hideMature$ = combineLatest([this.signingService.isSigned$, this.settingsApi.getHideMature()]).pipe(
      switchMap(([signed, hideMature]) => {
        if (signed) {
          return of(hideMature);
        } else {
          return of(this.localStorage.getHideMature());
        }
      }),
    );

    this.hideFlashing$ = combineLatest([this.signingService.isSigned$, this.settingsApi.getHideFlashing()]).pipe(
      switchMap(([signed, hideFlashing]) => {
        if (signed) {
          return of(hideFlashing);
        } else {
          return of(this.localStorage.getHideFlashing());
        }
      }),
    );
  }

  isMature(attributes) {
    if (attributes === undefined) return false;

    return attributes
      .filter(attribute => !!attribute.attribute)
      .some(attribute => {
        return attribute.attribute.name === '__nsfw_' && attribute.attribute.value === 'true';
      });
  }

  isFlashing(attributes) {
    if (attributes === undefined) return false;

    return attributes
      .filter(attribute => !!attribute.attribute)
      .some(attribute => {
        return attribute.attribute.name === '__hazards_' && attribute.attribute.value === 'flashing';
      });
  }
}
