import {Component, Input, OnInit} from '@angular/core';
import {FaModel, GalleryModel} from 'src/app/types/types';

@Component({
  selector: 'app-top-collection',
  templateUrl: './top-collection.component.html',
  styleUrls: ['./top-collection.component.scss'],
})
export class TopCollectionComponent implements OnInit {
  @Input() fa2: (FaModel | GalleryModel) & (FaModel & GalleryModel);
  @Input() gallery: GalleryModel;
  @Input() value: number;

  routerLink: string;
  queryParams: any;

  constructor() {}

  ngOnInit(): void {
    if (this.fa2?.path || this.fa2?.contract) {
      this.routerLink = `/collection/${this.fa2?.path || this.fa2?.contract}`;
    } else if (this.fa2?.slug && this.fa2?.registry?.slug) {
      this.routerLink = '/explore/tokens';
      this.queryParams = {gallery: `${this.fa2?.registry?.slug}:${this.fa2?.slug}`};
    }
  }
}
