import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';
import {SettingsApiService} from '../../../services/api/settings-api.service';

@Injectable({
  providedIn: 'root',
})
export class WarningModalService {
  modals$ = new BehaviorSubject<any[]>([]);
  private _modals = [];

  constructor(readonly settingsApiService: SettingsApiService) {}

  remove(modalId) {
    this._modals = this._modals.filter(modal => modal.id !== modalId);
    this.update();
  }

  addFirstTimeBuyingWarning(fn: () => any) {
    this.settingsApiService.getHideDisclaimer().subscribe(hideDisclaimer => {
      if (hideDisclaimer === true) {
        fn();
        return;
      }
      this.add(
        `<p>objkt.com is an open, decentralised marketplace, allowing anybody to mint their art freely. With this openess come certain risks, such as copy-minting.</p>
          <p>We recommend you do your own due diligence before buying any token to make sure that the creator is authentic and the art is original.</p>
          <p>By clicking Confirm, you acknowledge having read and understood this message and that you are aware of the risks involved.</p>
          <p>This message will only appear once.</p>`,
        'objkt.com Marketplace Disclaimer',
        confirmed => {
          if (confirmed) this.settingsApiService.hideDisclaimer().subscribe(_ => fn());
        },
      );
    });
  }

  private add(message, title, onClose): any {
    const modal = {
      id: Date.now(),
      message,
      onClose,
      title,
    };
    this._modals.push(modal);
    this.update();
    return modal;
  }

  private update() {
    this.modals$.next(this._modals);
  }
}
