import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';

import {InfoPopoverComponent} from './info-popover.component';
import {AvatarImageModule} from '../avatar-image/avatar-image.module';
import {UsernamePipeModule} from 'src/app/pipes/username/username-pipe.module';
import {CollectionImageModule} from '../collection-image/collection-image.module';
import {ShortAddressPipeModule} from 'src/app/pipes/short-address/short-address-pipe.module';
import {CollectionLinkPipeModule} from 'src/app/pipes/collection-link/collection-link-pipe.module';
import {ShortNumberPipeModule} from 'src/app/pipes/short-number/short-number-pipe.module';
import {TezPipeModule} from 'src/app/pipes/tez/tez-pipe.module';
import {GalleryLinkModule} from 'src/app/pipes/gallery-link/gallery-link.module';
import {ObjktLinkPipeModule} from 'src/app/pipes/objkt-links/objkt-link-pipe.module';

@NgModule({
  declarations: [InfoPopoverComponent],
  exports: [InfoPopoverComponent],
  imports: [
    CommonModule,
    UsernamePipeModule,
    AvatarImageModule,
    TezPipeModule,
    CollectionImageModule,
    ShortAddressPipeModule,
    ShortNumberPipeModule,
    CollectionLinkPipeModule,
    GalleryLinkModule,
    ObjktLinkPipeModule,
  ],
})
export class InfoPopoverModule {}
