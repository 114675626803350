import {Component, Input, OnInit} from '@angular/core';
import {FeaturedObjktModel, ObjktDetailsModel, ObjktModel, ObjktPreviewSizes} from 'src/app/types/types';
import {environment} from 'src/environments/environment';
import {ObjktLinkPipe} from '../../pipes/objkt-links/objkt-link.pipe';

@Component({
  selector: 'app-featured-objkt',
  templateUrl: './featured-objkt.component.html',
  styleUrls: ['./featured-objkt.component.scss'],
  providers: [ObjktLinkPipe],
})
export class FeaturedObjktComponent implements OnInit {
  @Input() objkt: ObjktModel & {link: string};

  imgUrl = '';
  previewSize = ObjktPreviewSizes.Thumb400;
  imgPreloadUrl: string;
  isExternal: boolean;

  constructor() {}

  ngOnInit(): void {
    if (this.objkt?.preview) {
      this.imgPreloadUrl = environment.ipfsLink + this.objkt.artifact_uri.replace('ipfs://', '');
    } else {
      this.imgPreloadUrl = 'assets/blank.jpeg';
    }

    this.isExternal = this.objkt?.link?.startsWith('https://') || false;
  }
}
