import {Injectable} from '@angular/core';
import {Subject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class LoadingService {
  private _loading$ = new Subject<boolean>();
  private _loadingText$ = new Subject<string>();
  private _defaultLoadingText = 'Loading...';

  constructor() {}

  get loading$(): Subject<boolean> {
    return this._loading$;
  }

  get loadingText$(): Subject<string> {
    return this._loadingText$;
  }

  get defaultLoadingText(): string {
    return this._defaultLoadingText;
  }

  isLoading(text) {
    this._loadingText$.next(text || this._defaultLoadingText);
    this._loading$.next(true);
  }

  stopLoading() {
    this._loading$.next(false);
    this._loadingText$.next(this._defaultLoadingText);
  }
}
