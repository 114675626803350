import {Component, Input, OnInit} from '@angular/core';
import {Currency} from 'src/app/types/types';

@Component({
  selector: 'app-tez',
  templateUrl: './tez.component.html',
  styleUrls: ['./tez.component.scss'],
})
export class TezComponent implements OnInit {
  @Input() currency?: Currency;

  constructor() {}

  get tezosString() {
    return navigator.userAgent.match(/ipad|iphone/i) ? 'tez' : 'ꜩ';
  }

  ngOnInit(): void {}
}
