import {Injectable} from '@angular/core';
import {BeaconWallet} from '@taquito/beacon-wallet';
import {MichelCodecPacker, TezosToolkit} from '@taquito/taquito';
import {BehaviorSubject, Observable, of, from} from 'rxjs';
import {map, mergeMap, shareReplay, tap} from 'rxjs/operators';
import {RpcClientCache} from '@taquito/rpc';
import {UserApiService} from '../api/user-api.service';
import {RpcClientRetry} from './rpc-client-retry';
import {UserModel} from 'src/app/types/types';

@Injectable({
  providedIn: 'root',
})
export class WalletService {
  private _wallet: BeaconWallet;
  private _tezos$: Observable<TezosToolkit>;
  private _address$ = new BehaviorSubject<string | null>(null);

  constructor(private readonly userApiService: UserApiService) {
    this.newWallet();
    this._tezos$ = of(this.setupTezosToolkit()).pipe(shareReplay());
  }

  get address$(): Observable<string | null> {
    return this._address$.pipe(shareReplay());
  }

  get wallet(): BeaconWallet {
    return this._wallet;
  }

  get tezos$(): Observable<TezosToolkit> {
    return this._tezos$;
  }

  getHead() {
    return this.tezos$.pipe(mergeMap(tezos => from(tezos.rpc.getBlock()).pipe(map(block => block.header))));
  }

  getUser(): Observable<UserModel> {
    return this.userAddress().pipe(mergeMap(address => (address ? this.userApiService.getUser(address) : of(null))));
  }

  userAddress(): Observable<string> {
    return from(this._wallet.getPKH().catch(_ => null)).pipe(tap(address => this._address$.next(address)));

    // TEST DIFFERENT USERS HERE
    // .pipe(map(_ => 'tz1QGCWjNpYmcS6T9qFGYSam25e36WeFUCK4'))
  }

  clearAddress() {
    return this._address$.next(null);
  }

  userAddressCropped(): Observable<string> {
    return this.userAddress().pipe(
      map(address => {
        return `${address.substring(0, 5)}...${address.slice(-5)}`;
      }),
    );
  }

  getContractAt(contract: string) {
    return this.tezos$.pipe(mergeMap(tezos => from(tezos.wallet.at(contract))));
  }

  newWallet() {
    if (!this._wallet) {
      this._wallet = new BeaconWallet({
        name: 'objkt.com',
        iconUrl: 'https://assets.objkt.media/file/assets-002/objkt/objkt-logo.png',
        appUrl: 'https://objkt.com',
      });
    }
  }

  disconnect() {
    return from(this.wallet.disconnect()).pipe(map(_ => (this._wallet = null)));
  }

  private setupTezosToolkit() {
    const rpcClient = new RpcClientRetry('main');
    const tezos = new TezosToolkit(new RpcClientCache(rpcClient));
    tezos.setPackerProvider(new MichelCodecPacker());
    tezos.setWalletProvider(this._wallet);
    return tezos;
  }

  // contract cache TODO: possibly add new contracts
  // this.contractsLibrary.addContract({
  //   KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq: {
  //     script: marketplaceCache.script,
  //     entrypoints: marketplaceCache.entrypoints,
  //   },
  //   KT1HbQepzV1nVGg8QVznG7z4RcHseD5kwqBn: {
  //     script: henMarketplaceCache.script,
  //     entrypoints: henMarketplaceCache.entrypoints,
  //   },
  //   KT1XjcRq5MLAzMKQ3UHsrue2SeU2NbxUrzmU: {
  //     script: englishAuctionCache.script,
  //     entrypoints: englishAuctionCache.entrypoints,
  //   },
  //   KT1QJ71jypKGgyTNtXjkCAYJZNhCKWiHuT2r: {
  //     script: dutchAuctionCache.script,
  //     entrypoints: dutchAuctionCache.entrypoints,
  //   },
  //   KT1Aq4wWmVanpQhq4TTfjZXB5AjFpx15iQMM: {
  //     script: mintingFactoryCache.script,
  //     entrypoints: mintingFactoryCache.entrypoints,
  //   },
  // });
}
