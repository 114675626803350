import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TokenCarouselComponent} from './token-carousel.component';
import {CarouselModule} from '../carousel/carousel.module';
import {ObjktGalleryElementModule} from '../objkt-gallery-element/objkt-gallery-element.module';

@NgModule({
  declarations: [TokenCarouselComponent],
  imports: [CommonModule, CarouselModule, ObjktGalleryElementModule],
  exports: [TokenCarouselComponent],
})
export class TokenCarouselModule {}
