import {Directive, ElementRef, HostListener, Renderer2} from '@angular/core';

@Directive({
  selector: '[appImageLoading]',
})
export class ImageLoadingDirective {
  constructor(private renderer: Renderer2, private hostElement: ElementRef) {
    this.renderer.addClass(this.hostElement.nativeElement, 'gradient-loading-bg');
  }

  @HostListener('load')
  onLoad() {
    this.renderer.removeClass(this.hostElement.nativeElement, 'gradient-loading-bg');
  }
}
