import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'shuffle',
})
export class ShufflePipe implements PipeTransform {
  transform(value: any[]): unknown {
    return this.shuffle(value);
  }

  shuffle(arr: any[]) {
    const randomizedArray = [];
    const array = [...arr];
    while (array.length !== 0) {
      const i = ~~(array.length * Math.random());
      randomizedArray.push(array[i]);
      array.splice(i, 1);
    }

    return randomizedArray;
  }
}
