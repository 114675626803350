import {Component} from '@angular/core';

import {TransactionsService} from '../../services/tezos/transactions.service';

@Component({
  selector: 'app-transactions-panel',
  templateUrl: './transactions-panel.component.html',
  styleUrls: ['./transactions-panel.component.scss'],
})
export class TransactionsPanelComponent {
  constructor(public readonly transactionService: TransactionsService) {}
}
