import {Pipe, PipeTransform} from '@angular/core';
import {ShortAddressPipe} from '../short-address/short-address.pipe';

@Pipe({name: 'username'})
export class UsernamePipe implements PipeTransform {
  transform(user: {address?: string; alias?: string; tzdomain?: string}): string {
    const addressPipe = new ShortAddressPipe();
    if (user) {
      return user.alias || user.tzdomain || addressPipe.transform(user.address);
    } else {
      return '-';
    }
  }
}
