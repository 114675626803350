<app-modal [(show)]="show" (showChange)="showChange.next($event)" [title]="title">
  <ng-container body>
    <p *ngIf="tooManyObjktsError">{{ tooManyObjktsError }}</p>
    <form id="objktForm" [formGroup]="objktsForm" (ngSubmit)="handleBatchAsk()">
      <table class="batch-table">
        <thead>
          <tr>
            <th>ID</th>
            <th>Title</th>
            <th>Amount</th>
            <th>Price</th>
            <th>&nbsp;</th>
          </tr>
        </thead>
        <tbody>
          <ng-container *ngFor="let objktFormGroup of objktsFormArray.controls; let i = index">
            <tr [formGroup]="getObjktFormGroupByIndex(i)">
              <td>{{ this.objkts[i].token_id }}</td>
              <td>{{ this.objkts[i].name }}</td>
              <td>
                <input type="number" formControlName="amount" placeholder="1" />
                <div class="error-text-container">
                  <ng-container *ngIf="getFormControl(i, 'amount').invalid && (!getFormControl(i, 'amount').pristine || getFormControl(i, 'amount').touched)">
                    <div *ngIf="getFormControl(i, 'amount').errors.pattern" class="error-text">Must be an integer</div>
                    <div *ngIf="getFormControl(i, 'amount').errors.number" class="error-text">
                      {{ showNumberError(i, 'amount') }}
                    </div>
                  </ng-container>
                </div>
              </td>
              <td>
                <input type="number" formControlName="price" placeholder="20xtz" />
                <div class="error-text-container">
                  <ng-container *ngIf="getFormControl(i, 'price').invalid && (!getFormControl(i, 'price').pristine || getFormControl(i, 'price').touched)">
                    <div *ngIf="getFormControl(i, 'price').errors.number" class="error-text">{{ showNumberError(i, 'price') }}</div>
                  </ng-container>
                </div>
              </td>
              <td>
                <app-icon-button (click)="handleRemoveObjkt(i)">
                  <span class="material-icons-sharp remove"> remove_circle </span>
                </app-icon-button>
              </td>
            </tr>
          </ng-container>
        </tbody>
      </table>
    </form>
  </ng-container>
  <ng-container footer>
    <div class="frow row-end">
      <button [attr.aria-busy]="loading" form="objktForm" type="submit" [disabled]="!!tooManyObjktsError">List tokens</button>
    </div>
  </ng-container>
</app-modal>
