import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ObjktSelectService} from '../../../services/select.service';
import {FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ObjktDetailsModel} from '../../../types/types';
import {mergeMap} from 'rxjs/operators';
import {SnackService} from '../../../services/snack.service';
import {tezosAddressValidator} from '../../../directives/tezos-address-validator.directive';
import {numberValidator} from '../../../directives/number-validator.directive';
import {WalletService} from '../../../services/tezos/wallet.service';
import {MarketplaceService} from '../../../services/tezos/marketplace.service';

@Component({
  selector: 'app-batch-transfer-modal',
  templateUrl: './batch-transfer-modal.component.html',
  styleUrls: ['./batch-transfer-modal.component.scss'],
})
export class BatchTransferModalComponent implements OnInit {
  @Input() show;
  @Output() showChange = new EventEmitter<boolean>();
  @ViewChild('objktForm') form: ElementRef;
  objkts: ObjktDetailsModel[];
  objktsForm = new FormGroup({objkts: new FormArray([])});
  title = 'Batch Transfer';
  tooManyObjktsError = null;
  maxObjkts = 100;
  loading = false;

  constructor(
    public objktSelect: ObjktSelectService,
    private readonly walletService: WalletService,
    private readonly marketplaceService: MarketplaceService,
    private readonly snackService: SnackService,
  ) {}

  get objktsFormArray(): FormArray {
    return this.objktsForm.get('objkts') as FormArray;
  }

  ngOnInit(): void {
    this.objktSelect.data$.subscribe(v => {
      if (v.lastAction.type === 'removeAtIndex') {
        this.objktsFormArray.removeAt(v.lastAction.params.index);
      }
      this.objkts = Object.values(v.items);
      this.tooManyObjktsError = this.objkts.length > this.maxObjkts ? `Exceeds maximum of ${this.maxObjkts} objkts, please remove some items` : null;
      if (!this.objkts.length) {
        this.show = false;
      }
    });

    for (const objkt of this.objkts) {
      this.objktsFormArray.push(
        new FormGroup({
          amount: new FormControl('', [
            Validators.required,
            Validators.pattern('^[0-9-]+$'),
            numberValidator({
              min: 1,
              max: 10000,
            }),
          ]),
          address: new FormControl('', [Validators.required, tezosAddressValidator()]),
        }),
      );
    }
  }

  getObjktFormGroupByIndex(index): FormGroup {
    return this.objktsFormArray?.get(index.toString()) as FormGroup;
  }

  handleBatchTransfer() {
    if (!this.objktsForm.valid) {
      this.validateAllFormFields(this.objktsForm);
      return;
    }

    this.loading = true;
    const recipients = [];
    for (let i = 0; i < this.objkts.length; i++) {
      recipients.push(
        Object.assign({}, this.objkts[i], {
          amount: this.objktsForm.get('objkts').value[i].amount,
          address: this.objktsForm.get('objkts').value[i].address,
        }),
      );
    }

    this.walletService
      .userAddress()
      .pipe(mergeMap(address => this.marketplaceService.batchTransfer(recipients, address)))
      .pipe(mergeMap(op => op.confirmation(1)))
      .subscribe(
        _ => {
          this.showChange.emit(false);
          this.snackService.success('The tokens have been transferred');
          this.loading = false;
        },
        err => {
          this.snackService.warn(err.description);
          this.loading = false;
        },
      );
  }

  getFormControl(index, type) {
    const formArray = this.objktsForm.get('objkts') as FormArray;
    return formArray.controls[index].get(type);
  }

  handleRemoveObjkt(index: number) {
    this.objktSelect.removeAtIndex(index);
  }

  showNumberError(index, type) {
    const formArray = this.objktsForm.get('objkts') as FormArray;
    const errors = formArray.controls[index].get(type).errors;
    if (errors.number.value === 'nan') {
      return 'Enter a number';
    }
    if (errors.number.value === 'too-low') {
      return 'Too low';
    }
    if (errors.number.value === 'too-high') {
      return 'Too high';
    }
  }

  setMaxOwned() {
    this.objktsFormArray.controls?.forEach((formGroup: FormGroup, index) => {
      formGroup.get('amount').setValue(this.objkts[index]?.owned_quantity);
      formGroup.get('amount').markAsTouched();
    });
  }

  copyAddressToAll(address: string) {
    this.objktsFormArray.controls?.forEach((formGroup: FormGroup, index) => {
      formGroup.get('address').setValue(address);
      formGroup.get('address').markAsTouched();
    });
  }

  private validateAllFormFields(formGroup: FormGroup | FormArray) {
    for (const field of Object.keys(formGroup.controls)) {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
        continue;
      }
      this.validateAllFormFields(control as FormGroup | FormArray);
    }
  }
}
