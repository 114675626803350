import {ChangeDetectionStrategy, Component, ContentChild, Input, OnInit, TemplateRef} from '@angular/core';
import {merge, Observable, of} from 'rxjs';
import {ObjktDetailsModel} from 'src/app/types/types';

@Component({
  selector: 'app-token-carousel',
  templateUrl: './token-carousel.component.html',
  styleUrls: ['./token-carousel.component.scss'],
  changeDetection: ChangeDetectionStrategy.OnPush,
})
export class TokenCarouselComponent implements OnInit {
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;

  @Input() tokens$: Observable<ObjktDetailsModel[]>;
  @Input() tokenPath?: string;
  @Input() divide;

  emptyList: any[];

  tokensMerged$: Observable<ObjktDetailsModel[]>;

  constructor() {}

  ngOnInit(): void {
    this.tokensMerged$ = merge(of(Array(12)), this.tokens$);
  }
}
