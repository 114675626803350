import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {combineLatest, Observable, of} from 'rxjs';
import {map} from 'rxjs/operators';
import {TokenBalanceApiService} from 'src/app/services/api/token-balance-api.service';
import {CurrencyBalance} from 'src/app/types/types';
import {BalanceService} from '../../services/explore/balance.service';

@Component({
  selector: 'app-wallet-panel',
  templateUrl: './wallet-panel.component.html',
  styleUrls: ['./wallet-panel.component.scss'],
})
export class WalletPanelComponent implements OnInit {
  @Output() action = new EventEmitter();

  model$: Observable<{
    balanceXTZ: number;
    balances: CurrencyBalance[];
  }>;

  constructor(private readonly balanceService: BalanceService) {}

  ngOnInit(): void {
    this.model$ = combineLatest([this.balanceService.getBalance(), this.balanceService.getBalancesUser()]).pipe(
      map(([balanceXTZ, balances]) => {
        return {
          balanceXTZ,
          balances,
        };
      }),
    );
  }
}
