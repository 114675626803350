import {ChangeDetectionStrategy, Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-warning-modal',
  templateUrl: './warning-modal.component.html',
  styleUrls: ['./warning-modal.component.scss'],
})
export class WarningModalComponent implements OnInit {
  @Input() title;
  @Input() message;
  @Input() show = false;
  @Output() showChange = new EventEmitter<boolean>();
  @Output() done = new EventEmitter<boolean>();

  constructor() {}

  ngOnInit(): void {}

  continue(value) {
    this.show = false;
    this.done.emit(value);
  }
}
