<app-header></app-header>
<div class="frow-container full-width main">
  <div class="content-container">
    <router-outlet></router-outlet>
  </div>
</div>
<app-footer></app-footer>
<div [class.is-visible]="transactionLoading$ | async" class="transaction-loading frow centered">
  <div class="modal-body">
    <div class="objkt-loader"></div>
    <p class="mt-40 text-center">
      {{ (loadingText$ | async) || 'Loading...' }}
    </p>
  </div>
</div>
<ng-container *ngIf="warningModalService.modals$ | async as modals">
  <app-warning-modal
    *ngFor="let modal of modals"
    [title]="modal.title"
    [message]="modal.message"
    [show]="true"
    (showChange)="$event === false ? handleModalClose(false, modal) : null"
    (done)="handleModalClose($event, modal)"
  ></app-warning-modal>
</ng-container>
<app-bee-migrate-modal></app-bee-migrate-modal>
<app-hanging-listings-modal></app-hanging-listings-modal>
