<div class="component-search">
  <ng-template #loading>
    <div class="objkt-loader"></div>
  </ng-template>
  <ng-container *ngIf="model$ | async as model; else loading">
    <ng-container *ngIf="!model.loading; else loading">
      <div class="users list">
        <h5>Users <a class="more" routerLink="explore/users" [queryParams]="{search: term}">view all »</a></h5>
        <a *ngFor="let user of model.users" class="list-element frow row-start" routerLink="profile/{{ user.address }}">
          <div class="avatar">
            <app-avatar-image [user]="user"></app-avatar-image>
          </div>
          <div class="element-content">
            <h3>{{ user.alias || user.tzdomain }}</h3>
            <span class="element-subtitle">{{ user.address | shortAddress }}</span>
          </div>
        </a>
        <p *ngIf="!model.users || !model.users.length" class="not-found">No Users found</p>
      </div>
      <div class="collections list">
        <h5>Collections <a class="more" routerLink="explore/collections" [queryParams]="{search: term, sort: 'volume_total:desc'}">view all »</a></h5>

        <a *ngFor="let collection of model.collections" [routerLink]="collection | collectionLink" class="list-element frow row-start">
          <app-collection-image [collection]="collection" class="preview"></app-collection-image>
          <div class="element-content">
            <h3>{{ collection.name }}</h3>
            <span class="element-subtitle">{{ collection.contract | shortAddress }}</span>
          </div>
        </a>
        <p *ngIf="!model.collections || !model.collections.length" class="not-found">No Collections found</p>
      </div>
      <div class="objkts list">
        <h5>Tokens <a class="more" routerLink="explore/tokens" [queryParams]="{search: term}">view all »</a></h5>
        <a *ngFor="let objkt of model.objkts" [routerLink]="objkt | objktLink" class="list-element frow row-start">
          <app-objkt-preview [objkt]="objkt" class="preview"></app-objkt-preview>
          <div class="element-content">
            <h3>{{ objkt.name }}</h3>
            <span class="element-subtitle">{{ objkt.fa?.name }}</span>
          </div>
        </a>
        <p *ngIf="!model.objkts || !model.objkts.length" class="not-found pb-20">No Objkts found</p>
      </div>
    </ng-container>
  </ng-container>
</div>
