import {catchError, tap} from 'rxjs/operators';
import {Observable, throwError} from 'rxjs';

import {Router} from '@angular/router';
import {Injectable} from '@angular/core';
import {HttpErrorResponse, HttpEvent, HttpHandler, HttpInterceptor, HttpRequest, HttpResponse} from '@angular/common/http';
import {environment} from 'src/environments/environment';

@Injectable()
export class AppInterceptor implements HttpInterceptor {
  constructor(private readonly router: Router) {}

  intercept(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    return next.handle(req).pipe(
      tap(evt => {
        if (environment.production === true) return;

        // graphQL does not return HTTP errors
        if (evt instanceof HttpResponse && evt.url.endsWith('graphql')) {
          if (evt.body.hasOwnProperty('errors')) {
            console.log(`💥 graphQL errors on ${req.body.operationName}`);
            console.log(evt.body.errors.map(e => e.message));
          }
        }
      }),
      catchError((error: HttpErrorResponse) => {
        if (error?.status && String(error.status).startsWith('5')) {
          this.router.navigate(['/500'], {state: {type: 'server'}, skipLocationChange: true});
        }
        return throwError(error);
      }),
    );
  }
}
