import {Injectable} from '@angular/core';
import {DisplayEventModel, EventModel} from '../types/types';
import {DateTime, Duration} from 'luxon';
import {environment} from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class EventService {
  readonly typeMap = {
    english_auction_bid: 'Auction bid',
    english_auction_create: 'Create auction',
    dutch_auction_create: 'Create auction',
    list: 'List',
    list_create: 'List',
    list_cancel: 'Cancelled listing',
    relist: 'Relist',
    purchase: 'Sale',
    open_edition_buy: 'Sale',
    open_edition_create: 'List Open',
    open_edition_update: 'List Open',
    list_buy: 'Sale',
    dutch_auction_buy: 'Sale',
    english_auction_settle: 'Sale',
    english_auction_settle_nosale: 'No Sale',
    create_collection: 'Create collection',
    offer_create: 'Offer',
    offer_floor_create: 'Offer',
    offer_accept: 'Accept offer',
    offer_floor_accept: 'Accept offer',
    transfer: 'Transfer',
    burn: 'Burn',
    mint: 'Mint',
    english_auction_cancel: 'Cancelled auction',
    dutch_auction_cancel: 'Cancelled auction',
    offer_cancel: 'Cancelled offer',
    offer_floor_cancel: 'Cancelled offer',
  };

  readonly eventFilterMap = [
    {
      label: 'List Buy',
      value: {market: 'list_buy'},
      group: 'Sale',
    },
    {
      label: 'Open Edition Buy',
      value: {event: 'open_edition_buy'},
      group: 'Sale',
    },
    {
      label: 'Dutch Auction Buy',
      value: {market: 'dutch_auction_buy'},
      group: 'Sale',
    },
    {
      label: 'Accept Offer',
      value: {market: 'offer_accept'},
      group: 'Sale',
    },
    {
      label: 'Accept Floor Offer',
      value: {market: 'offer_floor_accept'},
      group: 'Sale',
    },
    {
      label: 'Transfer',
      value: {event: 'transfer'},
      group: 'Transfer',
    },
    {
      label: 'Offer Create',
      value: {market: 'offer_create'},
      group: 'Offer',
    },
    {
      label: 'Floor Offer Create',
      value: {market: 'offer_floor_create'},
      group: 'Offer',
    },
    {
      label: 'Offer Cancel',
      value: {market: 'offer_cancel'},
      group: 'Offer',
    },
    {
      label: 'Floor Offer Cancel',
      value: {market: 'offer_floor_cancel'},
      group: 'Offer',
    },
    {
      label: 'Listing Create',
      value: {market: 'list_create'},
      group: 'List',
    },
    {
      label: 'Listing Cancel',
      value: {market: 'list_cancel'},
      group: 'List',
    },
    {
      label: 'Listing Open Create',
      value: {event: 'open_edition_create'},
      group: 'List',
    },
    {
      label: 'Listing Open Update',
      value: {event: 'open_edition_update'},
      group: 'List',
    },
    {
      label: 'Auction Bid',
      value: {market: 'english_auction_bid'},
      group: 'Auction',
    },
    {
      label: 'Auction Settle',
      value: {market: 'english_auction_settle'},
      group: 'Auction',
    },
    {
      label: 'New English Auction',
      value: {market: 'english_auction_create'},
      group: 'Auction',
    },
    {
      label: 'New Dutch Auction',
      value: {market: 'dutch_auction_create'},
      group: 'Auction',
    },
    {
      label: 'English Auction Cancel',
      value: {market: 'english_auction_cancel'},
      group: 'Auction',
    },
    {
      label: 'Dutch Auction Cancel',
      value: {market: 'dutch_auction_cancel'},
      group: 'Auction',
    },

    {
      label: 'Mint',
      value: {event: 'mint'},
      group: 'Mint',
    },
    {
      label: 'New Collection',
      value: {event: 'create_collection'},
      group: 'New Collection',
    },
  ];

  readonly collectionEventTypes = ['create_collection', 'offer_floor_create', 'offer_floor_cancel'];

  sanitizeEvents(events: EventModel[]): EventModel[] {
    const eventsByOpHash = events.reduce((map, event) => map.set(event.ophash, [...(map.get(event.ophash) || []), event]), new Map<string, EventModel[]>());

    eventsByOpHash.forEach((list, key) => {
      const eventTypes = list.map(e => e.event_type);
      const marketplaceEventTypes = list.map(e => e.marketplace_event_type);
      // removing transfer events that are not "done by hand" but triggered/requested by other operations
      if (
        eventTypes.includes('transfer') &&
        marketplaceEventTypes.some(e =>
          [
            'list_buy',
            'list_cancel',
            'list_create',
            'english_auction_create',
            'english_auction_settle',
            'english_auction_cancel',
            'dutch_auction_buy',
            'dutch_auction_cancel',
            'offer_accept',
            'offer_floor_accept',
          ].includes(e),
        )
      ) {
        eventsByOpHash.set(
          key,
          list.filter(e => e.event_type !== 'transfer'),
        );
      }
      // handle relist which consists of three events: list_cancel, transfer and list_create
      if (marketplaceEventTypes.includes('list_create') && marketplaceEventTypes.includes('list_cancel')) {
        const cancelEvent = list.find(e => e.marketplace_event_type === 'list_cancel');
        eventsByOpHash.set(key, [
          // keeping other events that are not related to the relist in the operation group
          ...list.filter(e => e.marketplace_event_type !== 'list_cancel' && e.marketplace_event_type !== 'list_create' && e.marketplace_event_type === 'transfer'),
          // renaming the list_create event into relist
          ...list
            .filter(e => e.marketplace_event_type === 'list_create')
            .map(e => ({
              ...e,
              event_type: 'relist',
              lastPrice: cancelEvent.price_xtz,
            })),
        ]);
      }
    });

    return Array.from(eventsByOpHash.values())
      .reduce((prev, curr) => [...prev, ...curr], [])
      .map(event => {
        if (event.event_type === 'english_auction_settle' && !event.price_xtz) {
          event.event_type = 'english_auction_settle_nosale';
        } else if (event.event_type === 'transfer' && environment.burnWallets.includes(event.recipient?.address)) {
          event.event_type = 'burn';
        }
        return event;
      })
      .sort((a, b) => new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime());
  }

  convertToDisplayEventModel(events: EventModel[]): DisplayEventModel[] {
    let currentDate;
    return events.map(event => {
      const date = DateTime.fromISO(event.timestamp);
      let dayString;
      let shortDayString;

      const now = DateTime.now();
      const yesterday = now.minus(Duration.fromObject({days: 1}));
      if (!currentDate || !date.hasSame(currentDate, 'day')) {
        if (date.hasSame(now, 'day')) {
          dayString = 'Today';
          shortDayString = dayString;
        } else if (date.hasSame(yesterday, 'day')) {
          dayString = 'Yesterday';
          shortDayString = dayString;
        } else if (date.hasSame(now, 'year')) {
          dayString = date.toFormat('d MMMM');
          shortDayString = date.toFormat('d MMM');
        } else {
          dayString = date.toFormat('d MMMM, yyyy');
          shortDayString = date.toFormat('d.M.yyyy');
        }
      }

      currentDate = date;

      return {
        ...event,
        dayString,
        shortDayString,
        timeString: DateTime.fromISO(event.timestamp).toFormat('HH:mm'),
      };
    });
  }

  filterOutCurrencyTransfer(events: EventModel[]) {
    return events.filter(event => event.event_type !== 'transfer' || event.token.decimals <= 0);
  }
}
