import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function tezosAddressValidator(): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!/^(tz|KT)[123456789ABCDEFGHJKLMNPQRSTUVWXYZabcdefghijkmnopqrstuvwxyz]{34}$/.test(control.value)) {
      return {address: {value: 'invalid'}};
    }

    return null;
  };
}
