<container-element [ngSwitch]="status">
  <div *ngSwitchCase="'banned'" class="status-banner banned">
    <span class="material-icons-outlined">warning</span> Interaction with this
    account is restricted.
  </div>
  <div *ngSwitchCase="'flagged'" class="status-banner review">
    <span class="material-icons-outlined">warning</span> This account is under
    review.
  </div>
  <div *ngSwitchCase="'hidden'" class="status-banner hidden">
    <span class="material-icons-outlined">warning</span> This {{ type }} is
    hidden to other users.
  </div>
  <div *ngSwitchCase="'collection_hidden_token'" class="status-banner hidden">
    <span class="material-icons-outlined">warning</span> Collection is hidden.
    This Token is still accessible through the page link.
  </div>
  <div *ngSwitchCase="'token_not_ready'" class="status-banner token-not-ready">
    <span class="material-icons-outlined">warning</span> Retrieving metadata for
    {{ type }}. Please try again in a few minutes.
  </div>
  <div *ngSwitchCase="'mature'" class="status-banner mature">
    <span class="material-icons-outlined">warning</span>
    <span>
      Mature content is hidden.
      <a [routerLink]="['/settings']">
        Update your settings to view this token.
      </a>
    </span>
  </div>
  <div *ngSwitchCase="'flashing'" class="status-banner flashing">
    <span class="material-icons-outlined">warning</span>
    <span>
      Flashing hazards content is hidden.
      <a [routerLink]="['/settings']">
        Update your settings to view this token.
      </a>
    </span>
  </div>
</container-element>
