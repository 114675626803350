import BigNumber from 'bignumber.js';
import {IndexerCurrency} from 'src/graphql/gen/indexer';
import {MarketContractType} from './types/types';

export interface Person {
  address?: string;
  description?: string;
  twitter?: string;
  site?: string;
  avatar?: string;
  name?: string;
}

export interface Auction {
  id?: number;
  creator: string;
  end_time: string;
  fa2: string;
  objkt_id: BigNumber;
  start_time: string;
  state: number;
}

export interface EnglishAuction extends Auction {
  highest_bidder: string;
  current_price: BigNumber;
  extension_time: BigNumber;
  price_increment: BigNumber;
  reserve: BigNumber;
  marketContractType?: MarketContractType;
  currency?: IndexerCurrency;
}

export interface DutchAuction extends Auction {
  end_price: BigNumber;
  start_price: BigNumber;
}

export interface HicdexAuction {
  id: number;
  type: AuctionType;
}

export enum AuctionType {
  English = 'e',
  Dutch = 'd',
  MultiEnglish = 'em',
  MultiDutch = 'dm',
}

export const enum AuctionStatus {
  finished = 'finished',
  concluded = 'concluded',
  cancelled = 'cancelled',
  active = 'active',
}

export const enum ActionType {
  englishBid = 'englishBid',
  dutchAuction = 'dutchAuction',
  englishAuction = 'englishAuction',
  bid = 'bid',
  ask = 'ask',
  fulfilledAsk = 'fulfilledAsk',
}

export interface LiveAction {
  type: ActionType;
  initiator: {
    name: string;
    address: string;
  };
  objktId: number;
  timestamp: string;
  amount?: number;
  value?: number;
}
