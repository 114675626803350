<ng-container *ngIf="tokenPageDefault$ | async as tokenPage">
  <ng-container *ngIf="!tokenPage.error">
    <ng-container *ngIf="tokenPage.tokens?.length; else noTokensFound">
      <div
        #tokenWrapper
        class="token-wrapper"
        [class.large-mode]="isLargeMode"
        [class.medium-mode]="isMediumMode"
        [class.masonry-mode]="isMasonryMode"
        [class.hide-details]="!isDetailMode"
      >
        <ng-container
          *ngFor="
            let token of tokenPage.tokens
              | paginate
                : {
                    id: 'server',
                    itemsPerPage: tokenPage.limit,
                    currentPage: tokenPage.page,
                    totalItems: tokenPage.total
                  }
          "
        >
          <app-objkt-gallery-element [objkt]="token" [showPricing]="true" [showSelect]="batchMode" [imgSize]="imageSize"></app-objkt-gallery-element>
        </ng-container>
      </div>
      <div class="pagination-wrapper text-center width-100 mt-30">
        <pagination-template #p="paginationApi" id="server" (pageChange)="pageChange.emit($event)">
          <div class="frow custom-pagination">
            <div class="pagination-previous" [class.disabled]="p.isFirstPage()">
              <a *ngIf="!p.isFirstPage()" (click)="p.previous()" class="mr-20 navigate frow centered">
                <span class="material-icons-outlined"> arrow_back_ios </span>
              </a>
            </div>
            <div class="page-of visible-xs">{{ p.getCurrent() | number: '1.0-0' }} of {{ p.pages[p.pages.length - 1]?.label | number: '1.0-0' }}</div>

            <div class="pagination-pages hidden-xs" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
              <a (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">
                <span>{{ page.label }}</span>
              </a>
              <div class="current-page" *ngIf="p.getCurrent() === page.value">
                <span>{{ page.label }}</span>
              </div>
            </div>

            <div class="pagination-next" [class.disabled]="p.isLastPage()">
              <a *ngIf="!p.isLastPage()" (click)="p.next()" class="ml-20 navigate frow centered">
                <span class="material-icons-outlined"> arrow_forward_ios </span>
              </a>
            </div>
          </div>
        </pagination-template>
      </div>
    </ng-container>
    <ng-template #noTokensFound>
      <ng-content select="[empty]"></ng-content>
    </ng-template>
  </ng-container>
  <div *ngIf="tokenPage.error">
    <ng-content select="[error]"></ng-content>
  </div>
</ng-container>
