import {AbstractControl, ValidationErrors, ValidatorFn} from '@angular/forms';

export function numberValidator({min = null, max = null}): ValidatorFn {
  return (control: AbstractControl): ValidationErrors | null => {
    if (!/^[0-9-.,]+$/.test(control.value) || /^00+$/.test(control.value)) {
      return {number: {value: 'nan'}};
    }
    if (min !== null && control.value < min) {
      return {number: {value: 'too-low'}};
    }
    if (max !== null && control.value > max) {
      return {number: {value: 'too-high'}};
    }

    return null;
  };
}
