import {Injectable} from '@angular/core';
import {ObjktModel, ObjktPreviewSizes} from '../types/types';
import {TokenService} from './token.service';

// Cloudflare caches 404 for about 3 minutes
const SIX_MINUTES = 6 * 60 * 1000;

@Injectable({
  providedIn: 'root',
})
export class AssetService {
  constructor(private tokenService: TokenService) {}

  getUri(objkt: ObjktModel, size?: ObjktPreviewSizes): string {
    const url = this.tokenService.getCDNUri(objkt, size);
    const timestampsByURL = this.getTimestampsByURL();

    if (this.isCached(timestampsByURL[url])) {
      return `${url}?cache=${Date.now()}`;
    } else {
      return url;
    }
  }

  markNotFound(objkt) {
    const timestampsByURL = this.getTimestampsByURL();
    const url = this.tokenService.getCDNUri(objkt);
    timestampsByURL[url] = Date.now();
    window.localStorage.setItem('timestampsByURL', JSON.stringify(timestampsByURL));
  }

  cleanNotFound() {
    const timestampsByURL = this.getTimestampsByURL();
    const newTimestampsByURL = {};

    for (const [url, timestamp] of Object.entries(timestampsByURL)) {
      if (this.isCached(timestamp)) newTimestampsByURL[url] = timestamp;
    }

    window.localStorage.setItem('timestampsByURL', JSON.stringify(newTimestampsByURL));
  }

  private getTimestampsByURL(): Record<string, number> {
    const rawTimestampsByURL = window.localStorage.getItem('timestampsByURL') || '{}';
    try {
      return JSON.parse(rawTimestampsByURL);
    } catch (error) {
      console.error(error);
      return {};
    }
  }

  private isCached(timestamp) {
    if (timestamp === undefined) return false;
    return Date.now() - timestamp < SIX_MINUTES;
  }
}
