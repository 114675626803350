import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WarningModalComponent} from './warning-modal.component';
import {ModalModule} from '../modal.module';
import {ButtonsModule} from '../../buttons/buttons.module';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [WarningModalComponent],
  imports: [CommonModule, ModalModule, ButtonsModule, RouterModule],
  exports: [WarningModalComponent],
})
export class WarningModalModule {}
