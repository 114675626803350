import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CategoryLinkComponent} from './category-link.component';
import {RouterModule} from '@angular/router';

@NgModule({
  declarations: [CategoryLinkComponent],
  exports: [CategoryLinkComponent],
  imports: [CommonModule, RouterModule],
})
export class CategoryLinkModule {}
