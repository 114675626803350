import {Injectable} from '@angular/core';
import {map} from 'rxjs/operators';
import {IndexerGetManyTokenOperatorsGQL} from '../../../graphql/gen/indexer';

@Injectable({
  providedIn: 'root',
})
export class OperatorsService {
  constructor(private readonly manyOperatorsGql: IndexerGetManyTokenOperatorsGQL) {}

  getOperatorsGql(userAddress: string, tokens: {contract: string; token_pk: number}[]) {
    const tokenPksGroupedByContract = this.getTokenPksGroupedByContract(tokens);
    const orClauses = this.getOrClauses(tokenPksGroupedByContract);
    return this.manyOperatorsGql.fetch({orClauses, userAddress}, {}).pipe(map(res => res.data.token_operator));
  }

  private getOrClauses(tokensGroupedByContract: {[p: string]: number[]}) {
    return Object.entries(tokensGroupedByContract).map(([contract, tokenPks]) => ({
      operator_address: {_eq: contract},
      token_pk: {_in: tokenPks},
    }));
  }

  private getTokenPksGroupedByContract(tokens: {contract: string; token_pk: number}[]) {
    return tokens.reduce<{[contract: string]: number[]}>((obj, {contract, token_pk}) => {
      if (!obj[contract]) obj[contract] = [token_pk];
      else obj[contract].push(token_pk);
      return obj;
    }, {});
  }
}
