import BigNumber from 'bignumber.js';
import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, switchMap} from 'rxjs/operators';
import {
  IndexerGetAuctionCountsByCreatorGQL,
  IndexerGetAuctionsEndingSoonFa2GQL,
  IndexerGetAuctionsEndingSoonFa2Query,
  IndexerGetAuctionsEndingSoonGQL,
  IndexerGetContractAuctionsGQL,
  IndexerGetCreatorAuctionsGQL,
  IndexerGetCuratedAuctionsGQL,
  IndexerGetDutchAuctionGQL,
  IndexerGetEnglishAuctionBidsGQL,
  IndexerGetEnglishAuctionGQL,
  IndexerGetTrendingAuctionsGQL,
  IndexerGetUserAuctionsGQL,
  IndexerGetUserOnlyAuctionsGQL,
} from 'src/graphql/gen/indexer';
import {
  AuctionType,
  ContractDutchAuction,
  DutchAuctionModel,
  EnglishAuctionBidModel,
  EnglishAuctionModel,
  GenericAuctionModel,
  MarketContractType,
} from 'src/app/types/types';
import {ObjktApiService} from './objkt-api.service';
import {ApolloQueryResult} from '@apollo/client';
import {DateTime} from 'luxon';

@Injectable({
  providedIn: 'root',
})
export class AuctionApiService {
  constructor(
    private readonly auctionEndingSoon: IndexerGetAuctionsEndingSoonGQL,
    private readonly auctionEndingSoonFa2: IndexerGetAuctionsEndingSoonFa2GQL,
    private readonly englishAuction: IndexerGetEnglishAuctionGQL,
    private readonly dutchAuction: IndexerGetDutchAuctionGQL,
    private readonly englishAuctionBids: IndexerGetEnglishAuctionBidsGQL,
    private readonly userAuctions: IndexerGetUserAuctionsGQL,
    private readonly collectionAuctions: IndexerGetUserOnlyAuctionsGQL,
    private readonly contractAuctions: IndexerGetContractAuctionsGQL,
    private readonly objktApiService: ObjktApiService,
    private readonly creatorAuctions: IndexerGetCreatorAuctionsGQL,
    private readonly curatedAuctions: IndexerGetCuratedAuctionsGQL,
    private readonly auctionCountsByCreator: IndexerGetAuctionCountsByCreatorGQL,
    private readonly trendingAuctions: IndexerGetTrendingAuctionsGQL,
  ) {}

  getAuctionsEndingSoon(
    contract: string,
    limit = 24,
  ): Observable<GenericAuctionModel[]> {
    let query$;

    if (contract) {
      query$ = this.auctionEndingSoonFa2.fetch(
        {
          fa2: contract,
          limit,
        },
        {
          fetchPolicy: 'no-cache',
        },
      );
    } else {
      query$ = this.auctionEndingSoon.fetch(
        {
          limit,
        },
        {
          fetchPolicy: 'no-cache',
        },
      );
    }

    return this.processAuctionsEndingSoon(query$, limit).pipe(
      switchMap(auctions => this.addDigestHashToAuctions(auctions)),
    );
  }

  getTrendingAuctions(limit: number = 24): Observable<EnglishAuctionModel[]> {
    return this.trendingAuctions
      .fetch({
        limit,
      })
      .pipe(
        map(this.zipAndSortAuctionsResponse),
        switchMap(auctions => this.addDigestHashToAuctions(auctions)),
      );
  }

  getAuction(
    type: AuctionType,
    id: number,
  ): Observable<EnglishAuctionModel | DutchAuctionModel> {
    switch (type) {
      case AuctionType.English:
        return this.getEnglishAuction(id);
      case AuctionType.Dutch:
        return this.getDutchAuction(id);
    }
  }

  // Probably not used, remove or comment?
  getCuratedAuctions(englishHashes: string[], dutchHashes: string[]) {
    return this.curatedAuctions.fetch({englishHashes, dutchHashes}).pipe(
      map(
        this.zipAndSortAuctionsResponse,
        switchMap(auctions => this.addDigestHashToAuctions(auctions)),
      ),
    );
  }

  getEnglishAuction(id: number): Observable<EnglishAuctionModel> {
    return this.englishAuction
      .fetch(
        {id},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result => result.data.english_auction[0]),
        map(auction => this.extendEnglishAuction(auction)),
        map(auction => ({
          ...auction,
          token: this.objktApiService.addAdditionalData(auction.token),
        })),
      );
  }

  getDutchAuction(id: number): Observable<DutchAuctionModel> {
    return this.dutchAuction
      .fetch(
        {id},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result => result.data.dutch_auction[0]),
        map(auction => this.extendDutchAuction(auction)),
        map(auction => ({
          ...auction,
          token: this.objktApiService.addAdditionalData(auction.token),
        })),
      );
  }

  getEnglishAuctionBids(id: number): Observable<EnglishAuctionBidModel[]> {
    return this.englishAuctionBids
      .fetch(
        {id},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data.english_auction_bid));
  }

  getAuctionCountsByCreator(address: string): Observable<number> {
    return this.auctionCountsByCreator
      .fetch(
        {address},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(res => {
          const englishCount =
            res.data.english_auction_aggregate.aggregate.count;
          const dutchCount = res.data.dutch_auction_aggregate.aggregate.count;

          return englishCount + dutchCount;
        }),
      );
  }

  getUserAuctions(address: string): Observable<GenericAuctionModel[]> {
    return this.userAuctions
      .fetch(
        {address},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(this.zipAuctionsResponse),
        switchMap(auctions => this.addDigestHashToAuctions(auctions)),
      );
  }

  // Not used, remove or comment?
  getCollectionAuctions(address: string): Observable<GenericAuctionModel[]> {
    return this.collectionAuctions
      .fetch(
        {
          address,
          timestamp: new Date(
            Math.floor(Date.now() / 60000) * 60000,
          ).toISOString(),
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(this.zipAndSortAuctionsResponse),
        switchMap(auctions => this.addDigestHashToAuctions(auctions)),
      );
  }

  // Not used, remove or comment?
  getCollectionAuctionsByContract(
    fa2: string,
  ): Observable<GenericAuctionModel[]> {
    return this.contractAuctions
      .fetch(
        {
          fa2,
          timestamp: new Date(
            Math.floor(Date.now() / 60000) * 60000,
          ).toISOString(),
          limit: 100,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(this.zipAndSortAuctionsResponse),
        switchMap(auctions => this.addDigestHashToAuctions(auctions)),
      );
  }

  getCurrentPriceFromModel(auction: DutchAuctionModel, timestamp: string) {
    const a = {
      ...auction,
      start_price: new BigNumber(auction.start_price),
      end_price: new BigNumber(auction.end_price),
    } as Partial<ContractDutchAuction>;
    return this.getCurrentPrice(a, timestamp);
  }

  getCurrentPrice(auction: Partial<ContractDutchAuction>, timestamp: string) {
    const currentTime = DateTime.fromISO(timestamp);
    const startTime = DateTime.fromISO(auction.start_time);
    const endTime = DateTime.fromISO(auction.end_time);
    const timeRemaining = new BigNumber(
      currentTime.diff(startTime).toMillis(),
    ).dividedToIntegerBy(1000);
    const priceDiff = auction.start_price.minus(auction.end_price);
    const timeDiff = new BigNumber(
      endTime.diff(startTime).toMillis(),
    ).dividedToIntegerBy(1000);
    return auction.start_price.minus(
      timeRemaining.multipliedBy(priceDiff.dividedToIntegerBy(timeDiff)),
    );
  }

  getCreatorAuctions(address: string): Observable<GenericAuctionModel[]> {
    return this.creatorAuctions
      .fetch(
        {
          address,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(this.zipAndSortAuctionsResponse),
        switchMap(auctions => this.addDigestHashToAuctions(auctions)),
      );
  }

  private addDigestHashToAuctions(auctions) {
    return this.objktApiService
      .addDigestHashToList(auctions.map(auction => auction.token))
      .pipe(
        map(tokens =>
          auctions.map(auction => ({
            ...auction,
            token:
              tokens.find(token => token.pk === auction.token.pk) ||
              auction.token,
          })),
        ),
      );
  }

  private processAuctionsEndingSoon(
    query$: Observable<ApolloQueryResult<IndexerGetAuctionsEndingSoonFa2Query>>,
    limit: number,
  ) {
    return query$.pipe(
      map(this.zipAndSortAuctionsResponse),
      map(results => results.slice(0, limit)),
    );
  }

  private zipAndSortAuctionsResponse = response => {
    return this.zipAuctionsResponse(response).sort((a, b) => {
      const aT = DateTime.fromISO(a.end_time);
      const bT = DateTime.fromISO(b.end_time);
      return aT.toMillis() - bT.toMillis();
    });
  };

  private zipAuctionsResponse = response => {
    return [
      ...(response.data.dutch_auction || []).map(a =>
        this.extendDutchAuction(a),
      ),
      ...(response.data.english_auction || []).map(a =>
        this.extendEnglishAuction(a),
      ),
    ].map(auction => ({
      ...auction,
      token: this.objktApiService.addAdditionalData(auction.token),
    }));
  };

  private extendEnglishAuction(auction) {
    return {
      ...auction,
      type: AuctionType.English,
      status: this.fixStatus(auction, AuctionType.English),
      marketContractType:
        MarketContractType[MarketContractType[auction.contract_version]],
    };
  }

  private extendDutchAuction(auction) {
    return {
      ...auction,
      type: AuctionType.Dutch,
      status: this.fixStatus(auction, AuctionType.Dutch),
      marketContractType:
        MarketContractType[MarketContractType[auction.contract_version]],
    };
  }

  private fixStatus(auction, type) {
    // auctions from graphQL active views do not have a status property but are indeed active
    let status = auction.status || 'active';
    const now = new Date();
    if (status === 'active' && now > new Date(auction.end_time)) {
      status = type === AuctionType.Dutch ? 'concluded' : 'finished';
    }

    return status;
  }
}
