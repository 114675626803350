import {Pipe, PipeTransform} from '@angular/core';
import {TokenService} from '../../services/token.service';

@Pipe({name: 'ipfs'})
export class IpfsPipe implements PipeTransform {
  constructor(private readonly tokenService: TokenService) {}

  transform(value: string): string {
    return this.tokenService.getTokenUri(value);
  }
}
