import {AfterViewInit, ChangeDetectorRef, Component, ContentChild, ElementRef, HostListener, Input, TemplateRef, ViewChild} from '@angular/core';
import {VirtualScrollerComponent, IPageInfo} from '../virtual-scroller/virtual-scroller.component';

const DEFAULT_DIVIDE = 4;

@Component({
  selector: 'app-carousel',
  templateUrl: './carousel.component.html',
  styleUrls: ['./carousel.component.scss'],
})
export class CarouselComponent implements AfterViewInit {
  @Input() divide = DEFAULT_DIVIDE;
  @Input() minWidth = 200;
  @Input() items: any[];
  @Input() loading = false;
  @ViewChild('itemsContainer') itemsContainer: ElementRef;
  @ViewChild(VirtualScrollerComponent)
  private virtualScroller: VirtualScrollerComponent;
  @ContentChild(TemplateRef) itemTemplate: TemplateRef<any>;
  page: IPageInfo;
  itemsWidth: string;
  hideNext: boolean;
  initialChange = true;
  scroll = 0;

  _scrollElement: any;

  private get scrollElement() {
    return this._scrollElement || (this._scrollElement = this.itemsContainer?.nativeElement?.querySelector('.scrollable-content'));
  }

  constructor(readonly cdRef: ChangeDetectorRef) {}

  ngAfterViewInit() {
    this.onResize(null);
    setTimeout(() => {
      this.onResize(null);
      this.itemsContainer.nativeElement.children[0].onscroll = event => this.onScroll(event);
    });
  }

  next() {
    let nextIndex = this.page.endIndex + 1;
    const itemNotEntirelyVisible = this.itemsContainer.nativeElement.clientWidth < this.getItemsWidth() * this.virtualScroller.viewPortItems.length;

    if (itemNotEntirelyVisible) {
      nextIndex -= 1;
    }

    this.virtualScroller.scrollToIndex(nextIndex);
  }

  previous() {
    let nextIndex = Math.max(0, this.page.startIndex - this.getItemsShown());
    const itemNotEntirelyVisible = this.itemsContainer.nativeElement.clientWidth < this.getItemsWidth() * this.getItemsShown();

    if (itemNotEntirelyVisible && nextIndex !== this.page.startIndex - 1) {
      nextIndex += 1;
    }
    this.virtualScroller.scrollToIndex(nextIndex);
  }

  @HostListener('window:resize', ['$event'])
  onResize(event) {
    this.setItemsWidth();
    this.virtualScroller.refresh();
    // this.cdRef.detectChanges();
  }

  private setItemsWidth() {
    this.itemsWidth = `max(${this.minWidth}px,${100 / this.getDivide()}%)`;
  }

  private getItemsWidth() {
    return Math.max(this.minWidth, this.itemsContainer.nativeElement.clientWidth / this.getDivide());
  }

  private getItemsShown() {
    return Math.floor(
      this.itemsContainer.nativeElement.clientWidth / Math.max(this.minWidth, this.itemsContainer.nativeElement.clientWidth / this.getDivide()),
    );
  }

  private getDivide() {
    const width = this.itemsContainer.nativeElement.clientWidth;

    if (width >= 1650) {
      return 8;
    }

    if (width >= 1200) {
      return 6;
    }

    return this.divide || DEFAULT_DIVIDE;
  }

  vsChange(page) {
    this.page = page;
  }

  private onScroll(event) {
    if (event.target.scrollWidth - event.target.scrollLeft === event.target.clientWidth) {
      this.hideNext = true;
      this.cdRef.detectChanges();
    } else if (this.hideNext) {
      this.hideNext = false;
      this.cdRef.detectChanges();
    }
  }
}
