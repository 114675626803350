<a [class.disabled]="!fa2" class="component-top-collection frow row-start items-stretch" [routerLink]="routerLink" [queryParams]="queryParams">
  <div class="avatar frow column-start justify-between">
    <app-collection-image *ngIf="fa2; else loadingImg" [collection]="fa2"></app-collection-image>
    <ng-template #loadingImg>
      <div class="loading-image gradient-loading-bg"></div>
    </ng-template>
  </div>
  <div class="ml-15 component-top-element-content frow column-start justify-evenly text-ellipsis">
    <a [class.gradient-loading-bg]="!fa2" class="person text-ellipsis" href="" [routerLink]="routerLink" [queryParams]="queryParams">
      <span *ngIf="fa2">{{ fa2.name || (fa2.contract | shortAddress) }}</span>
    </a>
    <span [class.gradient-loading-bg]="!fa2" class="value"
      >{{ value ? (value | tez | localisedNumber : '1.2-2') : '' }}
      <app-tez *ngIf="value"></app-tez>
    </span>
  </div>
</a>
