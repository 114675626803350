<div class="context-action-button-component" [class.left]="left">
  <div #button>
    <app-icon-button (click)="showActions = !showActions" [attr.aria-busy]="loading">
      <span class="material-icons-outlined" *ngIf="!loading"> {{ icon }} </span>
    </app-icon-button>
  </div>
  <div class="actions frow column-start" *ngIf="showActions">
    <ng-content></ng-content>
  </div>
</div>
