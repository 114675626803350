<div class="transactions-panel" *ngIf="transactionService.pendingTransactions$ | async as transactions">
  <a target="_blank" [href]="'https://tzkt.io/' + transaction.hash" *ngFor="let transaction of transactions">
    <span>{{ transaction.type }}</span>
    <span>
      {{ transaction.hash | shortAddress }}
      <span class="material-icons link">link</span>
    </span>
  </a>
  <div *ngIf="!transactions.length">
    <span class="material-icons">published_with_changes</span>
    No pending transactions
  </div>
</div>
