import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map, withLatestFrom} from 'rxjs/operators';
import {
  BackendGetNotificationsPageGQL,
  BackendMarkAllNotificationsReadGQL,
} from 'src/graphql/gen/backend';
import UserNotification from '../../models/notification.model';
import {BackendNotificationPageModel} from '../../types/types';
import {IUserNotificationPaginatedResults} from '../../models/notifications.interface';
import {WalletService} from '../tezos/wallet.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationApiService {
  hiddenNotifications = [];

  constructor(
    private getNotifications: BackendGetNotificationsPageGQL,
    private markAllNotificationsRead: BackendMarkAllNotificationsReadGQL,
    private walletService: WalletService,
  ) {}

  getAll(
    page: number,
    unread?: boolean,
  ): Observable<IUserNotificationPaginatedResults> {
    return this.getNotifications
      .fetch({page, unread}, {fetchPolicy: 'no-cache'})
      .pipe(
        map(res => res.data.notifications as BackendNotificationPageModel),
        withLatestFrom(this.walletService.userAddress()),
        map<
          [BackendNotificationPageModel, string],
          IUserNotificationPaginatedResults
        >(
          ([res, userAddress]) =>
            ({
              ...res,
              results: res.results
                .filter(
                  notification =>
                    this.hiddenNotifications.includes(
                      notification?.activity?.verb,
                    ) === false,
                )
                .map(n => new UserNotification(n, userAddress)),
            } as IUserNotificationPaginatedResults),
        ),
      );
  }

  markAllAsRead() {
    return this.markAllNotificationsRead.mutate();
  }
}
