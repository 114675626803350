<div class="component-objkt-auction-element {{ auction.status }}" *ngIf="auction; else loading">
  <a class="cover-wrapper" routerLink="/auction/{{ auction.type }}/{{ auction.bigmap_key | auctionIdHash }}">
    <span *ngIf="!inFuture; else notStarted" class="status-badge {{ auction.status }}">{{ auction.status }}</span>
    <ng-template #notStarted><span class="status-badge scheduled">Scheduled</span></ng-template>
    <app-objkt-preview [objkt]="auction?.token"></app-objkt-preview>
  </a>
  <ng-container *ngIf="model$ | async as model; else loadingModel">
    <div class="component-objkt-auction-element-content frow column-start">
      <div *ngIf="model.highestBid; else noBid" class="title">
        {{ model.highestBid | tez | localisedNumber: '1.2-2' }}
        <app-tez></app-tez>
      </div>
      <ng-template #noBid>
        <div *ngIf="auction.reserve; else noPrice" class="title reserve">
          {{ auction.reserve | tez | localisedNumber: '1.2-2' }}
          <app-tez></app-tez>
          <span style="font-size: 80%"> reserve</span>
        </div>
        <ng-template #noPrice>
          <div class="title">-</div>
        </ng-template>
      </ng-template>
      <div class="auction-type">{{ auctionTitle }}</div>
      <div *ngIf="auction.status !== 'finished' && auction.status !== 'cancelled'; else ended" class="time-remaining mt-5">
        <span class="amount">{{ model.countdown.days }}</span>
        <span class="time">d</span>
        <span class="amount">{{ model.countdown.hours }}</span>
        <span class="time">h</span>
        <span class="amount">{{ model.countdown.minutes }}</span>
        <span class="time">m</span>
        <span class="amount">{{ model.countdown.seconds }}</span>
        <span class="time">s</span>
      </div>
    </div>
    <ng-template #ended>
      <div class="time-remaining mt-5">ended</div>
    </ng-template>
  </ng-container>
  <ng-template #loadingModel>
    <div class="component-objkt-auction-element-content frow column-start gradient-loading">
      <div class="title">
        ?
        <app-tez></app-tez>
      </div>
      <div class="auction-type">?</div>
      <div class="time-remaining mt-5">
        <span class="amount">?</span>
      </div>
    </div>
  </ng-template>
</div>
<ng-template #loading>
  <div class="component-objkt-auction-element loading">
    <a class="cover-wrapper">
      <span class="status-badge gradient-loading">l</span>
      <div class="preview gradient-loading"></div>
    </a>
    <div class="component-objkt-auction-element-content frow column-start gradient-loading">
      <div class="title">
        ?
        <app-tez></app-tez>
      </div>
      <div class="auction-type">?</div>
      <div class="time-remaining mt-5">
        <span class="amount">?</span>
      </div>
    </div>
  </div>
</ng-template>
