import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {combineLatest, Observable} from 'rxjs';
import {map, mergeMap} from 'rxjs/operators';
import {SnackService} from 'src/app/services/snack.service';
import {environment} from 'src/environments/environment';
import {WalletService} from '../../../services/tezos/wallet.service';
import {BalanceService} from '../../../services/explore/balance.service';
import {MarketplaceService} from '../../../services/tezos/marketplace.service';

@Component({
  selector: 'app-wrap-modal',
  templateUrl: './wrap-modal.component.html',
  styleUrls: ['./wrap-modal.component.scss'],
})
export class WrapModalComponent implements OnInit {
  @Input() show = false;
  @Output() showChange = new EventEmitter<boolean>();
  form: FormGroup;
  mode: 'wrap' | 'unwrap' = 'wrap';
  address: string;

  isLoading = false;

  model$: Observable<{
    address: string;
    xtzBalance: number;
    wxtzBalance: number;
  }>;

  constructor(
    readonly formBuilder: FormBuilder,
    private readonly walletService: WalletService,
    private readonly balanceService: BalanceService,
    private readonly marketplaceService: MarketplaceService,
    private readonly snackService: SnackService,
  ) {}

  ngOnInit(): void {
    this.model$ = combineLatest([
      this.walletService.userAddress(),
      this.balanceService.getBalance(),
      this.balanceService.getBalanceCurrency(environment.currencies[0]),
    ]).pipe(
      map(([address, xtzBalance, wxtz]) => {
        this.initForm(xtzBalance);

        this.address = address;

        return {
          address,
          xtzBalance,
          wxtzBalance: wxtz.balance,
        };
      }),
    );
  }

  initForm(balance) {
    let normalizedBalance = balance / 1e6;

    if (this.mode === 'wrap') {
      normalizedBalance -= 0.15;
    }
    this.form = this.formBuilder.group({
      from: [null, [Validators.required, Validators.max(normalizedBalance), Validators.min(0.150001)]],
    });
  }

  switchMode(xtzBalance, wxtzBalance) {
    this.mode = this.mode === 'wrap' ? 'unwrap' : 'wrap';
    this.updateFromValue();

    if (this.mode === 'wrap') {
      this.initForm(xtzBalance);
    } else {
      this.initForm(wxtzBalance);
    }
  }

  updateFromValue(value?) {
    if (this.mode === 'wrap' && value) {
      value = parseFloat((value - 0.15).toFixed(6));
    }

    this.form.controls.from.setValue(value);
  }

  get toCurrencyValue() {
    return this.form.controls.from.value;
  }

  wrap() {
    if (!this.form.valid) {
      this.validateAllFormFields(this.form);
      return;
    }

    this.isLoading = true;

    const amount = this.form.controls.from.value * 1e6;

    this.marketplaceService
      .wrapXtz(amount, this.address)
      .pipe(
        mergeMap(op => {
          setTimeout(() => (this.show = false));
          this.showChange.emit(false);
          return op.confirmation(1);
        }),
      )
      .subscribe(
        res => {
          this.isLoading = false;
          this.snackService.success(`You succesfully wrapped ${this.form.controls.from.value} XTZ`);
        },
        err => {
          this.isLoading = false;
          this.snackService.warn(err.description);
        },
      );
  }

  unwrap() {
    if (!this.form.valid) {
      this.validateAllFormFields(this.form);
      return;
    }

    this.isLoading = true;

    const amount = this.form.controls.from.value * 1e6;

    this.marketplaceService
      .unwrapXtz(amount)
      .pipe(
        mergeMap(op => {
          this.showChange.emit(false);
          return op.confirmation(1);
        }),
      )
      .subscribe(
        res => {
          this.isLoading = false;
          this.snackService.success(`You succesfully unwrapped ${this.form.controls.from.value} wXTZ`);
        },
        err => {
          this.isLoading = false;
          this.snackService.warn(err.description);
        },
      );
  }

  private validateAllFormFields(formGroup: FormGroup) {
    Object.keys(formGroup.controls).forEach(field => {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
      } else if (control instanceof FormGroup) {
        this.validateAllFormFields(control);
      }
    });
  }
}
