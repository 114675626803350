<app-modal *ngIf="hangingListings$ | async as listings" [(show)]="showModal" title="Hanging Listings" class="hanging-listings-modal">
  <ng-container body>
    <ng-container *ngIf="!listingsCancelled; else isCancelled">
      <p>You have {{ listings.length }} active listing{{ listings.length > 1 ? 's' : '' }} for which you don't own the associated token anymore.</p>
      <p>Cancel all hanging listings now to prevent them from automatically reactivating if you ever receive the same token back.</p>
    </ng-container>
    <ng-template #isCancelled>
      <p>Your listings have been cancelled.</p>
    </ng-template>
  </ng-container>
  <ng-container footer>
    <div class="frow justify-end">
      <button *ngIf="!listingsCancelled" [attr.aria-busy]="isLoading" (click)="cancelListings(listings)">Cancel Listings</button>
      <button *ngIf="listingsCancelled" (click)="showModal = false">Close</button>
    </div>
  </ng-container>
</app-modal>
