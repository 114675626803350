import {Component, OnInit} from '@angular/core';
import {
  BehaviorSubject,
  forkJoin,
  merge,
  Observable,
  of,
  Subject,
  timer,
} from 'rxjs';
import {
  distinctUntilChanged,
  map,
  mergeMap,
  shareReplay,
  startWith,
  switchMap,
  tap,
} from 'rxjs/operators';
import {LoaderService} from 'src/app/loader.service';
import {
  EventModel,
  GenericAuctionModel,
  ObjktDetailsModel,
  ObjktModel,
} from 'src/app/types/types';
import {AuctionApiService} from 'src/app/services/api/auction-api.service';
import {EventApiService} from 'src/app/services/api/event-api.service';
import {Fa2ApiService} from 'src/app/services/api/fa2-api.service';
import {ObjktApiService} from 'src/app/services/api/objkt-api.service';
import {SalesStatsApiService} from 'src/app/services/api/sales-stats-api.service';
import {IndexerOrder_By} from 'src/graphql/gen/indexer';
import {DateTime} from 'luxon';
import {CurationApiService} from '../../services/api/curation-api.service';
import {GalleryApiService} from 'src/app/services/api/gallery-api.service';
import {WalletService} from 'src/app/services/tezos/wallet.service';

export interface CuratedTokenCategory {
  name: string;
  category: string;
}

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss'],
})
export class HomeComponent implements OnInit {
  startInterval$ = new Subject<number>();
  interval$ = timer(0, 10000);
  changeFeatured$ = new Subject<number>();
  featuredVisible = 0;
  featuredLength: number;
  topSellersAction$ = new BehaviorSubject<number>(1);
  topBuyersAction$ = new BehaviorSubject<number>(1);
  topSales$ = new BehaviorSubject<number>(1);
  featuredAuctions$ = new BehaviorSubject<string>('trending');
  topOptions = [
    {
      label: '1 day',
      value: 1,
    },
    {
      label: '7 days',
      value: 7,
    },
    {
      label: '30 days',
      value: 30,
    },
    {
      label: 'All time',
      value: 3650,
    },
  ];
  topSales = [
    {
      label: 'Today',
      value: 1,
    },
    {
      label: '7 days',
      value: 7,
    },
    {
      label: '30 days',
      value: 30,
    },
  ];
  featuredAuctions = [
    {
      label: 'Trending',
      value: 'trending',
    },
    {
      label: 'Ending Soon',
      value: 'ending-soon',
    },
  ];
  hotCollections$: Observable<any[]>;
  oeEndingSoon$: Observable<ObjktDetailsModel[]>;
  objktOne$: Observable<ObjktDetailsModel[]>;
  auctions$: Observable<GenericAuctionModel[]>;
  recentlyListed$: Observable<ObjktDetailsModel[]>;
  topSaleTokens$: Observable<EventModel[]>;
  gems$: Observable<ObjktDetailsModel[]>;
  topSellers$: Observable<any[]>;
  topBuyers$: Observable<any[]>;
  curations$: Observable<{
    tokens: (ObjktDetailsModel & {link: string})[];
    title: string;
  }>;
  userAddress$: Observable<string>;
  previouslyFeatured$: Observable<CuratedTokenCategory[]>;
  recentMints$: Observable<{
    limit: number;
    page: number;
    total: number;
    tokens: ObjktModel[];
  }>;

  bannedCollections = {
    afro_candies: 'KT1WgLr3avGBpqnm5SyM2nAzs3YgJiJFJU3t',
    emoz: 'KT1R2gKKacWREZJ6LvFnMFeapz5sUoLKckxP',
    fxhash: 'KT1KEa8z6vWXDJrVqtMrAeDVzsvxat3kHaCE', // remove fxhash because we show the galleries instead
    fxhash2: 'KT1U6EHmNxJTkvaWJ4ThczG4FSDaHC21ssvi', // remove fxhash because we show the galleries instead
    emprops: 'KT1MhSRKsujc4q5b5KsXvmsvkFyht9h4meZs', // remove Emergent properties because we show the galleries instead
  };

  constructor(
    readonly loaderService: LoaderService,
    readonly auctionApiService: AuctionApiService,
    readonly objktApiService: ObjktApiService,
    readonly salesStatsApiService: SalesStatsApiService,
    readonly fa2ApiService: Fa2ApiService,
    readonly eventApiService: EventApiService,
    readonly curationsApiService: CurationApiService,
    readonly galleryApiService: GalleryApiService,
    readonly walletService: WalletService,
  ) {
    this.loaderService.setLoading(true);
  }

  ngOnInit(): void {
    this.initModel();
  }

  initModel() {
    const curations$ = this.curationsApiService
      .getCurationsHttp()
      .pipe(shareReplay());
    this.oeEndingSoon$ = this.objktApiService.getOpenEditionsEndingSoon();

    this.objktOne$ = this.objktApiService.getObjktOneActiveTokens();

    this.auctions$ = this.featuredAuctions$.pipe(
      switchMap(value => {
        switch (value) {
          case 'ending-soon':
            return merge(
              of(new Array(10)),
              this.auctionApiService.getAuctionsEndingSoon(null, 24),
            );
          case 'trending':
          default:
            return merge(
              of(new Array(10)),
              this.auctionApiService.getTrendingAuctions(24),
            );
        }
      }),
    );
    this.recentlyListed$ = this.objktApiService.getAllRecentlyListedObjkts(24);
    this.gems$ = curations$.pipe(
      mergeMap(res => {
        const conditions = res.tokenFeed.curatedTokens.map(curatedToken => ({
          token_id: {_eq: curatedToken.tokenId.toString()},
          fa_contract: {_eq: curatedToken.contract},
        }));
        return this.objktApiService.getFeaturedCurations(conditions).pipe(
          map(resolvedTokens => {
            return res.tokenFeed?.curatedTokens.map(curatedToken => {
              return resolvedTokens.find(
                rt =>
                  rt.token_id === curatedToken.tokenId.toString() &&
                  rt.fa_contract === curatedToken.contract,
              );
            });
          }),
        );
      }),
    );
    this.topSellers$ = this.topSellersAction$.pipe(
      mergeMap(time => merge(of(new Array(10)), this.getTopSellers(time))),
    );
    this.topBuyers$ = this.topBuyersAction$.pipe(
      mergeMap(time => merge(of(new Array(10)), this.getTopBuyers(time))),
    );

    this.topSaleTokens$ = this.topSales$.pipe(
      mergeMap(time => merge(of(Array(6)), this.getTopSales(time))),
    );

    this.curations$ = curations$.pipe(
      mergeMap(res => {
        const conditions = res.curations[0].curatedTokens.map(curatedToken => ({
          token_id: {_eq: curatedToken.tokenId.toString()},
          fa_contract: {_eq: curatedToken.contract},
        }));
        return this.objktApiService.getFeaturedCurations(conditions).pipe(
          map(resolvedTokens => {
            let tokens = res.curations[0].curatedTokens
              .map(curatedToken => {
                const token = resolvedTokens.find(
                  rt =>
                    rt.token_id === curatedToken.tokenId.toString() &&
                    rt.fa_contract === curatedToken.contract,
                );
                if (token) {
                  return {
                    ...token,
                    link: curatedToken.link,
                  };
                }
              })
              .filter(token => !!token);

            if (res.curations[0].randomise) {
              tokens = tokens
                .map(value => ({value, sort: Math.random()}))
                .sort((a, b) => a.sort - b.sort)
                .map(({value}) => value);
            }

            return {
              title: res.curations[0].title,
              tokens,
            };
          }),
        );
      }),
      tap(curations => {
        this.featuredLength = curations.tokens.length;
        this.startInterval$.next(curations.tokens.length);
      }),
    );

    this.previouslyFeatured$ = this.curationsApiService.getCategoriesHttp();

    this.recentMints$ = this.objktApiService
      .getObjktsFiltered(
        {
          timestamp: {
            _is_null: false,
            _lt: new Date(
              Math.floor(
                DateTime.utc().minus({minutes: 2}).toMillis() / 60000,
              ) * 60000,
            ).toISOString(),
          },
          fa: {
            collection_type: {
              _eq: 'artist',
            },
          },
          artifact_uri: {
            _neq: '',
          },
          supply: {_gt: 0},
          flag: {_neq: 'removed'},
          content_rating: {_is_null: true},
        },
        {
          timestamp: IndexerOrder_By.Desc,
        },
        0,
        12,
      )
      .pipe(
        map(result => ({
          tokens: result,
          limit: 12,
          page: 0,
          total: 12,
        })),
      );

    this.hotCollections$ = merge(of(Array(20)), this.getHotFaAndGalleries());
    this.userAddress$ = this.walletService
      .userAddress()
      .pipe(distinctUntilChanged());

    this.changeFeatured$
      .pipe(
        startWith(0),
        switchMap(num => this.interval$.pipe(map(i => i + num))),
      )
      .subscribe(num => {
        this.featuredVisible = this.getNextFeatured(num);
      });
  }

  getNextFeatured(next) {
    if (next >= this.featuredLength) {
      return 0;
    } else {
      return next;
    }
  }

  getPrevFeatured(prev) {
    if (prev < 0) {
      return this.featuredLength - 1;
    } else {
      return prev;
    }
  }

  triggerNext() {
    this.triggerUpdate(this.getNextFeatured(this.featuredVisible + 1));
  }

  triggerPrev() {
    this.triggerUpdate(this.getPrevFeatured(this.featuredVisible - 1));
  }

  triggerUpdate(next) {
    this.changeFeatured$.next(next);
  }

  toRelative(timestamp: string) {
    return DateTime.fromISO(timestamp).toRelative();
  }

  private getTopBuyers(time: number) {
    return this.salesStatsApiService.getTopBuyers(time);
  }

  private getTopSellers(time: number) {
    return this.salesStatsApiService.getTopSellers(time);
  }

  private getTopSales(time: number) {
    return this.eventApiService.getAllRecentSales(time);
  }

  private getHotFaAndGalleries(amount = 20) {
    return forkJoin([
      this.fa2ApiService.getAllFa2(
        {
          volume_24h: IndexerOrder_By.Desc,
        },
        {
          live: {_eq: true},
          volume_24h: {_is_null: false},
          contract: {_nin: Object.values(this.bannedCollections)},
          _or: [
            {creator: {flag: {_eq: 'none'}}},
            {creator_address: {_is_null: true}},
          ],
        },
        amount,
      ),
      this.galleryApiService.getGalleriesLight(
        {
          volume_24h: {_is_null: false},
        },
        {
          volume_24h: IndexerOrder_By.Desc,
        },
        amount,
      ),
    ]).pipe(
      map(([fas, galleries]) => {
        return [...fas, ...galleries]
          .sort((a, b) => b.volume_24h - a.volume_24h)
          .slice(0, amount);
      }),
    );
  }
}
