import {Pipe, PipeTransform} from '@angular/core';
import {ObjktModel, ObjktPreviewSizes} from '../../types/types';
import {AssetService} from 'src/app/services/asset.service';

@Pipe({
  name: 'cdnArt',
})
export class CdnArtPipe implements PipeTransform {
  constructor(private readonly assetService: AssetService) {}

  transform(objkt: ObjktModel, ...args: ObjktPreviewSizes[]): string {
    return this.assetService.getUri(objkt, args && args[0]);
  }
}
