<footer class="footer">
  <div class="frow-container height-100">
    <div class="frow row-between height-100">
      <div class="logo frow centerd">
        <a class="mr-30" href="https://objkt.com">objkt.com</a>
        <a class="mr-15" href="https://twitter.com/objktcom" rel="nofollow" target="_blank">
          <img class="social-icon" src="/assets/twitter.svg" />
        </a>
        <a href="https://discord.gg/m7YFqPvmj6" rel="nofollow" target="_blank">
          <img class="social-icon" src="/assets/discord.svg" />
        </a>
      </div>
      <div class="links frow centered">
        <a class="mr-30" href="https://roadmap.objkt.com">Roadmap</a>
        <a class="mr-30" href="https://status.objkt.com">Status</a>
        <a class="mr-30" href="https://data.objkt.com">API</a>
        <a class="mr-30" href="https://docs.objkt.com">Docs</a>
        <a routerLink="/page/terms" class="mr-30">Terms & Conditions</a>
        <a routerLink="/page/imprint">Imprint</a>
        <!-- <a routerLink="release">Releases</a> -->
      </div>
    </div>
  </div>
</footer>
