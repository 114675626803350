import {Injectable} from '@angular/core';

import {IndividualConfig, ToastrService} from 'ngx-toastr';

@Injectable({
  providedIn: 'root',
})
export class SnackService {
  constructor(private toastr: ToastrService) {}

  default(text: string, title?: string, configOverride?: Partial<IndividualConfig>) {
    this.show('DEFAULT', text, title, configOverride);
  }

  success(text: string, title?: string, configOverride?: Partial<IndividualConfig>) {
    this.show('SUCCESS', text, title, configOverride);
  }

  warn(text: string, title?: string, configOverride?: Partial<IndividualConfig>) {
    this.show('WARNING', text, title, configOverride);
  }

  attention(text: string, title?: string, configOverride?: Partial<IndividualConfig>) {
    this.show('ATTENTION', text, title, configOverride);
  }

  private show(type: 'DEFAULT' | 'SUCCESS' | 'WARNING' | 'ATTENTION', text: string, title?: string, configOverride?: Partial<IndividualConfig>) {
    this.toastr.show(text, title, Object.assign({}, configOverride, {toastClass: `ngx-toastr ${type.toLowerCase()}`}));
  }
}
