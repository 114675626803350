import {HttpClient} from '@angular/common/http';
import {Injectable} from '@angular/core';
import {forkJoin, from, Observable, of} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  filter,
  map,
  mergeMap,
  switchMap,
} from 'rxjs/operators';
import {IpfsPipe} from 'src/app/pipes/ipfs/ipfs.pipe';
import {
  FeaturedObjktModel,
  MarketContractType,
  ObjktAskModel,
  ObjktBidModel,
  ObjktDetailsModel,
  ObjktDetailsModelPage,
  ObjktDisplayType,
  ObjktModel,
  TokenFilter,
  TokenHolderFilter,
  TokenSorting,
  TypesenseAuctionType,
} from 'src/app/types/types';
import {
  IndexerEnglishAuctionLightFragment,
  IndexerGetAllRecentlyListedObjktsGQL,
  IndexerGetFeaturedCurationsGQL,
  IndexerGetHomepageSelectionGQL,
  IndexerGetLatestTokenIdGQL,
  IndexerGetObjktDetailedGQL,
  IndexerGetObjktOneActiveTokensGQL,
  IndexerGetObjktsByHolderPagedGQL,
  IndexerGetObjktsGQL,
  IndexerGetObjktSimpleGQL,
  IndexerGetObjktsPagedGQL,
  IndexerGetObjktsSimpleGQL,
  IndexerGetOpenEditionEndingSoonGQL,
  IndexerGetRecentlyListedObjktsGQL,
  IndexerGetTokenMigrationCreatedTokensGQL,
  IndexerGetTokenMigrationOwnedTokensGQL,
  IndexerGetUserListingsGQL,
  IndexerGetUserOffersGQL,
  IndexerListingDefaultFragment,
  IndexerObjktDetailedOfferFragment,
  IndexerOfferDefaultFragment,
  IndexerOrder_By,
  IndexerToken_Bool_Exp,
  IndexerToken_Holder_Bool_Exp,
  IndexerToken_Holder_Order_By,
  IndexerToken_Order_By,
  IndexerUserListingFragment,
} from 'src/graphql/gen/indexer';
import {UserApiService} from './user-api.service';
import {environment} from 'src/environments/environment';
import {MarketplaceTypeService} from '../marketplace-type.service';

@Injectable({
  providedIn: 'root',
})
export class ObjktApiService {
  private mimeTypeGroups = {
    audio: [
      'audio/wav',
      'audio/ogg',
      'audio/mpeg',
      'audio/flac',
      'audio/x-wav',
    ],
    image: ['image/png', 'image/jpeg', 'image/bmp', 'image/tiff', 'image/webp'],
    gif: ['image/gif'],
    svg: ['image/svg+xml'],
    model: ['model/gltf-binary', 'model/gltf+json'],
    video: [
      'video/mp4',
      'video/webm',
      'video/quicktime',
      'video/ogg',
      'video/webm',
    ],
    interactive: ['application/x-directory'],
    pdf: ['application/pdf'],
    label: ['text/markdown'],
  };

  supportedThirdPartyMarketplaces = [
    environment.teiaContract,
    environment.versumMarketplaceContract,
    environment.hicetnuncContract,
    environment.fxhashMarketplaceContract,
    environment.fxhashMarketplaceContractV3,
    environment.bioduMarketplaceContract,
    environment.bidou24MarketplaceContract,
    environment.bidou24MonoMarketplaceContract,
    environment.typedMarketplaceContract,
    environment.scriboMarketplaceContract,
    environment.empropsMarketplaceContract,
    environment.akaswapMarketplaceV1Contract,
    environment.akaswapMarketplaceV2Contract,
    environment.akaswapMarketplaceV2_1Contract,
    environment.akaswapMarketplaceOfferContract,
    environment.dogamiMarketplaceContract,
  ];
  supportedObjktMarketplaces = [
    environment.contracts.marketplace,
    environment.contractsOld.marketplace,
  ];
  noRoyaltyFilter = [
    environment.versumMarketplaceContract,
    environment.fxhashMarketplaceContract,
    environment.fxhashMarketplaceContractV3,
    environment.empropsMarketplaceContract,
    environment.akaswapMarketplaceOfferContract,
  ];
  no0tezAllowed = [
    environment.hicetnuncContract,
    environment.bioduMarketplaceContract,
    environment.bidou24MarketplaceContract,
    environment.bidou24MonoMarketplaceContract,
  ];

  constructor(
    private objkts: IndexerGetObjktsGQL,
    private objktDetailed: IndexerGetObjktDetailedGQL,
    private objktSimple: IndexerGetObjktSimpleGQL,
    private objktsSimple: IndexerGetObjktsSimpleGQL,
    private homepageSelection: IndexerGetHomepageSelectionGQL,
    private objktsPaged: IndexerGetObjktsPagedGQL,
    private objktsByHolderPaged: IndexerGetObjktsByHolderPagedGQL,
    private objktsRecentlyListed: IndexerGetRecentlyListedObjktsGQL,
    private allObjktsRecentlyListed: IndexerGetAllRecentlyListedObjktsGQL,
    private userListings: IndexerGetUserListingsGQL,
    private userOffers: IndexerGetUserOffersGQL,
    private userApiService: UserApiService,
    private http: HttpClient,
    private ipfsPipe: IpfsPipe,
    private getFeaturedCurationsGql: IndexerGetFeaturedCurationsGQL,
    private getTokenMigrationOwnedTokens: IndexerGetTokenMigrationOwnedTokensGQL,
    private getTokenMigrationCreatedTokens: IndexerGetTokenMigrationCreatedTokensGQL,
    private marketplaceTypeService: MarketplaceTypeService,
    private latestTokenId: IndexerGetLatestTokenIdGQL,
    private openEditionEndingSoon: IndexerGetOpenEditionEndingSoonGQL,
    private objktOneActiveTokens: IndexerGetObjktOneActiveTokensGQL,
  ) {}

  getObjktDetailed(id: string, fa2: string): Observable<ObjktDetailsModel> {
    return this.objktDetailed
      .fetch(
        {
          tokenId: id,
          fa2,
          supportedThirdpartyContracts: this.supportedThirdPartyMarketplaces,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        filter(res => !!res.data.token),
        map(res =>
          this.addAdditionalData(res.data.token[0], null, null, res.data.offer),
        ),
        switchMap(res => this.addAsyncData(res)),
        switchMap(res => this.addAsyncUserData(res)),
      );
  }

  getLatestTokenId(fa2: string): Observable<string> {
    return this.latestTokenId
      .fetch(
        {fa2},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data.token[0]?.token_id));
  }

  getOpenEditionsEndingSoon(): Observable<ObjktDetailsModel[]> {
    return this.openEditionEndingSoon
      .fetch(
        {
          limit: 24,
          timestamp: new Date(
            Math.floor(Date.now() / 60000) * 60000,
          ).toISOString(),
        },
        {fetchPolicy: 'no-cache'},
      )
      .pipe(
        filter(res => !!res.data.token),
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
      );
  }

  getObjktOneActiveTokens(limit = 24): Observable<ObjktDetailsModel[]> {
    return this.objktOneActiveTokens
      .fetch({
        limit,
      })
      .pipe(
        filter(res => !!res.data.english_auction),
        map(res =>
          res.data.english_auction.map(auction =>
            this.addAdditionalData(
              auction.token,
              auction,
              TypesenseAuctionType.English,
            ),
          ),
        ),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
      );
  }

  getObjktSimple(id: string, fa2: string): Observable<ObjktModel> {
    return this.objktSimple
      .fetch(
        {tokenId: id, fa2},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        filter(res => !!res.data.token),
        map(res => this.addAdditionalData(res.data.token[0])),
        switchMap(res => this.addAsyncData(res)),
        switchMap(res => this.addAsyncUserData(res)),
      );
  }

  getObjktsSimple(where: IndexerToken_Bool_Exp): Observable<ObjktModel[]> {
    return this.objktsSimple
      .fetch(
        {where},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        filter(res => !!res.data.token),
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(res =>
          forkJoin(res.map(objkt => this.addAsyncUserData(objkt))),
        ),
      );
  }

  getHomepageSelection(
    objktData: {id: string; link?: string}[],
    fa2: string,
  ): Observable<FeaturedObjktModel[]> {
    return this.homepageSelection
      .fetch({tokenIds: objktData.map(objkt => objkt.id), fa2})
      .pipe(
        filter(res => !!res.data.token),
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
        map(objkts =>
          objkts.map(objkt => ({
            ...objkt,
            link: objktData.find(o => o.id === objkt.token_id).link,
          })),
        ),
      );
  }

  getRecentlyListedObjkts(
    fa2: string,
    limit = 24,
  ): Observable<ObjktDetailsModel[]> {
    return this.objktsRecentlyListed
      .fetch(
        {
          limit,
          fa2,
          supportedContracts: [
            ...this.supportedObjktMarketplaces,
            ...this.supportedThirdPartyMarketplaces,
          ],
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        filter(res => !!res.data.token),
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
      );
  }

  getAllRecentlyListedObjkts(limit = 24): Observable<ObjktDetailsModel[]> {
    return this.allObjktsRecentlyListed
      .fetch(
        {
          limit,
          supportedContracts: [
            ...this.supportedObjktMarketplaces,
            ...this.supportedThirdPartyMarketplaces,
          ],
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        filter(res => !!res.data.listing),
        map(res =>
          res.data.listing.map(ask => this.addAdditionalData(ask.token)),
        ),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
      );
  }

  getMigrationTokensOwned(
    tokenFilter: TokenHolderFilter,
    sorting: TokenSorting,
  ) {
    let orderBy = this.getObjktHolderSorting(sorting);

    if (orderBy.last_incremented_at) {
      orderBy = [
        {last_incremented_at: orderBy.last_incremented_at},
        {token_pk: orderBy.last_incremented_at},
      ] as any;
    }

    return this.getTokenMigrationOwnedTokens
      .fetch(
        {
          where: this.getObjktHolderFilter(tokenFilter),
          order_by: orderBy,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        mergeMap(res =>
          res.data.token_holder.length
            ? forkJoin(
                res.data.token_holder.map(tokenHolder =>
                  this.addDigestHash(tokenHolder.token).pipe(
                    map(token => ({
                      ...token,
                    })),
                  ),
                ),
              )
            : of([]),
        ),
      );
  }

  getMigrationTokensCreated(creator) {
    return this.getTokenMigrationCreatedTokens
      .fetch(
        {creator},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        filter(res => !!res.data.token),
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(res =>
          forkJoin(res.map(objkt => this.addAsyncUserData(objkt))),
        ),
      );
  }

  getObjktsByHolderPaged(
    tokenFilter: TokenHolderFilter,
    sorting: TokenSorting,
    offset = 0,
    limit = 12,
  ): Observable<ObjktDetailsModelPage> {
    let orderBy = this.getObjktHolderSorting(sorting);

    if (orderBy.last_incremented_at) {
      orderBy = [
        {last_incremented_at: orderBy.last_incremented_at},
        {token_pk: orderBy.last_incremented_at},
      ] as any;
    }
    return this.objktsByHolderPaged
      .fetch(
        {
          where: this.getObjktHolderFilter(tokenFilter),
          order_by: orderBy,
          limit,
          offset,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        mergeMap(res =>
          (res.data.token_holder.length
            ? forkJoin(
                res.data.token_holder.map(tokenHolder =>
                  this.addDigestHash(tokenHolder.token).pipe(
                    map(token => ({
                      ...token,
                      owned_quantity: tokenHolder.quantity,
                    })),
                  ),
                ),
              )
            : of([])
          ).pipe(
            map(tokens => ({
              total: res.data.token_holder_aggregate.aggregate.count,
              page: Math.floor(offset / limit) + 1,
              objkts: tokens.map(token => this.addAdditionalData(token)),
            })),
          ),
        ),
      );
  }

  getObjktsFiltered(
    where: IndexerToken_Bool_Exp,
    order_by: IndexerToken_Order_By,
    offset = 0,
    limit = 12,
  ) {
    return this.objkts
      .fetch(
        {
          where,
          order_by,
          limit,
          offset,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
      );
  }

  getObjkts(
    tokenFilter: TokenFilter,
    sorting: TokenSorting,
    offset = 0,
    limit = 12,
  ): Observable<ObjktDetailsModel[]> {
    return this.objkts
      .fetch(
        {
          where: this.getObjktFilter(tokenFilter),
          order_by: this.getObjktSorting(sorting),
          limit,
          offset,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(res => res.data.token.map(token => this.addAdditionalData(token))),
        switchMap(tokens => this.addDigestHashToList(tokens)),
        switchMap(tokens => this.addAsyncUserDataToList(tokens)),
      );
  }

  getObjktsPaged(
    tokenFilter: TokenFilter,
    sorting: TokenSorting,
    offset = 0,
    limit = 12,
  ): Observable<ObjktDetailsModelPage> {
    return this.objktsPaged
      .fetch(
        {
          where: this.getObjktFilter(tokenFilter),
          order_by: this.getObjktSorting(sorting),
          limit,
          offset,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        mergeMap(res =>
          this.addDigestHashToList(res.data.token).pipe(
            map(tokens => ({
              total: res.data.token_aggregate.aggregate.count,
              page: Math.floor(offset / limit) + 1,
              objkts: tokens.map(token => this.addAdditionalData(token)),
            })),
          ),
        ),
      );
  }

  getUserListings(address: string): Observable<IndexerUserListingFragment[]> {
    return this.userListings
      .fetch(
        {
          address,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        // Not using pluck as it hides things from TypeScript
        map(res => res.data.listing),
        defaultIfEmpty([]),
        map(listing => {
          return listing.sort(
            (a, b) =>
              new Date(b.timestamp).getTime() - new Date(a.timestamp).getTime(),
          );
        }),
      );
  }

  getUserOffers(address: string): Observable<ObjktBidModel[]> {
    return this.userOffers
      .fetch(
        {
          address,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        mergeMap(res =>
          res.data.offer.length
            ? forkJoin(
                res.data.offer.map(offer =>
                  this.addDigestHash(offer.token).pipe(
                    map(() => ({
                      ...offer,
                      marketContractType: this.marketplaceTypeService.get(
                        offer.marketplace_contract,
                      ),
                      token: offer.token
                        ? this.addAdditionalData(offer.token)
                        : null,
                    })),
                  ),
                ),
              )
            : of([]),
        ),
      );
  }

  addAdditionalData(
    objkt,
    activeAuction: IndexerEnglishAuctionLightFragment = null,
    activeAuctionType: TypesenseAuctionType = null,
    floorOffers:
      | IndexerObjktDetailedOfferFragment[]
      | IndexerOfferDefaultFragment[] = [],
  ) {
    const enrichedObjkt = {
      ...objkt,
      creators: objkt.creators?.map(creator => ({
        ...creator,
        holder: this.userApiService.extendUser(creator.holder),
      })),
      preview: this.getObjktPreview(objkt),
      listings: this.filterListings(objkt.listings, objkt),
      offers: [...(objkt.offers || []), ...floorOffers].filter(offer =>
        [
          ...this.supportedObjktMarketplaces,
          ...this.supportedThirdPartyMarketplaces,
        ].includes(offer.marketplace_contract),
      ),
    };

    // Filter invalid bids
    if (enrichedObjkt.offers) {
      enrichedObjkt.offers = enrichedObjkt.offers
        .filter(offer => {
          return this.filterInvalidRoyalties(
            offer,
            objkt.royalties,
            objkt.fa.collection_id,
          );
        })

        // add contract type
        .map(offer => {
          offer.marketContractType = this.marketplaceTypeService.get(
            offer.marketplace_contract,
          );
          return offer;
        })
        .sort((a, b) => this.sortOffers(a, b));
    }

    if (!enrichedObjkt.creators || !enrichedObjkt.creators.length) {
      enrichedObjkt.creators = [];
    }

    if ('holders' in objkt) {
      enrichedObjkt.holders = this.removeBurnFromHolders(objkt.holders);
    }

    if (enrichedObjkt.attributes) {
      try {
        enrichedObjkt.attributes = enrichedObjkt.attributes.map(attribute => {
          const count = objkt.fa.items;
          return {
            attribute: {
              ...attribute.attribute,
              rarity: attribute.attribute.count / count,
            },
          };
        });
      } catch (e) {}
    }

    if (activeAuction && activeAuctionType) {
      enrichedObjkt.auction_type = activeAuctionType;
      enrichedObjkt.hash = activeAuction.hash;
      enrichedObjkt.auction_status = activeAuction.status;
      enrichedObjkt.auction_highest_bid = activeAuction.highest_bid;
      enrichedObjkt.auction_end_time = activeAuction.end_time;
      enrichedObjkt.auction_start_time = activeAuction.start_time;
      enrichedObjkt.auction_reserve = activeAuction.reserve;
      enrichedObjkt.auction_price_increment = activeAuction.price_increment;
    }

    return enrichedObjkt;
  }

  getFeaturedCurations(conditions: IndexerToken_Bool_Exp[]) {
    return this.getFeaturedCurationsGql.fetch({conditions}).pipe(
      map(res => res.data.token.map(token => this.addAdditionalData(token))),
      switchMap(tokens => this.addDigestHashToList(tokens)),
      switchMap(tokens => this.addAsyncUserDataToList(tokens)),
    );
  }

  getDisplayType(mime: string, objkt: ObjktModel): ObjktDisplayType {
    mime = mime || '';

    // if no mime is present and we have a data URI, extract mime from there
    if (!mime && objkt.artifact_uri?.startsWith('data:')) {
      mime = objkt.artifact_uri.substring(
        objkt.artifact_uri.indexOf(':') + 1,
        objkt.artifact_uri.indexOf(';'),
      );
    }

    // Endless Ways
    if (objkt.fa_contract === 'KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB') {
      return ObjktDisplayType.EndlessWays;
    }

    // Tezos Domains
    if (objkt.fa_contract === 'KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS') {
      return ObjktDisplayType.Domain;
    }

    if (mime.includes('audio')) {
      return ObjktDisplayType.Audio;
    }

    if (mime.includes('image/svg+xml')) {
      return ObjktDisplayType.SVGXML;
    }

    if (
      mime.includes('application/x-directory') ||
      (mime.includes('text/html') && objkt.artifact_uri)
    ) {
      return ObjktDisplayType.HTML;
    }

    if (mime.includes('video')) {
      return ObjktDisplayType.Video;
    }

    if (mime.includes('image')) {
      return ObjktDisplayType.Image;
    }

    if (mime.includes('model/gltf')) {
      return ObjktDisplayType.Glb;
    }

    if (mime.includes('application/pdf')) {
      return ObjktDisplayType.PDF;
    }

    if (mime.includes('text/plain')) {
      return ObjktDisplayType.PlainText;
    }

    return ObjktDisplayType.None;
  }

  addDigestHashToList(objkts: any[]) {
    return forkJoin(objkts.map(objkt => this.addDigestHash(objkt))).pipe(
      defaultIfEmpty([]),
    );
  }

  addDigestHash(objkt) {
    if (!objkt) {
      return of(null);
    }

    return this.getDigestHash(objkt).pipe(
      map(artifactHash => {
        return {
          ...objkt,
          artifact_uri: artifactHash,
          original_artifact_uri: objkt.artifact_uri,
        };
      }),
    );
  }

  private filterInvalidRoyalties(
    offer:
      | IndexerOfferDefaultFragment
      | IndexerListingDefaultFragment
      | ObjktAskModel,
    objktRoyalties: {
      receiver_address: string;
      decimals: number;
      amount: number;
    }[],
    collectionId?: string,
  ) {
    let isValid = true;

    if (this.noRoyaltyFilter.includes(offer.marketplace_contract)) {
      return true;
    }

    offer.shares?.forEach(share => {
      const value = this.normalizeDecimals(
        Object.assign({decimals: share.decimals || 4}, share),
      );
      const objktRoyalty = objktRoyalties.find(
        royalty => royalty.receiver_address === share.recipient,
      );

      // SUTU hack just ignore
      if (
        share.recipient === 'KT1AjjABiXZWo9fr7HBthKUPd49wa8iiFty4' &&
        collectionId === '9190'
      ) {
        return;
      }

      // if there are no royalties, and royalty value is 0, return
      // this shouldn't happen as if there are no royalties the listing shouldn't have any shares.
      // but for some reason some listings like this exists on the old marketplace contract
      if (!objktRoyalties.length && !value) {
        return;
      }

      // Allow old offers below cutoff (11.02.2022) to be shown
      const isOldValidOffer =
        offer.bigmap_key <= 142613 && offer.__typename === 'offer';
      const isOldValidAsk =
        offer.bigmap_key <= 508468 && offer.__typename === 'listing';

      if (
        this.marketplaceTypeService.get(offer.marketplace_contract) ===
          MarketContractType.v1 &&
        (isOldValidAsk || isOldValidOffer) &&
        objktRoyalties.length > 1
      ) {
        return;
      }

      // check if the royalty is valid
      isValid =
        isValid &&
        objktRoyalty &&
        this.normalizeDecimals(objktRoyalty) === value;
    });

    // check if royalties are valid AND (royalties are the same length OR there are no royalties on the token).
    return (
      isValid &&
      (this.totalRoyalties(objktRoyalties) ===
        this.totalRoyalties(offer.shares) ||
        !objktRoyalties.length)
    );
  }

  private totalRoyalties(shares) {
    return shares.reduce(
      (acc, share) => acc + this.normalizeDecimals(share),
      0,
    );
  }

  private filterListings(
    listings: ObjktAskModel[] = [],
    objkt: ObjktModel,
  ): ObjktAskModel[] {
    return listings
      .filter(listing =>
        [
          ...this.supportedObjktMarketplaces,
          ...this.supportedThirdPartyMarketplaces,
        ].includes(listing.marketplace_contract),
      )
      .filter(listing => this.remove0Listing(listing))
      .map(listing => {
        listing.marketContractType = this.marketplaceTypeService.get(
          listing.marketplace_contract,
        );
        return listing;
      })
      .sort((a, b) => this.sortListings(a, b));
  }

  private remove0Listing(listing: ObjktAskModel) {
    return (
      !this.no0tezAllowed.includes(listing.marketplace_contract) ||
      listing.price > 0
    );
  }

  private addAsyncData(objkt) {
    // Remove the mime from bazaarmarket NFTs as it doesn't reflect reality
    if (objkt.mime === 'application/json; charset=utf-8') objkt.mime = null;

    const displayType = this.getDisplayType(objkt.mime, objkt);
    return forkJoin({
      artifactUri: this.getDigestHash(objkt),
      displayType:
        displayType === ObjktDisplayType.None && objkt.mime
          ? this.getDigestHash(objkt).pipe(
              mergeMap(digestHash =>
                this.getMimeType(digestHash).pipe(
                  catchError(_ => this.getMimeType(objkt.artifact_uri || '')),
                  map(mime => {
                    objkt.mime = mime;
                    return this.getDisplayType(mime, objkt);
                  }),
                ),
              ),
            )
          : of(displayType),
    }).pipe(
      map(({artifactUri, displayType}) => ({
        ...objkt,
        displayType,
        artifact_uri: artifactUri,
        original_artifact_uri: objkt.artifact_uri,
      })),
    );
  }

  addAsyncUserDataToList(objkts): Observable<any[]> {
    return forkJoin(objkts.map(objkt => this.addAsyncUserData(objkt))).pipe(
      defaultIfEmpty([]),
    );
  }

  private addAsyncUserData(objkt): Observable<any> {
    return forkJoin(
      objkt.creators?.map(creator =>
        this.userApiService.addAvatarToUser(creator.holder).pipe(
          map(user => ({
            ...creator,
            holder: user,
          })),
        ),
      ),
    ).pipe(
      defaultIfEmpty([]),
      map(creators => ({
        ...objkt,
        creators,
      })),
    );
  }

  private getObjktHolderSorting(
    sorting: TokenSorting,
  ): IndexerToken_Holder_Order_By {
    // TODO
    // We will have to sort this by "Recently Received / Oldest" in the future when it becomes available
    // we are now falling back to token.timestamp (which is not correct but a workaround)

    const propertyName = 'last_incremented_at';

    const s = {
      [propertyName]: null,
    };
    switch (sorting?.sortBy) {
      case 'hidAsc':
        s[propertyName] = IndexerOrder_By.Asc;
        break;
      case 'hidDesc':
      case undefined:
        s[propertyName] = IndexerOrder_By.Desc;
        break;
    }
    return {
      ...s,
      token: s[propertyName] ? undefined : this.getObjktSorting(sorting),
    };
  }

  private getObjktSorting(sorting: TokenSorting): IndexerToken_Order_By {
    switch (sorting?.sortBy) {
      case 'listDesc':
        return {
          last_listed: IndexerOrder_By.DescNullsLast,
        };
      case 'bidAsc':
        return {
          highest_offer: IndexerOrder_By.AscNullsLast,
        };
      case 'bidDesc':
        return {
          highest_offer: IndexerOrder_By.DescNullsLast,
        };
      case 'askAsc':
        return {
          lowest_ask: IndexerOrder_By.AscNullsLast,
        };
      case 'askDesc':
        return {
          lowest_ask: IndexerOrder_By.DescNullsLast,
        };
      case 'idAsc':
        return [
          {timestamp: IndexerOrder_By.Asc},
          {token_id: IndexerOrder_By.Asc},
        ];
      case 'idDesc':
      default:
        return [
          {timestamp: IndexerOrder_By.Desc},
          {token_id: IndexerOrder_By.Desc},
        ];
    }
  }

  private getObjktFilter(tokenFilter: TokenFilter): IndexerToken_Bool_Exp {
    const newFilter: IndexerToken_Bool_Exp = {
      supply: {_gt: '0'},
      flag: {_neq: 'removed'},
      artifact_uri: {_neq: ''},
      timestamp: {_is_null: false},
      _or: [],
    };

    if (tokenFilter.hideBanned) {
      newFilter.flag = {
        _eq: 'none',
      };
    }

    if (!tokenFilter.showHidden) {
      newFilter.fa = {
        live: {_eq: true},
      };
    }

    if (tokenFilter.id) {
      newFilter._or.push({token_id: {_eq: tokenFilter.id}});
    }

    if (tokenFilter.search) {
      newFilter._or = [
        {
          name: {
            _ilike: tokenFilter.search ? `%${tokenFilter.search}%` : undefined,
          },
        },
        {
          creators: {
            creator_address: {
              _eq: tokenFilter.search || undefined,
            },
            verified: {
              _eq: true,
            },
          },
        },
        {
          creators: {
            holder: {
              alias: {
                _ilike: tokenFilter.search
                  ? `%${tokenFilter.search}%`
                  : undefined,
              },
            },
            verified: {
              _eq: true,
            },
          },
        },
        {
          creators: {
            holder: {
              tzdomain: {
                _eq: tokenFilter.search || undefined,
              },
            },
            verified: {
              _eq: true,
            },
          },
        },
        ...newFilter._or,
      ];
    }

    if (tokenFilter.creatorId) {
      newFilter.creators = {creator_address: {_eq: tokenFilter.creatorId}};
    }

    if (tokenFilter.creatorIdNot) {
      newFilter.creators = {
        creator_address: {_neq: tokenFilter.creatorIdNot},
      };
    }

    if (tokenFilter.fa2) {
      if (tokenFilter.fa2.length === 1) {
        newFilter.fa_contract = {
          _eq: tokenFilter.fa2[0],
        };
      } else {
        newFilter.fa_contract = {
          _in: tokenFilter.fa2,
        };
      }
    }

    if (tokenFilter.type) {
      newFilter.mime = {_in: this.mimeTypeGroups?.[tokenFilter.type] || []};
    }

    if (tokenFilter.primary) {
      newFilter.holders = {
        holder_address: {_eq: tokenFilter.primary},
        quantity: {_gt: 0},
      };
      newFilter.lowest_ask = {_gte: 0};
    }

    if (!newFilter._or.length) {
      newFilter._or = undefined;
    }

    if (tokenFilter.showOe) {
      newFilter.supply = undefined;
      newFilter._or = [
        {
          supply: {_gt: '0'},
        },
        {
          _not: {holders: {}},
        },
      ];
    }

    return newFilter;
  }

  private getObjktHolderFilter(
    tokenHolderFilter: TokenHolderFilter,
  ): IndexerToken_Holder_Bool_Exp {
    return {
      holder_address: {_eq: tokenHolderFilter.holderId},
      quantity: {_gt: 0},
      token: this.getObjktFilter(tokenHolderFilter.tokenFilter),
    };
  }

  private getMimeType(uri: string) {
    return this.http
      .head(this.ipfsPipe.transform(uri), {observe: 'response'})
      .pipe(
        map(response => {
          return response.headers.get('Content-Type');
        }),
      );
  }

  getObjktPreview(token) {
    let preview;
    if (token.mime && token.mime.includes('image')) {
      preview = token.display_uri || token.artifact_uri;
    } else {
      preview = token.display_uri || token.thumbnail_uri;
    }
    if (preview?.startsWith('ipfs://') || preview?.startsWith('data:image/')) {
      return preview;
    }
    return '';
  }

  private removeBurnFromHolders(tokenHolders) {
    return tokenHolders.filter(
      o => !environment.burnWallets.includes(o.holder_address),
    );
  }

  private normalizeDecimals(royalty: {
    amount: number;
    decimals: number;
  }): number {
    const deltaDecimals = 4 - (royalty.decimals || 4);
    return Math.round(royalty.amount * Math.pow(10, deltaDecimals));
  }

  private getDigestHash(objkt) {
    const exceptions = {
      endlessWays: 'KT1VdCrmZsQfuYgbQsezAHT1pXvs6zKF8xHB',
      tzDomains: 'KT1GBZmSxmnKJXGMdMLbugPfLyUPmuLSMwKS',
    };
    if (
      (objkt.artifact_uri?.startsWith('https://') ||
        objkt.artifact_uri?.startsWith('http://')) &&
      !Object.values(exceptions).includes(objkt.fa_contract)
    ) {
      return this.digestMessage(objkt.artifact_uri).pipe(
        map(digestHex => `hex://${digestHex}`),
      );
    }
    return of(objkt.artifact_uri);
  }

  private digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message);
    return from(crypto.subtle.digest('SHA-1', msgUint8)).pipe(
      map(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      }),
      catchError(() => of(message)),
    );
  }

  private sortListings(
    a: {price?: number; marketplace_contract: string},
    b: {price?: number; marketplace_contract: string},
  ) {
    if (a.price < b.price) {
      return -1;
    }

    if (b.price < a.price) {
      return 1;
    }

    // if prices are equal, show our marketplace first
    if (!this.marketplaceTypeService.isObjkt(a.marketplace_contract)) {
      return 1;
    }

    return -1;
  }

  private sortOffers(
    a: {price?: number; marketplace_contract: string},
    b: {price?: number; marketplace_contract: string},
  ) {
    if (a.price > b.price) {
      return -1;
    }

    if (b.price > a.price) {
      return 1;
    }

    // if prices are equal, show our marketplace first
    if (!this.marketplaceTypeService.isObjkt(a.marketplace_contract)) {
      return 1;
    }

    return -1;
  }
}
