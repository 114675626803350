import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SearchPreviewComponent} from './search-preview.component';
import {RouterModule} from '@angular/router';
import {ShortAddressPipeModule} from '../../pipes/short-address/short-address-pipe.module';
import {CollectionLinkPipeModule} from '../../pipes/collection-link/collection-link-pipe.module';
import {ObjktLinkPipeModule} from '../../pipes/objkt-links/objkt-link-pipe.module';
import {ObjktPreviewModule} from '../objkt-preview/objkt-preview.module';
import {CollectionImageModule} from '../collection-image/collection-image.module';
import {AvatarImageModule} from '../avatar-image/avatar-image.module';

@NgModule({
  declarations: [SearchPreviewComponent],
  imports: [
    CommonModule,
    RouterModule,
    ShortAddressPipeModule,
    CollectionLinkPipeModule,
    ObjktLinkPipeModule,
    ObjktPreviewModule,
    CollectionImageModule,
    AvatarImageModule,
  ],
  exports: [SearchPreviewComponent],
})
export class SearchPreviewModule {}
