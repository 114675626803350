<app-modal [(show)]="show" (showChange)="showChange.next($event)" title="Wrap/Unwrap XTZ" class="wrap-modal-component">
  <ng-container body>
    <ng-template #loading>
      <div class="objkt-loader"></div>
    </ng-template>
    <form *ngIf="model$ | async as model; else loading" class="frow column-center pt-5" [formGroup]="form">
      <div class="width-100">
        <h4 class="mb-10">From</h4>
        <label class="mt-10">
          <ng-container [ngTemplateOutlet]="mode === 'wrap' ? xtz : wxtz"></ng-container>
          <input formControlName="from" placeholder="Amount" type="number" />
        </label>
      </div>

      <span class="material-icons switch-mode" (click)="switchMode(model.xtzBalance, model.wxtzBalance)"> swap_vert </span>

      <div class="width-100">
        <h4 class="mb-10">To</h4>
        <label class="mt-10">
          <ng-container [ngTemplateOutlet]="mode === 'wrap' ? wxtz : xtz"></ng-container>

          <input disabled [value]="toCurrencyValue || ''" placeholder="Amount" type="text" />
        </label>
      </div>

      <ng-template #xtz>
        <span class="mb-5">
          <img class="currency-logo" src="./assets/XTZ.svg" />
          XTZ
          <span class="right" (click)="updateFromValue(model.xtzBalance / 1e6)">Balance: {{ model.xtzBalance | tez | localisedNumber: '1.2-6' }}</span>
        </span>
      </ng-template>
      <ng-template #wxtz>
        <span class="mb-5">
          <img class="currency-logo" src="./assets/wXTZ.svg" />
          oXTZ
          <span class="right" (click)="updateFromValue(model.wxtzBalance / 1e6)">Balance: {{ model.wxtzBalance | tez | localisedNumber: '1.2-6' }}</span>
        </span>
      </ng-template>
    </form>
  </ng-container>
  <ng-container footer>
    <div class="frow width-100 row-end">
      <button [attr.aria-busy]="isLoading" *ngIf="mode === 'wrap'" (click)="wrap()">Wrap</button>
      <button [attr.aria-busy]="isLoading" *ngIf="mode === 'unwrap'" (click)="unwrap()">Unwrap</button>
    </div>
  </ng-container>
</app-modal>
