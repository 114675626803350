import {Injectable} from '@angular/core';
import {environment} from '../../../environments/environment';
import {
  CurrencyBalance,
  FaModel,
  GenericAuctionModel,
  MarketContractType,
  ObjktAskModel,
  ObjktBidModel,
  ObjktDetailsModel,
  ObjktModel,
  TransactionType,
  UbisoftTransferResponseType,
} from '../../types/types';
import {WalletService} from './wallet.service';
import {
  catchError,
  map,
  mergeMap,
  switchMap,
  tap,
  toArray,
} from 'rxjs/operators';
import {
  combineLatest,
  forkJoin,
  from,
  Observable,
  of,
} from 'rxjs';
import {
  ContractAbstraction,
  DefaultWalletType,
  MichelsonMap,
  OpKind,
  OriginationWalletOperation,
  Wallet,
  WalletOperation,
  WalletParamsWithKind,
} from '@taquito/taquito';
import {AuctionType, DutchAuction, EnglishAuction} from '../../types';
import {IndexerRoyalties} from '../../../graphql/gen/indexer';
import {TransactionsService} from './transactions.service';
import {HttpClient} from '@angular/common/http';
import {OperatorsService} from '../api/operators.service';
import {MarketplaceTypeService} from '../marketplace-type.service';
import {openEditionV1ContractCode} from 'src/app/views/mint/new-collection/contracts/open_edition_1';

@Injectable({
  providedIn: 'root',
})
export class MarketplaceService {
  private _storageLimit = 350;
  private _storagePerShare = 33;

  constructor(
    private readonly walletService: WalletService,
    private readonly transactionsService: TransactionsService,
    private readonly operatorsService: OperatorsService,
    private readonly http: HttpClient,
    private readonly marketplaceType: MarketplaceTypeService,
  ) {}

  getTransferAnnotations(fa2Contract) {
    // Used to deal with fa2-contracts deployed without or with different type annotations

    const defaultAnnotations = {
      from: 'from_',
      txs: 'txs',
      to: 'to_',
      tokenId: 'token_id',
      amount: 'amount',
    };

    try {
      const annotations = {...defaultAnnotations};

      // hard-coded paths for annotations
      const args = fa2Contract.entrypoints.entrypoints.transfer.args[0];
      const from = args.args[0]?.annots;
      const txs = args.args[1]?.annots;
      const to = args.args[1]?.args[0]?.args[0]?.annots;
      let tokenId = args.args[1]?.args[0]?.args[1]?.annots;
      let amount = args.args[1]?.args[0]?.args[2]?.annots;

      tokenId = tokenId || args.args[1]?.args[0]?.args[1].args[0]?.annots;
      amount = amount || args.args[1]?.args[0]?.args[1].args[1]?.annots;

      // annotations are prefixed with a "%"
      annotations.from = from ? from[0]?.substr(1) ?? '0' : '0';
      annotations.txs = txs ? txs[0]?.substr(1) ?? '1' : '1';
      annotations.to = to ? to[0]?.substr(1) ?? '0' : '0';
      annotations.tokenId = tokenId ? tokenId[0]?.substr(1) ?? '1' : '1';
      annotations.amount = amount ? amount[0]?.substr(1) ?? '2' : '2';

      return annotations;
    } catch {}

    return defaultAnnotations;
  }

  getMarketplaceContract(contract: string) {
    return this.walletService.getContractAt(contract);
  }

  getLatestMarketplaceContract() {
    return this.walletService.getContractAt(environment.contracts.marketplace);
  }

  getEnglishAuctionContract(type: MarketContractType) {
    switch (type) {
      case MarketContractType.v1:
        throw new Error('Contract version 1 not supported');
      case MarketContractType.v2:
        return this.walletService.getContractAt(
          environment.contractsOld.english,
        );
      case MarketContractType.v4:
        return this.walletService.getContractAt(environment.contracts.english);
      default:
        throw new Error('Contract version not supported');
    }
  }

  getLatestEnglishAuctionContract() {
    return this.getEnglishAuctionContract(MarketContractType.v4);
  }

  getDutchAuctionContract(type: MarketContractType) {
    switch (type) {
      case MarketContractType.v1:
        throw new Error('Contract version 1 not supported');
      case MarketContractType.v2:
        return this.walletService.getContractAt(environment.contractsOld.dutch);
      case MarketContractType.v4:
        return this.walletService.getContractAt(environment.contracts.dutch);
      default:
        throw new Error('Contract version not supported');
    }
  }

  getLatestDutchAuctionContract() {
    return this.getDutchAuctionContract(MarketContractType.v4);
  }

  cancelBid(offer: ObjktBidModel) {
    switch (this.marketplaceType.get(offer.marketplace_contract)) {
      case MarketContractType.v1:
        return this.cancelBidV1(offer);
      case MarketContractType.v4:
        return this.cancelBidV4(offer);
      case MarketContractType.versum:
        return this.cancelBidVersum(offer);
      case MarketContractType.fxhash:
      case MarketContractType.fxhashV3:
        return this.cancelBidFxhash(offer);
      case MarketContractType.akaswapOffer:
        return this.cancelBidAkaswap(offer);
      default:
        throw new Error('Contract version not supported');
    }
  }

  cancelAsk(ask: ObjktAskModel) {
    switch (this.marketplaceType.get(ask.marketplace_contract)) {
      case MarketContractType.hen:
      case MarketContractType.teia:
      case MarketContractType.scribo:
        return this.cancelAskHen(ask);
      case MarketContractType.versum:
        return this.cancelAskVersum(ask);
      case MarketContractType.fxhash:
      case MarketContractType.fxhashV3:
        return this.cancelAskFxhash(ask);
      case MarketContractType.v1:
        return this.cancelAskV1(ask);
      case MarketContractType.v4:
        return this.cancelAskV4(ask);
      case MarketContractType.typed:
        return this.cancelAskTyped(ask);
      case MarketContractType.bidou:
      case MarketContractType.bidou24:
      case MarketContractType.bidou24Mono:
        return this.cancelAsk8bidou(ask);
      case MarketContractType.emprops:
        return this.cancelAskEmprops(ask);
      case MarketContractType.akaswapV1:
      case MarketContractType.akaswapV2:
      case MarketContractType.akaswapV2_1:
        return this.cancelAskAkaswap(ask);
      case MarketContractType.dogami:
        return this.cancelAskDogami(ask);
      default:
        throw new Error('Contract version not supported');
    }
  }

  batchCancelAsk(asks: ObjktAskModel[]) {
    return forkJoin(
      asks.map(ask =>
        this.getMarketplaceContract(ask.marketplace_contract).pipe(
          map(marketplaceContract => ({
            kind: OpKind.TRANSACTION,
            ...this.getCancelAskTransaction(ask, marketplaceContract),
          })),
        ),
      ),
    ).pipe(
      mergeMap(batch =>
        this.walletService.tezos$.pipe(
          mergeMap(tezos =>
            tezos.wallet.batch(batch as WalletParamsWithKind[]).send(),
          ),
        ),
      ),
      tap(this.manageTransaction(TransactionType.BatchCancel)),
    );
  }

  batchCancelBid(bids: ObjktBidModel[]) {
    return forkJoin(
      bids.map(bid =>
        this.getMarketplaceContract(bid.marketplace_contract).pipe(
          map(marketplaceContract => ({
            kind: OpKind.TRANSACTION,
            ...this.getCancelBidTransaction(bid, marketplaceContract),
          })),
        ),
      ),
    ).pipe(
      mergeMap(batch =>
        this.walletService.tezos$.pipe(
          mergeMap(tezos =>
            tezos.wallet.batch(batch as WalletParamsWithKind[]).send(),
          ),
        ),
      ),
      tap(this.manageTransaction(TransactionType.BatchCancel)),
    );
  }

  batchTransfer(
    objkts: (ObjktModel & {amount: number; address: string})[],
    senderAddress: string,
  ) {
    return from(objkts).pipe(
      mergeMap((objkt: ObjktModel & {amount: number; address: string}) =>
        this.walletService
          .getContractAt(objkt.fa_contract)
          .pipe(map(fa2Contract => ({objkt, fa2Contract}))),
      ),
      map(({objkt, fa2Contract}) => {
        const annotations = this.getTransferAnnotations(fa2Contract);
        return {
          kind: OpKind.TRANSACTION,
          ...fa2Contract.methods
            .transfer([
              {
                [annotations.from]: senderAddress,
                [annotations.txs]: [
                  {
                    [annotations.to]: objkt.address,
                    [annotations.tokenId]: objkt.token_id,
                    [annotations.amount]: objkt.amount,
                  },
                ],
              },
            ])
            .toTransferParams({storageLimit: this._storageLimit}),
        };
      }),
      toArray(),
      mergeMap(batch =>
        this.walletService.tezos$.pipe(
          mergeMap(tezos =>
            tezos.wallet.batch(batch as WalletParamsWithKind[]).send(),
          ),
        ),
      ),
      tap(this.manageTransaction(TransactionType.BatchTransfer)),
    );
  }

  reswapAsk(
    ask: ObjktAskModel,
    objkt: ObjktModel,
    mutez: number,
    ownerAddress: string,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getMarketplaceContract(ask.marketplace_contract),
      this.getLatestMarketplaceContract(),
    ]).pipe(
      mergeMap(
        ([fa2Contract, marketplaceContract, latestMarketplaceContract]) => {
          const batch = [
            {
              kind: OpKind.TRANSACTION,
              ...this.getCancelAskTransaction(ask, marketplaceContract),
            },
            this.getLatestAskCall(
              objkt,
              ask.amount,
              mutez,
              ownerAddress,
              latestMarketplaceContract,
            ),
          ];

          return this.addFa2Operator(
            fa2Contract,
            ownerAddress,
            [
              ...new Set([
                marketplaceContract.address,
                latestMarketplaceContract.address,
              ]),
            ],
            objkt,
            batch,
          );
        },
      ),
      tap(this.manageTransaction(TransactionType.Reswap)),
    );
  }

  bid(objkt: ObjktModel, amount: number, expiryTime: string = null) {
    return this.getLatestMarketplaceContract().pipe(
      mergeMap(contract => {
        return contract.methodsObject
          .offer({
            token: {
              address: objkt.fa_contract,
              token_id: objkt.token_id,
            },
            currency: {tez: {}}, // only tez for now
            amount,
            shares: objkt.royalties.map(this.getShareFromRoyalty),
            expiry_time: expiryTime,
            target: null,
            proxy: null,
          })
          .send({
            amount,
            mutez: true,
            storageLimit: this.getStorageWithShare(objkt.royalties),
          });
      }),
      tap(this.manageTransaction(TransactionType.Offer, objkt)),
    );
  }

  floorBid(objkt: ObjktModel, amount: number, expiryTime: string = null) {
    return this.getLatestMarketplaceContract().pipe(
      mergeMap(contract => {
        return contract.methodsObject
          .offer({
            token: {
              address: objkt.fa_contract,
            },
            currency: {tez: {}}, // only tez for now
            amount,
            shares: objkt.royalties.map(this.getShareFromRoyalty),
            expiry_time: expiryTime,
            target: null,
            proxy: null,
          })
          .send({
            amount,
            mutez: true,
            storageLimit: this.getStorageWithShare(objkt.royalties),
          });
      }),
      tap(this.manageTransaction(TransactionType.FloorOffer, objkt)),
    );
  }

  listOpenEdition(
    objkt: ObjktModel,
    price: number,
    expirationDate: Date,
    userAddress: string,
    maxPerWallet: number,
    startDate: Date,
    shares?: any[],
  ) {
    const listingShares = (shares || objkt.royalties).map(
      this.getShareFromRoyalty,
    );
    const total = listingShares.reduce((acc, val) => acc + val.amount, 0);
    const objktShare = environment.openEditionFee;
    const missing = 10000 - total - objktShare;

    if (missing > 0) {
      listingShares.push({recipient: userAddress, amount: missing});
    }

    const royaltyMap = {};

    listingShares.forEach(share => {
      royaltyMap[share.recipient] =
        (royaltyMap[share.recipient] || 0) + share.amount;
    });

    // add objkt.com fee
    royaltyMap[environment.objktTreasury] = objktShare;

    const michelsonRoyaltyMap = MichelsonMap.fromLiteral(royaltyMap);

    return this.walletService.getContractAt(objkt.fa_contract).pipe(
      switchMap(contract =>
        contract.methodsObject
          .create_claim({
            airdrop_capacity: 0,
            split: {
              total: 10000,
              shares: michelsonRoyaltyMap,
            },
            end_time: expirationDate,
            start_time: startDate,
            token_id: objkt.token_id,
            max_per_wallet: maxPerWallet,
            price,
            burn_recipe: [],
          })
          .send({
            storageLimit: this.getStorageWithShare(objkt.royalties),
          }),
      ),
      tap(this.manageTransaction(TransactionType.List, objkt)),
    );
  }

  ask(
    objkt: ObjktModel,
    amount: number,
    price: number,
    ownerAddress: string,
    expiryTime: string = null,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getLatestMarketplaceContract(),
    ]).pipe(
      mergeMap(([fa2Contract, marketplaceContract]) => {
        const askCall = this.getLatestAskCall(
          objkt,
          amount,
          price,
          ownerAddress,
          marketplaceContract,
          expiryTime,
        );
        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [marketplaceContract.address],
          objkt,
          [askCall],
        );
      }),
      tap(this.manageTransaction(TransactionType.List, objkt)),
    );
  }

  batchAsk(
    objkts: (ObjktModel & {amount: number; price: number})[],
    ownerAddress: string,
  ) {
    return forkJoin(
      objkts.map(objkt =>
        this.walletService.getContractAt(objkt.fa_contract).pipe(
          map(fa2Contract => ({
            fa2Contract,
            objkt,
          })),
        ),
      ),
    ).pipe(
      mergeMap(transactionData =>
        this.getLatestMarketplaceContract().pipe(
          map(marketplaceContract => ({
            transactionData,
            marketplaceContract,
          })),
        ),
      ),
      mergeMap(({transactionData, marketplaceContract}) => {
        const operatorData = transactionData.map(t => ({
          contract: marketplaceContract.address,
          token_pk: t.objkt.pk,
        }));

        return this.operatorsService
          .getOperatorsGql(ownerAddress, operatorData)
          .pipe(
            map(operators => ({
              transactionData,
              marketplaceContract,
              operators,
            })),
          );
      }),
      map(({transactionData, marketplaceContract, operators}) =>
        transactionData
          .map(t => {
            const transactions = [];
            if (!operators.find(o => o.token_pk === t.objkt.pk)) {
              transactions.push(
                this.getAddOperator(
                  t.fa2Contract,
                  ownerAddress,
                  marketplaceContract.address,
                  t.objkt.token_id,
                ),
              );
            }
            transactions.push({
              kind: OpKind.TRANSACTION,
              ...this.getLatestAskCall(
                t.objkt,
                t.objkt.amount,
                t.objkt.price,
                ownerAddress,
                marketplaceContract,
              ),
            });

            return transactions;
          })
          .flat(),
      ),
      mergeMap(batch =>
        this.walletService.tezos$.pipe(
          mergeMap(tezos => tezos.wallet.batch(batch).send()),
        ),
      ),
      tap(this.manageTransaction(TransactionType.BatchAsk)),
    );
  }

  acceptOffer(
    objkt: ObjktModel,
    bid: ObjktBidModel,
    ownerAddress: string,
    keepOperator: boolean,
  ) {
    switch (bid.marketContractType) {
      case MarketContractType.v1:
        return this.acceptOfferV1(objkt, bid, ownerAddress);
      case MarketContractType.v4:
        return this.acceptOfferV4(objkt, bid, ownerAddress, keepOperator);
      case MarketContractType.versum:
        return this.acceptOfferVersum(objkt, bid, ownerAddress, keepOperator);
      case MarketContractType.fxhash:
      case MarketContractType.fxhashV3:
        return this.acceptOfferFxhash(objkt, bid, ownerAddress, keepOperator);
      case MarketContractType.akaswapOffer:
        return this.acceptOfferAkaswap(objkt, bid, ownerAddress, keepOperator);
      default:
        throw new Error('Contract version not supported');
    }
  }

  acceptBids(
    objkt: ObjktModel,
    bids: ObjktBidModel[],
    ownerAddress: string,
    keepOperator: boolean,
  ) {
    return forkJoin({
      batch: forkJoin(
        bids.map(bid =>
          this.getMarketplaceContract(bid.marketplace_contract).pipe(
            map(marketplaceContract => ({
              contract: marketplaceContract.address,
              transaction: {
                kind: OpKind.TRANSACTION,
                ...this.getAcceptBidTransaction(bid, marketplaceContract),
              },
            })),
          ),
        ),
      ),
      fa2Contract: this.walletService.getContractAt(objkt.fa.contract),
    }).pipe(
      mergeMap(result =>
        this.addFa2Operator(
          result.fa2Contract,
          ownerAddress,
          result.batch.map(b => b.contract),
          objkt,
          result.batch.map(b => b.transaction),
          keepOperator ? false : true,
        ),
      ),
      tap(this.manageTransaction(TransactionType.BatchAccept)),
    );
  }

  acceptOpenEdition(
    objkt: ObjktDetailsModel,
    price: number,
    amount: number = 1,
  ) {
    return this.walletService.getContractAt(objkt.fa_contract).pipe(
      switchMap(contract =>
        contract.methodsObject
          .claim({
            token_id: objkt.token_id,
            amount,
            burn_tokens: [],
            proxy_for: null,
          })
          .send({
            amount: price * amount,
            mutez: true,
            storageLimit: this._storageLimit,
          }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAsk(
    ask: ObjktAskModel,
    proxyAddress?: string,
    galleryId?: string,
    userAddress?: string,
  ) {
    switch (ask.marketContractType) {
      case MarketContractType.hen:
        return this.acceptAskHen(ask);
      case MarketContractType.teia:
      case MarketContractType.scribo:
        return this.acceptAskHen(ask);
      case MarketContractType.v1:
        return this.acceptAskV1(ask);
      case MarketContractType.v4:
        return this.acceptAskV4(ask, proxyAddress);
      case MarketContractType.versum:
        return this.acceptAskVersum(ask);
      case MarketContractType.fxhash:
      case MarketContractType.fxhashV3:
        return this.acceptAskFxhash(ask);
      case MarketContractType.typed:
        return this.acceptAskTyped(ask);
      case MarketContractType.bidou:
      case MarketContractType.bidou24:
      case MarketContractType.bidou24Mono:
        return this.acceptAsk8bidou(ask);
      case MarketContractType.emprops:
        return this.acceptAskEmprops(ask, galleryId);
      case MarketContractType.akaswapV1:
        return this.acceptAskAkaswap(ask);
      case MarketContractType.akaswapV2:
      case MarketContractType.akaswapV2_1:
        return this.acceptAskAkaswapV2(ask);
      case MarketContractType.dogami:
        return this.acceptAskDogami(ask, userAddress);
      default:
        throw new Error('Contract version not supported');
    }
  }

  acceptAskAkaswap(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methodsObject
          .collect({
            akaOBJ_amount: 1,
            swap_id: ask.bigmap_key,
          })
          .send({
            amount: ask.price,
            mutez: true,
            storageLimit: this._storageLimit,
          }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAskDogami(ask: ObjktAskModel, userAddress: string) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methodsObject
          .collect({
            swap_id: ask.bigmap_key,
            to_: userAddress,
            token_symbol: 'XTZ',
            amount_ft: ask.price,
          })
          .send({
            amount: ask.price,
            mutez: true,
            storageLimit: this._storageLimit,
          }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAskAkaswapV2(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methodsObject
          .collect({
            swap_id: ask.bigmap_key,
            token_amount: 1,
          })
          .send({
            amount: ask.price,
            mutez: true,
            storageLimit: this._storageLimit,
          }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAskEmprops(ask: ObjktAskModel, galleryId: string) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methods.create_sale(galleryId, ask.bigmap_key).send({
          amount: ask.price,
          mutez: true,
          storageLimit: this._storageLimit,
        }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAskV1(ask: ObjktAskModel) {
    return this.acceptAskV4(ask);
  }

  acceptAskV4(ask: ObjktAskModel, proxyAddress?: string) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methods.fulfill_ask(ask.bigmap_key, proxyAddress).send({
          amount: ask.price,
          mutez: true,
          storageLimit: this._storageLimit,
        }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAskVersum(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methods.collect_swap(1, ask.bigmap_key).send({
          amount: ask.price,
          mutez: true,
          storageLimit: this._storageLimit,
        }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAskFxhash(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract => {
        return this.acceptAskFxhashTransaction(ask, contract.methods);
      }),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  private acceptAskFxhashTransaction(ask, methods) {
    const listing_id = ask.bigmap_key;
    switch (ask.marketContractType) {
      // https://better-call.dev/mainnet/KT1GbyoDi7H1sfXmimXpptZJuCdHMh66WS9u/interact/listing_accept
      case MarketContractType.fxhash:
        return methods
          .listing_accept(listing_id)
          .send({
            amount: ask.price,
            mutez: true,
            storageLimit: this._storageLimit,
          });
      // https://better-call.dev/mainnet/KT1M1NyU9X4usEimt2f3kDaijZnDMNBu42Ja/interact/listing_accept
      case MarketContractType.fxhashV3:
        const referrerShare = 1000; // 1000 is the maximum allowed and it translates to 1%
        const objktReferrer = {address: environment.objktTreasury, pct: referrerShare};
        const referrers = [objktReferrer];
        const amount = 1;
        return methods
          .listing_accept(listing_id, amount, referrers)
          .send({
            amount: ask.price,
            mutez: true,
            storageLimit: this._storageLimit,
          });
      default:
        throw new Error(
          `Fxhash listing_accept version not supported: ${ask.marketContractType}`
        );
    }
  }

  acceptAskTyped(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methods.collect(ask.bigmap_key).send({
          amount: ask.price,
          mutez: true,
          storageLimit: this._storageLimit,
        }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  acceptAsk8bidou(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      switchMap(contract =>
        contract.methods.buy(ask.bigmap_key, 1, ask.price).send({
          amount: ask.price,
          mutez: true,
          storageLimit: this._storageLimit,
        }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  sendToken(
    receiverAddress: string,
    senderAddress: string,
    amount: number,
    tokenId: string,
    contract: FaModel,
    transactionType: TransactionType = TransactionType.Transfer,
  ) {
    return this.walletService.getContractAt(contract.contract).pipe(
      mergeMap(fa2Contract => {
        const annotations = this.getTransferAnnotations(fa2Contract);
        return fa2Contract.methods
          .transfer([
            {
              [annotations.from]: senderAddress,
              [annotations.txs]: [
                {
                  [annotations.to]: receiverAddress,
                  [annotations.tokenId]: tokenId,
                  [annotations.amount]: amount,
                },
              ],
            },
          ])
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(transactionType)),
    );
  }

  burnToken(
    senderAddress: string,
    amount: number,
    tokenId: string,
    contract: FaModel,
  ) {
    return this.sendToken(
      environment.burnWallets[0],
      senderAddress,
      amount,
      tokenId,
      contract,
      TransactionType.Burn,
    );
  }

  burnOeToken(tokenId: string, fa: FaModel, hasOpenEdition: boolean) {
    return this.walletService.getContractAt(fa.contract).pipe(
      switchMap(c => {
        const batch = [];
        const shares = MichelsonMap.fromLiteral({
          [environment.burnWallets[0]]: 1,
        });

        if (hasOpenEdition) {
          batch.push({
            kind: OpKind.TRANSACTION,
            ...c.methodsObject
              .create_claim({
                airdrop_capacity: 0,
                split: {
                  total: 1,
                  shares,
                },
                end_time: new Date(300000),
                start_time: new Date(0),
                token_id: tokenId,
                max_per_wallet: 0,
                price: 0,
                burn_recipe: [],
              })
              .toTransferParams(),
          });
        }

        batch.push({
          kind: OpKind.TRANSACTION,
          ...c.methodsObject
            .mint({
              mint_items: [
                {
                  to_: environment.burnWallets[0],
                  amount: 1,
                },
              ],
              token_id: tokenId,
            })
            .toTransferParams(),
        });
        batch.push({
          kind: OpKind.TRANSACTION,
          ...c.methodsObject
            .lock({
              token_id: tokenId,
              mint: true,
              metadata: false,
            })
            .toTransferParams(),
        });

        return this.walletService.tezos$.pipe(
          switchMap(tezos =>
            tezos.wallet.batch(batch as WalletParamsWithKind[]).send(),
          ),
        );
      }),
      tap(this.manageTransaction(TransactionType.Burn)),
    );
  }

  // AUCTIONS
  createDutchAuction(
    endPrice: number,
    endTime: Date,
    objkt: ObjktModel,
    startPrice: number,
    startTime: Date,
    ownerAddress: string,
    editions: number,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getLatestDutchAuctionContract(),
    ]).pipe(
      mergeMap(([fa2Contract, auctionContract]) => {
        const contractCall = {
          kind: OpKind.TRANSACTION,
          ...auctionContract.methodsObject
            .create_auction({
              token: {
                address: objkt.fa_contract,
                token_id: objkt.token_id,
              },
              currency: {tez: {}}, // only tez for now
              editions,
              start_time: startTime,
              end_time: endTime,
              start_price: startPrice,
              end_price: endPrice,
              shares: objkt.royalties.map(this.getShareFromRoyalty),
            })
            .toTransferParams({
              storageLimit: this.getStorageWithShare(objkt.royalties),
            }),
        };

        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [auctionContract.address],
          objkt,
          [contractCall],
        );
      }),
      tap(this.manageTransaction(TransactionType.CreateAuction)),
    );
  }

  createEnglishAuction(
    startTime: Date,
    endTime: Date,
    extensionTime: number,
    objkt: ObjktModel,
    priceIncrement: number,
    reserve: number,
    ownerAddress: string,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getLatestEnglishAuctionContract(),
    ]).pipe(
      mergeMap(([fa2Contract, auctionContract]) => {
        const contractCall = {
          kind: OpKind.TRANSACTION,
          ...auctionContract.methodsObject
            .create_auction({
              token: {
                address: objkt.fa_contract,
                token_id: objkt.token_id,
              },
              currency: {fa12: environment.wrappedTezContract}, // only wtez for now
              reserve,
              start_time: startTime,
              end_time: endTime,
              extension_time: extensionTime,
              price_increment: priceIncrement,
              shares: objkt.royalties.map(this.getShareFromRoyalty),
            })
            .toTransferParams({
              storageLimit: this.getStorageWithShare(objkt.royalties),
            }),
        };

        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [auctionContract.address],
          objkt,
          [contractCall],
        );
      }),
      tap(this.manageTransaction(TransactionType.CreateAuction)),
    );
  }

  concludeAuction(auction: GenericAuctionModel) {
    switch (auction.type) {
      case AuctionType.English:
        return this.concludeEnglish(auction);
      case AuctionType.Dutch:
        return this.concludeDutch(auction);
      default:
        throw new Error('Auction Type not supported');
    }
  }

  cancelAuction(auction: GenericAuctionModel) {
    switch (auction.type) {
      case AuctionType.English:
        return this.cancelEnglish(auction);
      case AuctionType.Dutch:
        return this.cancelDutch(auction);
      default:
        throw new Error('Auction Type not supported');
    }
  }

  placeAuctionBid(
    type: AuctionType,
    amount: number,
    auctionId: number,
    userAddress?: string,
    currency?: CurrencyBalance,
  ): Observable<any> {
    switch (type) {
      case AuctionType.English:
        return this.bidEnglish(auctionId, amount, userAddress, currency);
      case AuctionType.Dutch:
        return this.bidDutch(auctionId, amount);
      default:
        throw new Error('Auction Type not supported');
    }
  }

  /*
  We're retrieving the auction data from the RPC node and can't rely on any backend data (in case the backend is down)

  auctionId:

  v1 < 10000
  v2 >= 10000 && < 1000000
  v4 >= 1000000
*/
  getAuctionMap(auctionType: AuctionType, auctionId: string): Observable<any> {
    if (auctionType === AuctionType.English) {
      return this.getEnglishAuctionMap(auctionId);
    } else if (auctionType === AuctionType.Dutch) {
      return this.getDutchAuctionMap(auctionId);
    }
  }

  getEnglishAuctionMap(auctionId: string): Observable<EnglishAuction> {
    let auctionContract: Observable<ContractAbstraction<Wallet>>;

    if (parseInt(auctionId) < 10_000) {
      auctionContract = this.getEnglishAuctionContract(MarketContractType.v1);
    } else if (parseInt(auctionId) < 1_000_000) {
      auctionContract = this.getEnglishAuctionContract(MarketContractType.v2);
    } else {
      auctionContract = this.getEnglishAuctionContract(MarketContractType.v4);
    }

    return auctionContract.pipe(
      mergeMap(contract => {
        return contract
          .storage()
          .then((storage: any) => storage.auctions.get(auctionId)); // Todo: Remove need for `any`
      }),
    );
  }

  getDutchAuctionMap(auctionId: string): Observable<DutchAuction> {
    let auctionContract: Observable<ContractAbstraction<Wallet>>;

    if (parseInt(auctionId) < 10_000) {
      auctionContract = this.getDutchAuctionContract(MarketContractType.v1);
    } else if (parseInt(auctionId) < 1_000_000) {
      auctionContract = this.getDutchAuctionContract(MarketContractType.v2);
    } else {
      auctionContract = this.getDutchAuctionContract(MarketContractType.v4);
    }

    return auctionContract.pipe(
      mergeMap(contract => {
        return contract
          .storage()
          .then((storage: any) => storage.auctions.get(auctionId)); // Todo: Remove need for `any`
      }),
    );
  }

  mintOpenEdition(
    contract: string,
    metadataHash: string,
    address: string,
    editions: number,
    tokenId: string,
  ) {
    const metadataMap = MichelsonMap.fromLiteral({
      '': metadataHash,
    });
    return this.walletService.getContractAt(contract).pipe(
      switchMap(contract => {
        const createToken = {
          kind: OpKind.TRANSACTION,
          ...contract.methodsObject
            .create_token(metadataMap)
            .toTransferParams(),
        };
        const batch = [createToken];

        if (editions && editions > 0) {
          batch.push({
            kind: OpKind.TRANSACTION,
            ...contract.methodsObject
              .mint({
                mint_items: [
                  {
                    to_: address,
                    amount: editions,
                  },
                ],
                token_id: tokenId,
              })
              .toTransferParams(),
          });
          batch.push({
            kind: OpKind.TRANSACTION,
            ...contract.methodsObject
              .lock({
                token_id: tokenId,
                mint: true,
                metadata: false,
              })
              .toTransferParams(),
          });
        }
        return this.walletService.tezos$.pipe(
          switchMap(tezos =>
            tezos.wallet.batch(batch as WalletParamsWithKind[]).send(),
          ),
        );
      }),
      tap(this.manageTransaction(TransactionType.Mint)),
    );
  }

  mint(
    collectionId: number,
    address: string,
    editions: number,
    metadataHash: string,
  ) {
    return this.walletService.getContractAt(environment.mintingContract).pipe(
      mergeMap(contract => {
        return contract.methods
          .mint_artist(collectionId, editions, metadataHash, address)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.Mint)),
    );
  }

  createOrUpdateCollection(
    metadataHash: string,
    collectionId: string,
    contract: null | string,
  ) {
    if (contract) {
      return this.updateOpenCollection(metadataHash, contract);
    } else if (collectionId) {
      return this.updateCollection(metadataHash, collectionId);
    } else {
      return this.createCollection(metadataHash);
    }
  }

  createOEContract(
    metadataHash: string,
    userAddress: string,
  ): Observable<OriginationWalletOperation<DefaultWalletType>> {
    const metadataMap = MichelsonMap.fromLiteral({
      '': metadataHash,
    });
    return this.walletService.tezos$.pipe(
      switchMap(tezos =>
        tezos.wallet
          .originate({
            code: openEditionV1ContractCode,
            storage: {
              last_token_id: 0,
              administrator: userAddress,
              metadata: metadataMap,
              claimed: new MichelsonMap(),
              claims: new MichelsonMap(),
              ledger: new MichelsonMap(),
              locked: new MichelsonMap(),
              managers: new MichelsonMap(),
              operators: new MichelsonMap(),
              supply: new MichelsonMap(),
              token_metadata: new MichelsonMap(),
            },
          })
          .send(),
      ),
      tap(this.manageOrigination(TransactionType.NewColletion)),
    );
  }

  createCollection(metadataHash: string) {
    return this.walletService.getContractAt(environment.mintingContract).pipe(
      mergeMap(contract => {
        return contract.methods.create_artist_collection(metadataHash).send();
      }),
      tap(this.manageTransaction(TransactionType.NewColletion)),
    );
  }

  updateCollection(metadataHash: string, collectionId: string) {
    return this.walletService.getContractAt(environment.mintingContract).pipe(
      mergeMap(contract => {
        return contract.methods
          .update_artist_metadata(collectionId, metadataHash)
          .send();
      }),
      tap(this.manageTransaction(TransactionType.UpdateCollection)),
    );
  }

  updateOpenCollection(metadataHash: string, contract: string) {
    const metadataMap = MichelsonMap.fromLiteral({
      '': metadataHash,
    });

    return this.walletService.getContractAt(contract).pipe(
      mergeMap(c => {
        return c.methodsObject.set_metadata(metadataMap).send();
      }),
      tap(this.manageTransaction(TransactionType.UpdateCollection)),
    );
  }

  inviteArtistToCollection(collectionId: number, address: string) {
    return this.walletService.getContractAt(environment.mintingContract).pipe(
      mergeMap(contract => {
        return contract.methods
          .invite_collaborator(address, collectionId)
          .send();
      }),
      tap(this.manageTransaction(TransactionType.InviteArtist)),
    );
  }

  acceptInvitationToCollection(collectionId: number) {
    return this.walletService.getContractAt(environment.mintingContract).pipe(
      mergeMap(contract => {
        return contract.methods.accept_invitation(collectionId).send();
      }),
      tap(this.manageTransaction(TransactionType.AcceptInvitation)),
    );
  }

  removeArtistFromCollection(collectionId: number, address: string) {
    return this.walletService.getContractAt(environment.mintingContract).pipe(
      mergeMap(contract => {
        return contract.methods
          .remove_collaborator(address, collectionId)
          .send();
      }),
      tap(this.manageTransaction(TransactionType.RemoveCollaborator)),
    );
  }

  checkUbisoftAllowed(
    userAddress: string,
    tokenId: string,
  ): Observable<UbisoftTransferResponseType> {
    return this.walletService.tezos$.pipe(
      mergeMap(tezos => {
        const url = tezos.rpc.getRpcUrl();

        return this.http
          .post<any>(
            `${url}/chains/main/blocks/head/helpers/scripts/run_view`,
            {
              chain_id: 'NetXdQprcVkpaWU',
              contract: 'KT1TnVQhjxeNvLutGvzwZvYtC7vKRpwPWhc6',
              entrypoint: 'can_transfer',
              gas: '100000',
              input: {
                prim: 'Pair',
                args: [
                  {
                    int: tokenId,
                  },
                  {
                    prim: 'Pair',
                    args: [
                      {
                        string: 'tz1VcBJbjACv7xQ6G1vRmmq14zK5rXF9APtr', // hardcoded address that has rights to send
                      },
                      {
                        string: userAddress,
                      },
                    ],
                  },
                ],
              },
              source: userAddress,
              unparsing_mode: 'Readable',
            },
          )
          .pipe(
            map(res => {
              const responseString = res?.data?.string;
              if (responseString === '') {
                return UbisoftTransferResponseType.Ok;
              }
              return responseString;
            }),
          );
      }),
      catchError(e => {
        return of(false);
      }),
    );
  }

  wrapXtz(amount: number, address: string) {
    return this.walletService
      .getContractAt(environment.wrappedTezContract)
      .pipe(
        mergeMap(contract =>
          contract.methods.wrap(address).send({
            amount,
            mutez: true,
            storageLimit: this._storageLimit,
          }),
        ),
        tap(this.manageTransaction(TransactionType.Wrap)),
      );
  }

  unwrapXtz(amount: number) {
    return this.walletService
      .getContractAt(environment.wrappedTezContract)
      .pipe(
        mergeMap(contract =>
          contract.methods
            .unwrap(amount)
            .send({storageLimit: this._storageLimit}),
        ),
        tap(this.manageTransaction(TransactionType.Unwrap)),
      );
  }

  private cancelBidV1(offer: ObjktBidModel) {
    return this.getMarketplaceContract(offer.marketplace_contract).pipe(
      mergeMap(contract => {
        return contract.methods
          .retract_bid(offer.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelOffer)),
    );
  }

  private cancelBidV4(offer: ObjktBidModel) {
    return this.getMarketplaceContract(offer.marketplace_contract).pipe(
      mergeMap(contract => {
        return contract.methods
          .retract_offer(offer.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelOffer)),
    );
  }

  private cancelBidVersum(offer: ObjktBidModel) {
    return this.getMarketplaceContract(offer.marketplace_contract).pipe(
      mergeMap(contract => {
        return contract.methods
          .cancel_offer(offer.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelOffer)),
    );
  }

  private cancelBidAkaswap(offer: ObjktBidModel) {
    return this.getMarketplaceContract(offer.marketplace_contract).pipe(
      mergeMap(contract => {
        return contract.methods
          .cancel_offer(offer.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelOffer)),
    );
  }

  private cancelBidFxhash(offer: ObjktBidModel) {
    return this.getMarketplaceContract(offer.marketplace_contract).pipe(
      mergeMap(contract => {
        // https://better-call.dev/mainnet/KT1GbyoDi7H1sfXmimXpptZJuCdHMh66WS9u/interact/offer_cancel
        // https://better-call.dev/mainnet/KT1M1NyU9X4usEimt2f3kDaijZnDMNBu42Ja/interact/offer_cancel
        return contract.methods
          .offer_cancel(offer.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelOffer)),
    );
  }

  private cancelAskDogami(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .removeFromMarketplace(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskAkaswap(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .cancel_swap(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskEmprops(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .unlist_token(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskVersum(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .cancel_swap(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskFxhash(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .listing_cancel(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskHen(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .cancel_swap(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskV1(ask: ObjktAskModel) {
    return this.cancelAskV4(ask);
  }

  private cancelAskV4(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .retract_ask(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAskTyped(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .cancel_swap(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private cancelAsk8bidou(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods
          .cancelswap(ask.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.CancelListing)),
    );
  }

  private getCancelAskTransaction(
    ask: ObjktAskModel,
    marketplaceContract: ContractAbstraction<Wallet>,
  ) {
    switch (this.marketplaceType.get(ask.marketplace_contract)) {
      case MarketContractType.hen:
      case MarketContractType.teia:
      case MarketContractType.scribo:
      case MarketContractType.versum:
        return marketplaceContract.methods
          .cancel_swap(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.fxhash:
        return marketplaceContract.methods
          .listing_cancel(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.v1:
      case MarketContractType.v4:
        return marketplaceContract.methods
          .retract_ask(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.typed:
        return marketplaceContract.methods
          .cancel_swap(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.bidou:
      case MarketContractType.bidou24:
      case MarketContractType.bidou24Mono:
        return marketplaceContract.methods
          .cancelswap(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.emprops:
        return marketplaceContract.methods
          .unlist_token(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.akaswapV1:
      case MarketContractType.akaswapV2:
      case MarketContractType.akaswapV2_1:
        return marketplaceContract.methods
          .cancel_swap(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.dogami:
        return marketplaceContract.methods
          .removeFromMarketplace(ask.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      default:
        throw new Error('Contract version not supported');
    }
  }

  private getCancelBidTransaction(
    bid: ObjktBidModel,
    marketplaceContract: ContractAbstraction<Wallet>,
  ) {
    switch (this.marketplaceType.get(bid.marketplace_contract)) {
      case MarketContractType.hen:
        throw new Error('Contract version not supported');
      case MarketContractType.versum:
        return marketplaceContract.methods
          .cancel_offer(bid.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.fxhash:
        return marketplaceContract.methods
          .offer_cancel(bid.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.v1:
        return marketplaceContract.methods
          .retract_bid(bid.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.v4:
        return marketplaceContract.methods
          .retract_offer(bid.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.akaswapOffer:
        return marketplaceContract.methods
          .cancel_offer(bid.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      default:
        throw new Error('Contract version not supported');
    }
  }

  private getLatestAskCall(
    objkt: ObjktModel,
    amount: number,
    price: number,
    ownerAddress: string,
    marketplaceContract: ContractAbstraction<Wallet>,
    expiryTime: string = null,
  ) {
    return {
      kind: OpKind.TRANSACTION,
      ...marketplaceContract.methodsObject
        .ask({
          token: {
            address: objkt.fa_contract,
            token_id: objkt.token_id,
          },
          currency: {
            tez: {}, // only tez for now
          },
          amount: price,
          editions: amount, // only 1 edition to swap for now
          shares: objkt.royalties.map(this.getShareFromRoyalty),
          expiry_time: expiryTime,
          target: null,
        })
        .toTransferParams({
          storageLimit: this.getStorageWithShare(objkt.royalties),
        }),
    };
  }

  private acceptOfferV1(
    objkt: ObjktModel,
    bid: ObjktBidModel,
    ownerAddress: string,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getMarketplaceContract(bid.marketplace_contract),
    ]).pipe(
      mergeMap(([fa2Contract, marketplaceContract]) => {
        const acceptBid = {
          kind: OpKind.TRANSACTION,
          ...marketplaceContract.methods
            .fulfill_bid(bid.bigmap_key)
            .toTransferParams({storageLimit: this._storageLimit}),
        };
        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [marketplaceContract.address],
          objkt,
          [acceptBid],
        );
      }),
      tap(this.manageTransaction(TransactionType.Sell, objkt)),
    );
  }

  private acceptOfferV4(
    objkt: ObjktModel,
    bid: ObjktBidModel,
    ownerAddress: string,
    keepOperator: boolean,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getMarketplaceContract(bid.marketplace_contract),
    ]).pipe(
      mergeMap(([fa2Contract, marketplaceContract]) => {
        const acceptBid = {
          kind: OpKind.TRANSACTION,
          ...marketplaceContract.methods
            .fulfill_offer(bid.bigmap_key, objkt.token_id)
            .toTransferParams({storageLimit: this._storageLimit}),
        };
        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [marketplaceContract.address],
          objkt,
          [acceptBid],
          keepOperator ? false : true,
        );
      }),
      tap(this.manageTransaction(TransactionType.Sell, objkt)),
    );
  }

  private acceptOfferVersum(
    objkt: ObjktModel,
    bid: ObjktBidModel,
    ownerAddress: string,
    keepOperator: boolean,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getMarketplaceContract(bid.marketplace_contract),
    ]).pipe(
      mergeMap(([fa2Contract, marketplaceContract]) => {
        const acceptBid = {
          kind: OpKind.TRANSACTION,
          ...marketplaceContract.methods
            .accept_offer(bid.bigmap_key)
            .toTransferParams({storageLimit: this._storageLimit}),
        };
        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [marketplaceContract.address],
          objkt,
          [acceptBid],
          keepOperator ? false : true,
        );
      }),
      tap(this.manageTransaction(TransactionType.Sell, objkt)),
    );
  }

  private acceptOfferAkaswap(
    objkt: ObjktModel,
    bid: ObjktBidModel,
    ownerAddress: string,
    keepOperator: boolean,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getMarketplaceContract(bid.marketplace_contract),
    ]).pipe(
      mergeMap(([fa2Contract, marketplaceContract]) => {
        const acceptBid = {
          kind: OpKind.TRANSACTION,
          ...marketplaceContract.methods
            .fulfill_offer(bid.bigmap_key)
            .toTransferParams({storageLimit: this._storageLimit}),
        };
        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [marketplaceContract.address],
          objkt,
          [acceptBid],
          keepOperator ? false : true,
        );
      }),
      tap(this.manageTransaction(TransactionType.Sell, objkt)),
    );
  }

  private acceptOfferFxhash(
    objkt: ObjktModel,
    bid: ObjktBidModel,
    ownerAddress: string,
    keepOperator: boolean,
  ) {
    return combineLatest([
      this.walletService.getContractAt(objkt.fa.contract),
      this.getMarketplaceContract(bid.marketplace_contract),
    ]).pipe(
      mergeMap(([fa2Contract, marketplaceContract]) => {
        const acceptBid = {
          kind: OpKind.TRANSACTION,
          ...this.acceptOfferFxhashTransaction(
            bid, marketplaceContract.methods, objkt.token_id
          ),
        }

        return this.addFa2Operator(
          fa2Contract,
          ownerAddress,
          [marketplaceContract.address],
          objkt,
          [acceptBid],
          keepOperator ? false : true,
        );
      }),
      tap(this.manageTransaction(TransactionType.Sell, objkt)),
    );
  }

  private acceptOfferFxhashTransaction(bid, methods, token_id) {
    switch (bid.marketContractType) {
      // https://better-call.dev/mainnet/KT1GbyoDi7H1sfXmimXpptZJuCdHMh66WS9u/interact/offer_accept
      case MarketContractType.fxhash:
        const offer_accept = bid.bigmap_key;
        return methods
          .offer_accept(offer_accept)
          .toTransferParams({storageLimit: this._storageLimit});
      // https://better-call.dev/mainnet/KT1M1NyU9X4usEimt2f3kDaijZnDMNBu42Ja/interact/offer_accept
      case MarketContractType.fxhashV3:
        const asset_id = token_id;
        const offer_id = bid.bigmap_key;
        const referrerShare = 1000; // 1000 is the maximum allowed and it translates to 1%
        const objktReferrer = {address: environment.objktTreasury, pct: referrerShare};
        const referrers = [objktReferrer];
        return methods
          .offer_accept(asset_id, offer_id, referrers)
          .toTransferParams({storageLimit: this._storageLimit});
      default:
        throw new Error(
          `Fxhash offer_accept version not supported: ${bid.marketContractType}`
        );
    }
  }

  private getAcceptBidTransaction(
    bid: ObjktBidModel,
    marketplaceContract: ContractAbstraction<Wallet>,
    token_id?: number,
  ) {
    switch (bid.marketContractType) {
      case MarketContractType.v1:
        return marketplaceContract.methods
          .fulfill_bid(bid.bigmap_key)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      case MarketContractType.v4:
        return marketplaceContract.methods
          .fulfill_offer(bid.bigmap_key, token_id)
          .toTransferParams({
            amount: 0,
            storageLimit: this._storageLimit,
          });
      default:
        throw new Error('Contract version not supported');
    }
  }

  private acceptAskHen(ask: ObjktAskModel) {
    return this.getMarketplaceContract(ask.marketplace_contract).pipe(
      mergeMap(contract =>
        contract.methods.collect(ask.bigmap_key).send({
          amount: ask.price,
          mutez: true,
          storageLimit: this._storageLimit,
        }),
      ),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  private concludeEnglish(auction: GenericAuctionModel) {
    switch (auction.marketContractType) {
      case MarketContractType.v2:
        return this.concludeEnglishV2(auction);
      case MarketContractType.v4:
        return this.concludeEnglishV4(auction);
      default:
        throw new Error('Contract type not supported');
    }
  }

  private concludeEnglishV2(auction: GenericAuctionModel) {
    return this.getEnglishAuctionContract(auction.marketContractType).pipe(
      mergeMap(contract =>
        contract.methods
          .conclude_auction(auction.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.ConcludeAuction)),
    );
  }

  private concludeEnglishV4(auction: GenericAuctionModel) {
    return this.getEnglishAuctionContract(auction.marketContractType).pipe(
      mergeMap(contract =>
        contract.methods
          .settle_auction(auction.bigmap_key)
          .send({storageLimit: this._storageLimit}),
      ),
      tap(this.manageTransaction(TransactionType.ConcludeAuction)),
    );
  }

  private concludeDutch(auction: GenericAuctionModel) {
    switch (auction.marketContractType) {
      case MarketContractType.v2:
        return this.concludeDutchV2(auction);
      case MarketContractType.v4:
        throw new Error('Dutch Contract type 2 does not support concluding');
      default:
        throw new Error('Contract type not supported');
    }
  }

  private concludeDutchV2(auction: GenericAuctionModel) {
    return this.getDutchAuctionContract(auction.marketContractType).pipe(
      mergeMap(contract => {
        return contract.methods
          .conclude_auction(auction.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.ConcludeAuction)),
    );
  }

  private cancelEnglish(auction: GenericAuctionModel) {
    switch (auction.marketContractType) {
      case MarketContractType.v2:
        return this.cancelEnglishV2(auction);
      case MarketContractType.v4:
        return this.cancelEnglishV4(auction);
      default:
        throw new Error('Contract type not supported');
    }
  }

  private cancelEnglishV2(auction: GenericAuctionModel) {
    return this.cancelEnglishV4(auction);
  }

  private cancelEnglishV4(auction: GenericAuctionModel) {
    return this.getEnglishAuctionContract(auction.marketContractType).pipe(
      mergeMap(contract => {
        return contract.methods
          .cancel_auction(auction.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelAuction)),
    );
  }

  private cancelDutch(auction: GenericAuctionModel) {
    switch (auction.marketContractType) {
      case MarketContractType.v2:
        return this.cancelDutchV2(auction);
      case MarketContractType.v4:
        return this.cancelDutchV4(auction);
      default:
        throw new Error('Contract type not supported');
    }
  }

  private cancelDutchV2(auction: GenericAuctionModel) {
    return this.cancelDutchV4(auction);
  }

  private cancelDutchV4(auction: GenericAuctionModel) {
    return this.getDutchAuctionContract(auction.marketContractType).pipe(
      mergeMap(contract => {
        return contract.methods
          .cancel_auction(auction.bigmap_key)
          .send({storageLimit: this._storageLimit});
      }),
      tap(this.manageTransaction(TransactionType.CancelAuction)),
    );
  }

  private bidEnglish(
    auctionId: number,
    amount: number,
    userAddress?: string,
    currency?: CurrencyBalance,
  ) {
    if (auctionId < 1_000_000) {
      return this.bidEnglishV2(auctionId, amount);
    } else {
      return this.bidEnglishV4(auctionId, amount, userAddress, currency);
    }
  }

  private bidEnglishV2(auctionId: number, amount: number) {
    return this.getEnglishAuctionContract(MarketContractType.v2).pipe(
      mergeMap(contract => {
        return contract.methods.bid(auctionId).send({
          amount,
          mutez: true,
          storageLimit: this._storageLimit,
        });
      }),
      tap(this.manageTransaction(TransactionType.Bid)),
    );
  }

  private bidEnglishV4(
    auctionId: number,
    amount: number,
    userAddress: string,
    currency: CurrencyBalance,
  ) {
    const isWrappedTez = currency.contract === environment.wrappedTezContract;

    return forkJoin({
      auctionContract: this.getEnglishAuctionContract(MarketContractType.v4),
      faContract: this.walletService.getContractAt(currency.contract), // currently only fa1.2
      wrappedTezcontract: this.walletService.getContractAt(
        environment.wrappedTezContract,
      ),
    }).pipe(
      mergeMap(({auctionContract, faContract, wrappedTezcontract}) => {
        const calls = [];

        // we wrap tez automatically if the currency is oXTZ
        if (isWrappedTez && amount > currency.balance) {
          const remaining = amount - currency.balance;
          const wrapTezCall = {
            kind: OpKind.TRANSACTION,
            ...wrappedTezcontract.methods
              .wrap(userAddress)
              .toTransferParams({amount: remaining, mutez: true}),
          };
          calls.push(wrapTezCall);
        }

        const bidCall = {
          kind: OpKind.TRANSACTION,
          ...auctionContract.methods.bid(auctionId, amount).toTransferParams(),
        };

        calls.push(bidCall);

        return this.addAllowanceFa12(
          faContract,
          amount,
          currency.allowance,
          auctionContract.address,
          calls,
        );
      }),
      tap(this.manageTransaction(TransactionType.Bid)),
    );
  }

  private bidDutch(auctionId: number, amount: number) {
    if (auctionId < 1_000_000) {
      return this.bidDutchV2(auctionId, amount);
    } else {
      return this.bidDutchV4(auctionId, amount);
    }
  }

  private bidDutchV2(auctionId: number, amount: number) {
    return this.getDutchAuctionContract(MarketContractType.v2).pipe(
      mergeMap(contract => {
        return contract.methods.buy(auctionId).send({
          amount,
          mutez: true,
          storageLimit: this._storageLimit,
        });
      }),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  private bidDutchV4(auctionId: number, amount: number, address?: string) {
    return this.getDutchAuctionContract(MarketContractType.v4).pipe(
      mergeMap(contract => {
        return contract.methods.buy(auctionId, amount, address).send({
          amount,
          mutez: true,
          storageLimit: this._storageLimit,
        });
      }),
      tap(this.manageTransaction(TransactionType.Purchase)),
    );
  }

  private isOldContract(objectId: number) {
    return objectId < 1e6;
  }

  private addFa2Operator(
    fa2Contract,
    ownerAddress,
    contracts: string[],
    token,
    contractCalls,
    removeOperator = false,
  ) {
    return this.operatorsService
      .getOperatorsGql(
        ownerAddress,
        contracts.map(contract => ({
          contract,
          token_pk: token.pk,
        })),
      )
      .pipe(
        mergeMap(operators => {
          const addOperators = !operators.length
            ? contracts.map(contract =>
                this.getAddOperator(
                  fa2Contract,
                  ownerAddress,
                  contract,
                  token.token_id,
                ),
              )
            : [];

          const removeOperators = contracts
            .filter(
              contract =>
                this.marketplaceType.get(contract) === MarketContractType.v1 ||
                this.marketplaceType.get(contract) === MarketContractType.v2 ||
                removeOperator,
            )
            .map(contract =>
              this.getRemoveOperator(
                fa2Contract,
                ownerAddress,
                contract,
                token,
              ),
            );

          const batch = [...addOperators, ...contractCalls, ...removeOperators];

          return this.walletService.tezos$.pipe(
            mergeMap(tezos => {
              return tezos.wallet.batch(batch).send();
            }),
          );
        }),
      );
  }

  private getRemoveOperator(fa2Contract, ownerAddress, contractAddress, token) {
    let annotation = 'remove_operator';
    try {
      const noAnnotations =
        !fa2Contract.entrypoints.entrypoints.update_operators.args[0].args[0]
          .annots;
      annotation = noAnnotations ? '1' : 'remove_operator';
    } catch {}

    return {
      kind: OpKind.TRANSACTION,
      ...fa2Contract.methods
        .update_operators([
          {
            [annotation]: {
              owner: ownerAddress,
              operator: contractAddress,
              token_id: token.token_id,
            },
          },
        ])
        .toTransferParams({storageLimit: this._storageLimit}),
    };
  }

  private getAddOperator(fa2Contract, ownerAddress, contractAddress, tokenId) {
    let annotation = 'add_operator';
    try {
      const noAnnotations =
        !fa2Contract.entrypoints.entrypoints.update_operators.args[0].args[0]
          .annots;
      annotation = noAnnotations ? '0' : 'add_operator';
    } catch {}
    return {
      kind: OpKind.TRANSACTION,
      ...fa2Contract.methods
        .update_operators([
          {
            [annotation]: {
              owner: ownerAddress,
              operator: contractAddress,
              token_id: tokenId,
            },
          },
        ])
        .toTransferParams({storageLimit: this._storageLimit}),
    };
  }

  private addAllowanceFa12(
    fa12Contract,
    amount: number,
    allowance: number,
    contract: string,
    contractCalls,
  ) {
    const resetAllowance = this.addAllowance(fa12Contract, 0, contract);
    const addAllowance = this.addAllowance(fa12Contract, amount, contract);

    let batch = [];

    if (allowance > 0) {
      batch.push(resetAllowance);
    }

    batch = [...batch, addAllowance, ...contractCalls];

    return this.walletService.tezos$.pipe(
      mergeMap(tezos => {
        return tezos.wallet.batch(batch).send();
      }),
    );
  }

  private addAllowance(fa12Contract, amount, contract) {
    return {
      kind: OpKind.TRANSACTION,
      ...fa12Contract.methodsObject
        .approve({
          spender: contract,
          value: amount,
        })
        .toTransferParams({storageLimit: this._storageLimit}),
    };
  }

  manageOrigination(type: TransactionType) {
    return (op: OriginationWalletOperation<DefaultWalletType>) => {
      this.transactionsService.addTransaction({
        hash: op.opHash,
        type,
        pending: true,
      });

      op.confirmation(1)
        .then(() => {
          this.transactionsService.addTransaction({
            hash: op.opHash,
            type,
            pending: false,
          });
        })
        .catch(() => {
          this.transactionsService.addTransaction({
            hash: op.opHash,
            type,
            pending: false,
          });
        });
    };
  }

  manageTransaction(type: TransactionType, token?: ObjktModel) {
    return (
      op: WalletOperation | OriginationWalletOperation<DefaultWalletType>,
    ) => {
      this.transactionsService.addTransaction({
        hash: op.opHash,
        type,
        pending: true,
        token,
      });

      op.confirmation(1)
        .then(() => {
          this.transactionsService.addTransaction({
            hash: op.opHash,
            type,
            pending: false,
            token,
          });
        })
        .catch(() => {
          this.transactionsService.addTransaction({
            hash: op.opHash,
            type,
            pending: false,
            token,
          });
        });
    };
  }

  private getShareFromRoyalty(royalty: IndexerRoyalties): {
    amount: number;
    recipient: string;
  } {
    const deltaDecimals = 4 - royalty.decimals;
    const amount = Math.round(royalty.amount * Math.pow(10, deltaDecimals));

    return {
      amount,
      recipient: royalty.receiver_address,
    };
  }

  private getStorageWithShare(shares: any[]) {
    return shares.length * this._storagePerShare + this._storageLimit;
  }
}
