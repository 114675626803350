import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable, of} from 'rxjs';
import {catchError, filter, first, map, mergeMap, switchMap, tap} from 'rxjs/operators';
import {UserSettingsModel} from 'src/app/types/types';
import {BackendGetCurrentUserGQL, BackendUpdateUserSettingsGQL} from 'src/graphql/gen/backend';
import {SigningService} from '../tezos/signing.service';

@Injectable({
  providedIn: 'root',
})
export class SettingsApiService {
  private localStorageKey = 'objkt-settings';
  private _settings$ = new BehaviorSubject<UserSettingsModel | null>(null);

  allNotificationTypes = [
    'accept_offer',
    'offer',
    'ask_purchase',
    'conclude_auction',
    'create_dutch_auction',
    'create_english_auction',
    'dutch_purchase',
    'english_bid',
    'english_auction_outbid',
    'bookmark_token_buyable',
    'list',
    'mint',
    'ignored_owned_tokens',
  ];

  constructor(
    private readonly getUser: BackendGetCurrentUserGQL,
    private readonly updateUserSettings: BackendUpdateUserSettingsGQL,
    private readonly signingService: SigningService,
  ) {
    this.signingService.isSigned$
      .pipe(
        filter(isSigned => isSigned !== null),
        switchMap(isSigned => this.getSettings(isSigned)),
        tap(settings => this.setLocalStorageSettings(settings)),
      )
      .subscribe(this._settings$);
  }

  get settings$(): Observable<UserSettingsModel | null> {
    return this._settings$.asObservable();
  }

  toggleShowAllNotifications(value: boolean) {
    return this._settings$.pipe(
      first(),
      switchMap(settings =>
        this.update({
          ...settings,
          notifications: {
            ...settings?.notifications,
            disable: !value,
            ignore_types: value ? [] : this.allNotificationTypes,
          },
        }),
      ),
    );
  }

  toggleShowNotificationTypes(types: string[], value: boolean) {
    return this.settings$.pipe(
      first(),
      switchMap(settings => {
        let ignoreTypes = settings?.notifications?.ignore_types || [];
        ignoreTypes = ignoreTypes.filter(t => !types.includes(t));
        if (!value) {
          ignoreTypes = [...ignoreTypes, ...types];
        }
        const allHidden = ignoreTypes.sort().join(',') === this.allNotificationTypes.sort().join(',');
        return this.update({
          ...settings,
          notifications: {
            ...settings?.notifications,
            disable: value && !allHidden ? false : allHidden || settings?.notifications?.disable,
            ignore_types: ignoreTypes,
          },
        });
      }),
    );
  }

  getSetting(key: string) {
    return this._settings$.pipe(
      filter(settings => settings !== null),
      first(),
      map(settings => settings?.[key]),
    );
  }

  updateSetting(key: string, value: any) {
    return this._settings$.pipe(
      first(),
      switchMap(settings =>
        this.update({
          ...settings,
          [key]: value,
        }),
      ),
    );
  }

  getHideDisclaimer(): Observable<boolean> {
    return this.getSetting('hide_disclaimer');
  }

  hideDisclaimer() {
    return this.updateSetting('hide_disclaimer', true);
  }

  getHideMature() {
    return this.getSetting('hide_mature') as Observable<boolean>;
  }

  toggleHideMature(value: boolean) {
    return this.updateSetting('hide_mature', value);
  }

  getHideFlashing() {
    return this.getSetting('hide_flashing') as Observable<boolean>;
  }

  toggleHideFlashing(value: boolean) {
    return this.updateSetting('hide_flashing', value);
  }

  getShowInteractiveWarning() {
    return this.getSetting('hide_interactive_warning');
  }

  toggleShowInteractiveWarning(value: boolean) {
    return this.updateSetting('hide_interactive_warning', !value);
  }

  getHideHaningWarning() {
    return this.getSetting('hide_hanging_warning');
  }

  toggleHideHangingWarning(value: boolean) {
    return this.updateSetting('hide_hanging_warning', !value);
  }

  private getSettings(isSigned): Observable<UserSettingsModel> {
    if (!isSigned) return of({});
    return this.getUser.fetch().pipe(
      map(res => res.data.user.settings),
      catchError(_ => this.getLocalStorageSettings()),
    );
  }

  private update(settings: UserSettingsModel) {
    return this.updateUserSettings.mutate({settings: JSON.stringify(settings)}).pipe(
      map(res => res.data?.user?.settings || null),
      tap(settings => this._settings$.next(settings)),
      catchError(_ => of(null)),
    );
  }

  private getLocalStorageSettings() {
    const localSettings = window.localStorage.getItem(this.localStorageKey);
    return localSettings ? JSON.parse(localSettings) : null;
  }

  private setLocalStorageSettings(settings: UserSettingsModel) {
    if (!settings) return;
    window.localStorage.setItem(this.localStorageKey, JSON.stringify(settings));
  }
}
