import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {catchError, map, mergeMap} from 'rxjs/operators';
import BigNumber from 'bignumber.js';
import {Currency, CurrencyBalance, CurrencyType} from '../../types/types';
import {environment} from '../../../environments/environment';
import {WalletService} from '../tezos/wallet.service';

@Injectable({
  providedIn: 'root',
})
export class BalanceService {
  constructor(private readonly walletService: WalletService) {}

  getBalance() {
    return forkJoin({
      address: this.walletService.userAddress(),
      tezos: this.walletService.tezos$,
    }).pipe(
      mergeMap(res => {
        if (res.address) {
          return res.tezos.tz.getBalance(res.address);
        } else {
          return of(new BigNumber(0));
        }
      }),
      map(balance => balance.toNumber()),
    );
  }

  getBalanceCurrency(currency: Currency) {
    switch (currency.type) {
      case CurrencyType.fa12:
        return this.getBalanceCurrencyFa12(currency);
      case CurrencyType.fa2:
        return this.getBalanceCurrencyFa12(currency); // TODO
    }
  }

  getBalancesUser() {
    return forkJoin(environment.currencies.map(currency => this.getBalanceCurrency(currency)));
  }

  private getBalanceCurrencyFa12(currency: Currency): Observable<CurrencyBalance> {
    return forkJoin({
      address: this.walletService.userAddress(),
      storage: this.walletService.getContractAt(currency.contract).pipe(mergeMap(contract => contract.storage())),
    }).pipe(
      mergeMap(({address, storage}) => storage[currency.path].get(address)),
      map((value: any) => ({...currency, balance: value?.balance?.toNumber() || 0, allowance: value?.allowance?.toNumber() || null})),
      catchError(_ => of({...currency, balance: 0})),
    );
  }
}
