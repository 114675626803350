import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-text-button',
  templateUrl: './text-button.component.html',
  styleUrls: ['../buttons.scss', './text-button.component.scss'],
})
export class TextButtonComponent implements OnInit {
  @Input() disabled: boolean;
  @Input() type: string;
  @Output() onClick = new EventEmitter();

  constructor() {}

  ngOnInit(): void {}
}
