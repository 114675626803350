import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopCollectionComponent} from './top-collection.component';
import {RouterModule} from '@angular/router';
import {CollectionImageModule} from '../collection-image/collection-image.module';
import {TezPipeModule} from '../../pipes/tez/tez-pipe.module';
import {TezModule} from '../tez/tez.module';
import {LocalisedNumberModule} from '../../pipes/localised-number/localised-number.module';
import {ShortAddressPipeModule} from '../../pipes/short-address/short-address-pipe.module';

@NgModule({
  declarations: [TopCollectionComponent],
  imports: [CommonModule, RouterModule, CollectionImageModule, TezPipeModule, TezModule, LocalisedNumberModule, ShortAddressPipeModule],
  exports: [TopCollectionComponent],
})
export class TopCollectionModule {}
