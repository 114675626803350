<div class="notification-panel">
  <div class="notification-panel-header">
    <h2 class="notification-panel-title">Latest Notifications</h2>
    <a *ngIf="userAddress" routerLink="profile/{{ userAddress }}/notifications" class="notifications-link">View All</a>
  </div>
  <div class="notifications-container">
    <ng-container *ngIf="signingService.isSigned$ | async; else login">
      <ng-container *ngIf="this.notificationService.unreadNotifications$ | async as unreadNotifications">
        <ng-container *ngIf="unreadNotifications.notifications.length; else noNotifications">
          <a *ngFor="let notification of unreadNotifications.notifications" routerLink="{{ getObjktModel(notification.activity.object.token) | objktLink }}">
            <div [class]="['notification-row', notification.unread ? 'notification-unread' : ''].join(' ')">
              <div *ngIf="notification.activity.object.token" class="notification-image-container">
                <app-objkt-preview [objkt]="getObjktModel(notification.activity.object.token)" class="objkt-preview"></app-objkt-preview>
              </div>
              <div class="notification-text-container">
                <h4 class="notification-title" *ngIf="notification.activity.object.token.title">{{ notification.activity.object.token.title }}</h4>
                <p class="notification-text">{{ notification.description }}</p>
              </div>
            </div>
          </a>
        </ng-container>
      </ng-container>
    </ng-container>
    <ng-template #noNotifications>
      <p class="notification-empty">No new notifications</p>
    </ng-template>
    <ng-template #login>
      <div class="pl-10">
        <app-login-message>
          <p>To enable notifications, please login</p>
        </app-login-message>
      </div>
    </ng-template>
  </div>
</div>
