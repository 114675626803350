import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {ExternalPaymentPayload} from '../components/external-payment/external-payment.component';
import {MarketContractType} from '../types/types';

// when adding a new marketplace, always make sure to also add the credit card params
//

@Injectable({
  providedIn: 'root',
})
export class MarketplaceTypeService {
  private contractMap = {
    [environment.contracts.marketplace]: MarketContractType.v4,
    [environment.contracts.english]: MarketContractType.v4,
    [environment.contracts.dutch]: MarketContractType.v4,
    [environment.contractsOld.english]: MarketContractType.v2,
    [environment.contractsOld.dutch]: MarketContractType.v3,
    [environment.contractsOld.marketplace]: MarketContractType.v1,
    [environment.hicetnuncContract]: MarketContractType.hen,
    [environment.teiaContract]: MarketContractType.teia,
    [environment.versumMarketplaceContract]: MarketContractType.versum,
    [environment.fxhashMarketplaceContract]: MarketContractType.fxhash,
    [environment.fxhashMarketplaceContractV3]: MarketContractType.fxhashV3,
    [environment.typedMarketplaceContract]: MarketContractType.typed,
    [environment.bioduMarketplaceContract]: MarketContractType.bidou,
    [environment.bidou24MarketplaceContract]: MarketContractType.bidou24,
    [environment.bidou24MonoMarketplaceContract]:
      MarketContractType.bidou24Mono,
    [environment.scriboMarketplaceContract]: MarketContractType.scribo,
    [environment.empropsMarketplaceContract]: MarketContractType.emprops,
    [environment.akaswapMarketplaceV1Contract]: MarketContractType.akaswapV1,
    [environment.akaswapMarketplaceV2Contract]: MarketContractType.akaswapV2,
    [environment.akaswapMarketplaceV2_1Contract]:
      MarketContractType.akaswapV2_1,
    [environment.akaswapMarketplaceOfferContract]:
      MarketContractType.akaswapOffer,
    [environment.dogamiMarketplaceContract]: MarketContractType.dogami,
  };
  private nameMap = {
    [MarketContractType.v1]: 'objkt.com',
    [MarketContractType.v2]: 'objkt.com',
    [MarketContractType.v3]: 'objkt.com',
    [MarketContractType.v4]: 'objkt.com',
    [MarketContractType.hen]: 'hen',
    [MarketContractType.teia]: 'teia',
    [MarketContractType.versum]: 'versum',
    [MarketContractType.fxhash]: 'fxhash',
    [MarketContractType.fxhashV3]: 'fxhash',
    [MarketContractType.typed]: 'typed',
    [MarketContractType.bidou]: '8bidou',
    [MarketContractType.bidou24]: '8bidou',
    [MarketContractType.bidou24Mono]: '8bidou',
    [MarketContractType.scribo]: '8scribo',
    [MarketContractType.emprops]: 'Emerging Properties',
    [MarketContractType.akaswapV1]: 'Akaswap',
    [MarketContractType.akaswapV2]: 'Akaswap',
    [MarketContractType.akaswapV2_1]: 'Akaswap',
    [MarketContractType.akaswapOffer]: 'Akaswap',
    [MarketContractType.dogami]: 'Dogami',
  };

  private objktContracts = [
    MarketContractType.v1,
    MarketContractType.v2,
    MarketContractType.v3,
    MarketContractType.v4,
  ];

  constructor() {}

  get(marketplaceContract: string): MarketContractType {
    return this.contractMap[marketplaceContract];
  }

  getName(marketplaceContract: string): string {
    const marketplaceType = this.contractMap[marketplaceContract];
    return this.nameMap[marketplaceType];
  }

  isObjkt(marketplaceContract: string): boolean {
    const marketplaceType = this.contractMap[marketplaceContract];
    return this.objktContracts.includes(marketplaceType);
  }

  getExternalPaymentParams(payload: ExternalPaymentPayload): string {
    const type = this.get(payload.contract);
    const params = [];

    switch (type) {
      case MarketContractType.v1: // works
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.v4: // works
        params.push({value: payload.bigmapKey});
        params.push({value: 'proxyAddress'});
        break;
      case MarketContractType.hen: // nope
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.teia: // works
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.versum: // works
        params.push({value: payload.quantity || 1});
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.fxhash: // works
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.bidou: // works
      case MarketContractType.bidou24: // works
      case MarketContractType.bidou24Mono: // works
        params.push({value: payload.bigmapKey});
        params.push({value: payload.quantity || 1});
        params.push({value: payload.price});
        break;
      case MarketContractType.emprops: // couldn't test
        params.push({value: payload.galleryId});
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.typed: // worked but said it failed
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.scribo: // works
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.akaswapV1: // doesn't work
        params.push({value: payload.quantity || 1});
        params.push({value: payload.bigmapKey});
        break;
      case MarketContractType.akaswapV2: // no listings found
      case MarketContractType.akaswapV2_1: // works
        params.push({value: payload.bigmapKey});
        params.push({value: payload.quantity || 1});
        break;
      case MarketContractType.dogami: // couldn't test
        params.push({value: payload.bigmapKey});
        params.push({value: 'XTZ'});
        params.push({value: payload.price});
        params.push({value: 'proxyAddress'});
        break;
    }

    return JSON.stringify(params);
  }
}
