<app-modal [(show)]="show" (showChange)="showChange.next($event)" [title]="title" class="warning-modal-component">
  <ng-container body>
    <div [innerHTML]="message"></div>
  </ng-container>
  <ng-container footer>
    <div class="frow justify-end">
      <app-button buttonStyle="main" (onClick)="continue(true)">Confirm</app-button>
      <app-button class="ml-10" (onClick)="continue(false)">Cancel</app-button>
    </div>
  </ng-container>
</app-modal>
