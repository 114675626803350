import { Injectable } from '@angular/core';
import Hashids from 'hashids';

@Injectable({
  providedIn: 'root',
})
export class HashidService {
  hashids = new Hashids('objkt.bid!', 8);

  constructor() {}

  encode(id: string) {
    return this.hashids.encode(id);
  }

  decode(id: any): number {
    return parseInt(this.hashids.decode(id).toString());
  }
}
