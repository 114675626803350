import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {SafeUrl} from '@angular/platform-browser';
import {combineLatest, Observable, timer} from 'rxjs';
import {map} from 'rxjs/operators';
import {AuctionType, DutchAuctionModel, EnglishAuctionModel} from 'src/app/types/types';
import {AuctionApiService} from 'src/app/services/api/auction-api.service';
import {PreviewService} from 'src/app/services/preview.service';
import {DateTime, Duration} from 'luxon';
import {SigningService} from '../../services/tezos/signing.service';
import {AuctionService} from '../../services/auction.service';
import {WalletService} from '../../services/tezos/wallet.service';

@Component({
  selector: 'app-objkt-auction-element',
  templateUrl: './objkt-auction-element.component.html',
  styleUrls: ['./objkt-auction-element.component.scss'],
})
export class ObjktAuctionElementComponent implements OnChanges {
  @Input() auction: EnglishAuctionModel | DutchAuctionModel;
  imgUrl: string | SafeUrl = '';
  inFuture: boolean;
  countdown$: Observable<any>;
  model$: Observable<{
    countdown: {days: number; hours: number; minutes: number; seconds: number};
    highestBid: number;
  }>;

  constructor(
    readonly auctionApiService: AuctionApiService,
    readonly auctionService: AuctionService,
    readonly previewService: PreviewService,
    readonly walletService: WalletService,
  ) {}

  get imgPreloadUrl() {
    return this.previewService.getObjktPreview(this.auction?.token);
  }

  get timeRemaining() {
    return DateTime.fromISO(this.auction.end_time).toRelative();
  }

  get timePrefix() {
    const endTime = DateTime.fromISO(this.auction.end_time);
    const now = DateTime.now();
    return endTime > now ? 'ends' : 'ended';
  }

  get auctionTitle() {
    if (this.auction.type === AuctionType.English) {
      return 'Highest Bid Auction';
    } else if (this.auction.type === AuctionType.Dutch) {
      return 'Price Drop Auction';
    }
  }

  highestBid(timestamp: string) {
    switch (this.auction.type) {
      case AuctionType.Dutch:
        const dutchAuction = this.auction as DutchAuctionModel;
        const price = Number(this.auctionApiService.getCurrentPriceFromModel(dutchAuction, timestamp));
        return Math.max(price, dutchAuction.end_price);
      case AuctionType.English:
        const englishAuction = this.auction as EnglishAuctionModel;
        return englishAuction.highest_bid;
    }
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.auction && this.auction) {
      const startTime = DateTime.fromISO(this.auction?.start_time);
      const now = DateTime.now();
      this.inFuture = startTime > now;
      this.model$ = combineLatest([timer(0, 1000), this.walletService.getHead()]).pipe(
        map(([countdown, head]) => {
          const blockTime = 30;
          const timestamp = DateTime.fromISO(head.timestamp as string);
          const offset = Duration.fromObject({seconds: ~~(countdown / blockTime) * blockTime});
          const approxBlockTimestamp = timestamp.plus(offset).toISO();
          return {
            countdown: this.getCountdown(),
            highestBid: this.highestBid(approxBlockTimestamp),
          };
        }),
      );
    }
  }

  private getCountdown() {
    const startTime = DateTime.fromISO(this.auction.start_time);
    const endTime = DateTime.fromISO(this.auction.end_time);
    const now = DateTime.now();
    const diff = (this.inFuture ? startTime.diff(now) : endTime.diff(now)).shiftTo('days', 'hours', 'minutes', 'seconds', 'milliseconds');
    return {
      days: Math.max(0, diff.days),
      hours: Math.max(0, diff.hours),
      minutes: Math.max(0, diff.minutes),
      seconds: Math.max(0, diff.seconds),
    };
  }
}
