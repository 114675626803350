import {Pipe, PipeTransform} from '@angular/core';
import {environment} from 'src/environments/environment';

@Pipe({name: 'shortAddress'})
export class ShortAddressPipe implements PipeTransform {
  transform(value: string): string {
    if (
      value === environment.contractsOld.dutch ||
      value === environment.contracts.dutch
    ) {
      return 'objkt.com Auction House (Dutch)';
    }
    if (
      value === environment.contractsOld.english ||
      value === environment.contracts.english
    ) {
      return 'objkt.com Auction House (English)';
    }
    if (
      value === environment.contractsOld.marketplace ||
      value === environment.contracts.marketplace
    ) {
      return 'objkt.com Marketplace';
    }
    if (
      value === 'KT1Hkg5qeNhfwpKW4fXvq7HGZB9z2EnmCCA9' ||
      value === 'KT1HbQepzV1nVGg8QVznG7z4RcHseD5kwqBn'
    ) {
      return 'hic et nunc Marketplace';
    }
    if (value === 'KT1PHubm9HtyQEJ4BBpMTVomq6mhbfNZ9z5w') {
      return 'teia Marketplace';
    }
    if (value === 'KT1GbyoDi7H1sfXmimXpptZJuCdHMh66WS9u') {
      return 'fxhash Marketplace v2';
    }
    if (value === 'KT1M1NyU9X4usEimt2f3kDaijZnDMNBu42Ja') {
      return 'fxhash Marketplace v3';
    }
    if (value === 'KT1GyRAJNdizF1nojQz62uGYkx8WFRUJm9X5') {
      return 'versum Marketplace';
    }
    if (value === 'KT18sk72dcUViatVTp3qZMiAXergPYPqLmuN') {
      return 'Migration Contract';
    }

    return value ? `${value.substring(0, 5)}...${value.slice(-5)}` : 'n/a';
  }
}
