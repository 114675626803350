<span #tippyContainer [class.text-popover-trigger]="text" [class.user-popover-trigger]="userAddress" [class.collection-popover-trigger]="collectionContract">
  <ng-content></ng-content>
</span>

<div style="display: none">
  <div #textTemplate>
    <div class="popover-content-wrapper text">
      {{ text }}
    </div>
  </div>

  <div #userTemplate>
    <div *ngIf="(model$ | async)?.user as user; else loading" class="popover-content-wrapper user-popover frow centered-column">
      <a href="/profile/{{ user.address }}">
        <app-avatar-image [user]="user"></app-avatar-image>
      </a>
      <a href="/profile/{{ user.address }}">
        <h3 class="name mt-5">{{ user.alias || user.tzdomain || (user.address | shortAddress) }}</h3>
      </a>
      <a href="https://tzkt.io/{{ user.address }}" rel="nofollow" target="_blank">
        <h4 class="address">
          {{ user.address | shortAddress }}
        </h4>
      </a>
      <div *ngIf="user.twitter || user.website" class="social-links frow row-center">
        <a *ngIf="user.twitter" [href]="user.twitter" rel="nofollow" target="_blank">
          <img class="social-icon" src="/assets/twitter.svg" />
        </a>
        <a *ngIf="user.website" [href]="user.website" rel="nofollow" target="_blank">
          <img class="social-icon" src="/assets/web.svg" />
        </a>
      </div>
    </div>
  </div>

  <div #collectionTemplate>
    <ng-container *ngIf="(model$ | async)?.collection as collection; else loading">
      <div class="collection-popover">
        <div class="popover-content-wrapper frow centered-column">
          <a [href]="collection | collectionLink">
            <app-collection-image [collection]="collection" class="collection-image"></app-collection-image>
          </a>
          <div class="mt-5 frow row-center">
            <a [href]="collection | collectionLink">
              <h3 class="name">{{ collection.name }}</h3>
            </a>
            <ng-container *ngTemplateOutlet="collectionAsset; context: {icon: getAsset(collection)}"></ng-container>
            <ng-template #collectionAsset let-icon="icon">
              <img *ngIf="icon" [class.ml-5]="collection.name" class="verified {{ icon }}" src="assets/{{ icon }}.svg" />
            </ng-template>
          </div>
          <a [href]="'https://tzkt.io/' + collection.contract" rel="nofollow" target="_blank">
            <h4 class="address">
              {{ collection.contract | shortAddress }}
            </h4>
          </a>
          <div *ngIf="collection.twitter" class="social-links frow row-center">
            <a [href]="'https://twitter.com/' + collection.twitter" rel="nofollow" target="_blank">
              <img class="social-icon" src="/assets/twitter.svg" />
            </a>
          </div>
        </div>
        <div class="stats frow">
          <div>
            <label>Tokens</label>
            <span>{{ (collection.items | shortNumber) || '0' }}</span>
          </div>
          <div>
            <label>Vol</label>
            <span>{{ (collection.volume_total | tez | shortNumber) || '0' }}</span>
          </div>
          <div>
            <label>Owners</label>
            <span>{{ (collection.owners | shortNumber) || '0' }}</span>
          </div>
          <div>
            <label>Floor</label>
            <span>{{ (collection.floor_price | tez | shortNumber) || '-' }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div #galleryTemplate>
    <ng-container *ngIf="(model$ | async)?.gallery as gallery; else loading">
      <div class="gallery-popover">
        <div class="popover-content-wrapper frow centered-column">
          <a [href]="gallery | galleryLink">
            <app-collection-image [collection]="gallery" class="collection-image"></app-collection-image>
          </a>
          <span class="registry-name" *ngIf="gallery.registry">{{ gallery.registry.name }}</span>
          <div class="mt-5 frow row-center">
            <a [href]="gallery | galleryLink">
              <h3 class="name">{{ gallery.name || '-' }}</h3>
            </a>
            <ng-container *ngTemplateOutlet="galleryAsset; context: {icon: getAsset(gallery)}"></ng-container>
            <ng-template #galleryAsset let-icon="icon">
              <img *ngIf="icon" class="verified ml-5 {{ icon }}" src="assets/{{ icon }}.svg" />
            </ng-template>
          </div>
        </div>
        <div class="stats frow">
          <div>
            <label>Tokens</label>
            <span>{{ (gallery.items | shortNumber) || '0' }}</span>
          </div>
          <div>
            <label>Vol</label>
            <span>{{ (gallery.volume_total | tez | shortNumber) || '0' }}</span>
          </div>
          <div>
            <label>Owners</label>
            <span>{{ (gallery.owners | shortNumber) || '0' }}</span>
          </div>
          <div>
            <label>Floor</label>
            <span>{{ (gallery.floor_price | tez | shortNumber) || '-' }}</span>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div #tokenTemplate>
    <ng-container *ngIf="(model$ | async)?.token as token; else loading">
      <div class="token-popover">
        <div class="popover-content-wrapper frow centered">
          <div class="details">
            <h4 class="title">{{ token.name }}</h4>
            <span class="description">{{ token.description }}</span>
          </div>
        </div>
        <div class="token-properties">
          <div class="data-point">
            <div>
              <label>Creator</label
              ><b *ngIf="token.creators && token.creators.length >= 1">
                <ng-container *ngFor="let creator of token.creators; let i = index">
                  <a target="_blank" href="/profile/{{ creator.creator_address }}">
                    {{ creator.holder.alias || creator.holder.tzdomain || (creator.creator_address | shortAddress) }}
                  </a>
                  <span *ngIf="i + 1 < token.creators.length">, </span>
                </ng-container>
              </b>
            </div>
          </div>
          <div class="data-point">
            <div>
              <label>Collection</label>
              <b>
                <a [href]="token.fa | collectionLink" class="link-button" target="_blank">
                  <span>{{ token.fa.name }}</span>
                </a>
              </b>
            </div>
          </div>
          <div class="data-point">
            <div>
              <label>Editions</label><b>{{ token.supply | number : '1.0-0' }}</b>
            </div>
          </div>
          <div class="data-point">
            <div>
              <label
                >Royalties <span *ngIf="token.royalties?.length > 1">({{ token.royalties?.length }})</span></label
              >
              <b class="royalty-indicator">{{ token.totalRoyalties }}%</b>
            </div>
          </div>
          <div class="data-point">
            <div>
              <label>MIME type</label> <b>{{ token.mime || '-' }}</b>
            </div>
          </div>
          <div class="data-point">
            <div>
              <label>Minted</label>
              <b>
                <time [dateTime]="token.timestamp" [title]="token.timestamp">{{ token.timestamp | date }}</time>
              </b>
            </div>
          </div>
          <div class="data-point">
            <div>
              <label>Token ID</label><b>{{ token.token_id | number : '1.0-0' }}</b>
            </div>
          </div>
        </div>
      </div>
    </ng-container>
  </div>

  <div #customTemplate>
    <ng-container *ngTemplateOutlet="custom; context: customTemplateContext"></ng-container>
  </div>

  <ng-template #loading>
    <div class="objkt-loader-wrapper">
      <div class="objkt-loader"></div>
    </div>
  </ng-template>
</div>
