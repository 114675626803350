import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TopElementComponent} from './top-element.component';
import {RouterModule} from '@angular/router';
import {UsernamePipeModule} from '../../pipes/username/username-pipe.module';
import {TezPipeModule} from '../../pipes/tez/tez-pipe.module';
import {TezModule} from '../tez/tez.module';
import {PrerenderStopModule} from 'src/app/directives/prerender-stop/prerender-stop.module';
import {SrcFallbackModule} from 'src/app/directives/src-fallback/src-fallback.module';
import {LocalisedNumberModule} from '../../pipes/localised-number/localised-number.module';
import {AvatarImageModule} from '../avatar-image/avatar-image.module';

@NgModule({
  declarations: [TopElementComponent],
  imports: [
    CommonModule,
    RouterModule,
    UsernamePipeModule,
    TezPipeModule,
    TezModule,
    PrerenderStopModule,
    SrcFallbackModule,
    LocalisedNumberModule,
    AvatarImageModule,
  ],
  exports: [TopElementComponent],
})
export class TopElementModule {}
