import {Injectable} from '@angular/core';
import {defer, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {HubConnectionBuilder} from '@microsoft/signalr';
import {AuctionType} from '../types';

@Injectable({
  providedIn: 'root',
})
export class AuctionService {
  connection = new HubConnectionBuilder().withUrl(environment.tzktWebSocket).build();

  constructor() {}

  connectWith(auctionType: AuctionType, auctionId: number) {
    if (auctionType === AuctionType.English) {
      return this.connectEnglish(auctionId);
    } else if (auctionType === AuctionType.Dutch) {
      return this.connectDutch(auctionId);
    }
  }

  connectEnglish(auctionId: number): Observable<any> {
    return this.connect({
      contract: auctionId < 1e6 ? environment.contractsOld.english : environment.contracts.english,
      path: 'auctions',
    });
  }

  connectDutch(auctionId: number): Observable<any> {
    return this.connect({
      contract: auctionId < 1e6 ? environment.contractsOld.dutch : environment.contracts.dutch,
      path: 'auctions',
    });
  }

  connectHead(): Observable<any> {
    return defer(() => {
      return this.connection.invoke('SubscribeToHead');
    });
  }

  startConnection() {
    return defer(() => {
      return this.connection.start();
    });
  }

  private connect(operation): Observable<any> {
    return defer(() => {
      return this.connection.invoke('SubscribeToBigMaps', operation);
    });
  }
}
