<div class="component-featured-objkt">
  <a routerLink="{{ objkt?.link || (objkt ? (objkt | objktLink) : '') }}" *ngIf="!isExternal; else externalLink">
    <app-objkt-preview [objkt]="objkt" [size]="previewSize"></app-objkt-preview>
  </a>
  <ng-template #externalLink>
    <a [href]="objkt?.link" target="_blank" rel="nofollow">
      <app-objkt-preview [objkt]="objkt" [size]="previewSize"></app-objkt-preview>
    </a>
  </ng-template>

  <div appPrerenderStop class="component-featured-objkt-content" *ngIf="objkt?.creators?.length === 1; else multipleCreators">
    <app-info-popover [placement]="'top-start'" [userAddress]="objkt.creators[0].holder.address">
      <div class="frow row-start">
        <app-avatar-image [user]="objkt.creators[0].holder" class="mr-10"></app-avatar-image>
        <div class="user-text text-ellipsis">
          <h4 class="text-ellipsis">{{ objkt?.name || '-' }}</h4>
          <div class="person">{{ (objkt?.creators[0].holder | username) || '-' }}</div>
        </div>
      </div>
    </app-info-popover>
  </div>
  <ng-template #multipleCreators>
    <!-- TODO: MULTICREATOR -->
    <div appPrerenderStop class="component-featured-objkt-content frow row-start">
      <!-- <img class="profile-image" src="{{ objkt.creators[0].avatar }}" /> -->
      <div class="user-text text-ellipsis">
        <h4 class="text-ellipsis">{{ objkt?.name || '-' }}</h4>
        <div class="person">Multiple Creators</div>
      </div>
    </div>
  </ng-template>
</div>
