import {Component, HostListener, Input, OnInit, ViewChild} from '@angular/core';

@Component({
  selector: 'app-context-action-button',
  templateUrl: './context-action-button.component.html',
  styleUrls: ['./context-action-button.component.scss'],
  host: {
    '(document:click)': 'closeMenu($event)',
  },
})
export class ContextActionButtonComponent implements OnInit {
  @Input() actions: {name: string; fn: () => void}[] = [];
  @Input() icon = 'more_horiz';
  @Input() loading = false;
  @Input() left = false;
  @ViewChild('button') button;
  showActions = false;

  constructor() {}

  ngOnInit(): void {}

  @HostListener('document:keydown', ['$event'])
  onKeydownHandler(event: KeyboardEvent) {
    if (event.key === 'Escape') {
      this.showActions = false;
    }
  }

  closeMenu(event) {
    if (!this.button || !this.button.nativeElement?.contains(event.target)) {
      this.showActions = false;
    }
  }
}
