import {Component, OnInit} from '@angular/core';
import {combineLatest, Observable} from 'rxjs';
import {filter, first, map, switchMap, tap} from 'rxjs/operators';
import {ListingApiService} from 'src/app/services/api/listing-api.service';
import {MarketplaceService} from 'src/app/services/tezos/marketplace.service';
import {WalletService} from 'src/app/services/tezos/wallet.service';
import {MarketContractType} from 'src/app/types/types';
import {DateTime, Duration} from 'luxon';
import {SettingsApiService} from 'src/app/services/api/settings-api.service';
import {SigningService} from 'src/app/services/tezos/signing.service';
import { IndexerHangingListingFragment } from 'src/graphql/gen/indexer';

@Component({
  selector: 'app-hanging-listings-modal',
  templateUrl: './hanging-listings.component.html',
  styleUrls: ['./hanging-listings.component.scss'],
})
export class HangingListingsComponent implements OnInit {
  hangingListings$: Observable<IndexerHangingListingFragment[]>;
  listingsCancelled = false;
  isLoading = false;
  showModal = false;

  constructor(
    private readonly walletService: WalletService,
    private readonly settingsApiService: SettingsApiService,
    private readonly signingService: SigningService,
    private readonly listingApiService: ListingApiService,
    private readonly marketplaceService: MarketplaceService,
  ) {}

  ngOnInit(): void {
    this.hangingListings$ = combineLatest([
      this.signingService.isSigned$.pipe(
        filter(isSigned => isSigned !== null),
        switchMap(_ =>
          this.walletService.address$.pipe(
            first(),
            filter(address => address !== null),
          ),
        ),
      ),
      this.settingsApiService.getHideHaningWarning(),
    ]).pipe(
      filter(([_, hideHangingWarngin]) => !hideHangingWarngin),
      switchMap(([address, _]) => this.listingApiService.getHangingListings(address)),
      map(listings => listings.filter(listing => this.isSufficientlyInThePast(listing.timestamp))),
      tap(listings => {
        if (listings.length) {
          this.showModal = true;
        }
      }),
    );
  }

  cancelListings(listings: IndexerHangingListingFragment[]) {
    this.isLoading = true;

    this.marketplaceService
      .batchCancelAsk(
        listings.slice(0, 100).map(listing => ({
          marketplace_contract: listing.marketplace_contract,
          marketContractType: MarketContractType.v4,
          id: listing.bigmap_key,
          bigmap_key: listing.bigmap_key,
          amount_left: listing.amount_left,
          token_pk: null,
          pk: null,
          seller: null,
          fa_contract: null,
        })),
      )
      .pipe(
        switchMap(op => {
          return op.confirmation(1);
        }),
      )
      .subscribe(
        _ => {
          this.isLoading = false;
          this.listingsCancelled = true;
        },
        _ => {
          this.isLoading = false;
        },
      );
  }

  private isSufficientlyInThePast(timestamp: string) {
    // only show popup if listing is older than 5 minutes (to prevent potential reace conditions)
    return DateTime.fromISO(timestamp).diffNow('minutes').minutes <= -5;
  }
}
