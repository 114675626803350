import {Injectable} from '@angular/core';

@Injectable({
  providedIn: 'root',
})
export class LocalStorageService {
  private localStorageKey = 'objkt-settings-local';

  constructor() {}

  getHideMature() {
    const hideMature = this.getSetting('hide_mature');
    if (hideMature === null) return true;
    else return hideMature === true ? true : false;
  }

  setHideMature(value: boolean) {
    return this.set('hide_mature', value);
  }

  getHideFlashing() {
    const hideFlashing = this.getSetting('hide_flashing');
    if (hideFlashing === null) return false;
    else return hideFlashing === true ? true : false;
  }

  setHideFlashing(value: boolean) {
    return this.set('hide_flashing', value);
  }

  getUploadRegion() {
    return this.getSetting('upload_region');
  }

  setUploadRegion(value: string) {
    return this.set('upload_region', value);
  }

  private set(key: string, value: any) {
    try {
      if (value !== null && value !== undefined) {
        window.localStorage.setItem(this.getKey(key), JSON.stringify(value));
      } else {
        window.localStorage.removeItem(this.getKey(key));
      }
    } catch (e) {}
  }

  private getSetting(key: string) {
    const localSettings = window.localStorage.getItem(this.getKey(key));
    try {
      return localSettings ? JSON.parse(localSettings) : null;
    } catch (e) {
      return null;
    }
  }

  private getKey(key: string) {
    return `${this.localStorageKey}-${key}`;
  }
}
