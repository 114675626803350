<ng-container *ngIf="tokensMerged$ | async as tokens">
  <app-carousel [divide]="divide" [items]="tokens" *ngIf="tokens?.length; else noTokens">
    <ng-template template-element let-item>
      <app-objkt-gallery-element [objkt]="tokenPath && item ? item[tokenPath] : item">
        <ng-template [ngTemplateOutlet]="itemTemplate" [ngTemplateOutletContext]="{$implicit: item}"></ng-template>
      </app-objkt-gallery-element>
    </ng-template>
  </app-carousel>
  <ng-template #noTokens>
    <p class="not-found ml-15">No tokens found.</p>
  </ng-template>
</ng-container>
