import {Injectable} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {environment} from 'src/environments/environment';
import {Channel, Socket} from 'phoenix';
import {NotificationApiService} from './api/notification-api.service';
import {tap} from 'rxjs/operators';
import {IUserNotification} from '../models/notifications.interface';
import UserNotification from '../models/notification.model';
import {WalletService} from './tezos/wallet.service';

@Injectable({
  providedIn: 'root',
})
export class NotificationService {
  unreadNotifications$ = new BehaviorSubject<{
    notifications: IUserNotification[];
    total: number;
  }>({
    notifications: [],
    total: 0,
  });

  socket: Socket;
  channel: Channel;

  constructor(private notificationApiService: NotificationApiService) {}

  getPermission(): Observable<NotificationPermission> {
    return new Observable(subscriber => {
      Notification.requestPermission(status => {
        subscriber.next(status);
      });
    });
  }

  connect(address, token): Observable<void> {
    this.socket = new Socket(environment.backendWebSocket, {
      params: {address, token},
    });
    this.socket.connect();
    this.channel = this.socket.channel(`user:${address}`, {});

    return new Observable(observer => {
      this.channel
        .join()
        .receive('ok', resp => {
          observer.next();
        })
        .receive('error', resp => {
          observer.error();
        });

      this.channel.on('new_notif', (notification: IUserNotification) => {
        const current = this.unreadNotifications$.value;
        if (!current.notifications.find(n => n.id === notification.id)) {
          this.unreadNotifications$.next({
            notifications: [new UserNotification(notification, address), ...current.notifications],
            total: current.total + 1,
          });
        }
      });
    });
  }

  disconnect() {
    return new Observable(subscriber => {
      this.socket?.disconnect(() => subscriber.complete());
    });
  }

  refresh() {
    this.notificationApiService
      .getAll(1)
      .pipe(
        tap(response => {
          this.unreadNotifications$.next({
            notifications: response.results,
            total: response.results.filter(r => r.unread)?.length || 0,
          });
        }),
      )
      .subscribe();
  }
}
