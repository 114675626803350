import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WrapModalComponent} from './wrap-modal.component';
import {ModalModule} from '../modal.module';
import {ReactiveFormsModule} from '@angular/forms';
import {TezPipeModule} from 'src/app/pipes/tez/tez-pipe.module';
import {LocalisedNumberModule} from '../../../pipes/localised-number/localised-number.module';

@NgModule({
  declarations: [WrapModalComponent],
  imports: [CommonModule, ModalModule, ReactiveFormsModule, TezPipeModule, LocalisedNumberModule],
  exports: [WrapModalComponent],
})
export class WrapModalModule {}
