import {Component, Input, OnChanges, OnInit, SimpleChanges} from '@angular/core';
import {UserImageSize, UserModel} from 'src/app/types/types';
import {environment} from 'src/environments/environment';
import {IndexerCreatorDefaultFragment} from '../../../graphql/gen/indexer';

@Component({
  selector: 'app-avatar-image',
  templateUrl: './avatar-image.component.html',
  styleUrls: ['./avatar-image.component.scss'],
})
export class AvatarImageComponent implements OnChanges {
  @Input() user: IndexerCreatorDefaultFragment & UserModel;
  @Input() avatarString?: string;
  @Input() size: UserImageSize = UserImageSize.Thumb120;

  loading = true;

  avatar: string;
  avatarFallback: string;

  fullSizeAvatar = UserImageSize.Original;

  square = false;

  constructor() {}

  ngOnChanges(changes: SimpleChanges): void {
    if (this.user && (this.user.creator_address || this.user.address)) {
      if (this.user.creator_address === 'tz2WbetYprSXdP5JfxEcYecmv7FmJKUNtByf' || this.user.address === 'tz2WbetYprSXdP5JfxEcYecmv7FmJKUNtByf') {
        this.square = true;
      }
      this.avatarFallback = this.user.avatarFallback || `https://services.tzkt.io/v1/avatars2/${this.user.creator_address || this.user.address}`;
      const image = this.getAvatar(this.user.avatar || this.user.logo);
      this.avatar = image?.includes('https://') ? image : this.avatarFallback;
    }
  }

  private getAvatar(logo: string) {
    if (logo?.startsWith('hex://')) {
      return environment.cdnLink + logo?.replace('hex://', '') + this.size;
    }

    return logo;
  }

  handleImageError() {
    this.loading = false;
    this.avatar = this.avatarFallback;
  }

  handleImageLoad() {
    this.loading = false;
  }
}
