import BigNumber from 'bignumber.js';
import {
  BackendActivityFragment,
  BackendCreator,
  BackendNotificationFragment,
  BackendNotificationPageFragment,
  BackendUserFragment,
} from 'src/graphql/gen/backend';
import {
  IndexerListingDefaultFragment,
  IndexerAttribute,
  IndexerDutchAuctionDefaultFragment,
  IndexerEnglishAuctionBidsDefaultFragment,
  IndexerEnglishAuctionDefaultFragment,
  IndexerEventDefaultFragment,
  IndexerFaFragment,
  IndexerSalesStatsFragment,
  IndexerTokenDefaultFragment,
  IndexerTokenHolderDefaultFragment,
  IndexerTokenHoldersFragment,
  IndexerTokenMarketFragment,
  IndexerUserDefaultFragment,
  IndexerInvitationFragment,
  IndexerFaLightFragment,
  IndexerUserOfferFragment,
  IndexerFaCardFragment,
  IndexerGalleryFragment,
  IndexerOpenEditionFragment,
  IndexerEventHistoryFragment,
} from 'src/graphql/gen/indexer';

export type GalleryModel = IndexerGalleryFragment;

export type InvitationModel = IndexerInvitationFragment;

export type BackendCreatorModel = BackendCreator;
export type BackendUserModel = BackendUserFragment & {};

export type BackendNotificationModel = BackendNotificationFragment;
export type BackendActivityModel = BackendActivityFragment;
export type BackendNotificationPageModel = BackendNotificationPageFragment & {
  results: UserNotification[];
};

export type UserNotification = {
  activity: {
    actor: {
      address: string;
      name: string;
    };
    object: {
      amount: number;
      fa2: {
        contract: string;
        name: string;
      };
      token: {
        creator: {
          address: string;
          alias: string;
        };
        id: number;
        title: string;
        fa2_id?: string;
        artifact_uri?: string;
        display_uri?: string;
        thumbnail_uri?: string;
        contract?: FaModel;
      };
      price: number;
      event_creator?: {
        address: string;
        alias: string;
      };
      seller?: {
        address: string;
        alias: string;
      };
      buyer?: {
        address: string;
        alias: string;
      };
      timestamp: Date;
    };
    verb: string;
  };
  id: number;
  unread: boolean;
};

type Objkt = {
  owned_quantity?: number;
  original_artifact_uri?: string;
  preview?: string;
  artist?: User;
  displayType?: ObjktDisplayType;
  creator?: UserModel;
  fa_collection_type?: string;
  tags?: {tag: {name: string}}[];
  metadata?: string;
  galleries_pk?: number[];
  open_edition?: OpenEditionModel;

  // Explore Auction typesense card add-on (all token interfaces will be merged in the future into one UI interface)
  auction_status?: string;
  auction_type?: TypesenseAuctionType;
  auction_amount?: number;
  auction_amount_left?: number;
  auction_start_price?: number;
  auction_end_price?: number;
  auction_start_time?: number;
  auction_end_time?: number;
  auction_highest_bid?: number;
  auction_reserve?: number;
  auction_price_increment?: number;
  auction_bids?: number;
  hash?: string;
};

export type OpenEditionModel = IndexerOpenEditionFragment;

type User = {
  address: string;
  alias?: string;
  description?: string;
  site?: string;
  twitter?: string;
  logo?: string;
  avatar?: string;
  avatarFallback?: string;
  name?: string;
};

type Auction = {
  type?: AuctionType;
  token?: ObjktModel;
  marketContractType?: MarketContractType;
};

export enum ObjktDisplayType {
  Image,
  EndlessWays,
  Video,
  Glb,
  HTML,
  SVGXML,
  Audio,
  PDF,
  Domain,
  PlainText,
  None,
}

export enum ObjktPreviewSizes {
  Thumb288 = '/thumb288',
  Thumb400 = '/thumb400',
  Display = '/display',
}

export enum UserImageSize {
  Original = '/profile-picture',
  Thumb120 = '/thumb120',
}

export enum AuctionType {
  English = 'e',
  Dutch = 'd',
}

export enum TypesenseAuctionType {
  English = 'english',
  Dutch = 'dutch',
  OpenEdition = 'open_edition',
}

export enum ContractType {
  Open = 'open',
  Curated = 'curated',
  Generative = 'generative',
  User = 'user',
}

export type TokenAttributeModel = IndexerAttribute & {
  rarity?: number;
};

export type ObjktDetailsModelPage = {
  total: number;
  page: number;
  objkts: ObjktDetailsModel[];
};

export type ObjktDetailsModel = IndexerTokenDefaultFragment &
  IndexerTokenMarketFragment &
  IndexerTokenHoldersFragment &
  Objkt;
export type ObjktModel = IndexerTokenDefaultFragment & Objkt;
export type FeaturedObjktModel = ObjktModel & {
  link?: string;
};

export interface ObjktModelPlus extends ObjktModel {
  [property: string]: any;
}

export type UserModel = User & IndexerUserDefaultFragment;
export type TokenHolderModel = IndexerTokenHolderDefaultFragment;

export type SalesStatsModel = IndexerSalesStatsFragment;

export type EnglishAuctionModel = IndexerEnglishAuctionDefaultFragment &
  Auction;
export type DutchAuctionModel = IndexerDutchAuctionDefaultFragment & Auction;
export type GenericAuctionModel = EnglishAuctionModel | DutchAuctionModel;

export type EnglishAuctionBidModel = IndexerEnglishAuctionBidsDefaultFragment;

// export type ObjktSwapModel = IndexerSwapDefaultFragment & {
//   isHen?: boolean;
// };

export enum MarketContractType {
  hen = 'hen',
  teia = 'teia',
  versum = 'versum',
  fxhash = 'fxhash',
  fxhashV3 = 'fxhashV3',
  bidou = '8bidou',
  bidou24 = '8bidou24',
  bidou24Mono = '8bidou24Mono',
  emprops = 'emprops',
  typed = 'typed',
  scribo = '8scribo',
  akaswapV1 = 'akaswapV1',
  akaswapV2 = 'akaswapV2',
  akaswapV2_1 = 'akaswapV2_1',
  akaswapOffer = 'akaswapOffer',
  dogami = 'dogami',
  v1 = 1,
  v2 = 2,
  v3 = 3,
  v4 = 4,
}

export type ObjktAskModel = IndexerListingDefaultFragment & {
  id?: number;
  bigmap_key?: number;
  swap_id?: number;
  token?: IndexerTokenDefaultFragment;
  isHen?: boolean;
  shares?: any;
  contract_version?: number;
  contract?: string;
  marketContractType?: MarketContractType;
};
export type ObjktBidModel = IndexerUserOfferFragment & {
  token?: IndexerTokenDefaultFragment;
  fa?: IndexerFaCardFragment;
  marketContractType?: MarketContractType;
};
export type FaModel = IndexerFaFragment;
export type FaLightModel = IndexerFaLightFragment & {
  collaborators?: InvitationModel[];
  creator?: UserModel;
};
export type UserCollectionModel = {
  collection_id?: string;
  collection_type?: string;
  name?: string;
  path?: string;
  logo?: string;
  contract?: string;
  live?: boolean;
  count: number;
};

export type EventModel = IndexerEventDefaultFragment & {
  added?: boolean;
};
export type EventHistoryModel = IndexerEventHistoryFragment;
export type DisplayEventModel = EventModel & {
  dayString?: string;
  timeString?: string;
  shortDayString?: string;
};

export type CollectionMetaData = {
  volumeTotal: number;
  volume24h: number;
  owners: number;
  items: number;
  activeListings: number;
  activeAuctions: number;
};

export type TokenFilter = {
  search?: string;
  creatorId?: string;
  creatorIdNot?: string;
  fa2?: string[];
  id?: string;
  type?: string;
  primary?: string;
  showHidden?: boolean;
  hideBanned?: boolean;
  showOe?: boolean;
};

export type TokenHolderFilter = {
  holderId?: string;
  tokenFilter?: TokenFilter;
};

export type TokenSorting = {
  sortBy: TokenSortingCriteria;
};

export type TokenSortingCriteria =
  | 'hidAsc'
  | 'hidDesc'
  | 'listDesc'
  | 'idAsc'
  | 'idDesc'
  | 'bidDesc'
  | 'bidAsc'
  | 'askDesc'
  | 'askAsc';

export interface TokenPageState {
  tokenFilter: TokenFilter;
  tokenSorting: TokenSorting;
  page?: number;
}

// Auction model as returned directly by the contract
export interface ContractAuction {
  id?: number;
  creator: string;
  end_time: string;
  fa2: string;
  objkt_id: BigNumber;
  start_time: string;
  state: number;
}

export interface ContractEnglishAuction extends ContractAuction {
  highest_bidder: string;
  current_price: BigNumber;
  extension_time: BigNumber;
  price_increment: BigNumber;
  reserve: BigNumber;
}

export interface ContractDutchAuction extends ContractAuction {
  end_price: BigNumber;
  start_price: BigNumber;
  editions?: number;
}

export enum MIMETYPE {
  BMP = 'image/bmp',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  SVG = 'image/svg+xml',
  TIFF = 'image/tiff',
  WEBP = 'image/webp',
  MP4 = 'video/mp4',
  OGV = 'video/ogg',
  QUICKTIME = 'video/quicktime',
  WEBM = 'video/webm',
  GLB = 'model/gltf-binary',
  GLTF = 'model/gltf+json',
  MP3 = 'audio/mpeg',
  OGA = 'audio/ogg',
  WAV = 'audio/wav',
  XWAV = 'audio/x-wav',
  FLAC = 'audio/flac',
  PDF = 'application/pdf',
  ZIP = 'application/zip',
  ZIP1 = 'application/x-zip-compressed',
  ZIP2 = 'multipart/x-zip',
  MD = 'text/plain',
}

export enum COVER_MIMETYPE {
  BMP = 'image/bmp',
  GIF = 'image/gif',
  JPEG = 'image/jpeg',
  PNG = 'image/png',
  TIFF = 'image/tiff',
  WEBP = 'image/webp',
}

export enum UbisoftTransferResponseType {
  Restricted = 'TO_RESTRICTED',
  QuotaReached = 'ARCHETYPE_QUOTA_REACHED',
  Ok = 'OK',
}

export enum TransactionType {
  Purchase = 'Purchase',
  Transfer = 'Transer',
  Burn = 'Burn',
  Sell = 'Sell',
  List = 'List',
  Offer = 'Offer',
  FloorOffer = 'Floor Offer',
  Mint = 'Mint',
  NewColletion = 'New Collection',
  UpdateCollection = 'Update Collection',
  InviteArtist = 'Invite Artist',
  AcceptInvitation = 'Accept Invitation',
  RemoveCollaborator = 'Remove Collaborator',
  Bid = 'Bid',
  CancelListing = 'Cancel Listing',
  CancelOffer = 'Cancel Offer',
  CreateAuction = 'Create Auction',
  ConcludeAuction = 'Conclude Auction',
  CancelAuction = 'Cancel Auction',
  BatchAsk = 'Batch Ask',
  BatchCancel = 'Batch Cancel',
  BatchAccept = 'Batch Accept',
  BatchTransfer = 'Batch Transfer',
  Reswap = 'Reswap',
  Default = '-',
  Wrap = 'Wrap XTZ',
  Unwrap = 'Unwrap oXTZ',
  MigrationSwap = 'Migration Swap',
  ClaimMigrationSwap = 'Claim Migration Swap',
  Unknown = 'Unknown',
}

export type TransactionModel = {
  hash: string;
  pending: boolean;
  type: TransactionType;
  token?: ObjktModel;
};

export type Currency = {
  name: string;
  displayName: string;
  symbol: string;
  type: CurrencyType;
  contract: string;
  path: string;
  decimals: number;
  valueInUsd?: number;
};

export type CurrencyBalance = Currency & {
  allowance?: number;
  balance?: number;
};

export enum CurrencyType {
  fa12 = 'fa12',
  fa2 = 'fa2',
}

export type AttributeCountModel = {
  id?: number;
  editions?: number;
  tokens?: number;
  attribute_id?: number;
};

export type UserSettingsModel = {
  notifications?: {
    disable: boolean;
    ignore_types: string[];
    ignore_tokens: {id: string; contract: string}[];
  };
  hide_interactive_warning?: boolean;
  hide_disclaimer?: boolean;
  hide_hanging_warning?: boolean;
  hide_mature?: boolean;
  hide_flashing?: boolean;
};
