import {Component, ElementRef, EventEmitter, Input, OnInit, Output, ViewChild} from '@angular/core';
import {ObjktSelectService} from '../../../services/select.service';
import {AbstractControl, FormArray, FormControl, FormGroup, Validators} from '@angular/forms';
import {ObjktModel} from '../../../types/types';
import {map, mergeMap} from 'rxjs/operators';
import {SnackService} from '../../../services/snack.service';
import {numberValidator} from '../../../directives/number-validator.directive';
import {WalletService} from '../../../services/tezos/wallet.service';
import {MarketplaceService} from '../../../services/tezos/marketplace.service';

@Component({
  selector: 'app-batch-ask-modal',
  templateUrl: './batch-ask-modal.component.html',
  styleUrls: ['./batch-ask-modal.component.scss'],
})
export class BatchAskModalComponent implements OnInit {
  @Input() show;
  @Output() showChange = new EventEmitter<boolean>();
  @ViewChild('objktForm') form: ElementRef;
  objkts: ObjktModel[];
  objktsForm = new FormGroup({objkts: new FormArray([])});
  title = 'Batch Ask';
  tooManyObjktsError = null;
  maxObjkts = 25;
  loading = false;

  constructor(
    readonly objktSelect: ObjktSelectService,
    private readonly walletService: WalletService,
    private readonly marketplaceService: MarketplaceService,
    private readonly snackService: SnackService,
  ) {}

  get objktsFormArray(): FormArray {
    return this.objktsForm.get('objkts') as FormArray;
  }

  ngOnInit(): void {
    this.objktSelect.data$.subscribe({
      next: v => {
        if (v.lastAction.type === 'removeAtIndex') {
          this.objktsFormArray.removeAt(v.lastAction.params.index);
        }
        this.objkts = Object.values(v.items);
        this.tooManyObjktsError = this.objkts.length > this.maxObjkts ? `Exceeds maximum of ${this.maxObjkts} objkts, please remove some items` : null;
        if (!this.objkts.length) {
          this.show = false;
        }
      },
    });

    for (const objkt of this.objkts) {
      this.objktsFormArray.push(
        new FormGroup({
          amount: new FormControl('', [
            Validators.required,
            Validators.pattern('^[0-9-]+$'),
            numberValidator({
              min: 1,
              max: 10000,
            }),
          ]),
          price: new FormControl('', [Validators.required, numberValidator({min: 0.1})]),
        }),
      );
    }
  }

  getObjktFormGroupByIndex(index): FormGroup {
    return this.objktsFormArray?.get(index.toString()) as FormGroup;
  }

  handleBatchAsk() {
    if (!this.objktsForm.valid) {
      this.validateAllFormFields(this.objktsForm);
      return;
    }

    this.loading = true;
    const asks = [];
    for (let i = 0; i < this.objkts.length; i++) {
      asks.push(
        Object.assign({}, this.objkts[i], {
          price: Math.round(this.objktsForm.get('objkts').value[i].price * 1e6),
          amount: this.objktsForm.get('objkts').value[i].amount,
        }),
      );
    }

    this.walletService
      .userAddress()
      .pipe(
        map(address => {
          if (address === null) {
            throw new Error('cannot get address for batch ask');
          } else {
            return address;
          }
        }),
        mergeMap(address => this.marketplaceService.batchAsk(asks, address)),
      )
      .pipe(mergeMap(op => op.confirmation(1)))
      .subscribe(
        _ => {
          this.showChange.emit(false);
          this.snackService.success('Your listings have been placed');
          this.loading = false;
        },
        err => {
          this.showChange.emit(false);
          this.snackService.warn(err?.description || err);
          console.error(err);
          this.loading = false;
        },
      );
  }

  getFormControl(index, type) {
    const formArray = this.objktsForm.get('objkts') as FormArray;
    return formArray.controls[index].get(type);
  }

  showNumberError(index, type) {
    const formArray = this.objktsForm.get('objkts') as FormArray;
    const errors = formArray.controls[index].get(type).errors;
    if (errors.number.value === 'nan') {
      return 'Enter a number';
    }
    if (errors.number.value === 'too-low') {
      return 'Too low';
    }
    if (errors.number.value === 'too-high') {
      return 'Too high';
    }
  }

  handleRemoveObjkt(index: number) {
    this.objktSelect.removeAtIndex(index);
  }

  private validateAllFormFields(formGroup: FormGroup | FormArray) {
    for (const field of Object.keys(formGroup.controls)) {
      const control = formGroup.get(field);
      if (control instanceof FormControl) {
        control.markAsTouched({onlySelf: true});
        continue;
      }
      this.validateAllFormFields(control as FormGroup | FormArray);
    }
  }

  log(controls: AbstractControl[]) {
    console.log(controls);
    return null;
  }
}
