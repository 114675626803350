import {BehaviorSubject} from 'rxjs';
import {ObjktAskModel, ObjktBidModel, ObjktModelPlus} from '../types/types';
import {Injectable} from '@angular/core';

class SelectServiceAbstract {
  _data: BehaviorSubject<{
    items: {[key: number]: any};
    size: number;
    lastAction: {type: string; params: any};
  }>;

  protected readonly _key;

  constructor(key: string) {
    this._key = key;
  }

  get items() {
    return this._data.value.items;
  }

  get keys() {
    return Object.keys(this._data.value.items);
  }

  get values() {
    return Object.values(this._data.value.items);
  }

  get size() {
    return this._data.value.size;
  }

  get data$() {
    return this._data;
  }

  hasItems() {
    return !!Object.keys(this.items).length;
  }

  add(item) {
    const items = Object.assign({}, this.items);
    items[item[this._key]] = item;
    this._data.next({items, size: this.size + 1, lastAction: {type: 'add', params: {item}}});
  }

  remove(item) {
    const items = Object.assign({}, this.items);
    delete items[item[this._key]];
    this._data.next({items, size: this.size - 1, lastAction: {type: 'remove', params: {item}}});
  }

  removeAtIndex(index) {
    const arr = Object.values(this.items);
    arr.splice(index, 1);
    const items = arr.reduce((obj, item) => ({...obj, [item[this._key]]: item}), {});
    this._data.next({items, size: this.size - 1, lastAction: {type: 'removeAtIndex', params: {index}}});
  }

  update(item) {
    const items = Object.assign({}, this.items);
    items[item[this._key]] = item;
    this._data.next({items, size: this.size, lastAction: {type: 'update', params: {item}}});
  }

  clear() {
    this._data.next({items: {}, size: 0, lastAction: {type: 'clear', params: null}});
  }
}

@Injectable({
  providedIn: 'root',
})
export class ObjktSelectService extends SelectServiceAbstract {
  _data = new BehaviorSubject<{
    items: {[key: number]: ObjktModelPlus};
    size: number;
    lastAction: {type: string; params: any};
  }>({
    items: {},
    size: 0,
    lastAction: {type: 'init', params: null},
  });

  constructor() {
    super('pk');
  }
}

@Injectable({
  providedIn: 'root',
})
export class ListingSelectService extends SelectServiceAbstract {
  _data = new BehaviorSubject<{
    items: {[key: number]: ObjktAskModel | ObjktBidModel};
    size: number;
    lastAction: {type: string; params: any};
  }>({
    items: {},
    size: 0,
    lastAction: {type: 'init', params: null},
  });

  constructor() {
    super('id');
  }
}
