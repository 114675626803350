import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule} from '@angular/router';
import {ReactiveFormsModule} from '@angular/forms';

import {HeaderComponent} from './header.component';
import {WrapModalModule} from '../modal/wrap-modal/wrap-modal.module';
import {WalletPanelModule} from '../wallet-panel/wallet-panel.module';
import {AvatarImageModule} from '../avatar-image/avatar-image.module';
import {InfoPopoverModule} from '../info-popover/info-popover.module';
import {SearchPreviewModule} from '../search-preview/search-preview.module';
import {TransactionsPanelModule} from '../transactions-panel/transactions-panel.module';
import {NotificationsPanelModule} from '../notifications-panel/notifications-panel.module';

import {AccessibleLinkDirective} from '../../directives/accessible-link.directive';

@NgModule({
  declarations: [HeaderComponent, AccessibleLinkDirective],
  imports: [
    CommonModule,
    RouterModule,
    SearchPreviewModule,
    ReactiveFormsModule,
    TransactionsPanelModule,
    NotificationsPanelModule,
    WalletPanelModule,
    WrapModalModule,
    AvatarImageModule,
    InfoPopoverModule,
  ],
  exports: [HeaderComponent],
})
export class HeaderModule {}
