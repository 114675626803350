import {Injectable} from '@angular/core';
import {environment} from 'src/environments/environment';
import {FaModel, ObjktModel, ObjktPreviewSizes} from '../types/types';
import {CollectionImagePipe} from '../pipes/collection-image/collection-image.pipe';
import {Observable, of} from 'rxjs';
import {HttpClient} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {TokenService} from './token.service';

@Injectable({
  providedIn: 'root',
})
export class PreviewService {
  constructor(
    private readonly httpClient: HttpClient,
    private readonly tokenService: TokenService,
    private readonly collectionImagePipe: CollectionImagePipe,
  ) {}

  getObjktPreview(objkt: ObjktModel, size: ObjktPreviewSizes = ObjktPreviewSizes.Thumb288) {
    return this.tokenService.getCDNPreviewUri(objkt, size);
  }

  getObjktIpfsPreview(objkt: ObjktModel) {
    return this.tokenService.getIPFSPreviewUri(objkt);
  }

  getCollectionImage(collection: FaModel, size: ObjktPreviewSizes = ObjktPreviewSizes.Thumb288) {
    if (collection.logo?.startsWith('ipfs://')) {
      return `${environment.cdnLink}${collection.logo.replace('ipfs://', '')}${size}`;
    }
    return collection.logo;
  }

  getCollectionIpfs(collection: FaModel) {
    return this.collectionImagePipe.transform(collection.logo);
  }

  getEndlessWaysArtifact(objkt: ObjktModel): Observable<string> {
    const id = objkt.token_id;
    return this.httpClient
      .get(`https://mainnet.images.endlessways.net/thumbnail/${id}?noRedirect=1`, {responseType: 'text'})
      .pipe(catchError(e => of('assets/blank.jpeg')));
  }

  getAvatar(logo: string) {
    if (logo?.startsWith('hex://')) {
      return environment.cdnLink + logo?.replace('hex://', '') + '/profile-picture';
    }

    return logo;
  }
}
