import {Pipe, PipeTransform} from '@angular/core';
import {ObjktModel} from '../../types/types';

@Pipe({name: 'objktLink'})
export class ObjktLinkPipe implements PipeTransform {
  transform(objkt: ObjktModel): string {
    if (objkt?.auction_type && objkt?.hash) return `/auction/${objkt.auction_type.split('')[0]}/${objkt.hash}`;
    return `/asset/${objkt.fa.path || objkt.fa.contract}/${objkt.token_id}`;
  }
}
