<div
  [class.restricted]="isRestricted"
  [class.mature]="isMature && (hideMature$ | async)"
  [class.flashing]="isFlashing && (hideFlashing$ | async)"
  [class.scribo]="is8scribo"
  [class.small]="isSmall"
  [class.large]="!isSmall"
  class="objkt-preview-component"
>
  <div class="holder">
    <ng-container *ngIf="!isDomain">
      <div class="overlay gradient-loading" [class.done]="!loading"></div>
      <img #img (error)="handleImageError()" (load)="handleImageLoad()" [src]="dataImgUrl || imgUrl" class="card-image" [class.smol]="isSmol" />
    </ng-container>
    <div *ngIf="isDomain" class="domain">
      <div class="content frow centered">{{ objkt.name }}</div>
    </div>
  </div>
</div>
