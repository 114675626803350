import {
  Component,
  Input,
  OnChanges,
  OnInit,
  SimpleChanges,
} from '@angular/core';
import {BehaviorSubject, Observable} from 'rxjs';
import {
  debounceTime,
  distinctUntilChanged,
  map,
  switchMap,
} from 'rxjs/operators';
import {FaModel, ObjktModel, UserModel} from 'src/app/types/types';
import {TypesenseApiService} from '../../services/api/typesense-api.service';

@Component({
  selector: 'app-search-preview',
  templateUrl: './search-preview.component.html',
  styleUrls: ['./search-preview.component.scss'],
})
export class SearchPreviewComponent implements OnInit, OnChanges {
  @Input() term: string;

  model$: Observable<{
    loading: boolean;
    objkts: ObjktModel[];
    collections: FaModel[];
    users: UserModel[];
  }>;

  updateSearch$ = new BehaviorSubject<string>(null);

  constructor(private readonly typesenseApi: TypesenseApiService) {}

  ngOnInit(): void {
    this.model$ = this.updateSearch$.pipe(
      distinctUntilChanged(),
      debounceTime(200),
      switchMap(term =>
        this.typesenseApi.queryAll(term).pipe(
          map(data => ({
            loading: false,
            objkts: data.results?.[0]?.hits?.map(h => h.document),
            collections: data.results?.[1]?.hits?.map(h => ({
              ...h.document,
              contract: h.document.address,
            })),
            users: data.results?.[2]?.hits?.map(h => h.document),
          })),
        ),
      ),
    );
  }

  ngOnChanges(changes: SimpleChanges): void {
    if (changes.term && changes.term.currentValue) {
      this.updateSearch$.next(changes.term.currentValue);
    }
  }
}
