import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {GalleryLinkPipe} from './gallery-link.pipe';

@NgModule({
  declarations: [GalleryLinkPipe],
  imports: [CommonModule],
  exports: [GalleryLinkPipe],
})
export class GalleryLinkModule {}
