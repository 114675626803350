import {Component, Input, OnInit} from '@angular/core';
import {UserImageSize, UserModel} from 'src/app/types/types';
import {TezPipe} from '../../pipes/tez/tez.pipe';

@Component({
  selector: 'app-top-element',
  templateUrl: './top-element.component.html',
  styleUrls: ['./top-element.component.scss'],
  providers: [TezPipe],
})
export class TopElementComponent implements OnInit {
  @Input() person: UserModel;
  @Input() value: number;

  avatarSize = UserImageSize.Thumb120;

  constructor() {}

  ngOnInit(): void {}
}
