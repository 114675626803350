import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {CarouselComponent} from './carousel.component';
import {VirtualScrollerModule} from '../virtual-scroller/virtual-scroller.component';

@NgModule({
  declarations: [CarouselComponent],
  imports: [CommonModule, VirtualScrollerModule],
  exports: [CarouselComponent],
})
export class CarouselModule {}
