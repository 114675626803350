import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {PrerenderStopDirective} from './prerender-stop.directive';

@NgModule({
  declarations: [PrerenderStopDirective],
  imports: [CommonModule],
  exports: [PrerenderStopDirective],
})
export class PrerenderStopModule {}
