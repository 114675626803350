<ng-container *ngIf="auctionsMerged$ | async as auctions">
  <app-carousel [items]="auctions" *ngIf="auctions?.length; else noAuctions">
    <ng-template template-element let-item>
      <app-objkt-auction-element [auction]="item"></app-objkt-auction-element>
    </ng-template>
  </app-carousel>
  <ng-template #noAuctions>
    <p class="not-found ml-15">No auctions found.</p>
  </ng-template>
</ng-container>
