import {Component, Input, OnInit} from '@angular/core';
import {FaModel, ObjktPreviewSizes} from 'src/app/types/types';
import {PreviewService} from 'src/app/services/preview.service';

@Component({
  selector: 'app-collection-image',
  templateUrl: './collection-image.component.html',
  styleUrls: ['./collection-image.component.scss'],
})
export class CollectionImageComponent implements OnInit {
  @Input() collection: FaModel;
  @Input() size? = ObjktPreviewSizes.Thumb288;

  error = false;
  imgUrl = '';

  constructor(readonly previewService: PreviewService) {}

  get imgPreloadUrl() {
    return this.previewService.getCollectionImage(this.collection, this.size);
  }

  get imgIpfsPreloadUrl() {
    return this.previewService.getCollectionIpfs(this.collection);
  }

  ngOnInit(): void {}
}
