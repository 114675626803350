import {Injectable} from '@angular/core';
import {forkJoin, Observable, of} from 'rxjs';
import {defaultIfEmpty, map, switchMap} from 'rxjs/operators';
import {
  IndexerGetEventsGQL,
  IndexerGetEventsMultiGQL,
  IndexerGetEventsLiveGQL,
  IndexerOrder_By,
  IndexerEvent_Bool_Exp,
  IndexerGetEventsHistoryGQL,
} from 'src/graphql/gen/indexer';
import {EventHistoryModel, EventModel} from 'src/app/types/types';
import {ObjktApiService} from './objkt-api.service';
import {UserApiService} from './user-api.service';
import {DateTime, Duration} from 'luxon';
import {Fa2ApiService} from './fa2-api.service';
import {EventService} from '../event.service';
import { flatMap } from 'tslint/lib/utils';

@Injectable({
  providedIn: 'root',
})
export class EventApiService {
  constructor(
    readonly events: IndexerGetEventsGQL,
    readonly eventsHistory: IndexerGetEventsHistoryGQL,
    readonly eventsLive: IndexerGetEventsLiveGQL,
    readonly eventsMulti: IndexerGetEventsMultiGQL,
    readonly userApiService: UserApiService,
    readonly objktApiService: ObjktApiService,
    readonly fa2ApiService: Fa2ApiService,
    readonly eventService: EventService,
  ) {}

  getEvents(
    where: IndexerEvent_Bool_Exp = {
      timestamp: {_is_null: false},
      _or: [
        {
          event_type: {
            _neq: 'transfer',
          },
        },
        {
          event_type: {
            _is_null: true,
          },
        },
      ],
    },
    limit = null,
  ): Observable<EventModel[]> {
    return this.eventsLive
      .fetch(
        {
          where,
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event =>
            this.addDataToEvent(event as EventModel),
          ),
        ),
        switchMap(events => this.addDigestHashToList(events)),
        switchMap(events => this.addAsyncUserDataToList(events)),
        switchMap(events => this.addAsyncFaDataToList(events)),
      );
  }

  getEventsByAddress(
    address: string,
    limit: number = 100,
  ): Observable<EventModel[]> {
    return this.eventsLive
      .fetch(
        {
          where: {
            timestamp: {_is_null: false},
            reverted: {_neq: true},
            _or: [
              {
                creator_address: {
                  _eq: address,
                },
              },
              {
                recipient_address: {
                  _eq: address,
                },
              },
            ],
          },
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event =>
            this.addDataToEvent(event as EventModel),
          ),
        ),
        switchMap(events => this.addDigestHashToList(events)),
        switchMap(events => this.addAsyncUserDataToList(events)),
        switchMap(events => this.addAsyncFaDataToList(events)),
      );
  }

  getEventsByObjkt(
    objktId: string,
    fa2: string,
    limit: number,
    offset: number,
    eventTypes: string[],
    marketEventTypes: string[],
  ): Observable<EventHistoryModel[]> {
    return this.eventsHistory
      .fetch(
        {
          where: {
            timestamp: {_is_null: false},
            reverted: {_neq: true},
            event_type: eventTypes
              ? {
                  _in: eventTypes,
                }
              : undefined,
            marketplace_event_type: marketEventTypes
              ? {
                  _in: marketEventTypes,
                }
              : undefined,
            token: {
              token_id: {
                _eq: objktId,
              },
            },
            fa_contract: {
              _eq: fa2,
            },
          },
          limit,
          offset,
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event => this.addDataToEvent(event)),
        ),
        switchMap(events => this.addDigestHashToList(events)),
        switchMap(events => this.addAsyncUserDataToList(events)),
      );
  }

  getRecentSalesCollection(contract: string): Observable<EventModel[]> {
    return this.events
      .fetch(
        {
          where: {
            timestamp: {_is_null: false},
            price: {_is_null: false},
            reverted: {_neq: true},
            _or: [
              {
                marketplace_event_type: {
                  _in: [
                    'list_buy',
                    'dutch_auction_buy',
                    'offer_accept',
                    'english_auction_settle',
                    'offer_floor_accept',
                  ],
                },
              },
              {
                event_type: {
                  _in: ['open_edition_buy'],
                },
              },
            ],
            token: {
              fa_contract: {
                _eq: contract,
              },
            },
          },
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit: 48,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event => this.addDataToEvent(event)),
        ),
        map(events =>
          events.filter(
            event => event.event_type !== 'conclude_auction' || event.price > 0,
          ),
        ),
        switchMap(events => this.addDigestHashToList(events)),
      );
  }

  getAllRecentSales(days: number): Observable<EventModel[]> {
    return this.events
      .fetch(
        {
          where: {
            price_xtz: {_is_null: false},
            currency_id: {_in: [1, 18, 3, 2]},
            reverted: {_neq: true},
            token: {
              creators: {
                holder: {
                  flag: {
                    _eq: 'none',
                  },
                },
              },
              content_rating: {
                _is_null: true,
              },
            },
            _or: [
              {
                event_type: {
                  _in: ['open_edition_buy'],
                },
              },
              {
                marketplace_event_type: {
                  _in: [
                    'list_buy',
                    'dutch_auction_buy',
                    'offer_accept',
                    'english_auction_settle',
                    'offer_floor_accept',
                  ],
                },
              },
            ],
            timestamp: {
              _gte: DateTime.now()
                .startOf('minute')
                .minus(Duration.fromObject({days}))
                .toISO(),
            },
          },
          order_by: [{price_xtz: IndexerOrder_By.Desc}],
          limit: 48,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event => this.addDataToEvent(event)),
        ),
        map(events =>
          events.filter(
            event => event.event_type !== 'conclude_auction' || event.price > 0,
          ),
        ),
        switchMap(events => this.addDigestHashToList(events)),
      );
  }

  getRecentSales(address: string, limit = 48): Observable<EventModel[]> {
    return this.eventsMulti
      .fetch(
        {
          where_common: {
            price: {_is_null: false},
            reverted: {_neq: true},
            timestamp: {
              _is_null: false,
            },
            _or: [
              {
                event_type: {
                  _in: ['open_edition_buy'],
                },
              },
              {
                marketplace_event_type: {
                  _in: [
                    'list_buy',
                    'dutch_auction_buy',
                    'offer_accept',
                    'english_auction_settle',
                    'offer_floor_accept',
                  ],
                },
              },
            ],
          },
          where_a: {
            creator_address: {
              _eq: address,
            },
          },
          where_b: {
            token: {
              creators: {
                creator_address: {
                  _eq: address,
                },
              },
            },
          },
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          [...result.data.a, ...result.data.b].sort(
            (x, y) => Date.parse(y.timestamp) - Date.parse(x.timestamp),
          ),
        ),
        map(events =>
          events.filter((v, i, a) => a.findIndex(t => t.id === v.id) === i),
        ),
        map(events => events.slice(0, limit)),
        map(events => events.map(event => this.addDataToEvent(event))),
        map(events =>
          events.filter(
            event =>
              event.event_type !== 'english_auction_settle' || event.price > 0,
          ),
        ),
        switchMap(events => this.addDigestHashToList(events)),
      );
  }

  getRecentOffers(address: string): Observable<EventModel[]> {
    return this.events
      .fetch(
        {
          where: {
            timestamp: {_is_null: false},
            reverted: {_neq: true},
            marketplace_event_type: {
              _in: ['offer_create'],
            },
            creator_address: {
              _eq: address,
            },
          },
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit: 48,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event => this.addDataToEvent(event)),
        ),
        switchMap(events => this.addDigestHashToList(events)),
      );
  }

  getRecentListings(address: string): Observable<EventModel[]> {
    return this.events
      .fetch(
        {
          where: {
            token: {
              creators: {
                holder: {
                  flag: {
                    _eq: 'none',
                  },
                },
              },
              supply: {
                _gt: 0, // when supply is 0 the token is burned
              },
            },
            timestamp: {_is_null: false},
            reverted: {_neq: true},
            marketplace_event_type: {
              _in: ['list_create'],
            },
            creator_address: {
              _eq: address,
            },
          },
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit: 48,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event => this.addDataToEvent(event)),
        ),
        switchMap(events => this.addDigestHashToList(events)),
      );
  }

  getRecentPurchases(address: string): Observable<EventModel[]> {
    return this.events
      .fetch(
        {
          where: {
            price: {_is_null: false},
            timestamp: {_is_null: false},
            reverted: {_neq: true},
            _or: [
              {
                event_type: {
                  _in: ['open_edition_buy'],
                },
              },
              {
                marketplace_event_type: {
                  _in: [
                    'list_buy',
                    'dutch_auction_buy',
                    'offer_accept',
                    'english_auction_settle',
                  ],
                },
              },
            ],
            recipient_address: {
              _eq: address,
            },
          },
          order_by: [
            {timestamp: IndexerOrder_By.Desc},
            {id: IndexerOrder_By.Desc},
          ],
          limit: 48,
        },
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(
        map(result =>
          result.data.event.map(event => this.addDataToEvent(event)),
        ),
        map(events =>
          events.filter(
            event => event.event_type !== 'conclude_auction' || event.price > 0,
          ),
        ),
        switchMap(events => this.addDigestHashToList(events)),
      );
  }

  private addDigestHashToList(events: EventModel[]) {
    return forkJoin(events.map(event => this.addDigestHash(event))).pipe(
      defaultIfEmpty([]),
    );
  }

  private addDigestHash(event: EventModel) {
    return this.objktApiService.addDigestHash(event.token).pipe(
      map(token => ({
        ...event,
        token,
      })),
    );
  }

  private addDataToEvent(event: EventModel) {
    const token = {
      ...event.token,
      fa: event.fa,
    };
    return {
      ...event,
      event_type: event.event_type || event.marketplace_event_type,
      token: event.token
        ? this.objktApiService.addAdditionalData(token)
        : event.token,
      creator: event.creator
        ? this.userApiService.extendUser(event.creator)
        : event.creator,
      recipient: event.recipient
        ? this.userApiService.extendUser(event.recipient)
        : event.recipient,
    };
  }

  private addAsyncUserDataToList(events): Observable<any[]> {
    return forkJoin(events.map(event => this.addAsyncUserData(event))).pipe(
      defaultIfEmpty([]),
    );
  }

  private addAsyncFaDataToList(events): Observable<any[]> {
    const fa2s = events
      .filter(event =>
        this.eventService.collectionEventTypes.includes(event.event_type),
      )
      .map(event => event.fa_contract);

    return this.fa2ApiService
      .getAllFa2(undefined, {contract: {_in: fa2s}})
      .pipe(
        map(collections =>
          events.map(event => ({
            ...event,
            collection: collections.find(c => c.contract === event.fa_contract),
          })),
        ),
      );
  }

  private addAsyncUserData(event): Observable<any> {
    const creator$ = event.creator
      ? this.userApiService.addAvatarToUser(event.creator)
      : of(event.creator);
    const recipient$ = event.recipient
      ? this.userApiService.addAvatarToUser(event.recipient)
      : of(event.recipient);
    return forkJoin([creator$, recipient$]).pipe(
      map(([creator, recipient]) => ({
        ...event,
        creator,
        recipient,
      })),
    );
  }
}
