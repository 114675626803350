import {Pipe, PipeTransform} from '@angular/core';
import {environment} from 'src/environments/environment';

@Pipe({
  name: 'collectionImage',
})
export class CollectionImagePipe implements PipeTransform {
  transform(value: string): string {
    if (value?.startsWith('ipfs://')) {
      return environment.ipfsLink + value.replace('ipfs://', '');
    } else if (value) {
      return value;
    }

    return 'assets/blank.jpeg';
  }
}
