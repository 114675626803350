<div class="home-component frow-container mt-10">
  <div class="landing-page">
    <div class="frow gutters direction-reverse">
      <div
        class="col-sm-1-2 frow centered-column mb-40 objkts-featured-wrapper"
      >
        <ng-container
          *ngIf="curations$ | async as curations; else loadingCurations"
        >
          <ng-container *ngFor="let objkt of curations?.tokens; index as i">
            <app-featured-objkt
              [class.visible]="featuredVisible === i"
              [objkt]="objkt"
            ></app-featured-objkt>
            <div
              [class.visible]="featuredVisible === i"
              class="frow justify-between mt-10 navigation"
            >
              <span (click)="triggerPrev()" class="material-icons-outlined">
                arrow_back_ios
              </span>
              <div class="featured-number">
                {{ i + 1 }}/{{ curations?.tokens.length }}
              </div>
              <span (click)="triggerNext()" class="material-icons-outlined">
                arrow_forward_ios
              </span>
            </div>
          </ng-container>
          <div appPrerenderStop>
            <img
              (load)="imgUrl = imgPreloadUrl"
              *ngFor="let objkt of curations?.tokens"
              [src]="objkt.artifact_uri | ipfs"
              hidden
            />
          </div>
        </ng-container>
        <ng-template #loadingCurations>
          <div class="gradient-loading mb-25">
            <app-featured-objkt
              [class.visible]="true"
              [objkt]="undefined"
            ></app-featured-objkt>
          </div>
        </ng-template>
      </div>
      <div class="col-sm-1-2 frow">
        <div class="frow centered-column row-start">
          <h1>
            The largest <span class="clean-nft">Digital Art</span> & Collectible
            marketplace on Tezos
          </h1>
          <p>
            Buy and sell your NFTs on our marketplace or try out an English or a
            Dutch Auction.
          </p>
          <button class="mt-20" routerLink="/explore/tokens">
            Discover Now
          </button>
        </div>
      </div>
    </div>
  </div>

  <div class="hidden-gems mt-40">
    <h3 class="mb-10 frow justify-between">
      <span>Daily Curation</span>
      <a [routerLink]="['./explore/previously-featured']">view all »</a>
    </h3>
    <app-token-carousel [divide]="5" [tokens$]="gems$"></app-token-carousel>
  </div>

  <a class="banner objktone mt-20" href="https://objkt.one" target="_blank">
    <img src="assets/objkt-one-logo.png" />
  </a>

  <div class="mt-40">
    <h3 class="mb-20 top-list-header frow justify-between">
      Hot Collections (24h)
      <a [routerLink]="['./collections']">view all »</a>
    </h3>
    <div class="frow gutters" *ngIf="hotCollections$ | async as hotCollections">
      <div class="col-md-1-2 mb-10 mt-10">
        <div>
          <ul class="top-list">
            <li *ngFor="let fa2 of hotCollections.slice(0, 10); index as i">
              <div class="frow row-start list-element">
                <span class="position">{{ i + 1 }}</span>
                <app-top-collection
                  [fa2]="fa2"
                  [value]="fa2?.volume_24h"
                  class="item-stretch"
                ></app-top-collection>
              </div>
            </li>
          </ul>
        </div>
      </div>
      <div class="col-md-1-2 mb-10 mt-10">
        <div>
          <ul class="top-list">
            <li *ngFor="let fa2 of hotCollections.slice(10, 20); index as i">
              <div class="frow row-start list-element">
                <span class="position">{{ i + 11 }}</span>
                <app-top-collection
                  [fa2]="fa2"
                  [value]="fa2?.volume_24h"
                  class="item-stretch"
                ></app-top-collection>
              </div>
            </li>
          </ul>
        </div>
      </div>
    </div>
  </div>

  <div class="objkt-one mt-40">
    <h3 class="mb-10 frow justify-between">
      <span>objkt one</span>
      <a target="_blank" href="https://objkt.one">view all »</a>
    </h3>
    <app-token-carousel [divide]="5" [tokens$]="objktOne$"></app-token-carousel>
  </div>

  <div class="recently-listed mt-40">
    <h3 class="mb-10 frow justify-between">
      <span>OE Ending soon</span>
    </h3>
    <app-token-carousel
      [divide]="5"
      [tokens$]="oeEndingSoon$"
    ></app-token-carousel>
  </div>
  <div class="ending-soon mt-50">
    <h3 class="mb-10 frow justify-between select-header">
      <div>
        Auctions
        <div class="select-wrapper">
          <select (change)="featuredAuctions$.next($event.target.value)">
            <option
              *ngFor="let option of featuredAuctions"
              [value]="option.value"
            >
              {{ option.label }}
            </option>
          </select>
        </div>
      </div>
      <a
        [routerLink]="['/explore/auctions']"
        [queryParams]="{auction_status: 'active'}"
        >view all »</a
      >
    </h3>
    <app-auction-carousel [auctions$]="auctions$"></app-auction-carousel>
  </div>
  <div
    class="previously-featured mt-40"
    *ngIf="previouslyFeatured$ | async as previouslyFeatured"
  >
    <h3 class="mb-20 frow justify-between">
      <span>Previously Featured</span>
      <a [routerLink]="['/explore/previously-featured']">overview »</a>
    </h3>
    <div class="curated-tokens frow justify-start">
      <app-category-link
        *ngFor="let category of previouslyFeatured | shuffle"
        [category]="category"
      ></app-category-link>
    </div>
  </div>
  <div class="recent-sales mt-50">
    <h3 class="mb-10 top-list-header">
      Top Sales
      <div class="select-wrapper">
        <select (change)="topSales$.next($event.target.value)">
          <option *ngFor="let option of topSales" [value]="option.value">
            {{ option.label }}
          </option>
        </select>
      </div>
    </h3>
    <app-token-carousel
      [divide]="5"
      [tokens$]="topSaleTokens$"
      tokenPath="token"
    >
      <ng-template template-element let-item>
        <div class="sale">
          <div class="price mb-5">
            for
            <b
              >{{ item?.price_xtz | tez | localisedNumber : '1.2-2' || 0 }}
              <app-tez> </app-tez>
            </b>
          </div>
          <div class="time">{{ (item?.timestamp | toRelativeTime) || 0 }}</div>
        </div>
      </ng-template>
    </app-token-carousel>
  </div>
  <div class="frow gutters mt-40">
    <div class="col-md-1-2 mb-10 mt-10">
      <h3 class="mb-20 top-list-header">
        Top Creators
        <div class="select-wrapper">
          <select (change)="topSellersAction$.next($event.target.value)">
            <option *ngFor="let option of topOptions" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </h3>
      <div>
        <ul class="top-list" *ngIf="topSellers$ | async as topSellers">
          <li *ngFor="let el of topSellers; index as i">
            <div class="frow row-start list-element">
              <span class="position">{{ i + 1 }}</span>
              <app-top-element
                [person]="el?.subject"
                [value]="el?.volume"
                class="item-stretch"
              ></app-top-element>
            </div>
          </li>
        </ul>
      </div>
    </div>
    <div class="col-md-1-2 mb-10 mt-10">
      <h3 class="mb-20 top-list-header">
        Top Buyers
        <div class="select-wrapper">
          <select (change)="topBuyersAction$.next($event.target.value)">
            <option *ngFor="let option of topOptions" [value]="option.value">
              {{ option.label }}
            </option>
          </select>
        </div>
      </h3>
      <div>
        <ul class="top-list" *ngIf="topBuyers$ | async as topBuyers">
          <li *ngFor="let el of topBuyers; index as i">
            <div class="frow row-start list-element">
              <span class="position">{{ i + 1 }}</span>
              <app-top-element
                [person]="el?.subject"
                [value]="el?.volume"
                class="item-stretch"
              ></app-top-element>
            </div>
          </li>
        </ul>
      </div>
    </div>
  </div>
  <div class="recently-listed mt-40">
    <h3 class="mb-10 frow justify-between">
      <span>Recently Listed</span>
      <a [queryParams]="{sort: 'last_listed:desc'}" [routerLink]="['/search']"
        >view all »</a
      >
    </h3>
    <app-token-carousel
      [divide]="5"
      [tokens$]="recentlyListed$"
    ></app-token-carousel>
  </div>

  <h3 class="mt-40 mb-20 frow justify-between">
    <span>Latest Mints</span>
    <a [routerLink]="['/feed']">view feed »</a>
  </h3>
  <app-token-list
    class="recently-minted"
    [tokenPage$]="recentMints$"
    [viewMode]="1"
  ></app-token-list>
</div>
