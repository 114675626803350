import {Pipe, PipeTransform} from '@angular/core';
import {HashidService} from '../../services/hashid.service';

@Pipe({name: 'auctionIdHash'})
export class AuctionIdHashPipe implements PipeTransform {
  constructor(readonly hashidService: HashidService) {}

  transform(value: string): string {
    return this.hashidService.encode(value);
  }
}
