import {Directive, ElementRef, HostListener, Input} from '@angular/core';

@Directive({
  selector: '*[appSrcFallback]',
})
export class SrcFallbackDirective {
  @Input() appSrcFallback: string;
  hasTried = false;

  constructor(readonly eRef: ElementRef) {}

  @HostListener('error')
  loadFallbackOnError() {
    if (!this.hasTried) {
      this.init();
      this.hasTried = true;
    }
  }

  private init() {
    this.eRef.nativeElement.src = this.appSrcFallback || 'assets/blank.jpeg';

    // If we update the src in the audio source element, we need to call load() on the parent <audio> element
    if (this.eRef.nativeElement.parentElement?.load) {
      this.eRef.nativeElement.parentElement.src = this.eRef.nativeElement.src;
      this.eRef.nativeElement.parentElement.load();
    }
  }
}
