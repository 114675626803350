import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {WalletPanelComponent} from './wallet-panel.component';
import {TezPipeModule} from 'src/app/pipes/tez/tez-pipe.module';
import {LocalisedNumberModule} from '../../pipes/localised-number/localised-number.module';

@NgModule({
  declarations: [WalletPanelComponent],
  imports: [CommonModule, TezPipeModule, LocalisedNumberModule],
  exports: [WalletPanelComponent],
})
export class WalletPanelModule {}
