import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ObjktGalleryElementComponent} from './objkt-gallery-element.component';
import {RouterModule} from '@angular/router';
import {ObjktPreviewModule} from '../objkt-preview/objkt-preview.module';
import {TezModule} from '../tez/tez.module';
import {ObjktLinkPipeModule} from '../../pipes/objkt-links/objkt-link-pipe.module';
import {CollectionLinkPipeModule} from '../../pipes/collection-link/collection-link-pipe.module';
import {UsernamePipeModule} from '../../pipes/username/username-pipe.module';
import {TezPipeModule} from '../../pipes/tez/tez-pipe.module';
import {DisableLinkModule} from 'src/app/directives/disable-link/disable-link.module';
import {LocalisedNumberModule} from '../../pipes/localised-number/localised-number.module';
import {GalleryMimeComponent} from './gallery-mime/gallery-mime.component';
import {InfoPopoverModule} from '../info-popover/info-popover.module';
import {GalleryLinkModule} from 'src/app/pipes/gallery-link/gallery-link.module';
import {ShortAddressPipeModule} from '../../pipes/short-address/short-address-pipe.module';

@NgModule({
  declarations: [ObjktGalleryElementComponent, GalleryMimeComponent],
  imports: [
    CommonModule,
    RouterModule,
    ObjktPreviewModule,
    TezModule,
    ObjktLinkPipeModule,
    CollectionLinkPipeModule,
    UsernamePipeModule,
    TezPipeModule,
    DisableLinkModule,
    LocalisedNumberModule,
    InfoPopoverModule,
    GalleryLinkModule,
    ShortAddressPipeModule,
  ],
  exports: [ObjktGalleryElementComponent],
})
export class ObjktGalleryElementModule {}
