<div class="modal-component frow centered text-left" [class.visible]="show">
  <div class="modal-body">
    <header *ngIf="title" class="text-left">
      {{ title }}
      <span *ngIf="!preventClose" class="close material-icons-outlined" (click)="close()"> close </span>
    </header>
    <div class="content text-left">
      <ng-content select="[body]"></ng-content>
    </div>
    <footer>
      <ng-content select="[footer]"></ng-content>
    </footer>
  </div>
</div>
<div class="modal-background"></div>
