import {Injectable} from '@angular/core';
import {BehaviorSubject, defer, from, merge, Observable, of, Subject} from 'rxjs';
import {catchError, first, map, mergeMap, tap} from 'rxjs/operators';
import {environment} from 'src/environments/environment';
import {char2Bytes} from '@taquito/utils';
import {SigningType} from '@airgap/beacon-types';
import {HttpClient} from '@angular/common/http';
import {NotificationService} from '../notification.service';
import {SnackService} from '../snack.service';
import {WalletService} from './wallet.service';
import {LoadingService} from '../loading.service';

@Injectable({
  providedIn: 'root',
})
export class SigningService {
  private _isSigned$ = new BehaviorSubject(null);
  private _synching$ = new Subject<boolean>();
  private _updatePermission$ = new Subject<boolean>();

  constructor(
    private readonly http: HttpClient,
    private readonly notificationService: NotificationService,
    private readonly snackService: SnackService,
    private readonly walletService: WalletService,
    private readonly loadingService: LoadingService,
  ) {}

  get updatePermission$(): Subject<boolean> {
    return this._updatePermission$;
  }

  get synching$(): Subject<boolean> {
    return this._synching$;
  }

  get loadingText$(): Subject<string> {
    return this.loadingService.loadingText$;
  }

  get loading$(): Subject<boolean> {
    return this.loadingService.loading$;
  }

  get defaultLoadingText(): string {
    return this.loadingService.defaultLoadingText;
  }

  get isSigned$(): BehaviorSubject<boolean> {
    return this._isSigned$;
  }

  get isSigned(): boolean {
    return this._isSigned$.value;
  }

  isLoggedIn() {
    return this.http.get<any>(`${environment.backendUrl}/users/check`, {
      withCredentials: true,
    });
  }

  getToken(noSign = false): Observable<{token: string; address: string} | null> {
    return this.isLoggedIn().pipe(
      catchError(error => {
        if (noSign) {
          return of(null);
        }
        return this.walletService.userAddress().pipe(
          mergeMap(() =>
            this.sign(error.headers.get('x-sig-nonce')).pipe(
              mergeMap(({signature, message, address}) => {
                return this.http
                  .post<any>(
                    `${environment.backendUrl}/users/log_in`,
                    {
                      wallet: {signature, message, address},
                      params: {remember_me: true},
                    },
                    {withCredentials: true},
                  )
                  .pipe(
                    map(response => {
                      return {
                        token: response.token,
                        address: response.address,
                      };
                    }),
                  );
              }),
            ),
          ),
          catchError(err => {
            this._isSigned$.next(false);

            switch (err?.error?.message) {
              case 'Not revealed':
                this.snackService.warn(
                  'You wallet address is not yet revealed, please perform a transaction on the blockchain and try again later.',
                  err?.error?.message,
                  {
                    disableTimeOut: true,
                  },
                );
                break;
              case 'Invalid signature':
                this.snackService.attention('Could not validate the signature', err?.error?.message);
                break;
              default:
                this.snackService.warn('Signing failed', err?.error?.message);
            }
            return of(null);
          }),
        );
      }),
    );
  }

  login(noSign = false) {
    return this.getToken(noSign).pipe(
      tap(response => {
        if (!response) {
          this.isSigned$.next(false);
          return;
        }
        this._isSigned$.next(true);
        this.notificationService
          .connect(response.address, response.token)
          .pipe(
            tap(_ => {
              this.notificationService.refresh();
            }),
            catchError(_ => {
              return of(null);
            }),
          )
          .subscribe();
      }),
    );
  }

  requestPermission() {
    this.walletService.newWallet();
    return defer(() => {
      return this.walletService.wallet.client.requestPermissions({
        network: {
          type: environment.network as any,
        },
      });
    }).pipe(
      tap(() => {
        this._synching$.next(true);
      }),
      mergeMap(res =>
        this.login().pipe(
          map(_ => res),
          catchError(_ => of(res)),
        ),
      ),
      mergeMap(res => {
        return this.walletService.tezos$.pipe(
          map(tezos => {
            tezos.setWalletProvider(this.walletService.wallet);
            return res;
          }),
        );
      }),
      tap(_ => {
        this.isSigned$.subscribe(isSigned => {
          if (isSigned) this.notificationService.refresh();
        });
        this._updatePermission$.next(true);
        this._synching$.next(false);
      }),
      catchError(_ => {
        this._synching$.next(false);
        this._updatePermission$.next(false);
        this.snackService.warn('Login failed, please try again');
        return of(false);
      }),
    );
  }

  desync() {
    this._synching$.next(true);
    this._isSigned$.next(null);
    this.walletService.clearAddress();
    this._updatePermission$.next(false);
    this.notificationService.disconnect().subscribe();
    const body = new FormData();
    body.append('_method', 'delete');
    this.http.post(`${environment.backendUrl}/users/log_out`, body, {withCredentials: true}).subscribe();
    return merge(this.walletService.wallet.clearActiveAccount(), this.notificationService.disconnect()).pipe(
      tap(_ => {
        this.clearPermission();
        this._synching$.next(false);
      }),
    );
  }

  permissionedCall() {
    return this.hasPermission().pipe(
      mergeMap(hasPermission => {
        return hasPermission ? of(true) : this.requestPermission().pipe(mergeMap(_ => this.hasPermission()));
      }),
      first(),
    );
  }

  sign(nonce) {
    // The data to format
    const dappUrl = 'objkt.com';
    const ISO8601formatedTimestamp = new Date().toISOString();

    // The full string

    const signature = nonce ? `, sig:${nonce}` : ` at ${ISO8601formatedTimestamp}`;

    return this.walletService.userAddress().pipe(
      mergeMap(address => {
        const formattedInput = `Tezos Signed Message: Confirming my identity as ${address} on ${dappUrl}${signature}`;
        const bytes = `0501${this.hexLength(formattedInput)}${char2Bytes(formattedInput)}`;

        const payload = {
          signingType: SigningType.MICHELINE,
          payload: bytes,
          sourceAddress: address,
        };

        return this.requestSignPayload(payload).pipe(
          map(singedPayload => {
            return {singedPayload, address};
          }),
          map(res => {
            return {
              ...res,
              bytes,
            };
          }),
        );
      }),
      map(({singedPayload, address, bytes}) => {
        const {signature} = singedPayload;
        return {signature, message: bytes, address};
      }),
    );
  }

  hasPermission() {
    return merge(this.updatePermission$, from(this.walletService.wallet.client.getActiveAccount())).pipe(map(res => !!res));
  }

  private clearPermission() {
    return localStorage.removeItem('walletPermission');
  }

  private requestSignPayload(payload) {
    return defer(() => {
      return this.walletService.wallet.client.requestSignPayload(payload);
    });
  }

  private hexLength(message) {
    return new Blob([message]).size.toString(16).padStart(8, '0');
  }
}
