import {Injectable} from '@angular/core';
import {forkJoin, Observable} from 'rxjs';
import {defaultIfEmpty, map, pluck, switchMap} from 'rxjs/operators';
import {GalleryModel} from 'src/app/types/types';
import {
  IndexerGallery_Bool_Exp,
  IndexerGallery_Order_By,
  IndexerGetAllGalleriesGQL,
  IndexerGetAllGalleriesLightGQL,
  IndexerGetGalleryBySlugGQL,
  IndexerGetGalleryGQL,
  IndexerGetGalleryPkBySlugGQL,
} from 'src/graphql/gen/indexer';
import {UserApiService} from './user-api.service';

@Injectable({
  providedIn: 'root',
})
export class GalleryApiService {
  constructor(
    private readonly getAllGalleriesLight: IndexerGetAllGalleriesLightGQL,
    private readonly getAllGalleries: IndexerGetAllGalleriesGQL,
    private readonly getGallery: IndexerGetGalleryGQL,
    private readonly userApiService: UserApiService,
    private readonly getGalleryBySlug: IndexerGetGalleryBySlugGQL,
    private readonly getGalleryPKBySlug: IndexerGetGalleryPkBySlugGQL,
  ) {}

  getGalleryByPk(pk: number) {
    return this.getGallery.fetch({pk}).pipe(
      pluck('data', 'gallery_by_pk'),
      switchMap(gallery => this.addLogoToGallery(gallery)),
    );
  }

  getGalleryByRegistrySlug(registrySlug: string, gallerySlug: string) {
    return this.getGalleryBySlug.fetch({registrySlug, gallerySlug}).pipe(
      pluck('data', 'gallery'),
      switchMap(gallery => this.addLogoToGallery(gallery[0])),
    );
  }

  getGalleryPKByRegistrySlug(
    registrySlug: string,
    gallerySlug: string,
  ): Observable<number> {
    return this.getGalleryPKBySlug.fetch({registrySlug, gallerySlug}).pipe(
      pluck('data', 'gallery'),
      map(galleries => galleries?.[0]?.pk),
    );
  }

  getGalleriesLight(
    where: IndexerGallery_Bool_Exp,
    orderBy: IndexerGallery_Order_By,
    limit: number = null,
    offset: number = null,
  ) {
    return this.getAllGalleriesLight
      .fetch({
        where,
        order_by: orderBy,
        limit,
        offset,
      })
      .pipe(pluck('data', 'gallery'));
  }

  getGalleries(
    where: IndexerGallery_Bool_Exp,
    orderBy: IndexerGallery_Order_By,
    limit: number = null,
    offset: number = null,
  ) {
    return this.getAllGalleries
      .fetch({
        where,
        order_by: orderBy,
        limit,
        offset,
      })
      .pipe(
        pluck('data', 'gallery'),
        switchMap(galleries => this.addLogoToGalleries(galleries)),
      );
  }

  private addLogoToGalleries(
    galleries: GalleryModel[],
  ): Observable<GalleryModel[]> {
    return forkJoin(
      galleries.map(gallery => this.addLogoToGallery(gallery)),
    ).pipe(defaultIfEmpty([]));
  }

  private addLogoToGallery(gallery: GalleryModel): Observable<GalleryModel> {
    return forkJoin(
      gallery?.curators?.map(model =>
        this.userApiService.addAvatarToUser(model.curator).pipe(
          map(curator => ({
            ...model,
            curator,
          })),
        ),
      ) || [],
    ).pipe(
      defaultIfEmpty([]),
      map(curators => ({
        ...gallery,
        curators,
      })),
    );
  }
}
