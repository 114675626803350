import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'colorPickerRgba',
})
export class ColorPickerRgbaPipe implements PipeTransform {
  transform(value: {rgba: {r: number; g: number; b: number; a: number}}, ...args: unknown[]): unknown {
    const {r, g, b, a} = value.rgba;
    return `rgba(${r}, ${g}, ${b}, ${a})`;
  }
}
