import {HttpBackend, HttpRequestOptions, HttpResponseError, STATUS_CODE} from '@taquito/http-utils';
import axios from 'axios';
import {environment} from '../../../environments/environment';

enum ResponseType {
  TEXT = 'text',
  JSON = 'json',
}

const defaultTimeout = 8000;

export class HttpBackendRetry extends HttpBackend {
  nodes: string[] = [];

  constructor(private rpcLoadBalancerEndpoint: string) {
    super(defaultTimeout);
  }

  async createRequest<T>({url, method, timeout = defaultTimeout, query, headers = {}, json = true}: HttpRequestOptions, data?: object | string, retry = 0) {
    let resType: ResponseType;
    let transformResponse;

    const path = this.getPath(retry);

    if (!headers['Content-Type']) {
      headers['Content-Type'] = 'application/json';
    }

    if (retry > 0) {
      console.log(`${retry}. retrying RPC call using ${path}`);
    }

    if (!json) {
      resType = ResponseType.TEXT;
      transformResponse = [(v: any) => v];
    } else {
      resType = ResponseType.JSON;
    }

    let response;
    try {
      response = await axios.request<T>({
        url: path + url + this.serialize(query),
        method: method ?? 'GET',
        headers,
        responseType: resType,
        transformResponse,
        timeout,
        data,
      });
    } catch (err: any) {
      if (this.nodes.length === 0) {
        this.nodes = await this.getRpcNodes();
      }

      if (retry < 4) {
        return this.createRequest({url, method, timeout, query, headers, json}, data, ++retry);
      }

      if (err.response) {
        let errorData;

        if (typeof err.response.data === 'object') {
          errorData = JSON.stringify(err.response.data);
        } else {
          errorData = err.response.data;
        }

        throw new HttpResponseError(
          `Http error response: (${err.response.status}) ${errorData} after ${retry} retries`,
          err.response.status as STATUS_CODE,
          err.response.statusText,
          errorData,
          url + this.serialize(query),
        );
      } else {
        throw new Error(err);
      }
    }

    return response.data;
  }

  private async getRpcNodes() {
    try {
      const {data} = await axios.get(environment.rpcEndpoint);
      return shuffle(data);
    } catch (error) {
      console.error(error);
      return ['https://mainnet.api.tez.ie'];
    }
  }

  private getPath(retry = 0): string {
    if (retry === 0) return this.rpcLoadBalancerEndpoint;
    const index = (retry - 1) % this.nodes.length;
    return this.nodes[index];
  }
}

function shuffle(array) {
  let currentIndex = array.length;
  let randomIndex;

  // While there remain elements to shuffle.
  while (currentIndex != 0) {
    // Pick a remaining element.
    randomIndex = Math.floor(Math.random() * currentIndex);
    currentIndex--;

    // And swap it with the current element.
    [array[currentIndex], array[randomIndex]] = [array[randomIndex], array[currentIndex]];
  }

  return array;
}
