import {Observable, of} from 'rxjs';
import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';
import {catchError, map} from 'rxjs/operators';

import {environment} from '../../environments/environment';
import {ObjktDisplayType, ObjktModel, ObjktPreviewSizes} from '../types/types';

const mimeExt = {
  'application/epub+zip': 'epub',
  'application/gzip': 'gz',
  'application/java-archive': 'jar',
  'application/json': 'json',
  'application/ld+json': 'jsonld',
  'application/manifest+json': 'webmanifest',
  'application/msword': 'doc',
  'application/octet-stream': 'bin',
  'application/ogg': 'ogx',
  'application/pdf': 'pdf',
  'application/postscript': 'ps',
  'application/rtf': 'rtf',
  'application/vnd.amazon.ebook': 'azw',
  'application/vnd.ms-excel': 'xls',
  'application/vnd.ms-fontobject': 'eot',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/vnd.oasis.opendocument.presentation': 'odp',
  'application/vnd.oasis.opendocument.spreadsheet': 'ods',
  'application/vnd.oasis.opendocument.text': 'odt',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'pptx',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'xlsx',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'docx',
  'application/vnd.rar': 'rar',
  'application/vnd.visio': 'vsd',
  'application/wasm': 'wasm',
  'application/x-7z-compressed': '7z',
  'application/x-abiword': 'abw',
  'application/x-bzip': 'bz',
  'application/x-bzip2': 'bz2',
  'application/x-cdf': 'cda',
  'application/x-csh': 'csh',
  'application/x-freearc': 'arc',
  'application/x-httpd-php': 'php',
  'application/x-msaccess': 'mdb',
  'application/x-sh': 'sh',
  'application/x-shockwave-flash': 'swf',
  'application/x-tar': 'tar',
  'application/xhtml+xml': 'xhtml',
  'application/x-directory': 'html',
  'application/zip': 'zip',
  'audio/aac': 'aac',
  'audio/flac': 'flac',
  'audio/midi': 'mid',
  'audio/mpeg': 'mp3',
  'audio/ogg': 'oga',
  'audio/opus': 'opus',
  'audio/vnd.wave': 'wav',
  'audio/wav': 'wav',
  'audio/wave': 'wav',
  'audio/webm': 'weba',
  'audio/x-wav': 'wav',
  'font/otf': 'otf',
  'font/ttf': 'ttf',
  'font/woff': 'woff',
  'font/woff2': 'woff2',
  'image/avif': 'avif',
  'image/bmp': 'bmp',
  'image/gif': 'gif',
  'image/jpeg': 'jpg',
  'image/png': 'png',
  'image/svg+xml': 'svg',
  'image/tiff': 'tiff',
  'image/vnd.adobe.photoshop': 'psd',
  'image/vnd.microsoft.icon': 'ico',
  'image/webp': 'webp',
  'model/gltf-binary': 'glb',
  'model/gltf+json': 'gltf',
  'text/calendar': 'ics',
  'text/css': 'css',
  'text/csv': 'csv',
  'text/html': 'html',
  'text/javascript': 'js',
  'text/markdown': 'md',
  'text/plain': 'txt',
  'text/xml': 'xml',
  'video/3gpp': '3gp',
  'video/3gpp2': '3g2',
  'video/avi': 'avi',
  'video/mp4': 'mp4',
  'video/mpeg': 'mpg',
  'video/ogg': 'ogv',
  'video/quicktime': 'mov',
  'video/webm': 'webm',
  'video/x-matroska': 'mkv',
  'video/x-ms-wmv': 'wmv',
  'video/x-msvideo': 'avi',
};

@Injectable()
export class TokenService {
  constructor(private readonly httpClient: HttpClient) {}

  getArtifactContentCDN(token: ObjktModel): Observable<string | null> {
    if (this.isHTTP(token.artifact_uri) || this.isIPFS(token.artifact_uri)) {
      const cdnUri = this.getCDNUri(token);

      if (cdnUri) {
        return this.httpClient.get(cdnUri, {responseType: 'text'}).pipe(
          map(data => data),
          catchError(() => of(null)),
        );
      }
    }
    return of(null);
  }

  getArtifactContentIPFS(token: ObjktModel): Observable<string | null> {
    if (this.isIPFS(token.artifact_uri)) {
      const ipfsUri = this.getIPFSUri(token);

      if (ipfsUri) {
        return this.httpClient.get(ipfsUri, {responseType: 'text'}).pipe(
          map(data => data),
          catchError(() => of(null)),
        );
      }
    }
    return of(null);
  }

  getTokenUri(value: string): string {
    value = this.addForwardSlashForCloudflare(value);

    if (this.isIPFS(value)) {
      return environment.ipfsLink + value.replace('ipfs://', '');
    } else if (this.isBase64Image(value) || this.isHTTP(value)) {
      return value;
    } else if (this.isHEX(value)) {
      // Could getTokenUri be refactored to accept an objkt and use this.getCDNUri() here ?
      return environment.cdnLink + value.replace('hex://', '') + '/artifact';
    }
    return '';
  }

  getIPFSUri(objkt: ObjktModel): string {
    return this.isIPFS(objkt.artifact_uri) && this.getTokenUri(objkt.artifact_uri);
  }

  getMetadataIpfsUri(objkt: ObjktModel): string {
    return this.isIPFS(objkt.metadata) && this.getTokenUri(objkt.metadata);
  }

  getMetadataHttpUri(objkt: ObjktModel): string {
    return this.isHTTP(objkt.metadata) && this.getTokenUri(objkt.metadata);
  }

  /**
   * Retrieves the original artifact_uri for non-IPFS tokens
   */
  getArtifactUri(objkt: ObjktModel): string {
    return !this.getIPFSUri(objkt) && this.isHTTP(objkt?.original_artifact_uri) ? objkt?.original_artifact_uri : undefined;
  }

  getCDNUri(objkt: ObjktModel, size?: ObjktPreviewSizes): string {
    if (size && this.isBase64(objkt.thumbnail_uri)) return objkt.thumbnail_uri;
    if (this.isBase64(objkt.artifact_uri)) return objkt.artifact_uri;
    if (this.isNotIncludedCDN(objkt) || !this.getTokenIdentifier(objkt, size)) return undefined;
    if (!size) return `${environment.cdnLink}${this.getTokenIdentifier(objkt, size)}${this.getCDNArtifact(objkt)}`;
    if (size === ObjktPreviewSizes.Display) return `${environment.cdnLink}${this.getTokenIdentifier(objkt, size)}${size}`;
    return `${environment.cdnLink}${objkt.fa_contract}/${objkt.token_id}${size}`;
  }

  getTokenIdentifier(objkt: ObjktModel, size?: ObjktPreviewSizes): string {
    if (size && size === ObjktPreviewSizes.Display) {
      if (this.isIPFS(objkt.display_uri)) {
        return this.removeQueryParamsAndSlash(objkt.display_uri.replace('ipfs://', ''));
      }
    } else {
      if (this.isIPFS(objkt.artifact_uri)) {
        return this.removeQueryParamsAndSlash(objkt.artifact_uri.replace('ipfs://', ''));
      } else if (this.isHEX(objkt.artifact_uri)) {
        return this.removeQueryParamsAndSlash(objkt.artifact_uri.replace('hex://', ''));
      }
    }
    return undefined;
  }

  getCDNPreviewUri(objkt: ObjktModel, size?: ObjktPreviewSizes): string {
    return this.getCDNUri(objkt, size) || 'assets/blank.jpeg';
  }

  getIPFSPreviewUri(objkt: ObjktModel): string {
    return (
      this.isIPFS(objkt.display_uri || objkt.thumbnail_uri || objkt.artifact_uri) &&
      this.getTokenUri(objkt.display_uri || objkt.thumbnail_uri || objkt.artifact_uri)
    );
  }

  private isNotIncludedCDN(objkt: ObjktModel): boolean {
    return [ObjktDisplayType.Domain].includes(objkt.displayType);
  }

  private addForwardSlashForCloudflare(value: string): string {
    return value?.replace(/([^\/])\?/, '$1/?');
  }

  private isHTTP(value: string): boolean {
    return value?.startsWith('http://') || value?.startsWith('https://');
  }

  private isIPFS(value: string): boolean {
    return value?.startsWith('ipfs://');
  }

  private isHEX(value: string): boolean {
    return value?.startsWith('hex://');
  }

  private isBase64(value: string): boolean {
    return value?.startsWith('data:');
  }

  private isBase64Image(value: string): boolean {
    return value?.startsWith('data:image/');
  }

  private getCDNArtifact(objkt: ObjktModel): string {
    if (objkt.mime) {
      const queryParams = this.getQueryParams(objkt.artifact_uri);
      if (objkt.mime === 'application/x-directory') return `/artifact/index.html${queryParams}`;
      if (mimeExt[objkt.mime]) return `/artifact${queryParams}`;
    }
    return ''; // Required empty string for getCDNUri
  }

  private getQueryParams(url) {
    try {
      return new URL(url).search || '';
    } catch (e) {
      return '';
    }
  }

  private removeQueryParamsAndSlash(url) {
    let cleanedUrl = url;
    if (cleanedUrl.indexOf('?') >= 0) {
      cleanedUrl = cleanedUrl.substring(0, cleanedUrl.indexOf('?'));
    }
    return cleanedUrl.replace(/\/$/, '');
  }
}
