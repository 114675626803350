<div class="wallet-panel">
  <div class="wallet-panel-header">
    <h2 class="wallet-panel-title">Balances</h2>
    <a (click)="action.emit()" class="wallets-link">Wrap/Unwrap</a>
  </div>
  <ng-template #loading>
    <div class="objkt-loader"></div>
  </ng-template>
  <div class="wallet-container balances" *ngIf="model$ | async as model; else loading">
    <div class="frow justify-between currency-group">
      <span class="currency">
        <img class="currency-logo" src="./assets/XTZ.svg" />
        XTZ
      </span>
      <span class="spacer"></span>
      <span class="balance" [class.none]="model.balanceXTZ <= 0">{{ model.balanceXTZ | tez | localisedNumber: '1.6-6' }}</span>
    </div>
    <div class="frow justify-between currency-group" *ngFor="let balance of model.balances">
      <span class="currency"><img class="currency-logo" [src]="balance.icon" /> {{ balance.symbol }}</span>
      <span class="spacer"></span>
      <span class="balance" [class.none]="balance.balance <= 0">
        {{ balance.balance | tez: balance.decimals | localisedNumber: '1.' + balance.decimals + '-' + balance.decimals }}
      </span>
    </div>
  </div>
</div>
