import {RpcClient} from '@taquito/rpc';
import {HttpBackendRetry} from './http-backend-retry';
import {environment} from '../../../environments/environment';

export class RpcClientRetry extends RpcClient {
  httpBackend: HttpBackendRetry;
  nodes: string[];

  constructor(chain: string = 'main') {
    const {rpcLoadBalancerEndpoint} = environment;
    const customHttpBackend = new HttpBackendRetry(rpcLoadBalancerEndpoint);
    super(rpcLoadBalancerEndpoint, chain, customHttpBackend);
    this.httpBackend = customHttpBackend;
  }

  // we'll let HttpBackendRetry  handle the urls
  protected createURL(path: string): string {
    return path;
  }
}
