import {map} from 'rxjs/operators';
import {Observable, Subscription} from 'rxjs';
import tippy, {Instance, Placement, Props} from 'tippy.js';
import {
  AfterViewInit,
  ChangeDetectorRef,
  Component,
  ElementRef,
  EventEmitter,
  HostListener,
  Input,
  OnDestroy,
  Output,
  TemplateRef,
  ViewChild,
} from '@angular/core';
import {NavigationEnd, Router} from '@angular/router';

import {ContractType, FaModel, GalleryModel, ObjktDetailsModel, ObjktModel, UserModel} from '../../types/types';
import {Fa2ApiService} from '../../services/api/fa2-api.service';
import {UserApiService} from '../../services/api/user-api.service';
import {GalleryApiService} from 'src/app/services/api/gallery-api.service';
import {ObjktApiService} from 'src/app/services/api/objkt-api.service';

@Component({
  selector: 'app-info-popover',
  templateUrl: './info-popover.component.html',
  styleUrls: ['./info-popover.component.scss'],
})
export class InfoPopoverComponent implements AfterViewInit, OnDestroy {
  @Input() text: string;
  @Input() userAddress: string;
  @Input() token: {fa_contract: string; token_id: string};
  @Input() collectionContract: string;
  @Input() galleryPk: number;
  @Input() custom: TemplateRef<any>;
  @Input() delay: [number, number];
  @Input() placement: Placement;
  @Input() offset: [number, number];
  @Input() hideOnClick: boolean;
  @Input() interactOnClick: boolean;
  @Input() interactiveBorder: number;
  @Input() interactiveDebounce: number;
  @Input() appendToParent: boolean;
  @Input() ignoreResize: boolean;

  @Output() opened = new EventEmitter<undefined>();
  @Output() closed = new EventEmitter<undefined>();

  @ViewChild('tippyContainer') tippyContainer: ElementRef;
  @ViewChild('textTemplate') textTemplate: ElementRef;
  @ViewChild('userTemplate') userTemplate: ElementRef;
  @ViewChild('collectionTemplate') collectionTemplate: ElementRef;
  @ViewChild('galleryTemplate') galleryTemplate: ElementRef;
  @ViewChild('tokenTemplate') tokenTemplate: ElementRef;
  @ViewChild('customTemplate') customTemplate: ElementRef;

  instance: Instance<Props>;
  eventSubscription: Subscription;

  model$: Observable<{
    user?: UserModel;
    collection?: FaModel;
    gallery?: GalleryModel;
    token?: ObjktModel;
  }>;

  customTemplateContext = {
    hide: () => {
      if (this.instance) this.instance.hide();
    },
  };

  constructor(
    private readonly router: Router,
    private readonly cdr: ChangeDetectorRef,
    private readonly fa2ApiService: Fa2ApiService,
    private readonly userApiService: UserApiService,
    private readonly galleryApiService: GalleryApiService,
    private readonly objktApiService: ObjktApiService,
  ) {}

  @HostListener('window:resize', ['$event'])
  onResize() {
    if (this.instance && !this.ignoreResize) this.instance.hide();
  }

  ngAfterViewInit() {
    this.run();
    this.bindRouteChange();
  }

  ngOnDestroy() {
    if (this.instance) {
      this.instance.destroy();
      this.instance = undefined;
    }

    if (this.eventSubscription) this.eventSubscription.unsubscribe();
  }

  getDelay(): [number, number] {
    if (this.delay) return this.delay;
    else if (this.userAddress || this.collectionContract) return [200, 0];
    else return [0, 0];
  }

  getMaxWidth(): string | number {
    if (this.custom) return 'none';
    return 350;
  }

  getAsset(collection: FaModel) {
    switch (collection.collection_type) {
      case ContractType.Open:
        return 'attention';
      case ContractType.Curated:
        return 'verified';
      case ContractType.Generative:
        return 'generative';
      case ContractType.User:
      default:
        return 'user';
    }
  }

  private run() {
    if (this.text) this.setup(this.textTemplate);
    else if (this.userAddress) {
      this.setup(this.userTemplate, () => {
        this.model$ = this.userApiService.getUser(this.userAddress).pipe(map(user => ({user})));
        this.cdr.markForCheck();
      });
    } else if (this.collectionContract) {
      this.setup(this.collectionTemplate, () => {
        this.model$ = this.fa2ApiService.getFa2(this.collectionContract).pipe(map(collection => ({collection})));
        this.cdr.markForCheck();
      });
    } else if (this.galleryPk) {
      this.setup(this.galleryTemplate, () => {
        this.model$ = this.galleryApiService.getGalleryByPk(this.galleryPk).pipe(map(gallery => ({gallery})));
        this.cdr.markForCheck();
      });
    } else if (this.token) {
      this.setup(this.tokenTemplate, () => {
        this.model$ = this.objktApiService
          .getObjktDetailed(this.token.token_id, this.token.fa_contract)
          .pipe(map(token => ({token: {...token, totalRoyalties: this.getTotalRoyalties(token)}})));
        this.cdr.markForCheck();
      });
    } else if (this.custom) {
      this.setup(this.customTemplate);
    }
  }

  private setup(template: ElementRef, loadCallback?: Function) {
    const onShow = (): false | void => this.opened.emit();
    const onHide = (): false | void => this.closed.emit();

    this.instance = tippy(this.tippyContainer.nativeElement as Element, {
      arrow: false,
      allowHTML: true,
      content: template.nativeElement,
      placement: this.placement ?? 'top',
      trigger: this.interactOnClick ? 'click' : 'mouseenter focus',
      hideOnClick: this.hideOnClick,
      interactive: !this.text,
      offset: this.offset,
      delay: this.getDelay(),
      maxWidth: this.getMaxWidth(),
      touch: this.getTouchEnabled(),
      interactiveBorder: this.interactiveBorder || 2,
      interactiveDebounce: this.interactiveDebounce || 0,
      appendTo: this.appendToParent ? 'parent' : document.body,
      animation: 'scale-subtle',
      ignoreAttributes: true,
      onTrigger() {
        if (typeof loadCallback === 'function') loadCallback();
      },
      onShow() {
        return onShow();
      },
      onHide() {
        return onHide();
      },
      plugins: [
        {
          name: 'hideOnEsc',
          defaultValue: true,
          fn({hide}) {
            function onKeyDown(event) {
              if (event.keyCode === 27) hide();
            }
            return {
              onShow() {
                document.addEventListener('keydown', onKeyDown);
              },
              onHide() {
                document.removeEventListener('keydown', onKeyDown);
              },
            };
          },
        },
      ],
    });
  }

  private bindRouteChange() {
    this.eventSubscription = this.router.events.subscribe(event => {
      if (event instanceof NavigationEnd && this.instance) {
        this.instance.hide();
      }
    });
  }

  private getTouchEnabled() {
    return !(this.collectionContract || this.userAddress);
  }

  private getRoyalty(royalty) {
    const deltaDecimals = 4 - royalty.decimals;
    return Math.round(royalty.amount * Math.pow(10, deltaDecimals));
  }

  private getTotalRoyalties(token: ObjktDetailsModel) {
    return (
      token.royalties.reduce((acc, value) => {
        const amount = this.getRoyalty(value);
        return acc + amount;
      }, 0) / 100
    );
  }
}
