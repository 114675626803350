import {Injectable} from '@angular/core';
import {
  GraphCmsGetCuratedTokenCategoriesGQL,
  GraphCmsGetCuratedTokensByCategoryGQL,
  GraphCmsGetCurationsGQL,
  GraphCmsGetFeaturedCategoryCurationGQL,
} from '../../../graphql/gen/graph-cms';
import {map, mergeMap, pluck, switchMap} from 'rxjs/operators';
import {IndexerToken_Bool_Exp} from '../../../graphql/gen/indexer';
import {ObjktApiService} from './objkt-api.service';
import {of, zip} from 'rxjs';
import {AuctionApiService} from './auction-api.service';
import {ObjktDetailsModel} from '../../types/types';
import {HttpClient} from '@angular/common/http';
import {environment} from 'src/environments/environment';

enum AuctionTypes {
  ENGLISH = 'ENGLISH',
  DUTCH = 'DUTCH',
}

export interface ICuratedTokensData {
  tokens: ObjktDetailsModel[];
  prettyTitle: string;
  tokenCategories: {category: string; name: string}[];
  curatedTokensConnection: {
    aggregate: {
      count: number;
    };
  };
}

export interface ICuratedTokens {
  curations: ICuration[];
  tokenFeed: ITokenFeed;
}

export interface ICuration {
  curatedTokens: ICuratedToken[];
  randomise: boolean;
  slug: string;
  title: string;
}

export interface ITokenFeed {
  curatedTokens: ICuratedToken[];
  title: string;
}

export interface ICuratedCategory {
  name: string;
  category: string;
  firstTokens: ICuratedToken[];
}

export interface ICuratedToken {
  contract: string;
  id: string;
  link?: string;
  tokenId: number;
}

@Injectable({
  providedIn: 'root',
})
export class CurationApiService {
  urlSuffix = !environment.production ? '?draft' : '';

  constructor(
    private readonly getCurationsGql: GraphCmsGetCurationsGQL,
    private readonly getCuratedTokensByCategoryGql: GraphCmsGetCuratedTokensByCategoryGQL,
    private readonly getCuratedTokenCategoriesGql: GraphCmsGetCuratedTokenCategoriesGQL,
    private readonly objktApiService: ObjktApiService,
    private readonly auctionApiService: AuctionApiService,
    private readonly getFeaturedCategoryCurationGql: GraphCmsGetFeaturedCategoryCurationGQL,
    private readonly http: HttpClient,
  ) {}

  getCurations() {
    return this.getCurationsGql.fetch({}, {}).pipe(
      map(res => res.data),
      mergeMap(data =>
        zip(
          this.fetchAndMergeObjktData(data.curations?.[0]),
          this.fetchAndMergeObjktData(data.tokenFeed),
          this.fetchAndMergeAuctionData(data.auctionCurations),
        ).pipe(map(([curations, tokenFeed, auctionCurations]) => ({curations, tokenFeed, auctionCurations}))),
      ),
    );
  }

  getCuratedTokensByCategory(category: string, skip: number = 0, limit?: number) {
    return this.getCuratedTokensByCategoryGql.fetch({category, skip, limit}, {fetchPolicy: 'no-cache'}).pipe(
      map(res => {
        const prettyTitle = res.data.tokenCategories?.find(c => c.category === category)?.name || 'Unknown';
        return {...res.data, prettyTitle};
      }),
      switchMap(data => this.fetchAndMergeObjktData(data)),
    );
  }

  getCuratedTokensCategories() {
    return this.getCuratedTokenCategoriesGql.fetch().pipe(map(res => res.data?.tokenCategories || null));
  }

  getFeaturedCategoryCuration() {
    return this.getFeaturedCategoryCurationGql.fetch().pipe(map(res => res.data?.featuredCategoryCuration?.tokenCategories || null));
  }

  private fetchAndMergeObjktData(data) {
    if (!data) {
      return of(null);
    }
    // @ts-ignore
    const conditions = data.curatedTokens.map<IndexerToken_Bool_Exp>(curatedToken => ({
      token_id: {_eq: curatedToken.tokenId.toString()},
      fa_contract: {_eq: curatedToken.contract},
    }));
    return this.objktApiService.getFeaturedCurations(conditions).pipe(
      map(resolvedTokens => {
        let tokens = data.curatedTokens
          .map(curatedToken => {
            const token = resolvedTokens.find(rt => rt.token_id === curatedToken.tokenId.toString() && rt.fa_contract === curatedToken.contract);
            if (token) {
              return {
                ...token,
                link: curatedToken.link,
              };
            }
          })
          .filter(token => !!token);

        if (data.randomise) {
          tokens = this.shuffle(tokens);
        }

        return {
          tokens,
          ...data,
        };
      }),
    );
  }

  private fetchAndMergeAuctionData(data) {
    if (!data?.[0]) {
      return of(null);
    }
    const englishAuctionHashes = data[0].curatedAuctions.filter(ca => ca.auctionType === AuctionTypes.ENGLISH).map(ca => ca.auctionId);
    const dutchAuctionHashes = data[0].curatedAuctions.filter(ca => ca.auctionType === AuctionTypes.DUTCH).map(ca => ca.auctionId);
    return this.auctionApiService.getCuratedAuctions(englishAuctionHashes, dutchAuctionHashes);
  }

  private shuffle(arr: any[]) {
    const randomizedArray = [];
    const array = [...arr];
    while (array.length !== 0) {
      const i = ~~(array.length * Math.random());
      randomizedArray.push(array[i]);
      array.splice(i, 1);
    }

    return randomizedArray;
  }

  getCategoryTokensHttp(category: string, offset: number) {
    return this.http
      .get<{tokenCategories: ICuratedCategory[]}>(`${environment.graphCmsCacheUrl}/curation/tokens/${category}/${offset || 0}${this.urlSuffix}`)
      .pipe(
        map(res => {
          const prettyTitle = res.tokenCategories?.find(c => c.category === category)?.name || 'Unknown';
          return {...res, prettyTitle};
        }),
        switchMap(data => this.fetchAndMergeObjktData(data)),
      );
  }

  getCategoriesHttp() {
    return this.http
      .get<{tokenCategories: ICuratedCategory[]}>(`${environment.graphCmsCacheUrl}/curation/categories${this.urlSuffix}`)
      .pipe(pluck('tokenCategories'));
  }

  getCurationsHttp() {
    return this.http.get<ICuratedTokens>(`${environment.graphCmsCacheUrl}/curation${this.urlSuffix}`);
  }
}
