import {Injectable} from '@angular/core';
import {BehaviorSubject} from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class DarkModeService {
  darkMode$ = new BehaviorSubject<boolean>(this.isDarkMode());

  constructor() {
    this.darkMode$.subscribe(value => {
      if (value) {
        document.body.classList.add('dark');
      } else {
        document.body.classList.remove('dark');
      }
    });
  }

  updatePreference(isDarkMode: boolean) {
    window.localStorage.setItem('darkMode', `${isDarkMode}`);
    this.darkMode$.next(isDarkMode);
  }

  isDarkMode() {
    if (window.localStorage.getItem('darkMode') !== null) {
      return this.isPreferenceDarkMode();
    } else {
      return this.isOsDarkMode();
    }
  }

  private isOsDarkMode() {
    return window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches;
  }

  private isPreferenceDarkMode() {
    try {
      return JSON.parse(window.localStorage.getItem('darkMode')) === true;
    } catch (e) {
      return false;
    }
  }
}
