import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {BatchAskModalComponent} from './batch-ask-modal.component';
import {ReactiveFormsModule} from '@angular/forms';
import {ModalModule} from '../modal.module';
import {ButtonsModule} from '../../buttons/buttons.module';

@NgModule({
  declarations: [BatchAskModalComponent],
  exports: [BatchAskModalComponent],
  imports: [CommonModule, ReactiveFormsModule, ModalModule, ButtonsModule],
})
export class BatchAskModalModule {}
