// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

import {CurrencyType} from 'src/app/types/types';

export const environment = {
  openEditionFee: 500,
  production: false,
  objktTreasury: 'tz1hFhmqKNB7hnHVHAFSk9wNqm7K9GgF2GDN',
  hicetnuncContract: 'KT1HbQepzV1nVGg8QVznG7z4RcHseD5kwqBn',
  teiaContract: 'KT1PHubm9HtyQEJ4BBpMTVomq6mhbfNZ9z5w',
  ubisoftContract: 'KT1TnVQhjxeNvLutGvzwZvYtC7vKRpwPWhc6',
  versumContract: 'KT1LjmAdYQCLBjwv4S2oFkEzyHVkomAf5MrW',
  versumMarketplaceContract: 'KT1GyRAJNdizF1nojQz62uGYkx8WFRUJm9X5',
  fxhashMarketplaceContract: 'KT1GbyoDi7H1sfXmimXpptZJuCdHMh66WS9u',
  fxhashMarketplaceContractV3: 'KT1M1NyU9X4usEimt2f3kDaijZnDMNBu42Ja',
  typedMarketplaceContract: 'KT1VoZeuBMJF6vxtLqEFMoc4no5VDG789D7z',
  scriboMarketplaceContract: 'KT19vw7kh7dzTRxFUZNWu39773baauzNWtzj',
  empropsMarketplaceContract: 'KT1AML4jv2jMLKESGTApRZVoga5V5cSAMD2E',
  bioduMarketplaceContract: 'KT1BvWGFENd4CXW5F3u4n31xKfJhmBGipoqF',
  bidou24MonoMarketplaceContract: 'KT1AHBvSo828QwscsjDjeUuep7MgApi8hXqA',
  bidou24MarketplaceContract: 'KT1QtnHR8p2hBjUhPRy9BCWgy7s7L578PA7N',
  akaswapMarketplaceV1Contract: 'KT1HGL8vx7DP4xETVikL4LUYvFxSV19DxdFN',
  akaswapMarketplaceV2Contract: 'KT1Qieo8hJWj2rHFfe8BRqnMHXYga9av89GJ',
  akaswapMarketplaceV2_1Contract: 'KT1Dn3sambs7KZGW88hH2obZeSzfmCmGvpFo',
  akaswapMarketplaceOfferContract: 'KT1J2C7BsYNnSjQsGoyrSXShhYGkrDDLVGDd',
  dogamiMarketplaceContract: 'KT1HnV6WJFLksLaLZRLck1TX4SbbcTXULX9t',
  wrappedTezContract: 'KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz',
  xtzIcon: 'https://assets.objkt.media/file/assets-002/currencies/XTZ.svg',
  currencies: [
    {
      name: 'Wrapped Tezos',
      displayName: 'otez',
      symbol: 'oXTZ',
      type: CurrencyType.fa12,
      path: 'ledger',
      decimals: 6,
      contract: 'KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz',
      icon: 'https://assets.objkt.media/file/assets-002/currencies/wXTZ.svg',
    },
  ],
  contracts: {
    marketplace: 'KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC',
    english: 'KT18p94vjkkHYY3nPmernmgVR7HdZFzE7NAk',
    dutch: 'KT1XXu88HkNzQRHNgAf7Mnq68LyS9MZJNoHP',
  },
  contractsOld: {
    english: 'KT1XjcRq5MLAzMKQ3UHsrue2SeU2NbxUrzmU',
    dutch: 'KT1QJ71jypKGgyTNtXjkCAYJZNhCKWiHuT2r',
    marketplace: 'KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq',
  },
  backendUrl: '/api',
  backendGraphql: '/api/graphql',
  indexerGraphql: 'https://private-api.objkt.com/v1/graphql',
  graphCmsGraphql:
    'https://api-eu-central-1.graphcms.com/v2/ckzwsfjw93ur901ys4akubz4w/master',
  graphCmsStage: 'DRAFT',
  graphCmsAuth:
    'eyJhbGciOiJSUzI1NiIsInR5cCI6IkpXVCIsImtpZCI6ImdjbXMtbWFpbi1wcm9kdWN0aW9uIn0.eyJ2ZXJzaW9uIjozLCJpYXQiOjE2NDU1MjkzMDUsImF1ZCI6WyJodHRwczovL2FwaS1ldS1jZW50cmFsLTEuZ3JhcGhjbXMuY29tL3YyL2NrendzZmp3OTN1cjkwMXlzNGFrdWJ6NHcvbWFzdGVyIiwiaHR0cHM6Ly9tYW5hZ2VtZW50LW5leHQuZ3JhcGhjbXMuY29tIl0sImlzcyI6Imh0dHBzOi8vbWFuYWdlbWVudC5ncmFwaGNtcy5jb20vIiwic3ViIjoiMmQ2MjA3ZTItMjFlMS00MmI1LTk1ZTktOTUzNDMxMWY1OGU0IiwianRpIjoiY2t6eTFudWdkNWJocTAxejhkNXUyMmNheCJ9.lLovXISQ6pcBt6qpRszXjIKvnaEpacBngdA5tWLUlJ4D4-UL7eO7TAde_H1dC8KTAFfsAdeHwmxh25j3DGnYaE5DvvXepXwt6jEFoaVLUxNhfCq444N-O_9yp9BBks4o3PYjWdBgdeD0UHjsdN2RE5BeFTgc1dgwVUs-SSVo2S_ZBSGKTKBiyEYoR8drIPtt0KE3Sw17G9G9qdc1xKqZFOyYrTZu0YW2Hz2Oq05o5MW9E8_F93Zw2aVs3WkHMraqP3ritFH5Lf_QXLJ9PrHOD2WusV-vDmmM4Lh6ACDew50zIF98NiwR9nZgtVO8eifnz5kebNQ0GeE96sy_ek4tQyqEKnShlTxBMzyAXBiYPe4beaL_jvZ5f9eglub2xmFb8Cq1zRq0mQUSLkRdSJzLdP8ZA1uWa7KrCvGf1G0z-fx0e7sxPxeZ0_Nd1n7TAs7I7cU4a9pWpVZTUeOBudPRFVG6rcgRUjgOCWE5VSK-TVB4b8A4FQcqwMPd6aL_L1JvSwz_1jjaNY1BN-JNdTOWqHAYAn6sYjdsPJgvXwWwgEjIIOm6VB0ZkB1pMVtQvu3gu71UlA2DvvC1HVuXIifman-OBTGpl9vzpQigjjZq2yAQBGEu3odsXwFSnDvNHeCR4xm5-_PJlVNCN6lPwPDBi2BSsTUdlWKNYqA3kXauUeM',
  graphCmsCacheUrl: 'https://back.objkt.com/api/v1/cms',
  rpcEndpoint: 'https://back.objkt.com/api/v1/rpc/nodes.json',
  rpcLoadBalancerEndpoint: 'https://rpc.objkt.com',
  tzktApi: 'https://api.tzkt.io/v1/',
  tzktWebSocket: 'https://api.tzkt.io/v1/events',
  backendWebSocket: 'wss://back.objkt.com/socket',
  network: 'mainnet',
  ipfsLink: 'https://ipfs.io/ipfs/',
  cdnLink: 'https://assets.objkt.media/file/assets-003/',
  mintingContract: 'KT1Aq4wWmVanpQhq4TTfjZXB5AjFpx15iQMM',
  artistCollectionsStorageId: 24157,
  interactivePreviewUrl: 'https://interactive-preview.objkt.media',
  minFloorPriceThreshold: 0.5,
  typesenseApiKey:
    'aBVqygD52AbM8eQbO2I1LjJpvpFZOLAlWrdl1vZizyp2Mzj9D6bAKNzKE6TP6uOP',
  searchConfig: {
    host: 'search.objkt.com',
    port: 443,
    protocol: 'https',
  },
  mintSocket: 'wss://back.objkt.com/mint',
  burnWallets: [
    'tz1burnburnburnburnburnburnburjAYjjX',
    'KT1CZMurPAjSfZqcn6LBUNUhG4byE6AJgDT6',
  ],
  legacyWallets: ['KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq'],
  // Contracts allowed to display tokens of unsafe mime type inline
  trustedContracts: [
    'KT1DKBvxiwDR7qazNuuxCxY2AaXnoytmDE7H',
    'KT18kkvmUoefkdok5mrjU6fxsm7xmumy1NEw',
  ],
  emptyArtUri: 'assets/blank.jpeg',
  appsignalKey: ''
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
