import { gql } from 'apollo-angular';
import { Injectable } from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  auction_status: any;
  bigint: any;
  collection_type: any;
  creator_verification_type: any;
  event_type: any;
  fa_type: any;
  flag_type: any;
  gallery_type: any;
  invitation_type: any;
  jsonb: any;
  ledger_type: any;
  marketplace_event_type: any;
  metadata_status: any;
  numeric: any;
  sales_stat_type: any;
  timestamp: any;
  timestamptz: any;
  token_type: any;
};




/** Boolean expression to compare columns of type "Boolean". All fields are combined with logical 'AND'. */
export type IndexerBoolean_Comparison_Exp = {
  _eq?: Maybe<Scalars['Boolean']>;
  _gt?: Maybe<Scalars['Boolean']>;
  _gte?: Maybe<Scalars['Boolean']>;
  _in?: Maybe<Array<Scalars['Boolean']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Boolean']>;
  _lte?: Maybe<Scalars['Boolean']>;
  _neq?: Maybe<Scalars['Boolean']>;
  _nin?: Maybe<Array<Scalars['Boolean']>>;
};

/** Boolean expression to compare columns of type "Int". All fields are combined with logical 'AND'. */
export type IndexerInt_Comparison_Exp = {
  _eq?: Maybe<Scalars['Int']>;
  _gt?: Maybe<Scalars['Int']>;
  _gte?: Maybe<Scalars['Int']>;
  _in?: Maybe<Array<Scalars['Int']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['Int']>;
  _lte?: Maybe<Scalars['Int']>;
  _neq?: Maybe<Scalars['Int']>;
  _nin?: Maybe<Array<Scalars['Int']>>;
};

/** Boolean expression to compare columns of type "String". All fields are combined with logical 'AND'. */
export type IndexerString_Comparison_Exp = {
  _eq?: Maybe<Scalars['String']>;
  _gt?: Maybe<Scalars['String']>;
  _gte?: Maybe<Scalars['String']>;
  /** does the column match the given case-insensitive pattern */
  _ilike?: Maybe<Scalars['String']>;
  _in?: Maybe<Array<Scalars['String']>>;
  /** does the column match the given POSIX regular expression, case insensitive */
  _iregex?: Maybe<Scalars['String']>;
  _is_null?: Maybe<Scalars['Boolean']>;
  /** does the column match the given pattern */
  _like?: Maybe<Scalars['String']>;
  _lt?: Maybe<Scalars['String']>;
  _lte?: Maybe<Scalars['String']>;
  _neq?: Maybe<Scalars['String']>;
  /** does the column NOT match the given case-insensitive pattern */
  _nilike?: Maybe<Scalars['String']>;
  _nin?: Maybe<Array<Scalars['String']>>;
  /** does the column NOT match the given POSIX regular expression, case insensitive */
  _niregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given pattern */
  _nlike?: Maybe<Scalars['String']>;
  /** does the column NOT match the given POSIX regular expression, case sensitive */
  _nregex?: Maybe<Scalars['String']>;
  /** does the column NOT match the given SQL regular expression */
  _nsimilar?: Maybe<Scalars['String']>;
  /** does the column match the given POSIX regular expression, case sensitive */
  _regex?: Maybe<Scalars['String']>;
  /** does the column match the given SQL regular expression */
  _similar?: Maybe<Scalars['String']>;
};

/** columns and relationships of "attribute" */
export type IndexerAttribute = {
  __typename?: 'attribute';
  /** An array relationship */
  attribute_counts: Array<IndexerFa2_Attribute_Count>;
  /** An aggregate relationship */
  attribute_counts_aggregate: IndexerFa2_Attribute_Count_Aggregate;
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  tokens: Array<IndexerToken_Attribute>;
  /** An aggregate relationship */
  tokens_aggregate: IndexerToken_Attribute_Aggregate;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};


/** columns and relationships of "attribute" */
export type IndexerAttributeAttribute_CountsArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


/** columns and relationships of "attribute" */
export type IndexerAttributeAttribute_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


/** columns and relationships of "attribute" */
export type IndexerAttributeTokensArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


/** columns and relationships of "attribute" */
export type IndexerAttributeTokens_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};

/** aggregated selection of "attribute" */
export type IndexerAttribute_Aggregate = {
  __typename?: 'attribute_aggregate';
  aggregate?: Maybe<IndexerAttribute_Aggregate_Fields>;
  nodes: Array<IndexerAttribute>;
};

/** aggregate fields of "attribute" */
export type IndexerAttribute_Aggregate_Fields = {
  __typename?: 'attribute_aggregate_fields';
  avg?: Maybe<IndexerAttribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerAttribute_Max_Fields>;
  min?: Maybe<IndexerAttribute_Min_Fields>;
  stddev?: Maybe<IndexerAttribute_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerAttribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerAttribute_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerAttribute_Sum_Fields>;
  var_pop?: Maybe<IndexerAttribute_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerAttribute_Var_Samp_Fields>;
  variance?: Maybe<IndexerAttribute_Variance_Fields>;
};


/** aggregate fields of "attribute" */
export type IndexerAttribute_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerAttribute_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerAttribute_Avg_Fields = {
  __typename?: 'attribute_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "attribute". All fields are combined with a logical 'AND'. */
export type IndexerAttribute_Bool_Exp = {
  _and?: Maybe<Array<IndexerAttribute_Bool_Exp>>;
  _not?: Maybe<IndexerAttribute_Bool_Exp>;
  _or?: Maybe<Array<IndexerAttribute_Bool_Exp>>;
  attribute_counts?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
  attribute_counts_aggregate?: Maybe<IndexerFa2_Attribute_Count_Aggregate_Bool_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  tokens?: Maybe<IndexerToken_Attribute_Bool_Exp>;
  tokens_aggregate?: Maybe<IndexerToken_Attribute_Aggregate_Bool_Exp>;
  type?: Maybe<IndexerString_Comparison_Exp>;
  value?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerAttribute_Max_Fields = {
  __typename?: 'attribute_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IndexerAttribute_Min_Fields = {
  __typename?: 'attribute_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "attribute". */
export type IndexerAttribute_Order_By = {
  attribute_counts_aggregate?: Maybe<IndexerFa2_Attribute_Count_Aggregate_Order_By>;
  id?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  tokens_aggregate?: Maybe<IndexerToken_Attribute_Aggregate_Order_By>;
  type?: Maybe<IndexerOrder_By>;
  value?: Maybe<IndexerOrder_By>;
};

/** select columns of table "attribute" */
export enum IndexerAttribute_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  Type = 'type',
  /** column name */
  Value = 'value'
}

/** aggregate stddev on columns */
export type IndexerAttribute_Stddev_Fields = {
  __typename?: 'attribute_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerAttribute_Stddev_Pop_Fields = {
  __typename?: 'attribute_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerAttribute_Stddev_Samp_Fields = {
  __typename?: 'attribute_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "attribute" */
export type IndexerAttribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerAttribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerAttribute_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['String']>;
  value?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerAttribute_Sum_Fields = {
  __typename?: 'attribute_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerAttribute_Var_Pop_Fields = {
  __typename?: 'attribute_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerAttribute_Var_Samp_Fields = {
  __typename?: 'attribute_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerAttribute_Variance_Fields = {
  __typename?: 'attribute_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "auction_status". All fields are combined with logical 'AND'. */
export type IndexerAuction_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['auction_status']>;
  _gt?: Maybe<Scalars['auction_status']>;
  _gte?: Maybe<Scalars['auction_status']>;
  _in?: Maybe<Array<Scalars['auction_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['auction_status']>;
  _lte?: Maybe<Scalars['auction_status']>;
  _neq?: Maybe<Scalars['auction_status']>;
  _nin?: Maybe<Array<Scalars['auction_status']>>;
};


/** Boolean expression to compare columns of type "bigint". All fields are combined with logical 'AND'. */
export type IndexerBigint_Comparison_Exp = {
  _eq?: Maybe<Scalars['bigint']>;
  _gt?: Maybe<Scalars['bigint']>;
  _gte?: Maybe<Scalars['bigint']>;
  _in?: Maybe<Array<Scalars['bigint']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['bigint']>;
  _lte?: Maybe<Scalars['bigint']>;
  _neq?: Maybe<Scalars['bigint']>;
  _nin?: Maybe<Array<Scalars['bigint']>>;
};


/** Boolean expression to compare columns of type "collection_type". All fields are combined with logical 'AND'. */
export type IndexerCollection_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['collection_type']>;
  _gt?: Maybe<Scalars['collection_type']>;
  _gte?: Maybe<Scalars['collection_type']>;
  _in?: Maybe<Array<Scalars['collection_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['collection_type']>;
  _lte?: Maybe<Scalars['collection_type']>;
  _neq?: Maybe<Scalars['collection_type']>;
  _nin?: Maybe<Array<Scalars['collection_type']>>;
};


/** Boolean expression to compare columns of type "creator_verification_type". All fields are combined with logical 'AND'. */
export type IndexerCreator_Verification_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['creator_verification_type']>;
  _gt?: Maybe<Scalars['creator_verification_type']>;
  _gte?: Maybe<Scalars['creator_verification_type']>;
  _in?: Maybe<Array<Scalars['creator_verification_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['creator_verification_type']>;
  _lte?: Maybe<Scalars['creator_verification_type']>;
  _neq?: Maybe<Scalars['creator_verification_type']>;
  _nin?: Maybe<Array<Scalars['creator_verification_type']>>;
};

/** columns and relationships of "currency" */
export type IndexerCurrency = {
  __typename?: 'currency';
  decimals?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['token_type']>;
};

/** aggregated selection of "currency" */
export type IndexerCurrency_Aggregate = {
  __typename?: 'currency_aggregate';
  aggregate?: Maybe<IndexerCurrency_Aggregate_Fields>;
  nodes: Array<IndexerCurrency>;
};

/** aggregate fields of "currency" */
export type IndexerCurrency_Aggregate_Fields = {
  __typename?: 'currency_aggregate_fields';
  avg?: Maybe<IndexerCurrency_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerCurrency_Max_Fields>;
  min?: Maybe<IndexerCurrency_Min_Fields>;
  stddev?: Maybe<IndexerCurrency_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerCurrency_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerCurrency_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerCurrency_Sum_Fields>;
  var_pop?: Maybe<IndexerCurrency_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerCurrency_Var_Samp_Fields>;
  variance?: Maybe<IndexerCurrency_Variance_Fields>;
};


/** aggregate fields of "currency" */
export type IndexerCurrency_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerCurrency_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerCurrency_Avg_Fields = {
  __typename?: 'currency_avg_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "currency". All fields are combined with a logical 'AND'. */
export type IndexerCurrency_Bool_Exp = {
  _and?: Maybe<Array<IndexerCurrency_Bool_Exp>>;
  _not?: Maybe<IndexerCurrency_Bool_Exp>;
  _or?: Maybe<Array<IndexerCurrency_Bool_Exp>>;
  decimals?: Maybe<IndexerInt_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  type?: Maybe<IndexerToken_Type_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerCurrency_Max_Fields = {
  __typename?: 'currency_max_fields';
  decimals?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['token_type']>;
};

/** aggregate min on columns */
export type IndexerCurrency_Min_Fields = {
  __typename?: 'currency_min_fields';
  decimals?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['token_type']>;
};

/** Ordering options when selecting data from "currency". */
export type IndexerCurrency_Order_By = {
  decimals?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
};

/** select columns of table "currency" */
export enum IndexerCurrency_Select_Column {
  /** column name */
  Decimals = 'decimals',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type IndexerCurrency_Stddev_Fields = {
  __typename?: 'currency_stddev_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerCurrency_Stddev_Pop_Fields = {
  __typename?: 'currency_stddev_pop_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerCurrency_Stddev_Samp_Fields = {
  __typename?: 'currency_stddev_samp_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "currency" */
export type IndexerCurrency_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerCurrency_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerCurrency_Stream_Cursor_Value_Input = {
  decimals?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  type?: Maybe<Scalars['token_type']>;
};

/** aggregate sum on columns */
export type IndexerCurrency_Sum_Fields = {
  __typename?: 'currency_sum_fields';
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerCurrency_Var_Pop_Fields = {
  __typename?: 'currency_var_pop_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerCurrency_Var_Samp_Fields = {
  __typename?: 'currency_var_samp_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerCurrency_Variance_Fields = {
  __typename?: 'currency_variance_fields';
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** ordering argument of a cursor */
export enum IndexerCursor_Ordering {
  /** ascending ordering of the cursor */
  Asc = 'ASC',
  /** descending ordering of the cursor */
  Desc = 'DESC'
}

/** columns and relationships of "dutch_auction" */
export type IndexerDutch_Auction = {
  __typename?: 'dutch_auction';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  /** An array relationship */
  sales: Array<IndexerDutch_Auction_Sale>;
  /** An aggregate relationship */
  sales_aggregate: IndexerDutch_Auction_Sale_Aggregate;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties: Scalars['Boolean'];
};


/** columns and relationships of "dutch_auction" */
export type IndexerDutch_AuctionSalesArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "dutch_auction" */
export type IndexerDutch_AuctionSales_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "dutch_auction" */
export type IndexerDutch_AuctionSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "dutch_auction_active" */
export type IndexerDutch_Auction_Active = {
  __typename?: 'dutch_auction_active';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace?: Maybe<IndexerMarketplace_Contract>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  /** An array relationship */
  sales: Array<IndexerDutch_Auction_Sale>;
  /** An aggregate relationship */
  sales_aggregate: IndexerDutch_Auction_Sale_Aggregate;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "dutch_auction_active" */
export type IndexerDutch_Auction_ActiveSalesArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "dutch_auction_active" */
export type IndexerDutch_Auction_ActiveSales_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "dutch_auction_active" */
export type IndexerDutch_Auction_ActiveSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Aggregate = {
  __typename?: 'dutch_auction_active_aggregate';
  aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Fields>;
  nodes: Array<IndexerDutch_Auction_Active>;
};

export type IndexerDutch_Auction_Active_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerDutch_Auction_Active_Aggregate_Bool_Exp_Count>;
};

export type IndexerDutch_Auction_Active_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Aggregate_Fields = {
  __typename?: 'dutch_auction_active_aggregate_fields';
  avg?: Maybe<IndexerDutch_Auction_Active_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerDutch_Auction_Active_Max_Fields>;
  min?: Maybe<IndexerDutch_Auction_Active_Min_Fields>;
  stddev?: Maybe<IndexerDutch_Auction_Active_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerDutch_Auction_Active_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerDutch_Auction_Active_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerDutch_Auction_Active_Sum_Fields>;
  var_pop?: Maybe<IndexerDutch_Auction_Active_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerDutch_Auction_Active_Var_Samp_Fields>;
  variance?: Maybe<IndexerDutch_Auction_Active_Variance_Fields>;
};


/** aggregate fields of "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Aggregate_Order_By = {
  avg?: Maybe<IndexerDutch_Auction_Active_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerDutch_Auction_Active_Max_Order_By>;
  min?: Maybe<IndexerDutch_Auction_Active_Min_Order_By>;
  stddev?: Maybe<IndexerDutch_Auction_Active_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerDutch_Auction_Active_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerDutch_Auction_Active_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerDutch_Auction_Active_Sum_Order_By>;
  var_pop?: Maybe<IndexerDutch_Auction_Active_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerDutch_Auction_Active_Var_Samp_Order_By>;
  variance?: Maybe<IndexerDutch_Auction_Active_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerDutch_Auction_Active_Avg_Fields = {
  __typename?: 'dutch_auction_active_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "dutch_auction_active". All fields are combined with a logical 'AND'. */
export type IndexerDutch_Auction_Active_Bool_Exp = {
  _and?: Maybe<Array<IndexerDutch_Auction_Active_Bool_Exp>>;
  _not?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
  _or?: Maybe<Array<IndexerDutch_Auction_Active_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  amount_left?: Maybe<IndexerInt_Comparison_Exp>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  end_price?: Maybe<IndexerBigint_Comparison_Exp>;
  end_price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  end_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  hash?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  sales?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
  sales_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Bool_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  start_price?: Maybe<IndexerBigint_Comparison_Exp>;
  start_price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  start_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerDutch_Auction_Active_Max_Fields = {
  __typename?: 'dutch_auction_active_max_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerDutch_Auction_Active_Min_Fields = {
  __typename?: 'dutch_auction_active_min_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "dutch_auction_active". */
export type IndexerDutch_Auction_Active_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  sales_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Order_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** select columns of table "dutch_auction_active" */
export enum IndexerDutch_Auction_Active_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountLeft = 'amount_left',
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EndPrice = 'end_price',
  /** column name */
  EndPriceXtz = 'end_price_xtz',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  StartPrice = 'start_price',
  /** column name */
  StartPriceXtz = 'start_price_xtz',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp'
}

/** aggregate stddev on columns */
export type IndexerDutch_Auction_Active_Stddev_Fields = {
  __typename?: 'dutch_auction_active_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerDutch_Auction_Active_Stddev_Pop_Fields = {
  __typename?: 'dutch_auction_active_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerDutch_Auction_Active_Stddev_Samp_Fields = {
  __typename?: 'dutch_auction_active_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerDutch_Auction_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerDutch_Auction_Active_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IndexerDutch_Auction_Active_Sum_Fields = {
  __typename?: 'dutch_auction_active_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerDutch_Auction_Active_Var_Pop_Fields = {
  __typename?: 'dutch_auction_active_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerDutch_Auction_Active_Var_Samp_Fields = {
  __typename?: 'dutch_auction_active_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerDutch_Auction_Active_Variance_Fields = {
  __typename?: 'dutch_auction_active_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dutch_auction_active" */
export type IndexerDutch_Auction_Active_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregated selection of "dutch_auction" */
export type IndexerDutch_Auction_Aggregate = {
  __typename?: 'dutch_auction_aggregate';
  aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Fields>;
  nodes: Array<IndexerDutch_Auction>;
};

export type IndexerDutch_Auction_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerDutch_Auction_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerDutch_Auction_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerDutch_Auction_Aggregate_Bool_Exp_Count>;
};

export type IndexerDutch_Auction_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerDutch_Auction_Select_Column_Dutch_Auction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerDutch_Auction_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerDutch_Auction_Select_Column_Dutch_Auction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerDutch_Auction_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "dutch_auction" */
export type IndexerDutch_Auction_Aggregate_Fields = {
  __typename?: 'dutch_auction_aggregate_fields';
  avg?: Maybe<IndexerDutch_Auction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerDutch_Auction_Max_Fields>;
  min?: Maybe<IndexerDutch_Auction_Min_Fields>;
  stddev?: Maybe<IndexerDutch_Auction_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerDutch_Auction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerDutch_Auction_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerDutch_Auction_Sum_Fields>;
  var_pop?: Maybe<IndexerDutch_Auction_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerDutch_Auction_Var_Samp_Fields>;
  variance?: Maybe<IndexerDutch_Auction_Variance_Fields>;
};


/** aggregate fields of "dutch_auction" */
export type IndexerDutch_Auction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dutch_auction" */
export type IndexerDutch_Auction_Aggregate_Order_By = {
  avg?: Maybe<IndexerDutch_Auction_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerDutch_Auction_Max_Order_By>;
  min?: Maybe<IndexerDutch_Auction_Min_Order_By>;
  stddev?: Maybe<IndexerDutch_Auction_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerDutch_Auction_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerDutch_Auction_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerDutch_Auction_Sum_Order_By>;
  var_pop?: Maybe<IndexerDutch_Auction_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerDutch_Auction_Var_Samp_Order_By>;
  variance?: Maybe<IndexerDutch_Auction_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerDutch_Auction_Avg_Fields = {
  __typename?: 'dutch_auction_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "dutch_auction". All fields are combined with a logical 'AND'. */
export type IndexerDutch_Auction_Bool_Exp = {
  _and?: Maybe<Array<IndexerDutch_Auction_Bool_Exp>>;
  _not?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  _or?: Maybe<Array<IndexerDutch_Auction_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  amount_left?: Maybe<IndexerInt_Comparison_Exp>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  end_price?: Maybe<IndexerBigint_Comparison_Exp>;
  end_price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  end_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  hash?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  sales?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
  sales_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Bool_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  start_price?: Maybe<IndexerBigint_Comparison_Exp>;
  start_price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  start_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  status?: Maybe<IndexerAuction_Status_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  valid_royalties?: Maybe<IndexerBoolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerDutch_Auction_Max_Fields = {
  __typename?: 'dutch_auction_max_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerDutch_Auction_Min_Fields = {
  __typename?: 'dutch_auction_min_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "dutch_auction". */
export type IndexerDutch_Auction_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  sales_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Order_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
  valid_royalties?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale = {
  __typename?: 'dutch_auction_sale';
  amount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  buyer?: Maybe<IndexerHolder>;
  buyer_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  dutch_auction?: Maybe<IndexerDutch_Auction>;
  dutch_auction_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  sender?: Maybe<IndexerHolder>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Aggregate = {
  __typename?: 'dutch_auction_sale_aggregate';
  aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Fields>;
  nodes: Array<IndexerDutch_Auction_Sale>;
};

export type IndexerDutch_Auction_Sale_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Bool_Exp_Count>;
};

export type IndexerDutch_Auction_Sale_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Aggregate_Fields = {
  __typename?: 'dutch_auction_sale_aggregate_fields';
  avg?: Maybe<IndexerDutch_Auction_Sale_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerDutch_Auction_Sale_Max_Fields>;
  min?: Maybe<IndexerDutch_Auction_Sale_Min_Fields>;
  stddev?: Maybe<IndexerDutch_Auction_Sale_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerDutch_Auction_Sale_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerDutch_Auction_Sale_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerDutch_Auction_Sale_Sum_Fields>;
  var_pop?: Maybe<IndexerDutch_Auction_Sale_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerDutch_Auction_Sale_Var_Samp_Fields>;
  variance?: Maybe<IndexerDutch_Auction_Sale_Variance_Fields>;
};


/** aggregate fields of "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Aggregate_Order_By = {
  avg?: Maybe<IndexerDutch_Auction_Sale_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerDutch_Auction_Sale_Max_Order_By>;
  min?: Maybe<IndexerDutch_Auction_Sale_Min_Order_By>;
  stddev?: Maybe<IndexerDutch_Auction_Sale_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerDutch_Auction_Sale_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerDutch_Auction_Sale_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerDutch_Auction_Sale_Sum_Order_By>;
  var_pop?: Maybe<IndexerDutch_Auction_Sale_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerDutch_Auction_Sale_Var_Samp_Order_By>;
  variance?: Maybe<IndexerDutch_Auction_Sale_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerDutch_Auction_Sale_Avg_Fields = {
  __typename?: 'dutch_auction_sale_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "dutch_auction_sale". All fields are combined with a logical 'AND'. */
export type IndexerDutch_Auction_Sale_Bool_Exp = {
  _and?: Maybe<Array<IndexerDutch_Auction_Sale_Bool_Exp>>;
  _not?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
  _or?: Maybe<Array<IndexerDutch_Auction_Sale_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  buyer?: Maybe<IndexerHolder_Bool_Exp>;
  buyer_address?: Maybe<IndexerString_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  dutch_auction?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  dutch_auction_id?: Maybe<IndexerBigint_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  sender?: Maybe<IndexerHolder_Bool_Exp>;
  sender_address?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerDutch_Auction_Sale_Max_Fields = {
  __typename?: 'dutch_auction_sale_max_fields';
  amount?: Maybe<Scalars['Int']>;
  buyer_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  dutch_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  sender_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerDutch_Auction_Sale_Min_Fields = {
  __typename?: 'dutch_auction_sale_min_fields';
  amount?: Maybe<Scalars['Int']>;
  buyer_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  dutch_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  sender_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "dutch_auction_sale". */
export type IndexerDutch_Auction_Sale_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  buyer?: Maybe<IndexerHolder_Order_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction?: Maybe<IndexerDutch_Auction_Order_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  sender?: Maybe<IndexerHolder_Order_By>;
  sender_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "dutch_auction_sale" */
export enum IndexerDutch_Auction_Sale_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BuyerAddress = 'buyer_address',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  DutchAuctionId = 'dutch_auction_id',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  SenderAddress = 'sender_address',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerDutch_Auction_Sale_Stddev_Fields = {
  __typename?: 'dutch_auction_sale_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerDutch_Auction_Sale_Stddev_Pop_Fields = {
  __typename?: 'dutch_auction_sale_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerDutch_Auction_Sale_Stddev_Samp_Fields = {
  __typename?: 'dutch_auction_sale_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerDutch_Auction_Sale_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerDutch_Auction_Sale_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  buyer_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  dutch_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerDutch_Auction_Sale_Sum_Fields = {
  __typename?: 'dutch_auction_sale_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  currency_id?: Maybe<Scalars['bigint']>;
  dutch_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerDutch_Auction_Sale_Var_Pop_Fields = {
  __typename?: 'dutch_auction_sale_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerDutch_Auction_Sale_Var_Samp_Fields = {
  __typename?: 'dutch_auction_sale_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerDutch_Auction_Sale_Variance_Fields = {
  __typename?: 'dutch_auction_sale_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  dutch_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dutch_auction_sale" */
export type IndexerDutch_Auction_Sale_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  dutch_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "dutch_auction" */
export enum IndexerDutch_Auction_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountLeft = 'amount_left',
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EndPrice = 'end_price',
  /** column name */
  EndPriceXtz = 'end_price_xtz',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Hash = 'hash',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  StartPrice = 'start_price',
  /** column name */
  StartPriceXtz = 'start_price_xtz',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp',
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "dutch_auction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "dutch_auction" */
export enum IndexerDutch_Auction_Select_Column_Dutch_Auction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "dutch_auction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "dutch_auction" */
export enum IndexerDutch_Auction_Select_Column_Dutch_Auction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** aggregate stddev on columns */
export type IndexerDutch_Auction_Stddev_Fields = {
  __typename?: 'dutch_auction_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerDutch_Auction_Stddev_Pop_Fields = {
  __typename?: 'dutch_auction_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerDutch_Auction_Stddev_Samp_Fields = {
  __typename?: 'dutch_auction_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "dutch_auction" */
export type IndexerDutch_Auction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerDutch_Auction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerDutch_Auction_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IndexerDutch_Auction_Sum_Fields = {
  __typename?: 'dutch_auction_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  end_price_xtz?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  start_price?: Maybe<Scalars['bigint']>;
  start_price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerDutch_Auction_Var_Pop_Fields = {
  __typename?: 'dutch_auction_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerDutch_Auction_Var_Samp_Fields = {
  __typename?: 'dutch_auction_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerDutch_Auction_Variance_Fields = {
  __typename?: 'dutch_auction_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  end_price_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  start_price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "dutch_auction" */
export type IndexerDutch_Auction_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  end_price_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  start_price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "english_auction" */
export type IndexerEnglish_Auction = {
  __typename?: 'english_auction';
  /** An array relationship */
  bids: Array<IndexerEnglish_Auction_Bid>;
  /** An aggregate relationship */
  bids_aggregate: IndexerEnglish_Auction_Bid_Aggregate;
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  highest_bidder?: Maybe<IndexerHolder>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  referral_bonus?: Maybe<Scalars['Int']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties: Scalars['Boolean'];
};


/** columns and relationships of "english_auction" */
export type IndexerEnglish_AuctionBidsArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


/** columns and relationships of "english_auction" */
export type IndexerEnglish_AuctionBids_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


/** columns and relationships of "english_auction" */
export type IndexerEnglish_AuctionSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "english_auction_active" */
export type IndexerEnglish_Auction_Active = {
  __typename?: 'english_auction_active';
  /** An array relationship */
  bids: Array<IndexerEnglish_Auction_Bid>;
  /** An aggregate relationship */
  bids_aggregate: IndexerEnglish_Auction_Bid_Aggregate;
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  highest_bidder?: Maybe<IndexerHolder>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace?: Maybe<IndexerMarketplace_Contract>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "english_auction_active" */
export type IndexerEnglish_Auction_ActiveBidsArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


/** columns and relationships of "english_auction_active" */
export type IndexerEnglish_Auction_ActiveBids_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


/** columns and relationships of "english_auction_active" */
export type IndexerEnglish_Auction_ActiveSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "english_auction_active" */
export type IndexerEnglish_Auction_Active_Aggregate = {
  __typename?: 'english_auction_active_aggregate';
  aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Fields>;
  nodes: Array<IndexerEnglish_Auction_Active>;
};

export type IndexerEnglish_Auction_Active_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Bool_Exp_Count>;
};

export type IndexerEnglish_Auction_Active_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "english_auction_active" */
export type IndexerEnglish_Auction_Active_Aggregate_Fields = {
  __typename?: 'english_auction_active_aggregate_fields';
  avg?: Maybe<IndexerEnglish_Auction_Active_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerEnglish_Auction_Active_Max_Fields>;
  min?: Maybe<IndexerEnglish_Auction_Active_Min_Fields>;
  stddev?: Maybe<IndexerEnglish_Auction_Active_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerEnglish_Auction_Active_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerEnglish_Auction_Active_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerEnglish_Auction_Active_Sum_Fields>;
  var_pop?: Maybe<IndexerEnglish_Auction_Active_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerEnglish_Auction_Active_Var_Samp_Fields>;
  variance?: Maybe<IndexerEnglish_Auction_Active_Variance_Fields>;
};


/** aggregate fields of "english_auction_active" */
export type IndexerEnglish_Auction_Active_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Aggregate_Order_By = {
  avg?: Maybe<IndexerEnglish_Auction_Active_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerEnglish_Auction_Active_Max_Order_By>;
  min?: Maybe<IndexerEnglish_Auction_Active_Min_Order_By>;
  stddev?: Maybe<IndexerEnglish_Auction_Active_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerEnglish_Auction_Active_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerEnglish_Auction_Active_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerEnglish_Auction_Active_Sum_Order_By>;
  var_pop?: Maybe<IndexerEnglish_Auction_Active_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerEnglish_Auction_Active_Var_Samp_Order_By>;
  variance?: Maybe<IndexerEnglish_Auction_Active_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerEnglish_Auction_Active_Avg_Fields = {
  __typename?: 'english_auction_active_avg_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Avg_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "english_auction_active". All fields are combined with a logical 'AND'. */
export type IndexerEnglish_Auction_Active_Bool_Exp = {
  _and?: Maybe<Array<IndexerEnglish_Auction_Active_Bool_Exp>>;
  _not?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
  _or?: Maybe<Array<IndexerEnglish_Auction_Active_Bool_Exp>>;
  bids?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
  bids_aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Bool_Exp>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  end_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  extension_time?: Maybe<IndexerInt_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  hash?: Maybe<IndexerString_Comparison_Exp>;
  highest_bid?: Maybe<IndexerBigint_Comparison_Exp>;
  highest_bid_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  highest_bidder?: Maybe<IndexerHolder_Bool_Exp>;
  highest_bidder_address?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price_increment?: Maybe<IndexerBigint_Comparison_Exp>;
  price_increment_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  reserve?: Maybe<IndexerBigint_Comparison_Exp>;
  reserve_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  start_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerEnglish_Auction_Active_Max_Fields = {
  __typename?: 'english_auction_active_max_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Max_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  highest_bidder_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerEnglish_Auction_Active_Min_Fields = {
  __typename?: 'english_auction_active_min_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Min_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  highest_bidder_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "english_auction_active". */
export type IndexerEnglish_Auction_Active_Order_By = {
  bids_aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Order_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  highest_bidder?: Maybe<IndexerHolder_Order_By>;
  highest_bidder_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** select columns of table "english_auction_active" */
export enum IndexerEnglish_Auction_Active_Select_Column {
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  ExtensionTime = 'extension_time',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Hash = 'hash',
  /** column name */
  HighestBid = 'highest_bid',
  /** column name */
  HighestBidXtz = 'highest_bid_xtz',
  /** column name */
  HighestBidderAddress = 'highest_bidder_address',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  PriceIncrement = 'price_increment',
  /** column name */
  PriceIncrementXtz = 'price_increment_xtz',
  /** column name */
  Reserve = 'reserve',
  /** column name */
  ReserveXtz = 'reserve_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp'
}

/** aggregate stddev on columns */
export type IndexerEnglish_Auction_Active_Stddev_Fields = {
  __typename?: 'english_auction_active_stddev_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Stddev_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerEnglish_Auction_Active_Stddev_Pop_Fields = {
  __typename?: 'english_auction_active_stddev_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Stddev_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerEnglish_Auction_Active_Stddev_Samp_Fields = {
  __typename?: 'english_auction_active_stddev_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Stddev_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerEnglish_Auction_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerEnglish_Auction_Active_Stream_Cursor_Value_Input = {
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IndexerEnglish_Auction_Active_Sum_Fields = {
  __typename?: 'english_auction_active_sum_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  extension_time?: Maybe<Scalars['Int']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Sum_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerEnglish_Auction_Active_Var_Pop_Fields = {
  __typename?: 'english_auction_active_var_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Var_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerEnglish_Auction_Active_Var_Samp_Fields = {
  __typename?: 'english_auction_active_var_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Var_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerEnglish_Auction_Active_Variance_Fields = {
  __typename?: 'english_auction_active_variance_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "english_auction_active" */
export type IndexerEnglish_Auction_Active_Variance_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregated selection of "english_auction" */
export type IndexerEnglish_Auction_Aggregate = {
  __typename?: 'english_auction_aggregate';
  aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Fields>;
  nodes: Array<IndexerEnglish_Auction>;
};

export type IndexerEnglish_Auction_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp_Count>;
};

export type IndexerEnglish_Auction_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerEnglish_Auction_Select_Column_English_Auction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerEnglish_Auction_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerEnglish_Auction_Select_Column_English_Auction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerEnglish_Auction_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "english_auction" */
export type IndexerEnglish_Auction_Aggregate_Fields = {
  __typename?: 'english_auction_aggregate_fields';
  avg?: Maybe<IndexerEnglish_Auction_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerEnglish_Auction_Max_Fields>;
  min?: Maybe<IndexerEnglish_Auction_Min_Fields>;
  stddev?: Maybe<IndexerEnglish_Auction_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerEnglish_Auction_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerEnglish_Auction_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerEnglish_Auction_Sum_Fields>;
  var_pop?: Maybe<IndexerEnglish_Auction_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerEnglish_Auction_Var_Samp_Fields>;
  variance?: Maybe<IndexerEnglish_Auction_Variance_Fields>;
};


/** aggregate fields of "english_auction" */
export type IndexerEnglish_Auction_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "english_auction" */
export type IndexerEnglish_Auction_Aggregate_Order_By = {
  avg?: Maybe<IndexerEnglish_Auction_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerEnglish_Auction_Max_Order_By>;
  min?: Maybe<IndexerEnglish_Auction_Min_Order_By>;
  stddev?: Maybe<IndexerEnglish_Auction_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerEnglish_Auction_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerEnglish_Auction_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerEnglish_Auction_Sum_Order_By>;
  var_pop?: Maybe<IndexerEnglish_Auction_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerEnglish_Auction_Var_Samp_Order_By>;
  variance?: Maybe<IndexerEnglish_Auction_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerEnglish_Auction_Avg_Fields = {
  __typename?: 'english_auction_avg_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Avg_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "english_auction_bid" */
export type IndexerEnglish_Auction_Bid = {
  __typename?: 'english_auction_bid';
  amount?: Maybe<Scalars['bigint']>;
  amount_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  auction?: Maybe<IndexerEnglish_Auction>;
  /** An object relationship */
  bidder?: Maybe<IndexerHolder>;
  bidder_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  english_auction_id?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  referrers?: Maybe<Scalars['jsonb']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "english_auction_bid" */
export type IndexerEnglish_Auction_BidReferrersArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Aggregate = {
  __typename?: 'english_auction_bid_aggregate';
  aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Fields>;
  nodes: Array<IndexerEnglish_Auction_Bid>;
};

export type IndexerEnglish_Auction_Bid_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Bool_Exp_Count>;
};

export type IndexerEnglish_Auction_Bid_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Aggregate_Fields = {
  __typename?: 'english_auction_bid_aggregate_fields';
  avg?: Maybe<IndexerEnglish_Auction_Bid_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerEnglish_Auction_Bid_Max_Fields>;
  min?: Maybe<IndexerEnglish_Auction_Bid_Min_Fields>;
  stddev?: Maybe<IndexerEnglish_Auction_Bid_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerEnglish_Auction_Bid_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerEnglish_Auction_Bid_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerEnglish_Auction_Bid_Sum_Fields>;
  var_pop?: Maybe<IndexerEnglish_Auction_Bid_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerEnglish_Auction_Bid_Var_Samp_Fields>;
  variance?: Maybe<IndexerEnglish_Auction_Bid_Variance_Fields>;
};


/** aggregate fields of "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Aggregate_Order_By = {
  avg?: Maybe<IndexerEnglish_Auction_Bid_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerEnglish_Auction_Bid_Max_Order_By>;
  min?: Maybe<IndexerEnglish_Auction_Bid_Min_Order_By>;
  stddev?: Maybe<IndexerEnglish_Auction_Bid_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerEnglish_Auction_Bid_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerEnglish_Auction_Bid_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerEnglish_Auction_Bid_Sum_Order_By>;
  var_pop?: Maybe<IndexerEnglish_Auction_Bid_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerEnglish_Auction_Bid_Var_Samp_Order_By>;
  variance?: Maybe<IndexerEnglish_Auction_Bid_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerEnglish_Auction_Bid_Avg_Fields = {
  __typename?: 'english_auction_bid_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "english_auction_bid". All fields are combined with a logical 'AND'. */
export type IndexerEnglish_Auction_Bid_Bool_Exp = {
  _and?: Maybe<Array<IndexerEnglish_Auction_Bid_Bool_Exp>>;
  _not?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
  _or?: Maybe<Array<IndexerEnglish_Auction_Bid_Bool_Exp>>;
  amount?: Maybe<IndexerBigint_Comparison_Exp>;
  amount_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  auction?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  bidder?: Maybe<IndexerHolder_Bool_Exp>;
  bidder_address?: Maybe<IndexerString_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  english_auction_id?: Maybe<IndexerBigint_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  referrers?: Maybe<IndexerJsonb_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerEnglish_Auction_Bid_Max_Fields = {
  __typename?: 'english_auction_bid_max_fields';
  amount?: Maybe<Scalars['bigint']>;
  amount_xtz?: Maybe<Scalars['bigint']>;
  bidder_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  english_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  bidder_address?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerEnglish_Auction_Bid_Min_Fields = {
  __typename?: 'english_auction_bid_min_fields';
  amount?: Maybe<Scalars['bigint']>;
  amount_xtz?: Maybe<Scalars['bigint']>;
  bidder_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  english_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  bidder_address?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "english_auction_bid". */
export type IndexerEnglish_Auction_Bid_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  auction?: Maybe<IndexerEnglish_Auction_Order_By>;
  bidder?: Maybe<IndexerHolder_Order_By>;
  bidder_address?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  referrers?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
};

/** select columns of table "english_auction_bid" */
export enum IndexerEnglish_Auction_Bid_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountXtz = 'amount_xtz',
  /** column name */
  BidderAddress = 'bidder_address',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EnglishAuctionId = 'english_auction_id',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Referrers = 'referrers',
  /** column name */
  Timestamp = 'timestamp'
}

/** aggregate stddev on columns */
export type IndexerEnglish_Auction_Bid_Stddev_Fields = {
  __typename?: 'english_auction_bid_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerEnglish_Auction_Bid_Stddev_Pop_Fields = {
  __typename?: 'english_auction_bid_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerEnglish_Auction_Bid_Stddev_Samp_Fields = {
  __typename?: 'english_auction_bid_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerEnglish_Auction_Bid_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerEnglish_Auction_Bid_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['bigint']>;
  amount_xtz?: Maybe<Scalars['bigint']>;
  bidder_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  english_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  referrers?: Maybe<Scalars['jsonb']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IndexerEnglish_Auction_Bid_Sum_Fields = {
  __typename?: 'english_auction_bid_sum_fields';
  amount?: Maybe<Scalars['bigint']>;
  amount_xtz?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  english_auction_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerEnglish_Auction_Bid_Var_Pop_Fields = {
  __typename?: 'english_auction_bid_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerEnglish_Auction_Bid_Var_Samp_Fields = {
  __typename?: 'english_auction_bid_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerEnglish_Auction_Bid_Variance_Fields = {
  __typename?: 'english_auction_bid_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_xtz?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  english_auction_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "english_auction_bid" */
export type IndexerEnglish_Auction_Bid_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_xtz?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  english_auction_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "english_auction". All fields are combined with a logical 'AND'. */
export type IndexerEnglish_Auction_Bool_Exp = {
  _and?: Maybe<Array<IndexerEnglish_Auction_Bool_Exp>>;
  _not?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  _or?: Maybe<Array<IndexerEnglish_Auction_Bool_Exp>>;
  bids?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
  bids_aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Bool_Exp>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  duration?: Maybe<IndexerInt_Comparison_Exp>;
  end_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  extension_time?: Maybe<IndexerInt_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  hash?: Maybe<IndexerString_Comparison_Exp>;
  highest_bid?: Maybe<IndexerBigint_Comparison_Exp>;
  highest_bid_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  highest_bidder?: Maybe<IndexerHolder_Bool_Exp>;
  highest_bidder_address?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price_increment?: Maybe<IndexerBigint_Comparison_Exp>;
  price_increment_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  referral_bonus?: Maybe<IndexerInt_Comparison_Exp>;
  reserve?: Maybe<IndexerBigint_Comparison_Exp>;
  reserve_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  start_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  status?: Maybe<IndexerAuction_Status_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  valid_royalties?: Maybe<IndexerBoolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerEnglish_Auction_Max_Fields = {
  __typename?: 'english_auction_max_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  referral_bonus?: Maybe<Scalars['Int']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Max_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  highest_bidder_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerEnglish_Auction_Min_Fields = {
  __typename?: 'english_auction_min_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  referral_bonus?: Maybe<Scalars['Int']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Min_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  highest_bidder_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "english_auction". */
export type IndexerEnglish_Auction_Order_By = {
  bids_aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Order_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  hash?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  highest_bidder?: Maybe<IndexerHolder_Order_By>;
  highest_bidder_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
  valid_royalties?: Maybe<IndexerOrder_By>;
};

/** select columns of table "english_auction" */
export enum IndexerEnglish_Auction_Select_Column {
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Duration = 'duration',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  ExtensionTime = 'extension_time',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Hash = 'hash',
  /** column name */
  HighestBid = 'highest_bid',
  /** column name */
  HighestBidXtz = 'highest_bid_xtz',
  /** column name */
  HighestBidderAddress = 'highest_bidder_address',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  PriceIncrement = 'price_increment',
  /** column name */
  PriceIncrementXtz = 'price_increment_xtz',
  /** column name */
  ReferralBonus = 'referral_bonus',
  /** column name */
  Reserve = 'reserve',
  /** column name */
  ReserveXtz = 'reserve_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Status = 'status',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp',
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "english_auction_aggregate_bool_exp_bool_and_arguments_columns" columns of table "english_auction" */
export enum IndexerEnglish_Auction_Select_Column_English_Auction_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "english_auction_aggregate_bool_exp_bool_or_arguments_columns" columns of table "english_auction" */
export enum IndexerEnglish_Auction_Select_Column_English_Auction_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** aggregate stddev on columns */
export type IndexerEnglish_Auction_Stddev_Fields = {
  __typename?: 'english_auction_stddev_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Stddev_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerEnglish_Auction_Stddev_Pop_Fields = {
  __typename?: 'english_auction_stddev_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Stddev_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerEnglish_Auction_Stddev_Samp_Fields = {
  __typename?: 'english_auction_stddev_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Stddev_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "english_auction" */
export type IndexerEnglish_Auction_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerEnglish_Auction_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerEnglish_Auction_Stream_Cursor_Value_Input = {
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  extension_time?: Maybe<Scalars['Int']>;
  fa_contract?: Maybe<Scalars['String']>;
  hash?: Maybe<Scalars['String']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  highest_bidder_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  referral_bonus?: Maybe<Scalars['Int']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  status?: Maybe<Scalars['auction_status']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IndexerEnglish_Auction_Sum_Fields = {
  __typename?: 'english_auction_sum_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  duration?: Maybe<Scalars['Int']>;
  extension_time?: Maybe<Scalars['Int']>;
  highest_bid?: Maybe<Scalars['bigint']>;
  highest_bid_xtz?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price_increment?: Maybe<Scalars['bigint']>;
  price_increment_xtz?: Maybe<Scalars['bigint']>;
  referral_bonus?: Maybe<Scalars['Int']>;
  reserve?: Maybe<Scalars['bigint']>;
  reserve_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Sum_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerEnglish_Auction_Var_Pop_Fields = {
  __typename?: 'english_auction_var_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Var_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerEnglish_Auction_Var_Samp_Fields = {
  __typename?: 'english_auction_var_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Var_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerEnglish_Auction_Variance_Fields = {
  __typename?: 'english_auction_variance_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  duration?: Maybe<Scalars['Float']>;
  extension_time?: Maybe<Scalars['Float']>;
  highest_bid?: Maybe<Scalars['Float']>;
  highest_bid_xtz?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price_increment?: Maybe<Scalars['Float']>;
  price_increment_xtz?: Maybe<Scalars['Float']>;
  referral_bonus?: Maybe<Scalars['Float']>;
  reserve?: Maybe<Scalars['Float']>;
  reserve_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "english_auction" */
export type IndexerEnglish_Auction_Variance_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  duration?: Maybe<IndexerOrder_By>;
  extension_time?: Maybe<IndexerOrder_By>;
  highest_bid?: Maybe<IndexerOrder_By>;
  highest_bid_xtz?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price_increment?: Maybe<IndexerOrder_By>;
  price_increment_xtz?: Maybe<IndexerOrder_By>;
  referral_bonus?: Maybe<IndexerOrder_By>;
  reserve?: Maybe<IndexerOrder_By>;
  reserve_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "event" */
export type IndexerEvent = {
  __typename?: 'event';
  amount?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  creator: IndexerHolder;
  creator_address: Scalars['String'];
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  event_type?: Maybe<Scalars['event_type']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  level: Scalars['Int'];
  /** An object relationship */
  marketplace?: Maybe<IndexerMarketplace_Contract>;
  marketplace_contract?: Maybe<Scalars['String']>;
  marketplace_event_type?: Maybe<Scalars['marketplace_event_type']>;
  marketplace_object_id?: Maybe<Scalars['bigint']>;
  ob_contract: Scalars['Boolean'];
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  recipient?: Maybe<IndexerHolder>;
  recipient_address?: Maybe<Scalars['String']>;
  reverted?: Maybe<Scalars['Boolean']>;
  timestamp: Scalars['timestamptz'];
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "event" */
export type IndexerEvent_Aggregate = {
  __typename?: 'event_aggregate';
  aggregate?: Maybe<IndexerEvent_Aggregate_Fields>;
  nodes: Array<IndexerEvent>;
};

export type IndexerEvent_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerEvent_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerEvent_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerEvent_Aggregate_Bool_Exp_Count>;
};

export type IndexerEvent_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerEvent_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEvent_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerEvent_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerEvent_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEvent_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerEvent_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerEvent_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "event" */
export type IndexerEvent_Aggregate_Fields = {
  __typename?: 'event_aggregate_fields';
  avg?: Maybe<IndexerEvent_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerEvent_Max_Fields>;
  min?: Maybe<IndexerEvent_Min_Fields>;
  stddev?: Maybe<IndexerEvent_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerEvent_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerEvent_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerEvent_Sum_Fields>;
  var_pop?: Maybe<IndexerEvent_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerEvent_Var_Samp_Fields>;
  variance?: Maybe<IndexerEvent_Variance_Fields>;
};


/** aggregate fields of "event" */
export type IndexerEvent_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerEvent_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "event" */
export type IndexerEvent_Aggregate_Order_By = {
  avg?: Maybe<IndexerEvent_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerEvent_Max_Order_By>;
  min?: Maybe<IndexerEvent_Min_Order_By>;
  stddev?: Maybe<IndexerEvent_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerEvent_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerEvent_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerEvent_Sum_Order_By>;
  var_pop?: Maybe<IndexerEvent_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerEvent_Var_Samp_Order_By>;
  variance?: Maybe<IndexerEvent_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerEvent_Avg_Fields = {
  __typename?: 'event_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "event" */
export type IndexerEvent_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "event". All fields are combined with a logical 'AND'. */
export type IndexerEvent_Bool_Exp = {
  _and?: Maybe<Array<IndexerEvent_Bool_Exp>>;
  _not?: Maybe<IndexerEvent_Bool_Exp>;
  _or?: Maybe<Array<IndexerEvent_Bool_Exp>>;
  amount?: Maybe<IndexerBigint_Comparison_Exp>;
  creator?: Maybe<IndexerHolder_Bool_Exp>;
  creator_address?: Maybe<IndexerString_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  event_type?: Maybe<IndexerEvent_Type_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  marketplace_event_type?: Maybe<IndexerMarketplace_Event_Type_Comparison_Exp>;
  marketplace_object_id?: Maybe<IndexerBigint_Comparison_Exp>;
  ob_contract?: Maybe<IndexerBoolean_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  recipient?: Maybe<IndexerHolder_Bool_Exp>;
  recipient_address?: Maybe<IndexerString_Comparison_Exp>;
  reverted?: Maybe<IndexerBoolean_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerEvent_Max_Fields = {
  __typename?: 'event_max_fields';
  amount?: Maybe<Scalars['bigint']>;
  creator_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  event_type?: Maybe<Scalars['event_type']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  marketplace_event_type?: Maybe<Scalars['marketplace_event_type']>;
  marketplace_object_id?: Maybe<Scalars['bigint']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  recipient_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "event" */
export type IndexerEvent_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  creator_address?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  event_type?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  marketplace_event_type?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  recipient_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerEvent_Min_Fields = {
  __typename?: 'event_min_fields';
  amount?: Maybe<Scalars['bigint']>;
  creator_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  event_type?: Maybe<Scalars['event_type']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  marketplace_event_type?: Maybe<Scalars['marketplace_event_type']>;
  marketplace_object_id?: Maybe<Scalars['bigint']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  recipient_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "event" */
export type IndexerEvent_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  creator_address?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  event_type?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  marketplace_event_type?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  recipient_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "event". */
export type IndexerEvent_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  creator?: Maybe<IndexerHolder_Order_By>;
  creator_address?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  event_type?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  marketplace_event_type?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  ob_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  recipient?: Maybe<IndexerHolder_Order_By>;
  recipient_address?: Maybe<IndexerOrder_By>;
  reverted?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "event" */
export enum IndexerEvent_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  CreatorAddress = 'creator_address',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EventType = 'event_type',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  MarketplaceEventType = 'marketplace_event_type',
  /** column name */
  MarketplaceObjectId = 'marketplace_object_id',
  /** column name */
  ObContract = 'ob_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  RecipientAddress = 'recipient_address',
  /** column name */
  Reverted = 'reverted',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk'
}

/** select "event_aggregate_bool_exp_bool_and_arguments_columns" columns of table "event" */
export enum IndexerEvent_Select_Column_Event_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ObContract = 'ob_contract',
  /** column name */
  Reverted = 'reverted'
}

/** select "event_aggregate_bool_exp_bool_or_arguments_columns" columns of table "event" */
export enum IndexerEvent_Select_Column_Event_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ObContract = 'ob_contract',
  /** column name */
  Reverted = 'reverted'
}

/** aggregate stddev on columns */
export type IndexerEvent_Stddev_Fields = {
  __typename?: 'event_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "event" */
export type IndexerEvent_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerEvent_Stddev_Pop_Fields = {
  __typename?: 'event_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "event" */
export type IndexerEvent_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerEvent_Stddev_Samp_Fields = {
  __typename?: 'event_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "event" */
export type IndexerEvent_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "event" */
export type IndexerEvent_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerEvent_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerEvent_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['bigint']>;
  creator_address?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  event_type?: Maybe<Scalars['event_type']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  marketplace_event_type?: Maybe<Scalars['marketplace_event_type']>;
  marketplace_object_id?: Maybe<Scalars['bigint']>;
  ob_contract?: Maybe<Scalars['Boolean']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  recipient_address?: Maybe<Scalars['String']>;
  reverted?: Maybe<Scalars['Boolean']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerEvent_Sum_Fields = {
  __typename?: 'event_sum_fields';
  amount?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_object_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "event" */
export type IndexerEvent_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "event_type". All fields are combined with logical 'AND'. */
export type IndexerEvent_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['event_type']>;
  _gt?: Maybe<Scalars['event_type']>;
  _gte?: Maybe<Scalars['event_type']>;
  _in?: Maybe<Array<Scalars['event_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['event_type']>;
  _lte?: Maybe<Scalars['event_type']>;
  _neq?: Maybe<Scalars['event_type']>;
  _nin?: Maybe<Array<Scalars['event_type']>>;
};

/** aggregate var_pop on columns */
export type IndexerEvent_Var_Pop_Fields = {
  __typename?: 'event_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "event" */
export type IndexerEvent_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerEvent_Var_Samp_Fields = {
  __typename?: 'event_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "event" */
export type IndexerEvent_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerEvent_Variance_Fields = {
  __typename?: 'event_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  marketplace_object_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "event" */
export type IndexerEvent_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_object_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "fa" */
export type IndexerFa = {
  __typename?: 'fa';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  /** An array relationship */
  attribute_counts: Array<IndexerFa2_Attribute_Count>;
  /** An aggregate relationship */
  attribute_counts_aggregate: IndexerFa2_Attribute_Count_Aggregate;
  category?: Maybe<Scalars['String']>;
  code_hash?: Maybe<Scalars['Int']>;
  /** An array relationship */
  collaborators: Array<IndexerInvitation>;
  /** An aggregate relationship */
  collaborators_aggregate: IndexerInvitation_Aggregate;
  collection_id?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['collection_type']>;
  contract: Scalars['String'];
  /** An object relationship */
  creator?: Maybe<IndexerHolder>;
  creator_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  /** An array relationship */
  dutch_auctions: Array<IndexerDutch_Auction>;
  /** An array relationship */
  dutch_auctions_active: Array<IndexerDutch_Auction_Active>;
  /** An aggregate relationship */
  dutch_auctions_active_aggregate: IndexerDutch_Auction_Active_Aggregate;
  /** An aggregate relationship */
  dutch_auctions_aggregate: IndexerDutch_Auction_Aggregate;
  editions?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  english_auctions: Array<IndexerEnglish_Auction>;
  /** An array relationship */
  english_auctions_active: Array<IndexerEnglish_Auction_Active>;
  /** An aggregate relationship */
  english_auctions_active_aggregate: IndexerEnglish_Auction_Active_Aggregate;
  /** An aggregate relationship */
  english_auctions_aggregate: IndexerEnglish_Auction_Aggregate;
  /** An array relationship */
  events: Array<IndexerEvent>;
  /** An aggregate relationship */
  events_aggregate: IndexerEvent_Aggregate;
  floor_price?: Maybe<Scalars['bigint']>;
  index_contract_metadata?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  ledger_type?: Maybe<Scalars['ledger_type']>;
  level?: Maybe<Scalars['Int']>;
  /** An array relationship */
  listings: Array<IndexerListing>;
  /** An array relationship */
  listings_active: Array<IndexerListing_Active>;
  /** An aggregate relationship */
  listings_active_aggregate: IndexerListing_Active_Aggregate;
  /** An aggregate relationship */
  listings_aggregate: IndexerListing_Aggregate;
  live: Scalars['Boolean'];
  logo?: Maybe<Scalars['String']>;
  managers: Array<IndexerFa2_Manager>;
  managers_aggregate: IndexerFa2_Manager_Aggregate;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  offers: Array<IndexerOffer>;
  /** An array relationship */
  offers_active: Array<IndexerOffer_Active>;
  /** An aggregate relationship */
  offers_active_aggregate: IndexerOffer_Active_Aggregate;
  /** An aggregate relationship */
  offers_aggregate: IndexerOffer_Aggregate;
  /** An array relationship */
  open_edition: Array<IndexerOpen_Edition>;
  /** An array relationship */
  open_edition_active: Array<IndexerOpen_Edition_Active>;
  /** An aggregate relationship */
  open_edition_active_aggregate: IndexerOpen_Edition_Active_Aggregate;
  /** An aggregate relationship */
  open_edition_aggregate: IndexerOpen_Edition_Aggregate;
  originated?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_link?: Maybe<Scalars['String']>;
  /** An array relationship */
  tokens: Array<IndexerToken>;
  /** An aggregate relationship */
  tokens_aggregate: IndexerToken_Aggregate;
  twitter?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['fa_type']>;
  type_hash?: Maybe<Scalars['Int']>;
  tzip16_key?: Maybe<Scalars['String']>;
  updated_attributes_counts?: Maybe<Scalars['timestamptz']>;
  verified_creators?: Maybe<Scalars['jsonb']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "fa" */
export type IndexerFaAttribute_CountsArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaAttribute_Counts_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaCollaboratorsArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaCollaborators_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaDutch_AuctionsArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaDutch_Auctions_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaDutch_Auctions_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaDutch_Auctions_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaEnglish_AuctionsArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaEnglish_Auctions_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaEnglish_Auctions_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaEnglish_Auctions_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaEventsArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaListingsArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaListings_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaListings_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaListings_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaManagersArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaManagers_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOffersArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOffers_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOffers_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOpen_EditionArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOpen_Edition_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOpen_Edition_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaOpen_Edition_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaTokensArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Order_By>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaTokens_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Order_By>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


/** columns and relationships of "fa" */
export type IndexerFaVerified_CreatorsArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count = {
  __typename?: 'fa2_attribute_count';
  /** An object relationship */
  attribute: IndexerAttribute;
  attribute_id: Scalars['bigint'];
  editions?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  id: Scalars['bigint'];
  tokens?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Aggregate = {
  __typename?: 'fa2_attribute_count_aggregate';
  aggregate?: Maybe<IndexerFa2_Attribute_Count_Aggregate_Fields>;
  nodes: Array<IndexerFa2_Attribute_Count>;
};

export type IndexerFa2_Attribute_Count_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerFa2_Attribute_Count_Aggregate_Bool_Exp_Count>;
};

export type IndexerFa2_Attribute_Count_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Aggregate_Fields = {
  __typename?: 'fa2_attribute_count_aggregate_fields';
  avg?: Maybe<IndexerFa2_Attribute_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerFa2_Attribute_Count_Max_Fields>;
  min?: Maybe<IndexerFa2_Attribute_Count_Min_Fields>;
  stddev?: Maybe<IndexerFa2_Attribute_Count_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerFa2_Attribute_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerFa2_Attribute_Count_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerFa2_Attribute_Count_Sum_Fields>;
  var_pop?: Maybe<IndexerFa2_Attribute_Count_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerFa2_Attribute_Count_Var_Samp_Fields>;
  variance?: Maybe<IndexerFa2_Attribute_Count_Variance_Fields>;
};


/** aggregate fields of "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Aggregate_Order_By = {
  avg?: Maybe<IndexerFa2_Attribute_Count_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerFa2_Attribute_Count_Max_Order_By>;
  min?: Maybe<IndexerFa2_Attribute_Count_Min_Order_By>;
  stddev?: Maybe<IndexerFa2_Attribute_Count_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerFa2_Attribute_Count_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerFa2_Attribute_Count_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerFa2_Attribute_Count_Sum_Order_By>;
  var_pop?: Maybe<IndexerFa2_Attribute_Count_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerFa2_Attribute_Count_Var_Samp_Order_By>;
  variance?: Maybe<IndexerFa2_Attribute_Count_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerFa2_Attribute_Count_Avg_Fields = {
  __typename?: 'fa2_attribute_count_avg_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Avg_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "fa2_attribute_count". All fields are combined with a logical 'AND'. */
export type IndexerFa2_Attribute_Count_Bool_Exp = {
  _and?: Maybe<Array<IndexerFa2_Attribute_Count_Bool_Exp>>;
  _not?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
  _or?: Maybe<Array<IndexerFa2_Attribute_Count_Bool_Exp>>;
  attribute?: Maybe<IndexerAttribute_Bool_Exp>;
  attribute_id?: Maybe<IndexerBigint_Comparison_Exp>;
  editions?: Maybe<IndexerBigint_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  tokens?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerFa2_Attribute_Count_Max_Fields = {
  __typename?: 'fa2_attribute_count_max_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Max_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerFa2_Attribute_Count_Min_Fields = {
  __typename?: 'fa2_attribute_count_min_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Min_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "fa2_attribute_count". */
export type IndexerFa2_Attribute_Count_Order_By = {
  attribute?: Maybe<IndexerAttribute_Order_By>;
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** select columns of table "fa2_attribute_count" */
export enum IndexerFa2_Attribute_Count_Select_Column {
  /** column name */
  AttributeId = 'attribute_id',
  /** column name */
  Editions = 'editions',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Tokens = 'tokens'
}

/** aggregate stddev on columns */
export type IndexerFa2_Attribute_Count_Stddev_Fields = {
  __typename?: 'fa2_attribute_count_stddev_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Stddev_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerFa2_Attribute_Count_Stddev_Pop_Fields = {
  __typename?: 'fa2_attribute_count_stddev_pop_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Stddev_Pop_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerFa2_Attribute_Count_Stddev_Samp_Fields = {
  __typename?: 'fa2_attribute_count_stddev_samp_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Stddev_Samp_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerFa2_Attribute_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerFa2_Attribute_Count_Stream_Cursor_Value_Input = {
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerFa2_Attribute_Count_Sum_Fields = {
  __typename?: 'fa2_attribute_count_sum_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Sum_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerFa2_Attribute_Count_Var_Pop_Fields = {
  __typename?: 'fa2_attribute_count_var_pop_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Var_Pop_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerFa2_Attribute_Count_Var_Samp_Fields = {
  __typename?: 'fa2_attribute_count_var_samp_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Var_Samp_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerFa2_Attribute_Count_Variance_Fields = {
  __typename?: 'fa2_attribute_count_variance_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fa2_attribute_count" */
export type IndexerFa2_Attribute_Count_Variance_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "fa2_creator" */
export type IndexerFa2_Creator = {
  __typename?: 'fa2_creator';
  contract: Scalars['String'];
  creator: Scalars['String'];
  id: Scalars['bigint'];
  inserted_at: Scalars['timestamp'];
  updated_at: Scalars['timestamp'];
  verification_type: Scalars['creator_verification_type'];
};

/** aggregated selection of "fa2_creator" */
export type IndexerFa2_Creator_Aggregate = {
  __typename?: 'fa2_creator_aggregate';
  aggregate?: Maybe<IndexerFa2_Creator_Aggregate_Fields>;
  nodes: Array<IndexerFa2_Creator>;
};

/** aggregate fields of "fa2_creator" */
export type IndexerFa2_Creator_Aggregate_Fields = {
  __typename?: 'fa2_creator_aggregate_fields';
  avg?: Maybe<IndexerFa2_Creator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerFa2_Creator_Max_Fields>;
  min?: Maybe<IndexerFa2_Creator_Min_Fields>;
  stddev?: Maybe<IndexerFa2_Creator_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerFa2_Creator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerFa2_Creator_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerFa2_Creator_Sum_Fields>;
  var_pop?: Maybe<IndexerFa2_Creator_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerFa2_Creator_Var_Samp_Fields>;
  variance?: Maybe<IndexerFa2_Creator_Variance_Fields>;
};


/** aggregate fields of "fa2_creator" */
export type IndexerFa2_Creator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerFa2_Creator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerFa2_Creator_Avg_Fields = {
  __typename?: 'fa2_creator_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fa2_creator". All fields are combined with a logical 'AND'. */
export type IndexerFa2_Creator_Bool_Exp = {
  _and?: Maybe<Array<IndexerFa2_Creator_Bool_Exp>>;
  _not?: Maybe<IndexerFa2_Creator_Bool_Exp>;
  _or?: Maybe<Array<IndexerFa2_Creator_Bool_Exp>>;
  contract?: Maybe<IndexerString_Comparison_Exp>;
  creator?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  inserted_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  updated_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  verification_type?: Maybe<IndexerCreator_Verification_Type_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerFa2_Creator_Max_Fields = {
  __typename?: 'fa2_creator_max_fields';
  contract?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  verification_type?: Maybe<Scalars['creator_verification_type']>;
};

/** aggregate min on columns */
export type IndexerFa2_Creator_Min_Fields = {
  __typename?: 'fa2_creator_min_fields';
  contract?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  verification_type?: Maybe<Scalars['creator_verification_type']>;
};

/** Ordering options when selecting data from "fa2_creator". */
export type IndexerFa2_Creator_Order_By = {
  contract?: Maybe<IndexerOrder_By>;
  creator?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  updated_at?: Maybe<IndexerOrder_By>;
  verification_type?: Maybe<IndexerOrder_By>;
};

/** select columns of table "fa2_creator" */
export enum IndexerFa2_Creator_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  Creator = 'creator',
  /** column name */
  Id = 'id',
  /** column name */
  InsertedAt = 'inserted_at',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  VerificationType = 'verification_type'
}

/** aggregate stddev on columns */
export type IndexerFa2_Creator_Stddev_Fields = {
  __typename?: 'fa2_creator_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerFa2_Creator_Stddev_Pop_Fields = {
  __typename?: 'fa2_creator_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerFa2_Creator_Stddev_Samp_Fields = {
  __typename?: 'fa2_creator_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fa2_creator" */
export type IndexerFa2_Creator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerFa2_Creator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerFa2_Creator_Stream_Cursor_Value_Input = {
  contract?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  verification_type?: Maybe<Scalars['creator_verification_type']>;
};

/** aggregate sum on columns */
export type IndexerFa2_Creator_Sum_Fields = {
  __typename?: 'fa2_creator_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerFa2_Creator_Var_Pop_Fields = {
  __typename?: 'fa2_creator_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerFa2_Creator_Var_Samp_Fields = {
  __typename?: 'fa2_creator_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerFa2_Creator_Variance_Fields = {
  __typename?: 'fa2_creator_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "fa2_manager" */
export type IndexerFa2_Manager = {
  __typename?: 'fa2_manager';
  contract: Scalars['String'];
  fa?: Maybe<IndexerFa>;
  holder?: Maybe<IndexerHolder>;
  id: Scalars['bigint'];
  inserted_at: Scalars['timestamp'];
  manager: Scalars['String'];
};

/** aggregated selection of "fa2_manager" */
export type IndexerFa2_Manager_Aggregate = {
  __typename?: 'fa2_manager_aggregate';
  aggregate?: Maybe<IndexerFa2_Manager_Aggregate_Fields>;
  nodes: Array<IndexerFa2_Manager>;
};

/** aggregate fields of "fa2_manager" */
export type IndexerFa2_Manager_Aggregate_Fields = {
  __typename?: 'fa2_manager_aggregate_fields';
  avg?: Maybe<IndexerFa2_Manager_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerFa2_Manager_Max_Fields>;
  min?: Maybe<IndexerFa2_Manager_Min_Fields>;
  stddev?: Maybe<IndexerFa2_Manager_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerFa2_Manager_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerFa2_Manager_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerFa2_Manager_Sum_Fields>;
  var_pop?: Maybe<IndexerFa2_Manager_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerFa2_Manager_Var_Samp_Fields>;
  variance?: Maybe<IndexerFa2_Manager_Variance_Fields>;
};


/** aggregate fields of "fa2_manager" */
export type IndexerFa2_Manager_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerFa2_Manager_Avg_Fields = {
  __typename?: 'fa2_manager_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "fa2_manager". All fields are combined with a logical 'AND'. */
export type IndexerFa2_Manager_Bool_Exp = {
  _and?: Maybe<Array<IndexerFa2_Manager_Bool_Exp>>;
  _not?: Maybe<IndexerFa2_Manager_Bool_Exp>;
  _or?: Maybe<Array<IndexerFa2_Manager_Bool_Exp>>;
  contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  inserted_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  manager?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerFa2_Manager_Max_Fields = {
  __typename?: 'fa2_manager_max_fields';
  contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  manager?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IndexerFa2_Manager_Min_Fields = {
  __typename?: 'fa2_manager_min_fields';
  contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  manager?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "fa2_manager". */
export type IndexerFa2_Manager_Order_By = {
  contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  manager?: Maybe<IndexerOrder_By>;
};

/** select columns of table "fa2_manager" */
export enum IndexerFa2_Manager_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  Id = 'id',
  /** column name */
  InsertedAt = 'inserted_at',
  /** column name */
  Manager = 'manager'
}

/** aggregate stddev on columns */
export type IndexerFa2_Manager_Stddev_Fields = {
  __typename?: 'fa2_manager_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerFa2_Manager_Stddev_Pop_Fields = {
  __typename?: 'fa2_manager_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerFa2_Manager_Stddev_Samp_Fields = {
  __typename?: 'fa2_manager_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "fa2_manager" */
export type IndexerFa2_Manager_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerFa2_Manager_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerFa2_Manager_Stream_Cursor_Value_Input = {
  contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  manager?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerFa2_Manager_Sum_Fields = {
  __typename?: 'fa2_manager_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerFa2_Manager_Var_Pop_Fields = {
  __typename?: 'fa2_manager_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerFa2_Manager_Var_Samp_Fields = {
  __typename?: 'fa2_manager_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerFa2_Manager_Variance_Fields = {
  __typename?: 'fa2_manager_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregated selection of "fa" */
export type IndexerFa_Aggregate = {
  __typename?: 'fa_aggregate';
  aggregate?: Maybe<IndexerFa_Aggregate_Fields>;
  nodes: Array<IndexerFa>;
};

export type IndexerFa_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerFa_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerFa_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerFa_Aggregate_Bool_Exp_Count>;
};

export type IndexerFa_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerFa_Select_Column_Fa_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerFa_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerFa_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerFa_Select_Column_Fa_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerFa_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerFa_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerFa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerFa_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "fa" */
export type IndexerFa_Aggregate_Fields = {
  __typename?: 'fa_aggregate_fields';
  avg?: Maybe<IndexerFa_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerFa_Max_Fields>;
  min?: Maybe<IndexerFa_Min_Fields>;
  stddev?: Maybe<IndexerFa_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerFa_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerFa_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerFa_Sum_Fields>;
  var_pop?: Maybe<IndexerFa_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerFa_Var_Samp_Fields>;
  variance?: Maybe<IndexerFa_Variance_Fields>;
};


/** aggregate fields of "fa" */
export type IndexerFa_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerFa_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "fa" */
export type IndexerFa_Aggregate_Order_By = {
  avg?: Maybe<IndexerFa_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerFa_Max_Order_By>;
  min?: Maybe<IndexerFa_Min_Order_By>;
  stddev?: Maybe<IndexerFa_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerFa_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerFa_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerFa_Sum_Order_By>;
  var_pop?: Maybe<IndexerFa_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerFa_Var_Samp_Order_By>;
  variance?: Maybe<IndexerFa_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerFa_Avg_Fields = {
  __typename?: 'fa_avg_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "fa" */
export type IndexerFa_Avg_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "fa". All fields are combined with a logical 'AND'. */
export type IndexerFa_Bool_Exp = {
  _and?: Maybe<Array<IndexerFa_Bool_Exp>>;
  _not?: Maybe<IndexerFa_Bool_Exp>;
  _or?: Maybe<Array<IndexerFa_Bool_Exp>>;
  active_auctions?: Maybe<IndexerInt_Comparison_Exp>;
  active_listing?: Maybe<IndexerInt_Comparison_Exp>;
  attribute_counts?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
  attribute_counts_aggregate?: Maybe<IndexerFa2_Attribute_Count_Aggregate_Bool_Exp>;
  category?: Maybe<IndexerString_Comparison_Exp>;
  code_hash?: Maybe<IndexerInt_Comparison_Exp>;
  collaborators?: Maybe<IndexerInvitation_Bool_Exp>;
  collaborators_aggregate?: Maybe<IndexerInvitation_Aggregate_Bool_Exp>;
  collection_id?: Maybe<IndexerString_Comparison_Exp>;
  collection_type?: Maybe<IndexerCollection_Type_Comparison_Exp>;
  contract?: Maybe<IndexerString_Comparison_Exp>;
  creator?: Maybe<IndexerHolder_Bool_Exp>;
  creator_address?: Maybe<IndexerString_Comparison_Exp>;
  description?: Maybe<IndexerString_Comparison_Exp>;
  dutch_auctions?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  dutch_auctions_active?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
  dutch_auctions_active_aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Bool_Exp>;
  dutch_auctions_aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Bool_Exp>;
  editions?: Maybe<IndexerBigint_Comparison_Exp>;
  english_auctions?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  english_auctions_active?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
  english_auctions_active_aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Bool_Exp>;
  english_auctions_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp>;
  events?: Maybe<IndexerEvent_Bool_Exp>;
  events_aggregate?: Maybe<IndexerEvent_Aggregate_Bool_Exp>;
  floor_price?: Maybe<IndexerBigint_Comparison_Exp>;
  index_contract_metadata?: Maybe<IndexerBoolean_Comparison_Exp>;
  items?: Maybe<IndexerInt_Comparison_Exp>;
  last_metadata_update?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  ledger_type?: Maybe<IndexerLedger_Type_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  listings?: Maybe<IndexerListing_Bool_Exp>;
  listings_active?: Maybe<IndexerListing_Active_Bool_Exp>;
  listings_active_aggregate?: Maybe<IndexerListing_Active_Aggregate_Bool_Exp>;
  listings_aggregate?: Maybe<IndexerListing_Aggregate_Bool_Exp>;
  live?: Maybe<IndexerBoolean_Comparison_Exp>;
  logo?: Maybe<IndexerString_Comparison_Exp>;
  metadata?: Maybe<IndexerString_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  offers?: Maybe<IndexerOffer_Bool_Exp>;
  offers_active?: Maybe<IndexerOffer_Active_Bool_Exp>;
  offers_active_aggregate?: Maybe<IndexerOffer_Active_Aggregate_Bool_Exp>;
  offers_aggregate?: Maybe<IndexerOffer_Aggregate_Bool_Exp>;
  open_edition?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  open_edition_active?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
  open_edition_active_aggregate?: Maybe<IndexerOpen_Edition_Active_Aggregate_Bool_Exp>;
  open_edition_aggregate?: Maybe<IndexerOpen_Edition_Aggregate_Bool_Exp>;
  originated?: Maybe<IndexerString_Comparison_Exp>;
  owners?: Maybe<IndexerInt_Comparison_Exp>;
  path?: Maybe<IndexerString_Comparison_Exp>;
  short_name?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token_link?: Maybe<IndexerString_Comparison_Exp>;
  tokens?: Maybe<IndexerToken_Bool_Exp>;
  tokens_aggregate?: Maybe<IndexerToken_Aggregate_Bool_Exp>;
  twitter?: Maybe<IndexerString_Comparison_Exp>;
  type?: Maybe<IndexerFa_Type_Comparison_Exp>;
  type_hash?: Maybe<IndexerInt_Comparison_Exp>;
  tzip16_key?: Maybe<IndexerString_Comparison_Exp>;
  updated_attributes_counts?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  verified_creators?: Maybe<IndexerJsonb_Comparison_Exp>;
  volume_24h?: Maybe<IndexerBigint_Comparison_Exp>;
  volume_total?: Maybe<IndexerBigint_Comparison_Exp>;
  website?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerFa_Max_Fields = {
  __typename?: 'fa_max_fields';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  code_hash?: Maybe<Scalars['Int']>;
  collection_id?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['collection_type']>;
  contract?: Maybe<Scalars['String']>;
  creator_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['bigint']>;
  floor_price?: Maybe<Scalars['bigint']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  ledger_type?: Maybe<Scalars['ledger_type']>;
  level?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originated?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_link?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['fa_type']>;
  type_hash?: Maybe<Scalars['Int']>;
  tzip16_key?: Maybe<Scalars['String']>;
  updated_attributes_counts?: Maybe<Scalars['timestamptz']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
  website?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "fa" */
export type IndexerFa_Max_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  category?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  collection_id?: Maybe<IndexerOrder_By>;
  collection_type?: Maybe<IndexerOrder_By>;
  contract?: Maybe<IndexerOrder_By>;
  creator_address?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  ledger_type?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  logo?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  originated?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  path?: Maybe<IndexerOrder_By>;
  short_name?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_link?: Maybe<IndexerOrder_By>;
  twitter?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
  updated_attributes_counts?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
  website?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerFa_Min_Fields = {
  __typename?: 'fa_min_fields';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  code_hash?: Maybe<Scalars['Int']>;
  collection_id?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['collection_type']>;
  contract?: Maybe<Scalars['String']>;
  creator_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['bigint']>;
  floor_price?: Maybe<Scalars['bigint']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  ledger_type?: Maybe<Scalars['ledger_type']>;
  level?: Maybe<Scalars['Int']>;
  logo?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originated?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_link?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['fa_type']>;
  type_hash?: Maybe<Scalars['Int']>;
  tzip16_key?: Maybe<Scalars['String']>;
  updated_attributes_counts?: Maybe<Scalars['timestamptz']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
  website?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "fa" */
export type IndexerFa_Min_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  category?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  collection_id?: Maybe<IndexerOrder_By>;
  collection_type?: Maybe<IndexerOrder_By>;
  contract?: Maybe<IndexerOrder_By>;
  creator_address?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  ledger_type?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  logo?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  originated?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  path?: Maybe<IndexerOrder_By>;
  short_name?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_link?: Maybe<IndexerOrder_By>;
  twitter?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
  updated_attributes_counts?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
  website?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "fa". */
export type IndexerFa_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  attribute_counts_aggregate?: Maybe<IndexerFa2_Attribute_Count_Aggregate_Order_By>;
  category?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  collaborators_aggregate?: Maybe<IndexerInvitation_Aggregate_Order_By>;
  collection_id?: Maybe<IndexerOrder_By>;
  collection_type?: Maybe<IndexerOrder_By>;
  contract?: Maybe<IndexerOrder_By>;
  creator?: Maybe<IndexerHolder_Order_By>;
  creator_address?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  dutch_auctions_active_aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Order_By>;
  dutch_auctions_aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Order_By>;
  editions?: Maybe<IndexerOrder_By>;
  english_auctions_active_aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Order_By>;
  english_auctions_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Order_By>;
  events_aggregate?: Maybe<IndexerEvent_Aggregate_Order_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  index_contract_metadata?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  ledger_type?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listings_active_aggregate?: Maybe<IndexerListing_Active_Aggregate_Order_By>;
  listings_aggregate?: Maybe<IndexerListing_Aggregate_Order_By>;
  live?: Maybe<IndexerOrder_By>;
  logo?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  offers_active_aggregate?: Maybe<IndexerOffer_Active_Aggregate_Order_By>;
  offers_aggregate?: Maybe<IndexerOffer_Aggregate_Order_By>;
  open_edition_active_aggregate?: Maybe<IndexerOpen_Edition_Active_Aggregate_Order_By>;
  open_edition_aggregate?: Maybe<IndexerOpen_Edition_Aggregate_Order_By>;
  originated?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  path?: Maybe<IndexerOrder_By>;
  short_name?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_link?: Maybe<IndexerOrder_By>;
  tokens_aggregate?: Maybe<IndexerToken_Aggregate_Order_By>;
  twitter?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
  updated_attributes_counts?: Maybe<IndexerOrder_By>;
  verified_creators?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
  website?: Maybe<IndexerOrder_By>;
};

/** select columns of table "fa" */
export enum IndexerFa_Select_Column {
  /** column name */
  ActiveAuctions = 'active_auctions',
  /** column name */
  ActiveListing = 'active_listing',
  /** column name */
  Category = 'category',
  /** column name */
  CodeHash = 'code_hash',
  /** column name */
  CollectionId = 'collection_id',
  /** column name */
  CollectionType = 'collection_type',
  /** column name */
  Contract = 'contract',
  /** column name */
  CreatorAddress = 'creator_address',
  /** column name */
  Description = 'description',
  /** column name */
  Editions = 'editions',
  /** column name */
  FloorPrice = 'floor_price',
  /** column name */
  IndexContractMetadata = 'index_contract_metadata',
  /** column name */
  Items = 'items',
  /** column name */
  LastMetadataUpdate = 'last_metadata_update',
  /** column name */
  LedgerType = 'ledger_type',
  /** column name */
  Level = 'level',
  /** column name */
  Live = 'live',
  /** column name */
  Logo = 'logo',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Originated = 'originated',
  /** column name */
  Owners = 'owners',
  /** column name */
  Path = 'path',
  /** column name */
  ShortName = 'short_name',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenLink = 'token_link',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  Type = 'type',
  /** column name */
  TypeHash = 'type_hash',
  /** column name */
  Tzip16Key = 'tzip16_key',
  /** column name */
  UpdatedAttributesCounts = 'updated_attributes_counts',
  /** column name */
  VerifiedCreators = 'verified_creators',
  /** column name */
  Volume_24h = 'volume_24h',
  /** column name */
  VolumeTotal = 'volume_total',
  /** column name */
  Website = 'website'
}

/** select "fa_aggregate_bool_exp_bool_and_arguments_columns" columns of table "fa" */
export enum IndexerFa_Select_Column_Fa_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IndexContractMetadata = 'index_contract_metadata',
  /** column name */
  Live = 'live'
}

/** select "fa_aggregate_bool_exp_bool_or_arguments_columns" columns of table "fa" */
export enum IndexerFa_Select_Column_Fa_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IndexContractMetadata = 'index_contract_metadata',
  /** column name */
  Live = 'live'
}

/** aggregate stddev on columns */
export type IndexerFa_Stddev_Fields = {
  __typename?: 'fa_stddev_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "fa" */
export type IndexerFa_Stddev_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerFa_Stddev_Pop_Fields = {
  __typename?: 'fa_stddev_pop_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "fa" */
export type IndexerFa_Stddev_Pop_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerFa_Stddev_Samp_Fields = {
  __typename?: 'fa_stddev_samp_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "fa" */
export type IndexerFa_Stddev_Samp_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "fa" */
export type IndexerFa_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerFa_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerFa_Stream_Cursor_Value_Input = {
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  category?: Maybe<Scalars['String']>;
  code_hash?: Maybe<Scalars['Int']>;
  collection_id?: Maybe<Scalars['String']>;
  collection_type?: Maybe<Scalars['collection_type']>;
  contract?: Maybe<Scalars['String']>;
  creator_address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['bigint']>;
  floor_price?: Maybe<Scalars['bigint']>;
  index_contract_metadata?: Maybe<Scalars['Boolean']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  ledger_type?: Maybe<Scalars['ledger_type']>;
  level?: Maybe<Scalars['Int']>;
  live?: Maybe<Scalars['Boolean']>;
  logo?: Maybe<Scalars['String']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  originated?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  path?: Maybe<Scalars['String']>;
  short_name?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_link?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['fa_type']>;
  type_hash?: Maybe<Scalars['Int']>;
  tzip16_key?: Maybe<Scalars['String']>;
  updated_attributes_counts?: Maybe<Scalars['timestamptz']>;
  verified_creators?: Maybe<Scalars['jsonb']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerFa_Sum_Fields = {
  __typename?: 'fa_sum_fields';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  code_hash?: Maybe<Scalars['Int']>;
  editions?: Maybe<Scalars['bigint']>;
  floor_price?: Maybe<Scalars['bigint']>;
  items?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  owners?: Maybe<Scalars['Int']>;
  type_hash?: Maybe<Scalars['Int']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "fa" */
export type IndexerFa_Sum_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "fa_type". All fields are combined with logical 'AND'. */
export type IndexerFa_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['fa_type']>;
  _gt?: Maybe<Scalars['fa_type']>;
  _gte?: Maybe<Scalars['fa_type']>;
  _in?: Maybe<Array<Scalars['fa_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['fa_type']>;
  _lte?: Maybe<Scalars['fa_type']>;
  _neq?: Maybe<Scalars['fa_type']>;
  _nin?: Maybe<Array<Scalars['fa_type']>>;
};

/** aggregate var_pop on columns */
export type IndexerFa_Var_Pop_Fields = {
  __typename?: 'fa_var_pop_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "fa" */
export type IndexerFa_Var_Pop_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerFa_Var_Samp_Fields = {
  __typename?: 'fa_var_samp_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "fa" */
export type IndexerFa_Var_Samp_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerFa_Variance_Fields = {
  __typename?: 'fa_variance_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  code_hash?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  type_hash?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "fa" */
export type IndexerFa_Variance_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  code_hash?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  type_hash?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "flag_type". All fields are combined with logical 'AND'. */
export type IndexerFlag_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['flag_type']>;
  _gt?: Maybe<Scalars['flag_type']>;
  _gte?: Maybe<Scalars['flag_type']>;
  _in?: Maybe<Array<Scalars['flag_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['flag_type']>;
  _lte?: Maybe<Scalars['flag_type']>;
  _neq?: Maybe<Scalars['flag_type']>;
  _nin?: Maybe<Array<Scalars['flag_type']>>;
};

/** columns and relationships of "gallery" */
export type IndexerGallery = {
  __typename?: 'gallery';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  /** An array relationship */
  curators: Array<IndexerGallery_Curator>;
  /** An aggregate relationship */
  curators_aggregate: IndexerGallery_Curator_Aggregate;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['Int']>;
  floor_price?: Maybe<Scalars['bigint']>;
  gallery_id: Scalars['String'];
  inserted_at: Scalars['timestamp'];
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  max_items?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  pk: Scalars['bigint'];
  /** An object relationship */
  registry: IndexerGallery_Registry;
  registry_id: Scalars['bigint'];
  slug?: Maybe<Scalars['String']>;
  /** An array relationship */
  tokens: Array<IndexerGallery_Token>;
  /** An aggregate relationship */
  tokens_aggregate: IndexerGallery_Token_Aggregate;
  updated_at: Scalars['timestamp'];
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "gallery" */
export type IndexerGalleryCuratorsArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Curator_Order_By>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


/** columns and relationships of "gallery" */
export type IndexerGalleryCurators_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Curator_Order_By>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


/** columns and relationships of "gallery" */
export type IndexerGalleryTokensArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


/** columns and relationships of "gallery" */
export type IndexerGalleryTokens_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};

/** aggregated selection of "gallery" */
export type IndexerGallery_Aggregate = {
  __typename?: 'gallery_aggregate';
  aggregate?: Maybe<IndexerGallery_Aggregate_Fields>;
  nodes: Array<IndexerGallery>;
};

export type IndexerGallery_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerGallery_Aggregate_Bool_Exp_Count>;
};

export type IndexerGallery_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerGallery_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerGallery_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "gallery" */
export type IndexerGallery_Aggregate_Fields = {
  __typename?: 'gallery_aggregate_fields';
  avg?: Maybe<IndexerGallery_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerGallery_Max_Fields>;
  min?: Maybe<IndexerGallery_Min_Fields>;
  stddev?: Maybe<IndexerGallery_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerGallery_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerGallery_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerGallery_Sum_Fields>;
  var_pop?: Maybe<IndexerGallery_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerGallery_Var_Samp_Fields>;
  variance?: Maybe<IndexerGallery_Variance_Fields>;
};


/** aggregate fields of "gallery" */
export type IndexerGallery_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerGallery_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gallery" */
export type IndexerGallery_Aggregate_Order_By = {
  avg?: Maybe<IndexerGallery_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerGallery_Max_Order_By>;
  min?: Maybe<IndexerGallery_Min_Order_By>;
  stddev?: Maybe<IndexerGallery_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerGallery_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerGallery_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerGallery_Sum_Order_By>;
  var_pop?: Maybe<IndexerGallery_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerGallery_Var_Samp_Order_By>;
  variance?: Maybe<IndexerGallery_Variance_Order_By>;
};

/** columns and relationships of "gallery_attribute_count" */
export type IndexerGallery_Attribute_Count = {
  __typename?: 'gallery_attribute_count';
  /** An object relationship */
  attribute?: Maybe<IndexerAttribute>;
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  gallery?: Maybe<IndexerGallery>;
  gallery_pk: Scalars['bigint'];
  id: Scalars['bigint'];
  tokens?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "gallery_attribute_count" */
export type IndexerGallery_Attribute_Count_Aggregate = {
  __typename?: 'gallery_attribute_count_aggregate';
  aggregate?: Maybe<IndexerGallery_Attribute_Count_Aggregate_Fields>;
  nodes: Array<IndexerGallery_Attribute_Count>;
};

/** aggregate fields of "gallery_attribute_count" */
export type IndexerGallery_Attribute_Count_Aggregate_Fields = {
  __typename?: 'gallery_attribute_count_aggregate_fields';
  avg?: Maybe<IndexerGallery_Attribute_Count_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerGallery_Attribute_Count_Max_Fields>;
  min?: Maybe<IndexerGallery_Attribute_Count_Min_Fields>;
  stddev?: Maybe<IndexerGallery_Attribute_Count_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerGallery_Attribute_Count_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerGallery_Attribute_Count_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerGallery_Attribute_Count_Sum_Fields>;
  var_pop?: Maybe<IndexerGallery_Attribute_Count_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerGallery_Attribute_Count_Var_Samp_Fields>;
  variance?: Maybe<IndexerGallery_Attribute_Count_Variance_Fields>;
};


/** aggregate fields of "gallery_attribute_count" */
export type IndexerGallery_Attribute_Count_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerGallery_Attribute_Count_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerGallery_Attribute_Count_Avg_Fields = {
  __typename?: 'gallery_attribute_count_avg_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gallery_attribute_count". All fields are combined with a logical 'AND'. */
export type IndexerGallery_Attribute_Count_Bool_Exp = {
  _and?: Maybe<Array<IndexerGallery_Attribute_Count_Bool_Exp>>;
  _not?: Maybe<IndexerGallery_Attribute_Count_Bool_Exp>;
  _or?: Maybe<Array<IndexerGallery_Attribute_Count_Bool_Exp>>;
  attribute?: Maybe<IndexerAttribute_Bool_Exp>;
  attribute_id?: Maybe<IndexerBigint_Comparison_Exp>;
  editions?: Maybe<IndexerBigint_Comparison_Exp>;
  gallery_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  tokens?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerGallery_Attribute_Count_Max_Fields = {
  __typename?: 'gallery_attribute_count_max_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type IndexerGallery_Attribute_Count_Min_Fields = {
  __typename?: 'gallery_attribute_count_min_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "gallery_attribute_count". */
export type IndexerGallery_Attribute_Count_Order_By = {
  attribute?: Maybe<IndexerAttribute_Order_By>;
  attribute_id?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tokens?: Maybe<IndexerOrder_By>;
};

/** select columns of table "gallery_attribute_count" */
export enum IndexerGallery_Attribute_Count_Select_Column {
  /** column name */
  AttributeId = 'attribute_id',
  /** column name */
  Editions = 'editions',
  /** column name */
  GalleryPk = 'gallery_pk',
  /** column name */
  Id = 'id',
  /** column name */
  Tokens = 'tokens'
}

/** aggregate stddev on columns */
export type IndexerGallery_Attribute_Count_Stddev_Fields = {
  __typename?: 'gallery_attribute_count_stddev_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerGallery_Attribute_Count_Stddev_Pop_Fields = {
  __typename?: 'gallery_attribute_count_stddev_pop_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerGallery_Attribute_Count_Stddev_Samp_Fields = {
  __typename?: 'gallery_attribute_count_stddev_samp_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gallery_attribute_count" */
export type IndexerGallery_Attribute_Count_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerGallery_Attribute_Count_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerGallery_Attribute_Count_Stream_Cursor_Value_Input = {
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerGallery_Attribute_Count_Sum_Fields = {
  __typename?: 'gallery_attribute_count_sum_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  editions?: Maybe<Scalars['bigint']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  tokens?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerGallery_Attribute_Count_Var_Pop_Fields = {
  __typename?: 'gallery_attribute_count_var_pop_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerGallery_Attribute_Count_Var_Samp_Fields = {
  __typename?: 'gallery_attribute_count_var_samp_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerGallery_Attribute_Count_Variance_Fields = {
  __typename?: 'gallery_attribute_count_variance_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  tokens?: Maybe<Scalars['Float']>;
};

/** aggregate avg on columns */
export type IndexerGallery_Avg_Fields = {
  __typename?: 'gallery_avg_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gallery" */
export type IndexerGallery_Avg_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "gallery". All fields are combined with a logical 'AND'. */
export type IndexerGallery_Bool_Exp = {
  _and?: Maybe<Array<IndexerGallery_Bool_Exp>>;
  _not?: Maybe<IndexerGallery_Bool_Exp>;
  _or?: Maybe<Array<IndexerGallery_Bool_Exp>>;
  active_auctions?: Maybe<IndexerInt_Comparison_Exp>;
  active_listing?: Maybe<IndexerInt_Comparison_Exp>;
  curators?: Maybe<IndexerGallery_Curator_Bool_Exp>;
  curators_aggregate?: Maybe<IndexerGallery_Curator_Aggregate_Bool_Exp>;
  description?: Maybe<IndexerString_Comparison_Exp>;
  editions?: Maybe<IndexerInt_Comparison_Exp>;
  floor_price?: Maybe<IndexerBigint_Comparison_Exp>;
  gallery_id?: Maybe<IndexerString_Comparison_Exp>;
  inserted_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  items?: Maybe<IndexerInt_Comparison_Exp>;
  last_metadata_update?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  logo?: Maybe<IndexerString_Comparison_Exp>;
  max_items?: Maybe<IndexerInt_Comparison_Exp>;
  metadata?: Maybe<IndexerString_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  owners?: Maybe<IndexerInt_Comparison_Exp>;
  pk?: Maybe<IndexerBigint_Comparison_Exp>;
  registry?: Maybe<IndexerGallery_Registry_Bool_Exp>;
  registry_id?: Maybe<IndexerBigint_Comparison_Exp>;
  slug?: Maybe<IndexerString_Comparison_Exp>;
  tokens?: Maybe<IndexerGallery_Token_Bool_Exp>;
  tokens_aggregate?: Maybe<IndexerGallery_Token_Aggregate_Bool_Exp>;
  updated_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  volume_24h?: Maybe<IndexerBigint_Comparison_Exp>;
  volume_total?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** columns and relationships of "gallery_curator" */
export type IndexerGallery_Curator = {
  __typename?: 'gallery_curator';
  curator?: Maybe<IndexerHolder>;
  curator_address: Scalars['String'];
  /** An object relationship */
  gallery?: Maybe<IndexerGallery>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
};

/** aggregated selection of "gallery_curator" */
export type IndexerGallery_Curator_Aggregate = {
  __typename?: 'gallery_curator_aggregate';
  aggregate?: Maybe<IndexerGallery_Curator_Aggregate_Fields>;
  nodes: Array<IndexerGallery_Curator>;
};

export type IndexerGallery_Curator_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerGallery_Curator_Aggregate_Bool_Exp_Count>;
};

export type IndexerGallery_Curator_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerGallery_Curator_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "gallery_curator" */
export type IndexerGallery_Curator_Aggregate_Fields = {
  __typename?: 'gallery_curator_aggregate_fields';
  avg?: Maybe<IndexerGallery_Curator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerGallery_Curator_Max_Fields>;
  min?: Maybe<IndexerGallery_Curator_Min_Fields>;
  stddev?: Maybe<IndexerGallery_Curator_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerGallery_Curator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerGallery_Curator_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerGallery_Curator_Sum_Fields>;
  var_pop?: Maybe<IndexerGallery_Curator_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerGallery_Curator_Var_Samp_Fields>;
  variance?: Maybe<IndexerGallery_Curator_Variance_Fields>;
};


/** aggregate fields of "gallery_curator" */
export type IndexerGallery_Curator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gallery_curator" */
export type IndexerGallery_Curator_Aggregate_Order_By = {
  avg?: Maybe<IndexerGallery_Curator_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerGallery_Curator_Max_Order_By>;
  min?: Maybe<IndexerGallery_Curator_Min_Order_By>;
  stddev?: Maybe<IndexerGallery_Curator_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerGallery_Curator_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerGallery_Curator_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerGallery_Curator_Sum_Order_By>;
  var_pop?: Maybe<IndexerGallery_Curator_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerGallery_Curator_Var_Samp_Order_By>;
  variance?: Maybe<IndexerGallery_Curator_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerGallery_Curator_Avg_Fields = {
  __typename?: 'gallery_curator_avg_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Avg_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "gallery_curator". All fields are combined with a logical 'AND'. */
export type IndexerGallery_Curator_Bool_Exp = {
  _and?: Maybe<Array<IndexerGallery_Curator_Bool_Exp>>;
  _not?: Maybe<IndexerGallery_Curator_Bool_Exp>;
  _or?: Maybe<Array<IndexerGallery_Curator_Bool_Exp>>;
  curator_address?: Maybe<IndexerString_Comparison_Exp>;
  gallery?: Maybe<IndexerGallery_Bool_Exp>;
  gallery_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerGallery_Curator_Max_Fields = {
  __typename?: 'gallery_curator_max_fields';
  curator_address?: Maybe<Scalars['String']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Max_Order_By = {
  curator_address?: Maybe<IndexerOrder_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerGallery_Curator_Min_Fields = {
  __typename?: 'gallery_curator_min_fields';
  curator_address?: Maybe<Scalars['String']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Min_Order_By = {
  curator_address?: Maybe<IndexerOrder_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "gallery_curator". */
export type IndexerGallery_Curator_Order_By = {
  curator_address?: Maybe<IndexerOrder_By>;
  gallery?: Maybe<IndexerGallery_Order_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** select columns of table "gallery_curator" */
export enum IndexerGallery_Curator_Select_Column {
  /** column name */
  CuratorAddress = 'curator_address',
  /** column name */
  GalleryPk = 'gallery_pk',
  /** column name */
  Id = 'id'
}

/** aggregate stddev on columns */
export type IndexerGallery_Curator_Stddev_Fields = {
  __typename?: 'gallery_curator_stddev_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Stddev_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerGallery_Curator_Stddev_Pop_Fields = {
  __typename?: 'gallery_curator_stddev_pop_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Stddev_Pop_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerGallery_Curator_Stddev_Samp_Fields = {
  __typename?: 'gallery_curator_stddev_samp_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Stddev_Samp_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "gallery_curator" */
export type IndexerGallery_Curator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerGallery_Curator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerGallery_Curator_Stream_Cursor_Value_Input = {
  curator_address?: Maybe<Scalars['String']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerGallery_Curator_Sum_Fields = {
  __typename?: 'gallery_curator_sum_fields';
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Sum_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerGallery_Curator_Var_Pop_Fields = {
  __typename?: 'gallery_curator_var_pop_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Var_Pop_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerGallery_Curator_Var_Samp_Fields = {
  __typename?: 'gallery_curator_var_samp_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Var_Samp_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerGallery_Curator_Variance_Fields = {
  __typename?: 'gallery_curator_variance_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gallery_curator" */
export type IndexerGallery_Curator_Variance_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
};

/** aggregate max on columns */
export type IndexerGallery_Max_Fields = {
  __typename?: 'gallery_max_fields';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['Int']>;
  floor_price?: Maybe<Scalars['bigint']>;
  gallery_id?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  max_items?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['bigint']>;
  registry_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "gallery" */
export type IndexerGallery_Max_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  gallery_id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  logo?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  slug?: Maybe<IndexerOrder_By>;
  updated_at?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerGallery_Min_Fields = {
  __typename?: 'gallery_min_fields';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['Int']>;
  floor_price?: Maybe<Scalars['bigint']>;
  gallery_id?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  max_items?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['bigint']>;
  registry_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "gallery" */
export type IndexerGallery_Min_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  gallery_id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  logo?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  slug?: Maybe<IndexerOrder_By>;
  updated_at?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "gallery". */
export type IndexerGallery_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  curators_aggregate?: Maybe<IndexerGallery_Curator_Aggregate_Order_By>;
  description?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  gallery_id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  logo?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry?: Maybe<IndexerGallery_Registry_Order_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  slug?: Maybe<IndexerOrder_By>;
  tokens_aggregate?: Maybe<IndexerGallery_Token_Aggregate_Order_By>;
  updated_at?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "gallery_registry" */
export type IndexerGallery_Registry = {
  __typename?: 'gallery_registry';
  /** An array relationship */
  galleries: Array<IndexerGallery>;
  /** An aggregate relationship */
  galleries_aggregate: IndexerGallery_Aggregate;
  id: Scalars['bigint'];
  inserted_at: Scalars['timestamp'];
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type: Scalars['gallery_type'];
  updated_at: Scalars['timestamp'];
};


/** columns and relationships of "gallery_registry" */
export type IndexerGallery_RegistryGalleriesArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Order_By>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};


/** columns and relationships of "gallery_registry" */
export type IndexerGallery_RegistryGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Order_By>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};

/** aggregated selection of "gallery_registry" */
export type IndexerGallery_Registry_Aggregate = {
  __typename?: 'gallery_registry_aggregate';
  aggregate?: Maybe<IndexerGallery_Registry_Aggregate_Fields>;
  nodes: Array<IndexerGallery_Registry>;
};

/** aggregate fields of "gallery_registry" */
export type IndexerGallery_Registry_Aggregate_Fields = {
  __typename?: 'gallery_registry_aggregate_fields';
  avg?: Maybe<IndexerGallery_Registry_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerGallery_Registry_Max_Fields>;
  min?: Maybe<IndexerGallery_Registry_Min_Fields>;
  stddev?: Maybe<IndexerGallery_Registry_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerGallery_Registry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerGallery_Registry_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerGallery_Registry_Sum_Fields>;
  var_pop?: Maybe<IndexerGallery_Registry_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerGallery_Registry_Var_Samp_Fields>;
  variance?: Maybe<IndexerGallery_Registry_Variance_Fields>;
};


/** aggregate fields of "gallery_registry" */
export type IndexerGallery_Registry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerGallery_Registry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerGallery_Registry_Avg_Fields = {
  __typename?: 'gallery_registry_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "gallery_registry". All fields are combined with a logical 'AND'. */
export type IndexerGallery_Registry_Bool_Exp = {
  _and?: Maybe<Array<IndexerGallery_Registry_Bool_Exp>>;
  _not?: Maybe<IndexerGallery_Registry_Bool_Exp>;
  _or?: Maybe<Array<IndexerGallery_Registry_Bool_Exp>>;
  galleries?: Maybe<IndexerGallery_Bool_Exp>;
  galleries_aggregate?: Maybe<IndexerGallery_Aggregate_Bool_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  inserted_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  slug?: Maybe<IndexerString_Comparison_Exp>;
  type?: Maybe<IndexerGallery_Type_Comparison_Exp>;
  updated_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerGallery_Registry_Max_Fields = {
  __typename?: 'gallery_registry_max_fields';
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['gallery_type']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate min on columns */
export type IndexerGallery_Registry_Min_Fields = {
  __typename?: 'gallery_registry_min_fields';
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['gallery_type']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** Ordering options when selecting data from "gallery_registry". */
export type IndexerGallery_Registry_Order_By = {
  galleries_aggregate?: Maybe<IndexerGallery_Aggregate_Order_By>;
  id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  slug?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  updated_at?: Maybe<IndexerOrder_By>;
};

/** select columns of table "gallery_registry" */
export enum IndexerGallery_Registry_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  InsertedAt = 'inserted_at',
  /** column name */
  Name = 'name',
  /** column name */
  Slug = 'slug',
  /** column name */
  Type = 'type',
  /** column name */
  UpdatedAt = 'updated_at'
}

/** aggregate stddev on columns */
export type IndexerGallery_Registry_Stddev_Fields = {
  __typename?: 'gallery_registry_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerGallery_Registry_Stddev_Pop_Fields = {
  __typename?: 'gallery_registry_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerGallery_Registry_Stddev_Samp_Fields = {
  __typename?: 'gallery_registry_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "gallery_registry" */
export type IndexerGallery_Registry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerGallery_Registry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerGallery_Registry_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  name?: Maybe<Scalars['String']>;
  slug?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['gallery_type']>;
  updated_at?: Maybe<Scalars['timestamp']>;
};

/** aggregate sum on columns */
export type IndexerGallery_Registry_Sum_Fields = {
  __typename?: 'gallery_registry_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerGallery_Registry_Var_Pop_Fields = {
  __typename?: 'gallery_registry_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerGallery_Registry_Var_Samp_Fields = {
  __typename?: 'gallery_registry_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerGallery_Registry_Variance_Fields = {
  __typename?: 'gallery_registry_variance_fields';
  id?: Maybe<Scalars['Float']>;
};

/** select columns of table "gallery" */
export enum IndexerGallery_Select_Column {
  /** column name */
  ActiveAuctions = 'active_auctions',
  /** column name */
  ActiveListing = 'active_listing',
  /** column name */
  Description = 'description',
  /** column name */
  Editions = 'editions',
  /** column name */
  FloorPrice = 'floor_price',
  /** column name */
  GalleryId = 'gallery_id',
  /** column name */
  InsertedAt = 'inserted_at',
  /** column name */
  Items = 'items',
  /** column name */
  LastMetadataUpdate = 'last_metadata_update',
  /** column name */
  Logo = 'logo',
  /** column name */
  MaxItems = 'max_items',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  Name = 'name',
  /** column name */
  Owners = 'owners',
  /** column name */
  Pk = 'pk',
  /** column name */
  RegistryId = 'registry_id',
  /** column name */
  Slug = 'slug',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  Volume_24h = 'volume_24h',
  /** column name */
  VolumeTotal = 'volume_total'
}

/** aggregate stddev on columns */
export type IndexerGallery_Stddev_Fields = {
  __typename?: 'gallery_stddev_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gallery" */
export type IndexerGallery_Stddev_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerGallery_Stddev_Pop_Fields = {
  __typename?: 'gallery_stddev_pop_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gallery" */
export type IndexerGallery_Stddev_Pop_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerGallery_Stddev_Samp_Fields = {
  __typename?: 'gallery_stddev_samp_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gallery" */
export type IndexerGallery_Stddev_Samp_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "gallery" */
export type IndexerGallery_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerGallery_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerGallery_Stream_Cursor_Value_Input = {
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  editions?: Maybe<Scalars['Int']>;
  floor_price?: Maybe<Scalars['bigint']>;
  gallery_id?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  items?: Maybe<Scalars['Int']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  max_items?: Maybe<Scalars['Int']>;
  metadata?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  owners?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['bigint']>;
  registry_id?: Maybe<Scalars['bigint']>;
  slug?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerGallery_Sum_Fields = {
  __typename?: 'gallery_sum_fields';
  active_auctions?: Maybe<Scalars['Int']>;
  active_listing?: Maybe<Scalars['Int']>;
  editions?: Maybe<Scalars['Int']>;
  floor_price?: Maybe<Scalars['bigint']>;
  items?: Maybe<Scalars['Int']>;
  max_items?: Maybe<Scalars['Int']>;
  owners?: Maybe<Scalars['Int']>;
  pk?: Maybe<Scalars['bigint']>;
  registry_id?: Maybe<Scalars['bigint']>;
  volume_24h?: Maybe<Scalars['bigint']>;
  volume_total?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "gallery" */
export type IndexerGallery_Sum_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "gallery_token" */
export type IndexerGallery_Token = {
  __typename?: 'gallery_token';
  fa_contract: Scalars['String'];
  /** An object relationship */
  gallery?: Maybe<IndexerGallery>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id: Scalars['bigint'];
  position?: Maybe<Scalars['Int']>;
  token?: Maybe<IndexerToken>;
  token_id: Scalars['String'];
};

/** aggregated selection of "gallery_token" */
export type IndexerGallery_Token_Aggregate = {
  __typename?: 'gallery_token_aggregate';
  aggregate?: Maybe<IndexerGallery_Token_Aggregate_Fields>;
  nodes: Array<IndexerGallery_Token>;
};

export type IndexerGallery_Token_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerGallery_Token_Aggregate_Bool_Exp_Count>;
};

export type IndexerGallery_Token_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerGallery_Token_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "gallery_token" */
export type IndexerGallery_Token_Aggregate_Fields = {
  __typename?: 'gallery_token_aggregate_fields';
  avg?: Maybe<IndexerGallery_Token_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerGallery_Token_Max_Fields>;
  min?: Maybe<IndexerGallery_Token_Min_Fields>;
  stddev?: Maybe<IndexerGallery_Token_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerGallery_Token_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerGallery_Token_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerGallery_Token_Sum_Fields>;
  var_pop?: Maybe<IndexerGallery_Token_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerGallery_Token_Var_Samp_Fields>;
  variance?: Maybe<IndexerGallery_Token_Variance_Fields>;
};


/** aggregate fields of "gallery_token" */
export type IndexerGallery_Token_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "gallery_token" */
export type IndexerGallery_Token_Aggregate_Order_By = {
  avg?: Maybe<IndexerGallery_Token_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerGallery_Token_Max_Order_By>;
  min?: Maybe<IndexerGallery_Token_Min_Order_By>;
  stddev?: Maybe<IndexerGallery_Token_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerGallery_Token_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerGallery_Token_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerGallery_Token_Sum_Order_By>;
  var_pop?: Maybe<IndexerGallery_Token_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerGallery_Token_Var_Samp_Order_By>;
  variance?: Maybe<IndexerGallery_Token_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerGallery_Token_Avg_Fields = {
  __typename?: 'gallery_token_avg_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "gallery_token" */
export type IndexerGallery_Token_Avg_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "gallery_token". All fields are combined with a logical 'AND'. */
export type IndexerGallery_Token_Bool_Exp = {
  _and?: Maybe<Array<IndexerGallery_Token_Bool_Exp>>;
  _not?: Maybe<IndexerGallery_Token_Bool_Exp>;
  _or?: Maybe<Array<IndexerGallery_Token_Bool_Exp>>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  gallery?: Maybe<IndexerGallery_Bool_Exp>;
  gallery_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  position?: Maybe<IndexerInt_Comparison_Exp>;
  token_id?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerGallery_Token_Max_Fields = {
  __typename?: 'gallery_token_max_fields';
  fa_contract?: Maybe<Scalars['String']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
  token_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "gallery_token" */
export type IndexerGallery_Token_Max_Order_By = {
  fa_contract?: Maybe<IndexerOrder_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerGallery_Token_Min_Fields = {
  __typename?: 'gallery_token_min_fields';
  fa_contract?: Maybe<Scalars['String']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
  token_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "gallery_token" */
export type IndexerGallery_Token_Min_Order_By = {
  fa_contract?: Maybe<IndexerOrder_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "gallery_token". */
export type IndexerGallery_Token_Order_By = {
  fa_contract?: Maybe<IndexerOrder_By>;
  gallery?: Maybe<IndexerGallery_Order_By>;
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
};

/** select columns of table "gallery_token" */
export enum IndexerGallery_Token_Select_Column {
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  GalleryPk = 'gallery_pk',
  /** column name */
  Id = 'id',
  /** column name */
  Position = 'position',
  /** column name */
  TokenId = 'token_id'
}

/** aggregate stddev on columns */
export type IndexerGallery_Token_Stddev_Fields = {
  __typename?: 'gallery_token_stddev_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "gallery_token" */
export type IndexerGallery_Token_Stddev_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerGallery_Token_Stddev_Pop_Fields = {
  __typename?: 'gallery_token_stddev_pop_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "gallery_token" */
export type IndexerGallery_Token_Stddev_Pop_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerGallery_Token_Stddev_Samp_Fields = {
  __typename?: 'gallery_token_stddev_samp_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "gallery_token" */
export type IndexerGallery_Token_Stddev_Samp_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "gallery_token" */
export type IndexerGallery_Token_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerGallery_Token_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerGallery_Token_Stream_Cursor_Value_Input = {
  fa_contract?: Maybe<Scalars['String']>;
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
  token_id?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerGallery_Token_Sum_Fields = {
  __typename?: 'gallery_token_sum_fields';
  gallery_pk?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  position?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "gallery_token" */
export type IndexerGallery_Token_Sum_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerGallery_Token_Var_Pop_Fields = {
  __typename?: 'gallery_token_var_pop_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gallery_token" */
export type IndexerGallery_Token_Var_Pop_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerGallery_Token_Var_Samp_Fields = {
  __typename?: 'gallery_token_var_samp_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gallery_token" */
export type IndexerGallery_Token_Var_Samp_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerGallery_Token_Variance_Fields = {
  __typename?: 'gallery_token_variance_fields';
  gallery_pk?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  position?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gallery_token" */
export type IndexerGallery_Token_Variance_Order_By = {
  gallery_pk?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  position?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "gallery_type". All fields are combined with logical 'AND'. */
export type IndexerGallery_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['gallery_type']>;
  _gt?: Maybe<Scalars['gallery_type']>;
  _gte?: Maybe<Scalars['gallery_type']>;
  _in?: Maybe<Array<Scalars['gallery_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['gallery_type']>;
  _lte?: Maybe<Scalars['gallery_type']>;
  _neq?: Maybe<Scalars['gallery_type']>;
  _nin?: Maybe<Array<Scalars['gallery_type']>>;
};

/** aggregate var_pop on columns */
export type IndexerGallery_Var_Pop_Fields = {
  __typename?: 'gallery_var_pop_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "gallery" */
export type IndexerGallery_Var_Pop_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerGallery_Var_Samp_Fields = {
  __typename?: 'gallery_var_samp_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "gallery" */
export type IndexerGallery_Var_Samp_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerGallery_Variance_Fields = {
  __typename?: 'gallery_variance_fields';
  active_auctions?: Maybe<Scalars['Float']>;
  active_listing?: Maybe<Scalars['Float']>;
  editions?: Maybe<Scalars['Float']>;
  floor_price?: Maybe<Scalars['Float']>;
  items?: Maybe<Scalars['Float']>;
  max_items?: Maybe<Scalars['Float']>;
  owners?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  registry_id?: Maybe<Scalars['Float']>;
  volume_24h?: Maybe<Scalars['Float']>;
  volume_total?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "gallery" */
export type IndexerGallery_Variance_Order_By = {
  active_auctions?: Maybe<IndexerOrder_By>;
  active_listing?: Maybe<IndexerOrder_By>;
  editions?: Maybe<IndexerOrder_By>;
  floor_price?: Maybe<IndexerOrder_By>;
  items?: Maybe<IndexerOrder_By>;
  max_items?: Maybe<IndexerOrder_By>;
  owners?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  registry_id?: Maybe<IndexerOrder_By>;
  volume_24h?: Maybe<IndexerOrder_By>;
  volume_total?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "holder" */
export type IndexerHolder = {
  __typename?: 'holder';
  address: Scalars['String'];
  alias?: Maybe<Scalars['String']>;
  /** An array relationship */
  collaborations: Array<IndexerInvitation>;
  /** An aggregate relationship */
  collaborations_aggregate: IndexerInvitation_Aggregate;
  /** An array relationship */
  created_tokens: Array<IndexerToken_Creator>;
  /** An aggregate relationship */
  created_tokens_aggregate: IndexerToken_Creator_Aggregate;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  dns?: Maybe<Scalars['String']>;
  /** An array relationship */
  dutch_auctions_active: Array<IndexerDutch_Auction_Active>;
  /** An aggregate relationship */
  dutch_auctions_active_aggregate: IndexerDutch_Auction_Active_Aggregate;
  /** An array relationship */
  dutch_auctions_bought: Array<IndexerDutch_Auction_Sale>;
  /** An aggregate relationship */
  dutch_auctions_bought_aggregate: IndexerDutch_Auction_Sale_Aggregate;
  /** An array relationship */
  dutch_auctions_created: Array<IndexerDutch_Auction>;
  /** An aggregate relationship */
  dutch_auctions_created_aggregate: IndexerDutch_Auction_Aggregate;
  /** An array relationship */
  dutch_auctions_sold: Array<IndexerDutch_Auction_Sale>;
  /** An aggregate relationship */
  dutch_auctions_sold_aggregate: IndexerDutch_Auction_Sale_Aggregate;
  email?: Maybe<Scalars['String']>;
  /** An array relationship */
  english_auction_bids: Array<IndexerEnglish_Auction_Bid>;
  /** An aggregate relationship */
  english_auction_bids_aggregate: IndexerEnglish_Auction_Bid_Aggregate;
  /** An array relationship */
  english_auctions_active: Array<IndexerEnglish_Auction_Active>;
  /** An aggregate relationship */
  english_auctions_active_aggregate: IndexerEnglish_Auction_Active_Aggregate;
  /** An array relationship */
  english_auctions_created: Array<IndexerEnglish_Auction>;
  /** An aggregate relationship */
  english_auctions_created_aggregate: IndexerEnglish_Auction_Aggregate;
  /** An array relationship */
  english_auctions_highest_bidder: Array<IndexerEnglish_Auction>;
  /** An aggregate relationship */
  english_auctions_highest_bidder_aggregate: IndexerEnglish_Auction_Aggregate;
  ethereum?: Maybe<Scalars['String']>;
  /** An array relationship */
  events_creator: Array<IndexerEvent>;
  /** An aggregate relationship */
  events_creator_aggregate: IndexerEvent_Aggregate;
  /** An array relationship */
  events_recipient: Array<IndexerEvent>;
  /** An aggregate relationship */
  events_recipient_aggregate: IndexerEvent_Aggregate;
  /** An array relationship */
  fa2s_created: Array<IndexerFa>;
  /** An aggregate relationship */
  fa2s_created_aggregate: IndexerFa_Aggregate;
  facebook?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  github?: Maybe<Scalars['String']>;
  gitlab?: Maybe<Scalars['String']>;
  /** An array relationship */
  held_tokens: Array<IndexerToken_Holder>;
  /** An aggregate relationship */
  held_tokens_aggregate: IndexerToken_Holder_Aggregate;
  inserted_at?: Maybe<Scalars['timestamptz']>;
  instagram?: Maybe<Scalars['String']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  /** An array relationship */
  listings_active: Array<IndexerListing_Active>;
  /** An aggregate relationship */
  listings_active_aggregate: IndexerListing_Active_Aggregate;
  /** An array relationship */
  listings_bought: Array<IndexerListing_Sale>;
  /** An aggregate relationship */
  listings_bought_aggregate: IndexerListing_Sale_Aggregate;
  /** An array relationship */
  listings_created: Array<IndexerListing>;
  /** An aggregate relationship */
  listings_created_aggregate: IndexerListing_Aggregate;
  /** An array relationship */
  listings_sold: Array<IndexerListing_Sale>;
  /** An aggregate relationship */
  listings_sold_aggregate: IndexerListing_Sale_Aggregate;
  logo?: Maybe<Scalars['String']>;
  managed_contracts: Array<IndexerFa2_Manager>;
  managed_contracts_aggregate: IndexerFa2_Manager_Aggregate;
  medium?: Maybe<Scalars['String']>;
  /** An array relationship */
  offers_accepted: Array<IndexerOffer>;
  /** An aggregate relationship */
  offers_accepted_aggregate: IndexerOffer_Aggregate;
  /** An array relationship */
  offers_active: Array<IndexerOffer_Active>;
  /** An aggregate relationship */
  offers_active_aggregate: IndexerOffer_Active_Aggregate;
  /** An array relationship */
  offers_created: Array<IndexerOffer>;
  /** An aggregate relationship */
  offers_created_aggregate: IndexerOffer_Aggregate;
  /** An array relationship */
  open_edition_active: Array<IndexerOpen_Edition_Active>;
  /** An aggregate relationship */
  open_edition_active_aggregate: IndexerOpen_Edition_Active_Aggregate;
  /** An array relationship */
  open_edition_created: Array<IndexerOpen_Edition>;
  /** An aggregate relationship */
  open_edition_created_aggregate: IndexerOpen_Edition_Aggregate;
  /** An array relationship */
  operator_operators: Array<IndexerToken_Operator>;
  /** An aggregate relationship */
  operator_operators_aggregate: IndexerToken_Operator_Aggregate;
  /** An array relationship */
  owner_operators: Array<IndexerToken_Operator>;
  /** An aggregate relationship */
  owner_operators_aggregate: IndexerToken_Operator_Aggregate;
  /** An array relationship */
  receiver_royalties: Array<IndexerRoyalties>;
  /** An aggregate relationship */
  receiver_royalties_aggregate: IndexerRoyalties_Aggregate;
  reddit?: Maybe<Scalars['String']>;
  /** An array relationship */
  sales_stats: Array<IndexerSales_Stat>;
  /** An aggregate relationship */
  sales_stats_aggregate: IndexerSales_Stat_Aggregate;
  slack?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tzdomain?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};


/** columns and relationships of "holder" */
export type IndexerHolderCollaborationsArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderCollaborations_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderCreated_TokensArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderCreated_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_BoughtArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_Bought_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_CreatedArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_Created_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_SoldArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderDutch_Auctions_Sold_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auction_BidsArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auction_Bids_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auctions_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auctions_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auctions_CreatedArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auctions_Created_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auctions_Highest_BidderArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEnglish_Auctions_Highest_Bidder_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEvents_CreatorArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEvents_Creator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEvents_RecipientArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderEvents_Recipient_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderFa2s_CreatedArgs = {
  distinct_on?: Maybe<Array<IndexerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa_Order_By>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderFa2s_Created_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa_Order_By>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderHeld_TokensArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderHeld_Tokens_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_BoughtArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_Bought_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_CreatedArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_Created_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_SoldArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderListings_Sold_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderManaged_ContractsArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderManaged_Contracts_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOffers_AcceptedArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOffers_Accepted_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOffers_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOffers_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOffers_CreatedArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOffers_Created_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOpen_Edition_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOpen_Edition_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOpen_Edition_CreatedArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOpen_Edition_Created_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOperator_OperatorsArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOperator_Operators_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOwner_OperatorsArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderOwner_Operators_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderReceiver_RoyaltiesArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderReceiver_Royalties_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderSales_StatsArgs = {
  distinct_on?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerSales_Stat_Order_By>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};


/** columns and relationships of "holder" */
export type IndexerHolderSales_Stats_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerSales_Stat_Order_By>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};

/** aggregated selection of "holder" */
export type IndexerHolder_Aggregate = {
  __typename?: 'holder_aggregate';
  aggregate?: Maybe<IndexerHolder_Aggregate_Fields>;
  nodes: Array<IndexerHolder>;
};

/** aggregate fields of "holder" */
export type IndexerHolder_Aggregate_Fields = {
  __typename?: 'holder_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IndexerHolder_Max_Fields>;
  min?: Maybe<IndexerHolder_Min_Fields>;
};


/** aggregate fields of "holder" */
export type IndexerHolder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerHolder_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "holder". All fields are combined with a logical 'AND'. */
export type IndexerHolder_Bool_Exp = {
  _and?: Maybe<Array<IndexerHolder_Bool_Exp>>;
  _not?: Maybe<IndexerHolder_Bool_Exp>;
  _or?: Maybe<Array<IndexerHolder_Bool_Exp>>;
  address?: Maybe<IndexerString_Comparison_Exp>;
  alias?: Maybe<IndexerString_Comparison_Exp>;
  collaborations?: Maybe<IndexerInvitation_Bool_Exp>;
  collaborations_aggregate?: Maybe<IndexerInvitation_Aggregate_Bool_Exp>;
  created_tokens?: Maybe<IndexerToken_Creator_Bool_Exp>;
  created_tokens_aggregate?: Maybe<IndexerToken_Creator_Aggregate_Bool_Exp>;
  description?: Maybe<IndexerString_Comparison_Exp>;
  discord?: Maybe<IndexerString_Comparison_Exp>;
  dns?: Maybe<IndexerString_Comparison_Exp>;
  dutch_auctions_active?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
  dutch_auctions_active_aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Bool_Exp>;
  dutch_auctions_bought?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
  dutch_auctions_bought_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Bool_Exp>;
  dutch_auctions_created?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  dutch_auctions_created_aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Bool_Exp>;
  dutch_auctions_sold?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
  dutch_auctions_sold_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Bool_Exp>;
  email?: Maybe<IndexerString_Comparison_Exp>;
  english_auction_bids?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
  english_auction_bids_aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Bool_Exp>;
  english_auctions_active?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
  english_auctions_active_aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Bool_Exp>;
  english_auctions_created?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  english_auctions_created_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp>;
  english_auctions_highest_bidder?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  english_auctions_highest_bidder_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp>;
  ethereum?: Maybe<IndexerString_Comparison_Exp>;
  events_creator?: Maybe<IndexerEvent_Bool_Exp>;
  events_creator_aggregate?: Maybe<IndexerEvent_Aggregate_Bool_Exp>;
  events_recipient?: Maybe<IndexerEvent_Bool_Exp>;
  events_recipient_aggregate?: Maybe<IndexerEvent_Aggregate_Bool_Exp>;
  fa2s_created?: Maybe<IndexerFa_Bool_Exp>;
  fa2s_created_aggregate?: Maybe<IndexerFa_Aggregate_Bool_Exp>;
  facebook?: Maybe<IndexerString_Comparison_Exp>;
  flag?: Maybe<IndexerFlag_Type_Comparison_Exp>;
  github?: Maybe<IndexerString_Comparison_Exp>;
  gitlab?: Maybe<IndexerString_Comparison_Exp>;
  held_tokens?: Maybe<IndexerToken_Holder_Bool_Exp>;
  held_tokens_aggregate?: Maybe<IndexerToken_Holder_Aggregate_Bool_Exp>;
  inserted_at?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  instagram?: Maybe<IndexerString_Comparison_Exp>;
  last_metadata_update?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  listings_active?: Maybe<IndexerListing_Active_Bool_Exp>;
  listings_active_aggregate?: Maybe<IndexerListing_Active_Aggregate_Bool_Exp>;
  listings_bought?: Maybe<IndexerListing_Sale_Bool_Exp>;
  listings_bought_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Bool_Exp>;
  listings_created?: Maybe<IndexerListing_Bool_Exp>;
  listings_created_aggregate?: Maybe<IndexerListing_Aggregate_Bool_Exp>;
  listings_sold?: Maybe<IndexerListing_Sale_Bool_Exp>;
  listings_sold_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Bool_Exp>;
  logo?: Maybe<IndexerString_Comparison_Exp>;
  medium?: Maybe<IndexerString_Comparison_Exp>;
  offers_accepted?: Maybe<IndexerOffer_Bool_Exp>;
  offers_accepted_aggregate?: Maybe<IndexerOffer_Aggregate_Bool_Exp>;
  offers_active?: Maybe<IndexerOffer_Active_Bool_Exp>;
  offers_active_aggregate?: Maybe<IndexerOffer_Active_Aggregate_Bool_Exp>;
  offers_created?: Maybe<IndexerOffer_Bool_Exp>;
  offers_created_aggregate?: Maybe<IndexerOffer_Aggregate_Bool_Exp>;
  open_edition_active?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
  open_edition_active_aggregate?: Maybe<IndexerOpen_Edition_Active_Aggregate_Bool_Exp>;
  open_edition_created?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  open_edition_created_aggregate?: Maybe<IndexerOpen_Edition_Aggregate_Bool_Exp>;
  operator_operators?: Maybe<IndexerToken_Operator_Bool_Exp>;
  operator_operators_aggregate?: Maybe<IndexerToken_Operator_Aggregate_Bool_Exp>;
  owner_operators?: Maybe<IndexerToken_Operator_Bool_Exp>;
  owner_operators_aggregate?: Maybe<IndexerToken_Operator_Aggregate_Bool_Exp>;
  receiver_royalties?: Maybe<IndexerRoyalties_Bool_Exp>;
  receiver_royalties_aggregate?: Maybe<IndexerRoyalties_Aggregate_Bool_Exp>;
  reddit?: Maybe<IndexerString_Comparison_Exp>;
  sales_stats?: Maybe<IndexerSales_Stat_Bool_Exp>;
  sales_stats_aggregate?: Maybe<IndexerSales_Stat_Aggregate_Bool_Exp>;
  slack?: Maybe<IndexerString_Comparison_Exp>;
  support?: Maybe<IndexerString_Comparison_Exp>;
  telegram?: Maybe<IndexerString_Comparison_Exp>;
  twitter?: Maybe<IndexerString_Comparison_Exp>;
  tzdomain?: Maybe<IndexerString_Comparison_Exp>;
  website?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerHolder_Max_Fields = {
  __typename?: 'holder_max_fields';
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  dns?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ethereum?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  github?: Maybe<Scalars['String']>;
  gitlab?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['timestamptz']>;
  instagram?: Maybe<Scalars['String']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  slack?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tzdomain?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IndexerHolder_Min_Fields = {
  __typename?: 'holder_min_fields';
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  dns?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ethereum?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  github?: Maybe<Scalars['String']>;
  gitlab?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['timestamptz']>;
  instagram?: Maybe<Scalars['String']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  slack?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tzdomain?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "holder". */
export type IndexerHolder_Order_By = {
  address?: Maybe<IndexerOrder_By>;
  alias?: Maybe<IndexerOrder_By>;
  collaborations_aggregate?: Maybe<IndexerInvitation_Aggregate_Order_By>;
  created_tokens_aggregate?: Maybe<IndexerToken_Creator_Aggregate_Order_By>;
  description?: Maybe<IndexerOrder_By>;
  discord?: Maybe<IndexerOrder_By>;
  dns?: Maybe<IndexerOrder_By>;
  dutch_auctions_active_aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Order_By>;
  dutch_auctions_bought_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Order_By>;
  dutch_auctions_created_aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Order_By>;
  dutch_auctions_sold_aggregate?: Maybe<IndexerDutch_Auction_Sale_Aggregate_Order_By>;
  email?: Maybe<IndexerOrder_By>;
  english_auction_bids_aggregate?: Maybe<IndexerEnglish_Auction_Bid_Aggregate_Order_By>;
  english_auctions_active_aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Order_By>;
  english_auctions_created_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Order_By>;
  english_auctions_highest_bidder_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Order_By>;
  ethereum?: Maybe<IndexerOrder_By>;
  events_creator_aggregate?: Maybe<IndexerEvent_Aggregate_Order_By>;
  events_recipient_aggregate?: Maybe<IndexerEvent_Aggregate_Order_By>;
  fa2s_created_aggregate?: Maybe<IndexerFa_Aggregate_Order_By>;
  facebook?: Maybe<IndexerOrder_By>;
  flag?: Maybe<IndexerOrder_By>;
  github?: Maybe<IndexerOrder_By>;
  gitlab?: Maybe<IndexerOrder_By>;
  held_tokens_aggregate?: Maybe<IndexerToken_Holder_Aggregate_Order_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  instagram?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  listings_active_aggregate?: Maybe<IndexerListing_Active_Aggregate_Order_By>;
  listings_bought_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Order_By>;
  listings_created_aggregate?: Maybe<IndexerListing_Aggregate_Order_By>;
  listings_sold_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Order_By>;
  logo?: Maybe<IndexerOrder_By>;
  medium?: Maybe<IndexerOrder_By>;
  offers_accepted_aggregate?: Maybe<IndexerOffer_Aggregate_Order_By>;
  offers_active_aggregate?: Maybe<IndexerOffer_Active_Aggregate_Order_By>;
  offers_created_aggregate?: Maybe<IndexerOffer_Aggregate_Order_By>;
  open_edition_active_aggregate?: Maybe<IndexerOpen_Edition_Active_Aggregate_Order_By>;
  open_edition_created_aggregate?: Maybe<IndexerOpen_Edition_Aggregate_Order_By>;
  operator_operators_aggregate?: Maybe<IndexerToken_Operator_Aggregate_Order_By>;
  owner_operators_aggregate?: Maybe<IndexerToken_Operator_Aggregate_Order_By>;
  receiver_royalties_aggregate?: Maybe<IndexerRoyalties_Aggregate_Order_By>;
  reddit?: Maybe<IndexerOrder_By>;
  sales_stats_aggregate?: Maybe<IndexerSales_Stat_Aggregate_Order_By>;
  slack?: Maybe<IndexerOrder_By>;
  support?: Maybe<IndexerOrder_By>;
  telegram?: Maybe<IndexerOrder_By>;
  twitter?: Maybe<IndexerOrder_By>;
  tzdomain?: Maybe<IndexerOrder_By>;
  website?: Maybe<IndexerOrder_By>;
};

/** select columns of table "holder" */
export enum IndexerHolder_Select_Column {
  /** column name */
  Address = 'address',
  /** column name */
  Alias = 'alias',
  /** column name */
  Description = 'description',
  /** column name */
  Discord = 'discord',
  /** column name */
  Dns = 'dns',
  /** column name */
  Email = 'email',
  /** column name */
  Ethereum = 'ethereum',
  /** column name */
  Facebook = 'facebook',
  /** column name */
  Flag = 'flag',
  /** column name */
  Github = 'github',
  /** column name */
  Gitlab = 'gitlab',
  /** column name */
  InsertedAt = 'inserted_at',
  /** column name */
  Instagram = 'instagram',
  /** column name */
  LastMetadataUpdate = 'last_metadata_update',
  /** column name */
  Logo = 'logo',
  /** column name */
  Medium = 'medium',
  /** column name */
  Reddit = 'reddit',
  /** column name */
  Slack = 'slack',
  /** column name */
  Support = 'support',
  /** column name */
  Telegram = 'telegram',
  /** column name */
  Twitter = 'twitter',
  /** column name */
  Tzdomain = 'tzdomain',
  /** column name */
  Website = 'website'
}

/** Streaming cursor of the table "holder" */
export type IndexerHolder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerHolder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerHolder_Stream_Cursor_Value_Input = {
  address?: Maybe<Scalars['String']>;
  alias?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  discord?: Maybe<Scalars['String']>;
  dns?: Maybe<Scalars['String']>;
  email?: Maybe<Scalars['String']>;
  ethereum?: Maybe<Scalars['String']>;
  facebook?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  github?: Maybe<Scalars['String']>;
  gitlab?: Maybe<Scalars['String']>;
  inserted_at?: Maybe<Scalars['timestamptz']>;
  instagram?: Maybe<Scalars['String']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  logo?: Maybe<Scalars['String']>;
  medium?: Maybe<Scalars['String']>;
  reddit?: Maybe<Scalars['String']>;
  slack?: Maybe<Scalars['String']>;
  support?: Maybe<Scalars['String']>;
  telegram?: Maybe<Scalars['String']>;
  twitter?: Maybe<Scalars['String']>;
  tzdomain?: Maybe<Scalars['String']>;
  website?: Maybe<Scalars['String']>;
};

/** columns and relationships of "invitation" */
export type IndexerInvitation = {
  __typename?: 'invitation';
  collaborator_address: Scalars['String'];
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  history?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  holder: IndexerHolder;
  id: Scalars['bigint'];
  level: Scalars['Int'];
  status?: Maybe<Scalars['invitation_type']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "invitation" */
export type IndexerInvitationHistoryArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "invitation" */
export type IndexerInvitation_Aggregate = {
  __typename?: 'invitation_aggregate';
  aggregate?: Maybe<IndexerInvitation_Aggregate_Fields>;
  nodes: Array<IndexerInvitation>;
};

export type IndexerInvitation_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerInvitation_Aggregate_Bool_Exp_Count>;
};

export type IndexerInvitation_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerInvitation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerInvitation_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "invitation" */
export type IndexerInvitation_Aggregate_Fields = {
  __typename?: 'invitation_aggregate_fields';
  avg?: Maybe<IndexerInvitation_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerInvitation_Max_Fields>;
  min?: Maybe<IndexerInvitation_Min_Fields>;
  stddev?: Maybe<IndexerInvitation_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerInvitation_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerInvitation_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerInvitation_Sum_Fields>;
  var_pop?: Maybe<IndexerInvitation_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerInvitation_Var_Samp_Fields>;
  variance?: Maybe<IndexerInvitation_Variance_Fields>;
};


/** aggregate fields of "invitation" */
export type IndexerInvitation_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerInvitation_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "invitation" */
export type IndexerInvitation_Aggregate_Order_By = {
  avg?: Maybe<IndexerInvitation_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerInvitation_Max_Order_By>;
  min?: Maybe<IndexerInvitation_Min_Order_By>;
  stddev?: Maybe<IndexerInvitation_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerInvitation_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerInvitation_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerInvitation_Sum_Order_By>;
  var_pop?: Maybe<IndexerInvitation_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerInvitation_Var_Samp_Order_By>;
  variance?: Maybe<IndexerInvitation_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerInvitation_Avg_Fields = {
  __typename?: 'invitation_avg_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "invitation" */
export type IndexerInvitation_Avg_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "invitation". All fields are combined with a logical 'AND'. */
export type IndexerInvitation_Bool_Exp = {
  _and?: Maybe<Array<IndexerInvitation_Bool_Exp>>;
  _not?: Maybe<IndexerInvitation_Bool_Exp>;
  _or?: Maybe<Array<IndexerInvitation_Bool_Exp>>;
  collaborator_address?: Maybe<IndexerString_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  history?: Maybe<IndexerJsonb_Comparison_Exp>;
  holder?: Maybe<IndexerHolder_Bool_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  status?: Maybe<IndexerInvitation_Type_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerInvitation_Max_Fields = {
  __typename?: 'invitation_max_fields';
  collaborator_address?: Maybe<Scalars['String']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['invitation_type']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "invitation" */
export type IndexerInvitation_Max_Order_By = {
  collaborator_address?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerInvitation_Min_Fields = {
  __typename?: 'invitation_min_fields';
  collaborator_address?: Maybe<Scalars['String']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['invitation_type']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "invitation" */
export type IndexerInvitation_Min_Order_By = {
  collaborator_address?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "invitation". */
export type IndexerInvitation_Order_By = {
  collaborator_address?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  history?: Maybe<IndexerOrder_By>;
  holder?: Maybe<IndexerHolder_Order_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** select columns of table "invitation" */
export enum IndexerInvitation_Select_Column {
  /** column name */
  CollaboratorAddress = 'collaborator_address',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  History = 'history',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  Status = 'status',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  UpdateTimestamp = 'update_timestamp'
}

/** aggregate stddev on columns */
export type IndexerInvitation_Stddev_Fields = {
  __typename?: 'invitation_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "invitation" */
export type IndexerInvitation_Stddev_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerInvitation_Stddev_Pop_Fields = {
  __typename?: 'invitation_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "invitation" */
export type IndexerInvitation_Stddev_Pop_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerInvitation_Stddev_Samp_Fields = {
  __typename?: 'invitation_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "invitation" */
export type IndexerInvitation_Stddev_Samp_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "invitation" */
export type IndexerInvitation_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerInvitation_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerInvitation_Stream_Cursor_Value_Input = {
  collaborator_address?: Maybe<Scalars['String']>;
  fa_contract?: Maybe<Scalars['String']>;
  history?: Maybe<Scalars['jsonb']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  status?: Maybe<Scalars['invitation_type']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IndexerInvitation_Sum_Fields = {
  __typename?: 'invitation_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "invitation" */
export type IndexerInvitation_Sum_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "invitation_type". All fields are combined with logical 'AND'. */
export type IndexerInvitation_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['invitation_type']>;
  _gt?: Maybe<Scalars['invitation_type']>;
  _gte?: Maybe<Scalars['invitation_type']>;
  _in?: Maybe<Array<Scalars['invitation_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['invitation_type']>;
  _lte?: Maybe<Scalars['invitation_type']>;
  _neq?: Maybe<Scalars['invitation_type']>;
  _nin?: Maybe<Array<Scalars['invitation_type']>>;
};

/** aggregate var_pop on columns */
export type IndexerInvitation_Var_Pop_Fields = {
  __typename?: 'invitation_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "invitation" */
export type IndexerInvitation_Var_Pop_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerInvitation_Var_Samp_Fields = {
  __typename?: 'invitation_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "invitation" */
export type IndexerInvitation_Var_Samp_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerInvitation_Variance_Fields = {
  __typename?: 'invitation_variance_fields';
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "invitation" */
export type IndexerInvitation_Variance_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
};


export type IndexerJsonb_Cast_Exp = {
  String?: Maybe<IndexerString_Comparison_Exp>;
};

/** Boolean expression to compare columns of type "jsonb". All fields are combined with logical 'AND'. */
export type IndexerJsonb_Comparison_Exp = {
  _cast?: Maybe<IndexerJsonb_Cast_Exp>;
  /** is the column contained in the given json value */
  _contained_in?: Maybe<Scalars['jsonb']>;
  /** does the column contain the given json value at the top level */
  _contains?: Maybe<Scalars['jsonb']>;
  _eq?: Maybe<Scalars['jsonb']>;
  _gt?: Maybe<Scalars['jsonb']>;
  _gte?: Maybe<Scalars['jsonb']>;
  /** does the string exist as a top-level key in the column */
  _has_key?: Maybe<Scalars['String']>;
  /** do all of these strings exist as top-level keys in the column */
  _has_keys_all?: Maybe<Array<Scalars['String']>>;
  /** do any of these strings exist as top-level keys in the column */
  _has_keys_any?: Maybe<Array<Scalars['String']>>;
  _in?: Maybe<Array<Scalars['jsonb']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['jsonb']>;
  _lte?: Maybe<Scalars['jsonb']>;
  _neq?: Maybe<Scalars['jsonb']>;
  _nin?: Maybe<Array<Scalars['jsonb']>>;
};


/** Boolean expression to compare columns of type "ledger_type". All fields are combined with logical 'AND'. */
export type IndexerLedger_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['ledger_type']>;
  _gt?: Maybe<Scalars['ledger_type']>;
  _gte?: Maybe<Scalars['ledger_type']>;
  _in?: Maybe<Array<Scalars['ledger_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['ledger_type']>;
  _lte?: Maybe<Scalars['ledger_type']>;
  _neq?: Maybe<Scalars['ledger_type']>;
  _nin?: Maybe<Array<Scalars['ledger_type']>>;
};

/** columns and relationships of "listing" */
export type IndexerListing = {
  __typename?: 'listing';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An array relationship */
  listing_sales: Array<IndexerListing_Sale>;
  /** An aggregate relationship */
  listing_sales_aggregate: IndexerListing_Sale_Aggregate;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller: IndexerHolder;
  seller_address: Scalars['String'];
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['auction_status']>;
  /** An object relationship */
  target?: Maybe<IndexerHolder>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties: Scalars['Boolean'];
};


/** columns and relationships of "listing" */
export type IndexerListingListing_SalesArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "listing" */
export type IndexerListingListing_Sales_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "listing" */
export type IndexerListingSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "listing_active" */
export type IndexerListing_Active = {
  __typename?: 'listing_active';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace?: Maybe<IndexerMarketplace_Contract>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  target?: Maybe<IndexerHolder>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "listing_active" */
export type IndexerListing_ActiveSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "listing_active" */
export type IndexerListing_Active_Aggregate = {
  __typename?: 'listing_active_aggregate';
  aggregate?: Maybe<IndexerListing_Active_Aggregate_Fields>;
  nodes: Array<IndexerListing_Active>;
};

export type IndexerListing_Active_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerListing_Active_Aggregate_Bool_Exp_Count>;
};

export type IndexerListing_Active_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerListing_Active_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "listing_active" */
export type IndexerListing_Active_Aggregate_Fields = {
  __typename?: 'listing_active_aggregate_fields';
  avg?: Maybe<IndexerListing_Active_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerListing_Active_Max_Fields>;
  min?: Maybe<IndexerListing_Active_Min_Fields>;
  stddev?: Maybe<IndexerListing_Active_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerListing_Active_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerListing_Active_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerListing_Active_Sum_Fields>;
  var_pop?: Maybe<IndexerListing_Active_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerListing_Active_Var_Samp_Fields>;
  variance?: Maybe<IndexerListing_Active_Variance_Fields>;
};


/** aggregate fields of "listing_active" */
export type IndexerListing_Active_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "listing_active" */
export type IndexerListing_Active_Aggregate_Order_By = {
  avg?: Maybe<IndexerListing_Active_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerListing_Active_Max_Order_By>;
  min?: Maybe<IndexerListing_Active_Min_Order_By>;
  stddev?: Maybe<IndexerListing_Active_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerListing_Active_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerListing_Active_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerListing_Active_Sum_Order_By>;
  var_pop?: Maybe<IndexerListing_Active_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerListing_Active_Var_Samp_Order_By>;
  variance?: Maybe<IndexerListing_Active_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerListing_Active_Avg_Fields = {
  __typename?: 'listing_active_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "listing_active" */
export type IndexerListing_Active_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "listing_active". All fields are combined with a logical 'AND'. */
export type IndexerListing_Active_Bool_Exp = {
  _and?: Maybe<Array<IndexerListing_Active_Bool_Exp>>;
  _not?: Maybe<IndexerListing_Active_Bool_Exp>;
  _or?: Maybe<Array<IndexerListing_Active_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  amount_left?: Maybe<IndexerInt_Comparison_Exp>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  end_price?: Maybe<IndexerBigint_Comparison_Exp>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  start_price?: Maybe<IndexerBigint_Comparison_Exp>;
  target?: Maybe<IndexerHolder_Bool_Exp>;
  target_address?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerListing_Active_Max_Fields = {
  __typename?: 'listing_active_max_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "listing_active" */
export type IndexerListing_Active_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerListing_Active_Min_Fields = {
  __typename?: 'listing_active_min_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "listing_active" */
export type IndexerListing_Active_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "listing_active". */
export type IndexerListing_Active_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  target?: Maybe<IndexerHolder_Order_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** select columns of table "listing_active" */
export enum IndexerListing_Active_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountLeft = 'amount_left',
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EndPrice = 'end_price',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  StartPrice = 'start_price',
  /** column name */
  TargetAddress = 'target_address',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp'
}

/** aggregate stddev on columns */
export type IndexerListing_Active_Stddev_Fields = {
  __typename?: 'listing_active_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "listing_active" */
export type IndexerListing_Active_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerListing_Active_Stddev_Pop_Fields = {
  __typename?: 'listing_active_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "listing_active" */
export type IndexerListing_Active_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerListing_Active_Stddev_Samp_Fields = {
  __typename?: 'listing_active_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "listing_active" */
export type IndexerListing_Active_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "listing_active" */
export type IndexerListing_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerListing_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerListing_Active_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IndexerListing_Active_Sum_Fields = {
  __typename?: 'listing_active_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  start_price?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "listing_active" */
export type IndexerListing_Active_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerListing_Active_Var_Pop_Fields = {
  __typename?: 'listing_active_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "listing_active" */
export type IndexerListing_Active_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerListing_Active_Var_Samp_Fields = {
  __typename?: 'listing_active_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "listing_active" */
export type IndexerListing_Active_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerListing_Active_Variance_Fields = {
  __typename?: 'listing_active_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "listing_active" */
export type IndexerListing_Active_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregated selection of "listing" */
export type IndexerListing_Aggregate = {
  __typename?: 'listing_aggregate';
  aggregate?: Maybe<IndexerListing_Aggregate_Fields>;
  nodes: Array<IndexerListing>;
};

export type IndexerListing_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerListing_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerListing_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerListing_Aggregate_Bool_Exp_Count>;
};

export type IndexerListing_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerListing_Select_Column_Listing_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerListing_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerListing_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerListing_Select_Column_Listing_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerListing_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerListing_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerListing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerListing_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "listing" */
export type IndexerListing_Aggregate_Fields = {
  __typename?: 'listing_aggregate_fields';
  avg?: Maybe<IndexerListing_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerListing_Max_Fields>;
  min?: Maybe<IndexerListing_Min_Fields>;
  stddev?: Maybe<IndexerListing_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerListing_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerListing_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerListing_Sum_Fields>;
  var_pop?: Maybe<IndexerListing_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerListing_Var_Samp_Fields>;
  variance?: Maybe<IndexerListing_Variance_Fields>;
};


/** aggregate fields of "listing" */
export type IndexerListing_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerListing_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "listing" */
export type IndexerListing_Aggregate_Order_By = {
  avg?: Maybe<IndexerListing_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerListing_Max_Order_By>;
  min?: Maybe<IndexerListing_Min_Order_By>;
  stddev?: Maybe<IndexerListing_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerListing_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerListing_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerListing_Sum_Order_By>;
  var_pop?: Maybe<IndexerListing_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerListing_Var_Samp_Order_By>;
  variance?: Maybe<IndexerListing_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerListing_Avg_Fields = {
  __typename?: 'listing_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "listing" */
export type IndexerListing_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "listing". All fields are combined with a logical 'AND'. */
export type IndexerListing_Bool_Exp = {
  _and?: Maybe<Array<IndexerListing_Bool_Exp>>;
  _not?: Maybe<IndexerListing_Bool_Exp>;
  _or?: Maybe<Array<IndexerListing_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  amount_left?: Maybe<IndexerInt_Comparison_Exp>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  end_price?: Maybe<IndexerBigint_Comparison_Exp>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  listing_sales?: Maybe<IndexerListing_Sale_Bool_Exp>;
  listing_sales_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Bool_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  start_price?: Maybe<IndexerBigint_Comparison_Exp>;
  status?: Maybe<IndexerAuction_Status_Comparison_Exp>;
  target?: Maybe<IndexerHolder_Bool_Exp>;
  target_address?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  valid_royalties?: Maybe<IndexerBoolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerListing_Max_Fields = {
  __typename?: 'listing_max_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['auction_status']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "listing" */
export type IndexerListing_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerListing_Min_Fields = {
  __typename?: 'listing_min_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  start_price?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['auction_status']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "listing" */
export type IndexerListing_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "listing". */
export type IndexerListing_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_sales_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Order_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  target?: Maybe<IndexerHolder_Order_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
  valid_royalties?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "listing_sale" */
export type IndexerListing_Sale = {
  __typename?: 'listing_sale';
  amount?: Maybe<Scalars['Int']>;
  /** An object relationship */
  buyer?: Maybe<IndexerHolder>;
  buyer_address?: Maybe<Scalars['String']>;
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  listing?: Maybe<IndexerListing>;
  listing_id?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  /** An object relationship */
  sender?: Maybe<IndexerHolder>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "listing_sale" */
export type IndexerListing_Sale_Aggregate = {
  __typename?: 'listing_sale_aggregate';
  aggregate?: Maybe<IndexerListing_Sale_Aggregate_Fields>;
  nodes: Array<IndexerListing_Sale>;
};

export type IndexerListing_Sale_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerListing_Sale_Aggregate_Bool_Exp_Count>;
};

export type IndexerListing_Sale_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerListing_Sale_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "listing_sale" */
export type IndexerListing_Sale_Aggregate_Fields = {
  __typename?: 'listing_sale_aggregate_fields';
  avg?: Maybe<IndexerListing_Sale_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerListing_Sale_Max_Fields>;
  min?: Maybe<IndexerListing_Sale_Min_Fields>;
  stddev?: Maybe<IndexerListing_Sale_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerListing_Sale_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerListing_Sale_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerListing_Sale_Sum_Fields>;
  var_pop?: Maybe<IndexerListing_Sale_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerListing_Sale_Var_Samp_Fields>;
  variance?: Maybe<IndexerListing_Sale_Variance_Fields>;
};


/** aggregate fields of "listing_sale" */
export type IndexerListing_Sale_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "listing_sale" */
export type IndexerListing_Sale_Aggregate_Order_By = {
  avg?: Maybe<IndexerListing_Sale_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerListing_Sale_Max_Order_By>;
  min?: Maybe<IndexerListing_Sale_Min_Order_By>;
  stddev?: Maybe<IndexerListing_Sale_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerListing_Sale_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerListing_Sale_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerListing_Sale_Sum_Order_By>;
  var_pop?: Maybe<IndexerListing_Sale_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerListing_Sale_Var_Samp_Order_By>;
  variance?: Maybe<IndexerListing_Sale_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerListing_Sale_Avg_Fields = {
  __typename?: 'listing_sale_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "listing_sale" */
export type IndexerListing_Sale_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "listing_sale". All fields are combined with a logical 'AND'. */
export type IndexerListing_Sale_Bool_Exp = {
  _and?: Maybe<Array<IndexerListing_Sale_Bool_Exp>>;
  _not?: Maybe<IndexerListing_Sale_Bool_Exp>;
  _or?: Maybe<Array<IndexerListing_Sale_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  buyer?: Maybe<IndexerHolder_Bool_Exp>;
  buyer_address?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  listing?: Maybe<IndexerListing_Bool_Exp>;
  listing_id?: Maybe<IndexerBigint_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  sender?: Maybe<IndexerHolder_Bool_Exp>;
  sender_address?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerListing_Sale_Max_Fields = {
  __typename?: 'listing_sale_max_fields';
  amount?: Maybe<Scalars['Int']>;
  buyer_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  listing_id?: Maybe<Scalars['bigint']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "listing_sale" */
export type IndexerListing_Sale_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  sender_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerListing_Sale_Min_Fields = {
  __typename?: 'listing_sale_min_fields';
  amount?: Maybe<Scalars['Int']>;
  buyer_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  listing_id?: Maybe<Scalars['bigint']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "listing_sale" */
export type IndexerListing_Sale_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  sender_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "listing_sale". */
export type IndexerListing_Sale_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  buyer?: Maybe<IndexerHolder_Order_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing?: Maybe<IndexerListing_Order_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  sender?: Maybe<IndexerHolder_Order_By>;
  sender_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "listing_sale" */
export enum IndexerListing_Sale_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  BuyerAddress = 'buyer_address',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  ListingId = 'listing_id',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  SenderAddress = 'sender_address',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerListing_Sale_Stddev_Fields = {
  __typename?: 'listing_sale_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "listing_sale" */
export type IndexerListing_Sale_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerListing_Sale_Stddev_Pop_Fields = {
  __typename?: 'listing_sale_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "listing_sale" */
export type IndexerListing_Sale_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerListing_Sale_Stddev_Samp_Fields = {
  __typename?: 'listing_sale_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "listing_sale" */
export type IndexerListing_Sale_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "listing_sale" */
export type IndexerListing_Sale_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerListing_Sale_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerListing_Sale_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  buyer_address?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  listing_id?: Maybe<Scalars['bigint']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  sender_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerListing_Sale_Sum_Fields = {
  __typename?: 'listing_sale_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  listing_id?: Maybe<Scalars['bigint']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "listing_sale" */
export type IndexerListing_Sale_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerListing_Sale_Var_Pop_Fields = {
  __typename?: 'listing_sale_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "listing_sale" */
export type IndexerListing_Sale_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerListing_Sale_Var_Samp_Fields = {
  __typename?: 'listing_sale_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "listing_sale" */
export type IndexerListing_Sale_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerListing_Sale_Variance_Fields = {
  __typename?: 'listing_sale_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  listing_id?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "listing_sale" */
export type IndexerListing_Sale_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_id?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "listing" */
export enum IndexerListing_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  AmountLeft = 'amount_left',
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  EndPrice = 'end_price',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  StartPrice = 'start_price',
  /** column name */
  Status = 'status',
  /** column name */
  TargetAddress = 'target_address',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp',
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "listing_aggregate_bool_exp_bool_and_arguments_columns" columns of table "listing" */
export enum IndexerListing_Select_Column_Listing_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "listing_aggregate_bool_exp_bool_or_arguments_columns" columns of table "listing" */
export enum IndexerListing_Select_Column_Listing_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** aggregate stddev on columns */
export type IndexerListing_Stddev_Fields = {
  __typename?: 'listing_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "listing" */
export type IndexerListing_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerListing_Stddev_Pop_Fields = {
  __typename?: 'listing_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "listing" */
export type IndexerListing_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerListing_Stddev_Samp_Fields = {
  __typename?: 'listing_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "listing" */
export type IndexerListing_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "listing" */
export type IndexerListing_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerListing_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerListing_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  start_price?: Maybe<Scalars['bigint']>;
  status?: Maybe<Scalars['auction_status']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IndexerListing_Sum_Fields = {
  __typename?: 'listing_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  amount_left?: Maybe<Scalars['Int']>;
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  end_price?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  start_price?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "listing" */
export type IndexerListing_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerListing_Var_Pop_Fields = {
  __typename?: 'listing_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "listing" */
export type IndexerListing_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerListing_Var_Samp_Fields = {
  __typename?: 'listing_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "listing" */
export type IndexerListing_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerListing_Variance_Fields = {
  __typename?: 'listing_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  amount_left?: Maybe<Scalars['Float']>;
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  end_price?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  start_price?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "listing" */
export type IndexerListing_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  amount_left?: Maybe<IndexerOrder_By>;
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  end_price?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  start_price?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "marketplace_contract" */
export type IndexerMarketplace_Contract = {
  __typename?: 'marketplace_contract';
  contract: Scalars['String'];
  /** An array relationship */
  events: Array<IndexerEvent>;
  /** An aggregate relationship */
  events_aggregate: IndexerEvent_Aggregate;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subgroup?: Maybe<Scalars['String']>;
};


/** columns and relationships of "marketplace_contract" */
export type IndexerMarketplace_ContractEventsArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "marketplace_contract" */
export type IndexerMarketplace_ContractEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};

/** aggregated selection of "marketplace_contract" */
export type IndexerMarketplace_Contract_Aggregate = {
  __typename?: 'marketplace_contract_aggregate';
  aggregate?: Maybe<IndexerMarketplace_Contract_Aggregate_Fields>;
  nodes: Array<IndexerMarketplace_Contract>;
};

/** aggregate fields of "marketplace_contract" */
export type IndexerMarketplace_Contract_Aggregate_Fields = {
  __typename?: 'marketplace_contract_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IndexerMarketplace_Contract_Max_Fields>;
  min?: Maybe<IndexerMarketplace_Contract_Min_Fields>;
};


/** aggregate fields of "marketplace_contract" */
export type IndexerMarketplace_Contract_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerMarketplace_Contract_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "marketplace_contract". All fields are combined with a logical 'AND'. */
export type IndexerMarketplace_Contract_Bool_Exp = {
  _and?: Maybe<Array<IndexerMarketplace_Contract_Bool_Exp>>;
  _not?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  _or?: Maybe<Array<IndexerMarketplace_Contract_Bool_Exp>>;
  contract?: Maybe<IndexerString_Comparison_Exp>;
  events?: Maybe<IndexerEvent_Bool_Exp>;
  events_aggregate?: Maybe<IndexerEvent_Aggregate_Bool_Exp>;
  group?: Maybe<IndexerString_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  subgroup?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerMarketplace_Contract_Max_Fields = {
  __typename?: 'marketplace_contract_max_fields';
  contract?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subgroup?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IndexerMarketplace_Contract_Min_Fields = {
  __typename?: 'marketplace_contract_min_fields';
  contract?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subgroup?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "marketplace_contract". */
export type IndexerMarketplace_Contract_Order_By = {
  contract?: Maybe<IndexerOrder_By>;
  events_aggregate?: Maybe<IndexerEvent_Aggregate_Order_By>;
  group?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  subgroup?: Maybe<IndexerOrder_By>;
};

/** select columns of table "marketplace_contract" */
export enum IndexerMarketplace_Contract_Select_Column {
  /** column name */
  Contract = 'contract',
  /** column name */
  Group = 'group',
  /** column name */
  Name = 'name',
  /** column name */
  Subgroup = 'subgroup'
}

/** Streaming cursor of the table "marketplace_contract" */
export type IndexerMarketplace_Contract_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerMarketplace_Contract_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerMarketplace_Contract_Stream_Cursor_Value_Input = {
  contract?: Maybe<Scalars['String']>;
  group?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  subgroup?: Maybe<Scalars['String']>;
};


/** Boolean expression to compare columns of type "marketplace_event_type". All fields are combined with logical 'AND'. */
export type IndexerMarketplace_Event_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['marketplace_event_type']>;
  _gt?: Maybe<Scalars['marketplace_event_type']>;
  _gte?: Maybe<Scalars['marketplace_event_type']>;
  _in?: Maybe<Array<Scalars['marketplace_event_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['marketplace_event_type']>;
  _lte?: Maybe<Scalars['marketplace_event_type']>;
  _neq?: Maybe<Scalars['marketplace_event_type']>;
  _nin?: Maybe<Array<Scalars['marketplace_event_type']>>;
};


/** Boolean expression to compare columns of type "metadata_status". All fields are combined with logical 'AND'. */
export type IndexerMetadata_Status_Comparison_Exp = {
  _eq?: Maybe<Scalars['metadata_status']>;
  _gt?: Maybe<Scalars['metadata_status']>;
  _gte?: Maybe<Scalars['metadata_status']>;
  _in?: Maybe<Array<Scalars['metadata_status']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['metadata_status']>;
  _lte?: Maybe<Scalars['metadata_status']>;
  _neq?: Maybe<Scalars['metadata_status']>;
  _nin?: Maybe<Array<Scalars['metadata_status']>>;
};


/** Boolean expression to compare columns of type "numeric". All fields are combined with logical 'AND'. */
export type IndexerNumeric_Comparison_Exp = {
  _eq?: Maybe<Scalars['numeric']>;
  _gt?: Maybe<Scalars['numeric']>;
  _gte?: Maybe<Scalars['numeric']>;
  _in?: Maybe<Array<Scalars['numeric']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['numeric']>;
  _lte?: Maybe<Scalars['numeric']>;
  _neq?: Maybe<Scalars['numeric']>;
  _nin?: Maybe<Array<Scalars['numeric']>>;
};

/** columns and relationships of "offer" */
export type IndexerOffer = {
  __typename?: 'offer';
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  buyer?: Maybe<IndexerHolder>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  id: Scalars['bigint'];
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace: IndexerMarketplace_Contract;
  marketplace_contract: Scalars['String'];
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['auction_status']>;
  /** An object relationship */
  target?: Maybe<IndexerHolder>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties: Scalars['Boolean'];
};


/** columns and relationships of "offer" */
export type IndexerOfferSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "offer_active" */
export type IndexerOffer_Active = {
  __typename?: 'offer_active';
  bigmap_key?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  buyer?: Maybe<IndexerHolder>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  /** An object relationship */
  currency?: Maybe<IndexerCurrency>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  /** An object relationship */
  marketplace?: Maybe<IndexerMarketplace_Contract>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  target?: Maybe<IndexerHolder>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};


/** columns and relationships of "offer_active" */
export type IndexerOffer_ActiveSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "offer_active" */
export type IndexerOffer_Active_Aggregate = {
  __typename?: 'offer_active_aggregate';
  aggregate?: Maybe<IndexerOffer_Active_Aggregate_Fields>;
  nodes: Array<IndexerOffer_Active>;
};

export type IndexerOffer_Active_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerOffer_Active_Aggregate_Bool_Exp_Count>;
};

export type IndexerOffer_Active_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOffer_Active_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "offer_active" */
export type IndexerOffer_Active_Aggregate_Fields = {
  __typename?: 'offer_active_aggregate_fields';
  avg?: Maybe<IndexerOffer_Active_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerOffer_Active_Max_Fields>;
  min?: Maybe<IndexerOffer_Active_Min_Fields>;
  stddev?: Maybe<IndexerOffer_Active_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerOffer_Active_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerOffer_Active_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerOffer_Active_Sum_Fields>;
  var_pop?: Maybe<IndexerOffer_Active_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerOffer_Active_Var_Samp_Fields>;
  variance?: Maybe<IndexerOffer_Active_Variance_Fields>;
};


/** aggregate fields of "offer_active" */
export type IndexerOffer_Active_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "offer_active" */
export type IndexerOffer_Active_Aggregate_Order_By = {
  avg?: Maybe<IndexerOffer_Active_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerOffer_Active_Max_Order_By>;
  min?: Maybe<IndexerOffer_Active_Min_Order_By>;
  stddev?: Maybe<IndexerOffer_Active_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerOffer_Active_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerOffer_Active_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerOffer_Active_Sum_Order_By>;
  var_pop?: Maybe<IndexerOffer_Active_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerOffer_Active_Var_Samp_Order_By>;
  variance?: Maybe<IndexerOffer_Active_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerOffer_Active_Avg_Fields = {
  __typename?: 'offer_active_avg_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "offer_active" */
export type IndexerOffer_Active_Avg_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "offer_active". All fields are combined with a logical 'AND'. */
export type IndexerOffer_Active_Bool_Exp = {
  _and?: Maybe<Array<IndexerOffer_Active_Bool_Exp>>;
  _not?: Maybe<IndexerOffer_Active_Bool_Exp>;
  _or?: Maybe<Array<IndexerOffer_Active_Bool_Exp>>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  buyer?: Maybe<IndexerHolder_Bool_Exp>;
  buyer_address?: Maybe<IndexerString_Comparison_Exp>;
  collection_offer?: Maybe<IndexerString_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  target?: Maybe<IndexerHolder_Bool_Exp>;
  target_address?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerOffer_Active_Max_Fields = {
  __typename?: 'offer_active_max_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "offer_active" */
export type IndexerOffer_Active_Max_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  collection_offer?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerOffer_Active_Min_Fields = {
  __typename?: 'offer_active_min_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "offer_active" */
export type IndexerOffer_Active_Min_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  collection_offer?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "offer_active". */
export type IndexerOffer_Active_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  buyer?: Maybe<IndexerHolder_Order_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  collection_offer?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  target?: Maybe<IndexerHolder_Order_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** select columns of table "offer_active" */
export enum IndexerOffer_Active_Select_Column {
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  BuyerAddress = 'buyer_address',
  /** column name */
  CollectionOffer = 'collection_offer',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  TargetAddress = 'target_address',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp'
}

/** aggregate stddev on columns */
export type IndexerOffer_Active_Stddev_Fields = {
  __typename?: 'offer_active_stddev_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "offer_active" */
export type IndexerOffer_Active_Stddev_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerOffer_Active_Stddev_Pop_Fields = {
  __typename?: 'offer_active_stddev_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "offer_active" */
export type IndexerOffer_Active_Stddev_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerOffer_Active_Stddev_Samp_Fields = {
  __typename?: 'offer_active_stddev_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "offer_active" */
export type IndexerOffer_Active_Stddev_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "offer_active" */
export type IndexerOffer_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerOffer_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerOffer_Active_Stream_Cursor_Value_Input = {
  bigmap_key?: Maybe<Scalars['bigint']>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** aggregate sum on columns */
export type IndexerOffer_Active_Sum_Fields = {
  __typename?: 'offer_active_sum_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "offer_active" */
export type IndexerOffer_Active_Sum_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerOffer_Active_Var_Pop_Fields = {
  __typename?: 'offer_active_var_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "offer_active" */
export type IndexerOffer_Active_Var_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerOffer_Active_Var_Samp_Fields = {
  __typename?: 'offer_active_var_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "offer_active" */
export type IndexerOffer_Active_Var_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerOffer_Active_Variance_Fields = {
  __typename?: 'offer_active_variance_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "offer_active" */
export type IndexerOffer_Active_Variance_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregated selection of "offer" */
export type IndexerOffer_Aggregate = {
  __typename?: 'offer_aggregate';
  aggregate?: Maybe<IndexerOffer_Aggregate_Fields>;
  nodes: Array<IndexerOffer>;
};

export type IndexerOffer_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerOffer_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerOffer_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerOffer_Aggregate_Bool_Exp_Count>;
};

export type IndexerOffer_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerOffer_Select_Column_Offer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOffer_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerOffer_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerOffer_Select_Column_Offer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOffer_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerOffer_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerOffer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOffer_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "offer" */
export type IndexerOffer_Aggregate_Fields = {
  __typename?: 'offer_aggregate_fields';
  avg?: Maybe<IndexerOffer_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerOffer_Max_Fields>;
  min?: Maybe<IndexerOffer_Min_Fields>;
  stddev?: Maybe<IndexerOffer_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerOffer_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerOffer_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerOffer_Sum_Fields>;
  var_pop?: Maybe<IndexerOffer_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerOffer_Var_Samp_Fields>;
  variance?: Maybe<IndexerOffer_Variance_Fields>;
};


/** aggregate fields of "offer" */
export type IndexerOffer_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerOffer_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "offer" */
export type IndexerOffer_Aggregate_Order_By = {
  avg?: Maybe<IndexerOffer_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerOffer_Max_Order_By>;
  min?: Maybe<IndexerOffer_Min_Order_By>;
  stddev?: Maybe<IndexerOffer_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerOffer_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerOffer_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerOffer_Sum_Order_By>;
  var_pop?: Maybe<IndexerOffer_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerOffer_Var_Samp_Order_By>;
  variance?: Maybe<IndexerOffer_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerOffer_Avg_Fields = {
  __typename?: 'offer_avg_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "offer" */
export type IndexerOffer_Avg_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "offer". All fields are combined with a logical 'AND'. */
export type IndexerOffer_Bool_Exp = {
  _and?: Maybe<Array<IndexerOffer_Bool_Exp>>;
  _not?: Maybe<IndexerOffer_Bool_Exp>;
  _or?: Maybe<Array<IndexerOffer_Bool_Exp>>;
  bigmap_key?: Maybe<IndexerBigint_Comparison_Exp>;
  buyer?: Maybe<IndexerHolder_Bool_Exp>;
  buyer_address?: Maybe<IndexerString_Comparison_Exp>;
  collection_offer?: Maybe<IndexerString_Comparison_Exp>;
  currency?: Maybe<IndexerCurrency_Bool_Exp>;
  currency_id?: Maybe<IndexerBigint_Comparison_Exp>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
  marketplace_contract?: Maybe<IndexerString_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  price_xtz?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  status?: Maybe<IndexerAuction_Status_Comparison_Exp>;
  target?: Maybe<IndexerHolder_Bool_Exp>;
  target_address?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  update_level?: Maybe<IndexerInt_Comparison_Exp>;
  update_ophash?: Maybe<IndexerString_Comparison_Exp>;
  update_timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  valid_royalties?: Maybe<IndexerBoolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerOffer_Max_Fields = {
  __typename?: 'offer_max_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['auction_status']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by max() on columns of table "offer" */
export type IndexerOffer_Max_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  collection_offer?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerOffer_Min_Fields = {
  __typename?: 'offer_min_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  status?: Maybe<Scalars['auction_status']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
};

/** order by min() on columns of table "offer" */
export type IndexerOffer_Min_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  collection_offer?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "offer". */
export type IndexerOffer_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  buyer?: Maybe<IndexerHolder_Order_By>;
  buyer_address?: Maybe<IndexerOrder_By>;
  collection_offer?: Maybe<IndexerOrder_By>;
  currency?: Maybe<IndexerCurrency_Order_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  expiry?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  marketplace?: Maybe<IndexerMarketplace_Contract_Order_By>;
  marketplace_contract?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  status?: Maybe<IndexerOrder_By>;
  target?: Maybe<IndexerHolder_Order_By>;
  target_address?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
  update_ophash?: Maybe<IndexerOrder_By>;
  update_timestamp?: Maybe<IndexerOrder_By>;
  valid_royalties?: Maybe<IndexerOrder_By>;
};

/** select columns of table "offer" */
export enum IndexerOffer_Select_Column {
  /** column name */
  BigmapKey = 'bigmap_key',
  /** column name */
  BuyerAddress = 'buyer_address',
  /** column name */
  CollectionOffer = 'collection_offer',
  /** column name */
  CurrencyId = 'currency_id',
  /** column name */
  Expiry = 'expiry',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Level = 'level',
  /** column name */
  MarketplaceContract = 'marketplace_contract',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  PriceXtz = 'price_xtz',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  Status = 'status',
  /** column name */
  TargetAddress = 'target_address',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  UpdateLevel = 'update_level',
  /** column name */
  UpdateOphash = 'update_ophash',
  /** column name */
  UpdateTimestamp = 'update_timestamp',
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "offer_aggregate_bool_exp_bool_and_arguments_columns" columns of table "offer" */
export enum IndexerOffer_Select_Column_Offer_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "offer_aggregate_bool_exp_bool_or_arguments_columns" columns of table "offer" */
export enum IndexerOffer_Select_Column_Offer_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** aggregate stddev on columns */
export type IndexerOffer_Stddev_Fields = {
  __typename?: 'offer_stddev_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "offer" */
export type IndexerOffer_Stddev_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerOffer_Stddev_Pop_Fields = {
  __typename?: 'offer_stddev_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "offer" */
export type IndexerOffer_Stddev_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerOffer_Stddev_Samp_Fields = {
  __typename?: 'offer_stddev_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "offer" */
export type IndexerOffer_Stddev_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "offer" */
export type IndexerOffer_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerOffer_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerOffer_Stream_Cursor_Value_Input = {
  bigmap_key?: Maybe<Scalars['bigint']>;
  buyer_address?: Maybe<Scalars['String']>;
  collection_offer?: Maybe<Scalars['String']>;
  currency_id?: Maybe<Scalars['bigint']>;
  expiry?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  marketplace_contract?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  status?: Maybe<Scalars['auction_status']>;
  target_address?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
  update_ophash?: Maybe<Scalars['String']>;
  update_timestamp?: Maybe<Scalars['timestamptz']>;
  valid_royalties?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IndexerOffer_Sum_Fields = {
  __typename?: 'offer_sum_fields';
  bigmap_key?: Maybe<Scalars['bigint']>;
  currency_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  price_xtz?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
  update_level?: Maybe<Scalars['Int']>;
};

/** order by sum() on columns of table "offer" */
export type IndexerOffer_Sum_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerOffer_Var_Pop_Fields = {
  __typename?: 'offer_var_pop_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "offer" */
export type IndexerOffer_Var_Pop_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerOffer_Var_Samp_Fields = {
  __typename?: 'offer_var_samp_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "offer" */
export type IndexerOffer_Var_Samp_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerOffer_Variance_Fields = {
  __typename?: 'offer_variance_fields';
  bigmap_key?: Maybe<Scalars['Float']>;
  currency_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  price_xtz?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
  update_level?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "offer" */
export type IndexerOffer_Variance_Order_By = {
  bigmap_key?: Maybe<IndexerOrder_By>;
  currency_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  price_xtz?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  update_level?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "open_edition" */
export type IndexerOpen_Edition = {
  __typename?: 'open_edition';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  burn_recipe?: Maybe<Scalars['jsonb']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
  valid_royalties: Scalars['Boolean'];
};


/** columns and relationships of "open_edition" */
export type IndexerOpen_EditionBurn_RecipeArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "open_edition" */
export type IndexerOpen_EditionSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** columns and relationships of "open_edition_active" */
export type IndexerOpen_Edition_Active = {
  __typename?: 'open_edition_active';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  burn_recipe?: Maybe<Scalars['jsonb']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  fa?: Maybe<IndexerFa>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  seller?: Maybe<IndexerHolder>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
};


/** columns and relationships of "open_edition_active" */
export type IndexerOpen_Edition_ActiveBurn_RecipeArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "open_edition_active" */
export type IndexerOpen_Edition_ActiveSharesArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "open_edition_active" */
export type IndexerOpen_Edition_Active_Aggregate = {
  __typename?: 'open_edition_active_aggregate';
  aggregate?: Maybe<IndexerOpen_Edition_Active_Aggregate_Fields>;
  nodes: Array<IndexerOpen_Edition_Active>;
};

export type IndexerOpen_Edition_Active_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerOpen_Edition_Active_Aggregate_Bool_Exp_Count>;
};

export type IndexerOpen_Edition_Active_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "open_edition_active" */
export type IndexerOpen_Edition_Active_Aggregate_Fields = {
  __typename?: 'open_edition_active_aggregate_fields';
  avg?: Maybe<IndexerOpen_Edition_Active_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerOpen_Edition_Active_Max_Fields>;
  min?: Maybe<IndexerOpen_Edition_Active_Min_Fields>;
  stddev?: Maybe<IndexerOpen_Edition_Active_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerOpen_Edition_Active_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerOpen_Edition_Active_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerOpen_Edition_Active_Sum_Fields>;
  var_pop?: Maybe<IndexerOpen_Edition_Active_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerOpen_Edition_Active_Var_Samp_Fields>;
  variance?: Maybe<IndexerOpen_Edition_Active_Variance_Fields>;
};


/** aggregate fields of "open_edition_active" */
export type IndexerOpen_Edition_Active_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Aggregate_Order_By = {
  avg?: Maybe<IndexerOpen_Edition_Active_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerOpen_Edition_Active_Max_Order_By>;
  min?: Maybe<IndexerOpen_Edition_Active_Min_Order_By>;
  stddev?: Maybe<IndexerOpen_Edition_Active_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerOpen_Edition_Active_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerOpen_Edition_Active_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerOpen_Edition_Active_Sum_Order_By>;
  var_pop?: Maybe<IndexerOpen_Edition_Active_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerOpen_Edition_Active_Var_Samp_Order_By>;
  variance?: Maybe<IndexerOpen_Edition_Active_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerOpen_Edition_Active_Avg_Fields = {
  __typename?: 'open_edition_active_avg_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Avg_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "open_edition_active". All fields are combined with a logical 'AND'. */
export type IndexerOpen_Edition_Active_Bool_Exp = {
  _and?: Maybe<Array<IndexerOpen_Edition_Active_Bool_Exp>>;
  _not?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
  _or?: Maybe<Array<IndexerOpen_Edition_Active_Bool_Exp>>;
  airdrop_capacity?: Maybe<IndexerInt_Comparison_Exp>;
  burn_recipe?: Maybe<IndexerJsonb_Comparison_Exp>;
  end_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  max_per_wallet?: Maybe<IndexerInt_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  shares_total?: Maybe<IndexerInt_Comparison_Exp>;
  start_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerOpen_Edition_Active_Max_Fields = {
  __typename?: 'open_edition_active_max_fields';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Max_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerOpen_Edition_Active_Min_Fields = {
  __typename?: 'open_edition_active_min_fields';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Min_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "open_edition_active". */
export type IndexerOpen_Edition_Active_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  burn_recipe?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "open_edition_active" */
export enum IndexerOpen_Edition_Active_Select_Column {
  /** column name */
  AirdropCapacity = 'airdrop_capacity',
  /** column name */
  BurnRecipe = 'burn_recipe',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Level = 'level',
  /** column name */
  MaxPerWallet = 'max_per_wallet',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  SharesTotal = 'shares_total',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerOpen_Edition_Active_Stddev_Fields = {
  __typename?: 'open_edition_active_stddev_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Stddev_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerOpen_Edition_Active_Stddev_Pop_Fields = {
  __typename?: 'open_edition_active_stddev_pop_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Stddev_Pop_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerOpen_Edition_Active_Stddev_Samp_Fields = {
  __typename?: 'open_edition_active_stddev_samp_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Stddev_Samp_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "open_edition_active" */
export type IndexerOpen_Edition_Active_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerOpen_Edition_Active_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerOpen_Edition_Active_Stream_Cursor_Value_Input = {
  airdrop_capacity?: Maybe<Scalars['Int']>;
  burn_recipe?: Maybe<Scalars['jsonb']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerOpen_Edition_Active_Sum_Fields = {
  __typename?: 'open_edition_active_sum_fields';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  shares_total?: Maybe<Scalars['Int']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Sum_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerOpen_Edition_Active_Var_Pop_Fields = {
  __typename?: 'open_edition_active_var_pop_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Var_Pop_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerOpen_Edition_Active_Var_Samp_Fields = {
  __typename?: 'open_edition_active_var_samp_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Var_Samp_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerOpen_Edition_Active_Variance_Fields = {
  __typename?: 'open_edition_active_variance_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "open_edition_active" */
export type IndexerOpen_Edition_Active_Variance_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregated selection of "open_edition" */
export type IndexerOpen_Edition_Aggregate = {
  __typename?: 'open_edition_aggregate';
  aggregate?: Maybe<IndexerOpen_Edition_Aggregate_Fields>;
  nodes: Array<IndexerOpen_Edition>;
};

export type IndexerOpen_Edition_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerOpen_Edition_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerOpen_Edition_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerOpen_Edition_Aggregate_Bool_Exp_Count>;
};

export type IndexerOpen_Edition_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerOpen_Edition_Select_Column_Open_Edition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerOpen_Edition_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerOpen_Edition_Select_Column_Open_Edition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerOpen_Edition_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "open_edition" */
export type IndexerOpen_Edition_Aggregate_Fields = {
  __typename?: 'open_edition_aggregate_fields';
  avg?: Maybe<IndexerOpen_Edition_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerOpen_Edition_Max_Fields>;
  min?: Maybe<IndexerOpen_Edition_Min_Fields>;
  stddev?: Maybe<IndexerOpen_Edition_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerOpen_Edition_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerOpen_Edition_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerOpen_Edition_Sum_Fields>;
  var_pop?: Maybe<IndexerOpen_Edition_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerOpen_Edition_Var_Samp_Fields>;
  variance?: Maybe<IndexerOpen_Edition_Variance_Fields>;
};


/** aggregate fields of "open_edition" */
export type IndexerOpen_Edition_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "open_edition" */
export type IndexerOpen_Edition_Aggregate_Order_By = {
  avg?: Maybe<IndexerOpen_Edition_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerOpen_Edition_Max_Order_By>;
  min?: Maybe<IndexerOpen_Edition_Min_Order_By>;
  stddev?: Maybe<IndexerOpen_Edition_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerOpen_Edition_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerOpen_Edition_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerOpen_Edition_Sum_Order_By>;
  var_pop?: Maybe<IndexerOpen_Edition_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerOpen_Edition_Var_Samp_Order_By>;
  variance?: Maybe<IndexerOpen_Edition_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerOpen_Edition_Avg_Fields = {
  __typename?: 'open_edition_avg_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "open_edition" */
export type IndexerOpen_Edition_Avg_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "open_edition". All fields are combined with a logical 'AND'. */
export type IndexerOpen_Edition_Bool_Exp = {
  _and?: Maybe<Array<IndexerOpen_Edition_Bool_Exp>>;
  _not?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  _or?: Maybe<Array<IndexerOpen_Edition_Bool_Exp>>;
  airdrop_capacity?: Maybe<IndexerInt_Comparison_Exp>;
  burn_recipe?: Maybe<IndexerJsonb_Comparison_Exp>;
  end_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  max_per_wallet?: Maybe<IndexerInt_Comparison_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  price?: Maybe<IndexerBigint_Comparison_Exp>;
  seller?: Maybe<IndexerHolder_Bool_Exp>;
  seller_address?: Maybe<IndexerString_Comparison_Exp>;
  shares?: Maybe<IndexerJsonb_Comparison_Exp>;
  shares_total?: Maybe<IndexerInt_Comparison_Exp>;
  start_time?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  valid_royalties?: Maybe<IndexerBoolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerOpen_Edition_Max_Fields = {
  __typename?: 'open_edition_max_fields';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "open_edition" */
export type IndexerOpen_Edition_Max_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerOpen_Edition_Min_Fields = {
  __typename?: 'open_edition_min_fields';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "open_edition" */
export type IndexerOpen_Edition_Min_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "open_edition". */
export type IndexerOpen_Edition_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  burn_recipe?: Maybe<IndexerOrder_By>;
  end_time?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  seller?: Maybe<IndexerHolder_Order_By>;
  seller_address?: Maybe<IndexerOrder_By>;
  shares?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  start_time?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  valid_royalties?: Maybe<IndexerOrder_By>;
};

/** select columns of table "open_edition" */
export enum IndexerOpen_Edition_Select_Column {
  /** column name */
  AirdropCapacity = 'airdrop_capacity',
  /** column name */
  BurnRecipe = 'burn_recipe',
  /** column name */
  EndTime = 'end_time',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Level = 'level',
  /** column name */
  MaxPerWallet = 'max_per_wallet',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Price = 'price',
  /** column name */
  SellerAddress = 'seller_address',
  /** column name */
  Shares = 'shares',
  /** column name */
  SharesTotal = 'shares_total',
  /** column name */
  StartTime = 'start_time',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "open_edition_aggregate_bool_exp_bool_and_arguments_columns" columns of table "open_edition" */
export enum IndexerOpen_Edition_Select_Column_Open_Edition_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** select "open_edition_aggregate_bool_exp_bool_or_arguments_columns" columns of table "open_edition" */
export enum IndexerOpen_Edition_Select_Column_Open_Edition_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  ValidRoyalties = 'valid_royalties'
}

/** aggregate stddev on columns */
export type IndexerOpen_Edition_Stddev_Fields = {
  __typename?: 'open_edition_stddev_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "open_edition" */
export type IndexerOpen_Edition_Stddev_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerOpen_Edition_Stddev_Pop_Fields = {
  __typename?: 'open_edition_stddev_pop_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "open_edition" */
export type IndexerOpen_Edition_Stddev_Pop_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerOpen_Edition_Stddev_Samp_Fields = {
  __typename?: 'open_edition_stddev_samp_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "open_edition" */
export type IndexerOpen_Edition_Stddev_Samp_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "open_edition" */
export type IndexerOpen_Edition_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerOpen_Edition_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerOpen_Edition_Stream_Cursor_Value_Input = {
  airdrop_capacity?: Maybe<Scalars['Int']>;
  burn_recipe?: Maybe<Scalars['jsonb']>;
  end_time?: Maybe<Scalars['timestamptz']>;
  fa_contract?: Maybe<Scalars['String']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  ophash?: Maybe<Scalars['String']>;
  price?: Maybe<Scalars['bigint']>;
  seller_address?: Maybe<Scalars['String']>;
  shares?: Maybe<Scalars['jsonb']>;
  shares_total?: Maybe<Scalars['Int']>;
  start_time?: Maybe<Scalars['timestamptz']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_pk?: Maybe<Scalars['bigint']>;
  valid_royalties?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IndexerOpen_Edition_Sum_Fields = {
  __typename?: 'open_edition_sum_fields';
  airdrop_capacity?: Maybe<Scalars['Int']>;
  level?: Maybe<Scalars['Int']>;
  max_per_wallet?: Maybe<Scalars['Int']>;
  price?: Maybe<Scalars['bigint']>;
  shares_total?: Maybe<Scalars['Int']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "open_edition" */
export type IndexerOpen_Edition_Sum_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerOpen_Edition_Var_Pop_Fields = {
  __typename?: 'open_edition_var_pop_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "open_edition" */
export type IndexerOpen_Edition_Var_Pop_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerOpen_Edition_Var_Samp_Fields = {
  __typename?: 'open_edition_var_samp_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "open_edition" */
export type IndexerOpen_Edition_Var_Samp_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerOpen_Edition_Variance_Fields = {
  __typename?: 'open_edition_variance_fields';
  airdrop_capacity?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  max_per_wallet?: Maybe<Scalars['Float']>;
  price?: Maybe<Scalars['Float']>;
  shares_total?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "open_edition" */
export type IndexerOpen_Edition_Variance_Order_By = {
  airdrop_capacity?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  max_per_wallet?: Maybe<IndexerOrder_By>;
  price?: Maybe<IndexerOrder_By>;
  shares_total?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** column ordering options */
export enum IndexerOrder_By {
  /** in ascending order, nulls last */
  Asc = 'asc',
  /** in ascending order, nulls first */
  AscNullsFirst = 'asc_nulls_first',
  /** in ascending order, nulls last */
  AscNullsLast = 'asc_nulls_last',
  /** in descending order, nulls first */
  Desc = 'desc',
  /** in descending order, nulls first */
  DescNullsFirst = 'desc_nulls_first',
  /** in descending order, nulls last */
  DescNullsLast = 'desc_nulls_last'
}

export type IndexerQuery_Root = {
  __typename?: 'query_root';
  /** fetch data from the table: "attribute" */
  attribute: Array<IndexerAttribute>;
  /** fetch aggregated fields from the table: "attribute" */
  attribute_aggregate: IndexerAttribute_Aggregate;
  /** fetch data from the table: "attribute" using primary key columns */
  attribute_by_pk?: Maybe<IndexerAttribute>;
  /** fetch data from the table: "currency" */
  currency: Array<IndexerCurrency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: IndexerCurrency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<IndexerCurrency>;
  /** fetch data from the table: "dutch_auction" */
  dutch_auction: Array<IndexerDutch_Auction>;
  /** fetch data from the table: "dutch_auction_active" */
  dutch_auction_active: Array<IndexerDutch_Auction_Active>;
  /** fetch aggregated fields from the table: "dutch_auction_active" */
  dutch_auction_active_aggregate: IndexerDutch_Auction_Active_Aggregate;
  /** fetch aggregated fields from the table: "dutch_auction" */
  dutch_auction_aggregate: IndexerDutch_Auction_Aggregate;
  /** fetch data from the table: "dutch_auction" using primary key columns */
  dutch_auction_by_pk?: Maybe<IndexerDutch_Auction>;
  /** fetch data from the table: "dutch_auction_sale" */
  dutch_auction_sale: Array<IndexerDutch_Auction_Sale>;
  /** fetch aggregated fields from the table: "dutch_auction_sale" */
  dutch_auction_sale_aggregate: IndexerDutch_Auction_Sale_Aggregate;
  /** fetch data from the table: "dutch_auction_sale" using primary key columns */
  dutch_auction_sale_by_pk?: Maybe<IndexerDutch_Auction_Sale>;
  /** fetch data from the table: "english_auction" */
  english_auction: Array<IndexerEnglish_Auction>;
  /** fetch data from the table: "english_auction_active" */
  english_auction_active: Array<IndexerEnglish_Auction_Active>;
  /** fetch aggregated fields from the table: "english_auction_active" */
  english_auction_active_aggregate: IndexerEnglish_Auction_Active_Aggregate;
  /** fetch aggregated fields from the table: "english_auction" */
  english_auction_aggregate: IndexerEnglish_Auction_Aggregate;
  /** fetch data from the table: "english_auction_bid" */
  english_auction_bid: Array<IndexerEnglish_Auction_Bid>;
  /** fetch aggregated fields from the table: "english_auction_bid" */
  english_auction_bid_aggregate: IndexerEnglish_Auction_Bid_Aggregate;
  /** fetch data from the table: "english_auction_bid" using primary key columns */
  english_auction_bid_by_pk?: Maybe<IndexerEnglish_Auction_Bid>;
  /** fetch data from the table: "english_auction" using primary key columns */
  english_auction_by_pk?: Maybe<IndexerEnglish_Auction>;
  /** fetch data from the table: "event" */
  event: Array<IndexerEvent>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: IndexerEvent_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<IndexerEvent>;
  /** fetch data from the table: "fa" */
  fa: Array<IndexerFa>;
  /** fetch data from the table: "fa2_attribute_count" */
  fa2_attribute_count: Array<IndexerFa2_Attribute_Count>;
  /** fetch aggregated fields from the table: "fa2_attribute_count" */
  fa2_attribute_count_aggregate: IndexerFa2_Attribute_Count_Aggregate;
  /** fetch data from the table: "fa2_attribute_count" using primary key columns */
  fa2_attribute_count_by_pk?: Maybe<IndexerFa2_Attribute_Count>;
  /** fetch data from the table: "fa2_creator" */
  fa2_creator: Array<IndexerFa2_Creator>;
  /** fetch aggregated fields from the table: "fa2_creator" */
  fa2_creator_aggregate: IndexerFa2_Creator_Aggregate;
  /** fetch data from the table: "fa2_creator" using primary key columns */
  fa2_creator_by_pk?: Maybe<IndexerFa2_Creator>;
  /** fetch data from the table: "fa2_manager" */
  fa2_manager: Array<IndexerFa2_Manager>;
  /** fetch aggregated fields from the table: "fa2_manager" */
  fa2_manager_aggregate: IndexerFa2_Manager_Aggregate;
  /** fetch data from the table: "fa2_manager" using primary key columns */
  fa2_manager_by_pk?: Maybe<IndexerFa2_Manager>;
  /** fetch aggregated fields from the table: "fa" */
  fa_aggregate: IndexerFa_Aggregate;
  /** fetch data from the table: "fa" using primary key columns */
  fa_by_pk?: Maybe<IndexerFa>;
  /** fetch data from the table: "gallery" */
  gallery: Array<IndexerGallery>;
  /** fetch aggregated fields from the table: "gallery" */
  gallery_aggregate: IndexerGallery_Aggregate;
  /** fetch data from the table: "gallery_attribute_count" */
  gallery_attribute_count: Array<IndexerGallery_Attribute_Count>;
  /** fetch aggregated fields from the table: "gallery_attribute_count" */
  gallery_attribute_count_aggregate: IndexerGallery_Attribute_Count_Aggregate;
  /** fetch data from the table: "gallery_attribute_count" using primary key columns */
  gallery_attribute_count_by_pk?: Maybe<IndexerGallery_Attribute_Count>;
  /** fetch data from the table: "gallery" using primary key columns */
  gallery_by_pk?: Maybe<IndexerGallery>;
  /** fetch data from the table: "gallery_curator" */
  gallery_curator: Array<IndexerGallery_Curator>;
  /** fetch aggregated fields from the table: "gallery_curator" */
  gallery_curator_aggregate: IndexerGallery_Curator_Aggregate;
  /** fetch data from the table: "gallery_curator" using primary key columns */
  gallery_curator_by_pk?: Maybe<IndexerGallery_Curator>;
  /** fetch data from the table: "gallery_registry" */
  gallery_registry: Array<IndexerGallery_Registry>;
  /** fetch aggregated fields from the table: "gallery_registry" */
  gallery_registry_aggregate: IndexerGallery_Registry_Aggregate;
  /** fetch data from the table: "gallery_registry" using primary key columns */
  gallery_registry_by_pk?: Maybe<IndexerGallery_Registry>;
  /** fetch data from the table: "gallery_token" */
  gallery_token: Array<IndexerGallery_Token>;
  /** fetch aggregated fields from the table: "gallery_token" */
  gallery_token_aggregate: IndexerGallery_Token_Aggregate;
  /** fetch data from the table: "gallery_token" using primary key columns */
  gallery_token_by_pk?: Maybe<IndexerGallery_Token>;
  /** fetch data from the table: "holder" */
  holder: Array<IndexerHolder>;
  /** fetch aggregated fields from the table: "holder" */
  holder_aggregate: IndexerHolder_Aggregate;
  /** fetch data from the table: "holder" using primary key columns */
  holder_by_pk?: Maybe<IndexerHolder>;
  /** fetch data from the table: "invitation" */
  invitation: Array<IndexerInvitation>;
  /** fetch aggregated fields from the table: "invitation" */
  invitation_aggregate: IndexerInvitation_Aggregate;
  /** fetch data from the table: "invitation" using primary key columns */
  invitation_by_pk?: Maybe<IndexerInvitation>;
  /** fetch data from the table: "listing" */
  listing: Array<IndexerListing>;
  /** fetch data from the table: "listing_active" */
  listing_active: Array<IndexerListing_Active>;
  /** fetch aggregated fields from the table: "listing_active" */
  listing_active_aggregate: IndexerListing_Active_Aggregate;
  /** fetch aggregated fields from the table: "listing" */
  listing_aggregate: IndexerListing_Aggregate;
  /** fetch data from the table: "listing" using primary key columns */
  listing_by_pk?: Maybe<IndexerListing>;
  /** fetch data from the table: "listing_sale" */
  listing_sale: Array<IndexerListing_Sale>;
  /** fetch aggregated fields from the table: "listing_sale" */
  listing_sale_aggregate: IndexerListing_Sale_Aggregate;
  /** fetch data from the table: "listing_sale" using primary key columns */
  listing_sale_by_pk?: Maybe<IndexerListing_Sale>;
  /** fetch data from the table: "marketplace_contract" */
  marketplace_contract: Array<IndexerMarketplace_Contract>;
  /** fetch aggregated fields from the table: "marketplace_contract" */
  marketplace_contract_aggregate: IndexerMarketplace_Contract_Aggregate;
  /** fetch data from the table: "marketplace_contract" using primary key columns */
  marketplace_contract_by_pk?: Maybe<IndexerMarketplace_Contract>;
  /** fetch data from the table: "offer" */
  offer: Array<IndexerOffer>;
  /** fetch data from the table: "offer_active" */
  offer_active: Array<IndexerOffer_Active>;
  /** fetch aggregated fields from the table: "offer_active" */
  offer_active_aggregate: IndexerOffer_Active_Aggregate;
  /** fetch aggregated fields from the table: "offer" */
  offer_aggregate: IndexerOffer_Aggregate;
  /** fetch data from the table: "offer" using primary key columns */
  offer_by_pk?: Maybe<IndexerOffer>;
  /** An array relationship */
  open_edition: Array<IndexerOpen_Edition>;
  /** An array relationship */
  open_edition_active: Array<IndexerOpen_Edition_Active>;
  /** An aggregate relationship */
  open_edition_active_aggregate: IndexerOpen_Edition_Active_Aggregate;
  /** An aggregate relationship */
  open_edition_aggregate: IndexerOpen_Edition_Aggregate;
  /** fetch data from the table: "open_edition" using primary key columns */
  open_edition_by_pk?: Maybe<IndexerOpen_Edition>;
  /** An array relationship */
  royalties: Array<IndexerRoyalties>;
  /** An aggregate relationship */
  royalties_aggregate: IndexerRoyalties_Aggregate;
  /** fetch data from the table: "royalties" using primary key columns */
  royalties_by_pk?: Maybe<IndexerRoyalties>;
  /** fetch data from the table: "sales_stat" */
  sales_stat: Array<IndexerSales_Stat>;
  /** fetch aggregated fields from the table: "sales_stat" */
  sales_stat_aggregate: IndexerSales_Stat_Aggregate;
  /** fetch data from the table: "sales_stat" using primary key columns */
  sales_stat_by_pk?: Maybe<IndexerSales_Stat>;
  /** fetch data from the table: "tag" */
  tag: Array<IndexerTag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: IndexerTag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<IndexerTag>;
  /** fetch data from the table: "tezos_storage" */
  tezos_storage: Array<IndexerTezos_Storage>;
  /** fetch aggregated fields from the table: "tezos_storage" */
  tezos_storage_aggregate: IndexerTezos_Storage_Aggregate;
  /** fetch data from the table: "tezos_storage" using primary key columns */
  tezos_storage_by_pk?: Maybe<IndexerTezos_Storage>;
  /** fetch data from the table: "token" */
  token: Array<IndexerToken>;
  /** fetch aggregated fields from the table: "token" */
  token_aggregate: IndexerToken_Aggregate;
  /** fetch data from the table: "token_attribute" */
  token_attribute: Array<IndexerToken_Attribute>;
  /** fetch aggregated fields from the table: "token_attribute" */
  token_attribute_aggregate: IndexerToken_Attribute_Aggregate;
  /** fetch data from the table: "token_attribute" using primary key columns */
  token_attribute_by_pk?: Maybe<IndexerToken_Attribute>;
  /** fetch data from the table: "token" using primary key columns */
  token_by_pk?: Maybe<IndexerToken>;
  /** fetch data from the table: "token_creator" */
  token_creator: Array<IndexerToken_Creator>;
  /** fetch aggregated fields from the table: "token_creator" */
  token_creator_aggregate: IndexerToken_Creator_Aggregate;
  /** fetch data from the table: "token_creator" using primary key columns */
  token_creator_by_pk?: Maybe<IndexerToken_Creator>;
  /** fetch data from the table: "token_holder" */
  token_holder: Array<IndexerToken_Holder>;
  /** fetch aggregated fields from the table: "token_holder" */
  token_holder_aggregate: IndexerToken_Holder_Aggregate;
  /** fetch data from the table: "token_holder" using primary key columns */
  token_holder_by_pk?: Maybe<IndexerToken_Holder>;
  /** fetch data from the table: "token_nsfw_override" */
  token_nsfw_override: Array<IndexerToken_Nsfw_Override>;
  /** fetch aggregated fields from the table: "token_nsfw_override" */
  token_nsfw_override_aggregate: IndexerToken_Nsfw_Override_Aggregate;
  /** fetch data from the table: "token_nsfw_override" using primary key columns */
  token_nsfw_override_by_pk?: Maybe<IndexerToken_Nsfw_Override>;
  /** fetch data from the table: "token_operator" */
  token_operator: Array<IndexerToken_Operator>;
  /** fetch aggregated fields from the table: "token_operator" */
  token_operator_aggregate: IndexerToken_Operator_Aggregate;
  /** fetch data from the table: "token_operator" using primary key columns */
  token_operator_by_pk?: Maybe<IndexerToken_Operator>;
  /** fetch data from the table: "token_registry" */
  token_registry: Array<IndexerToken_Registry>;
  /** fetch aggregated fields from the table: "token_registry" */
  token_registry_aggregate: IndexerToken_Registry_Aggregate;
  /** fetch data from the table: "token_registry" using primary key columns */
  token_registry_by_pk?: Maybe<IndexerToken_Registry>;
  /** fetch data from the table: "token_tag" */
  token_tag: Array<IndexerToken_Tag>;
  /** fetch aggregated fields from the table: "token_tag" */
  token_tag_aggregate: IndexerToken_Tag_Aggregate;
  /** fetch data from the table: "token_tag" using primary key columns */
  token_tag_by_pk?: Maybe<IndexerToken_Tag>;
  /** fetch data from the table: "tzd_domain" */
  tzd_domain: Array<IndexerTzd_Domain>;
  /** fetch aggregated fields from the table: "tzd_domain" */
  tzd_domain_aggregate: IndexerTzd_Domain_Aggregate;
  /** fetch data from the table: "tzd_domain" using primary key columns */
  tzd_domain_by_pk?: Maybe<IndexerTzd_Domain>;
  /** fetch data from the table: "tzd_record" */
  tzd_record: Array<IndexerTzd_Record>;
  /** fetch aggregated fields from the table: "tzd_record" */
  tzd_record_aggregate: IndexerTzd_Record_Aggregate;
  /** fetch data from the table: "tzd_record" using primary key columns */
  tzd_record_by_pk?: Maybe<IndexerTzd_Record>;
  /** fetch data from the table: "tzd_tld" */
  tzd_tld: Array<IndexerTzd_Tld>;
  /** fetch aggregated fields from the table: "tzd_tld" */
  tzd_tld_aggregate: IndexerTzd_Tld_Aggregate;
  /** fetch data from the table: "tzd_tld" using primary key columns */
  tzd_tld_by_pk?: Maybe<IndexerTzd_Tld>;
};


export type IndexerQuery_RootAttributeArgs = {
  distinct_on?: Maybe<Array<IndexerAttribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerAttribute_Order_By>>;
  where?: Maybe<IndexerAttribute_Bool_Exp>;
};


export type IndexerQuery_RootAttribute_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerAttribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerAttribute_Order_By>>;
  where?: Maybe<IndexerAttribute_Bool_Exp>;
};


export type IndexerQuery_RootAttribute_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootCurrencyArgs = {
  distinct_on?: Maybe<Array<IndexerCurrency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerCurrency_Order_By>>;
  where?: Maybe<IndexerCurrency_Bool_Exp>;
};


export type IndexerQuery_RootCurrency_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerCurrency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerCurrency_Order_By>>;
  where?: Maybe<IndexerCurrency_Bool_Exp>;
};


export type IndexerQuery_RootCurrency_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootDutch_AuctionArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


export type IndexerQuery_RootDutch_Auction_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


export type IndexerQuery_RootDutch_Auction_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


export type IndexerQuery_RootDutch_Auction_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


export type IndexerQuery_RootDutch_Auction_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootDutch_Auction_SaleArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


export type IndexerQuery_RootDutch_Auction_Sale_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


export type IndexerQuery_RootDutch_Auction_Sale_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootEnglish_AuctionArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


export type IndexerQuery_RootEnglish_Auction_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


export type IndexerQuery_RootEnglish_Auction_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


export type IndexerQuery_RootEnglish_Auction_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


export type IndexerQuery_RootEnglish_Auction_BidArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


export type IndexerQuery_RootEnglish_Auction_Bid_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


export type IndexerQuery_RootEnglish_Auction_Bid_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootEnglish_Auction_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootEventArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


export type IndexerQuery_RootEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


export type IndexerQuery_RootEvent_By_PkArgs = {
  id: Scalars['bigint'];
  timestamp: Scalars['timestamptz'];
};


export type IndexerQuery_RootFaArgs = {
  distinct_on?: Maybe<Array<IndexerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa_Order_By>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Attribute_CountArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Attribute_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Attribute_Count_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootFa2_CreatorArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Creator_Order_By>>;
  where?: Maybe<IndexerFa2_Creator_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Creator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Creator_Order_By>>;
  where?: Maybe<IndexerFa2_Creator_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Creator_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootFa2_ManagerArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Manager_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


export type IndexerQuery_RootFa2_Manager_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootFa_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa_Order_By>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


export type IndexerQuery_RootFa_By_PkArgs = {
  contract: Scalars['String'];
};


export type IndexerQuery_RootGalleryArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Order_By>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};


export type IndexerQuery_RootGallery_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Order_By>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Attribute_CountArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerGallery_Attribute_Count_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Attribute_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerGallery_Attribute_Count_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Attribute_Count_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootGallery_By_PkArgs = {
  pk: Scalars['bigint'];
};


export type IndexerQuery_RootGallery_CuratorArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Curator_Order_By>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Curator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Curator_Order_By>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Curator_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootGallery_RegistryArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Registry_Order_By>>;
  where?: Maybe<IndexerGallery_Registry_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Registry_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Registry_Order_By>>;
  where?: Maybe<IndexerGallery_Registry_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Registry_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootGallery_TokenArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


export type IndexerQuery_RootGallery_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootHolderArgs = {
  distinct_on?: Maybe<Array<IndexerHolder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerHolder_Order_By>>;
  where?: Maybe<IndexerHolder_Bool_Exp>;
};


export type IndexerQuery_RootHolder_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerHolder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerHolder_Order_By>>;
  where?: Maybe<IndexerHolder_Bool_Exp>;
};


export type IndexerQuery_RootHolder_By_PkArgs = {
  address: Scalars['String'];
};


export type IndexerQuery_RootInvitationArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


export type IndexerQuery_RootInvitation_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


export type IndexerQuery_RootInvitation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootListingArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


export type IndexerQuery_RootListing_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


export type IndexerQuery_RootListing_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


export type IndexerQuery_RootListing_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


export type IndexerQuery_RootListing_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootListing_SaleArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


export type IndexerQuery_RootListing_Sale_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


export type IndexerQuery_RootListing_Sale_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootMarketplace_ContractArgs = {
  distinct_on?: Maybe<Array<IndexerMarketplace_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerMarketplace_Contract_Order_By>>;
  where?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
};


export type IndexerQuery_RootMarketplace_Contract_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerMarketplace_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerMarketplace_Contract_Order_By>>;
  where?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
};


export type IndexerQuery_RootMarketplace_Contract_By_PkArgs = {
  contract: Scalars['String'];
};


export type IndexerQuery_RootOfferArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


export type IndexerQuery_RootOffer_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


export type IndexerQuery_RootOffer_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


export type IndexerQuery_RootOffer_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


export type IndexerQuery_RootOffer_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootOpen_EditionArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


export type IndexerQuery_RootOpen_Edition_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


export type IndexerQuery_RootOpen_Edition_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


export type IndexerQuery_RootOpen_Edition_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


export type IndexerQuery_RootOpen_Edition_By_PkArgs = {
  token_pk: Scalars['bigint'];
};


export type IndexerQuery_RootRoyaltiesArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


export type IndexerQuery_RootRoyalties_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


export type IndexerQuery_RootRoyalties_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootSales_StatArgs = {
  distinct_on?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerSales_Stat_Order_By>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};


export type IndexerQuery_RootSales_Stat_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerSales_Stat_Order_By>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};


export type IndexerQuery_RootSales_Stat_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootTagArgs = {
  distinct_on?: Maybe<Array<IndexerTag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTag_Order_By>>;
  where?: Maybe<IndexerTag_Bool_Exp>;
};


export type IndexerQuery_RootTag_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTag_Order_By>>;
  where?: Maybe<IndexerTag_Bool_Exp>;
};


export type IndexerQuery_RootTag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootTezos_StorageArgs = {
  distinct_on?: Maybe<Array<IndexerTezos_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTezos_Storage_Order_By>>;
  where?: Maybe<IndexerTezos_Storage_Bool_Exp>;
};


export type IndexerQuery_RootTezos_Storage_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTezos_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTezos_Storage_Order_By>>;
  where?: Maybe<IndexerTezos_Storage_Bool_Exp>;
};


export type IndexerQuery_RootTezos_Storage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootTokenArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Order_By>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


export type IndexerQuery_RootToken_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Order_By>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


export type IndexerQuery_RootToken_AttributeArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


export type IndexerQuery_RootToken_Attribute_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


export type IndexerQuery_RootToken_Attribute_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootToken_By_PkArgs = {
  pk: Scalars['bigint'];
};


export type IndexerQuery_RootToken_CreatorArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


export type IndexerQuery_RootToken_Creator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


export type IndexerQuery_RootToken_Creator_By_PkArgs = {
  creator_address: Scalars['String'];
  token_pk: Scalars['bigint'];
};


export type IndexerQuery_RootToken_HolderArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


export type IndexerQuery_RootToken_Holder_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


export type IndexerQuery_RootToken_Holder_By_PkArgs = {
  holder_address: Scalars['String'];
  token_pk: Scalars['bigint'];
};


export type IndexerQuery_RootToken_Nsfw_OverrideArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Nsfw_Override_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Nsfw_Override_Order_By>>;
  where?: Maybe<IndexerToken_Nsfw_Override_Bool_Exp>;
};


export type IndexerQuery_RootToken_Nsfw_Override_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Nsfw_Override_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Nsfw_Override_Order_By>>;
  where?: Maybe<IndexerToken_Nsfw_Override_Bool_Exp>;
};


export type IndexerQuery_RootToken_Nsfw_Override_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootToken_OperatorArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


export type IndexerQuery_RootToken_Operator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


export type IndexerQuery_RootToken_Operator_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootToken_RegistryArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Registry_Order_By>>;
  where?: Maybe<IndexerToken_Registry_Bool_Exp>;
};


export type IndexerQuery_RootToken_Registry_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Registry_Order_By>>;
  where?: Maybe<IndexerToken_Registry_Bool_Exp>;
};


export type IndexerQuery_RootToken_Registry_By_PkArgs = {
  address: Scalars['String'];
};


export type IndexerQuery_RootToken_TagArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


export type IndexerQuery_RootToken_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


export type IndexerQuery_RootToken_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerQuery_RootTzd_DomainArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Domain_Order_By>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};


export type IndexerQuery_RootTzd_Domain_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Domain_Order_By>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};


export type IndexerQuery_RootTzd_Domain_By_PkArgs = {
  id: Scalars['String'];
};


export type IndexerQuery_RootTzd_RecordArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Record_Order_By>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};


export type IndexerQuery_RootTzd_Record_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Record_Order_By>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};


export type IndexerQuery_RootTzd_Record_By_PkArgs = {
  id: Scalars['String'];
};


export type IndexerQuery_RootTzd_TldArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Tld_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Tld_Order_By>>;
  where?: Maybe<IndexerTzd_Tld_Bool_Exp>;
};


export type IndexerQuery_RootTzd_Tld_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Tld_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Tld_Order_By>>;
  where?: Maybe<IndexerTzd_Tld_Bool_Exp>;
};


export type IndexerQuery_RootTzd_Tld_By_PkArgs = {
  id: Scalars['String'];
};

/** columns and relationships of "royalties" */
export type IndexerRoyalties = {
  __typename?: 'royalties';
  amount: Scalars['Int'];
  decimals: Scalars['Int'];
  /** An object relationship */
  holder: IndexerHolder;
  id: Scalars['bigint'];
  receiver_address: Scalars['String'];
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
};

/** aggregated selection of "royalties" */
export type IndexerRoyalties_Aggregate = {
  __typename?: 'royalties_aggregate';
  aggregate?: Maybe<IndexerRoyalties_Aggregate_Fields>;
  nodes: Array<IndexerRoyalties>;
};

export type IndexerRoyalties_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerRoyalties_Aggregate_Bool_Exp_Count>;
};

export type IndexerRoyalties_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerRoyalties_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "royalties" */
export type IndexerRoyalties_Aggregate_Fields = {
  __typename?: 'royalties_aggregate_fields';
  avg?: Maybe<IndexerRoyalties_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerRoyalties_Max_Fields>;
  min?: Maybe<IndexerRoyalties_Min_Fields>;
  stddev?: Maybe<IndexerRoyalties_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerRoyalties_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerRoyalties_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerRoyalties_Sum_Fields>;
  var_pop?: Maybe<IndexerRoyalties_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerRoyalties_Var_Samp_Fields>;
  variance?: Maybe<IndexerRoyalties_Variance_Fields>;
};


/** aggregate fields of "royalties" */
export type IndexerRoyalties_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "royalties" */
export type IndexerRoyalties_Aggregate_Order_By = {
  avg?: Maybe<IndexerRoyalties_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerRoyalties_Max_Order_By>;
  min?: Maybe<IndexerRoyalties_Min_Order_By>;
  stddev?: Maybe<IndexerRoyalties_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerRoyalties_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerRoyalties_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerRoyalties_Sum_Order_By>;
  var_pop?: Maybe<IndexerRoyalties_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerRoyalties_Var_Samp_Order_By>;
  variance?: Maybe<IndexerRoyalties_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerRoyalties_Avg_Fields = {
  __typename?: 'royalties_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "royalties" */
export type IndexerRoyalties_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "royalties". All fields are combined with a logical 'AND'. */
export type IndexerRoyalties_Bool_Exp = {
  _and?: Maybe<Array<IndexerRoyalties_Bool_Exp>>;
  _not?: Maybe<IndexerRoyalties_Bool_Exp>;
  _or?: Maybe<Array<IndexerRoyalties_Bool_Exp>>;
  amount?: Maybe<IndexerInt_Comparison_Exp>;
  decimals?: Maybe<IndexerInt_Comparison_Exp>;
  holder?: Maybe<IndexerHolder_Bool_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  receiver_address?: Maybe<IndexerString_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerRoyalties_Max_Fields = {
  __typename?: 'royalties_max_fields';
  amount?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  receiver_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "royalties" */
export type IndexerRoyalties_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  receiver_address?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerRoyalties_Min_Fields = {
  __typename?: 'royalties_min_fields';
  amount?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  receiver_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "royalties" */
export type IndexerRoyalties_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  receiver_address?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "royalties". */
export type IndexerRoyalties_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  holder?: Maybe<IndexerHolder_Order_By>;
  id?: Maybe<IndexerOrder_By>;
  receiver_address?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "royalties" */
export enum IndexerRoyalties_Select_Column {
  /** column name */
  Amount = 'amount',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Id = 'id',
  /** column name */
  ReceiverAddress = 'receiver_address',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerRoyalties_Stddev_Fields = {
  __typename?: 'royalties_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "royalties" */
export type IndexerRoyalties_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerRoyalties_Stddev_Pop_Fields = {
  __typename?: 'royalties_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "royalties" */
export type IndexerRoyalties_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerRoyalties_Stddev_Samp_Fields = {
  __typename?: 'royalties_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "royalties" */
export type IndexerRoyalties_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "royalties" */
export type IndexerRoyalties_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerRoyalties_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerRoyalties_Stream_Cursor_Value_Input = {
  amount?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  receiver_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerRoyalties_Sum_Fields = {
  __typename?: 'royalties_sum_fields';
  amount?: Maybe<Scalars['Int']>;
  decimals?: Maybe<Scalars['Int']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "royalties" */
export type IndexerRoyalties_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerRoyalties_Var_Pop_Fields = {
  __typename?: 'royalties_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "royalties" */
export type IndexerRoyalties_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerRoyalties_Var_Samp_Fields = {
  __typename?: 'royalties_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "royalties" */
export type IndexerRoyalties_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerRoyalties_Variance_Fields = {
  __typename?: 'royalties_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "royalties" */
export type IndexerRoyalties_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "sales_stat" */
export type IndexerSales_Stat = {
  __typename?: 'sales_stat';
  id: Scalars['bigint'];
  interval_days?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  /** An object relationship */
  subject: IndexerHolder;
  subject_address: Scalars['String'];
  type?: Maybe<Scalars['sales_stat_type']>;
  volume?: Maybe<Scalars['bigint']>;
};

/** aggregated selection of "sales_stat" */
export type IndexerSales_Stat_Aggregate = {
  __typename?: 'sales_stat_aggregate';
  aggregate?: Maybe<IndexerSales_Stat_Aggregate_Fields>;
  nodes: Array<IndexerSales_Stat>;
};

export type IndexerSales_Stat_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerSales_Stat_Aggregate_Bool_Exp_Count>;
};

export type IndexerSales_Stat_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerSales_Stat_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "sales_stat" */
export type IndexerSales_Stat_Aggregate_Fields = {
  __typename?: 'sales_stat_aggregate_fields';
  avg?: Maybe<IndexerSales_Stat_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerSales_Stat_Max_Fields>;
  min?: Maybe<IndexerSales_Stat_Min_Fields>;
  stddev?: Maybe<IndexerSales_Stat_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerSales_Stat_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerSales_Stat_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerSales_Stat_Sum_Fields>;
  var_pop?: Maybe<IndexerSales_Stat_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerSales_Stat_Var_Samp_Fields>;
  variance?: Maybe<IndexerSales_Stat_Variance_Fields>;
};


/** aggregate fields of "sales_stat" */
export type IndexerSales_Stat_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "sales_stat" */
export type IndexerSales_Stat_Aggregate_Order_By = {
  avg?: Maybe<IndexerSales_Stat_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerSales_Stat_Max_Order_By>;
  min?: Maybe<IndexerSales_Stat_Min_Order_By>;
  stddev?: Maybe<IndexerSales_Stat_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerSales_Stat_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerSales_Stat_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerSales_Stat_Sum_Order_By>;
  var_pop?: Maybe<IndexerSales_Stat_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerSales_Stat_Var_Samp_Order_By>;
  variance?: Maybe<IndexerSales_Stat_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerSales_Stat_Avg_Fields = {
  __typename?: 'sales_stat_avg_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "sales_stat" */
export type IndexerSales_Stat_Avg_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "sales_stat". All fields are combined with a logical 'AND'. */
export type IndexerSales_Stat_Bool_Exp = {
  _and?: Maybe<Array<IndexerSales_Stat_Bool_Exp>>;
  _not?: Maybe<IndexerSales_Stat_Bool_Exp>;
  _or?: Maybe<Array<IndexerSales_Stat_Bool_Exp>>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  interval_days?: Maybe<IndexerInt_Comparison_Exp>;
  rank?: Maybe<IndexerInt_Comparison_Exp>;
  subject?: Maybe<IndexerHolder_Bool_Exp>;
  subject_address?: Maybe<IndexerString_Comparison_Exp>;
  type?: Maybe<IndexerSales_Stat_Type_Comparison_Exp>;
  volume?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerSales_Stat_Max_Fields = {
  __typename?: 'sales_stat_max_fields';
  id?: Maybe<Scalars['bigint']>;
  interval_days?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  subject_address?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['sales_stat_type']>;
  volume?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "sales_stat" */
export type IndexerSales_Stat_Max_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  subject_address?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerSales_Stat_Min_Fields = {
  __typename?: 'sales_stat_min_fields';
  id?: Maybe<Scalars['bigint']>;
  interval_days?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  subject_address?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['sales_stat_type']>;
  volume?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "sales_stat" */
export type IndexerSales_Stat_Min_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  subject_address?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "sales_stat". */
export type IndexerSales_Stat_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  subject?: Maybe<IndexerHolder_Order_By>;
  subject_address?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** select columns of table "sales_stat" */
export enum IndexerSales_Stat_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  IntervalDays = 'interval_days',
  /** column name */
  Rank = 'rank',
  /** column name */
  SubjectAddress = 'subject_address',
  /** column name */
  Type = 'type',
  /** column name */
  Volume = 'volume'
}

/** aggregate stddev on columns */
export type IndexerSales_Stat_Stddev_Fields = {
  __typename?: 'sales_stat_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "sales_stat" */
export type IndexerSales_Stat_Stddev_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerSales_Stat_Stddev_Pop_Fields = {
  __typename?: 'sales_stat_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "sales_stat" */
export type IndexerSales_Stat_Stddev_Pop_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerSales_Stat_Stddev_Samp_Fields = {
  __typename?: 'sales_stat_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "sales_stat" */
export type IndexerSales_Stat_Stddev_Samp_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "sales_stat" */
export type IndexerSales_Stat_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerSales_Stat_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerSales_Stat_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['bigint']>;
  interval_days?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  subject_address?: Maybe<Scalars['String']>;
  type?: Maybe<Scalars['sales_stat_type']>;
  volume?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerSales_Stat_Sum_Fields = {
  __typename?: 'sales_stat_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  interval_days?: Maybe<Scalars['Int']>;
  rank?: Maybe<Scalars['Int']>;
  volume?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "sales_stat" */
export type IndexerSales_Stat_Sum_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "sales_stat_type". All fields are combined with logical 'AND'. */
export type IndexerSales_Stat_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['sales_stat_type']>;
  _gt?: Maybe<Scalars['sales_stat_type']>;
  _gte?: Maybe<Scalars['sales_stat_type']>;
  _in?: Maybe<Array<Scalars['sales_stat_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['sales_stat_type']>;
  _lte?: Maybe<Scalars['sales_stat_type']>;
  _neq?: Maybe<Scalars['sales_stat_type']>;
  _nin?: Maybe<Array<Scalars['sales_stat_type']>>;
};

/** aggregate var_pop on columns */
export type IndexerSales_Stat_Var_Pop_Fields = {
  __typename?: 'sales_stat_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "sales_stat" */
export type IndexerSales_Stat_Var_Pop_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerSales_Stat_Var_Samp_Fields = {
  __typename?: 'sales_stat_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "sales_stat" */
export type IndexerSales_Stat_Var_Samp_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerSales_Stat_Variance_Fields = {
  __typename?: 'sales_stat_variance_fields';
  id?: Maybe<Scalars['Float']>;
  interval_days?: Maybe<Scalars['Float']>;
  rank?: Maybe<Scalars['Float']>;
  volume?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "sales_stat" */
export type IndexerSales_Stat_Variance_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  interval_days?: Maybe<IndexerOrder_By>;
  rank?: Maybe<IndexerOrder_By>;
  volume?: Maybe<IndexerOrder_By>;
};

export type IndexerSubscription_Root = {
  __typename?: 'subscription_root';
  /** fetch data from the table: "attribute" */
  attribute: Array<IndexerAttribute>;
  /** fetch aggregated fields from the table: "attribute" */
  attribute_aggregate: IndexerAttribute_Aggregate;
  /** fetch data from the table: "attribute" using primary key columns */
  attribute_by_pk?: Maybe<IndexerAttribute>;
  /** fetch data from the table in a streaming manner: "attribute" */
  attribute_stream: Array<IndexerAttribute>;
  /** fetch data from the table: "currency" */
  currency: Array<IndexerCurrency>;
  /** fetch aggregated fields from the table: "currency" */
  currency_aggregate: IndexerCurrency_Aggregate;
  /** fetch data from the table: "currency" using primary key columns */
  currency_by_pk?: Maybe<IndexerCurrency>;
  /** fetch data from the table in a streaming manner: "currency" */
  currency_stream: Array<IndexerCurrency>;
  /** fetch data from the table: "dutch_auction" */
  dutch_auction: Array<IndexerDutch_Auction>;
  /** fetch data from the table: "dutch_auction_active" */
  dutch_auction_active: Array<IndexerDutch_Auction_Active>;
  /** fetch aggregated fields from the table: "dutch_auction_active" */
  dutch_auction_active_aggregate: IndexerDutch_Auction_Active_Aggregate;
  /** fetch data from the table in a streaming manner: "dutch_auction_active" */
  dutch_auction_active_stream: Array<IndexerDutch_Auction_Active>;
  /** fetch aggregated fields from the table: "dutch_auction" */
  dutch_auction_aggregate: IndexerDutch_Auction_Aggregate;
  /** fetch data from the table: "dutch_auction" using primary key columns */
  dutch_auction_by_pk?: Maybe<IndexerDutch_Auction>;
  /** fetch data from the table: "dutch_auction_sale" */
  dutch_auction_sale: Array<IndexerDutch_Auction_Sale>;
  /** fetch aggregated fields from the table: "dutch_auction_sale" */
  dutch_auction_sale_aggregate: IndexerDutch_Auction_Sale_Aggregate;
  /** fetch data from the table: "dutch_auction_sale" using primary key columns */
  dutch_auction_sale_by_pk?: Maybe<IndexerDutch_Auction_Sale>;
  /** fetch data from the table in a streaming manner: "dutch_auction_sale" */
  dutch_auction_sale_stream: Array<IndexerDutch_Auction_Sale>;
  /** fetch data from the table in a streaming manner: "dutch_auction" */
  dutch_auction_stream: Array<IndexerDutch_Auction>;
  /** fetch data from the table: "english_auction" */
  english_auction: Array<IndexerEnglish_Auction>;
  /** fetch data from the table: "english_auction_active" */
  english_auction_active: Array<IndexerEnglish_Auction_Active>;
  /** fetch aggregated fields from the table: "english_auction_active" */
  english_auction_active_aggregate: IndexerEnglish_Auction_Active_Aggregate;
  /** fetch data from the table in a streaming manner: "english_auction_active" */
  english_auction_active_stream: Array<IndexerEnglish_Auction_Active>;
  /** fetch aggregated fields from the table: "english_auction" */
  english_auction_aggregate: IndexerEnglish_Auction_Aggregate;
  /** fetch data from the table: "english_auction_bid" */
  english_auction_bid: Array<IndexerEnglish_Auction_Bid>;
  /** fetch aggregated fields from the table: "english_auction_bid" */
  english_auction_bid_aggregate: IndexerEnglish_Auction_Bid_Aggregate;
  /** fetch data from the table: "english_auction_bid" using primary key columns */
  english_auction_bid_by_pk?: Maybe<IndexerEnglish_Auction_Bid>;
  /** fetch data from the table in a streaming manner: "english_auction_bid" */
  english_auction_bid_stream: Array<IndexerEnglish_Auction_Bid>;
  /** fetch data from the table: "english_auction" using primary key columns */
  english_auction_by_pk?: Maybe<IndexerEnglish_Auction>;
  /** fetch data from the table in a streaming manner: "english_auction" */
  english_auction_stream: Array<IndexerEnglish_Auction>;
  /** fetch data from the table: "event" */
  event: Array<IndexerEvent>;
  /** fetch aggregated fields from the table: "event" */
  event_aggregate: IndexerEvent_Aggregate;
  /** fetch data from the table: "event" using primary key columns */
  event_by_pk?: Maybe<IndexerEvent>;
  /** fetch data from the table in a streaming manner: "event" */
  event_stream: Array<IndexerEvent>;
  /** fetch data from the table: "fa" */
  fa: Array<IndexerFa>;
  /** fetch data from the table: "fa2_attribute_count" */
  fa2_attribute_count: Array<IndexerFa2_Attribute_Count>;
  /** fetch aggregated fields from the table: "fa2_attribute_count" */
  fa2_attribute_count_aggregate: IndexerFa2_Attribute_Count_Aggregate;
  /** fetch data from the table: "fa2_attribute_count" using primary key columns */
  fa2_attribute_count_by_pk?: Maybe<IndexerFa2_Attribute_Count>;
  /** fetch data from the table in a streaming manner: "fa2_attribute_count" */
  fa2_attribute_count_stream: Array<IndexerFa2_Attribute_Count>;
  /** fetch data from the table: "fa2_creator" */
  fa2_creator: Array<IndexerFa2_Creator>;
  /** fetch aggregated fields from the table: "fa2_creator" */
  fa2_creator_aggregate: IndexerFa2_Creator_Aggregate;
  /** fetch data from the table: "fa2_creator" using primary key columns */
  fa2_creator_by_pk?: Maybe<IndexerFa2_Creator>;
  /** fetch data from the table in a streaming manner: "fa2_creator" */
  fa2_creator_stream: Array<IndexerFa2_Creator>;
  /** fetch data from the table: "fa2_manager" */
  fa2_manager: Array<IndexerFa2_Manager>;
  /** fetch aggregated fields from the table: "fa2_manager" */
  fa2_manager_aggregate: IndexerFa2_Manager_Aggregate;
  /** fetch data from the table: "fa2_manager" using primary key columns */
  fa2_manager_by_pk?: Maybe<IndexerFa2_Manager>;
  /** fetch data from the table in a streaming manner: "fa2_manager" */
  fa2_manager_stream: Array<IndexerFa2_Manager>;
  /** fetch aggregated fields from the table: "fa" */
  fa_aggregate: IndexerFa_Aggregate;
  /** fetch data from the table: "fa" using primary key columns */
  fa_by_pk?: Maybe<IndexerFa>;
  /** fetch data from the table in a streaming manner: "fa" */
  fa_stream: Array<IndexerFa>;
  /** fetch data from the table: "gallery" */
  gallery: Array<IndexerGallery>;
  /** fetch aggregated fields from the table: "gallery" */
  gallery_aggregate: IndexerGallery_Aggregate;
  /** fetch data from the table: "gallery_attribute_count" */
  gallery_attribute_count: Array<IndexerGallery_Attribute_Count>;
  /** fetch aggregated fields from the table: "gallery_attribute_count" */
  gallery_attribute_count_aggregate: IndexerGallery_Attribute_Count_Aggregate;
  /** fetch data from the table: "gallery_attribute_count" using primary key columns */
  gallery_attribute_count_by_pk?: Maybe<IndexerGallery_Attribute_Count>;
  /** fetch data from the table in a streaming manner: "gallery_attribute_count" */
  gallery_attribute_count_stream: Array<IndexerGallery_Attribute_Count>;
  /** fetch data from the table: "gallery" using primary key columns */
  gallery_by_pk?: Maybe<IndexerGallery>;
  /** fetch data from the table: "gallery_curator" */
  gallery_curator: Array<IndexerGallery_Curator>;
  /** fetch aggregated fields from the table: "gallery_curator" */
  gallery_curator_aggregate: IndexerGallery_Curator_Aggregate;
  /** fetch data from the table: "gallery_curator" using primary key columns */
  gallery_curator_by_pk?: Maybe<IndexerGallery_Curator>;
  /** fetch data from the table in a streaming manner: "gallery_curator" */
  gallery_curator_stream: Array<IndexerGallery_Curator>;
  /** fetch data from the table: "gallery_registry" */
  gallery_registry: Array<IndexerGallery_Registry>;
  /** fetch aggregated fields from the table: "gallery_registry" */
  gallery_registry_aggregate: IndexerGallery_Registry_Aggregate;
  /** fetch data from the table: "gallery_registry" using primary key columns */
  gallery_registry_by_pk?: Maybe<IndexerGallery_Registry>;
  /** fetch data from the table in a streaming manner: "gallery_registry" */
  gallery_registry_stream: Array<IndexerGallery_Registry>;
  /** fetch data from the table in a streaming manner: "gallery" */
  gallery_stream: Array<IndexerGallery>;
  /** fetch data from the table: "gallery_token" */
  gallery_token: Array<IndexerGallery_Token>;
  /** fetch aggregated fields from the table: "gallery_token" */
  gallery_token_aggregate: IndexerGallery_Token_Aggregate;
  /** fetch data from the table: "gallery_token" using primary key columns */
  gallery_token_by_pk?: Maybe<IndexerGallery_Token>;
  /** fetch data from the table in a streaming manner: "gallery_token" */
  gallery_token_stream: Array<IndexerGallery_Token>;
  /** fetch data from the table: "holder" */
  holder: Array<IndexerHolder>;
  /** fetch aggregated fields from the table: "holder" */
  holder_aggregate: IndexerHolder_Aggregate;
  /** fetch data from the table: "holder" using primary key columns */
  holder_by_pk?: Maybe<IndexerHolder>;
  /** fetch data from the table in a streaming manner: "holder" */
  holder_stream: Array<IndexerHolder>;
  /** fetch data from the table: "invitation" */
  invitation: Array<IndexerInvitation>;
  /** fetch aggregated fields from the table: "invitation" */
  invitation_aggregate: IndexerInvitation_Aggregate;
  /** fetch data from the table: "invitation" using primary key columns */
  invitation_by_pk?: Maybe<IndexerInvitation>;
  /** fetch data from the table in a streaming manner: "invitation" */
  invitation_stream: Array<IndexerInvitation>;
  /** fetch data from the table: "listing" */
  listing: Array<IndexerListing>;
  /** fetch data from the table: "listing_active" */
  listing_active: Array<IndexerListing_Active>;
  /** fetch aggregated fields from the table: "listing_active" */
  listing_active_aggregate: IndexerListing_Active_Aggregate;
  /** fetch data from the table in a streaming manner: "listing_active" */
  listing_active_stream: Array<IndexerListing_Active>;
  /** fetch aggregated fields from the table: "listing" */
  listing_aggregate: IndexerListing_Aggregate;
  /** fetch data from the table: "listing" using primary key columns */
  listing_by_pk?: Maybe<IndexerListing>;
  /** fetch data from the table: "listing_sale" */
  listing_sale: Array<IndexerListing_Sale>;
  /** fetch aggregated fields from the table: "listing_sale" */
  listing_sale_aggregate: IndexerListing_Sale_Aggregate;
  /** fetch data from the table: "listing_sale" using primary key columns */
  listing_sale_by_pk?: Maybe<IndexerListing_Sale>;
  /** fetch data from the table in a streaming manner: "listing_sale" */
  listing_sale_stream: Array<IndexerListing_Sale>;
  /** fetch data from the table in a streaming manner: "listing" */
  listing_stream: Array<IndexerListing>;
  /** fetch data from the table: "marketplace_contract" */
  marketplace_contract: Array<IndexerMarketplace_Contract>;
  /** fetch aggregated fields from the table: "marketplace_contract" */
  marketplace_contract_aggregate: IndexerMarketplace_Contract_Aggregate;
  /** fetch data from the table: "marketplace_contract" using primary key columns */
  marketplace_contract_by_pk?: Maybe<IndexerMarketplace_Contract>;
  /** fetch data from the table in a streaming manner: "marketplace_contract" */
  marketplace_contract_stream: Array<IndexerMarketplace_Contract>;
  /** fetch data from the table: "offer" */
  offer: Array<IndexerOffer>;
  /** fetch data from the table: "offer_active" */
  offer_active: Array<IndexerOffer_Active>;
  /** fetch aggregated fields from the table: "offer_active" */
  offer_active_aggregate: IndexerOffer_Active_Aggregate;
  /** fetch data from the table in a streaming manner: "offer_active" */
  offer_active_stream: Array<IndexerOffer_Active>;
  /** fetch aggregated fields from the table: "offer" */
  offer_aggregate: IndexerOffer_Aggregate;
  /** fetch data from the table: "offer" using primary key columns */
  offer_by_pk?: Maybe<IndexerOffer>;
  /** fetch data from the table in a streaming manner: "offer" */
  offer_stream: Array<IndexerOffer>;
  /** An array relationship */
  open_edition: Array<IndexerOpen_Edition>;
  /** An array relationship */
  open_edition_active: Array<IndexerOpen_Edition_Active>;
  /** An aggregate relationship */
  open_edition_active_aggregate: IndexerOpen_Edition_Active_Aggregate;
  /** fetch data from the table in a streaming manner: "open_edition_active" */
  open_edition_active_stream: Array<IndexerOpen_Edition_Active>;
  /** An aggregate relationship */
  open_edition_aggregate: IndexerOpen_Edition_Aggregate;
  /** fetch data from the table: "open_edition" using primary key columns */
  open_edition_by_pk?: Maybe<IndexerOpen_Edition>;
  /** fetch data from the table in a streaming manner: "open_edition" */
  open_edition_stream: Array<IndexerOpen_Edition>;
  /** An array relationship */
  royalties: Array<IndexerRoyalties>;
  /** An aggregate relationship */
  royalties_aggregate: IndexerRoyalties_Aggregate;
  /** fetch data from the table: "royalties" using primary key columns */
  royalties_by_pk?: Maybe<IndexerRoyalties>;
  /** fetch data from the table in a streaming manner: "royalties" */
  royalties_stream: Array<IndexerRoyalties>;
  /** fetch data from the table: "sales_stat" */
  sales_stat: Array<IndexerSales_Stat>;
  /** fetch aggregated fields from the table: "sales_stat" */
  sales_stat_aggregate: IndexerSales_Stat_Aggregate;
  /** fetch data from the table: "sales_stat" using primary key columns */
  sales_stat_by_pk?: Maybe<IndexerSales_Stat>;
  /** fetch data from the table in a streaming manner: "sales_stat" */
  sales_stat_stream: Array<IndexerSales_Stat>;
  /** fetch data from the table: "tag" */
  tag: Array<IndexerTag>;
  /** fetch aggregated fields from the table: "tag" */
  tag_aggregate: IndexerTag_Aggregate;
  /** fetch data from the table: "tag" using primary key columns */
  tag_by_pk?: Maybe<IndexerTag>;
  /** fetch data from the table in a streaming manner: "tag" */
  tag_stream: Array<IndexerTag>;
  /** fetch data from the table: "tezos_storage" */
  tezos_storage: Array<IndexerTezos_Storage>;
  /** fetch aggregated fields from the table: "tezos_storage" */
  tezos_storage_aggregate: IndexerTezos_Storage_Aggregate;
  /** fetch data from the table: "tezos_storage" using primary key columns */
  tezos_storage_by_pk?: Maybe<IndexerTezos_Storage>;
  /** fetch data from the table in a streaming manner: "tezos_storage" */
  tezos_storage_stream: Array<IndexerTezos_Storage>;
  /** fetch data from the table: "token" */
  token: Array<IndexerToken>;
  /** fetch aggregated fields from the table: "token" */
  token_aggregate: IndexerToken_Aggregate;
  /** fetch data from the table: "token_attribute" */
  token_attribute: Array<IndexerToken_Attribute>;
  /** fetch aggregated fields from the table: "token_attribute" */
  token_attribute_aggregate: IndexerToken_Attribute_Aggregate;
  /** fetch data from the table: "token_attribute" using primary key columns */
  token_attribute_by_pk?: Maybe<IndexerToken_Attribute>;
  /** fetch data from the table in a streaming manner: "token_attribute" */
  token_attribute_stream: Array<IndexerToken_Attribute>;
  /** fetch data from the table: "token" using primary key columns */
  token_by_pk?: Maybe<IndexerToken>;
  /** fetch data from the table: "token_creator" */
  token_creator: Array<IndexerToken_Creator>;
  /** fetch aggregated fields from the table: "token_creator" */
  token_creator_aggregate: IndexerToken_Creator_Aggregate;
  /** fetch data from the table: "token_creator" using primary key columns */
  token_creator_by_pk?: Maybe<IndexerToken_Creator>;
  /** fetch data from the table in a streaming manner: "token_creator" */
  token_creator_stream: Array<IndexerToken_Creator>;
  /** fetch data from the table: "token_holder" */
  token_holder: Array<IndexerToken_Holder>;
  /** fetch aggregated fields from the table: "token_holder" */
  token_holder_aggregate: IndexerToken_Holder_Aggregate;
  /** fetch data from the table: "token_holder" using primary key columns */
  token_holder_by_pk?: Maybe<IndexerToken_Holder>;
  /** fetch data from the table in a streaming manner: "token_holder" */
  token_holder_stream: Array<IndexerToken_Holder>;
  /** fetch data from the table: "token_nsfw_override" */
  token_nsfw_override: Array<IndexerToken_Nsfw_Override>;
  /** fetch aggregated fields from the table: "token_nsfw_override" */
  token_nsfw_override_aggregate: IndexerToken_Nsfw_Override_Aggregate;
  /** fetch data from the table: "token_nsfw_override" using primary key columns */
  token_nsfw_override_by_pk?: Maybe<IndexerToken_Nsfw_Override>;
  /** fetch data from the table in a streaming manner: "token_nsfw_override" */
  token_nsfw_override_stream: Array<IndexerToken_Nsfw_Override>;
  /** fetch data from the table: "token_operator" */
  token_operator: Array<IndexerToken_Operator>;
  /** fetch aggregated fields from the table: "token_operator" */
  token_operator_aggregate: IndexerToken_Operator_Aggregate;
  /** fetch data from the table: "token_operator" using primary key columns */
  token_operator_by_pk?: Maybe<IndexerToken_Operator>;
  /** fetch data from the table in a streaming manner: "token_operator" */
  token_operator_stream: Array<IndexerToken_Operator>;
  /** fetch data from the table: "token_registry" */
  token_registry: Array<IndexerToken_Registry>;
  /** fetch aggregated fields from the table: "token_registry" */
  token_registry_aggregate: IndexerToken_Registry_Aggregate;
  /** fetch data from the table: "token_registry" using primary key columns */
  token_registry_by_pk?: Maybe<IndexerToken_Registry>;
  /** fetch data from the table in a streaming manner: "token_registry" */
  token_registry_stream: Array<IndexerToken_Registry>;
  /** fetch data from the table in a streaming manner: "token" */
  token_stream: Array<IndexerToken>;
  /** fetch data from the table: "token_tag" */
  token_tag: Array<IndexerToken_Tag>;
  /** fetch aggregated fields from the table: "token_tag" */
  token_tag_aggregate: IndexerToken_Tag_Aggregate;
  /** fetch data from the table: "token_tag" using primary key columns */
  token_tag_by_pk?: Maybe<IndexerToken_Tag>;
  /** fetch data from the table in a streaming manner: "token_tag" */
  token_tag_stream: Array<IndexerToken_Tag>;
  /** fetch data from the table: "tzd_domain" */
  tzd_domain: Array<IndexerTzd_Domain>;
  /** fetch aggregated fields from the table: "tzd_domain" */
  tzd_domain_aggregate: IndexerTzd_Domain_Aggregate;
  /** fetch data from the table: "tzd_domain" using primary key columns */
  tzd_domain_by_pk?: Maybe<IndexerTzd_Domain>;
  /** fetch data from the table in a streaming manner: "tzd_domain" */
  tzd_domain_stream: Array<IndexerTzd_Domain>;
  /** fetch data from the table: "tzd_record" */
  tzd_record: Array<IndexerTzd_Record>;
  /** fetch aggregated fields from the table: "tzd_record" */
  tzd_record_aggregate: IndexerTzd_Record_Aggregate;
  /** fetch data from the table: "tzd_record" using primary key columns */
  tzd_record_by_pk?: Maybe<IndexerTzd_Record>;
  /** fetch data from the table in a streaming manner: "tzd_record" */
  tzd_record_stream: Array<IndexerTzd_Record>;
  /** fetch data from the table: "tzd_tld" */
  tzd_tld: Array<IndexerTzd_Tld>;
  /** fetch aggregated fields from the table: "tzd_tld" */
  tzd_tld_aggregate: IndexerTzd_Tld_Aggregate;
  /** fetch data from the table: "tzd_tld" using primary key columns */
  tzd_tld_by_pk?: Maybe<IndexerTzd_Tld>;
  /** fetch data from the table in a streaming manner: "tzd_tld" */
  tzd_tld_stream: Array<IndexerTzd_Tld>;
};


export type IndexerSubscription_RootAttributeArgs = {
  distinct_on?: Maybe<Array<IndexerAttribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerAttribute_Order_By>>;
  where?: Maybe<IndexerAttribute_Bool_Exp>;
};


export type IndexerSubscription_RootAttribute_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerAttribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerAttribute_Order_By>>;
  where?: Maybe<IndexerAttribute_Bool_Exp>;
};


export type IndexerSubscription_RootAttribute_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootAttribute_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerAttribute_Stream_Cursor_Input>>;
  where?: Maybe<IndexerAttribute_Bool_Exp>;
};


export type IndexerSubscription_RootCurrencyArgs = {
  distinct_on?: Maybe<Array<IndexerCurrency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerCurrency_Order_By>>;
  where?: Maybe<IndexerCurrency_Bool_Exp>;
};


export type IndexerSubscription_RootCurrency_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerCurrency_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerCurrency_Order_By>>;
  where?: Maybe<IndexerCurrency_Bool_Exp>;
};


export type IndexerSubscription_RootCurrency_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootCurrency_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerCurrency_Stream_Cursor_Input>>;
  where?: Maybe<IndexerCurrency_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_AuctionArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerDutch_Auction_Active_Stream_Cursor_Input>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootDutch_Auction_SaleArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_Sale_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Sale_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_Sale_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootDutch_Auction_Sale_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerDutch_Auction_Sale_Stream_Cursor_Input>>;
  where?: Maybe<IndexerDutch_Auction_Sale_Bool_Exp>;
};


export type IndexerSubscription_RootDutch_Auction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerDutch_Auction_Stream_Cursor_Input>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_AuctionArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerEnglish_Auction_Active_Stream_Cursor_Input>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_BidArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_Bid_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Bid_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Bid_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_Bid_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootEnglish_Auction_Bid_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerEnglish_Auction_Bid_Stream_Cursor_Input>>;
  where?: Maybe<IndexerEnglish_Auction_Bid_Bool_Exp>;
};


export type IndexerSubscription_RootEnglish_Auction_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootEnglish_Auction_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerEnglish_Auction_Stream_Cursor_Input>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


export type IndexerSubscription_RootEventArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


export type IndexerSubscription_RootEvent_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


export type IndexerSubscription_RootEvent_By_PkArgs = {
  id: Scalars['bigint'];
  timestamp: Scalars['timestamptz'];
};


export type IndexerSubscription_RootEvent_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerEvent_Stream_Cursor_Input>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


export type IndexerSubscription_RootFaArgs = {
  distinct_on?: Maybe<Array<IndexerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa_Order_By>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Attribute_CountArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Attribute_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Attribute_Count_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootFa2_Attribute_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerFa2_Attribute_Count_Stream_Cursor_Input>>;
  where?: Maybe<IndexerFa2_Attribute_Count_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_CreatorArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Creator_Order_By>>;
  where?: Maybe<IndexerFa2_Creator_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Creator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Creator_Order_By>>;
  where?: Maybe<IndexerFa2_Creator_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Creator_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootFa2_Creator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerFa2_Creator_Stream_Cursor_Input>>;
  where?: Maybe<IndexerFa2_Creator_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_ManagerArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Manager_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa2_Manager_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa2_Manager_Order_By>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


export type IndexerSubscription_RootFa2_Manager_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootFa2_Manager_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerFa2_Manager_Stream_Cursor_Input>>;
  where?: Maybe<IndexerFa2_Manager_Bool_Exp>;
};


export type IndexerSubscription_RootFa_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerFa_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerFa_Order_By>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


export type IndexerSubscription_RootFa_By_PkArgs = {
  contract: Scalars['String'];
};


export type IndexerSubscription_RootFa_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerFa_Stream_Cursor_Input>>;
  where?: Maybe<IndexerFa_Bool_Exp>;
};


export type IndexerSubscription_RootGalleryArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Order_By>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Order_By>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Attribute_CountArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerGallery_Attribute_Count_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Attribute_Count_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Attribute_Count_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Attribute_Count_Order_By>>;
  where?: Maybe<IndexerGallery_Attribute_Count_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Attribute_Count_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootGallery_Attribute_Count_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerGallery_Attribute_Count_Stream_Cursor_Input>>;
  where?: Maybe<IndexerGallery_Attribute_Count_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_By_PkArgs = {
  pk: Scalars['bigint'];
};


export type IndexerSubscription_RootGallery_CuratorArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Curator_Order_By>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Curator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Curator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Curator_Order_By>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Curator_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootGallery_Curator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerGallery_Curator_Stream_Cursor_Input>>;
  where?: Maybe<IndexerGallery_Curator_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_RegistryArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Registry_Order_By>>;
  where?: Maybe<IndexerGallery_Registry_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Registry_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Registry_Order_By>>;
  where?: Maybe<IndexerGallery_Registry_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Registry_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootGallery_Registry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerGallery_Registry_Stream_Cursor_Input>>;
  where?: Maybe<IndexerGallery_Registry_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerGallery_Stream_Cursor_Input>>;
  where?: Maybe<IndexerGallery_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_TokenArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Token_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


export type IndexerSubscription_RootGallery_Token_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootGallery_Token_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerGallery_Token_Stream_Cursor_Input>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


export type IndexerSubscription_RootHolderArgs = {
  distinct_on?: Maybe<Array<IndexerHolder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerHolder_Order_By>>;
  where?: Maybe<IndexerHolder_Bool_Exp>;
};


export type IndexerSubscription_RootHolder_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerHolder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerHolder_Order_By>>;
  where?: Maybe<IndexerHolder_Bool_Exp>;
};


export type IndexerSubscription_RootHolder_By_PkArgs = {
  address: Scalars['String'];
};


export type IndexerSubscription_RootHolder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerHolder_Stream_Cursor_Input>>;
  where?: Maybe<IndexerHolder_Bool_Exp>;
};


export type IndexerSubscription_RootInvitationArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


export type IndexerSubscription_RootInvitation_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerInvitation_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerInvitation_Order_By>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


export type IndexerSubscription_RootInvitation_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootInvitation_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerInvitation_Stream_Cursor_Input>>;
  where?: Maybe<IndexerInvitation_Bool_Exp>;
};


export type IndexerSubscription_RootListingArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


export type IndexerSubscription_RootListing_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


export type IndexerSubscription_RootListing_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


export type IndexerSubscription_RootListing_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerListing_Active_Stream_Cursor_Input>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


export type IndexerSubscription_RootListing_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


export type IndexerSubscription_RootListing_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootListing_SaleArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


export type IndexerSubscription_RootListing_Sale_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


export type IndexerSubscription_RootListing_Sale_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootListing_Sale_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerListing_Sale_Stream_Cursor_Input>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


export type IndexerSubscription_RootListing_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerListing_Stream_Cursor_Input>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


export type IndexerSubscription_RootMarketplace_ContractArgs = {
  distinct_on?: Maybe<Array<IndexerMarketplace_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerMarketplace_Contract_Order_By>>;
  where?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
};


export type IndexerSubscription_RootMarketplace_Contract_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerMarketplace_Contract_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerMarketplace_Contract_Order_By>>;
  where?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
};


export type IndexerSubscription_RootMarketplace_Contract_By_PkArgs = {
  contract: Scalars['String'];
};


export type IndexerSubscription_RootMarketplace_Contract_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerMarketplace_Contract_Stream_Cursor_Input>>;
  where?: Maybe<IndexerMarketplace_Contract_Bool_Exp>;
};


export type IndexerSubscription_RootOfferArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


export type IndexerSubscription_RootOffer_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


export type IndexerSubscription_RootOffer_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


export type IndexerSubscription_RootOffer_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerOffer_Active_Stream_Cursor_Input>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


export type IndexerSubscription_RootOffer_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


export type IndexerSubscription_RootOffer_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootOffer_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerOffer_Stream_Cursor_Input>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


export type IndexerSubscription_RootOpen_EditionArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


export type IndexerSubscription_RootOpen_Edition_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


export type IndexerSubscription_RootOpen_Edition_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Active_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


export type IndexerSubscription_RootOpen_Edition_Active_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerOpen_Edition_Active_Stream_Cursor_Input>>;
  where?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
};


export type IndexerSubscription_RootOpen_Edition_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOpen_Edition_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOpen_Edition_Order_By>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


export type IndexerSubscription_RootOpen_Edition_By_PkArgs = {
  token_pk: Scalars['bigint'];
};


export type IndexerSubscription_RootOpen_Edition_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerOpen_Edition_Stream_Cursor_Input>>;
  where?: Maybe<IndexerOpen_Edition_Bool_Exp>;
};


export type IndexerSubscription_RootRoyaltiesArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


export type IndexerSubscription_RootRoyalties_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


export type IndexerSubscription_RootRoyalties_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootRoyalties_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerRoyalties_Stream_Cursor_Input>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


export type IndexerSubscription_RootSales_StatArgs = {
  distinct_on?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerSales_Stat_Order_By>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};


export type IndexerSubscription_RootSales_Stat_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerSales_Stat_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerSales_Stat_Order_By>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};


export type IndexerSubscription_RootSales_Stat_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootSales_Stat_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerSales_Stat_Stream_Cursor_Input>>;
  where?: Maybe<IndexerSales_Stat_Bool_Exp>;
};


export type IndexerSubscription_RootTagArgs = {
  distinct_on?: Maybe<Array<IndexerTag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTag_Order_By>>;
  where?: Maybe<IndexerTag_Bool_Exp>;
};


export type IndexerSubscription_RootTag_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTag_Order_By>>;
  where?: Maybe<IndexerTag_Bool_Exp>;
};


export type IndexerSubscription_RootTag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootTag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerTag_Stream_Cursor_Input>>;
  where?: Maybe<IndexerTag_Bool_Exp>;
};


export type IndexerSubscription_RootTezos_StorageArgs = {
  distinct_on?: Maybe<Array<IndexerTezos_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTezos_Storage_Order_By>>;
  where?: Maybe<IndexerTezos_Storage_Bool_Exp>;
};


export type IndexerSubscription_RootTezos_Storage_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTezos_Storage_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTezos_Storage_Order_By>>;
  where?: Maybe<IndexerTezos_Storage_Bool_Exp>;
};


export type IndexerSubscription_RootTezos_Storage_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootTezos_Storage_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerTezos_Storage_Stream_Cursor_Input>>;
  where?: Maybe<IndexerTezos_Storage_Bool_Exp>;
};


export type IndexerSubscription_RootTokenArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Order_By>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


export type IndexerSubscription_RootToken_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Order_By>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


export type IndexerSubscription_RootToken_AttributeArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Attribute_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Attribute_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_Attribute_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Attribute_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


export type IndexerSubscription_RootToken_By_PkArgs = {
  pk: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_CreatorArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Creator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Creator_By_PkArgs = {
  creator_address: Scalars['String'];
  token_pk: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_Creator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Creator_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


export type IndexerSubscription_RootToken_HolderArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Holder_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Holder_By_PkArgs = {
  holder_address: Scalars['String'];
  token_pk: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_Holder_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Holder_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Nsfw_OverrideArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Nsfw_Override_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Nsfw_Override_Order_By>>;
  where?: Maybe<IndexerToken_Nsfw_Override_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Nsfw_Override_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Nsfw_Override_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Nsfw_Override_Order_By>>;
  where?: Maybe<IndexerToken_Nsfw_Override_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Nsfw_Override_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_Nsfw_Override_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Nsfw_Override_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Nsfw_Override_Bool_Exp>;
};


export type IndexerSubscription_RootToken_OperatorArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Operator_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Operator_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_Operator_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Operator_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


export type IndexerSubscription_RootToken_RegistryArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Registry_Order_By>>;
  where?: Maybe<IndexerToken_Registry_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Registry_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Registry_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Registry_Order_By>>;
  where?: Maybe<IndexerToken_Registry_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Registry_By_PkArgs = {
  address: Scalars['String'];
};


export type IndexerSubscription_RootToken_Registry_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Registry_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Registry_Bool_Exp>;
};


export type IndexerSubscription_RootToken_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Bool_Exp>;
};


export type IndexerSubscription_RootToken_TagArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Tag_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


export type IndexerSubscription_RootToken_Tag_By_PkArgs = {
  id: Scalars['bigint'];
};


export type IndexerSubscription_RootToken_Tag_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerToken_Tag_Stream_Cursor_Input>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_DomainArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Domain_Order_By>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_Domain_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Domain_Order_By>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_Domain_By_PkArgs = {
  id: Scalars['String'];
};


export type IndexerSubscription_RootTzd_Domain_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerTzd_Domain_Stream_Cursor_Input>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_RecordArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Record_Order_By>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_Record_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Record_Order_By>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_Record_By_PkArgs = {
  id: Scalars['String'];
};


export type IndexerSubscription_RootTzd_Record_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerTzd_Record_Stream_Cursor_Input>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_TldArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Tld_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Tld_Order_By>>;
  where?: Maybe<IndexerTzd_Tld_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_Tld_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Tld_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Tld_Order_By>>;
  where?: Maybe<IndexerTzd_Tld_Bool_Exp>;
};


export type IndexerSubscription_RootTzd_Tld_By_PkArgs = {
  id: Scalars['String'];
};


export type IndexerSubscription_RootTzd_Tld_StreamArgs = {
  batch_size: Scalars['Int'];
  cursor: Array<Maybe<IndexerTzd_Tld_Stream_Cursor_Input>>;
  where?: Maybe<IndexerTzd_Tld_Bool_Exp>;
};

/** columns and relationships of "tag" */
export type IndexerTag = {
  __typename?: 'tag';
  id: Scalars['bigint'];
  name?: Maybe<Scalars['String']>;
  token_count?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  tokens: Array<IndexerToken_Tag>;
  /** An aggregate relationship */
  tokens_aggregate: IndexerToken_Tag_Aggregate;
};


/** columns and relationships of "tag" */
export type IndexerTagTokensArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


/** columns and relationships of "tag" */
export type IndexerTagTokens_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};

/** aggregated selection of "tag" */
export type IndexerTag_Aggregate = {
  __typename?: 'tag_aggregate';
  aggregate?: Maybe<IndexerTag_Aggregate_Fields>;
  nodes: Array<IndexerTag>;
};

/** aggregate fields of "tag" */
export type IndexerTag_Aggregate_Fields = {
  __typename?: 'tag_aggregate_fields';
  avg?: Maybe<IndexerTag_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerTag_Max_Fields>;
  min?: Maybe<IndexerTag_Min_Fields>;
  stddev?: Maybe<IndexerTag_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerTag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerTag_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerTag_Sum_Fields>;
  var_pop?: Maybe<IndexerTag_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerTag_Var_Samp_Fields>;
  variance?: Maybe<IndexerTag_Variance_Fields>;
};


/** aggregate fields of "tag" */
export type IndexerTag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerTag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerTag_Avg_Fields = {
  __typename?: 'tag_avg_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tag". All fields are combined with a logical 'AND'. */
export type IndexerTag_Bool_Exp = {
  _and?: Maybe<Array<IndexerTag_Bool_Exp>>;
  _not?: Maybe<IndexerTag_Bool_Exp>;
  _or?: Maybe<Array<IndexerTag_Bool_Exp>>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  token_count?: Maybe<IndexerBigint_Comparison_Exp>;
  tokens?: Maybe<IndexerToken_Tag_Bool_Exp>;
  tokens_aggregate?: Maybe<IndexerToken_Tag_Aggregate_Bool_Exp>;
};

/** aggregate max on columns */
export type IndexerTag_Max_Fields = {
  __typename?: 'tag_max_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  token_count?: Maybe<Scalars['bigint']>;
};

/** aggregate min on columns */
export type IndexerTag_Min_Fields = {
  __typename?: 'tag_min_fields';
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  token_count?: Maybe<Scalars['bigint']>;
};

/** Ordering options when selecting data from "tag". */
export type IndexerTag_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  token_count?: Maybe<IndexerOrder_By>;
  tokens_aggregate?: Maybe<IndexerToken_Tag_Aggregate_Order_By>;
};

/** select columns of table "tag" */
export enum IndexerTag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Name = 'name',
  /** column name */
  TokenCount = 'token_count'
}

/** aggregate stddev on columns */
export type IndexerTag_Stddev_Fields = {
  __typename?: 'tag_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerTag_Stddev_Pop_Fields = {
  __typename?: 'tag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerTag_Stddev_Samp_Fields = {
  __typename?: 'tag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tag" */
export type IndexerTag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerTag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerTag_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['bigint']>;
  name?: Maybe<Scalars['String']>;
  token_count?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerTag_Sum_Fields = {
  __typename?: 'tag_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  token_count?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerTag_Var_Pop_Fields = {
  __typename?: 'tag_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerTag_Var_Samp_Fields = {
  __typename?: 'tag_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerTag_Variance_Fields = {
  __typename?: 'tag_variance_fields';
  id?: Maybe<Scalars['Float']>;
  token_count?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "tezos_storage" */
export type IndexerTezos_Storage = {
  __typename?: 'tezos_storage';
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  id: Scalars['bigint'];
  tzip16_key?: Maybe<Scalars['String']>;
  tzip16_value?: Maybe<Scalars['String']>;
};

/** aggregated selection of "tezos_storage" */
export type IndexerTezos_Storage_Aggregate = {
  __typename?: 'tezos_storage_aggregate';
  aggregate?: Maybe<IndexerTezos_Storage_Aggregate_Fields>;
  nodes: Array<IndexerTezos_Storage>;
};

/** aggregate fields of "tezos_storage" */
export type IndexerTezos_Storage_Aggregate_Fields = {
  __typename?: 'tezos_storage_aggregate_fields';
  avg?: Maybe<IndexerTezos_Storage_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerTezos_Storage_Max_Fields>;
  min?: Maybe<IndexerTezos_Storage_Min_Fields>;
  stddev?: Maybe<IndexerTezos_Storage_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerTezos_Storage_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerTezos_Storage_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerTezos_Storage_Sum_Fields>;
  var_pop?: Maybe<IndexerTezos_Storage_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerTezos_Storage_Var_Samp_Fields>;
  variance?: Maybe<IndexerTezos_Storage_Variance_Fields>;
};


/** aggregate fields of "tezos_storage" */
export type IndexerTezos_Storage_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerTezos_Storage_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerTezos_Storage_Avg_Fields = {
  __typename?: 'tezos_storage_avg_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "tezos_storage". All fields are combined with a logical 'AND'. */
export type IndexerTezos_Storage_Bool_Exp = {
  _and?: Maybe<Array<IndexerTezos_Storage_Bool_Exp>>;
  _not?: Maybe<IndexerTezos_Storage_Bool_Exp>;
  _or?: Maybe<Array<IndexerTezos_Storage_Bool_Exp>>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  tzip16_key?: Maybe<IndexerString_Comparison_Exp>;
  tzip16_value?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerTezos_Storage_Max_Fields = {
  __typename?: 'tezos_storage_max_fields';
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tzip16_key?: Maybe<Scalars['String']>;
  tzip16_value?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IndexerTezos_Storage_Min_Fields = {
  __typename?: 'tezos_storage_min_fields';
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tzip16_key?: Maybe<Scalars['String']>;
  tzip16_value?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tezos_storage". */
export type IndexerTezos_Storage_Order_By = {
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
  tzip16_value?: Maybe<IndexerOrder_By>;
};

/** select columns of table "tezos_storage" */
export enum IndexerTezos_Storage_Select_Column {
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  Tzip16Key = 'tzip16_key',
  /** column name */
  Tzip16Value = 'tzip16_value'
}

/** aggregate stddev on columns */
export type IndexerTezos_Storage_Stddev_Fields = {
  __typename?: 'tezos_storage_stddev_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerTezos_Storage_Stddev_Pop_Fields = {
  __typename?: 'tezos_storage_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerTezos_Storage_Stddev_Samp_Fields = {
  __typename?: 'tezos_storage_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "tezos_storage" */
export type IndexerTezos_Storage_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerTezos_Storage_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerTezos_Storage_Stream_Cursor_Value_Input = {
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  tzip16_key?: Maybe<Scalars['String']>;
  tzip16_value?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerTezos_Storage_Sum_Fields = {
  __typename?: 'tezos_storage_sum_fields';
  id?: Maybe<Scalars['bigint']>;
};

/** aggregate var_pop on columns */
export type IndexerTezos_Storage_Var_Pop_Fields = {
  __typename?: 'tezos_storage_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerTezos_Storage_Var_Samp_Fields = {
  __typename?: 'tezos_storage_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerTezos_Storage_Variance_Fields = {
  __typename?: 'tezos_storage_variance_fields';
  id?: Maybe<Scalars['Float']>;
};


/** Boolean expression to compare columns of type "timestamp". All fields are combined with logical 'AND'. */
export type IndexerTimestamp_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamp']>;
  _gt?: Maybe<Scalars['timestamp']>;
  _gte?: Maybe<Scalars['timestamp']>;
  _in?: Maybe<Array<Scalars['timestamp']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamp']>;
  _lte?: Maybe<Scalars['timestamp']>;
  _neq?: Maybe<Scalars['timestamp']>;
  _nin?: Maybe<Array<Scalars['timestamp']>>;
};


/** Boolean expression to compare columns of type "timestamptz". All fields are combined with logical 'AND'. */
export type IndexerTimestamptz_Comparison_Exp = {
  _eq?: Maybe<Scalars['timestamptz']>;
  _gt?: Maybe<Scalars['timestamptz']>;
  _gte?: Maybe<Scalars['timestamptz']>;
  _in?: Maybe<Array<Scalars['timestamptz']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['timestamptz']>;
  _lte?: Maybe<Scalars['timestamptz']>;
  _neq?: Maybe<Scalars['timestamptz']>;
  _nin?: Maybe<Array<Scalars['timestamptz']>>;
};

/** columns and relationships of "token" */
export type IndexerToken = {
  __typename?: 'token';
  artifact_uri?: Maybe<Scalars['String']>;
  /** An array relationship */
  attributes: Array<IndexerToken_Attribute>;
  /** An aggregate relationship */
  attributes_aggregate: IndexerToken_Attribute_Aggregate;
  average?: Maybe<Scalars['bigint']>;
  content_rating?: Maybe<Scalars['String']>;
  /** An array relationship */
  creators: Array<IndexerToken_Creator>;
  /** An aggregate relationship */
  creators_aggregate: IndexerToken_Creator_Aggregate;
  decimals?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  display_uri?: Maybe<Scalars['String']>;
  /** An array relationship */
  dutch_auctions: Array<IndexerDutch_Auction>;
  /** An array relationship */
  dutch_auctions_active: Array<IndexerDutch_Auction_Active>;
  /** An aggregate relationship */
  dutch_auctions_active_aggregate: IndexerDutch_Auction_Active_Aggregate;
  /** An aggregate relationship */
  dutch_auctions_aggregate: IndexerDutch_Auction_Aggregate;
  /** An array relationship */
  english_auctions: Array<IndexerEnglish_Auction>;
  /** An array relationship */
  english_auctions_active: Array<IndexerEnglish_Auction_Active>;
  /** An aggregate relationship */
  english_auctions_active_aggregate: IndexerEnglish_Auction_Active_Aggregate;
  /** An aggregate relationship */
  english_auctions_aggregate: IndexerEnglish_Auction_Aggregate;
  /** An array relationship */
  events: Array<IndexerEvent>;
  /** An aggregate relationship */
  events_aggregate: IndexerEvent_Aggregate;
  extra?: Maybe<Scalars['jsonb']>;
  /** An object relationship */
  fa: IndexerFa;
  fa_contract: Scalars['String'];
  flag?: Maybe<Scalars['flag_type']>;
  galleries: Array<IndexerGallery_Token>;
  galleries_aggregate: IndexerGallery_Token_Aggregate;
  highest_offer?: Maybe<Scalars['bigint']>;
  /** An array relationship */
  holders: Array<IndexerToken_Holder>;
  /** An aggregate relationship */
  holders_aggregate: IndexerToken_Holder_Aggregate;
  is_boolean_amount?: Maybe<Scalars['Boolean']>;
  last_listed?: Maybe<Scalars['timestamptz']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  level?: Maybe<Scalars['Int']>;
  /** An array relationship */
  listing_sales: Array<IndexerListing_Sale>;
  /** An aggregate relationship */
  listing_sales_aggregate: IndexerListing_Sale_Aggregate;
  /** An array relationship */
  listings: Array<IndexerListing>;
  /** An array relationship */
  listings_active: Array<IndexerListing_Active>;
  /** An aggregate relationship */
  listings_active_aggregate: IndexerListing_Active_Aggregate;
  /** An aggregate relationship */
  listings_aggregate: IndexerListing_Aggregate;
  lowest_ask?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['String']>;
  metadata_status: Scalars['metadata_status'];
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  /** An array relationship */
  offers: Array<IndexerOffer>;
  /** An array relationship */
  offers_active: Array<IndexerOffer_Active>;
  /** An aggregate relationship */
  offers_active_aggregate: IndexerOffer_Active_Aggregate;
  /** An aggregate relationship */
  offers_aggregate: IndexerOffer_Aggregate;
  /** An object relationship */
  open_edition?: Maybe<IndexerOpen_Edition>;
  /** An object relationship */
  open_edition_active?: Maybe<IndexerOpen_Edition_Active>;
  /** An array relationship */
  operators: Array<IndexerToken_Operator>;
  /** An aggregate relationship */
  operators_aggregate: IndexerToken_Operator_Aggregate;
  ophash?: Maybe<Scalars['String']>;
  pk: Scalars['bigint'];
  rights?: Maybe<Scalars['String']>;
  /** An array relationship */
  royalties: Array<IndexerRoyalties>;
  /** An aggregate relationship */
  royalties_aggregate: IndexerRoyalties_Aggregate;
  supply?: Maybe<Scalars['bigint']>;
  symbol?: Maybe<Scalars['String']>;
  /** An array relationship */
  tags: Array<IndexerToken_Tag>;
  /** An aggregate relationship */
  tags_aggregate: IndexerToken_Tag_Aggregate;
  thumbnail_uri?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_id: Scalars['String'];
  tzip16_key?: Maybe<Scalars['String']>;
};


/** columns and relationships of "token" */
export type IndexerTokenAttributesArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenAttributes_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Attribute_Order_By>>;
  where?: Maybe<IndexerToken_Attribute_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenCreatorsArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenCreators_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Creator_Order_By>>;
  where?: Maybe<IndexerToken_Creator_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenDutch_AuctionsArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenDutch_Auctions_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenDutch_Auctions_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Active_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenDutch_Auctions_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerDutch_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerDutch_Auction_Order_By>>;
  where?: Maybe<IndexerDutch_Auction_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenEnglish_AuctionsArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenEnglish_Auctions_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenEnglish_Auctions_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Active_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenEnglish_Auctions_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEnglish_Auction_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEnglish_Auction_Order_By>>;
  where?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenEventsArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenEvents_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerEvent_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerEvent_Order_By>>;
  where?: Maybe<IndexerEvent_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenExtraArgs = {
  path?: Maybe<Scalars['String']>;
};


/** columns and relationships of "token" */
export type IndexerTokenGalleriesArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenGalleries_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerGallery_Token_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerGallery_Token_Order_By>>;
  where?: Maybe<IndexerGallery_Token_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenHoldersArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenHolders_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By>>;
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenListing_SalesArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenListing_Sales_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Sale_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Sale_Order_By>>;
  where?: Maybe<IndexerListing_Sale_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenListingsArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenListings_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenListings_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Active_Order_By>>;
  where?: Maybe<IndexerListing_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenListings_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerListing_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerListing_Order_By>>;
  where?: Maybe<IndexerListing_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenOffersArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenOffers_ActiveArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenOffers_Active_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Active_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Active_Order_By>>;
  where?: Maybe<IndexerOffer_Active_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenOffers_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerOffer_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerOffer_Order_By>>;
  where?: Maybe<IndexerOffer_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenOperatorsArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenOperators_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Operator_Order_By>>;
  where?: Maybe<IndexerToken_Operator_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenRoyaltiesArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenRoyalties_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerRoyalties_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerRoyalties_Order_By>>;
  where?: Maybe<IndexerRoyalties_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenTagsArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};


/** columns and relationships of "token" */
export type IndexerTokenTags_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerToken_Tag_Order_By>>;
  where?: Maybe<IndexerToken_Tag_Bool_Exp>;
};

/** aggregated selection of "token" */
export type IndexerToken_Aggregate = {
  __typename?: 'token_aggregate';
  aggregate?: Maybe<IndexerToken_Aggregate_Fields>;
  nodes: Array<IndexerToken>;
};

export type IndexerToken_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerToken_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerToken_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerToken_Aggregate_Bool_Exp_Count>;
};

export type IndexerToken_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerToken_Select_Column_Token_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerToken_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerToken_Select_Column_Token_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerToken_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "token" */
export type IndexerToken_Aggregate_Fields = {
  __typename?: 'token_aggregate_fields';
  avg?: Maybe<IndexerToken_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Max_Fields>;
  min?: Maybe<IndexerToken_Min_Fields>;
  stddev?: Maybe<IndexerToken_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Variance_Fields>;
};


/** aggregate fields of "token" */
export type IndexerToken_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "token" */
export type IndexerToken_Aggregate_Order_By = {
  avg?: Maybe<IndexerToken_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerToken_Max_Order_By>;
  min?: Maybe<IndexerToken_Min_Order_By>;
  stddev?: Maybe<IndexerToken_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerToken_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerToken_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerToken_Sum_Order_By>;
  var_pop?: Maybe<IndexerToken_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerToken_Var_Samp_Order_By>;
  variance?: Maybe<IndexerToken_Variance_Order_By>;
};

/** columns and relationships of "token_attribute" */
export type IndexerToken_Attribute = {
  __typename?: 'token_attribute';
  /** An object relationship */
  attribute: IndexerAttribute;
  attribute_id: Scalars['bigint'];
  id: Scalars['bigint'];
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
};

/** aggregated selection of "token_attribute" */
export type IndexerToken_Attribute_Aggregate = {
  __typename?: 'token_attribute_aggregate';
  aggregate?: Maybe<IndexerToken_Attribute_Aggregate_Fields>;
  nodes: Array<IndexerToken_Attribute>;
};

export type IndexerToken_Attribute_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerToken_Attribute_Aggregate_Bool_Exp_Count>;
};

export type IndexerToken_Attribute_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Attribute_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "token_attribute" */
export type IndexerToken_Attribute_Aggregate_Fields = {
  __typename?: 'token_attribute_aggregate_fields';
  avg?: Maybe<IndexerToken_Attribute_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Attribute_Max_Fields>;
  min?: Maybe<IndexerToken_Attribute_Min_Fields>;
  stddev?: Maybe<IndexerToken_Attribute_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Attribute_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Attribute_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Attribute_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Attribute_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Attribute_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Attribute_Variance_Fields>;
};


/** aggregate fields of "token_attribute" */
export type IndexerToken_Attribute_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Attribute_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "token_attribute" */
export type IndexerToken_Attribute_Aggregate_Order_By = {
  avg?: Maybe<IndexerToken_Attribute_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerToken_Attribute_Max_Order_By>;
  min?: Maybe<IndexerToken_Attribute_Min_Order_By>;
  stddev?: Maybe<IndexerToken_Attribute_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerToken_Attribute_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerToken_Attribute_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerToken_Attribute_Sum_Order_By>;
  var_pop?: Maybe<IndexerToken_Attribute_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerToken_Attribute_Var_Samp_Order_By>;
  variance?: Maybe<IndexerToken_Attribute_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerToken_Attribute_Avg_Fields = {
  __typename?: 'token_attribute_avg_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Avg_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "token_attribute". All fields are combined with a logical 'AND'. */
export type IndexerToken_Attribute_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Attribute_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Attribute_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Attribute_Bool_Exp>>;
  attribute?: Maybe<IndexerAttribute_Bool_Exp>;
  attribute_id?: Maybe<IndexerBigint_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Attribute_Max_Fields = {
  __typename?: 'token_attribute_max_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Max_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerToken_Attribute_Min_Fields = {
  __typename?: 'token_attribute_min_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Min_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "token_attribute". */
export type IndexerToken_Attribute_Order_By = {
  attribute?: Maybe<IndexerAttribute_Order_By>;
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_attribute" */
export enum IndexerToken_Attribute_Select_Column {
  /** column name */
  AttributeId = 'attribute_id',
  /** column name */
  Id = 'id',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerToken_Attribute_Stddev_Fields = {
  __typename?: 'token_attribute_stddev_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Stddev_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Attribute_Stddev_Pop_Fields = {
  __typename?: 'token_attribute_stddev_pop_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Stddev_Pop_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Attribute_Stddev_Samp_Fields = {
  __typename?: 'token_attribute_stddev_samp_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Stddev_Samp_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "token_attribute" */
export type IndexerToken_Attribute_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Attribute_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Attribute_Stream_Cursor_Value_Input = {
  attribute_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerToken_Attribute_Sum_Fields = {
  __typename?: 'token_attribute_sum_fields';
  attribute_id?: Maybe<Scalars['bigint']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Sum_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Attribute_Var_Pop_Fields = {
  __typename?: 'token_attribute_var_pop_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Var_Pop_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Attribute_Var_Samp_Fields = {
  __typename?: 'token_attribute_var_samp_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Var_Samp_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerToken_Attribute_Variance_Fields = {
  __typename?: 'token_attribute_variance_fields';
  attribute_id?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "token_attribute" */
export type IndexerToken_Attribute_Variance_Order_By = {
  attribute_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate avg on columns */
export type IndexerToken_Avg_Fields = {
  __typename?: 'token_avg_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "token" */
export type IndexerToken_Avg_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "token". All fields are combined with a logical 'AND'. */
export type IndexerToken_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Bool_Exp>>;
  artifact_uri?: Maybe<IndexerString_Comparison_Exp>;
  attributes?: Maybe<IndexerToken_Attribute_Bool_Exp>;
  attributes_aggregate?: Maybe<IndexerToken_Attribute_Aggregate_Bool_Exp>;
  average?: Maybe<IndexerBigint_Comparison_Exp>;
  content_rating?: Maybe<IndexerString_Comparison_Exp>;
  creators?: Maybe<IndexerToken_Creator_Bool_Exp>;
  creators_aggregate?: Maybe<IndexerToken_Creator_Aggregate_Bool_Exp>;
  decimals?: Maybe<IndexerInt_Comparison_Exp>;
  description?: Maybe<IndexerString_Comparison_Exp>;
  display_uri?: Maybe<IndexerString_Comparison_Exp>;
  dutch_auctions?: Maybe<IndexerDutch_Auction_Bool_Exp>;
  dutch_auctions_active?: Maybe<IndexerDutch_Auction_Active_Bool_Exp>;
  dutch_auctions_active_aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Bool_Exp>;
  dutch_auctions_aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Bool_Exp>;
  english_auctions?: Maybe<IndexerEnglish_Auction_Bool_Exp>;
  english_auctions_active?: Maybe<IndexerEnglish_Auction_Active_Bool_Exp>;
  english_auctions_active_aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Bool_Exp>;
  english_auctions_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Bool_Exp>;
  events?: Maybe<IndexerEvent_Bool_Exp>;
  events_aggregate?: Maybe<IndexerEvent_Aggregate_Bool_Exp>;
  extra?: Maybe<IndexerJsonb_Comparison_Exp>;
  fa?: Maybe<IndexerFa_Bool_Exp>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  flag?: Maybe<IndexerFlag_Type_Comparison_Exp>;
  highest_offer?: Maybe<IndexerBigint_Comparison_Exp>;
  holders?: Maybe<IndexerToken_Holder_Bool_Exp>;
  holders_aggregate?: Maybe<IndexerToken_Holder_Aggregate_Bool_Exp>;
  is_boolean_amount?: Maybe<IndexerBoolean_Comparison_Exp>;
  last_listed?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  last_metadata_update?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  level?: Maybe<IndexerInt_Comparison_Exp>;
  listing_sales?: Maybe<IndexerListing_Sale_Bool_Exp>;
  listing_sales_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Bool_Exp>;
  listings?: Maybe<IndexerListing_Bool_Exp>;
  listings_active?: Maybe<IndexerListing_Active_Bool_Exp>;
  listings_active_aggregate?: Maybe<IndexerListing_Active_Aggregate_Bool_Exp>;
  listings_aggregate?: Maybe<IndexerListing_Aggregate_Bool_Exp>;
  lowest_ask?: Maybe<IndexerBigint_Comparison_Exp>;
  metadata?: Maybe<IndexerString_Comparison_Exp>;
  metadata_status?: Maybe<IndexerMetadata_Status_Comparison_Exp>;
  mime?: Maybe<IndexerString_Comparison_Exp>;
  name?: Maybe<IndexerString_Comparison_Exp>;
  offers?: Maybe<IndexerOffer_Bool_Exp>;
  offers_active?: Maybe<IndexerOffer_Active_Bool_Exp>;
  offers_active_aggregate?: Maybe<IndexerOffer_Active_Aggregate_Bool_Exp>;
  offers_aggregate?: Maybe<IndexerOffer_Aggregate_Bool_Exp>;
  open_edition?: Maybe<IndexerOpen_Edition_Bool_Exp>;
  open_edition_active?: Maybe<IndexerOpen_Edition_Active_Bool_Exp>;
  operators?: Maybe<IndexerToken_Operator_Bool_Exp>;
  operators_aggregate?: Maybe<IndexerToken_Operator_Aggregate_Bool_Exp>;
  ophash?: Maybe<IndexerString_Comparison_Exp>;
  pk?: Maybe<IndexerBigint_Comparison_Exp>;
  rights?: Maybe<IndexerString_Comparison_Exp>;
  royalties?: Maybe<IndexerRoyalties_Bool_Exp>;
  royalties_aggregate?: Maybe<IndexerRoyalties_Aggregate_Bool_Exp>;
  supply?: Maybe<IndexerBigint_Comparison_Exp>;
  symbol?: Maybe<IndexerString_Comparison_Exp>;
  tags?: Maybe<IndexerToken_Tag_Bool_Exp>;
  tags_aggregate?: Maybe<IndexerToken_Tag_Aggregate_Bool_Exp>;
  thumbnail_uri?: Maybe<IndexerString_Comparison_Exp>;
  timestamp?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  token_id?: Maybe<IndexerString_Comparison_Exp>;
  tzip16_key?: Maybe<IndexerString_Comparison_Exp>;
};

/** columns and relationships of "token_creator" */
export type IndexerToken_Creator = {
  __typename?: 'token_creator';
  creator_address: Scalars['String'];
  /** An object relationship */
  holder: IndexerHolder;
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregated selection of "token_creator" */
export type IndexerToken_Creator_Aggregate = {
  __typename?: 'token_creator_aggregate';
  aggregate?: Maybe<IndexerToken_Creator_Aggregate_Fields>;
  nodes: Array<IndexerToken_Creator>;
};

export type IndexerToken_Creator_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerToken_Creator_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerToken_Creator_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerToken_Creator_Aggregate_Bool_Exp_Count>;
};

export type IndexerToken_Creator_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerToken_Creator_Select_Column_Token_Creator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Creator_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerToken_Creator_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerToken_Creator_Select_Column_Token_Creator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Creator_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerToken_Creator_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Creator_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "token_creator" */
export type IndexerToken_Creator_Aggregate_Fields = {
  __typename?: 'token_creator_aggregate_fields';
  avg?: Maybe<IndexerToken_Creator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Creator_Max_Fields>;
  min?: Maybe<IndexerToken_Creator_Min_Fields>;
  stddev?: Maybe<IndexerToken_Creator_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Creator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Creator_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Creator_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Creator_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Creator_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Creator_Variance_Fields>;
};


/** aggregate fields of "token_creator" */
export type IndexerToken_Creator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Creator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "token_creator" */
export type IndexerToken_Creator_Aggregate_Order_By = {
  avg?: Maybe<IndexerToken_Creator_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerToken_Creator_Max_Order_By>;
  min?: Maybe<IndexerToken_Creator_Min_Order_By>;
  stddev?: Maybe<IndexerToken_Creator_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerToken_Creator_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerToken_Creator_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerToken_Creator_Sum_Order_By>;
  var_pop?: Maybe<IndexerToken_Creator_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerToken_Creator_Var_Samp_Order_By>;
  variance?: Maybe<IndexerToken_Creator_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerToken_Creator_Avg_Fields = {
  __typename?: 'token_creator_avg_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "token_creator" */
export type IndexerToken_Creator_Avg_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "token_creator". All fields are combined with a logical 'AND'. */
export type IndexerToken_Creator_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Creator_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Creator_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Creator_Bool_Exp>>;
  creator_address?: Maybe<IndexerString_Comparison_Exp>;
  holder?: Maybe<IndexerHolder_Bool_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  verified?: Maybe<IndexerBoolean_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Creator_Max_Fields = {
  __typename?: 'token_creator_max_fields';
  creator_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "token_creator" */
export type IndexerToken_Creator_Max_Order_By = {
  creator_address?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerToken_Creator_Min_Fields = {
  __typename?: 'token_creator_min_fields';
  creator_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "token_creator" */
export type IndexerToken_Creator_Min_Order_By = {
  creator_address?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "token_creator". */
export type IndexerToken_Creator_Order_By = {
  creator_address?: Maybe<IndexerOrder_By>;
  holder?: Maybe<IndexerHolder_Order_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  verified?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_creator" */
export enum IndexerToken_Creator_Select_Column {
  /** column name */
  CreatorAddress = 'creator_address',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  Verified = 'verified'
}

/** select "token_creator_aggregate_bool_exp_bool_and_arguments_columns" columns of table "token_creator" */
export enum IndexerToken_Creator_Select_Column_Token_Creator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Verified = 'verified'
}

/** select "token_creator_aggregate_bool_exp_bool_or_arguments_columns" columns of table "token_creator" */
export enum IndexerToken_Creator_Select_Column_Token_Creator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Verified = 'verified'
}

/** aggregate stddev on columns */
export type IndexerToken_Creator_Stddev_Fields = {
  __typename?: 'token_creator_stddev_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "token_creator" */
export type IndexerToken_Creator_Stddev_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Creator_Stddev_Pop_Fields = {
  __typename?: 'token_creator_stddev_pop_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "token_creator" */
export type IndexerToken_Creator_Stddev_Pop_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Creator_Stddev_Samp_Fields = {
  __typename?: 'token_creator_stddev_samp_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "token_creator" */
export type IndexerToken_Creator_Stddev_Samp_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "token_creator" */
export type IndexerToken_Creator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Creator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Creator_Stream_Cursor_Value_Input = {
  creator_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
  verified?: Maybe<Scalars['Boolean']>;
};

/** aggregate sum on columns */
export type IndexerToken_Creator_Sum_Fields = {
  __typename?: 'token_creator_sum_fields';
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "token_creator" */
export type IndexerToken_Creator_Sum_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Creator_Var_Pop_Fields = {
  __typename?: 'token_creator_var_pop_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "token_creator" */
export type IndexerToken_Creator_Var_Pop_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Creator_Var_Samp_Fields = {
  __typename?: 'token_creator_var_samp_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "token_creator" */
export type IndexerToken_Creator_Var_Samp_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerToken_Creator_Variance_Fields = {
  __typename?: 'token_creator_variance_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "token_creator" */
export type IndexerToken_Creator_Variance_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "token_holder" */
export type IndexerToken_Holder = {
  __typename?: 'token_holder';
  /** An object relationship */
  holder: IndexerHolder;
  holder_address: Scalars['String'];
  last_incremented_at?: Maybe<Scalars['timestamptz']>;
  quantity: Scalars['numeric'];
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
};

/** aggregated selection of "token_holder" */
export type IndexerToken_Holder_Aggregate = {
  __typename?: 'token_holder_aggregate';
  aggregate?: Maybe<IndexerToken_Holder_Aggregate_Fields>;
  nodes: Array<IndexerToken_Holder>;
};

export type IndexerToken_Holder_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerToken_Holder_Aggregate_Bool_Exp_Count>;
};

export type IndexerToken_Holder_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Holder_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "token_holder" */
export type IndexerToken_Holder_Aggregate_Fields = {
  __typename?: 'token_holder_aggregate_fields';
  avg?: Maybe<IndexerToken_Holder_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Holder_Max_Fields>;
  min?: Maybe<IndexerToken_Holder_Min_Fields>;
  stddev?: Maybe<IndexerToken_Holder_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Holder_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Holder_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Holder_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Holder_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Holder_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Holder_Variance_Fields>;
};


/** aggregate fields of "token_holder" */
export type IndexerToken_Holder_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Holder_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "token_holder" */
export type IndexerToken_Holder_Aggregate_Order_By = {
  avg?: Maybe<IndexerToken_Holder_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerToken_Holder_Max_Order_By>;
  min?: Maybe<IndexerToken_Holder_Min_Order_By>;
  stddev?: Maybe<IndexerToken_Holder_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerToken_Holder_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerToken_Holder_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerToken_Holder_Sum_Order_By>;
  var_pop?: Maybe<IndexerToken_Holder_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerToken_Holder_Var_Samp_Order_By>;
  variance?: Maybe<IndexerToken_Holder_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerToken_Holder_Avg_Fields = {
  __typename?: 'token_holder_avg_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "token_holder" */
export type IndexerToken_Holder_Avg_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "token_holder". All fields are combined with a logical 'AND'. */
export type IndexerToken_Holder_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Holder_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Holder_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Holder_Bool_Exp>>;
  holder?: Maybe<IndexerHolder_Bool_Exp>;
  holder_address?: Maybe<IndexerString_Comparison_Exp>;
  last_incremented_at?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  quantity?: Maybe<IndexerNumeric_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Holder_Max_Fields = {
  __typename?: 'token_holder_max_fields';
  holder_address?: Maybe<Scalars['String']>;
  last_incremented_at?: Maybe<Scalars['timestamptz']>;
  quantity?: Maybe<Scalars['numeric']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "token_holder" */
export type IndexerToken_Holder_Max_Order_By = {
  holder_address?: Maybe<IndexerOrder_By>;
  last_incremented_at?: Maybe<IndexerOrder_By>;
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerToken_Holder_Min_Fields = {
  __typename?: 'token_holder_min_fields';
  holder_address?: Maybe<Scalars['String']>;
  last_incremented_at?: Maybe<Scalars['timestamptz']>;
  quantity?: Maybe<Scalars['numeric']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "token_holder" */
export type IndexerToken_Holder_Min_Order_By = {
  holder_address?: Maybe<IndexerOrder_By>;
  last_incremented_at?: Maybe<IndexerOrder_By>;
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "token_holder". */
export type IndexerToken_Holder_Order_By = {
  holder?: Maybe<IndexerHolder_Order_By>;
  holder_address?: Maybe<IndexerOrder_By>;
  last_incremented_at?: Maybe<IndexerOrder_By>;
  quantity?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_holder" */
export enum IndexerToken_Holder_Select_Column {
  /** column name */
  HolderAddress = 'holder_address',
  /** column name */
  LastIncrementedAt = 'last_incremented_at',
  /** column name */
  Quantity = 'quantity',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerToken_Holder_Stddev_Fields = {
  __typename?: 'token_holder_stddev_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "token_holder" */
export type IndexerToken_Holder_Stddev_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Holder_Stddev_Pop_Fields = {
  __typename?: 'token_holder_stddev_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "token_holder" */
export type IndexerToken_Holder_Stddev_Pop_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Holder_Stddev_Samp_Fields = {
  __typename?: 'token_holder_stddev_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "token_holder" */
export type IndexerToken_Holder_Stddev_Samp_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "token_holder" */
export type IndexerToken_Holder_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Holder_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Holder_Stream_Cursor_Value_Input = {
  holder_address?: Maybe<Scalars['String']>;
  last_incremented_at?: Maybe<Scalars['timestamptz']>;
  quantity?: Maybe<Scalars['numeric']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerToken_Holder_Sum_Fields = {
  __typename?: 'token_holder_sum_fields';
  quantity?: Maybe<Scalars['numeric']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "token_holder" */
export type IndexerToken_Holder_Sum_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Holder_Var_Pop_Fields = {
  __typename?: 'token_holder_var_pop_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "token_holder" */
export type IndexerToken_Holder_Var_Pop_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Holder_Var_Samp_Fields = {
  __typename?: 'token_holder_var_samp_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "token_holder" */
export type IndexerToken_Holder_Var_Samp_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerToken_Holder_Variance_Fields = {
  __typename?: 'token_holder_variance_fields';
  quantity?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "token_holder" */
export type IndexerToken_Holder_Variance_Order_By = {
  quantity?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate max on columns */
export type IndexerToken_Max_Fields = {
  __typename?: 'token_max_fields';
  artifact_uri?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['bigint']>;
  content_rating?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  display_uri?: Maybe<Scalars['String']>;
  fa_contract?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  highest_offer?: Maybe<Scalars['bigint']>;
  last_listed?: Maybe<Scalars['timestamptz']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  level?: Maybe<Scalars['Int']>;
  lowest_ask?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['String']>;
  metadata_status?: Maybe<Scalars['metadata_status']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['bigint']>;
  rights?: Maybe<Scalars['String']>;
  supply?: Maybe<Scalars['bigint']>;
  symbol?: Maybe<Scalars['String']>;
  thumbnail_uri?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_id?: Maybe<Scalars['String']>;
  tzip16_key?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "token" */
export type IndexerToken_Max_Order_By = {
  artifact_uri?: Maybe<IndexerOrder_By>;
  average?: Maybe<IndexerOrder_By>;
  content_rating?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  display_uri?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  flag?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  last_listed?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  metadata_status?: Maybe<IndexerOrder_By>;
  mime?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  rights?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
  symbol?: Maybe<IndexerOrder_By>;
  thumbnail_uri?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerToken_Min_Fields = {
  __typename?: 'token_min_fields';
  artifact_uri?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['bigint']>;
  content_rating?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  display_uri?: Maybe<Scalars['String']>;
  fa_contract?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  highest_offer?: Maybe<Scalars['bigint']>;
  last_listed?: Maybe<Scalars['timestamptz']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  level?: Maybe<Scalars['Int']>;
  lowest_ask?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['String']>;
  metadata_status?: Maybe<Scalars['metadata_status']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['bigint']>;
  rights?: Maybe<Scalars['String']>;
  supply?: Maybe<Scalars['bigint']>;
  symbol?: Maybe<Scalars['String']>;
  thumbnail_uri?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_id?: Maybe<Scalars['String']>;
  tzip16_key?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "token" */
export type IndexerToken_Min_Order_By = {
  artifact_uri?: Maybe<IndexerOrder_By>;
  average?: Maybe<IndexerOrder_By>;
  content_rating?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  display_uri?: Maybe<IndexerOrder_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  flag?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  last_listed?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  metadata_status?: Maybe<IndexerOrder_By>;
  mime?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  ophash?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  rights?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
  symbol?: Maybe<IndexerOrder_By>;
  thumbnail_uri?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "token_nsfw_override" */
export type IndexerToken_Nsfw_Override = {
  __typename?: 'token_nsfw_override';
  fa_contract: Scalars['String'];
  id: Scalars['bigint'];
  inserted_at: Scalars['timestamp'];
  metadata?: Maybe<Scalars['jsonb']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at: Scalars['timestamp'];
  user_id?: Maybe<Scalars['Int']>;
};


/** columns and relationships of "token_nsfw_override" */
export type IndexerToken_Nsfw_OverrideMetadataArgs = {
  path?: Maybe<Scalars['String']>;
};

/** aggregated selection of "token_nsfw_override" */
export type IndexerToken_Nsfw_Override_Aggregate = {
  __typename?: 'token_nsfw_override_aggregate';
  aggregate?: Maybe<IndexerToken_Nsfw_Override_Aggregate_Fields>;
  nodes: Array<IndexerToken_Nsfw_Override>;
};

/** aggregate fields of "token_nsfw_override" */
export type IndexerToken_Nsfw_Override_Aggregate_Fields = {
  __typename?: 'token_nsfw_override_aggregate_fields';
  avg?: Maybe<IndexerToken_Nsfw_Override_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Nsfw_Override_Max_Fields>;
  min?: Maybe<IndexerToken_Nsfw_Override_Min_Fields>;
  stddev?: Maybe<IndexerToken_Nsfw_Override_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Nsfw_Override_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Nsfw_Override_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Nsfw_Override_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Nsfw_Override_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Nsfw_Override_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Nsfw_Override_Variance_Fields>;
};


/** aggregate fields of "token_nsfw_override" */
export type IndexerToken_Nsfw_Override_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Nsfw_Override_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerToken_Nsfw_Override_Avg_Fields = {
  __typename?: 'token_nsfw_override_avg_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "token_nsfw_override". All fields are combined with a logical 'AND'. */
export type IndexerToken_Nsfw_Override_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Nsfw_Override_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Nsfw_Override_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Nsfw_Override_Bool_Exp>>;
  fa_contract?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  inserted_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  metadata?: Maybe<IndexerJsonb_Comparison_Exp>;
  token_id?: Maybe<IndexerString_Comparison_Exp>;
  updated_at?: Maybe<IndexerTimestamp_Comparison_Exp>;
  user_id?: Maybe<IndexerInt_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Nsfw_Override_Max_Fields = {
  __typename?: 'token_nsfw_override_max_fields';
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate min on columns */
export type IndexerToken_Nsfw_Override_Min_Fields = {
  __typename?: 'token_nsfw_override_min_fields';
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** Ordering options when selecting data from "token_nsfw_override". */
export type IndexerToken_Nsfw_Override_Order_By = {
  fa_contract?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  inserted_at?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
  updated_at?: Maybe<IndexerOrder_By>;
  user_id?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_nsfw_override" */
export enum IndexerToken_Nsfw_Override_Select_Column {
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Id = 'id',
  /** column name */
  InsertedAt = 'inserted_at',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  UpdatedAt = 'updated_at',
  /** column name */
  UserId = 'user_id'
}

/** aggregate stddev on columns */
export type IndexerToken_Nsfw_Override_Stddev_Fields = {
  __typename?: 'token_nsfw_override_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Nsfw_Override_Stddev_Pop_Fields = {
  __typename?: 'token_nsfw_override_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Nsfw_Override_Stddev_Samp_Fields = {
  __typename?: 'token_nsfw_override_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "token_nsfw_override" */
export type IndexerToken_Nsfw_Override_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Nsfw_Override_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Nsfw_Override_Stream_Cursor_Value_Input = {
  fa_contract?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['bigint']>;
  inserted_at?: Maybe<Scalars['timestamp']>;
  metadata?: Maybe<Scalars['jsonb']>;
  token_id?: Maybe<Scalars['String']>;
  updated_at?: Maybe<Scalars['timestamp']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate sum on columns */
export type IndexerToken_Nsfw_Override_Sum_Fields = {
  __typename?: 'token_nsfw_override_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  user_id?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Nsfw_Override_Var_Pop_Fields = {
  __typename?: 'token_nsfw_override_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Nsfw_Override_Var_Samp_Fields = {
  __typename?: 'token_nsfw_override_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerToken_Nsfw_Override_Variance_Fields = {
  __typename?: 'token_nsfw_override_variance_fields';
  id?: Maybe<Scalars['Float']>;
  user_id?: Maybe<Scalars['Float']>;
};

/** columns and relationships of "token_operator" */
export type IndexerToken_Operator = {
  __typename?: 'token_operator';
  allowed: Scalars['Boolean'];
  amount?: Maybe<Scalars['numeric']>;
  id: Scalars['bigint'];
  /** An object relationship */
  operator: IndexerHolder;
  operator_address: Scalars['String'];
  /** An object relationship */
  owner: IndexerHolder;
  owner_address: Scalars['String'];
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
};

/** aggregated selection of "token_operator" */
export type IndexerToken_Operator_Aggregate = {
  __typename?: 'token_operator_aggregate';
  aggregate?: Maybe<IndexerToken_Operator_Aggregate_Fields>;
  nodes: Array<IndexerToken_Operator>;
};

export type IndexerToken_Operator_Aggregate_Bool_Exp = {
  bool_and?: Maybe<IndexerToken_Operator_Aggregate_Bool_Exp_Bool_And>;
  bool_or?: Maybe<IndexerToken_Operator_Aggregate_Bool_Exp_Bool_Or>;
  count?: Maybe<IndexerToken_Operator_Aggregate_Bool_Exp_Count>;
};

export type IndexerToken_Operator_Aggregate_Bool_Exp_Bool_And = {
  arguments: IndexerToken_Operator_Select_Column_Token_Operator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Operator_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerToken_Operator_Aggregate_Bool_Exp_Bool_Or = {
  arguments: IndexerToken_Operator_Select_Column_Token_Operator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Operator_Bool_Exp>;
  predicate: IndexerBoolean_Comparison_Exp;
};

export type IndexerToken_Operator_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Operator_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "token_operator" */
export type IndexerToken_Operator_Aggregate_Fields = {
  __typename?: 'token_operator_aggregate_fields';
  avg?: Maybe<IndexerToken_Operator_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Operator_Max_Fields>;
  min?: Maybe<IndexerToken_Operator_Min_Fields>;
  stddev?: Maybe<IndexerToken_Operator_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Operator_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Operator_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Operator_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Operator_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Operator_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Operator_Variance_Fields>;
};


/** aggregate fields of "token_operator" */
export type IndexerToken_Operator_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Operator_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "token_operator" */
export type IndexerToken_Operator_Aggregate_Order_By = {
  avg?: Maybe<IndexerToken_Operator_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerToken_Operator_Max_Order_By>;
  min?: Maybe<IndexerToken_Operator_Min_Order_By>;
  stddev?: Maybe<IndexerToken_Operator_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerToken_Operator_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerToken_Operator_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerToken_Operator_Sum_Order_By>;
  var_pop?: Maybe<IndexerToken_Operator_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerToken_Operator_Var_Samp_Order_By>;
  variance?: Maybe<IndexerToken_Operator_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerToken_Operator_Avg_Fields = {
  __typename?: 'token_operator_avg_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "token_operator" */
export type IndexerToken_Operator_Avg_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "token_operator". All fields are combined with a logical 'AND'. */
export type IndexerToken_Operator_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Operator_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Operator_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Operator_Bool_Exp>>;
  allowed?: Maybe<IndexerBoolean_Comparison_Exp>;
  amount?: Maybe<IndexerNumeric_Comparison_Exp>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  operator?: Maybe<IndexerHolder_Bool_Exp>;
  operator_address?: Maybe<IndexerString_Comparison_Exp>;
  owner?: Maybe<IndexerHolder_Bool_Exp>;
  owner_address?: Maybe<IndexerString_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Operator_Max_Fields = {
  __typename?: 'token_operator_max_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  operator_address?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "token_operator" */
export type IndexerToken_Operator_Max_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  operator_address?: Maybe<IndexerOrder_By>;
  owner_address?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerToken_Operator_Min_Fields = {
  __typename?: 'token_operator_min_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  operator_address?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "token_operator" */
export type IndexerToken_Operator_Min_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  operator_address?: Maybe<IndexerOrder_By>;
  owner_address?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "token_operator". */
export type IndexerToken_Operator_Order_By = {
  allowed?: Maybe<IndexerOrder_By>;
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  operator?: Maybe<IndexerHolder_Order_By>;
  operator_address?: Maybe<IndexerOrder_By>;
  owner?: Maybe<IndexerHolder_Order_By>;
  owner_address?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_operator" */
export enum IndexerToken_Operator_Select_Column {
  /** column name */
  Allowed = 'allowed',
  /** column name */
  Amount = 'amount',
  /** column name */
  Id = 'id',
  /** column name */
  OperatorAddress = 'operator_address',
  /** column name */
  OwnerAddress = 'owner_address',
  /** column name */
  TokenPk = 'token_pk'
}

/** select "token_operator_aggregate_bool_exp_bool_and_arguments_columns" columns of table "token_operator" */
export enum IndexerToken_Operator_Select_Column_Token_Operator_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  Allowed = 'allowed'
}

/** select "token_operator_aggregate_bool_exp_bool_or_arguments_columns" columns of table "token_operator" */
export enum IndexerToken_Operator_Select_Column_Token_Operator_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  Allowed = 'allowed'
}

/** aggregate stddev on columns */
export type IndexerToken_Operator_Stddev_Fields = {
  __typename?: 'token_operator_stddev_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "token_operator" */
export type IndexerToken_Operator_Stddev_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Operator_Stddev_Pop_Fields = {
  __typename?: 'token_operator_stddev_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "token_operator" */
export type IndexerToken_Operator_Stddev_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Operator_Stddev_Samp_Fields = {
  __typename?: 'token_operator_stddev_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "token_operator" */
export type IndexerToken_Operator_Stddev_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "token_operator" */
export type IndexerToken_Operator_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Operator_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Operator_Stream_Cursor_Value_Input = {
  allowed?: Maybe<Scalars['Boolean']>;
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  operator_address?: Maybe<Scalars['String']>;
  owner_address?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerToken_Operator_Sum_Fields = {
  __typename?: 'token_operator_sum_fields';
  amount?: Maybe<Scalars['numeric']>;
  id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "token_operator" */
export type IndexerToken_Operator_Sum_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Operator_Var_Pop_Fields = {
  __typename?: 'token_operator_var_pop_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "token_operator" */
export type IndexerToken_Operator_Var_Pop_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Operator_Var_Samp_Fields = {
  __typename?: 'token_operator_var_samp_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "token_operator" */
export type IndexerToken_Operator_Var_Samp_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerToken_Operator_Variance_Fields = {
  __typename?: 'token_operator_variance_fields';
  amount?: Maybe<Scalars['Float']>;
  id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "token_operator" */
export type IndexerToken_Operator_Variance_Order_By = {
  amount?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "token". */
export type IndexerToken_Order_By_single = {
  artifact_uri?: Maybe<IndexerOrder_By>;
  attributes_aggregate?: Maybe<IndexerToken_Attribute_Aggregate_Order_By>;
  average?: Maybe<IndexerOrder_By>;
  content_rating?: Maybe<IndexerOrder_By>;
  creators_aggregate?: Maybe<IndexerToken_Creator_Aggregate_Order_By>;
  decimals?: Maybe<IndexerOrder_By>;
  description?: Maybe<IndexerOrder_By>;
  display_uri?: Maybe<IndexerOrder_By>;
  dutch_auctions_active_aggregate?: Maybe<IndexerDutch_Auction_Active_Aggregate_Order_By>;
  dutch_auctions_aggregate?: Maybe<IndexerDutch_Auction_Aggregate_Order_By>;
  english_auctions_active_aggregate?: Maybe<IndexerEnglish_Auction_Active_Aggregate_Order_By>;
  english_auctions_aggregate?: Maybe<IndexerEnglish_Auction_Aggregate_Order_By>;
  events_aggregate?: Maybe<IndexerEvent_Aggregate_Order_By>;
  extra?: Maybe<IndexerOrder_By>;
  fa?: Maybe<IndexerFa_Order_By>;
  fa_contract?: Maybe<IndexerOrder_By>;
  flag?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  holders_aggregate?: Maybe<IndexerToken_Holder_Aggregate_Order_By>;
  is_boolean_amount?: Maybe<IndexerOrder_By>;
  last_listed?: Maybe<IndexerOrder_By>;
  last_metadata_update?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  listing_sales_aggregate?: Maybe<IndexerListing_Sale_Aggregate_Order_By>;
  listings_active_aggregate?: Maybe<IndexerListing_Active_Aggregate_Order_By>;
  listings_aggregate?: Maybe<IndexerListing_Aggregate_Order_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  metadata?: Maybe<IndexerOrder_By>;
  metadata_status?: Maybe<IndexerOrder_By>;
  mime?: Maybe<IndexerOrder_By>;
  name?: Maybe<IndexerOrder_By>;
  offers_active_aggregate?: Maybe<IndexerOffer_Active_Aggregate_Order_By>;
  offers_aggregate?: Maybe<IndexerOffer_Aggregate_Order_By>;
  open_edition?: Maybe<IndexerOpen_Edition_Order_By>;
  open_edition_active?: Maybe<IndexerOpen_Edition_Active_Order_By>;
  operators_aggregate?: Maybe<IndexerToken_Operator_Aggregate_Order_By>;
  ophash?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  rights?: Maybe<IndexerOrder_By>;
  royalties_aggregate?: Maybe<IndexerRoyalties_Aggregate_Order_By>;
  supply?: Maybe<IndexerOrder_By>;
  symbol?: Maybe<IndexerOrder_By>;
  tags_aggregate?: Maybe<IndexerToken_Tag_Aggregate_Order_By>;
  thumbnail_uri?: Maybe<IndexerOrder_By>;
  timestamp?: Maybe<IndexerOrder_By>;
  token_id?: Maybe<IndexerOrder_By>;
  tzip16_key?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "token_registry" */
export type IndexerToken_Registry = {
  __typename?: 'token_registry';
  active?: Maybe<Scalars['Boolean']>;
  address: Scalars['String'];
  beneficiary?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['token_type']>;
};

/** aggregated selection of "token_registry" */
export type IndexerToken_Registry_Aggregate = {
  __typename?: 'token_registry_aggregate';
  aggregate?: Maybe<IndexerToken_Registry_Aggregate_Fields>;
  nodes: Array<IndexerToken_Registry>;
};

/** aggregate fields of "token_registry" */
export type IndexerToken_Registry_Aggregate_Fields = {
  __typename?: 'token_registry_aggregate_fields';
  avg?: Maybe<IndexerToken_Registry_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Registry_Max_Fields>;
  min?: Maybe<IndexerToken_Registry_Min_Fields>;
  stddev?: Maybe<IndexerToken_Registry_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Registry_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Registry_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Registry_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Registry_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Registry_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Registry_Variance_Fields>;
};


/** aggregate fields of "token_registry" */
export type IndexerToken_Registry_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Registry_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** aggregate avg on columns */
export type IndexerToken_Registry_Avg_Fields = {
  __typename?: 'token_registry_avg_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** Boolean expression to filter rows from the table "token_registry". All fields are combined with a logical 'AND'. */
export type IndexerToken_Registry_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Registry_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Registry_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Registry_Bool_Exp>>;
  active?: Maybe<IndexerBoolean_Comparison_Exp>;
  address?: Maybe<IndexerString_Comparison_Exp>;
  beneficiary?: Maybe<IndexerString_Comparison_Exp>;
  fee?: Maybe<IndexerInt_Comparison_Exp>;
  type?: Maybe<IndexerToken_Type_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Registry_Max_Fields = {
  __typename?: 'token_registry_max_fields';
  address?: Maybe<Scalars['String']>;
  beneficiary?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['token_type']>;
};

/** aggregate min on columns */
export type IndexerToken_Registry_Min_Fields = {
  __typename?: 'token_registry_min_fields';
  address?: Maybe<Scalars['String']>;
  beneficiary?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['token_type']>;
};

/** Ordering options when selecting data from "token_registry". */
export type IndexerToken_Registry_Order_By = {
  active?: Maybe<IndexerOrder_By>;
  address?: Maybe<IndexerOrder_By>;
  beneficiary?: Maybe<IndexerOrder_By>;
  fee?: Maybe<IndexerOrder_By>;
  type?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_registry" */
export enum IndexerToken_Registry_Select_Column {
  /** column name */
  Active = 'active',
  /** column name */
  Address = 'address',
  /** column name */
  Beneficiary = 'beneficiary',
  /** column name */
  Fee = 'fee',
  /** column name */
  Type = 'type'
}

/** aggregate stddev on columns */
export type IndexerToken_Registry_Stddev_Fields = {
  __typename?: 'token_registry_stddev_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Registry_Stddev_Pop_Fields = {
  __typename?: 'token_registry_stddev_pop_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Registry_Stddev_Samp_Fields = {
  __typename?: 'token_registry_stddev_samp_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** Streaming cursor of the table "token_registry" */
export type IndexerToken_Registry_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Registry_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Registry_Stream_Cursor_Value_Input = {
  active?: Maybe<Scalars['Boolean']>;
  address?: Maybe<Scalars['String']>;
  beneficiary?: Maybe<Scalars['String']>;
  fee?: Maybe<Scalars['Int']>;
  type?: Maybe<Scalars['token_type']>;
};

/** aggregate sum on columns */
export type IndexerToken_Registry_Sum_Fields = {
  __typename?: 'token_registry_sum_fields';
  fee?: Maybe<Scalars['Int']>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Registry_Var_Pop_Fields = {
  __typename?: 'token_registry_var_pop_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Registry_Var_Samp_Fields = {
  __typename?: 'token_registry_var_samp_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** aggregate variance on columns */
export type IndexerToken_Registry_Variance_Fields = {
  __typename?: 'token_registry_variance_fields';
  fee?: Maybe<Scalars['Float']>;
};

/** select columns of table "token" */
export enum IndexerToken_Select_Column {
  /** column name */
  ArtifactUri = 'artifact_uri',
  /** column name */
  Average = 'average',
  /** column name */
  ContentRating = 'content_rating',
  /** column name */
  Decimals = 'decimals',
  /** column name */
  Description = 'description',
  /** column name */
  DisplayUri = 'display_uri',
  /** column name */
  Extra = 'extra',
  /** column name */
  FaContract = 'fa_contract',
  /** column name */
  Flag = 'flag',
  /** column name */
  HighestOffer = 'highest_offer',
  /** column name */
  IsBooleanAmount = 'is_boolean_amount',
  /** column name */
  LastListed = 'last_listed',
  /** column name */
  LastMetadataUpdate = 'last_metadata_update',
  /** column name */
  Level = 'level',
  /** column name */
  LowestAsk = 'lowest_ask',
  /** column name */
  Metadata = 'metadata',
  /** column name */
  MetadataStatus = 'metadata_status',
  /** column name */
  Mime = 'mime',
  /** column name */
  Name = 'name',
  /** column name */
  Ophash = 'ophash',
  /** column name */
  Pk = 'pk',
  /** column name */
  Rights = 'rights',
  /** column name */
  Supply = 'supply',
  /** column name */
  Symbol = 'symbol',
  /** column name */
  ThumbnailUri = 'thumbnail_uri',
  /** column name */
  Timestamp = 'timestamp',
  /** column name */
  TokenId = 'token_id',
  /** column name */
  Tzip16Key = 'tzip16_key'
}

/** select "token_aggregate_bool_exp_bool_and_arguments_columns" columns of table "token" */
export enum IndexerToken_Select_Column_Token_Aggregate_Bool_Exp_Bool_And_Arguments_Columns {
  /** column name */
  IsBooleanAmount = 'is_boolean_amount'
}

/** select "token_aggregate_bool_exp_bool_or_arguments_columns" columns of table "token" */
export enum IndexerToken_Select_Column_Token_Aggregate_Bool_Exp_Bool_Or_Arguments_Columns {
  /** column name */
  IsBooleanAmount = 'is_boolean_amount'
}

/** aggregate stddev on columns */
export type IndexerToken_Stddev_Fields = {
  __typename?: 'token_stddev_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "token" */
export type IndexerToken_Stddev_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Stddev_Pop_Fields = {
  __typename?: 'token_stddev_pop_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "token" */
export type IndexerToken_Stddev_Pop_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Stddev_Samp_Fields = {
  __typename?: 'token_stddev_samp_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "token" */
export type IndexerToken_Stddev_Samp_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "token" */
export type IndexerToken_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Stream_Cursor_Value_Input = {
  artifact_uri?: Maybe<Scalars['String']>;
  average?: Maybe<Scalars['bigint']>;
  content_rating?: Maybe<Scalars['String']>;
  decimals?: Maybe<Scalars['Int']>;
  description?: Maybe<Scalars['String']>;
  display_uri?: Maybe<Scalars['String']>;
  extra?: Maybe<Scalars['jsonb']>;
  fa_contract?: Maybe<Scalars['String']>;
  flag?: Maybe<Scalars['flag_type']>;
  highest_offer?: Maybe<Scalars['bigint']>;
  is_boolean_amount?: Maybe<Scalars['Boolean']>;
  last_listed?: Maybe<Scalars['timestamptz']>;
  last_metadata_update?: Maybe<Scalars['timestamptz']>;
  level?: Maybe<Scalars['Int']>;
  lowest_ask?: Maybe<Scalars['bigint']>;
  metadata?: Maybe<Scalars['String']>;
  metadata_status?: Maybe<Scalars['metadata_status']>;
  mime?: Maybe<Scalars['String']>;
  name?: Maybe<Scalars['String']>;
  ophash?: Maybe<Scalars['String']>;
  pk?: Maybe<Scalars['bigint']>;
  rights?: Maybe<Scalars['String']>;
  supply?: Maybe<Scalars['bigint']>;
  symbol?: Maybe<Scalars['String']>;
  thumbnail_uri?: Maybe<Scalars['String']>;
  timestamp?: Maybe<Scalars['timestamptz']>;
  token_id?: Maybe<Scalars['String']>;
  tzip16_key?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerToken_Sum_Fields = {
  __typename?: 'token_sum_fields';
  average?: Maybe<Scalars['bigint']>;
  decimals?: Maybe<Scalars['Int']>;
  highest_offer?: Maybe<Scalars['bigint']>;
  level?: Maybe<Scalars['Int']>;
  lowest_ask?: Maybe<Scalars['bigint']>;
  pk?: Maybe<Scalars['bigint']>;
  supply?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "token" */
export type IndexerToken_Sum_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "token_tag" */
export type IndexerToken_Tag = {
  __typename?: 'token_tag';
  id: Scalars['bigint'];
  /** An object relationship */
  tag: IndexerTag;
  tag_id: Scalars['bigint'];
  /** An object relationship */
  token: IndexerToken;
  token_pk: Scalars['bigint'];
};

/** aggregated selection of "token_tag" */
export type IndexerToken_Tag_Aggregate = {
  __typename?: 'token_tag_aggregate';
  aggregate?: Maybe<IndexerToken_Tag_Aggregate_Fields>;
  nodes: Array<IndexerToken_Tag>;
};

export type IndexerToken_Tag_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerToken_Tag_Aggregate_Bool_Exp_Count>;
};

export type IndexerToken_Tag_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerToken_Tag_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "token_tag" */
export type IndexerToken_Tag_Aggregate_Fields = {
  __typename?: 'token_tag_aggregate_fields';
  avg?: Maybe<IndexerToken_Tag_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerToken_Tag_Max_Fields>;
  min?: Maybe<IndexerToken_Tag_Min_Fields>;
  stddev?: Maybe<IndexerToken_Tag_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerToken_Tag_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerToken_Tag_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerToken_Tag_Sum_Fields>;
  var_pop?: Maybe<IndexerToken_Tag_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerToken_Tag_Var_Samp_Fields>;
  variance?: Maybe<IndexerToken_Tag_Variance_Fields>;
};


/** aggregate fields of "token_tag" */
export type IndexerToken_Tag_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerToken_Tag_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "token_tag" */
export type IndexerToken_Tag_Aggregate_Order_By = {
  avg?: Maybe<IndexerToken_Tag_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerToken_Tag_Max_Order_By>;
  min?: Maybe<IndexerToken_Tag_Min_Order_By>;
  stddev?: Maybe<IndexerToken_Tag_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerToken_Tag_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerToken_Tag_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerToken_Tag_Sum_Order_By>;
  var_pop?: Maybe<IndexerToken_Tag_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerToken_Tag_Var_Samp_Order_By>;
  variance?: Maybe<IndexerToken_Tag_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerToken_Tag_Avg_Fields = {
  __typename?: 'token_tag_avg_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "token_tag" */
export type IndexerToken_Tag_Avg_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "token_tag". All fields are combined with a logical 'AND'. */
export type IndexerToken_Tag_Bool_Exp = {
  _and?: Maybe<Array<IndexerToken_Tag_Bool_Exp>>;
  _not?: Maybe<IndexerToken_Tag_Bool_Exp>;
  _or?: Maybe<Array<IndexerToken_Tag_Bool_Exp>>;
  id?: Maybe<IndexerBigint_Comparison_Exp>;
  tag?: Maybe<IndexerTag_Bool_Exp>;
  tag_id?: Maybe<IndexerBigint_Comparison_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerToken_Tag_Max_Fields = {
  __typename?: 'token_tag_max_fields';
  id?: Maybe<Scalars['bigint']>;
  tag_id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by max() on columns of table "token_tag" */
export type IndexerToken_Tag_Max_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerToken_Tag_Min_Fields = {
  __typename?: 'token_tag_min_fields';
  id?: Maybe<Scalars['bigint']>;
  tag_id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by min() on columns of table "token_tag" */
export type IndexerToken_Tag_Min_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "token_tag". */
export type IndexerToken_Tag_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag?: Maybe<IndexerTag_Order_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** select columns of table "token_tag" */
export enum IndexerToken_Tag_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  TagId = 'tag_id',
  /** column name */
  TokenPk = 'token_pk'
}

/** aggregate stddev on columns */
export type IndexerToken_Tag_Stddev_Fields = {
  __typename?: 'token_tag_stddev_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "token_tag" */
export type IndexerToken_Tag_Stddev_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerToken_Tag_Stddev_Pop_Fields = {
  __typename?: 'token_tag_stddev_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "token_tag" */
export type IndexerToken_Tag_Stddev_Pop_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerToken_Tag_Stddev_Samp_Fields = {
  __typename?: 'token_tag_stddev_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "token_tag" */
export type IndexerToken_Tag_Stddev_Samp_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "token_tag" */
export type IndexerToken_Tag_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerToken_Tag_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerToken_Tag_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['bigint']>;
  tag_id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** aggregate sum on columns */
export type IndexerToken_Tag_Sum_Fields = {
  __typename?: 'token_tag_sum_fields';
  id?: Maybe<Scalars['bigint']>;
  tag_id?: Maybe<Scalars['bigint']>;
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "token_tag" */
export type IndexerToken_Tag_Sum_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Tag_Var_Pop_Fields = {
  __typename?: 'token_tag_var_pop_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "token_tag" */
export type IndexerToken_Tag_Var_Pop_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Tag_Var_Samp_Fields = {
  __typename?: 'token_tag_var_samp_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "token_tag" */
export type IndexerToken_Tag_Var_Samp_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerToken_Tag_Variance_Fields = {
  __typename?: 'token_tag_variance_fields';
  id?: Maybe<Scalars['Float']>;
  tag_id?: Maybe<Scalars['Float']>;
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "token_tag" */
export type IndexerToken_Tag_Variance_Order_By = {
  id?: Maybe<IndexerOrder_By>;
  tag_id?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
};


/** Boolean expression to compare columns of type "token_type". All fields are combined with logical 'AND'. */
export type IndexerToken_Type_Comparison_Exp = {
  _eq?: Maybe<Scalars['token_type']>;
  _gt?: Maybe<Scalars['token_type']>;
  _gte?: Maybe<Scalars['token_type']>;
  _in?: Maybe<Array<Scalars['token_type']>>;
  _is_null?: Maybe<Scalars['Boolean']>;
  _lt?: Maybe<Scalars['token_type']>;
  _lte?: Maybe<Scalars['token_type']>;
  _neq?: Maybe<Scalars['token_type']>;
  _nin?: Maybe<Array<Scalars['token_type']>>;
};

/** aggregate var_pop on columns */
export type IndexerToken_Var_Pop_Fields = {
  __typename?: 'token_var_pop_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "token" */
export type IndexerToken_Var_Pop_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerToken_Var_Samp_Fields = {
  __typename?: 'token_var_samp_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "token" */
export type IndexerToken_Var_Samp_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerToken_Variance_Fields = {
  __typename?: 'token_variance_fields';
  average?: Maybe<Scalars['Float']>;
  decimals?: Maybe<Scalars['Float']>;
  highest_offer?: Maybe<Scalars['Float']>;
  level?: Maybe<Scalars['Float']>;
  lowest_ask?: Maybe<Scalars['Float']>;
  pk?: Maybe<Scalars['Float']>;
  supply?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "token" */
export type IndexerToken_Variance_Order_By = {
  average?: Maybe<IndexerOrder_By>;
  decimals?: Maybe<IndexerOrder_By>;
  highest_offer?: Maybe<IndexerOrder_By>;
  level?: Maybe<IndexerOrder_By>;
  lowest_ask?: Maybe<IndexerOrder_By>;
  pk?: Maybe<IndexerOrder_By>;
  supply?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "tzd_domain" */
export type IndexerTzd_Domain = {
  __typename?: 'tzd_domain';
  expiry?: Maybe<Scalars['timestamptz']>;
  id: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
  /** An array relationship */
  records: Array<IndexerTzd_Record>;
  /** An aggregate relationship */
  records_aggregate: IndexerTzd_Record_Aggregate;
  /** An object relationship */
  token?: Maybe<IndexerToken>;
  token_pk?: Maybe<Scalars['bigint']>;
  /** An object relationship */
  tzd_tld: IndexerTzd_Tld;
  tzd_tld_id: Scalars['String'];
};


/** columns and relationships of "tzd_domain" */
export type IndexerTzd_DomainRecordsArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Record_Order_By>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};


/** columns and relationships of "tzd_domain" */
export type IndexerTzd_DomainRecords_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Record_Order_By>>;
  where?: Maybe<IndexerTzd_Record_Bool_Exp>;
};

/** aggregated selection of "tzd_domain" */
export type IndexerTzd_Domain_Aggregate = {
  __typename?: 'tzd_domain_aggregate';
  aggregate?: Maybe<IndexerTzd_Domain_Aggregate_Fields>;
  nodes: Array<IndexerTzd_Domain>;
};

export type IndexerTzd_Domain_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerTzd_Domain_Aggregate_Bool_Exp_Count>;
};

export type IndexerTzd_Domain_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerTzd_Domain_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "tzd_domain" */
export type IndexerTzd_Domain_Aggregate_Fields = {
  __typename?: 'tzd_domain_aggregate_fields';
  avg?: Maybe<IndexerTzd_Domain_Avg_Fields>;
  count: Scalars['Int'];
  max?: Maybe<IndexerTzd_Domain_Max_Fields>;
  min?: Maybe<IndexerTzd_Domain_Min_Fields>;
  stddev?: Maybe<IndexerTzd_Domain_Stddev_Fields>;
  stddev_pop?: Maybe<IndexerTzd_Domain_Stddev_Pop_Fields>;
  stddev_samp?: Maybe<IndexerTzd_Domain_Stddev_Samp_Fields>;
  sum?: Maybe<IndexerTzd_Domain_Sum_Fields>;
  var_pop?: Maybe<IndexerTzd_Domain_Var_Pop_Fields>;
  var_samp?: Maybe<IndexerTzd_Domain_Var_Samp_Fields>;
  variance?: Maybe<IndexerTzd_Domain_Variance_Fields>;
};


/** aggregate fields of "tzd_domain" */
export type IndexerTzd_Domain_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tzd_domain" */
export type IndexerTzd_Domain_Aggregate_Order_By = {
  avg?: Maybe<IndexerTzd_Domain_Avg_Order_By>;
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerTzd_Domain_Max_Order_By>;
  min?: Maybe<IndexerTzd_Domain_Min_Order_By>;
  stddev?: Maybe<IndexerTzd_Domain_Stddev_Order_By>;
  stddev_pop?: Maybe<IndexerTzd_Domain_Stddev_Pop_Order_By>;
  stddev_samp?: Maybe<IndexerTzd_Domain_Stddev_Samp_Order_By>;
  sum?: Maybe<IndexerTzd_Domain_Sum_Order_By>;
  var_pop?: Maybe<IndexerTzd_Domain_Var_Pop_Order_By>;
  var_samp?: Maybe<IndexerTzd_Domain_Var_Samp_Order_By>;
  variance?: Maybe<IndexerTzd_Domain_Variance_Order_By>;
};

/** aggregate avg on columns */
export type IndexerTzd_Domain_Avg_Fields = {
  __typename?: 'tzd_domain_avg_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by avg() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Avg_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Boolean expression to filter rows from the table "tzd_domain". All fields are combined with a logical 'AND'. */
export type IndexerTzd_Domain_Bool_Exp = {
  _and?: Maybe<Array<IndexerTzd_Domain_Bool_Exp>>;
  _not?: Maybe<IndexerTzd_Domain_Bool_Exp>;
  _or?: Maybe<Array<IndexerTzd_Domain_Bool_Exp>>;
  expiry?: Maybe<IndexerTimestamptz_Comparison_Exp>;
  id?: Maybe<IndexerString_Comparison_Exp>;
  owner?: Maybe<IndexerString_Comparison_Exp>;
  records?: Maybe<IndexerTzd_Record_Bool_Exp>;
  records_aggregate?: Maybe<IndexerTzd_Record_Aggregate_Bool_Exp>;
  token?: Maybe<IndexerToken_Bool_Exp>;
  token_pk?: Maybe<IndexerBigint_Comparison_Exp>;
  tzd_tld?: Maybe<IndexerTzd_Tld_Bool_Exp>;
  tzd_tld_id?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerTzd_Domain_Max_Fields = {
  __typename?: 'tzd_domain_max_fields';
  expiry?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
  tzd_tld_id?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Max_Order_By = {
  expiry?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  owner?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  tzd_tld_id?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerTzd_Domain_Min_Fields = {
  __typename?: 'tzd_domain_min_fields';
  expiry?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
  tzd_tld_id?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Min_Order_By = {
  expiry?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  owner?: Maybe<IndexerOrder_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  tzd_tld_id?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "tzd_domain". */
export type IndexerTzd_Domain_Order_By = {
  expiry?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  owner?: Maybe<IndexerOrder_By>;
  records_aggregate?: Maybe<IndexerTzd_Record_Aggregate_Order_By>;
  token?: Maybe<IndexerToken_Order_By>;
  token_pk?: Maybe<IndexerOrder_By>;
  tzd_tld?: Maybe<IndexerTzd_Tld_Order_By>;
  tzd_tld_id?: Maybe<IndexerOrder_By>;
};

/** select columns of table "tzd_domain" */
export enum IndexerTzd_Domain_Select_Column {
  /** column name */
  Expiry = 'expiry',
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner',
  /** column name */
  TokenPk = 'token_pk',
  /** column name */
  TzdTldId = 'tzd_tld_id'
}

/** aggregate stddev on columns */
export type IndexerTzd_Domain_Stddev_Fields = {
  __typename?: 'tzd_domain_stddev_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Stddev_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_pop on columns */
export type IndexerTzd_Domain_Stddev_Pop_Fields = {
  __typename?: 'tzd_domain_stddev_pop_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_pop() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Stddev_Pop_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate stddev_samp on columns */
export type IndexerTzd_Domain_Stddev_Samp_Fields = {
  __typename?: 'tzd_domain_stddev_samp_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by stddev_samp() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Stddev_Samp_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** Streaming cursor of the table "tzd_domain" */
export type IndexerTzd_Domain_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerTzd_Domain_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerTzd_Domain_Stream_Cursor_Value_Input = {
  expiry?: Maybe<Scalars['timestamptz']>;
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
  token_pk?: Maybe<Scalars['bigint']>;
  tzd_tld_id?: Maybe<Scalars['String']>;
};

/** aggregate sum on columns */
export type IndexerTzd_Domain_Sum_Fields = {
  __typename?: 'tzd_domain_sum_fields';
  token_pk?: Maybe<Scalars['bigint']>;
};

/** order by sum() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Sum_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_pop on columns */
export type IndexerTzd_Domain_Var_Pop_Fields = {
  __typename?: 'tzd_domain_var_pop_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_pop() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Var_Pop_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate var_samp on columns */
export type IndexerTzd_Domain_Var_Samp_Fields = {
  __typename?: 'tzd_domain_var_samp_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by var_samp() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Var_Samp_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** aggregate variance on columns */
export type IndexerTzd_Domain_Variance_Fields = {
  __typename?: 'tzd_domain_variance_fields';
  token_pk?: Maybe<Scalars['Float']>;
};

/** order by variance() on columns of table "tzd_domain" */
export type IndexerTzd_Domain_Variance_Order_By = {
  token_pk?: Maybe<IndexerOrder_By>;
};

/** columns and relationships of "tzd_record" */
export type IndexerTzd_Record = {
  __typename?: 'tzd_record';
  /** An object relationship */
  domain: IndexerTzd_Domain;
  domain_id: Scalars['String'];
  id: Scalars['String'];
  /** An object relationship */
  target: IndexerHolder;
  target_address: Scalars['String'];
};

/** aggregated selection of "tzd_record" */
export type IndexerTzd_Record_Aggregate = {
  __typename?: 'tzd_record_aggregate';
  aggregate?: Maybe<IndexerTzd_Record_Aggregate_Fields>;
  nodes: Array<IndexerTzd_Record>;
};

export type IndexerTzd_Record_Aggregate_Bool_Exp = {
  count?: Maybe<IndexerTzd_Record_Aggregate_Bool_Exp_Count>;
};

export type IndexerTzd_Record_Aggregate_Bool_Exp_Count = {
  arguments?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
  filter?: Maybe<IndexerTzd_Record_Bool_Exp>;
  predicate: IndexerInt_Comparison_Exp;
};

/** aggregate fields of "tzd_record" */
export type IndexerTzd_Record_Aggregate_Fields = {
  __typename?: 'tzd_record_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IndexerTzd_Record_Max_Fields>;
  min?: Maybe<IndexerTzd_Record_Min_Fields>;
};


/** aggregate fields of "tzd_record" */
export type IndexerTzd_Record_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerTzd_Record_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** order by aggregate values of table "tzd_record" */
export type IndexerTzd_Record_Aggregate_Order_By = {
  count?: Maybe<IndexerOrder_By>;
  max?: Maybe<IndexerTzd_Record_Max_Order_By>;
  min?: Maybe<IndexerTzd_Record_Min_Order_By>;
};

/** Boolean expression to filter rows from the table "tzd_record". All fields are combined with a logical 'AND'. */
export type IndexerTzd_Record_Bool_Exp = {
  _and?: Maybe<Array<IndexerTzd_Record_Bool_Exp>>;
  _not?: Maybe<IndexerTzd_Record_Bool_Exp>;
  _or?: Maybe<Array<IndexerTzd_Record_Bool_Exp>>;
  domain?: Maybe<IndexerTzd_Domain_Bool_Exp>;
  domain_id?: Maybe<IndexerString_Comparison_Exp>;
  id?: Maybe<IndexerString_Comparison_Exp>;
  target?: Maybe<IndexerHolder_Bool_Exp>;
  target_address?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerTzd_Record_Max_Fields = {
  __typename?: 'tzd_record_max_fields';
  domain_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  target_address?: Maybe<Scalars['String']>;
};

/** order by max() on columns of table "tzd_record" */
export type IndexerTzd_Record_Max_Order_By = {
  domain_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
};

/** aggregate min on columns */
export type IndexerTzd_Record_Min_Fields = {
  __typename?: 'tzd_record_min_fields';
  domain_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  target_address?: Maybe<Scalars['String']>;
};

/** order by min() on columns of table "tzd_record" */
export type IndexerTzd_Record_Min_Order_By = {
  domain_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  target_address?: Maybe<IndexerOrder_By>;
};

/** Ordering options when selecting data from "tzd_record". */
export type IndexerTzd_Record_Order_By = {
  domain?: Maybe<IndexerTzd_Domain_Order_By>;
  domain_id?: Maybe<IndexerOrder_By>;
  id?: Maybe<IndexerOrder_By>;
  target?: Maybe<IndexerHolder_Order_By>;
  target_address?: Maybe<IndexerOrder_By>;
};

/** select columns of table "tzd_record" */
export enum IndexerTzd_Record_Select_Column {
  /** column name */
  DomainId = 'domain_id',
  /** column name */
  Id = 'id',
  /** column name */
  TargetAddress = 'target_address'
}

/** Streaming cursor of the table "tzd_record" */
export type IndexerTzd_Record_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerTzd_Record_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerTzd_Record_Stream_Cursor_Value_Input = {
  domain_id?: Maybe<Scalars['String']>;
  id?: Maybe<Scalars['String']>;
  target_address?: Maybe<Scalars['String']>;
};

/** columns and relationships of "tzd_tld" */
export type IndexerTzd_Tld = {
  __typename?: 'tzd_tld';
  /** An array relationship */
  domains: Array<IndexerTzd_Domain>;
  /** An aggregate relationship */
  domains_aggregate: IndexerTzd_Domain_Aggregate;
  id: Scalars['String'];
  owner?: Maybe<Scalars['String']>;
};


/** columns and relationships of "tzd_tld" */
export type IndexerTzd_TldDomainsArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Domain_Order_By>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};


/** columns and relationships of "tzd_tld" */
export type IndexerTzd_TldDomains_AggregateArgs = {
  distinct_on?: Maybe<Array<IndexerTzd_Domain_Select_Column>>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  order_by?: Maybe<Array<IndexerTzd_Domain_Order_By>>;
  where?: Maybe<IndexerTzd_Domain_Bool_Exp>;
};

/** aggregated selection of "tzd_tld" */
export type IndexerTzd_Tld_Aggregate = {
  __typename?: 'tzd_tld_aggregate';
  aggregate?: Maybe<IndexerTzd_Tld_Aggregate_Fields>;
  nodes: Array<IndexerTzd_Tld>;
};

/** aggregate fields of "tzd_tld" */
export type IndexerTzd_Tld_Aggregate_Fields = {
  __typename?: 'tzd_tld_aggregate_fields';
  count: Scalars['Int'];
  max?: Maybe<IndexerTzd_Tld_Max_Fields>;
  min?: Maybe<IndexerTzd_Tld_Min_Fields>;
};


/** aggregate fields of "tzd_tld" */
export type IndexerTzd_Tld_Aggregate_FieldsCountArgs = {
  columns?: Maybe<Array<IndexerTzd_Tld_Select_Column>>;
  distinct?: Maybe<Scalars['Boolean']>;
};

/** Boolean expression to filter rows from the table "tzd_tld". All fields are combined with a logical 'AND'. */
export type IndexerTzd_Tld_Bool_Exp = {
  _and?: Maybe<Array<IndexerTzd_Tld_Bool_Exp>>;
  _not?: Maybe<IndexerTzd_Tld_Bool_Exp>;
  _or?: Maybe<Array<IndexerTzd_Tld_Bool_Exp>>;
  domains?: Maybe<IndexerTzd_Domain_Bool_Exp>;
  domains_aggregate?: Maybe<IndexerTzd_Domain_Aggregate_Bool_Exp>;
  id?: Maybe<IndexerString_Comparison_Exp>;
  owner?: Maybe<IndexerString_Comparison_Exp>;
};

/** aggregate max on columns */
export type IndexerTzd_Tld_Max_Fields = {
  __typename?: 'tzd_tld_max_fields';
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
};

/** aggregate min on columns */
export type IndexerTzd_Tld_Min_Fields = {
  __typename?: 'tzd_tld_min_fields';
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
};

/** Ordering options when selecting data from "tzd_tld". */
export type IndexerTzd_Tld_Order_By = {
  domains_aggregate?: Maybe<IndexerTzd_Domain_Aggregate_Order_By>;
  id?: Maybe<IndexerOrder_By>;
  owner?: Maybe<IndexerOrder_By>;
};

/** select columns of table "tzd_tld" */
export enum IndexerTzd_Tld_Select_Column {
  /** column name */
  Id = 'id',
  /** column name */
  Owner = 'owner'
}

/** Streaming cursor of the table "tzd_tld" */
export type IndexerTzd_Tld_Stream_Cursor_Input = {
  /** Stream column input with initial value */
  initial_value: IndexerTzd_Tld_Stream_Cursor_Value_Input;
  /** cursor ordering */
  ordering?: Maybe<IndexerCursor_Ordering>;
};

/** Initial value of the column from where the streaming should start */
export type IndexerTzd_Tld_Stream_Cursor_Value_Input = {
  id?: Maybe<Scalars['String']>;
  owner?: Maybe<Scalars['String']>;
};

export type IndexerGetAuctionsEndingSoonQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type IndexerGetAuctionsEndingSoonQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
    )> }
    & IndexerEnglishAuctionCardFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
    )> }
    & IndexerDutchAuctionCardFragment
  )> }
);

export type IndexerGetTrendingAuctionsQueryVariables = Exact<{
  limit: Scalars['Int'];
}>;


export type IndexerGetTrendingAuctionsQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
    )> }
    & IndexerEnglishAuctionCardFragment
  )> }
);

export type IndexerGetAuctionsEndingSoonFa2QueryVariables = Exact<{
  fa2: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type IndexerGetAuctionsEndingSoonFa2Query = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
      & IndexerContentFilteringAttributesFragment
    )> }
    & IndexerEnglishAuctionCardFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
      & IndexerContentFilteringAttributesFragment
    )> }
    & IndexerDutchAuctionCardFragment
  )> }
);

export type IndexerGetCuratedAuctionsQueryVariables = Exact<{
  englishHashes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  dutchHashes?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndexerGetCuratedAuctionsQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenCardFragment
    )> }
    & IndexerEnglishAuctionDefaultFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenCardFragment
    )> }
    & IndexerDutchAuctionDefaultFragment
  )> }
);

export type IndexerGetEnglishAuctionQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type IndexerGetEnglishAuctionQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerEnglishAuctionDefaultFragment
  )> }
);

export type IndexerGetDutchAuctionQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type IndexerGetDutchAuctionQuery = (
  { __typename?: 'query_root' }
  & { dutch_auction: Array<(
    { __typename?: 'dutch_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerDutchAuctionDefaultFragment
  )> }
);

export type IndexerGetEnglishAuctionBidsQueryVariables = Exact<{
  id: Scalars['bigint'];
}>;


export type IndexerGetEnglishAuctionBidsQuery = (
  { __typename?: 'query_root' }
  & { english_auction_bid: Array<(
    { __typename?: 'english_auction_bid' }
    & IndexerEnglishAuctionBidsDefaultFragment
  )> }
);

export type IndexerGetUserAuctionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserAuctionsQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerEnglishAuctionLightFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerDutchAuctionLightFragment
  )> }
);

export type IndexerGetUserOnlyAuctionsQueryVariables = Exact<{
  address: Scalars['String'];
  timestamp?: Maybe<Scalars['timestamptz']>;
}>;


export type IndexerGetUserOnlyAuctionsQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerEnglishAuctionLightFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerDutchAuctionLightFragment
  )> }
);

export type IndexerGetCreatorAuctionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetCreatorAuctionsQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
      & IndexerContentFilteringAttributesFragment
    )> }
    & IndexerEnglishAuctionCardFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerAuctionTokenFragment
      & IndexerContentFilteringAttributesFragment
    )> }
    & IndexerDutchAuctionCardFragment
  )> }
);

export type IndexerGetContractAuctionsQueryVariables = Exact<{
  fa2: Scalars['String'];
  timestamp?: Maybe<Scalars['timestamptz']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetContractAuctionsQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerEnglishAuctionLightFragment
  )>, dutch_auction: Array<(
    { __typename?: 'dutch_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerDutchAuctionLightFragment
  )> }
);

export type IndexerGetAuctionCountsBySellerQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetAuctionCountsBySellerQuery = (
  { __typename?: 'query_root' }
  & { dutch_auction_aggregate: (
    { __typename?: 'dutch_auction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'dutch_auction_aggregate_fields' }
      & Pick<IndexerDutch_Auction_Aggregate_Fields, 'count'>
    )> }
  ), english_auction_aggregate: (
    { __typename?: 'english_auction_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'english_auction_aggregate_fields' }
      & Pick<IndexerEnglish_Auction_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetAuctionCountsByCreatorQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetAuctionCountsByCreatorQuery = (
  { __typename?: 'query_root' }
  & { dutch_auction_aggregate: (
    { __typename?: 'dutch_auction_active_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'dutch_auction_active_aggregate_fields' }
      & Pick<IndexerDutch_Auction_Active_Aggregate_Fields, 'count'>
    )> }
  ), english_auction_aggregate: (
    { __typename?: 'english_auction_active_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'english_auction_active_aggregate_fields' }
      & Pick<IndexerEnglish_Auction_Active_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetTokenBalanceQueryVariables = Exact<{
  address: Scalars['String'];
  tokenContract: Scalars['String'];
}>;


export type IndexerGetTokenBalanceQuery = (
  { __typename?: 'query_root' }
  & { token_holder: Array<(
    { __typename?: 'token_holder' }
    & Pick<IndexerToken_Holder, 'quantity'>
  )> }
);

export type IndexerGetCollectionMetaDataQueryVariables = Exact<{
  fa2: Scalars['String'];
}>;


export type IndexerGetCollectionMetaDataQuery = (
  { __typename?: 'query_root' }
  & { token_holder_aggregate: (
    { __typename?: 'token_holder_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'token_holder_aggregate_fields' }
      & Pick<IndexerToken_Holder_Aggregate_Fields, 'count'>
    )> }
  ), token_aggregate: (
    { __typename?: 'token_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'token_aggregate_fields' }
      & Pick<IndexerToken_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetEventsQueryVariables = Exact<{
  where: IndexerEvent_Bool_Exp;
  order_by?: Maybe<Array<IndexerEvent_Order_By> | IndexerEvent_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetEventsQuery = (
  { __typename?: 'query_root' }
  & { event: Array<(
    { __typename?: 'event' }
    & IndexerEventDefaultFragment
  )> }
);

export type IndexerGetEventsHistoryQueryVariables = Exact<{
  where: IndexerEvent_Bool_Exp;
  order_by?: Maybe<Array<IndexerEvent_Order_By> | IndexerEvent_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetEventsHistoryQuery = (
  { __typename?: 'query_root' }
  & { event: Array<(
    { __typename?: 'event' }
    & IndexerEventHistoryFragment
  )> }
);

export type IndexerGetEventsLiveQueryVariables = Exact<{
  where: IndexerEvent_Bool_Exp;
  order_by?: Maybe<Array<IndexerEvent_Order_By> | IndexerEvent_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetEventsLiveQuery = (
  { __typename?: 'query_root' }
  & { event: Array<(
    { __typename?: 'event' }
    & IndexerEventLiveFragment
  )> }
);

export type IndexerGetEventsMultiQueryVariables = Exact<{
  where_common: IndexerEvent_Bool_Exp;
  where_a: IndexerEvent_Bool_Exp;
  where_b: IndexerEvent_Bool_Exp;
  order_by?: Maybe<Array<IndexerEvent_Order_By> | IndexerEvent_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetEventsMultiQuery = (
  { __typename?: 'query_root' }
  & { a: Array<(
    { __typename?: 'event' }
    & IndexerEventDefaultFragment
  )>, b: Array<(
    { __typename?: 'event' }
    & IndexerEventDefaultFragment
  )> }
);

export type IndexerGetExploreFaQueryVariables = Exact<{
  where?: Maybe<IndexerFa_Bool_Exp>;
  order_by?: Maybe<Array<IndexerFa_Order_By> | IndexerFa_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  tokenLimit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetExploreFaQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'logo' | 'name' | 'path' | 'contract' | 'items' | 'volume_total' | 'floor_price' | 'owners'>
    & { tokens: Array<(
      { __typename?: 'token' }
      & IndexerTokenExploreFragment
    )>, creator?: Maybe<(
      { __typename?: 'holder' }
      & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain' | 'logo'>
    )> }
  )>, fa_aggregate: (
    { __typename?: 'fa_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'fa_aggregate_fields' }
      & Pick<IndexerFa_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetExploreFaTokensQueryVariables = Exact<{
  where?: Maybe<IndexerFa_Bool_Exp>;
  order_by?: Maybe<Array<IndexerFa_Order_By> | IndexerFa_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
  tokenLimit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetExploreFaTokensQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'contract' | 'items' | 'owners'>
    & { tokens: Array<(
      { __typename?: 'token' }
      & IndexerTokenExploreFragment
    )> }
  )> }
);

export type IndexerGetFa2QueryVariables = Exact<{
  contract: Scalars['String'];
}>;


export type IndexerGetFa2Query = (
  { __typename?: 'query_root' }
  & { fa_by_pk?: Maybe<(
    { __typename?: 'fa' }
    & IndexerFaFragment
  )> }
);

export type IndexerGetFa2PathQueryVariables = Exact<{
  path: Scalars['String'];
}>;


export type IndexerGetFa2PathQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & IndexerFaFragment
  )> }
);

export type IndexerGetFa2ByCollectionIdQueryVariables = Exact<{
  collectionId?: Maybe<Scalars['String']>;
}>;


export type IndexerGetFa2ByCollectionIdQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & IndexerFaFragment
  )> }
);

export type IndexerGetUserActionableCollectionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserActionableCollectionsQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & { creator?: Maybe<(
      { __typename?: 'holder' }
      & IndexerUserDefaultFragment
    )>, collaborators: Array<(
      { __typename?: 'invitation' }
      & IndexerInvitationFragment
    )> }
    & IndexerFaLightFragment
  )> }
);

export type IndexerGetUserCollectionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserCollectionsQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'collection_id' | 'collection_type' | 'contract' | 'name' | 'path' | 'logo' | 'live'>
    & { tokens_aggregate: (
      { __typename?: 'token_aggregate' }
      & { aggregate?: Maybe<(
        { __typename?: 'token_aggregate_fields' }
        & Pick<IndexerToken_Aggregate_Fields, 'count'>
      )> }
    ) }
  )> }
);

export type IndexerGetUserOwnedCollectionsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserOwnedCollectionsQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & IndexerFaLightFragment
  )> }
);

export type IndexerGetAllFa2QueryVariables = Exact<{
  where?: Maybe<IndexerFa_Bool_Exp>;
  order_by?: Maybe<Array<IndexerFa_Order_By> | IndexerFa_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetAllFa2Query = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & IndexerFaLightFragment
  )> }
);

export type IndexerGetAllFa2WithAggregateQueryVariables = Exact<{
  where?: Maybe<IndexerFa_Bool_Exp>;
  order_by?: Maybe<Array<IndexerFa_Order_By> | IndexerFa_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetAllFa2WithAggregateQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & IndexerFaLightFragment
  )>, fa_aggregate: (
    { __typename?: 'fa_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'fa_aggregate_fields' }
      & Pick<IndexerFa_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetFa2ManagersQueryVariables = Exact<{
  contract: Scalars['String'];
}>;


export type IndexerGetFa2ManagersQuery = (
  { __typename?: 'query_root' }
  & { fa_by_pk?: Maybe<(
    { __typename?: 'fa' }
    & { managers: Array<(
      { __typename?: 'fa2_manager' }
      & IndexerManagerFragment
    )> }
  )> }
);

export type IndexerGetFa2AttributesQueryVariables = Exact<{
  where: IndexerFa2_Attribute_Count_Bool_Exp;
}>;


export type IndexerGetFa2AttributesQuery = (
  { __typename?: 'query_root' }
  & { fa2_attribute_count: Array<(
    { __typename?: 'fa2_attribute_count' }
    & Pick<IndexerFa2_Attribute_Count, 'id' | 'editions' | 'tokens' | 'attribute_id'>
  )> }
);

export type IndexerGetFaCollectorsQueryVariables = Exact<{
  contract: Scalars['String'];
}>;


export type IndexerGetFaCollectorsQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & { tokens: Array<(
      { __typename?: 'token' }
      & { holders: Array<(
        { __typename?: 'token_holder' }
        & Pick<IndexerToken_Holder, 'quantity' | 'holder_address'>
        & { holder: (
          { __typename?: 'holder' }
          & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain' | 'twitter' | 'website' | 'discord'>
        ) }
      )> }
    )> }
  )> }
);

export type IndexerGetFaFloorPriceQueryVariables = Exact<{
  contract: Scalars['String'];
}>;


export type IndexerGetFaFloorPriceQuery = (
  { __typename?: 'query_root' }
  & { fa_by_pk?: Maybe<(
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'floor_price'>
  )> }
);

export type IndexerGetFa2UnverifiedCreatorQueryVariables = Exact<{
  currentUser: Scalars['String'];
}>;


export type IndexerGetFa2UnverifiedCreatorQuery = (
  { __typename?: 'query_root' }
  & { fa: Array<(
    { __typename?: 'fa' }
    & IndexerFaFragment
  )> }
);

export type IndexerEnglishAuctionCardFragment = (
  { __typename?: 'english_auction_active' }
  & Pick<IndexerEnglish_Auction_Active, 'reserve' | 'start_time' | 'end_time' | 'highest_bid' | 'bigmap_key'>
);

export type IndexerDutchAuctionCardFragment = (
  { __typename?: 'dutch_auction_active' }
  & Pick<IndexerDutch_Auction_Active, 'id' | 'hash' | 'amount' | 'amount_left' | 'fa_contract' | 'end_price' | 'start_price' | 'end_time' | 'start_time' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'bigmap_key'>
);

export type IndexerAuctionTokenFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'display_uri' | 'thumbnail_uri' | 'artifact_uri' | 'fa_contract' | 'flag'>
);

export type IndexerContentFilteringAttributesFragment = (
  { __typename?: 'token' }
  & { attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerCreatorDefaultFragment = (
  { __typename?: 'token_creator' }
  & Pick<IndexerToken_Creator, 'creator_address'>
  & { holder: (
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  ) }
);

export type IndexerCreatorLightFragment = (
  { __typename?: 'token_creator' }
  & Pick<IndexerToken_Creator, 'creator_address'>
  & { holder: (
    { __typename?: 'holder' }
    & IndexerUserLightFragment
  ) }
);

export type IndexerCurrencyDefaultFragment = (
  { __typename?: 'currency' }
  & Pick<IndexerCurrency, 'fa_contract' | 'id' | 'type'>
);

export type IndexerDutchAuctionDefaultFragment = (
  { __typename?: 'dutch_auction' }
  & Pick<IndexerDutch_Auction, 'id' | 'hash' | 'amount' | 'amount_left' | 'fa_contract' | 'end_price' | 'start_price' | 'end_time' | 'shares' | 'start_time' | 'status' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'seller_address' | 'marketplace_contract' | 'bigmap_key'>
  & { seller?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerObjktDetailedDutchAuctionFragment = (
  { __typename?: 'dutch_auction_active' }
  & Pick<IndexerDutch_Auction_Active, 'id' | 'hash' | 'amount' | 'amount_left' | 'fa_contract' | 'end_price' | 'start_price' | 'end_time' | 'start_time' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'seller_address' | 'marketplace_contract' | 'bigmap_key'>
);

export type IndexerDutchAuctionLightFragment = (
  { __typename?: 'dutch_auction' }
  & Pick<IndexerDutch_Auction, 'id' | 'hash' | 'amount' | 'amount_left' | 'fa_contract' | 'end_price' | 'start_price' | 'end_time' | 'start_time' | 'status' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'bigmap_key'>
);

export type IndexerEnglishAuctionBidsDefaultFragment = (
  { __typename?: 'english_auction_bid' }
  & Pick<IndexerEnglish_Auction_Bid, 'amount' | 'bidder_address' | 'id' | 'timestamp'>
  & { bidder?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerEnglishAuctionDefaultFragment = (
  { __typename?: 'english_auction' }
  & Pick<IndexerEnglish_Auction, 'id' | 'hash' | 'fa_contract' | 'price_increment' | 'reserve' | 'shares' | 'start_time' | 'status' | 'end_time' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'seller_address' | 'highest_bid' | 'extension_time' | 'highest_bidder_address' | 'marketplace_contract' | 'bigmap_key'>
  & { currency?: Maybe<(
    { __typename?: 'currency' }
    & IndexerCurrencyDefaultFragment
  )>, bids: Array<(
    { __typename?: 'english_auction_bid' }
    & IndexerEnglishAuctionBidsDefaultFragment
  )>, token?: Maybe<(
    { __typename?: 'token' }
    & IndexerTokenDefaultFragment
  )> }
);

export type IndexerObjktDetailedEnglishAuctionFragment = (
  { __typename?: 'english_auction_active' }
  & Pick<IndexerEnglish_Auction_Active, 'id' | 'hash' | 'fa_contract' | 'price_increment' | 'reserve' | 'start_time' | 'end_time' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'seller_address' | 'highest_bid' | 'extension_time' | 'highest_bidder_address' | 'marketplace_contract' | 'bigmap_key'>
  & { bids: Array<(
    { __typename?: 'english_auction_bid' }
    & Pick<IndexerEnglish_Auction_Bid, 'amount'>
  )> }
);

export type IndexerEnglishAuctionLightFragment = (
  { __typename?: 'english_auction' }
  & Pick<IndexerEnglish_Auction, 'id' | 'hash' | 'fa_contract' | 'price_increment' | 'reserve' | 'start_time' | 'status' | 'end_time' | 'timestamp' | 'token_pk' | 'update_level' | 'update_timestamp' | 'seller_address' | 'highest_bid' | 'extension_time' | 'bigmap_key' | 'highest_bidder_address'>
  & { currency?: Maybe<(
    { __typename?: 'currency' }
    & IndexerCurrencyDefaultFragment
  )> }
);

export type IndexerEventDefaultFragment = (
  { __typename?: 'event' }
  & Pick<IndexerEvent, 'id' | 'amount' | 'price' | 'price_xtz' | 'event_type' | 'marketplace_event_type' | 'timestamp' | 'token_pk' | 'fa_contract' | 'ophash' | 'level'>
  & { fa?: Maybe<(
    { __typename?: 'fa' }
    & IndexerFaMinimalFragment
  )>, token?: Maybe<(
    { __typename?: 'token' }
    & IndexerTokenEventFragment
  )>, creator: (
    { __typename?: 'holder' }
    & IndexerUserLightFragment
  ), recipient?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserLightFragment
  )> }
);

export type IndexerEventHistoryFragment = (
  { __typename?: 'event' }
  & Pick<IndexerEvent, 'id' | 'amount' | 'price' | 'price_xtz' | 'event_type' | 'marketplace_event_type' | 'timestamp' | 'token_pk' | 'fa_contract' | 'ophash' | 'level'>
  & { fa?: Maybe<(
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'contract' | 'name' | 'path'>
  )>, creator: (
    { __typename?: 'holder' }
    & Pick<IndexerHolder, 'address' | 'alias' | 'logo' | 'tzdomain'>
  ), recipient?: Maybe<(
    { __typename?: 'holder' }
    & Pick<IndexerHolder, 'address' | 'alias' | 'logo' | 'tzdomain'>
  )> }
);

export type IndexerEventLiveFragment = (
  { __typename?: 'event' }
  & Pick<IndexerEvent, 'id' | 'amount' | 'price' | 'price_xtz' | 'event_type' | 'marketplace_event_type' | 'timestamp' | 'token_pk' | 'fa_contract' | 'ophash' | 'reverted' | 'level'>
  & { fa?: Maybe<(
    { __typename?: 'fa' }
    & IndexerFaMinimalFragment
  )>, token?: Maybe<(
    { __typename?: 'token' }
    & IndexerTokenLiveFragment
  )>, creator: (
    { __typename?: 'holder' }
    & IndexerUserLightFragment
  ), recipient?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserLightFragment
  )> }
);

export type IndexerFaCardFragment = (
  { __typename?: 'fa' }
  & Pick<IndexerFa, 'contract' | 'name' | 'logo' | 'collection_type' | 'creator_address' | 'collection_id' | 'path' | 'short_name' | 'live'>
  & { creator?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerFaEventFragment = (
  { __typename?: 'fa' }
  & Pick<IndexerFa, 'contract' | 'name' | 'logo' | 'collection_type' | 'creator_address' | 'collection_id' | 'path' | 'short_name' | 'live'>
);

export type IndexerFaLightFragment = (
  { __typename?: 'fa' }
  & Pick<IndexerFa, 'contract' | 'description' | 'name' | 'owners' | 'logo' | 'volume_24h' | 'volume_total' | 'floor_price' | 'items' | 'type' | 'collection_type' | 'collection_id' | 'path' | 'token_link' | 'short_name' | 'live'>
);

export type IndexerFaMinimalFragment = (
  { __typename?: 'fa' }
  & Pick<IndexerFa, 'contract' | 'name' | 'path' | 'collection_type' | 'collection_id'>
);

export type IndexerFaFragment = (
  { __typename?: 'fa' }
  & Pick<IndexerFa, 'active_auctions' | 'active_listing' | 'contract' | 'description' | 'name' | 'owners' | 'logo' | 'volume_24h' | 'volume_total' | 'website' | 'twitter' | 'items' | 'floor_price' | 'type' | 'category' | 'collection_type' | 'creator_address' | 'collection_id' | 'path' | 'token_link' | 'short_name' | 'live' | 'editions'>
  & { managers: Array<(
    { __typename?: 'fa2_manager' }
    & IndexerManagerFragment
  )>, collaborators: Array<(
    { __typename?: 'invitation' }
    & IndexerInvitationFragment
  )>, creator?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerGalleryFragment = (
  { __typename?: 'gallery' }
  & Pick<IndexerGallery, 'slug' | 'pk' | 'name' | 'description' | 'editions' | 'volume_24h' | 'volume_total' | 'owners' | 'logo' | 'items' | 'floor_price' | 'gallery_id' | 'active_auctions' | 'active_listing'>
  & { curators: Array<(
    { __typename?: 'gallery_curator' }
    & { curator?: Maybe<(
      { __typename?: 'holder' }
      & IndexerUserDefaultFragment
    )> }
  )>, registry: (
    { __typename?: 'gallery_registry' }
    & Pick<IndexerGallery_Registry, 'name' | 'slug' | 'type'>
  ) }
);

export type IndexerGalleryLightFragment = (
  { __typename?: 'gallery' }
  & Pick<IndexerGallery, 'slug' | 'name' | 'volume_24h' | 'gallery_id' | 'logo' | 'pk'>
  & { registry: (
    { __typename?: 'gallery_registry' }
    & Pick<IndexerGallery_Registry, 'name' | 'slug' | 'type'>
  ) }
);

export type IndexerGalleryTokenFragment = (
  { __typename?: 'gallery_token' }
  & { gallery?: Maybe<(
    { __typename?: 'gallery' }
    & IndexerGalleryLightFragment
  )> }
);

export type IndexerInvitationFragment = (
  { __typename?: 'invitation' }
  & Pick<IndexerInvitation, 'collaborator_address' | 'fa_contract' | 'id' | 'status' | 'timestamp' | 'update_timestamp'>
  & { fa: (
    { __typename?: 'fa' }
    & IndexerFaLightFragment
  ), holder: (
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  ) }
);

export type IndexerListingDefaultFragment = (
  { __typename?: 'listing' }
  & Pick<IndexerListing, 'id' | 'amount' | 'amount_left' | 'price' | 'shares' | 'status' | 'timestamp' | 'token_pk' | 'update_timestamp' | 'fa_contract' | 'marketplace_contract' | 'bigmap_key'>
  & { currency?: Maybe<(
    { __typename?: 'currency' }
    & IndexerCurrencyDefaultFragment
  )>, seller: (
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  ) }
);

export type IndexerObjktDetailedListingFragment = (
  { __typename?: 'listing_active' }
  & Pick<IndexerListing_Active, 'id' | 'amount' | 'amount_left' | 'price' | 'shares' | 'timestamp' | 'token_pk' | 'update_timestamp' | 'fa_contract' | 'marketplace_contract' | 'bigmap_key'>
  & { seller?: Maybe<(
    { __typename?: 'holder' }
    & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain'>
  )> }
);

export type IndexerHangingListingFragment = (
  { __typename?: 'listing' }
  & Pick<IndexerListing, 'amount_left' | 'bigmap_key' | 'marketplace_contract' | 'timestamp'>
);

export type IndexerUserListingFragment = (
  { __typename?: 'listing' }
  & Pick<IndexerListing, 'id' | 'amount' | 'amount_left' | 'price' | 'timestamp' | 'status' | 'token_pk' | 'update_timestamp' | 'fa_contract' | 'marketplace_contract' | 'bigmap_key'>
  & { token?: Maybe<(
    { __typename?: 'token' }
    & Pick<IndexerToken, 'token_id' | 'artifact_uri' | 'display_uri' | 'thumbnail_uri' | 'fa_contract' | 'flag'>
    & { fa: (
      { __typename?: 'fa' }
      & Pick<IndexerFa, 'contract' | 'path'>
    ), creators: Array<(
      { __typename?: 'token_creator' }
      & { holder: (
        { __typename?: 'holder' }
        & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain'>
      ) }
    )>, attributes: Array<(
      { __typename?: 'token_attribute' }
      & { attribute: (
        { __typename?: 'attribute' }
        & Pick<IndexerAttribute, 'name' | 'value'>
      ) }
    )> }
  )> }
);

export type IndexerManagerFragment = (
  { __typename?: 'fa2_manager' }
  & Pick<IndexerFa2_Manager, 'id' | 'inserted_at' | 'manager' | 'contract'>
  & { fa?: Maybe<(
    { __typename?: 'fa' }
    & IndexerFaLightFragment
  )>, holder?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerOfferDefaultFragment = (
  { __typename?: 'offer' }
  & Pick<IndexerOffer, 'id' | 'price' | 'shares' | 'status' | 'timestamp' | 'token_pk' | 'update_timestamp' | 'fa_contract' | 'marketplace_contract' | 'bigmap_key'>
  & { currency?: Maybe<(
    { __typename?: 'currency' }
    & IndexerCurrencyDefaultFragment
  )>, token?: Maybe<(
    { __typename?: 'token' }
    & IndexerTokenLightFragment
  )>, buyer?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )>, seller?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerObjktDetailedOfferFragment = (
  { __typename?: 'offer_active' }
  & Pick<IndexerOffer_Active, 'id' | 'price' | 'shares' | 'timestamp' | 'token_pk' | 'update_timestamp' | 'fa_contract' | 'marketplace_contract' | 'bigmap_key'>
  & { buyer?: Maybe<(
    { __typename?: 'holder' }
    & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain'>
  )> }
);

export type IndexerUserOfferFragment = (
  { __typename?: 'offer_active' }
  & Pick<IndexerOffer_Active, 'id' | 'price' | 'timestamp' | 'token_pk' | 'update_timestamp' | 'fa_contract' | 'marketplace_contract' | 'bigmap_key'>
  & { fa?: Maybe<(
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'logo' | 'path'>
    & { creator?: Maybe<(
      { __typename?: 'holder' }
      & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain'>
    )> }
  )> }
);

export type IndexerUserOfferTokenFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'artifact_uri' | 'display_uri' | 'thumbnail_uri' | 'fa_contract' | 'flag'>
  & { fa: (
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'contract' | 'path'>
  ), creators: Array<(
    { __typename?: 'token_creator' }
    & { holder: (
      { __typename?: 'holder' }
      & Pick<IndexerHolder, 'address' | 'alias'>
    ) }
  )>, attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerOpenEditionFragment = (
  { __typename?: 'open_edition' }
  & Pick<IndexerOpen_Edition, 'end_time' | 'airdrop_capacity' | 'max_per_wallet' | 'price' | 'start_time' | 'fa_contract' | 'shares'>
);

export type IndexerRoyaltiesFragment = (
  { __typename?: 'royalties' }
  & Pick<IndexerRoyalties, 'id' | 'amount' | 'decimals' | 'receiver_address'>
);

export type IndexerSalesStatsFragment = (
  { __typename?: 'sales_stat' }
  & Pick<IndexerSales_Stat, 'id' | 'interval_days' | 'rank' | 'type' | 'volume'>
  & { subject: (
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  ) }
);

export type IndexerTokenCardFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'display_uri' | 'thumbnail_uri' | 'artifact_uri' | 'fa_contract' | 'supply' | 'timestamp' | 'name' | 'mime' | 'flag' | 'lowest_ask' | 'highest_offer' | 'last_listed'>
  & { galleries: Array<(
    { __typename?: 'gallery_token' }
    & IndexerGalleryTokenFragment
  )>, creators: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, creators_unverified: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, fa: (
    { __typename?: 'fa' }
    & IndexerFaCardFragment
  ), attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerTokenDefaultFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'artifact_uri' | 'description' | 'display_uri' | 'thumbnail_uri' | 'fa_contract' | 'rights' | 'supply' | 'timestamp' | 'name' | 'mime' | 'last_listed' | 'highest_offer' | 'lowest_ask' | 'metadata' | 'flag'>
  & { royalties: Array<(
    { __typename?: 'royalties' }
    & IndexerRoyaltiesFragment
  )>, galleries: Array<(
    { __typename?: 'gallery_token' }
    & IndexerGalleryTokenFragment
  )>, fa: (
    { __typename?: 'fa' }
    & IndexerFaFragment
  ), creators: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, creators_unverified: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'id' | 'name' | 'type' | 'value'>
    ) }
  )> }
);

export type IndexerTokenEventFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'display_uri' | 'thumbnail_uri' | 'artifact_uri' | 'fa_contract' | 'supply' | 'mime' | 'name' | 'flag' | 'decimals'>
  & { creators: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, creators_unverified: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, galleries: Array<(
    { __typename?: 'gallery_token' }
    & IndexerGalleryTokenFragment
  )>, attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerTokenExploreFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'artifact_uri'>
  & { attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerTokenHolderDefaultFragment = (
  { __typename?: 'token_holder' }
  & Pick<IndexerToken_Holder, 'holder_address' | 'quantity' | 'token_pk'>
  & { holder: (
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  ) }
);

export type IndexerTokenHoldersFragment = (
  { __typename?: 'token' }
  & { holders: Array<(
    { __typename?: 'token_holder' }
    & IndexerTokenHolderDefaultFragment
  )> }
);

export type IndexerTokenLightFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'artifact_uri' | 'description' | 'display_uri' | 'thumbnail_uri' | 'fa_contract' | 'supply' | 'timestamp' | 'name' | 'mime' | 'flag' | 'last_listed'>
  & { creators: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, creators_unverified: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, fa: (
    { __typename?: 'fa' }
    & IndexerFaMinimalFragment
  ) }
);

export type IndexerTokenLiveFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'artifact_uri' | 'display_uri' | 'thumbnail_uri' | 'fa_contract' | 'mime' | 'decimals'>
  & { attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerTokenMarketFragment = (
  { __typename?: 'token' }
  & { english_auctions: Array<(
    { __typename?: 'english_auction' }
    & IndexerEnglishAuctionDefaultFragment
  )>, dutch_auctions: Array<(
    { __typename?: 'dutch_auction' }
    & IndexerDutchAuctionDefaultFragment
  )>, offers: Array<(
    { __typename?: 'offer' }
    & IndexerOfferDefaultFragment
  )>, listings: Array<(
    { __typename?: 'listing' }
    & IndexerListingDefaultFragment
  )> }
);

export type IndexerTokenMigrationFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'artifact_uri' | 'display_uri' | 'thumbnail_uri' | 'fa_contract' | 'name' | 'mime' | 'metadata' | 'supply'>
  & { creators: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, creators_unverified: Array<(
    { __typename?: 'token_creator' }
    & IndexerCreatorDefaultFragment
  )>, fa: (
    { __typename?: 'fa' }
    & IndexerFaFragment
  ), holders: Array<(
    { __typename?: 'token_holder' }
    & Pick<IndexerToken_Holder, 'holder_address' | 'quantity'>
  )> }
);

export type IndexerUserDefaultFragment = (
  { __typename?: 'holder' }
  & Pick<IndexerHolder, 'address' | 'alias' | 'website' | 'twitter' | 'description' | 'tzdomain' | 'flag' | 'logo' | 'dns'>
);

export type IndexerUserLightFragment = (
  { __typename?: 'holder' }
  & Pick<IndexerHolder, 'address' | 'alias' | 'logo' | 'tzdomain'>
);

export type IndexerGetGalleryQueryVariables = Exact<{
  pk: Scalars['bigint'];
}>;


export type IndexerGetGalleryQuery = (
  { __typename?: 'query_root' }
  & { gallery_by_pk?: Maybe<(
    { __typename?: 'gallery' }
    & IndexerGalleryFragment
  )> }
);

export type IndexerGetGalleryBySlugQueryVariables = Exact<{
  registrySlug: Scalars['String'];
  gallerySlug: Scalars['String'];
}>;


export type IndexerGetGalleryBySlugQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & IndexerGalleryFragment
  )> }
);

export type IndexerGetGalleryPkBySlugQueryVariables = Exact<{
  registrySlug: Scalars['String'];
  gallerySlug: Scalars['String'];
}>;


export type IndexerGetGalleryPkBySlugQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & Pick<IndexerGallery, 'pk'>
  )> }
);

export type IndexerGetAllGalleriesQueryVariables = Exact<{
  where?: Maybe<IndexerGallery_Bool_Exp>;
  order_by?: Maybe<Array<IndexerGallery_Order_By> | IndexerGallery_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetAllGalleriesQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & IndexerGalleryFragment
  )> }
);

export type IndexerGetAllGalleriesLightQueryVariables = Exact<{
  where?: Maybe<IndexerGallery_Bool_Exp>;
  order_by?: Maybe<Array<IndexerGallery_Order_By> | IndexerGallery_Order_By>;
  limit?: Maybe<Scalars['Int']>;
  offset?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetAllGalleriesLightQuery = (
  { __typename?: 'query_root' }
  & { gallery: Array<(
    { __typename?: 'gallery' }
    & IndexerGalleryLightFragment
  )> }
);

export type IndexerGetGalleryAttributesQueryVariables = Exact<{
  where: IndexerGallery_Attribute_Count_Bool_Exp;
}>;


export type IndexerGetGalleryAttributesQuery = (
  { __typename?: 'query_root' }
  & { gallery_attribute_count: Array<(
    { __typename?: 'gallery_attribute_count' }
    & Pick<IndexerGallery_Attribute_Count, 'id' | 'editions' | 'tokens' | 'attribute_id'>
  )> }
);

export type IndexerGetInvitationsQueryVariables = Exact<{
  where: IndexerInvitation_Bool_Exp;
  limit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetInvitationsQuery = (
  { __typename?: 'query_root' }
  & { invitation: Array<(
    { __typename?: 'invitation' }
    & { fa: (
      { __typename?: 'fa' }
      & { creator?: Maybe<(
        { __typename?: 'holder' }
        & IndexerUserDefaultFragment
      )>, collaborators: Array<(
        { __typename?: 'invitation' }
        & IndexerInvitationFragment
      )> }
      & IndexerFaLightFragment
    ) }
    & IndexerInvitationFragment
  )> }
);

export type IndexerGetHangingListingsQueryVariables = Exact<{
  sellerAddress: Scalars['String'];
}>;


export type IndexerGetHangingListingsQuery = (
  { __typename?: 'query_root' }
  & { listing: Array<(
    { __typename?: 'listing' }
    & IndexerHangingListingFragment
  )> }
);

export type IndexerGetUserListingsQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserListingsQuery = (
  { __typename?: 'query_root' }
  & { listing: Array<(
    { __typename?: 'listing' }
    & IndexerUserListingFragment
  )> }
);

export type IndexerGetObjktDetailedQueryVariables = Exact<{
  tokenId: Scalars['String'];
  fa2: Scalars['String'];
  supportedThirdpartyContracts?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndexerGetObjktDetailedQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & { english_auctions: Array<(
      { __typename?: 'english_auction_active' }
      & IndexerObjktDetailedEnglishAuctionFragment
    )>, dutch_auctions: Array<(
      { __typename?: 'dutch_auction_active' }
      & IndexerObjktDetailedDutchAuctionFragment
    )>, offers: Array<(
      { __typename?: 'offer_active' }
      & IndexerObjktDetailedOfferFragment
    )>, tags: Array<(
      { __typename?: 'token_tag' }
      & { tag: (
        { __typename?: 'tag' }
        & Pick<IndexerTag, 'name'>
      ) }
    )>, listings: Array<(
      { __typename?: 'listing_active' }
      & IndexerObjktDetailedListingFragment
    )>, open_edition?: Maybe<(
      { __typename?: 'open_edition' }
      & IndexerOpenEditionFragment
    )> }
    & IndexerTokenDefaultFragment
    & IndexerTokenHoldersFragment
  )>, offer: Array<(
    { __typename?: 'offer_active' }
    & IndexerObjktDetailedOfferFragment
  )> }
);

export type IndexerGetObjktSimpleQueryVariables = Exact<{
  tokenId: Scalars['String'];
  fa2: Scalars['String'];
}>;


export type IndexerGetObjktSimpleQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenDefaultFragment
  )> }
);

export type IndexerGetLatestTokenIdQueryVariables = Exact<{
  fa2: Scalars['String'];
}>;


export type IndexerGetLatestTokenIdQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & Pick<IndexerToken, 'token_id'>
  )> }
);

export type IndexerGetObjktsSimpleQueryVariables = Exact<{
  where?: Maybe<IndexerToken_Bool_Exp>;
  order_by?: Maybe<Array<IndexerToken_Order_By> | IndexerToken_Order_By>;
}>;


export type IndexerGetObjktsSimpleQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenDefaultFragment
  )> }
);

export type IndexerGetHomepageSelectionQueryVariables = Exact<{
  tokenIds?: Maybe<Array<Scalars['String']> | Scalars['String']>;
  fa2: Scalars['String'];
}>;


export type IndexerGetHomepageSelectionQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenDefaultFragment
  )> }
);

export type IndexerGetObjktsByHolderPagedQueryVariables = Exact<{
  limit: Scalars['Int'];
  offset: Scalars['Int'];
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By> | IndexerToken_Holder_Order_By>;
}>;


export type IndexerGetObjktsByHolderPagedQuery = (
  { __typename?: 'query_root' }
  & { token_holder: Array<(
    { __typename?: 'token_holder' }
    & Pick<IndexerToken_Holder, 'quantity'>
    & { token: (
      { __typename?: 'token' }
      & { royalties: Array<(
        { __typename?: 'royalties' }
        & IndexerRoyaltiesFragment
      )> }
      & IndexerTokenCardFragment
    ) }
  )>, token_holder_aggregate: (
    { __typename?: 'token_holder_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'token_holder_aggregate_fields' }
      & Pick<IndexerToken_Holder_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetObjktsPagedQueryVariables = Exact<{
  where?: Maybe<IndexerToken_Bool_Exp>;
  order_by?: Maybe<Array<IndexerToken_Order_By> | IndexerToken_Order_By>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type IndexerGetObjktsPagedQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenCardFragment
  )>, token_aggregate: (
    { __typename?: 'token_aggregate' }
    & { aggregate?: Maybe<(
      { __typename?: 'token_aggregate_fields' }
      & Pick<IndexerToken_Aggregate_Fields, 'count'>
    )> }
  ) }
);

export type IndexerGetObjktsQueryVariables = Exact<{
  where?: Maybe<IndexerToken_Bool_Exp>;
  order_by?: Maybe<Array<IndexerToken_Order_By> | IndexerToken_Order_By>;
  limit: Scalars['Int'];
  offset: Scalars['Int'];
}>;


export type IndexerGetObjktsQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenDefaultFragment
  )> }
);

export type IndexerGetRecentlyListedObjktsQueryVariables = Exact<{
  limit: Scalars['Int'];
  fa2: Scalars['String'];
  supportedContracts?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndexerGetRecentlyListedObjktsQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenCardFragment
  )> }
);

export type IndexerGetOpenEditionEndingSoonQueryVariables = Exact<{
  limit: Scalars['Int'];
  timestamp?: Maybe<Scalars['timestamptz']>;
}>;


export type IndexerGetOpenEditionEndingSoonQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & { open_edition?: Maybe<(
      { __typename?: 'open_edition' }
      & IndexerOpenEditionFragment
    )> }
    & IndexerTokenCardFragment
  )> }
);

export type IndexerGetAllRecentlyListedObjktsQueryVariables = Exact<{
  limit: Scalars['Int'];
  supportedContracts?: Maybe<Array<Scalars['String']> | Scalars['String']>;
}>;


export type IndexerGetAllRecentlyListedObjktsQuery = (
  { __typename?: 'query_root' }
  & { listing: Array<(
    { __typename?: 'listing' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenCardFragment
    )> }
  )> }
);

export type IndexerGetUserOffersQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserOffersQuery = (
  { __typename?: 'query_root' }
  & { offer: Array<(
    { __typename?: 'offer_active' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerUserOfferTokenFragment
    )> }
    & IndexerUserOfferFragment
  )> }
);

export type IndexerGetUserBookmarksQueryVariables = Exact<{
  conditions?: Maybe<Array<IndexerToken_Bool_Exp> | IndexerToken_Bool_Exp>;
}>;


export type IndexerGetUserBookmarksQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenDefaultFragment
  )> }
);

export type IndexerGetFeaturedCurationsQueryVariables = Exact<{
  conditions: Array<IndexerToken_Bool_Exp> | IndexerToken_Bool_Exp;
}>;


export type IndexerGetFeaturedCurationsQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & Pick<IndexerToken, 'highest_offer' | 'lowest_ask'>
    & { fa: (
      { __typename?: 'fa' }
      & IndexerFaLightFragment
    ) }
    & IndexerTokenCardFragment
  )> }
);

export type IndexerGetTokenMigrationOwnedTokensQueryVariables = Exact<{
  where?: Maybe<IndexerToken_Holder_Bool_Exp>;
  order_by?: Maybe<Array<IndexerToken_Holder_Order_By> | IndexerToken_Holder_Order_By>;
}>;


export type IndexerGetTokenMigrationOwnedTokensQuery = (
  { __typename?: 'query_root' }
  & { token_holder: Array<(
    { __typename?: 'token_holder' }
    & { token: (
      { __typename?: 'token' }
      & IndexerTokenMigrationFragment
    ) }
  )> }
);

export type IndexerGetTokenMigrationCreatedTokensQueryVariables = Exact<{
  creator: Scalars['String'];
}>;


export type IndexerGetTokenMigrationCreatedTokensQuery = (
  { __typename?: 'query_root' }
  & { token: Array<(
    { __typename?: 'token' }
    & IndexerTokenMigrationFragment
  )> }
);

export type IndexerGetObjktOneActiveTokensQueryVariables = Exact<{
  limit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetObjktOneActiveTokensQuery = (
  { __typename?: 'query_root' }
  & { english_auction: Array<(
    { __typename?: 'english_auction' }
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerTokenDefaultFragment
    )> }
    & IndexerEnglishAuctionLightFragment
  )> }
);

export type IndexerOpenEditionTokensQueryVariables = Exact<{
  fa2: Scalars['String'];
  limit: Scalars['Int'];
}>;


export type IndexerOpenEditionTokensQuery = (
  { __typename?: 'query_root' }
  & { open_edition: Array<(
    { __typename?: 'open_edition_active' }
    & Pick<IndexerOpen_Edition_Active, 'end_time' | 'price' | 'start_time'>
    & { token?: Maybe<(
      { __typename?: 'token' }
      & IndexerOpenEditionTokenFragment
    )> }
  )> }
);

export type IndexerOpenEditionTokenFragment = (
  { __typename?: 'token' }
  & Pick<IndexerToken, 'pk' | 'token_id' | 'display_uri' | 'thumbnail_uri' | 'artifact_uri' | 'fa_contract' | 'supply' | 'name' | 'mime' | 'flag'>
  & { galleries: Array<(
    { __typename?: 'gallery_token' }
    & { gallery?: Maybe<(
      { __typename?: 'gallery' }
      & Pick<IndexerGallery, 'pk'>
    )> }
  )>, creators: Array<(
    { __typename?: 'token_creator' }
    & Pick<IndexerToken_Creator, 'creator_address'>
    & { holder: (
      { __typename?: 'holder' }
      & Pick<IndexerHolder, 'address' | 'alias' | 'tzdomain'>
    ) }
  )>, fa: (
    { __typename?: 'fa' }
    & Pick<IndexerFa, 'contract' | 'name' | 'collection_type' | 'path' | 'short_name'>
  ), attributes: Array<(
    { __typename?: 'token_attribute' }
    & { attribute: (
      { __typename?: 'attribute' }
      & Pick<IndexerAttribute, 'name' | 'value'>
    ) }
  )> }
);

export type IndexerGetManyTokenOperatorsQueryVariables = Exact<{
  userAddress: Scalars['String'];
  orClauses: Array<IndexerToken_Operator_Bool_Exp> | IndexerToken_Operator_Bool_Exp;
}>;


export type IndexerGetManyTokenOperatorsQuery = (
  { __typename?: 'query_root' }
  & { token_operator: Array<(
    { __typename?: 'token_operator' }
    & Pick<IndexerToken_Operator, 'token_pk' | 'owner_address' | 'operator_address' | 'id'>
  )> }
);

export type IndexerGetSalesStatsQueryVariables = Exact<{
  type: Scalars['sales_stat_type'];
  days?: Maybe<Scalars['Int']>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetSalesStatsQuery = (
  { __typename?: 'query_root' }
  & { sales_stat: Array<(
    { __typename?: 'sales_stat' }
    & IndexerSalesStatsFragment
  )> }
);

export type IndexerGetTagsQueryVariables = Exact<{
  search: Scalars['String'];
  limit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetTagsQuery = (
  { __typename?: 'query_root' }
  & { tag: Array<(
    { __typename?: 'tag' }
    & Pick<IndexerTag, 'name'>
  )> }
);

export type IndexerGetUserQueryVariables = Exact<{
  address: Scalars['String'];
}>;


export type IndexerGetUserQuery = (
  { __typename?: 'query_root' }
  & { holder_by_pk?: Maybe<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export type IndexerGetUsersQueryVariables = Exact<{
  where?: Maybe<IndexerHolder_Bool_Exp>;
  limit?: Maybe<Scalars['Int']>;
}>;


export type IndexerGetUsersQuery = (
  { __typename?: 'query_root' }
  & { holder: Array<(
    { __typename?: 'holder' }
    & IndexerUserDefaultFragment
  )> }
);

export const IndexerEnglishAuctionCardFragmentDoc = gql`
    fragment EnglishAuctionCard on english_auction_active {
  reserve
  start_time
  end_time
  highest_bid
  bigmap_key
}
    `;
export const IndexerDutchAuctionCardFragmentDoc = gql`
    fragment DutchAuctionCard on dutch_auction_active {
  id
  hash
  amount
  amount_left
  fa_contract
  end_price
  start_price
  end_price
  end_time
  start_time
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  bigmap_key
}
    `;
export const IndexerAuctionTokenFragmentDoc = gql`
    fragment AuctionToken on token {
  pk
  token_id
  display_uri
  thumbnail_uri
  artifact_uri
  fa_contract
  flag
}
    `;
export const IndexerContentFilteringAttributesFragmentDoc = gql`
    fragment ContentFilteringAttributes on token {
  attributes {
    attribute {
      name
      value
    }
  }
}
    `;
export const IndexerUserLightFragmentDoc = gql`
    fragment UserLight on holder {
  address
  alias
  logo
  tzdomain
}
    `;
export const IndexerCreatorLightFragmentDoc = gql`
    fragment CreatorLight on token_creator {
  creator_address
  holder {
    ...UserLight
  }
}
    ${IndexerUserLightFragmentDoc}`;
export const IndexerObjktDetailedDutchAuctionFragmentDoc = gql`
    fragment ObjktDetailedDutchAuction on dutch_auction_active {
  id
  hash
  amount
  amount_left
  fa_contract
  end_price
  start_price
  end_price
  end_time
  start_time
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  seller_address
  marketplace_contract
  bigmap_key
}
    `;
export const IndexerDutchAuctionLightFragmentDoc = gql`
    fragment DutchAuctionLight on dutch_auction {
  id
  hash
  amount
  amount_left
  fa_contract
  end_price
  start_price
  end_price
  end_time
  start_time
  status
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  bigmap_key
}
    `;
export const IndexerObjktDetailedEnglishAuctionFragmentDoc = gql`
    fragment ObjktDetailedEnglishAuction on english_auction_active {
  id
  hash
  fa_contract
  price_increment
  reserve
  start_time
  end_time
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  seller_address
  highest_bid
  extension_time
  highest_bidder_address
  marketplace_contract
  bigmap_key
  bids {
    amount
  }
}
    `;
export const IndexerCurrencyDefaultFragmentDoc = gql`
    fragment CurrencyDefault on currency {
  fa_contract
  id
  type
}
    `;
export const IndexerEnglishAuctionLightFragmentDoc = gql`
    fragment EnglishAuctionLight on english_auction {
  id
  hash
  fa_contract
  price_increment
  reserve
  start_time
  status
  end_time
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  seller_address
  highest_bid
  extension_time
  bigmap_key
  highest_bidder_address
  currency {
    ...CurrencyDefault
  }
}
    ${IndexerCurrencyDefaultFragmentDoc}`;
export const IndexerFaMinimalFragmentDoc = gql`
    fragment FaMinimal on fa {
  contract
  name
  path
  collection_type
  collection_id
}
    `;
export const IndexerUserDefaultFragmentDoc = gql`
    fragment UserDefault on holder {
  address
  alias
  website
  twitter
  description
  tzdomain
  flag
  logo
  dns
}
    `;
export const IndexerCreatorDefaultFragmentDoc = gql`
    fragment CreatorDefault on token_creator {
  creator_address
  holder {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerGalleryLightFragmentDoc = gql`
    fragment GalleryLight on gallery {
  slug
  name
  volume_24h
  gallery_id
  logo
  pk
  registry {
    name
    slug
    type
  }
}
    `;
export const IndexerGalleryTokenFragmentDoc = gql`
    fragment GalleryToken on gallery_token {
  gallery {
    ...GalleryLight
  }
}
    ${IndexerGalleryLightFragmentDoc}`;
export const IndexerTokenEventFragmentDoc = gql`
    fragment TokenEvent on token {
  pk
  token_id
  display_uri
  thumbnail_uri
  artifact_uri
  fa_contract
  supply
  mime
  name
  flag
  decimals
  creators(where: {verified: {_eq: true}}) {
    ...CreatorDefault
  }
  creators_unverified: creators(where: {verified: {_eq: false}}) {
    ...CreatorDefault
  }
  galleries {
    ...GalleryToken
  }
  attributes {
    attribute {
      name
      value
    }
  }
}
    ${IndexerCreatorDefaultFragmentDoc}
${IndexerGalleryTokenFragmentDoc}`;
export const IndexerEventDefaultFragmentDoc = gql`
    fragment EventDefault on event {
  id
  amount
  price
  price_xtz
  event_type
  marketplace_event_type
  timestamp
  token_pk
  fa_contract
  ophash
  level
  fa {
    ...FaMinimal
  }
  token {
    ...TokenEvent
  }
  creator {
    ...UserLight
  }
  recipient {
    ...UserLight
  }
}
    ${IndexerFaMinimalFragmentDoc}
${IndexerTokenEventFragmentDoc}
${IndexerUserLightFragmentDoc}`;
export const IndexerEventHistoryFragmentDoc = gql`
    fragment EventHistory on event {
  id
  amount
  price
  price_xtz
  event_type
  marketplace_event_type
  timestamp
  token_pk
  fa_contract
  ophash
  level
  fa {
    contract
    name
    path
  }
  creator {
    address
    alias
    logo
    tzdomain
  }
  recipient {
    address
    alias
    logo
    tzdomain
  }
}
    `;
export const IndexerTokenLiveFragmentDoc = gql`
    fragment TokenLive on token {
  pk
  token_id
  artifact_uri
  display_uri
  thumbnail_uri
  fa_contract
  mime
  decimals
  attributes {
    attribute {
      name
      value
    }
  }
}
    `;
export const IndexerEventLiveFragmentDoc = gql`
    fragment EventLive on event {
  id
  amount
  price
  price_xtz
  event_type
  marketplace_event_type
  timestamp
  token_pk
  fa_contract
  ophash
  reverted
  level
  fa {
    ...FaMinimal
  }
  token {
    ...TokenLive
  }
  creator {
    ...UserLight
  }
  recipient {
    ...UserLight
  }
}
    ${IndexerFaMinimalFragmentDoc}
${IndexerTokenLiveFragmentDoc}
${IndexerUserLightFragmentDoc}`;
export const IndexerFaEventFragmentDoc = gql`
    fragment FaEvent on fa {
  contract
  name
  logo
  collection_type
  creator_address
  collection_id
  path
  short_name
  live
}
    `;
export const IndexerGalleryFragmentDoc = gql`
    fragment Gallery on gallery {
  slug
  pk
  name
  description
  editions
  volume_24h
  volume_total
  owners
  logo
  items
  floor_price
  gallery_id
  active_auctions
  active_listing
  curators {
    curator {
      ...UserDefault
    }
  }
  registry {
    name
    slug
    type
  }
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerObjktDetailedListingFragmentDoc = gql`
    fragment ObjktDetailedListing on listing_active {
  id
  amount
  amount_left
  price
  shares
  timestamp
  token_pk
  update_timestamp
  fa_contract
  marketplace_contract
  bigmap_key
  seller {
    address
    alias
    tzdomain
  }
}
    `;
export const IndexerHangingListingFragmentDoc = gql`
    fragment HangingListing on listing {
  amount_left
  bigmap_key
  marketplace_contract
  timestamp
}
    `;
export const IndexerUserListingFragmentDoc = gql`
    fragment UserListing on listing {
  id
  amount
  amount_left
  price
  timestamp
  status
  token_pk
  update_timestamp
  fa_contract
  marketplace_contract
  bigmap_key
  token {
    token_id
    artifact_uri
    display_uri
    thumbnail_uri
    fa_contract
    flag
    fa {
      contract
      path
    }
    creators {
      holder {
        address
        alias
        tzdomain
      }
    }
    attributes {
      attribute {
        name
        value
      }
    }
  }
}
    `;
export const IndexerObjktDetailedOfferFragmentDoc = gql`
    fragment ObjktDetailedOffer on offer_active {
  id
  price
  shares
  timestamp
  token_pk
  update_timestamp
  fa_contract
  marketplace_contract
  bigmap_key
  buyer {
    address
    alias
    tzdomain
  }
}
    `;
export const IndexerUserOfferFragmentDoc = gql`
    fragment UserOffer on offer_active {
  id
  price
  timestamp
  token_pk
  update_timestamp
  fa_contract
  marketplace_contract
  bigmap_key
  fa {
    logo
    path
    creator {
      address
      alias
      tzdomain
    }
  }
}
    `;
export const IndexerUserOfferTokenFragmentDoc = gql`
    fragment UserOfferToken on token {
  pk
  token_id
  artifact_uri
  display_uri
  thumbnail_uri
  fa_contract
  flag
  fa {
    contract
    path
  }
  creators(where: {verified: {_eq: true}}) {
    holder {
      address
      alias
    }
  }
  attributes {
    attribute {
      name
      value
    }
  }
}
    `;
export const IndexerOpenEditionFragmentDoc = gql`
    fragment OpenEdition on open_edition {
  end_time
  airdrop_capacity
  max_per_wallet
  price
  start_time
  fa_contract
  shares
}
    `;
export const IndexerSalesStatsFragmentDoc = gql`
    fragment SalesStats on sales_stat {
  id
  interval_days
  rank
  type
  volume
  subject {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerFaCardFragmentDoc = gql`
    fragment FaCard on fa {
  contract
  name
  logo
  collection_type
  creator_address
  collection_id
  path
  short_name
  live
  creator {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerTokenCardFragmentDoc = gql`
    fragment TokenCard on token {
  pk
  token_id
  display_uri
  thumbnail_uri
  artifact_uri
  fa_contract
  supply
  timestamp
  name
  mime
  flag
  lowest_ask
  highest_offer
  last_listed
  galleries {
    ...GalleryToken
  }
  creators(where: {verified: {_eq: true}}) {
    ...CreatorDefault
  }
  creators_unverified: creators(where: {verified: {_eq: false}}) {
    ...CreatorDefault
  }
  fa {
    ...FaCard
  }
  attributes {
    attribute {
      name
      value
    }
  }
}
    ${IndexerGalleryTokenFragmentDoc}
${IndexerCreatorDefaultFragmentDoc}
${IndexerFaCardFragmentDoc}`;
export const IndexerTokenExploreFragmentDoc = gql`
    fragment TokenExplore on token {
  pk
  token_id
  artifact_uri
  attributes {
    attribute {
      name
      value
    }
  }
}
    `;
export const IndexerTokenHolderDefaultFragmentDoc = gql`
    fragment TokenHolderDefault on token_holder {
  holder_address
  quantity
  token_pk
  holder {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerTokenHoldersFragmentDoc = gql`
    fragment TokenHolders on token {
  holders(where: {quantity: {_gt: "0"}}) {
    ...TokenHolderDefault
  }
}
    ${IndexerTokenHolderDefaultFragmentDoc}`;
export const IndexerEnglishAuctionBidsDefaultFragmentDoc = gql`
    fragment EnglishAuctionBidsDefault on english_auction_bid {
  amount
  bidder {
    ...UserDefault
  }
  bidder_address
  id
  timestamp
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerRoyaltiesFragmentDoc = gql`
    fragment Royalties on royalties {
  id
  amount
  decimals
  receiver_address
}
    `;
export const IndexerFaLightFragmentDoc = gql`
    fragment FaLight on fa {
  contract
  description
  name
  owners
  logo
  volume_24h
  volume_total
  floor_price
  items
  type
  collection_type
  collection_id
  path
  token_link
  short_name
  live
}
    `;
export const IndexerManagerFragmentDoc = gql`
    fragment Manager on fa2_manager {
  id
  inserted_at
  manager
  contract
  fa {
    ...FaLight
  }
  holder {
    ...UserDefault
  }
}
    ${IndexerFaLightFragmentDoc}
${IndexerUserDefaultFragmentDoc}`;
export const IndexerInvitationFragmentDoc = gql`
    fragment Invitation on invitation {
  collaborator_address
  fa_contract
  id
  status
  timestamp
  update_timestamp
  fa {
    ...FaLight
  }
  holder {
    ...UserDefault
  }
}
    ${IndexerFaLightFragmentDoc}
${IndexerUserDefaultFragmentDoc}`;
export const IndexerFaFragmentDoc = gql`
    fragment Fa on fa {
  active_auctions
  active_listing
  contract
  description
  name
  owners
  logo
  volume_24h
  volume_total
  website
  twitter
  items
  floor_price
  type
  category
  collection_type
  creator_address
  collection_id
  path
  token_link
  short_name
  live
  editions
  managers {
    ...Manager
  }
  collaborators {
    ...Invitation
  }
  creator {
    ...UserDefault
  }
}
    ${IndexerManagerFragmentDoc}
${IndexerInvitationFragmentDoc}
${IndexerUserDefaultFragmentDoc}`;
export const IndexerTokenDefaultFragmentDoc = gql`
    fragment TokenDefault on token {
  pk
  token_id
  artifact_uri
  description
  display_uri
  thumbnail_uri
  fa_contract
  rights
  royalties {
    ...Royalties
  }
  supply
  timestamp
  name
  mime
  last_listed
  highest_offer
  lowest_ask
  metadata
  flag
  galleries {
    ...GalleryToken
  }
  fa {
    ...Fa
  }
  creators(where: {verified: {_eq: true}}) {
    ...CreatorDefault
  }
  creators_unverified: creators(where: {verified: {_eq: false}}) {
    ...CreatorDefault
  }
  attributes {
    attribute {
      id
      name
      type
      value
    }
  }
}
    ${IndexerRoyaltiesFragmentDoc}
${IndexerGalleryTokenFragmentDoc}
${IndexerFaFragmentDoc}
${IndexerCreatorDefaultFragmentDoc}`;
export const IndexerEnglishAuctionDefaultFragmentDoc = gql`
    fragment EnglishAuctionDefault on english_auction {
  id
  hash
  fa_contract
  price_increment
  reserve
  shares
  start_time
  status
  end_time
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  seller_address
  highest_bid
  extension_time
  highest_bidder_address
  marketplace_contract
  bigmap_key
  currency {
    ...CurrencyDefault
  }
  bids {
    ...EnglishAuctionBidsDefault
  }
  token {
    ...TokenDefault
  }
}
    ${IndexerCurrencyDefaultFragmentDoc}
${IndexerEnglishAuctionBidsDefaultFragmentDoc}
${IndexerTokenDefaultFragmentDoc}`;
export const IndexerDutchAuctionDefaultFragmentDoc = gql`
    fragment DutchAuctionDefault on dutch_auction {
  id
  hash
  amount
  amount_left
  fa_contract
  end_price
  start_price
  end_price
  end_time
  shares
  start_time
  status
  timestamp
  token_pk
  update_level
  update_timestamp
  hash
  seller_address
  marketplace_contract
  bigmap_key
  seller {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;
export const IndexerTokenLightFragmentDoc = gql`
    fragment TokenLight on token {
  pk
  token_id
  artifact_uri
  description
  display_uri
  thumbnail_uri
  fa_contract
  supply
  timestamp
  name
  mime
  flag
  last_listed
  creators(where: {verified: {_eq: true}}) {
    ...CreatorDefault
  }
  creators_unverified: creators(where: {verified: {_eq: false}}) {
    ...CreatorDefault
  }
  fa {
    ...FaMinimal
  }
}
    ${IndexerCreatorDefaultFragmentDoc}
${IndexerFaMinimalFragmentDoc}`;
export const IndexerOfferDefaultFragmentDoc = gql`
    fragment OfferDefault on offer {
  id
  price
  shares
  status
  timestamp
  token_pk
  update_timestamp
  fa_contract
  marketplace_contract
  bigmap_key
  currency {
    ...CurrencyDefault
  }
  token {
    ...TokenLight
  }
  buyer {
    ...UserDefault
  }
  seller {
    ...UserDefault
  }
}
    ${IndexerCurrencyDefaultFragmentDoc}
${IndexerTokenLightFragmentDoc}
${IndexerUserDefaultFragmentDoc}`;
export const IndexerListingDefaultFragmentDoc = gql`
    fragment ListingDefault on listing {
  id
  amount
  amount_left
  price
  shares
  status
  timestamp
  token_pk
  update_timestamp
  fa_contract
  marketplace_contract
  bigmap_key
  currency {
    ...CurrencyDefault
  }
  seller {
    ...UserDefault
  }
}
    ${IndexerCurrencyDefaultFragmentDoc}
${IndexerUserDefaultFragmentDoc}`;
export const IndexerTokenMarketFragmentDoc = gql`
    fragment TokenMarket on token {
  english_auctions(
    where: {status: {_eq: "active"}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
  ) {
    ...EnglishAuctionDefault
  }
  dutch_auctions(where: {status: {_eq: "active"}, currency_id: {_eq: 1}}) {
    ...DutchAuctionDefault
  }
  offers(
    order_by: {price: desc}
    where: {status: {_eq: "active"}, _or: [{currency_id: {_eq: 1}}]}
  ) {
    ...OfferDefault
  }
  listings(
    order_by: {price: asc}
    where: {status: {_eq: "active"}, _or: [{currency_id: {_eq: 1}}]}
  ) {
    ...ListingDefault
  }
}
    ${IndexerEnglishAuctionDefaultFragmentDoc}
${IndexerDutchAuctionDefaultFragmentDoc}
${IndexerOfferDefaultFragmentDoc}
${IndexerListingDefaultFragmentDoc}`;
export const IndexerTokenMigrationFragmentDoc = gql`
    fragment TokenMigration on token {
  pk
  token_id
  artifact_uri
  display_uri
  thumbnail_uri
  fa_contract
  name
  mime
  metadata
  supply
  creators(where: {verified: {_eq: true}}) {
    ...CreatorDefault
  }
  creators_unverified: creators(where: {verified: {_eq: false}}) {
    ...CreatorDefault
  }
  fa {
    ...Fa
  }
  holders {
    holder_address
    quantity
  }
}
    ${IndexerCreatorDefaultFragmentDoc}
${IndexerFaFragmentDoc}`;
export const IndexerOpenEditionTokenFragmentDoc = gql`
    fragment OpenEditionToken on token {
  pk
  token_id
  display_uri
  thumbnail_uri
  artifact_uri
  fa_contract
  supply
  name
  mime
  flag
  galleries {
    gallery {
      pk
    }
  }
  creators(where: {verified: {_eq: true}}) {
    creator_address
    holder {
      address
      alias
      tzdomain
    }
  }
  fa {
    contract
    name
    collection_type
    path
    short_name
  }
  attributes {
    attribute {
      name
      value
    }
  }
}
    `;
export const IndexergetAuctionsEndingSoonDocument = gql`
    query getAuctionsEndingSoon($limit: Int!) {
  english_auction: english_auction_active(
    where: {fa: {live: {_eq: true}}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}, token: {content_rating: {_is_null: true}}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...EnglishAuctionCard
    token {
      ...AuctionToken
    }
  }
  dutch_auction: dutch_auction_active(
    where: {fa: {live: {_eq: true}}, currency_id: {_eq: 1}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...DutchAuctionCard
    token {
      ...AuctionToken
    }
  }
}
    ${IndexerEnglishAuctionCardFragmentDoc}
${IndexerAuctionTokenFragmentDoc}
${IndexerDutchAuctionCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAuctionsEndingSoonGQL extends Apollo.Query<IndexerGetAuctionsEndingSoonQuery, IndexerGetAuctionsEndingSoonQueryVariables> {
    document = IndexergetAuctionsEndingSoonDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetTrendingAuctionsDocument = gql`
    query getTrendingAuctions($limit: Int!) {
  english_auction: english_auction_active(
    where: {fa: {live: {_eq: true}}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}, token: {flag: {_neq: "removed"}, content_rating: {_is_null: true}}}
    order_by: {highest_bid: desc_nulls_last}
    limit: $limit
  ) {
    ...EnglishAuctionCard
    token {
      ...AuctionToken
    }
  }
}
    ${IndexerEnglishAuctionCardFragmentDoc}
${IndexerAuctionTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetTrendingAuctionsGQL extends Apollo.Query<IndexerGetTrendingAuctionsQuery, IndexerGetTrendingAuctionsQueryVariables> {
    document = IndexergetTrendingAuctionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetAuctionsEndingSoonFa2Document = gql`
    query getAuctionsEndingSoonFa2($fa2: String!, $limit: Int!) {
  english_auction: english_auction_active(
    where: {fa_contract: {_eq: $fa2}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...EnglishAuctionCard
    token {
      ...AuctionToken
      ...ContentFilteringAttributes
    }
  }
  dutch_auction: dutch_auction_active(
    where: {fa_contract: {_eq: $fa2}, currency_id: {_eq: 1}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...DutchAuctionCard
    token {
      ...AuctionToken
      ...ContentFilteringAttributes
    }
  }
}
    ${IndexerEnglishAuctionCardFragmentDoc}
${IndexerAuctionTokenFragmentDoc}
${IndexerContentFilteringAttributesFragmentDoc}
${IndexerDutchAuctionCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAuctionsEndingSoonFa2GQL extends Apollo.Query<IndexerGetAuctionsEndingSoonFa2Query, IndexerGetAuctionsEndingSoonFa2QueryVariables> {
    document = IndexergetAuctionsEndingSoonFa2Document;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetCuratedAuctionsDocument = gql`
    query getCuratedAuctions($englishHashes: [String!], $dutchHashes: [String!]) {
  english_auction(
    where: {hash: {_in: $englishHashes}, status: {_eq: "active"}, fa: {live: {_eq: true}}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    order_by: {end_time: asc}
  ) {
    ...EnglishAuctionDefault
    token {
      ...TokenCard
    }
  }
  dutch_auction(
    where: {hash: {_in: $dutchHashes}, status: {_eq: "active"}, fa: {live: {_eq: true}}, currency_id: {_eq: 1}}
    order_by: {end_time: asc}
  ) {
    ...DutchAuctionDefault
    token {
      ...TokenCard
    }
  }
}
    ${IndexerEnglishAuctionDefaultFragmentDoc}
${IndexerTokenCardFragmentDoc}
${IndexerDutchAuctionDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetCuratedAuctionsGQL extends Apollo.Query<IndexerGetCuratedAuctionsQuery, IndexerGetCuratedAuctionsQueryVariables> {
    document = IndexergetCuratedAuctionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetEnglishAuctionDocument = gql`
    query getEnglishAuction($id: bigint!) {
  english_auction(where: {bigmap_key: {_eq: $id}}) {
    ...EnglishAuctionDefault
    token {
      ...TokenDefault
    }
  }
}
    ${IndexerEnglishAuctionDefaultFragmentDoc}
${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetEnglishAuctionGQL extends Apollo.Query<IndexerGetEnglishAuctionQuery, IndexerGetEnglishAuctionQueryVariables> {
    document = IndexergetEnglishAuctionDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetDutchAuctionDocument = gql`
    query getDutchAuction($id: bigint!) {
  dutch_auction(where: {bigmap_key: {_eq: $id}}) {
    ...DutchAuctionDefault
    token {
      ...TokenDefault
    }
  }
}
    ${IndexerDutchAuctionDefaultFragmentDoc}
${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetDutchAuctionGQL extends Apollo.Query<IndexerGetDutchAuctionQuery, IndexerGetDutchAuctionQueryVariables> {
    document = IndexergetDutchAuctionDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetEnglishAuctionBidsDocument = gql`
    query getEnglishAuctionBids($id: bigint!) {
  english_auction_bid(
    where: {auction: {bigmap_key: {_eq: $id}}}
    order_by: {amount: desc}
  ) {
    ...EnglishAuctionBidsDefault
  }
}
    ${IndexerEnglishAuctionBidsDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetEnglishAuctionBidsGQL extends Apollo.Query<IndexerGetEnglishAuctionBidsQuery, IndexerGetEnglishAuctionBidsQueryVariables> {
    document = IndexergetEnglishAuctionBidsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserAuctionsDocument = gql`
    query getUserAuctions($address: String!) {
  english_auction(
    where: {seller_address: {_eq: $address}, fa: {live: {_eq: true}}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    order_by: {end_time: desc}
  ) {
    ...EnglishAuctionLight
    token {
      ...TokenDefault
    }
  }
  dutch_auction(
    where: {seller_address: {_eq: $address}, fa: {live: {_eq: true}}, currency_id: {_eq: 1}}
    order_by: {end_time: desc}
  ) {
    ...DutchAuctionLight
    token {
      ...TokenDefault
    }
  }
}
    ${IndexerEnglishAuctionLightFragmentDoc}
${IndexerTokenDefaultFragmentDoc}
${IndexerDutchAuctionLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserAuctionsGQL extends Apollo.Query<IndexerGetUserAuctionsQuery, IndexerGetUserAuctionsQueryVariables> {
    document = IndexergetUserAuctionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserOnlyAuctionsDocument = gql`
    query getUserOnlyAuctions($address: String!, $timestamp: timestamptz) {
  english_auction(
    where: {seller_address: {_eq: $address}, fa: {live: {_eq: true}}, token: {creators: {creator_address: {_neq: $address}}}, end_time: {_gt: $timestamp}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    order_by: {end_time: desc}
  ) {
    ...EnglishAuctionLight
    token {
      ...TokenDefault
    }
  }
  dutch_auction(
    where: {seller_address: {_eq: $address}, fa: {live: {_eq: true}}, token: {creators: {creator_address: {_neq: $address}}}, end_time: {_gt: $timestamp}, currency_id: {_eq: 1}}
    order_by: {end_time: desc}
  ) {
    ...DutchAuctionLight
    token {
      ...TokenDefault
    }
  }
}
    ${IndexerEnglishAuctionLightFragmentDoc}
${IndexerTokenDefaultFragmentDoc}
${IndexerDutchAuctionLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserOnlyAuctionsGQL extends Apollo.Query<IndexerGetUserOnlyAuctionsQuery, IndexerGetUserOnlyAuctionsQueryVariables> {
    document = IndexergetUserOnlyAuctionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetCreatorAuctionsDocument = gql`
    query getCreatorAuctions($address: String!) {
  english_auction: english_auction_active(
    where: {token: {creators: {creator_address: {_eq: $address}}}, fa: {live: {_eq: true}}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    order_by: {end_time: desc}
  ) {
    ...EnglishAuctionCard
    token {
      ...AuctionToken
      ...ContentFilteringAttributes
    }
  }
  dutch_auction: dutch_auction_active(
    where: {token: {creators: {creator_address: {_eq: $address}}}, fa: {live: {_eq: true}}, currency_id: {_eq: 1}}
    order_by: {end_time: desc}
  ) {
    ...DutchAuctionCard
    token {
      ...AuctionToken
      ...ContentFilteringAttributes
    }
  }
}
    ${IndexerEnglishAuctionCardFragmentDoc}
${IndexerAuctionTokenFragmentDoc}
${IndexerContentFilteringAttributesFragmentDoc}
${IndexerDutchAuctionCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetCreatorAuctionsGQL extends Apollo.Query<IndexerGetCreatorAuctionsQuery, IndexerGetCreatorAuctionsQueryVariables> {
    document = IndexergetCreatorAuctionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetContractAuctionsDocument = gql`
    query getContractAuctions($fa2: String!, $timestamp: timestamptz, $limit: Int = 100) {
  english_auction(
    where: {fa_contract: {_eq: $fa2}, end_time: {_gt: $timestamp}, status: {_eq: "active"}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...EnglishAuctionLight
    token {
      ...TokenDefault
    }
  }
  dutch_auction(
    where: {fa_contract: {_eq: $fa2}, end_time: {_gt: $timestamp}, status: {_eq: "active"}, currency_id: {_eq: 1}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...DutchAuctionLight
    token {
      ...TokenDefault
    }
  }
}
    ${IndexerEnglishAuctionLightFragmentDoc}
${IndexerTokenDefaultFragmentDoc}
${IndexerDutchAuctionLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetContractAuctionsGQL extends Apollo.Query<IndexerGetContractAuctionsQuery, IndexerGetContractAuctionsQueryVariables> {
    document = IndexergetContractAuctionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetAuctionCountsBySellerDocument = gql`
    query GetAuctionCountsBySeller($address: String!) {
  dutch_auction_aggregate(
    where: {seller_address: {_eq: $address}, status: {_eq: "active"}, end_time: {_gt: now}, currency_id: {_eq: 1}, fa: {live: {_eq: true}}}
  ) {
    aggregate {
      count
    }
  }
  english_auction_aggregate(
    where: {seller_address: {_eq: $address}, status: {_eq: "active"}, end_time: {_gt: now}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}, fa: {live: {_eq: true}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAuctionCountsBySellerGQL extends Apollo.Query<IndexerGetAuctionCountsBySellerQuery, IndexerGetAuctionCountsBySellerQueryVariables> {
    document = IndexerGetAuctionCountsBySellerDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetAuctionCountsByCreatorDocument = gql`
    query GetAuctionCountsByCreator($address: String!) {
  dutch_auction_aggregate: dutch_auction_active_aggregate(
    where: {token: {creators: {creator_address: {_eq: $address}}}, currency_id: {_eq: 1}, fa: {live: {_eq: true}}}
  ) {
    aggregate {
      count
    }
  }
  english_auction_aggregate: english_auction_active_aggregate(
    where: {token: {creators: {creator_address: {_eq: $address}}}, currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}, fa: {live: {_eq: true}}}
  ) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAuctionCountsByCreatorGQL extends Apollo.Query<IndexerGetAuctionCountsByCreatorQuery, IndexerGetAuctionCountsByCreatorQueryVariables> {
    document = IndexerGetAuctionCountsByCreatorDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetTokenBalanceDocument = gql`
    query getTokenBalance($address: String!, $tokenContract: String!) {
  token_holder(
    where: {holder: {address: {_eq: $address}}, token: {fa_contract: {_eq: $tokenContract}}}
  ) {
    quantity
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetTokenBalanceGQL extends Apollo.Query<IndexerGetTokenBalanceQuery, IndexerGetTokenBalanceQueryVariables> {
    document = IndexergetTokenBalanceDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetCollectionMetaDataDocument = gql`
    query getCollectionMetaData($fa2: String!) {
  token_holder_aggregate(
    distinct_on: holder_address
    where: {token: {fa_contract: {_eq: $fa2}}}
  ) {
    aggregate {
      count
    }
  }
  token_aggregate(where: {fa_contract: {_eq: $fa2}}) {
    aggregate {
      count
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetCollectionMetaDataGQL extends Apollo.Query<IndexerGetCollectionMetaDataQuery, IndexerGetCollectionMetaDataQueryVariables> {
    document = IndexergetCollectionMetaDataDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetEventsDocument = gql`
    query getEvents($where: event_bool_exp!, $order_by: [event_order_by!] = {}, $limit: Int = 100, $offset: Int = 0) {
  event(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...EventDefault
  }
}
    ${IndexerEventDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetEventsGQL extends Apollo.Query<IndexerGetEventsQuery, IndexerGetEventsQueryVariables> {
    document = IndexergetEventsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetEventsHistoryDocument = gql`
    query getEventsHistory($where: event_bool_exp!, $order_by: [event_order_by!] = {}, $limit: Int = 100, $offset: Int = 0) {
  event(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...EventHistory
  }
}
    ${IndexerEventHistoryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetEventsHistoryGQL extends Apollo.Query<IndexerGetEventsHistoryQuery, IndexerGetEventsHistoryQueryVariables> {
    document = IndexergetEventsHistoryDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetEventsLiveDocument = gql`
    query getEventsLive($where: event_bool_exp!, $order_by: [event_order_by!] = {}, $limit: Int = 100, $offset: Int = 0) {
  event(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...EventLive
  }
}
    ${IndexerEventLiveFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetEventsLiveGQL extends Apollo.Query<IndexerGetEventsLiveQuery, IndexerGetEventsLiveQueryVariables> {
    document = IndexergetEventsLiveDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetEventsMultiDocument = gql`
    query getEventsMulti($where_common: event_bool_exp!, $where_a: event_bool_exp!, $where_b: event_bool_exp!, $order_by: [event_order_by!] = {}, $limit: Int = 100, $offset: Int = 0) {
  a: event(
    order_by: $order_by
    limit: $limit
    offset: $offset
    where: {_and: [$where_common, $where_a]}
  ) {
    ...EventDefault
  }
  b: event(
    order_by: $order_by
    limit: $limit
    offset: $offset
    where: {_and: [$where_common, $where_b]}
  ) {
    ...EventDefault
  }
}
    ${IndexerEventDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetEventsMultiGQL extends Apollo.Query<IndexerGetEventsMultiQuery, IndexerGetEventsMultiQueryVariables> {
    document = IndexergetEventsMultiDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetExploreFaDocument = gql`
    query GetExploreFa($where: fa_bool_exp = {}, $order_by: [fa_order_by!] = {}, $limit: Int = null, $offset: Int = null, $tokenLimit: Int = 5) {
  fa(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    tokens(limit: $tokenLimit, where: {supply: {_gt: 0}}) {
      ...TokenExplore
    }
    logo
    name
    path
    contract
    items
    volume_total
    floor_price
    owners
    creator {
      address
      alias
      tzdomain
      logo
    }
  }
  fa_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${IndexerTokenExploreFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetExploreFaGQL extends Apollo.Query<IndexerGetExploreFaQuery, IndexerGetExploreFaQueryVariables> {
    document = IndexerGetExploreFaDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetExploreFaTokensDocument = gql`
    query getExploreFaTokens($where: fa_bool_exp = {}, $order_by: [fa_order_by!] = {}, $limit: Int = null, $offset: Int = null, $tokenLimit: Int = 3) {
  fa(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    contract
    items
    owners
    tokens(limit: $tokenLimit, where: {supply: {_gt: 0}}) {
      ...TokenExplore
    }
  }
}
    ${IndexerTokenExploreFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetExploreFaTokensGQL extends Apollo.Query<IndexerGetExploreFaTokensQuery, IndexerGetExploreFaTokensQueryVariables> {
    document = IndexergetExploreFaTokensDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetFa2Document = gql`
    query getFa2($contract: String!) {
  fa_by_pk(contract: $contract) {
    ...Fa
  }
}
    ${IndexerFaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFa2GQL extends Apollo.Query<IndexerGetFa2Query, IndexerGetFa2QueryVariables> {
    document = IndexergetFa2Document;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetFa2PathDocument = gql`
    query getFa2Path($path: String!) {
  fa(where: {path: {_eq: $path}}) {
    ...Fa
  }
}
    ${IndexerFaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFa2PathGQL extends Apollo.Query<IndexerGetFa2PathQuery, IndexerGetFa2PathQueryVariables> {
    document = IndexergetFa2PathDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetFa2ByCollectionIdDocument = gql`
    query getFa2ByCollectionId($collectionId: String) {
  fa(where: {collection_id: {_eq: $collectionId}}) {
    ...Fa
  }
}
    ${IndexerFaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFa2ByCollectionIdGQL extends Apollo.Query<IndexerGetFa2ByCollectionIdQuery, IndexerGetFa2ByCollectionIdQueryVariables> {
    document = IndexergetFa2ByCollectionIdDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserActionableCollectionsDocument = gql`
    query getUserActionableCollections($address: String!) {
  fa(
    where: {_or: [{creator_address: {_eq: $address}}, {collaborators: {status: {_eq: "accepted"}, collaborator_address: {_eq: $address}}}]}
  ) {
    ...FaLight
    creator {
      ...UserDefault
    }
    collaborators(where: {status: {_eq: "accepted"}}) {
      ...Invitation
    }
  }
}
    ${IndexerFaLightFragmentDoc}
${IndexerUserDefaultFragmentDoc}
${IndexerInvitationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserActionableCollectionsGQL extends Apollo.Query<IndexerGetUserActionableCollectionsQuery, IndexerGetUserActionableCollectionsQueryVariables> {
    document = IndexergetUserActionableCollectionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserCollectionsDocument = gql`
    query getUserCollections($address: String!) {
  fa(
    where: {_or: [{tokens: {creators: {creator_address: {_eq: $address}, verified: {_eq: true}}}}, {creator_address: {_eq: $address}}]}
  ) {
    collection_id
    collection_type
    contract
    name
    path
    logo
    live
    tokens_aggregate(
      where: {creators: {creator_address: {_eq: $address}}, supply: {_gt: "0"}}
    ) {
      aggregate {
        count
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserCollectionsGQL extends Apollo.Query<IndexerGetUserCollectionsQuery, IndexerGetUserCollectionsQueryVariables> {
    document = IndexergetUserCollectionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserOwnedCollectionsDocument = gql`
    query getUserOwnedCollections($address: String!) {
  fa(
    distinct_on: contract
    where: {tokens: {holders: {holder_address: {_eq: $address}, quantity: {_gt: "0"}}}}
  ) {
    ...FaLight
  }
}
    ${IndexerFaLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserOwnedCollectionsGQL extends Apollo.Query<IndexerGetUserOwnedCollectionsQuery, IndexerGetUserOwnedCollectionsQueryVariables> {
    document = IndexergetUserOwnedCollectionsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetAllFa2Document = gql`
    query getAllFa2($where: fa_bool_exp = {}, $order_by: [fa_order_by!] = {}, $limit: Int = null, $offset: Int = null) {
  fa(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...FaLight
  }
}
    ${IndexerFaLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAllFa2GQL extends Apollo.Query<IndexerGetAllFa2Query, IndexerGetAllFa2QueryVariables> {
    document = IndexergetAllFa2Document;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetAllFa2WithAggregateDocument = gql`
    query getAllFa2WithAggregate($where: fa_bool_exp = {}, $order_by: [fa_order_by!] = {}, $limit: Int = null, $offset: Int = null) {
  fa(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...FaLight
  }
  fa_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${IndexerFaLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAllFa2WithAggregateGQL extends Apollo.Query<IndexerGetAllFa2WithAggregateQuery, IndexerGetAllFa2WithAggregateQueryVariables> {
    document = IndexergetAllFa2WithAggregateDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetFa2ManagersDocument = gql`
    query getFa2Managers($contract: String!) {
  fa_by_pk(contract: $contract) {
    managers {
      ...Manager
    }
  }
}
    ${IndexerManagerFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFa2ManagersGQL extends Apollo.Query<IndexerGetFa2ManagersQuery, IndexerGetFa2ManagersQueryVariables> {
    document = IndexergetFa2ManagersDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetFa2AttributesDocument = gql`
    query getFa2Attributes($where: fa2_attribute_count_bool_exp!) {
  fa2_attribute_count(where: $where) {
    id
    editions
    tokens
    attribute_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFa2AttributesGQL extends Apollo.Query<IndexerGetFa2AttributesQuery, IndexerGetFa2AttributesQueryVariables> {
    document = IndexergetFa2AttributesDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetFaCollectorsDocument = gql`
    query GetFaCollectors($contract: String!) {
  fa(where: {contract: {_eq: $contract}}) {
    tokens {
      holders(distinct_on: holder_address, where: {quantity: {_gt: "0"}}) {
        quantity
        holder_address
        holder {
          address
          alias
          tzdomain
          twitter
          website
          discord
        }
      }
    }
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFaCollectorsGQL extends Apollo.Query<IndexerGetFaCollectorsQuery, IndexerGetFaCollectorsQueryVariables> {
    document = IndexerGetFaCollectorsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetFaFloorPriceDocument = gql`
    query GetFaFloorPrice($contract: String!) {
  fa_by_pk(contract: $contract) {
    floor_price
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFaFloorPriceGQL extends Apollo.Query<IndexerGetFaFloorPriceQuery, IndexerGetFaFloorPriceQueryVariables> {
    document = IndexerGetFaFloorPriceDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetFa2UnverifiedCreatorDocument = gql`
    query getFa2UnverifiedCreator($currentUser: String!) {
  fa(
    where: {tokens: {creators: {creator_address: {_eq: $currentUser}, verified: {_eq: false}}}}
  ) {
    ...Fa
  }
}
    ${IndexerFaFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFa2UnverifiedCreatorGQL extends Apollo.Query<IndexerGetFa2UnverifiedCreatorQuery, IndexerGetFa2UnverifiedCreatorQueryVariables> {
    document = IndexergetFa2UnverifiedCreatorDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetGalleryDocument = gql`
    query getGallery($pk: bigint!) {
  gallery_by_pk(pk: $pk) {
    ...Gallery
  }
}
    ${IndexerGalleryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetGalleryGQL extends Apollo.Query<IndexerGetGalleryQuery, IndexerGetGalleryQueryVariables> {
    document = IndexergetGalleryDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetGalleryBySlugDocument = gql`
    query getGalleryBySlug($registrySlug: String!, $gallerySlug: String!) {
  gallery(
    where: {slug: {_eq: $gallerySlug}, registry: {slug: {_eq: $registrySlug}}}
  ) {
    ...Gallery
  }
}
    ${IndexerGalleryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetGalleryBySlugGQL extends Apollo.Query<IndexerGetGalleryBySlugQuery, IndexerGetGalleryBySlugQueryVariables> {
    document = IndexergetGalleryBySlugDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetGalleryPkBySlugDocument = gql`
    query getGalleryPKBySlug($registrySlug: String!, $gallerySlug: String!) {
  gallery(
    where: {slug: {_eq: $gallerySlug}, registry: {slug: {_eq: $registrySlug}}}
  ) {
    pk
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetGalleryPkBySlugGQL extends Apollo.Query<IndexerGetGalleryPkBySlugQuery, IndexerGetGalleryPkBySlugQueryVariables> {
    document = IndexergetGalleryPkBySlugDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetAllGalleriesDocument = gql`
    query getAllGalleries($where: gallery_bool_exp = {}, $order_by: [gallery_order_by!] = {}, $limit: Int = null, $offset: Int = null) {
  gallery(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...Gallery
  }
}
    ${IndexerGalleryFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAllGalleriesGQL extends Apollo.Query<IndexerGetAllGalleriesQuery, IndexerGetAllGalleriesQueryVariables> {
    document = IndexergetAllGalleriesDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetAllGalleriesLightDocument = gql`
    query getAllGalleriesLight($where: gallery_bool_exp = {}, $order_by: [gallery_order_by!] = {}, $limit: Int = null, $offset: Int = null) {
  gallery(where: $where, order_by: $order_by, limit: $limit, offset: $offset) {
    ...GalleryLight
  }
}
    ${IndexerGalleryLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAllGalleriesLightGQL extends Apollo.Query<IndexerGetAllGalleriesLightQuery, IndexerGetAllGalleriesLightQueryVariables> {
    document = IndexergetAllGalleriesLightDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetGalleryAttributesDocument = gql`
    query getGalleryAttributes($where: gallery_attribute_count_bool_exp!) {
  gallery_attribute_count(where: $where) {
    id
    editions
    tokens
    attribute_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetGalleryAttributesGQL extends Apollo.Query<IndexerGetGalleryAttributesQuery, IndexerGetGalleryAttributesQueryVariables> {
    document = IndexergetGalleryAttributesDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetInvitationsDocument = gql`
    query getInvitations($where: invitation_bool_exp!, $limit: Int = 20) {
  invitation(where: $where, limit: $limit) {
    ...Invitation
    fa {
      ...FaLight
      creator {
        ...UserDefault
      }
      collaborators {
        ...Invitation
      }
    }
  }
}
    ${IndexerInvitationFragmentDoc}
${IndexerFaLightFragmentDoc}
${IndexerUserDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetInvitationsGQL extends Apollo.Query<IndexerGetInvitationsQuery, IndexerGetInvitationsQueryVariables> {
    document = IndexergetInvitationsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetHangingListingsDocument = gql`
    query getHangingListings($sellerAddress: String!) {
  listing(
    where: {seller_address: {_eq: $sellerAddress}, marketplace_contract: {_eq: "KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC"}, status: {_eq: "active"}, seller: {owner_operators: {allowed: {_eq: true}, owner_address: {_eq: $sellerAddress}}}, token: {holders: {holder_address: {_eq: $sellerAddress}, quantity: {_lte: "0"}}, supply: {_gt: "0"}}}
  ) {
    ...HangingListing
  }
}
    ${IndexerHangingListingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetHangingListingsGQL extends Apollo.Query<IndexerGetHangingListingsQuery, IndexerGetHangingListingsQueryVariables> {
    document = IndexergetHangingListingsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserListingsDocument = gql`
    query getUserListings($address: String!) {
  listing(
    order_by: {id: desc}
    where: {timestamp: {_is_null: false}, seller_address: {_eq: $address}, status: {_eq: "active"}}
  ) {
    ...UserListing
  }
}
    ${IndexerUserListingFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserListingsGQL extends Apollo.Query<IndexerGetUserListingsQuery, IndexerGetUserListingsQueryVariables> {
    document = IndexergetUserListingsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetObjktDetailedDocument = gql`
    query getObjktDetailed($tokenId: String!, $fa2: String!, $supportedThirdpartyContracts: [String!]) {
  token(
    where: {token_id: {_eq: $tokenId}, fa_contract: {_eq: $fa2}, flag: {_neq: "removed"}}
  ) {
    ...TokenDefault
    ...TokenHolders
    english_auctions: english_auctions_active(
      where: {currency: {fa_contract: {_eq: "KT1TjnZYs5CGLbmV6yuW169P8Pnr9BiVwwjz"}}}
    ) {
      ...ObjktDetailedEnglishAuction
    }
    dutch_auctions: dutch_auctions_active(where: {currency_id: {_eq: 1}}) {
      ...ObjktDetailedDutchAuction
    }
    offers: offers_active(order_by: {price: desc}, where: {currency_id: {_eq: 1}}) {
      ...ObjktDetailedOffer
    }
    tags {
      tag {
        name
      }
    }
    listings: listings_active(
      order_by: {price: asc}
      where: {price: {_gte: 0}, amount_left: {_gt: 0}, currency_id: {_eq: 1}, _or: [{seller: {owner_operators: {token: {fa_contract: {_eq: $fa2}, token_id: {_eq: $tokenId}}, allowed: {_eq: true}, operator_address: {_eq: "KT1WvzYHCNBvDSdwafTHv7nJ1dWmZ8GCYuuC"}}, held_tokens: {quantity: {_gt: "0"}, token: {fa_contract: {_eq: $fa2}, token_id: {_eq: $tokenId}}}}}, {marketplace_contract: {_eq: "KT1FvqJwEDWb1Gwc55Jd1jjTHRVWbYKUUpyq"}}, {marketplace_contract: {_in: $supportedThirdpartyContracts}}]}
    ) {
      ...ObjktDetailedListing
    }
    open_edition {
      ...OpenEdition
    }
  }
  offer: offer_active(
    order_by: {price: desc}
    where: {collection_offer: {_eq: $fa2}, currency_id: {_eq: 1}}
  ) {
    ...ObjktDetailedOffer
  }
}
    ${IndexerTokenDefaultFragmentDoc}
${IndexerTokenHoldersFragmentDoc}
${IndexerObjktDetailedEnglishAuctionFragmentDoc}
${IndexerObjktDetailedDutchAuctionFragmentDoc}
${IndexerObjktDetailedOfferFragmentDoc}
${IndexerObjktDetailedListingFragmentDoc}
${IndexerOpenEditionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktDetailedGQL extends Apollo.Query<IndexerGetObjktDetailedQuery, IndexerGetObjktDetailedQueryVariables> {
    document = IndexergetObjktDetailedDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetObjktSimpleDocument = gql`
    query getObjktSimple($tokenId: String!, $fa2: String!) {
  token(
    where: {token_id: {_eq: $tokenId}, fa_contract: {_eq: $fa2}, flag: {_neq: "removed"}}
  ) {
    ...TokenDefault
  }
}
    ${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktSimpleGQL extends Apollo.Query<IndexerGetObjktSimpleQuery, IndexerGetObjktSimpleQueryVariables> {
    document = IndexergetObjktSimpleDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetLatestTokenIdDocument = gql`
    query getLatestTokenId($fa2: String!) {
  token(where: {fa_contract: {_eq: $fa2}}, order_by: {timestamp: desc}, limit: 1) {
    token_id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetLatestTokenIdGQL extends Apollo.Query<IndexerGetLatestTokenIdQuery, IndexerGetLatestTokenIdQueryVariables> {
    document = IndexergetLatestTokenIdDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetObjktsSimpleDocument = gql`
    query getObjktsSimple($where: token_bool_exp = {}, $order_by: [token_order_by!] = {}) {
  token(where: $where, order_by: $order_by) {
    ...TokenDefault
  }
}
    ${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktsSimpleGQL extends Apollo.Query<IndexerGetObjktsSimpleQuery, IndexerGetObjktsSimpleQueryVariables> {
    document = IndexergetObjktsSimpleDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetHomepageSelectionDocument = gql`
    query getHomepageSelection($tokenIds: [String!], $fa2: String!) {
  token(where: {token_id: {_in: $tokenIds}, fa_contract: {_eq: $fa2}}) {
    ...TokenDefault
  }
}
    ${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetHomepageSelectionGQL extends Apollo.Query<IndexerGetHomepageSelectionQuery, IndexerGetHomepageSelectionQueryVariables> {
    document = IndexergetHomepageSelectionDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetObjktsByHolderPagedDocument = gql`
    query GetObjktsByHolderPaged($limit: Int!, $offset: Int!, $where: token_holder_bool_exp = {}, $order_by: [token_holder_order_by!] = {}) {
  token_holder(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    quantity
    token {
      ...TokenCard
      royalties {
        ...Royalties
      }
    }
  }
  token_holder_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${IndexerTokenCardFragmentDoc}
${IndexerRoyaltiesFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktsByHolderPagedGQL extends Apollo.Query<IndexerGetObjktsByHolderPagedQuery, IndexerGetObjktsByHolderPagedQueryVariables> {
    document = IndexerGetObjktsByHolderPagedDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetObjktsPagedDocument = gql`
    query GetObjktsPaged($where: token_bool_exp = {}, $order_by: [token_order_by!] = {}, $limit: Int!, $offset: Int!) {
  token(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...TokenCard
  }
  token_aggregate(where: $where) {
    aggregate {
      count
    }
  }
}
    ${IndexerTokenCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktsPagedGQL extends Apollo.Query<IndexerGetObjktsPagedQuery, IndexerGetObjktsPagedQueryVariables> {
    document = IndexerGetObjktsPagedDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetObjktsDocument = gql`
    query GetObjkts($where: token_bool_exp = {}, $order_by: [token_order_by!] = {}, $limit: Int!, $offset: Int!) {
  token(limit: $limit, offset: $offset, where: $where, order_by: $order_by) {
    ...TokenDefault
  }
}
    ${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktsGQL extends Apollo.Query<IndexerGetObjktsQuery, IndexerGetObjktsQueryVariables> {
    document = IndexerGetObjktsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetRecentlyListedObjktsDocument = gql`
    query getRecentlyListedObjkts($limit: Int!, $fa2: String!, $supportedContracts: [String!]) {
  token(
    where: {fa_contract: {_eq: $fa2}, supply: {_gt: 0}, flag: {_neq: "removed"}, listings: {timestamp: {_is_null: false}, currency_id: {_eq: 1}, marketplace_contract: {_in: $supportedContracts}}}
    order_by: {last_listed: desc}
    limit: $limit
  ) {
    ...TokenCard
  }
}
    ${IndexerTokenCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetRecentlyListedObjktsGQL extends Apollo.Query<IndexerGetRecentlyListedObjktsQuery, IndexerGetRecentlyListedObjktsQueryVariables> {
    document = IndexergetRecentlyListedObjktsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetOpenEditionEndingSoonDocument = gql`
    query getOpenEditionEndingSoon($limit: Int!, $timestamp: timestamptz) {
  token(
    where: {open_edition: {end_time: {_gt: $timestamp}}, flag: {_neq: "removed"}, creators: {creator_address: {_neq: "tz1XHhjLXQuG9rf9n7o1VbgegMkiggy1oktu"}}}
    order_by: {open_edition: {end_time: asc}}
    limit: $limit
  ) {
    ...TokenCard
    open_edition {
      ...OpenEdition
    }
  }
}
    ${IndexerTokenCardFragmentDoc}
${IndexerOpenEditionFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetOpenEditionEndingSoonGQL extends Apollo.Query<IndexerGetOpenEditionEndingSoonQuery, IndexerGetOpenEditionEndingSoonQueryVariables> {
    document = IndexergetOpenEditionEndingSoonDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetAllRecentlyListedObjktsDocument = gql`
    query getAllRecentlyListedObjkts($limit: Int!, $supportedContracts: [String!]) {
  listing(
    order_by: {timestamp: desc}
    limit: $limit
    where: {timestamp: {_is_null: false}, fa: {live: {_eq: true}}, token: {supply: {_gt: 0}, flag: {_neq: "removed"}, artifact_uri: {_neq: ""}, content_rating: {_is_null: true}}, currency_id: {_eq: 1}, marketplace_contract: {_in: $supportedContracts}}
  ) {
    token {
      ...TokenCard
    }
  }
}
    ${IndexerTokenCardFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetAllRecentlyListedObjktsGQL extends Apollo.Query<IndexerGetAllRecentlyListedObjktsQuery, IndexerGetAllRecentlyListedObjktsQueryVariables> {
    document = IndexergetAllRecentlyListedObjktsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserOffersDocument = gql`
    query getUserOffers($address: String!) {
  offer: offer_active(
    order_by: {id: desc}
    where: {timestamp: {_is_null: false}, buyer_address: {_eq: $address}}
  ) {
    ...UserOffer
    token {
      ...UserOfferToken
    }
  }
}
    ${IndexerUserOfferFragmentDoc}
${IndexerUserOfferTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserOffersGQL extends Apollo.Query<IndexerGetUserOffersQuery, IndexerGetUserOffersQueryVariables> {
    document = IndexergetUserOffersDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetUserBookmarksDocument = gql`
    query GetUserBookmarks($conditions: [token_bool_exp!] = []) {
  token(where: {_or: $conditions}) {
    ...TokenDefault
  }
}
    ${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserBookmarksGQL extends Apollo.Query<IndexerGetUserBookmarksQuery, IndexerGetUserBookmarksQueryVariables> {
    document = IndexerGetUserBookmarksDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetFeaturedCurationsDocument = gql`
    query GetFeaturedCurations($conditions: [token_bool_exp!]!) {
  token(where: {_or: $conditions}) {
    ...TokenCard
    highest_offer
    lowest_ask
    fa {
      ...FaLight
    }
  }
}
    ${IndexerTokenCardFragmentDoc}
${IndexerFaLightFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetFeaturedCurationsGQL extends Apollo.Query<IndexerGetFeaturedCurationsQuery, IndexerGetFeaturedCurationsQueryVariables> {
    document = IndexerGetFeaturedCurationsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetTokenMigrationOwnedTokensDocument = gql`
    query getTokenMigrationOwnedTokens($where: token_holder_bool_exp = {}, $order_by: [token_holder_order_by!] = {}) {
  token_holder(where: $where, order_by: $order_by) {
    token {
      ...TokenMigration
    }
  }
}
    ${IndexerTokenMigrationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetTokenMigrationOwnedTokensGQL extends Apollo.Query<IndexerGetTokenMigrationOwnedTokensQuery, IndexerGetTokenMigrationOwnedTokensQueryVariables> {
    document = IndexergetTokenMigrationOwnedTokensDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetTokenMigrationCreatedTokensDocument = gql`
    query getTokenMigrationCreatedTokens($creator: String!) {
  token(where: {creators: {creator_address: {_eq: $creator}}, supply: {_gt: 0}}) {
    ...TokenMigration
  }
}
    ${IndexerTokenMigrationFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetTokenMigrationCreatedTokensGQL extends Apollo.Query<IndexerGetTokenMigrationCreatedTokensQuery, IndexerGetTokenMigrationCreatedTokensQueryVariables> {
    document = IndexergetTokenMigrationCreatedTokensDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetObjktOneActiveTokensDocument = gql`
    query getObjktOneActiveTokens($limit: Int = 24) {
  english_auction(
    where: {token: {holders: {holder_address: {_eq: "KT1M6r2gRigUYP3tCSEjEptRnNG8qRLSRqcT"}, quantity: {_gt: "0"}}, flag: {_neq: "removed"}}, status: {_eq: "active"}, fa: {live: {_eq: true}}, fa_contract: {_eq: "KT1NvaAU5oqfvhBcapnE9BbSiWHNVVnKjmHB"}}
    order_by: {end_time: asc}
    limit: $limit
  ) {
    ...EnglishAuctionLight
    token {
      ...TokenDefault
    }
  }
}
    ${IndexerEnglishAuctionLightFragmentDoc}
${IndexerTokenDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetObjktOneActiveTokensGQL extends Apollo.Query<IndexerGetObjktOneActiveTokensQuery, IndexerGetObjktOneActiveTokensQueryVariables> {
    document = IndexergetObjktOneActiveTokensDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerOpenEditionTokensDocument = gql`
    query OpenEditionTokens($fa2: String!, $limit: Int!) {
  open_edition: open_edition_active(
    where: {fa_contract: {_eq: $fa2}, token: {flag: {_neq: "removed"}}}
    order_by: {end_time: desc}
    limit: $limit
  ) {
    end_time
    price
    start_time
    token {
      ...OpenEditionToken
    }
  }
}
    ${IndexerOpenEditionTokenFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerOpenEditionTokensGQL extends Apollo.Query<IndexerOpenEditionTokensQuery, IndexerOpenEditionTokensQueryVariables> {
    document = IndexerOpenEditionTokensDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexerGetManyTokenOperatorsDocument = gql`
    query GetManyTokenOperators($userAddress: String!, $orClauses: [token_operator_bool_exp!]!) {
  token_operator(
    where: {owner_address: {_eq: $userAddress}, allowed: {_eq: true}, _or: $orClauses}
  ) {
    token_pk
    owner_address
    operator_address
    id
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetManyTokenOperatorsGQL extends Apollo.Query<IndexerGetManyTokenOperatorsQuery, IndexerGetManyTokenOperatorsQueryVariables> {
    document = IndexerGetManyTokenOperatorsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetSalesStatsDocument = gql`
    query getSalesStats($type: sales_stat_type!, $days: Int, $limit: Int = 10) {
  sales_stat(
    where: {interval_days: {_eq: $days}, subject: {flag: {_eq: "none"}}, _and: {type: {_eq: $type}}}
    order_by: {rank: asc}
    limit: $limit
  ) {
    ...SalesStats
  }
}
    ${IndexerSalesStatsFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetSalesStatsGQL extends Apollo.Query<IndexerGetSalesStatsQuery, IndexerGetSalesStatsQueryVariables> {
    document = IndexergetSalesStatsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetTagsDocument = gql`
    query getTags($search: String!, $limit: Int = 20) {
  tag(
    limit: $limit
    where: {name: {_ilike: $search}}
    order_by: {token_count: desc}
  ) {
    name
  }
}
    `;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetTagsGQL extends Apollo.Query<IndexerGetTagsQuery, IndexerGetTagsQueryVariables> {
    document = IndexergetTagsDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUserDocument = gql`
    query getUser($address: String!) {
  holder_by_pk(address: $address) {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUserGQL extends Apollo.Query<IndexerGetUserQuery, IndexerGetUserQueryVariables> {
    document = IndexergetUserDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }
export const IndexergetUsersDocument = gql`
    query getUsers($where: holder_bool_exp, $limit: Int = 3) {
  holder(where: $where, limit: $limit) {
    ...UserDefault
  }
}
    ${IndexerUserDefaultFragmentDoc}`;

  @Injectable({
    providedIn: 'root'
  })
  export class IndexerGetUsersGQL extends Apollo.Query<IndexerGetUsersQuery, IndexerGetUsersQueryVariables> {
    document = IndexergetUsersDocument;
    client = 'indexer';
    constructor(apollo: Apollo.Apollo) {
      super(apollo);
    }
  }

export type IndexerToken_Order_By = IndexerToken_Order_By_single[] | IndexerToken_Order_By_single;
