import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {ColorPickerRgbaPipe} from './color-picker-rgba.pipe';

@NgModule({
  declarations: [ColorPickerRgbaPipe],
  exports: [ColorPickerRgbaPipe],
  imports: [CommonModule],
})
export class ColorPickerRgbaModule {}
