import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {SrcFallbackDirective} from './src-fallback.directive';

@NgModule({
  declarations: [SrcFallbackDirective],
  imports: [CommonModule],
  exports: [SrcFallbackDirective],
})
export class SrcFallbackModule {}
