import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {HangingListingsComponent} from './hanging-listings.component';
import {ModalModule} from '../modal.module';

@NgModule({
  declarations: [HangingListingsComponent],
  imports: [CommonModule, ModalModule],
  exports: [HangingListingsComponent],
})
export class HangingListingsModule {}
