import {NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {TransactionsPanelComponent} from './transactions-panel.component';
import {ShortAddressPipeModule} from '../../pipes/short-address/short-address-pipe.module';

@NgModule({
  declarations: [TransactionsPanelComponent],
  imports: [CommonModule, ShortAddressPipeModule],
  exports: [TransactionsPanelComponent],
})
export class TransactionsPanelModule {}
