import {Injectable} from '@angular/core';
import {Observable} from 'rxjs';
import {map} from 'rxjs/operators';
import {IndexerGetHangingListingsGQL, IndexerHangingListingFragment} from 'src/graphql/gen/indexer';

@Injectable({
  providedIn: 'root',
})
export class ListingApiService {
  constructor(private readonly hangingListings: IndexerGetHangingListingsGQL) {}

  getHangingListings(sellerAddress: string): Observable<IndexerHangingListingFragment[]> {
    return this.hangingListings.fetch({sellerAddress}).pipe(
      // Not using pluck as it hides things from TypeScript
      map(res => res.data.listing),
    );
  }
}
