import {QuickScore} from 'quick-score';
import {Injectable} from '@angular/core';
import {forkJoin, from, Observable, of} from 'rxjs';
import {
  catchError,
  defaultIfEmpty,
  map,
  mergeMap,
  switchMap,
  tap,
} from 'rxjs/operators';
import {
  IndexerGetUserGQL,
  IndexerGetUsersGQL,
  IndexerUserDefaultFragment,
} from 'src/graphql/gen/indexer';
import {BackendUserModel, UserModel} from 'src/app/types/types';
import {
  BackendGetCurrentUserGQL,
  BackendGetUserGQL,
} from 'src/graphql/gen/backend';

interface MetaDataHash {
  [key: string]: UserModel;
}

@Injectable({
  providedIn: 'root',
})
export class UserApiService {
  metaDataMap: MetaDataHash = {};
  metaDataMapUserName: MetaDataHash = {};

  constructor(
    readonly user: IndexerGetUserGQL,
    readonly users: IndexerGetUsersGQL,
    readonly backendGetCurrentUser: BackendGetCurrentUserGQL,
    readonly backendGetUser: BackendGetUserGQL,
  ) {}

  getCurrentBackendUserData(): Observable<BackendUserModel> {
    return this.backendGetCurrentUser
      .fetch(null, {
        fetchPolicy: 'no-cache',
      })
      .pipe(map(res => res.data.user));
  }

  getBackendUserData(address: string): Observable<BackendUserModel> {
    return this.backendGetUser
      .fetch(
        {address},
        {
          fetchPolicy: 'no-cache',
        },
      )
      .pipe(map(res => res.data.user));
  }

  getUserByName(name: string): Observable<UserModel | null> {
    const cachedUser = this.metaDataMapUserName[`${name}.tez`];
    return cachedUser
      ? of(cachedUser)
      : this.users
          .fetch({
            where: {
              tzdomain: {
                _eq: `${name}.tez`,
              },
            },
          })
          .pipe(
            map(res => res.data.holder),
            map(holders => holders.map(user => this.extendUser(user))[0]),
            map(user => this.extendUser(user)),
            switchMap(user => this.addAvatarToUser(user)),
            tap(user => {
              if (user) {
                this.metaDataMap[user.address] = user;
                this.metaDataMapUserName[user.tzdomain] = user;
              }
            }),
          );
  }

  getUser(address: string): Observable<UserModel | null> {
    const cachedUser = this.metaDataMap[address];
    return cachedUser
      ? of(cachedUser)
      : this.user.fetch({address}).pipe(
          map(res => res.data.holder_by_pk),
          map(user => this.extendUser(user)),
          switchMap(user => (user ? this.addAvatarToUser(user) : of(user))),
          tap(metaData => {
            this.metaDataMap[address] = metaData;
            if (metaData?.tzdomain) {
              this.metaDataMapUserName[metaData.tzdomain] = metaData;
            }
          }),
        );
  }

  getUsersByAddress(addresses: string[]): Observable<UserModel[]> {
    return this.users
      .fetch({where: {address: {_in: addresses}}, limit: 10000})
      .pipe(
        map(res => res.data.holder),
        map(users => users.map(user => this.extendUser(user))),
        switchMap(users =>
          forkJoin(users.map(user => this.addAvatarToUser(user))),
        ),
        defaultIfEmpty([]),
      );
  }

  getUsers(search: string, limit: number = 3): Observable<UserModel[]> {
    return this.users
      .fetch({
        where: {
          _or: [
            {alias: {_ilike: `%${search}%`}},
            {twitter: {_ilike: `%${search}%`}},
            {tzdomain: {_ilike: `%${search}%`}},
            {address: {_eq: search}},
          ],
        },
        limit: limit + 10,
      })
      .pipe(
        map(result => {
          const qs = new QuickScore(result.data.holder, [
            'address',
            'alias',
            'twitter',
            'tzdomain',
          ]);

          return qs
            .search(search)
            .slice(0, limit)
            .map(({item}) => item);
        }),
        map(holders => holders.map(user => this.extendUser(user))),
        switchMap(holders =>
          forkJoin(holders.map(user => this.addAvatarToUser(user))),
        ),
        defaultIfEmpty([]),
      );
  }

  extendUser(user: IndexerUserDefaultFragment) {
    if (!user) return null;

    const u = {
      ...user,
      avatarFallback: this.getAvatar(user?.address),
    };

    // Man Utd hardcoded
    if (user?.address === 'tz2WbetYprSXdP5JfxEcYecmv7FmJKUNtByf') {
      u.alias = 'Manchester United Digital Collectibles';
      u.description = `Manchester United’s officially licensed Digital Collectibles, powered by Tezos and brought to you by Tezos ecosystem companies.`;
      u.twitter = 'https://twitter.com/CollectMUFC';
      u.website = 'https://collectibles.manutd.com';
    }

    // SuperChiefGallery hardcoded
    if (user?.address === 'tz1bxyQQt4sxQeoV8rEdnVJbZ8wB55AH4jPY') {
      u.alias = 'Superchief Gallery NFT';
      u.description = `Superchief Gallery is the World's First IRL NFT gallery. Established in March 25th, 2021 in NYC.`;
      u.twitter = 'https://twitter.com/SuperchiefNFT';
      u.website = 'https://www.superchiefgallerynft.com';
    }

    // objkt.com hardcoded
    if (user?.address === 'tz1TWrPXuG3T3rR9NR5EqsBegJMwiMcobjkt') {
      u.alias = 'objkt.com';
      u.description = `Official objkt.com wallet.`;
      u.twitter = 'https://twitter.com/objktcom';
      u.website = 'https://www.objkt.com';
    }

    // Dogami
    if (user?.address === 'tz1bq5QazD43hBGxYxX8mv4sa1r1kz5sRGWz') {
      u.alias = 'DOGAMÍ';
      u.description =
        'DOGAMÍ is a unique play-to-earn blockchain game where you adopt and raise virtual dog NFTs';
      u.twitter = 'https://twitter.com/Dogami';
      u.website = 'https://dogami.com';
    }

    // McLaren
    if (user?.address === 'tz2W1hS4DURJckg7iZaLXL18kh8C3SJuUaxv') {
      u.alias = 'McLaren Racing Digital Collectibles';
      u.description =
        'McLaren Racing’s officially licensed Digital Collectibles, powered by Tezos and brought to you by Tezos ecosystem companies.';
      u.twitter = 'https://twitter.com/McLarenF1';
      u.website = 'https://collectibles.mclaren.com/';
    }

    return u;
  }

  private getAvatar(address: string) {
    // Man Utd hardcoded
    if (address === 'tz2WbetYprSXdP5JfxEcYecmv7FmJKUNtByf') {
      return 'https://assets.objkt.media/file/assets-002/collection-logos/mu_crest.png';
    }
    // Ubisoft hardcoded
    if (address === 'tz1hw5cpeoYgmB7dCSfJEnCTMafaqTD1Mdme') {
      return 'https://assets.objkt.media/file/assets-002/collection-logos/ubisoftquartz_creator.png';
    }
    // SuperChiefGallery hardcoded
    if (address === 'tz1bxyQQt4sxQeoV8rEdnVJbZ8wB55AH4jPY') {
      return 'https://assets.objkt.media/file/assets-002/collection-logos/SuperChiefGallery.png';
    }
    // objkt.com
    if (address === 'tz1TWrPXuG3T3rR9NR5EqsBegJMwiMcobjkt') {
      return 'https://assets.objkt.media/file/assets-002/collection-logos/objkt.png';
    }
    // Dogami hardcoded
    if (address === 'tz1bq5QazD43hBGxYxX8mv4sa1r1kz5sRGWz') {
      return 'https://assets.objkt.media/file/assets-002/collection-logos/dogami_user.png';
    }
    // McLaren hardcoded
    if (address === 'tz2W1hS4DURJckg7iZaLXL18kh8C3SJuUaxv') {
      return 'https://assets.objkt.media/file/assets-002/collection-logos/mclaren.png';
    }
    return `https://services.tzkt.io/v1/avatars2/${address}`;
  }

  addAvatarToUser(user: UserModel): Observable<UserModel> {
    return this.getAvatarCdn(user?.logo).pipe(
      map(avatar => {
        return {
          ...user,
          avatar,
        };
      }),
    );
  }

  private getAvatarCdn(url: string) {
    if (url?.startsWith('https://') || url?.startsWith('http://')) {
      return this.digestMessage(url).pipe(
        map(digestHex => `hex://${digestHex}`),
      );
    }
    return of(url);
  }

  private digestMessage(message) {
    const msgUint8 = new TextEncoder().encode(message);
    return from(crypto.subtle.digest('SHA-1', msgUint8)).pipe(
      map(hashBuffer => {
        const hashArray = Array.from(new Uint8Array(hashBuffer));
        return hashArray.map(b => b.toString(16).padStart(2, '0')).join('');
      }),
      catchError(e => of(message)),
    );
  }
}
