import {Component, OnInit} from '@angular/core';
import {EMPTY} from 'rxjs';
import {mergeMap, switchMap} from 'rxjs/operators';
import {SigningService} from '../../services/tezos/signing.service';

@Component({
  selector: 'app-login-message',
  templateUrl: './login-message.component.html',
  styleUrls: ['./login-message.component.scss'],
})
export class LoginMessageComponent implements OnInit {
  constructor(readonly signingService: SigningService) {}

  ngOnInit(): void {}

  loginBackend() {
    const login = this.signingService
      .permissionedCall()
      .pipe(
        mergeMap(hasPermission => {
          if (hasPermission) {
            return this.signingService.login();
          }

          throw new Error('no permission');
        }),
      )
      .subscribe(
        () => {
          window.location.reload();
          login.unsubscribe();
        },
        () => login.unsubscribe(),
      );
  }
}
