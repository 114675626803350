import {gql} from 'apollo-angular';
import {Injectable} from '@angular/core';
import * as Apollo from 'apollo-angular';
export type Maybe<T> = T | null;
export type Exact<T extends {[key: string]: unknown}> = {[K in keyof T]: T[K]};
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]?: Maybe<T[SubKey]>};
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & {[SubKey in K]: Maybe<T[SubKey]>};
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: string;
  String: string;
  Boolean: boolean;
  Int: number;
  Float: number;
  /**
   * The `Json` scalar type represents arbitrary json string data, represented as UTF-8
   * character sequences. The Json type is most often used to represent a free-form
   * human-readable json string.
   */
  Json: any;
};

export type BackendActivity = {
  __typename?: 'Activity';
  actor?: Maybe<Scalars['Json']>;
  object?: Maybe<Scalars['Json']>;
  verb?: Maybe<Scalars['String']>;
};

export type BackendBookmarked = {
  __typename?: 'Bookmarked';
  bookmarked?: Maybe<Scalars['Boolean']>;
};

export type BackendCreator = {
  __typename?: 'Creator';
  contract?: Maybe<Scalars['String']>;
  creator?: Maybe<Scalars['String']>;
  verificationType?: Maybe<Scalars['String']>;
};

export type BackendCreators = {
  __typename?: 'Creators';
  creators?: Maybe<Array<Maybe<BackendCreator>>>;
};

export type BackendError = {
  __typename?: 'Error';
  error?: Maybe<Scalars['String']>;
  field?: Maybe<Scalars['String']>;
};

export type BackendFeedItem = {
  __typename?: 'FeedItem';
  activity?: Maybe<BackendActivity>;
  id?: Maybe<Scalars['ID']>;
  unread?: Maybe<Scalars['Boolean']>;
};

export type BackendFollowing = {
  __typename?: 'Following';
  following?: Maybe<Scalars['Boolean']>;
};

export type BackendHidden = {
  __typename?: 'Hidden';
  hidden?: Maybe<Scalars['Boolean']>;
};

export type BackendManagers = {
  __typename?: 'Managers';
  managers?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BackendMetadataResult = {
  __typename?: 'MetadataResult';
  errors?: Maybe<Array<Maybe<BackendError>>>;
  metadata?: Maybe<Scalars['Json']>;
};

export type BackendPageInfo = {
  __typename?: 'PageInfo';
  page?: Maybe<Scalars['Int']>;
  totalPages?: Maybe<Scalars['Int']>;
};

export type BackendPaginatedFeed = {
  __typename?: 'PaginatedFeed';
  meta?: Maybe<BackendPageInfo>;
  results?: Maybe<Array<Maybe<BackendFeedItem>>>;
};

export type BackendReport = {
  __typename?: 'Report';
  address?: Maybe<Scalars['String']>;
  description?: Maybe<Scalars['String']>;
  faContract?: Maybe<Scalars['String']>;
  reason?: Maybe<BackendReportReasonType>;
  tokenId?: Maybe<Scalars['String']>;
};

export enum BackendReportReasonType {
  CopymintedMine = 'COPYMINTED_MINE',
  CopymintedOther = 'COPYMINTED_OTHER',
  IllegalOffensiveAbusive = 'ILLEGAL_OFFENSIVE_ABUSIVE',
  Other = 'OTHER',
}

export type BackendRootMutationType = {
  __typename?: 'RootMutationType';
  /** Un/Bookmark a token */
  bookmarkToken?: Maybe<BackendBookmarked>;
  /** Un/Hide a collection */
  collectionHide?: Maybe<BackendHidden>;
  /** Un/Follow a user */
  follow?: Maybe<BackendFollowing>;
  /** Mark all notifications as read */
  markAllRead?: Maybe<BackendUpdateResult>;
  /** Report a token */
  reportToken?: Maybe<BackendReport>;
  /** Report a user */
  reportUser?: Maybe<BackendReport>;
  /** Override FA2 metadata */
  updateContract?: Maybe<BackendMetadataResult>;
  /** Create/update FA2 creator verification_type */
  updateCreator?: Maybe<BackendCreator>;
  /** Add/Remove FA2 managers */
  updateManagers?: Maybe<BackendManagers>;
  /** Mark a notification as read */
  updateUnread?: Maybe<BackendUpdateResult>;
  /** Update user settings */
  user?: Maybe<BackendUser>;
};

export type BackendRootMutationTypeBookmarkTokenArgs = {
  bookmarked: Scalars['Boolean'];
  contract: Scalars['String'];
  tokenId: Scalars['String'];
};

export type BackendRootMutationTypeCollectionHideArgs = {
  contract: Scalars['String'];
  hidden: Scalars['Boolean'];
};

export type BackendRootMutationTypeFollowArgs = {
  address: Scalars['String'];
  follow: Scalars['Boolean'];
};

export type BackendRootMutationTypeReportTokenArgs = {
  description: Scalars['String'];
  faContract: Scalars['String'];
  reason: BackendReportReasonType;
  tokenId: Scalars['String'];
};

export type BackendRootMutationTypeReportUserArgs = {
  address: Scalars['String'];
  description: Scalars['String'];
  reason: BackendReportReasonType;
};

export type BackendRootMutationTypeUpdateContractArgs = {
  contract: Scalars['String'];
  metadata: Scalars['Json'];
};

export type BackendRootMutationTypeUpdateCreatorArgs = {
  contract: Scalars['String'];
  verificationType: Scalars['String'];
};

export type BackendRootMutationTypeUpdateManagersArgs = {
  add?: Maybe<Array<Maybe<Scalars['String']>>>;
  contract: Scalars['String'];
  remove?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BackendRootMutationTypeUpdateUnreadArgs = {
  ids: Array<Maybe<Scalars['ID']>>;
  unread: Scalars['Boolean'];
};

export type BackendRootMutationTypeUserArgs = {
  settings: Scalars['Json'];
};

export type BackendRootQueryType = {
  __typename?: 'RootQueryType';
  /** Get current user's verifiable fa2s */
  creatorVerifications?: Maybe<BackendCreators>;
  /** Check if current user is following address */
  following?: Maybe<BackendFollowing>;
  /** Get current user's notifications */
  notifications?: Maybe<BackendPaginatedFeed>;
  /** Get a user */
  user?: Maybe<BackendUser>;
};

export type BackendRootQueryTypeCreatorVerificationsArgs = {
  verificationTypes?: Maybe<Array<Maybe<Scalars['String']>>>;
};

export type BackendRootQueryTypeFollowingArgs = {
  address?: Maybe<Scalars['String']>;
};

export type BackendRootQueryTypeNotificationsArgs = {
  page: Scalars['Int'];
  unread?: Maybe<Scalars['Boolean']>;
};

export type BackendRootQueryTypeUserArgs = {
  address?: Maybe<Scalars['String']>;
};

export type BackendTokenBookmark = {
  __typename?: 'TokenBookmark';
  contract?: Maybe<Scalars['String']>;
  insertedAt?: Maybe<Scalars['String']>;
  tokenId?: Maybe<Scalars['String']>;
};

export type BackendUpdateResult = {
  __typename?: 'UpdateResult';
  updated?: Maybe<Scalars['Int']>;
};

export type BackendUser = {
  __typename?: 'User';
  address?: Maybe<Scalars['String']>;
  followeeCount?: Maybe<Scalars['Int']>;
  followees?: Maybe<Array<Maybe<BackendUser>>>;
  followerCount?: Maybe<Scalars['Int']>;
  followers?: Maybe<Array<Maybe<BackendUser>>>;
  level?: Maybe<Scalars['Int']>;
  settings?: Maybe<Scalars['Json']>;
  tokenBookmarks?: Maybe<Array<Maybe<BackendTokenBookmark>>>;
};

export type BackendGetBookmarksQueryVariables = Exact<{[key: string]: never}>;

export type BackendGetBookmarksQuery = {__typename?: 'RootQueryType'} & {
  user?: Maybe<
    {__typename?: 'User'} & {tokenBookmarks?: Maybe<Array<Maybe<{__typename?: 'TokenBookmark'} & Pick<BackendTokenBookmark, 'contract' | 'tokenId'>>>>}
  >;
};

export type BackendUpdateBookmarkMutationVariables = Exact<{
  contract: Scalars['String'];
  tokenId: Scalars['String'];
  bookmarked: Scalars['Boolean'];
}>;

export type BackendUpdateBookmarkMutation = {__typename?: 'RootMutationType'} & {
  bookmarkToken?: Maybe<{__typename?: 'Bookmarked'} & Pick<BackendBookmarked, 'bookmarked'>>;
};

export type BackendToggleIsHiddenFa2MutationVariables = Exact<{
  contract: Scalars['String'];
  hidden: Scalars['Boolean'];
}>;

export type BackendToggleIsHiddenFa2Mutation = {__typename?: 'RootMutationType'} & {
  collectionHide?: Maybe<{__typename?: 'Hidden'} & Pick<BackendHidden, 'hidden'>>;
};

export type BackendUpdateManagersFa2MutationVariables = Exact<{
  contract: Scalars['String'];
  add?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
  remove?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type BackendUpdateManagersFa2Mutation = {__typename?: 'RootMutationType'} & {
  updateManagers?: Maybe<{__typename?: 'Managers'} & Pick<BackendManagers, 'managers'>>;
};

export type BackendUpdateContractFa2MutationVariables = Exact<{
  contract: Scalars['String'];
  metadata: Scalars['Json'];
}>;

export type BackendUpdateContractFa2Mutation = {__typename?: 'RootMutationType'} & {
  updateContract?: Maybe<{__typename?: 'MetadataResult'} & Pick<BackendMetadataResult, 'metadata'>>;
};

export type BackendGetCreatorVerificationsQueryVariables = Exact<{
  verificationTypes?: Maybe<Array<Maybe<Scalars['String']>> | Maybe<Scalars['String']>>;
}>;

export type BackendGetCreatorVerificationsQuery = {__typename?: 'RootQueryType'} & {
  creatorVerifications?: Maybe<
    {__typename?: 'Creators'} & {creators?: Maybe<Array<Maybe<{__typename?: 'Creator'} & Pick<BackendCreator, 'contract' | 'creator' | 'verificationType'>>>>}
  >;
};

export type BackendUpdateCreatorVerificationMutationVariables = Exact<{
  contract: Scalars['String'];
  verificationType: Scalars['String'];
}>;

export type BackendUpdateCreatorVerificationMutation = {__typename?: 'RootMutationType'} & {
  updateCreator?: Maybe<{__typename?: 'Creator'} & Pick<BackendCreator, 'creator' | 'contract' | 'verificationType'>>;
};

export type BackendIsFollowingQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type BackendIsFollowingQuery = {__typename?: 'RootQueryType'} & {following?: Maybe<{__typename?: 'Following'} & Pick<BackendFollowing, 'following'>>};

export type BackendFollowMutationVariables = Exact<{
  address: Scalars['String'];
  follow?: Maybe<Scalars['Boolean']>;
}>;

export type BackendFollowMutation = {__typename?: 'RootMutationType'} & {follow?: Maybe<{__typename?: 'Following'} & Pick<BackendFollowing, 'following'>>};

export type BackendGetFollowCountsForSignedUserQueryVariables = Exact<{[key: string]: never}>;

export type BackendGetFollowCountsForSignedUserQuery = {__typename?: 'RootQueryType'} & {
  user?: Maybe<{__typename?: 'User'} & Pick<BackendUser, 'followeeCount' | 'followerCount'>>;
};

export type BackendGetFolloweeCountQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type BackendGetFolloweeCountQuery = {__typename?: 'RootQueryType'} & {user?: Maybe<{__typename?: 'User'} & Pick<BackendUser, 'followeeCount'>>};

export type BackendGetFolloweesQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type BackendGetFolloweesQuery = {__typename?: 'RootQueryType'} & {
  user?: Maybe<{__typename?: 'User'} & {followees?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>}>;
};

export type BackendGetFollowersForSignedUserQueryVariables = Exact<{[key: string]: never}>;

export type BackendGetFollowersForSignedUserQuery = {__typename?: 'RootQueryType'} & {
  user?: Maybe<
    {__typename?: 'User'} & Pick<BackendUser, 'followerCount' | 'followeeCount'> & {
        followers?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>;
      }
  >;
};

export type BackendActivityFragment = {__typename?: 'Activity'} & Pick<BackendActivity, 'actor' | 'object' | 'verb'>;

export type BackendNotificationFragment = {__typename?: 'FeedItem'} & Pick<BackendFeedItem, 'id' | 'unread'> & {
    activity?: Maybe<{__typename?: 'Activity'} & BackendActivityFragment>;
  };

export type BackendUserFragment = {__typename?: 'User'} & Pick<BackendUser, 'address' | 'followeeCount' | 'followerCount'> & {
    followees?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>;
    followers?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>;
  };

export type BackendNotificationPageFragment = {__typename?: 'PaginatedFeed'} & {
  meta?: Maybe<{__typename?: 'PageInfo'} & Pick<BackendPageInfo, 'page' | 'totalPages'>>;
  results?: Maybe<Array<Maybe<{__typename?: 'FeedItem'} & BackendNotificationFragment>>>;
};

export type BackendGetNotificationsPageQueryVariables = Exact<{
  page?: Maybe<Scalars['Int']>;
  unread?: Maybe<Scalars['Boolean']>;
}>;

export type BackendGetNotificationsPageQuery = {__typename?: 'RootQueryType'} & {
  notifications?: Maybe<{__typename?: 'PaginatedFeed'} & BackendNotificationPageFragment>;
};

export type BackendUpdateNotificationsMutationVariables = Exact<{
  ids: Array<Maybe<Scalars['ID']>> | Maybe<Scalars['ID']>;
  unread: Scalars['Boolean'];
}>;

export type BackendUpdateNotificationsMutation = {__typename?: 'RootMutationType'} & {
  updateUnread?: Maybe<{__typename?: 'UpdateResult'} & Pick<BackendUpdateResult, 'updated'>>;
};

export type BackendMarkAllNotificationsReadMutationVariables = Exact<{[key: string]: never}>;

export type BackendMarkAllNotificationsReadMutation = {__typename?: 'RootMutationType'} & {
  markAllRead?: Maybe<{__typename?: 'UpdateResult'} & Pick<BackendUpdateResult, 'updated'>>;
};

export type BackendReportTokenMutationVariables = Exact<{
  tokenId: Scalars['String'];
  faContract: Scalars['String'];
  reason: BackendReportReasonType;
  description: Scalars['String'];
}>;

export type BackendReportTokenMutation = {__typename?: 'RootMutationType'} & {
  reportToken?: Maybe<{__typename?: 'Report'} & Pick<BackendReport, 'tokenId' | 'faContract' | 'reason' | 'description'>>;
};

export type BackendReportUserMutationVariables = Exact<{
  address: Scalars['String'];
  reason: BackendReportReasonType;
  description: Scalars['String'];
}>;

export type BackendReportUserMutation = {__typename?: 'RootMutationType'} & {
  reportUser?: Maybe<{__typename?: 'Report'} & Pick<BackendReport, 'address' | 'reason' | 'description'>>;
};

export type BackendGetUserQueryVariables = Exact<{
  address: Scalars['String'];
}>;

export type BackendGetUserQuery = {__typename?: 'RootQueryType'} & {
  user?: Maybe<
    {__typename?: 'User'} & Pick<BackendUser, 'address' | 'level' | 'followeeCount'> & {
        followees?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>;
      }
  >;
};

export type BackendGetCurrentUserQueryVariables = Exact<{[key: string]: never}>;

export type BackendGetCurrentUserQuery = {__typename?: 'RootQueryType'} & {
  user?: Maybe<
    {__typename?: 'User'} & Pick<BackendUser, 'address' | 'level' | 'followeeCount' | 'followerCount' | 'settings'> & {
        followees?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>;
        followers?: Maybe<Array<Maybe<{__typename?: 'User'} & Pick<BackendUser, 'address'>>>>;
      }
  >;
};

export type BackendUpdateUserSettingsMutationVariables = Exact<{
  settings: Scalars['Json'];
}>;

export type BackendUpdateUserSettingsMutation = {__typename?: 'RootMutationType'} & {user?: Maybe<{__typename?: 'User'} & Pick<BackendUser, 'settings'>>};

export const BackendUserFragmentDoc = gql`
  fragment User on User {
    address
    followeeCount
    followerCount
    followees {
      address
    }
    followers {
      address
    }
  }
`;
export const BackendActivityFragmentDoc = gql`
  fragment Activity on Activity {
    actor
    object
    verb
  }
`;
export const BackendNotificationFragmentDoc = gql`
  fragment Notification on FeedItem {
    id
    unread
    activity {
      ...Activity
    }
  }
  ${BackendActivityFragmentDoc}
`;
export const BackendNotificationPageFragmentDoc = gql`
  fragment NotificationPage on PaginatedFeed {
    meta {
      page
      totalPages
    }
    results {
      ...Notification
    }
  }
  ${BackendNotificationFragmentDoc}
`;
export const BackendGetBookmarksDocument = gql`
  query GetBookmarks {
    user {
      tokenBookmarks {
        contract
        tokenId
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetBookmarksGQL extends Apollo.Query<BackendGetBookmarksQuery, BackendGetBookmarksQueryVariables> {
  document = BackendGetBookmarksDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendUpdateBookmarkDocument = gql`
  mutation UpdateBookmark($contract: String!, $tokenId: String!, $bookmarked: Boolean!) {
    bookmarkToken(contract: $contract, tokenId: $tokenId, bookmarked: $bookmarked) {
      bookmarked
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendUpdateBookmarkGQL extends Apollo.Mutation<BackendUpdateBookmarkMutation, BackendUpdateBookmarkMutationVariables> {
  document = BackendUpdateBookmarkDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendToggleIsHiddenFa2Document = gql`
  mutation ToggleIsHiddenFa2($contract: String!, $hidden: Boolean!) {
    collectionHide(contract: $contract, hidden: $hidden) {
      hidden
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendToggleIsHiddenFa2GQL extends Apollo.Mutation<BackendToggleIsHiddenFa2Mutation, BackendToggleIsHiddenFa2MutationVariables> {
  document = BackendToggleIsHiddenFa2Document;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendUpdateManagersFa2Document = gql`
  mutation UpdateManagersFa2($contract: String!, $add: [String], $remove: [String]) {
    updateManagers(contract: $contract, add: $add, remove: $remove) {
      managers
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendUpdateManagersFa2GQL extends Apollo.Mutation<BackendUpdateManagersFa2Mutation, BackendUpdateManagersFa2MutationVariables> {
  document = BackendUpdateManagersFa2Document;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendUpdateContractFa2Document = gql`
  mutation UpdateContractFa2($contract: String!, $metadata: Json!) {
    updateContract(contract: $contract, metadata: $metadata) {
      metadata
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendUpdateContractFa2GQL extends Apollo.Mutation<BackendUpdateContractFa2Mutation, BackendUpdateContractFa2MutationVariables> {
  document = BackendUpdateContractFa2Document;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendgetCreatorVerificationsDocument = gql`
  query getCreatorVerifications($verificationTypes: [String]) {
    creatorVerifications(verificationTypes: $verificationTypes) {
      creators {
        contract
        creator
        verificationType
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetCreatorVerificationsGQL extends Apollo.Query<BackendGetCreatorVerificationsQuery, BackendGetCreatorVerificationsQueryVariables> {
  document = BackendgetCreatorVerificationsDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendupdateCreatorVerificationDocument = gql`
  mutation updateCreatorVerification($contract: String!, $verificationType: String!) {
    updateCreator(contract: $contract, verificationType: $verificationType) {
      creator
      contract
      verificationType
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendUpdateCreatorVerificationGQL extends Apollo.Mutation<
  BackendUpdateCreatorVerificationMutation,
  BackendUpdateCreatorVerificationMutationVariables
> {
  document = BackendupdateCreatorVerificationDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendisFollowingDocument = gql`
  query isFollowing($address: String!) {
    following(address: $address) {
      following
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendIsFollowingGQL extends Apollo.Query<BackendIsFollowingQuery, BackendIsFollowingQueryVariables> {
  document = BackendisFollowingDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendfollowDocument = gql`
  mutation follow($address: String!, $follow: Boolean = true) {
    follow(address: $address, follow: $follow) {
      following
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendFollowGQL extends Apollo.Mutation<BackendFollowMutation, BackendFollowMutationVariables> {
  document = BackendfollowDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendGetFollowCountsForSignedUserDocument = gql`
  query GetFollowCountsForSignedUser {
    user {
      followeeCount
      followerCount
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetFollowCountsForSignedUserGQL extends Apollo.Query<
  BackendGetFollowCountsForSignedUserQuery,
  BackendGetFollowCountsForSignedUserQueryVariables
> {
  document = BackendGetFollowCountsForSignedUserDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendGetFolloweeCountDocument = gql`
  query GetFolloweeCount($address: String!) {
    user(address: $address) {
      followeeCount
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetFolloweeCountGQL extends Apollo.Query<BackendGetFolloweeCountQuery, BackendGetFolloweeCountQueryVariables> {
  document = BackendGetFolloweeCountDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendGetFolloweesDocument = gql`
  query GetFollowees($address: String!) {
    user(address: $address) {
      followees {
        address
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetFolloweesGQL extends Apollo.Query<BackendGetFolloweesQuery, BackendGetFolloweesQueryVariables> {
  document = BackendGetFolloweesDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendGetFollowersForSignedUserDocument = gql`
  query GetFollowersForSignedUser {
    user {
      followerCount
      followeeCount
      followers {
        address
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetFollowersForSignedUserGQL extends Apollo.Query<BackendGetFollowersForSignedUserQuery, BackendGetFollowersForSignedUserQueryVariables> {
  document = BackendGetFollowersForSignedUserDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendgetNotificationsPageDocument = gql`
  query getNotificationsPage($page: Int = 1, $unread: Boolean) {
    notifications(page: $page, unread: $unread) {
      ...NotificationPage
    }
  }
  ${BackendNotificationPageFragmentDoc}
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetNotificationsPageGQL extends Apollo.Query<BackendGetNotificationsPageQuery, BackendGetNotificationsPageQueryVariables> {
  document = BackendgetNotificationsPageDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendupdateNotificationsDocument = gql`
  mutation updateNotifications($ids: [ID]!, $unread: Boolean!) {
    updateUnread(ids: $ids, unread: $unread) {
      updated
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendUpdateNotificationsGQL extends Apollo.Mutation<BackendUpdateNotificationsMutation, BackendUpdateNotificationsMutationVariables> {
  document = BackendupdateNotificationsDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendmarkAllNotificationsReadDocument = gql`
  mutation markAllNotificationsRead {
    markAllRead {
      updated
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendMarkAllNotificationsReadGQL extends Apollo.Mutation<
  BackendMarkAllNotificationsReadMutation,
  BackendMarkAllNotificationsReadMutationVariables
> {
  document = BackendmarkAllNotificationsReadDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendreportTokenDocument = gql`
  mutation reportToken($tokenId: String!, $faContract: String!, $reason: ReportReasonType!, $description: String!) {
    reportToken(tokenId: $tokenId, faContract: $faContract, reason: $reason, description: $description) {
      tokenId
      faContract
      reason
      description
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendReportTokenGQL extends Apollo.Mutation<BackendReportTokenMutation, BackendReportTokenMutationVariables> {
  document = BackendreportTokenDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendreportUserDocument = gql`
  mutation reportUser($address: String!, $reason: ReportReasonType!, $description: String!) {
    reportUser(address: $address, reason: $reason, description: $description) {
      address
      reason
      description
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendReportUserGQL extends Apollo.Mutation<BackendReportUserMutation, BackendReportUserMutationVariables> {
  document = BackendreportUserDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendgetUserDocument = gql`
  query getUser($address: String!) {
    user(address: $address) {
      address
      level
      followeeCount
      followees {
        address
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetUserGQL extends Apollo.Query<BackendGetUserQuery, BackendGetUserQueryVariables> {
  document = BackendgetUserDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendgetCurrentUserDocument = gql`
  query getCurrentUser {
    user {
      address
      level
      followeeCount
      followerCount
      settings
      followees {
        address
      }
      followers {
        address
      }
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendGetCurrentUserGQL extends Apollo.Query<BackendGetCurrentUserQuery, BackendGetCurrentUserQueryVariables> {
  document = BackendgetCurrentUserDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
export const BackendupdateUserSettingsDocument = gql`
  mutation updateUserSettings($settings: Json!) {
    user(settings: $settings) {
      settings
    }
  }
`;

@Injectable({
  providedIn: 'root',
})
export class BackendUpdateUserSettingsGQL extends Apollo.Mutation<BackendUpdateUserSettingsMutation, BackendUpdateUserSettingsMutationVariables> {
  document = BackendupdateUserSettingsDocument;
  client = 'backend';
  constructor(apollo: Apollo.Apollo) {
    super(apollo);
  }
}
